import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import AlertOk from 'components/AlertOk'
import axios from 'axios'
// import {BASE_URL} from './PrdDiagnosa'
import QueryString from 'qs'
import {
  Route,
  Link,
  Routes,
  useParams,
  Navigate,
  useSearchParams,
} from 'react-router-dom'
import {API, graphqlOperation} from 'aws-amplify'
import {mUpdateDiagnosa} from 'graphql/mutations'
import {empty} from 'utils/functions'

const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

export default function PrdDiagnosaUbah(props) {
  const alert = useRef(null)

  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [uid, setUid] = useState(searchParams.get('dctid'))
  const [device, setDevice] = useState('')

  const [initialLoading, setInitialLoading] = useState(true)
  const [data, setData] = useState({})

  const detectDevice = () => {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    setDevice(isMobile ? 'mobile' : 'web')
  }

  useEffect(() => {
    detectDevice()
    fetchData()
    return () => {}
  }, [])

  const fetchData = () => {
    axios
      .post(
        `${BASE_URL}/diagnosa/view?id=${params.id}`,
        QueryString.stringify({
          a: 'dk13',
          uid,
        })
      )
      .then(({data: res}) => {
        console.log('Data Diagnosa', res)
        setData(res)
        setInitialLoading(false)

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Diagnosa', err.message)
      })
  }

  const params = useParams()
  // console.log('params', params)

  let simpan = data => {
    let {icdnama, icdkode, icdket} = data

    icdnama = icdnama.trim()
    icdkode = icdkode.trim()
    icdket = icdket.trim()

    API.graphql(
      graphqlOperation(mUpdateDiagnosa, {
        input: {
          req: JSON.stringify({
            reg: 'dbdok',
            a: 'dk13',
            data: {
              icdid: params.id,
              icdnama,
              icdbiaya: 0,
              icdkode,
              icdket,
            },
            uid,
          }),
        },
      })
    )
      .then(res => {
        let {body} = res.data.mUpdateDiagnosa
        body = JSON.parse(body)
        if (body?.status == 0) {
          alert.current.show({
            message: body?.message,
          })
          setSaving(false)
          return
        }
        console.log('logsuccess', res)
        setRedirect(true)
        // this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Ubah Biaya Pemeriksaan',
          })
          console.log('Error Ubah Diagnosa', errMsg)
        }
      })
  }

  return (
    <>
      <AlertOk ref={alert} />

      {redirect ? (
        <Navigate to={`/prd-mas-data-diagnosa?dctid=${uid}`} replace={true} />
      ) : null}

      {!initialLoading ? (
        <VmForm
          headerTitle={'Ubah Data Diagnosa'}
          onFabPress={() => {}}
          contentContainerClassName="px-4"
          footerOptions={{
            visible: true,
            btnSimpanTitle: 'Simpan',
            btnSimpanDisabled: saving ? true : false,
            btnBatalDisabled: saving ? true : false,
            btnBatalTitle: 'Batal',
            onCancel: () => {
              setRedirect(true)
            },
          }}
          fields={[
            {
              key: 'icdkode',
              caption: 'Kode',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: data.icdkode,
            },
            {
              key: 'icdnama',
              caption: 'Nama',
              type: 'input',
              variant: 'text',
              min: 0,
              defaultValue: data.icdnama,
            },
            {
              key: 'icdket',
              caption: 'Keterangan',
              type: 'input',
              variant: 'textarea',
              min: 0,
              defaultValue: data.icdket,
            },
          ]}
          onSimpan={data => {
            // do Something
            const {icdnama, icdkode, icdket} = data
            // Cek Data Kosong
            if (!icdnama.length) {
              alert.current.show({message: 'Nama Diagnosa harus diisi'})
              return
            } else if (!icdkode.length) {
              alert.current.show({message: 'Kode Diagnosa harus diisi'})
              return
            }

            setSaving(true)
            simpan(data)
            // Insert Data ke Database
          }}
          device={device}
        ></VmForm>
      ) : null}

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
