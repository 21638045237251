import {VmButton, VmIcons, VmText, VmView} from 'components'
import ErrorLayout from 'components/layout/ErrorLayout'
import {ProgressFullScreen, VmHeader} from 'molecules'

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {fetchViewV2} from './api'
import moment from 'moment'
import {reportDateFormat, reportDateFormatNoTime} from 'config/dateFormat'
import {empty, ifempty, tglDDMMMYYYY, tglYMD} from 'utils/functions'
import vmedisicon from 'assets/vmedisicon.png'
import QueryString from 'qs'
import {exportToPdf} from 'utils'
import {RgxMobileDevice} from 'utils/core'
import {exportToPdfFromHtml} from 'utils/export-to-pdf'
import {calcAgeYearOnly} from 'utils/calc-age'
const {REACT_APP_BASE_URL_DOKTER: APIDOC} = process.env

export default function Detail() {
  const navigate = useNavigate()
  const {id} = useParams()
  const [error, seterror] = useState(null)
  const [data, setdata] = useState({
    rujid: 0,
    rujtanggal: '',
    rujno: '',
    rujuid: '',
    farid: 0,
    farnama: '',
    porid: 0,
    pornama: '',
    dorid: 0,
    dornama: '',
    rujtindakan: '',
    rujstatus: 0,
    pasnama: '',
    pasumur: 0,
    pasrm: '',
    pastgllahir: '',
    pasjk: '',
    pasalamat: '',
    icdkode: '',
    icdnama: '',
  })

  const [user, setUser] = useState({
    user_id: 0,
    kl_id: 0,
    app_id: 'dk13',
    username: '',
    nama_lengkap: '',
    email: '',
    user_nosip: '',
    user_nosip_en: '',
    user_noktp: '',
    user_noktp_en: '',
    alamat: '',
    provinsi: '277',
    kota: '2439',
    kecamatan: '34055',
    keterangan: '',
    user_nostr_en: '',
    user_wa: '085606021473',
    kecnama: '',
    kotanama: '',
  })
  const [loading, setloading] = useState(false)
  /**
   * index = atur pengaturan resep
   * preview = preview cetak faktur
   */
  const [mode, setMode] = useState('index')
  const [device, setDevice] = useState(null)
  const detectDevice = () => {
    let isMobile = RgxMobileDevice.test(navigator.userAgent)
    setDevice(isMobile ? 'mobile' : 'web')
  }

  const handleBack = () => navigate(-1)
  const handleInit = async () => {
    setloading(true)
    const resData = await fetchViewV2({rujid: id, reg: 'dbdok'})
    setloading(false)
    if (resData.status == 0) {
      return seterror({e: true, ...resData})
    } else {
      seterror(null)
      setdata({...resData?.data})
      setUser(resData?.user)
    }
  }

  useEffect(() => {
    handleInit()
    detectDevice()
    // getIdentitas()
    moment.locale('id')
  }, [])

  if (error?.e)
    return (
      <>
        <ProgressFullScreen visible={loading} />
        <ErrorLayout
          onBackPress={handleBack}
          showreload={true}
          onClickReload={handleInit}
          message={error?.message}
        />
      </>
    )

  const renderIndex = () => {
    return (
      <VmView className="p-6 py-8 bg-white rounded-2xl">
        <VmText className="text-black0-payment font-bold mb-6 text-sm">
          Data Rujukan Eksternal
        </VmText>

        <RowData
          label={['No. RM', 'Tanggal Lahir']}
          data={[data.pasrm, moment(data.pastgllahir).format('DD MMM YYYY')]}
        />
        <RowData
          label={['Nama Lengkap', 'Jenis Kelamin']}
          data={[data['pasnama'], data['pasjk']]}
        />
        <RowData
          label={['Tanggal Rujukan']}
          data={[moment(data.rujtanggal).format('DD MMM YYYY')]}
        />
        <RowData label={['No. Rujukan']} data={[data['rujno']]} />
        <RowData
          label={['Nama Faskes']}
          data={[ifempty(data['farnama'], '-')]}
        />
        <RowData label={['Poli']} data={[ifempty(data['pornama'], '-')]} />
        <RowData label={['Dokter']} data={[data['dornama']]} />
        <RowData label={['Diagnosa']} data={[data['icdnama']]} />
        <RowData
          label={['Tindakan Yang Sudah Diberikan']}
          data={[data['rujtindakan']]}
        />
      </VmView>
    )
  }

  const renderWebIndex = () => {
    return (
      <VmView className="p-6 py-8 bg-white rounded-2xl">
        <VmText className="text-slate-700 font-bold mb-12 text-center">
          Data Rujukan Eksternal
        </VmText>

        <RowData
          label={['No. RM', 'Tanggal Lahir']}
          data={[data.pasrm, moment(data.pastgllahir).format('DD MMM YYYY')]}
        />
        <RowData
          label={['Nama Lengkap', 'Jenis Kelamin']}
          data={[data['pasnama'], data['pasjk']]}
        />
        <RowData
          label={['Tanggal Rujukan','Nama Faskes']}
          data={[moment(data.rujtanggal).format('DD MMM YYYY'), ifempty(data['farnama'], '-')]}
        />
        <RowData label={['Poli','Dokter']} data={[ifempty(data['pornama'], '-'),ifempty(data['dornama'], '-')]} />
        <RowData label={['No. Rujukan']} data={[data['rujno']]} />
        <RowData label={['Diagnosa']} data={[data['icdnama']]} />
        <RowData
          label={['Tindakan Yang Sudah Diberikan']}
          data={[data['rujtindakan']]}
        />
      </VmView>
    )
  }

  const renderPreview = () => {
    return (
      <VmView className="p-6 py-8 bg-white rounded-2xl" id="cetak">
        <VmView className="flex justify-center">
          <img src={vmedisicon} className="w-16" />
        </VmView>
        <p className="text-xs text-center font-bold mb-6 mt-2">
          Dokter Praktik Mandiri
        </p>

        <p className="text-xs text-center font-bold mb-2">
          Surat Keterangan Rujukan
        </p>
        <p className="text-sxs text-center mb-4">No. Surat : {data.rujno}</p>

        <p className="text-sxs mb-2">Kepada Yth,</p>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Nama Faskes</p>
          <p className="text-sxs flex-1">: {data.farnama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Poli</p>
          <p className="text-sxs flex-1">: {data.pornama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Dokter</p>
          <p className="text-sxs flex-1">: {data.dornama}</p>
        </VmView>

        <p className="text-sxs ">Dengan Hormat,</p>
        <p className="text-sxs mb-2">
          Bersama dengan surat ini, kami rujuk pasien
        </p>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Nama</p>
          <p className="text-sxs flex-1">: {data.pasnama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Umur</p>
          <p className="text-sxs flex-1">
            : {calcAgeYearOnly(data.pastgllahir) + ' Tahun'}
          </p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Tempat Tinggal</p>
          <p className="text-sxs flex-1">: {data.pasalamat}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Diagnosa</p>
          <p className="text-sxs flex-1">
            : {data.icdkode} - {data.icdnama}
          </p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sxs flex-1">Tindakan yang sudah diberikan</p>
          <p className="text-sxs flex-1">: {data.rujtindakan}</p>
        </VmView>
        <p className="text-sxs mb-2">
          Mohon pemeriksaan dan penanganan selanjutnya, Atas perhatian dan
          kerjasamanya, kami ucapkan terima kasih.
        </p>

        <p className="text-sxs mb-12 text-right">
          {user.kotanama}, {tglDDMMMYYYY()}
        </p>
        <p className="text-sxs mb-2 text-right font-bold">
          {user.nama_lengkap}
        </p>
      </VmView>
    )
  }

  const renderWebPreview = () => {
    return (
      <VmView className="p-6 py-8 bg-white rounded-2xl" id="cetak">
        <VmView className="flex justify-center">
          <img src={vmedisicon} className="w-24" />
        </VmView>
        <p className="text-sm text-center font-bold mb-6">Surat Keterangan Rujukan</p>

        <p className="text-sm text-center font-bold mb-2">
          Surat Keterangan Rujukan
        </p>
        <p className="text-xs text-center mb-4">No. Surat : {data.rujno}</p>

        <p className="text-xs mb-2">Kepada Yth,</p>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Nama Faskes</p>
          <p className="text-xs flex-1">{data.farnama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Poli</p>
          <p className="text-xs flex-1">{data.pornama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Dokter</p>
          <p className="text-xs flex-1">{data.dornama}</p>
        </VmView>

        <p className="text-xs ">Dengan Hormat,</p>
        <p className="text-xs mb-2">
          Bersama dengan surat ini, kami rujuk pasien
        </p>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Nama</p>
          <p className="text-xs flex-1">{data.pasnama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Umur</p>
          <p className="text-xs flex-1">{data.pasumur}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Tempat Tinggal</p>
          <p className="text-xs flex-1">{data.pasalamat}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Diagnosa</p>
          <p className="text-xs flex-1">{data.icdnama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-xs flex-1">Tindakan yang sudah diberikan</p>
          <p className="text-xs flex-1">{data.rujtindakan}</p>
        </VmView>
        <p className="text-xs mb-2">
          Mohon pemeriksaan dan penanganan selanjutnya, Atas perhatian dan
          kerjasamanya, kami ucapkan terima kasih.
        </p>

        <p className="text-xs mb-12 text-right">
          {user.kotanama}, {tglDDMMMYYYY()}
        </p>
        <p className="text-xs mb-2 text-right font-bold">{user.nama_lengkap}</p>
      </VmView>
    )
  }

  const MobLapRujukan = () => {
    return (
      <>
        <VmView className="fixed inset-0 bg-blue4-payment">
          <VmView className="fixed top-4 left-4 right-4 flex flex-col">
            <VmHeader
              className="mb-5"
              HeaderMode="!text-black2-payment"
              HeaderButtonVersion={0}
              HeaderName="Menu"
              HeaderType="Detail Rujukan Eksternal"
              onBackPress={handleBack}
            />
          </VmView>

          {/* Section scroll wrapper */}
          <VmView className="absolute inset-0 my-20 px-4 overflow-y-auto">
            {/* Section card items */}

            {/* Section 1 */}
            {mode == 'index' ? renderIndex() : renderPreview()}
          </VmView>
        </VmView>

        <VmView className="fixed flex justify-between bottom-0 left-0 right-0 bg-blue4-payment p-4">
          {(() => {
            switch (mode) {
              case 'index':
                return (
                  <>
                    <div></div>
                    <VmButton
                      onClick={() => {
                        setMode('preview')
                      }}
                      className={'text-xs py-4 px-8'}
                      rounded="full"
                    >
                      Cetak
                    </VmButton>
                  </>
                )

              case 'preview':
                return (
                  <>
                    {' '}
                    <VmButton
                      onClick={() => {
                        setMode('index')
                      }}
                      className={'text-xs py-4 px-8'}
                      rounded="full"
                      color="danger"
                    >
                      Tutup
                    </VmButton>
                    <VmButton
                      onClick={() => {
                        // setMode('preview')
                        // console.log({hostname:window.location.hostname,host:window.location.host})
                        let urldestination = window.location.href.replace(
                          /prd-lap-rujukan\/[0-9]+/,
                          `prd-lap-rujukan/export/${id}`
                        )
                        if (window.ReactNativeWebView?.postMessage) {
                          window.ReactNativeWebView.postMessage(
                            `openExternalLink:${urldestination}`
                          )
                        }
                      }}
                      className={'text-xs py-4 px-8'}
                      rounded="full"
                    >
                      Cetak
                    </VmButton>
                  </>
                )
            }
          })()}
        </VmView>
        <ProgressFullScreen visible={loading} />
      </>
    )
  }

  const WebLapRujukan = () => {
    return (
      <>
        <VmView className="!bg-white RootBodyPrint">
          <VmView className="flex flex-col overflow-y-scroll">
            {/* Content */}
            {mode == 'index' ? renderWebIndex() : renderWebPreview()}

            {/* Footer */}
            <VmView className="!notprint flex flex-row justify-between items-center px-4 py-4">
              {(() => {
                switch (mode) {
                  case 'index':
                    return (
                      <>
                        {/* Left */}
                        <VmView className="flex flex-row gap-4 px-2">
                          <VmButton
                            onClick={() => {
                              setMode('preview')
                            }}
                            className={
                              'max-h-20 py-3 px-10 text-sm text-white font-semibold block'
                            }
                            rounded="2xl"
                          >
                            Cetak
                          </VmButton>
                        </VmView>
                        {/* Right */}
                        <VmView className="flex flex-row gap-4 px-2">
                          <VmButton
                            onClick={handleBack}
                            className={
                              'max-h-20 py-3 px-10 text-sm font-semibold block'
                            }
                            rounded="2xl"
                            color="danger"
                            variant="outline"
                          >
                            Batal
                          </VmButton>
                        </VmView>
                      </>
                    )

                  case 'preview':
                    return (
                      <>
                        {' '}
                        <VmButton
                          onClick={() => {
                            let urldestination = window.location.href.replace(
                              /prd-lap-rujukan\/[0-9]+/,
                              `prd-lap-rujukan/export/${id}`
                            )
                            if (window.ReactNativeWebView?.postMessage) {
                              window.ReactNativeWebView.postMessage(
                                `openExternalLink:${urldestination}`
                              )
                            } else {
                              // window.open(urldestination)
                              window.print()
                            }
                          }}
                          className={
                            'max-h-20 py-3 px-10 text-sm text-white font-semibold block'
                          }
                          rounded="2xl"
                        >
                          Cetak
                        </VmButton>
                        <VmButton
                          onClick={() => {
                            setMode('index')
                          }}
                          className={
                            'max-h-20 py-3 px-10 text-sm font-semibold block'
                          }
                          rounded="2xl"
                          color="danger"
                          variant="outline"
                        >
                          Tutup
                        </VmButton>
                      </>
                    )
                }
              })()}
            </VmView>
          </VmView>
        </VmView>
        <ProgressFullScreen visible={loading} />
      </>
    )
  }

  if (!device) {
    return <ProgressFullScreen visible={true} />
  }

  if (device == 'mobile') {
    console.log(device, 'device')
    return <MobLapRujukan />
  } else if (device == 'web') {
    console.log(device, 'device')
    return <WebLapRujukan />
  } else {
    console.log(device, 'device')
  }
}

function RowData({data = [], label = []}) {
  return (
    <VmView className="flex flex-row justify-between">
      <VmView className="basis-1/2 mb-4">
        <VmText className="text-black1-payment font-semibold mb-2 text-xs">
          {label[0]}
        </VmText>
        <VmText className="text-black1-payment text-xs">
          {!!data[0] ? data[0] : '-'}
        </VmText>
      </VmView>

      {data.length > 1 ? (
        <VmView className="basis-1/2 mb-4">
          <VmText className="text-black1-payment font-semibold mb-2 text-xs">
            {label[1]}
          </VmText>
          <VmText className="text-black1-payment text-xs">
            {!!data[1] ? data[1] : '-'}
          </VmText>
        </VmView>
      ) : (
        <></>
      )}
    </VmView>
  )
}

function CustomCheckbox({checked = true, onChange = () => {}, className = ''}) {
  return (
    <VmView className={className}>
      <input
        type="checkbox"
        id="gc-checkbox"
        className="w-4 h-4 border-0 focus:ring-0 focus:accent-blue1-payment  accent-blue1-payment"
        checked={checked}
        onChange={onChange}
      />
    </VmView>
  )
}

function HubunganPasienCard({item}) {
  return (
    <VmView className="border-color-gray0-payment border rounded-2xl mb-6 pt-6 px-6 pb-4">
      <VmView className="flex flex-row items-center">
        {/* Section Left */}
        <VmView className="flex flex-col mr-4 items-center justify-center">
          <VmIcons name="VmUserIcon" size={70} className="text-blue1-payment" />
          <VmText className="text-black1-payment font-semibold text-xs mt-2">
            {!!item?.umur || item?.umur == 0 ? `${item?.umur} Tahun` : '-'}
          </VmText>
        </VmView>

        {/* Section right */}
        <VmView>
          <VmText className="text-black1-payment font-semibold text-xs">
            {item?.pasrm || '-'}
          </VmText>
          <VmText className="text-black1-payment text-xs mb-4">
            {item?.pasnama || '-'}
          </VmText>

          <VmView className="flex flex-row mb-2">
            <VmView>
              <VmIcons
                name="VmPinLocationIcon"
                size={20}
                className="text-black1-payment"
              />
            </VmView>
            <VmText className="text-black1-payment text-xs ml-2">
              {item?.pasalamat || '-'}
            </VmText>
          </VmView>

          <VmView className="flex flex-row">
            <VmIcons
              name="VmPhoneIcon"
              size={20}
              className="text-black1-payment"
            />
            <VmText className="text-black1-payment text-xs ml-2">
              {item?.pastelp || '-'}
            </VmText>
          </VmView>

          <VmView className="flex w-28 p-1 bg-blue1-payment rounded-lg mt-4 items-center justify-center">
            <VmText className="text-white">
              {item?.passtatussambung || '-'}
            </VmText>
          </VmView>
        </VmView>
      </VmView>
    </VmView>
  )
}
