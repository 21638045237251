import classNames from 'classnames'
import {VmButton, VmView} from 'components'

export default function CardItemMitra({
  data: {title = '', address = ''},
  index = 0,
}) {
  return (
    <VmView className="relative !text-black2-payment bg-gray7-payment mb-4 last:mb-20 rounded-xl rounded-t-2xl">
      <VmView className="flex bg-white px-4 py-3 rounded-xl">
        {/* Head */}
        <VmView className="block mr-auto max-w-[80%]">
          {/* Title */}
          <VmView className="pb-2 font-semibold !text-break-word">
            {title}
          </VmView>
          {/* Address */}
          <VmView className="text-sm !text-break-word">{address}</VmView>
        </VmView>
        <VmView className="flex items-end">
          <VmButton
            className={classNames(
              'flex justify-center items-center !flex-none text-sm p-1 px-4 !min-w-[4rem]',
              '!bg-blue1-payment !text-white'
            )}
            hidering={true}
            label={`add-mitra-${index}`}
          >
            Pilih
          </VmButton>
        </VmView>
      </VmView>
    </VmView>
  )
}
