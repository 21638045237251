import {VmArrowLeftIcon, VmChevronLeftIcon} from 'components/icons/solid'

import classNames from 'classnames'
import React from 'react'

export default function Header({
  title = '',
  chevron = false,
  light = false,
  className = ' ',
  spacing = false,
  onClick = () => {},
}) {
  const textStyle = light ? 'text-vm-blue' : 'text-white'
  const textSpacing = spacing ? 'ml-4' : 'ml-1'
  return (
    <div
      className={classNames(
        'flex flex-row p-3 fixed right-0 left-0 top-0 ',
        chevron ? 'px-2 py-3 ' : 'justify-between ',
        light ? 'bg-white' : 'bg-vm-blue',
        className
      )}
    >
      <div onClick={onClick}>
        {chevron ? (
          <VmChevronLeftIcon
            height="24px"
            width="24px"
            className={textStyle + ' mr-2'}
          />
        ) : (
          <VmArrowLeftIcon
            height={`${24}px`}
            width={`${24}px`}
            className={classNames(textStyle)}
          />
        )}
      </div>
      <h3 className={classNames(textStyle, 'font-medium', textSpacing)}>
        {title}
      </h3>
      <p></p>
    </div>
  )
}
