import {APIDOC} from 'config/apiurl'

const classExportAttrWeb = {
  content:
    'bg-white flex flex-row rounded-xl p-4 my-3 cursor-pointer shadow-md hover:scale-105 hover:bg-green-50/90',
  title: 'flex flex-col',
}
export const customExportAttrWeb = [
  {
    url: APIDOC + '/prd-lap-rujukan/export-web',
    title: 'Export rekap',
    classNames: classExportAttrWeb,
  },
]
export const customPrintAttrWeb = [
  {
    url: APIDOC + '/prd-lap-rujukan/print-web',
    title: 'Cetak rekap',
    desc: 'Fungsi untuk mengekspor data dalam bentuk pdf',
    type: 'PDF',
    color: 'red',
    classNames: classExportAttrWeb,
  },
]
export const pageTitle = 'Laporan Rujukan'
export const dataFields = ['pasnama', 'farnama', 'rujtindakan']

export const filterFields = [
  {
    Sorting: [
      {label: 'Tanggal Rujukan', dbColumn: 'rujtanggal'},
      {label: 'Nama Pasien', dbColumn: 'pasnama'},
      {label: 'Faskes Rujukan', dbColumn: 'farnama'},
      {label: 'Poli Rujukan', dbColumn: 'pornama'},
    ],
  },
]

export const aliasFields = [
  {
    dbColumn: 'rujstatus',
    alias: [
      {
        value: '1',
        label: 'Aktif',
        color: 'text-green-500 bg-green-200',
      },
      {
        value: '2',
        label: 'Non Aktif',
        color: 'text-red-500 bg-red-200',
      },
    ],
  },
]
