import {
  VmEyeIcon,
  VmPhotoIcon,
  VmPencilSquareIcon,
  VmTrashIcon,
} from 'components/icons/outline'
import {VmText, VmView, VmInput, VmButton} from 'components'
import {VmHeader} from 'molecules'
import classNames from 'classnames'
import {brokenImage} from 'utils/functions'
import {VmChevronDownIcon} from 'components/icons/solid'
import {Fragment} from 'react'

export const hidering = '!ring-0 !ring-transparent !ring-offset-0'
const PrdStrukSetting = ({
  data = {},
  HeaderName = 'Menu',
  HeaderType = 'Pengaturan Cetak',
}) => {
  if (data === null) {
    return <></>
  }
  return (
    <VmView className="w-screen h-screen UbahProfil flex flex-col bg-gray">
      <VmHeader
        HeaderName={HeaderName}
        HeaderType={HeaderType}
        className="absolute px-4 pt-4 w-full"
        classNameTitle="!pl-4"
      />
      <VmView className="Hero flex flex-col min-h-[35%] bg-blue-500 static" />
      {/* section content utama*/}
      <VmView className="flex flex-col flex-grow p-3 absolute bottom-0 left-0 right-0 top-16">
        <VmView className="flex flex-col  h-[-webkit-fill-available] bg-white rounded-xl shadow-xl flex-grow">
          <ChangeSettingInput data={data} />
        </VmView>
      </VmView>
      <VmButton
        label="SimpanSetting"
        className={classNames(
          hidering,
          'w-[-webkit-fill-available]',
          'shadow-lg max-h-20 bg-gradient-to-r from-orange-400 to-yellow-300 py-3',
          'rounded-lg text-sm text-white absolute bottom-[30px] left-[30px] right-[30px]'
        )}
      >
        Simpan
      </VmButton>
    </VmView>
  )
}

const ChangeSettingInput = ({data = null}) => {
  if (data === null) {
    return <></>
  }
  const lstmenu = [
    {
      type: 1,
      title: 'Catatan Promo',
      Icon: VmPencilSquareIcon,
      label: 'input-catatan',
      field: 'catatan',
      typeInput: 'textarea',
      maxLength: 255,
    },
    {
      type: 5,
      title: 'Tampilkan Catatan',
      subtitle: 'Opsi untuk menampilkan catatan pada struk.',
      Icon: VmPencilSquareIcon,
      label: 'input-checkbox-catatan',
      field: 'checkbox-catatan',
    },
    {
      type: 5,
      title: 'Tampilkan Terbilang',
      subtitle: 'Opsi untuk menampilkan bilangan harga pada struk.',
      Icon: VmPencilSquareIcon,
      label: 'input-checkbox-terbilang',
      field: 'checkbox-terbilang',
    },
  ]
  return (
    <VmView className="flex flex-col w-full mt-3 overflow-y-scroll px-2">
      {/* input - section */}
      {lstmenu.map((im, index) => {
        const ImIcon = im.Icon,
          key = JSON.stringify({t: im.title, index})
        if (im.type === 1) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-col p-2 justify-between"
            >
              <VmText className="text-xs text-blue-500">{im.title}</VmText>
              <VmView className="flex flex-row mt-1 bg-gray-200 rounded-md">
                <VmView
                  className={classNames(
                    im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                    'p-3.5 w-12 rounded-l-md border-none text-blue-500'
                  )}
                >
                  <ImIcon
                    className={classNames(
                      im.typeInput === 'textarea' ? '' : 'h-full',
                      'w-full'
                    )}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.6"
                  />
                </VmView>
                <VmInput
                  maxLength={im.maxLength}
                  typeInput={im.typeInput}
                  label={im.label}
                  className={classNames(
                    im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
                    hidering,
                    'transparent-items center-items bg-transparent w-full rounded-l-none border-none'
                  )}
                  defaultValue={data[im.field] || '-'}
                  key={data[im.field] || '-'}
                />
              </VmView>
            </VmView>
          )
        }
        if (im.type === 2) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-row p-2 pb-0 items-center"
            >
              <VmText className="text-xs text-blue-500">{im.title}</VmText>
              <VmInput
                maxLength={im.maxLength}
                typeInput={'checkbox'}
                label={im.label}
                className="ml-2"
                defaultChecked={im?.checked}
              />
            </VmView>
          )
        }
        if (im.type === 3) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-col p-2 justify-between"
            >
              <VmText className="text-xs text-blue-500">{im.title}</VmText>
              {
                <VmView className="flex flex-row mt-1">
                  <VmView
                    id={`img-${im.field}`}
                    label={`img-${im.field}`}
                    className="flex w-4/5 bg-contain bg-center border-xl hover:bg-contain"
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url(${
                        !!data[`${im.field}-to-upload`]
                          ? data[`${im.field}-to-upload`]
                          : !!data[im.field]
                          ? data[im.field]
                          : brokenImage
                      })`,
                    }}
                  />
                  {(!!data[`${im.field}-to-upload`] || !!data[im.field]) && (
                    <VmView className="flex w-1/5 ml-2 flex-col justify-end mb-2">
                      <VmButton
                        label={`view-${im.label}`}
                        className={classNames(
                          hidering,
                          'w-11 h-11 p-3.5 !bg-white rounded-lg shadow-lg mb-2'
                        )}
                      >
                        <VmEyeIcon
                          aria-label={`view-${im.label}`}
                          titleId={`view-${im.label}`}
                          className="w-full text-blue-500 "
                        />
                      </VmButton>
                      <VmButton
                        label={`remove-${im.label}`}
                        className={classNames(
                          hidering,
                          'w-11 h-11 p-3.5 !bg-red-400 rounded-lg shadow-lg'
                        )}
                      >
                        <VmTrashIcon
                          aria-label={`remove-${im.label}`}
                          titleId={`remove-${im.label}`}
                          className="w-full text-white "
                        />
                      </VmButton>
                    </VmView>
                  )}
                </VmView>
              }
              <VmView className="flex flex-row mt-1">
                <VmView className="p-3.5 h-12 w-12 bg-gray-200 rounded-l-md border-none text-blue-500">
                  <VmPhotoIcon
                    className="w-full h-full"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.6"
                  />
                </VmView>
                <input
                  maxLength={im.maxLength}
                  aria-label={im.label}
                  type="file"
                  className="items-center transparent-items center-items w-full h-12 rounded-l-none border-none bg-gray-200 leading-10"
                />
              </VmView>
            </VmView>
          )
        }
        if (im.type === 4 && !!data[im.field]) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-col p-2 justify-between"
            >
              <VmText className="text-xs text-blue-500">{im.title}</VmText>
              <VmView className="flex flex-row mt-1 bg-gray-200 rounded-md">
                <VmView
                  className={classNames(
                    im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                    'p-3.5 w-12 rounded-l-md border-none text-blue-500'
                  )}
                >
                  <ImIcon
                    className={classNames(
                      im.typeInput === 'textarea' ? '' : 'h-full',
                      'w-full'
                    )}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.6"
                  />
                </VmView>
                <VmInput
                  readOnly={true}
                  maxLength={im.maxLength}
                  typeInput={im.typeInput}
                  label={im.label}
                  className={classNames(
                    im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
                    hidering,
                    'transparent-items center-items bg-transparent w-full rounded-none border-none'
                  )}
                  key={!!data[im.field] ? data[im.field] : '-'}
                  value={!!data[im.field] ? data[im.field] : '-'}
                />
                <VmView
                  label={im.label}
                  className={classNames(
                    im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                    'p-3.5 w-12 rounded-r-md border-none text-blue-500'
                  )}
                >
                  <VmChevronDownIcon
                    className={classNames(
                      im.typeInput === 'textarea' ? '' : 'h-full',
                      'w-full'
                    )}
                    aria-label={im.label}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.6"
                  />
                </VmView>
              </VmView>
            </VmView>
          )
        }
        if (im.type === 5) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-row p-2 pb-0 items-center"
            >
              <VmInput
                maxLength={im.maxLength}
                typeInput={'checkbox'}
                label={im.label}
                className="mr-2 flex self-start"
                defaultChecked={data[im.field] || im?.checked}
              />
              <VmView>
                <VmText className="text-sm text-blue-500">
                  {im?.title || '-'}
                </VmText>
                <VmText className="text-xs text-gray-400">
                  {im?.subtitle || '-'}
                </VmText>
              </VmView>
            </VmView>
          )
        }
        return <Fragment key={key} />
      })}
      <VmView className="mb-24" />
    </VmView>
  )
}

export default PrdStrukSetting
