const modelData = ({key, data}) => {
  const reg = 'dbdok'
  switch (key) {
    case 'index':
      return {
        req: JSON.stringify({
          id: data?.id,
          ep: data?._ldata,
          reg,
        }),
      }
    default:
      return null
  }
}

export {modelData}
