import moment from 'moment'
import {
  VmCardContainer,
  VmView,
  VmText,
  VmDivider,
  VmButton,
  VmIcons,
} from 'components'
import {reportDateFormat} from 'config/dateFormat'
import classNames from 'classnames'
import {empty} from 'utils/functions'
import * as Fa from 'react-icons/fa'
import {Fragment} from 'react'

export default function Item({
  item,
  index = 0,
  classname = '',
  buttonLabel = 'Detail',
  imageComponent = '',
  buttonAction = () => {},
  onCardItemClick = () => {},

  dataNumber = true,
  button = false,
  buttonAdditionalProps = {},

  headerFields = [],
  fields = [],
  vitals = [],
  footerLabel = '',
  footerValue = '',
  footerDividerVisible = false,
  customStatusComponent = false,
  custom = '',
}) {
  const mainTextStyle = 'text-gray-700'
  const dimTextStyle = 'text-gray-500'

  const renderImageComponent = item => {
    const dbColumnValue = item[imageComponent.dynamicProps.dbColumn]
    const src = imageComponent.dynamicProps.alias.find(
      val => Object.keys(val)[0] === dbColumnValue
    )
    if (!src) return item
    return imageComponent.component({
      ...imageComponent.props,
      [imageComponent.dynamicProps.forProps]: src[dbColumnValue],
    })
  }
  const handleFooterValue = () => {
    if (footerValue?.name) return item[footerValue?.name] || '-'
    return footerValue || '-'
  }
  const formatRow = (
    row,
    dateFormat = false,
    customFormat = false,
    customFormatParams = []
  ) => {
    if (!row) return '-'
    return dateFormat
      ? moment.utc(row).format(reportDateFormat)
      : customFormat
      ? customFormat(row, ...customFormatParams)
      : row
  }
  const renderRow = (fieldsData, isHeader = false) =>
    fieldsData.map((fieldsItem, i) =>
      fieldsItem.name ? (
        <VmText key={i} className={isHeader ? mainTextStyle : dimTextStyle}>
          {formatRow(
            item[fieldsItem.name],
            fieldsItem?.dateFormat,
            fieldsItem?.customFormat,
            fieldsItem?.customFormatParams
          )}
        </VmText>
      ) : fieldsItem?.children ? (
        <Fragment key={i}>
          {typeof fieldsItem?.children === 'function'
            ? fieldsItem?.children(item, fieldsItem?.dbColumn)
            : fieldsItem?.children}
        </Fragment>
      ) : (
        <VmText key={i} className={isHeader ? mainTextStyle : dimTextStyle}>
          {item[fieldsItem] || '-'}
        </VmText>
      )
    )
  const renderVitals = fieldsData =>
    fieldsData.map((fieldsItem, i) => {
      const key = `${i}v`
      const Icon = Fa[fieldsItem?.icon?.name]
        ? Fa[fieldsItem?.icon?.name]
        : Fragment
      return (
        <VmView key={key} className="flex flex-row items-center gap-2">
          <Icon {...(fieldsItem?.icon || {})} key={`${key}-c`} />
          <VmText className={dimTextStyle}>
            {item[fieldsItem?.name]
              ? `${item[fieldsItem?.name]}`.replace(/\ /g, '')
              : '-'}
          </VmText>
        </VmView>
      )
    })

  const renderCardContents = item => {
    const rowItems = [
      !!custom['121']
        ? {
            icon: 'VmBpjsIcon',
            value: item?.pasnobpjs,
            className: 'font-semibold',
          }
        : '',
      {
        icon: 'VmUserIcon',
        value: item?.pasnama,
        className: !!custom['121'] ? '' : 'font-semibold',
      },
      {
        icon: 'VmStetoskop',
        value: item?.rmkeluhanutama,
        className: '',
      },
      {
        icon: 'VmClipboardDocumentListIcon',
        value: item?.icdkodenama,
        className: '',
      },
    ]

    return <CardContent dataFields={rowItems} />
  }

  const renderPcare = item => {
    const rowItems = [
      {
        icon: 'VmUserIcon',
        value: item?.kunpcarekunjungan,
        className: '',
      },
      {
        icon: 'VmUserIcon',
        value: item?.kunpcareno,
        className: '',
      },
    ]

    return (
      <>
        <VmDivider />
        <CardContent dataFields={rowItems} />
      </>
    )
  }

  const CardContent = ({dataFields = []}) => (
    <VmView className="text-gray-500">
      {dataFields.map((item, i) => {
        if (!item) return null
        return (
          <VmView className="flex flex-row items-baseline" key={i}>
            <VmView className="mr-2">
              <VmIcons name={item.icon} size={18} />
            </VmView>
            <VmText className={item.className}>{item?.value || '-'}</VmText>
          </VmView>
        )
      })}
    </VmView>
  )

  return (
    <VmCardContainer classname={`my-4 ${classname}`} hover>
      <VmView onClick={() => onCardItemClick(item)}>
        {/* Card Header */}
        <VmView className="flex flex-row justify-between items-start">
          <VmView className="flex flex-row items-center min-w-fit">
            {/* Data Number */}
            {dataNumber ? (
              <VmView className="bg-blue-500 box-border mr-2 w-10 h-10 rounded-xl flex items-center justify-center">
                <VmText className="text-xs text-white">{index + 1}</VmText>
              </VmView>
            ) : null}

            {/* Header Text */}
            <VmView className="flex flex-col">
              {renderRow(headerFields, true)}
            </VmView>
          </VmView>

          {!empty(customStatusComponent) ? customStatusComponent : <></>}
        </VmView>
        <VmDivider className="mt-2 mb-2" />

        {/* Card Content */}
        <VmView className="flex flex-col md:flex-row items-stretch justify-between gap-3">
          <VmView className="flex flex-row items-center">
            {/* Foto / Gambar / Icon*/}
            {imageComponent ? renderImageComponent(item) : null}

            {/* List Data Content */}
            <VmView>
              {fields?.length > 0 ? renderCardContents(item) : null}
            </VmView>
          </VmView>

          {!!custom['121'] && renderPcare(item)}

          {/* List Data Vital */}
          {vitals?.length > 0 && (
            <VmView className="p-2 pb-0 md:pb-2 md:border-l md:border-t-0 border-t border-gray-200 grid md:grid-cols-2 sm:grid-cols-4 grid-cols-2 gap-2">
              {renderVitals(vitals)}
            </VmView>
          )}
        </VmView>
      </VmView>

      {/* Footer */}
      {(button || footerLabel) && (
        <>
          {footerDividerVisible && <VmDivider className="mt-2 mb-4" />}
          <VmView className=" flex flex-row justify-between">
            {!!footerLabel && (
              <VmView className="flex flex-row items-center">
                <VmText className={mainTextStyle + ' mr-2'}>
                  {footerLabel}
                </VmText>
                <VmText className={dimTextStyle}>{handleFooterValue()}</VmText>
              </VmView>
            )}
            {button && (
              <VmButton
                className={classNames('hover:!bg-sky-600 ml-auto')}
                onClick={() => buttonAction(item)}
                {...buttonAdditionalProps}
              >
                {buttonLabel}
              </VmButton>
            )}
          </VmView>
        </>
      )}
    </VmCardContainer>
  )
}
