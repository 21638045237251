import {useState} from 'react'
import {VmButton, VmIcons, VmInput, VmView} from 'components'
import classNames from 'classnames'
import moment from 'moment'

export default function Searchbar({
  keyword = '',
  placeholder = 'Cari No Kartu',
  label = 'search',
  reset = () => {},
  onSearch = () => {},
  onChangeCaptureInput = () => {},
  onKeyDownCaptureInput = () => {},
}) {
  const classDatePicker = classNames(
      'text-prevent-select !text-xs',
      'flex gap-3 bg-white relative',
      'border-0 py-3 px-5 border-slate-600',
      'text-grey-darkest focus:outline-none',
      'focus:ring-2 focus:ring-slate-500 focus:ring-offset-2',
      'focus:ring-offset-slate-100 rounded-xl hover:border-blue-500',
      'justify-between items-center text-slate-700 font-bold cursor-pointer'
    ),
    today = moment().format('YYYY-MM-DD')

  const [dateperiode, setdateperiode] = useState({
    start: today,
    end: today,
  })

  return (
    <VmView className="flex flex-col mb-4 rounded-3xl !min-h-[12rem] overflow-hidden p-5 bg-blue1-payment">
      {/* <VmView
        className={classNames(
          'flex flex-row bg-blue1-payment rounded-3xl mb-5',
        )}
      >
      </VmView> */}
      <VmView>
        <VmView className={classDatePicker}>
          <VmIcons
            size={16}
            name="VmCalendarDaysIcon"
            className="stroke-[2.5]"
          />
          {moment(dateperiode.start).format('DD MMM YYYY')}
          <input
            type="date"
            aria-label="date-start-picker"
            className="absolute rounded-xl inset-0 bg-transparent text-transparent border-0 outline-0 text-center text-9xl input-date-picker-transparent"
            max={today}
            onChange={e => {
              setdateperiode(pd => {
                return {
                  ...pd,
                  start: e?.target?.value || moment().format('DD-MM-YYYY'),
                }
              })
            }}
          />
        </VmView>
        <VmInput
          typeInput="number"
          className="!text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0 mt-4 py-3"
          hidering={true}
          placeholder={'Jumlah Data'}
          onChangeCapture={onChangeCaptureInput}
          onKeyDownCapture={onKeyDownCaptureInput}
          label={`input-${label}`}
        />
        <VmView className="grid grid-cols-3 mt-4">
          <VmButton
            className="relative flex justify-center !flex-none p-3 rounded-xs !min-w-[50px] bg-yellow2-payment"
            hidering={true}
            onClick={onSearch}
            label={`btn-${label}`}
            color="bg-yellow2-payment"
          >
            <VmView className="text-white text-xs" label={`btn-${label}`}>
              Cari
            </VmView>
          </VmButton>
        </VmView>
      </VmView>
    </VmView>
  )
}
