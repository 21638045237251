import {VmView} from 'components'
import React, {useEffect, useRef, useState, forwardRef} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {useSearchParams} from 'react-router-dom'
import {VmCheckIcon} from 'components/icons/solid'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'
import { getLclStorage, setLclStorage } from 'utils/localstorage'
registerLocale('id', id)

const {REACT_APP_BASE_URL_BPJS: APIBPJS} = process.env

function SearchFaskesRujukan({
  uid = null,
  jenisFaskes = null,
  kdsubspesialis = null,
  kdkhusus = null,
  kdsarana = null,
  noKartu = null,
  tglEstRujuk = null,
  setfaskesrujukan = null,
  onChangeData = () => ({}),
  onCloseModal = () => ({}),
}) {
  const fieldsDesktop = [
    {
      key: 'nmppk',
      label: 'Faskes',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'kelas',
      label: 'Kelas',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'nmkc',
      label: 'Kantor Cabang',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'alamatPpk',
      label: 'Alamat',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'telpPpk',
      label: 'Telepon',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'distance',
      label: 'Jarak',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'jmlRujuk',
      label: 'Total Rujukan',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'kapasitas',
      label: 'Kapasitas',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'persentase',
      label: '(%)',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
    {
      key: 'jadwal',
      label: 'Jadwal',
      sorter: false,
      filterType: 'none',
      _style: {fontWeight: 'bold', className: 'text-[#3b82f6]'},
    },
  ]

  const navigate = useNavigate()
  const params = useParams()
  const alertyesno = useRef()
  const alertok = useRef()
  const [searchParams, setSearchParams] = useSearchParams()

  const [limit, setlimit] = useState(10) // Limit data per pag)
  const [data, setdata] = useState([])
  const [itemsCount, setitemsCount] = useState(0)

  const [fetchingMore, setfetchingMore] = useState(false)
  const [gettingInitialData, setgettingInitialData] = useState(true)
  const [hasMoreItems, sethasMoreItems] = useState(true)

  // Device-nya hanya dibuat mobile, karena registrasi pasien via web memang hanya diakses lewat desktop
  const [device, setdevice] = useState('web') // Bisa Web (Tabel) / Mobile (List)
  const [cari, setcari] = useState('') // Value pencarian use)

  // Filter untuk pencarian web
  const [sort, setsort] = useState('')
  const [activePage, setactivePage] = useState(1)
  const [refreshcount, setrefreshcount] = useState(0)
  const [faskesPcare, setFaskesPcare] = useState([])

  useEffect(() => {
    loadFaskesRujukan()
    // return () => {}
  }, [
    // activePage,
    // sort,
    // refreshcount,
    uid,
    jenisFaskes,
    kdsubspesialis,
    kdkhusus,
    kdsarana,
    noKartu,
    tglEstRujuk,
  ])

  const loadFaskesRujukan = async => {
    const lname = 'dataBpjs_faskes'
    const dataFaskes = getLclStorage(lname, {type:2})
    const ldataFaskes = dataFaskes ? JSON.parse(dataFaskes) : []

    console.log(ldataFaskes, 'ldataFaskes')

    setgettingInitialData(true)
    // if (ldataFaskes) {
    //   // console.log(ldataFaskes, 'ldataFaskes')
    //   setFaskesPcare(ldataFaskes)
    //   setitemsCount(ldataFaskes?.length)

    //   if (ldataFaskes?.length < 10) {
    //     sethasMoreItems(false)
    //   } else {
    //     sethasMoreItems(true)
    //   }

    //   setgettingInitialData(false)
    // } else {
    // setgettingInitialData(true)
    axios
      .post(
        `${APIBPJS}/bpjs-jkn/get-faskes-rujukan-pcare`,
        QueryString.stringify({
          uid: uid,
          reg: 'dbdok',
          a: 'dk13',
          type: 'faskes',
          jenisRujukan: jenisFaskes,
          subSpesialis: kdsubspesialis,
          sarana: kdsarana,
          tglEstRujuk: tglEstRujuk,
          noKartu: noKartu,
          kdKhusus: kdkhusus,
          // data: [{
          //     subSpesialis : kdsubspesialis,
          //     sarana : kdsarana,
          //     tglEstRujuk : tglEstRujuk,
          //     noKartu : noKartu,
          //     kdKhusus : kdkhusus,
          // }]
        })
      )
      .then(({data}) => {
        console.log(data, 'loadFaskesRujukan')
        if (data?.metaData?.code == 200) {
          setFaskesPcare(data?.response?.list)
          setitemsCount(data?.response?.count)

          if (data?.response?.count < 10) {
            sethasMoreItems(false)
          } else {
            sethasMoreItems(true)
          }

          setLclStorage(lname, JSON.stringify(data?.response?.list), {type:2})
          setgettingInitialData(false)

        } else {
          console.log('Data tidak ditemukan')
          setgettingInitialData(false)
        }
      })
      .catch(e => {
        console.log('Gagal mengambil data')
      })
      .finally(() => {
        setgettingInitialData(false)
      })
    // }
  }

  const fetchMore = () => {}

  const pilihFaskes = item => {
    console.log(item, 'pilihFaskes')

    const event = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      keyCode: 27,
      which: 27,
      bubbles: true,
    })

    setfaskesrujukan({kdppk: item?.kdppk, nmppk: item?.nmppk})
    onChangeData({faskesrujukan: {kdppk: item?.kdppk, nmppk: item?.nmppk}})

    document.dispatchEvent(event)
  }

  const refreshAfterActivateOrDelete = () => {
    setrefreshcount(prev => prev + 1)
  }

  return (
    <VmView>
      <AlertYesNo ref={alertyesno} />
      <AlertOk ref={alertok} />
      <VmIndexRead
        headerTitle={'Data Faskes Rujukan'}
        items={faskesPcare}
        itemsCount={itemsCount}
        fields={fieldsDesktop} // Karena device sudah pasti dari web, maka gunakan fieldDesktop langsung.
        listItemContainerClassName={'p-4 border shadow-lg m-4 rounded'}
        listSearchbarClassname="mr-2" // Class Untuk inputan filter search bar
        onItemPress={item => {}}
        customButton={item => {
          if (item.kdppk == '') return
          return (
            <div
              onClick={() => {
                pilihFaskes(item)
              }}
              className="flex flex-1 items-center justify-center rounded bg-green-500 ml-2 py-1"
            >
              <VmCheckIcon color="white" height={`${24}px`} width={`${24}px`} />
              <p className="text-white">Pilih</p>
            </div>
          )
        }}
        hasMoreItems={hasMoreItems}
        loadMore={() => {
          if (!fetchingMore && !gettingInitialData) fetchMore()
        }}
        device={device}
        onSearchSubmit={value => {
          setcari(value)
        }}
        /**
         * Parameter Untuk DataTable (Versi Desktop Web)
         */
        loadingFetchData={gettingInitialData}
        totalPage={Math.ceil(itemsCount / 10)}
        activePage={activePage}
        onActivePageChange={page => {
          setactivePage(page)
        }}
        onColumnFilterChange={data => {
          setactivePage(1)
        }}
        onSorterValueChange={sortData => {
          setsort(`${sortData.column} ${sortData.asc ? 'asc' : 'desc'}`)
        }}
        totalCount={itemsCount}
        title="Data Faskes Rujukan"
        onRefreshPress={() => {
          setactivePage(1)
          setsort('')
        }}
        columnFilterValue={{}}
        sorterValue={{column: 'nmppk', asc: true}}
        statusColName="kdppk"
        onActivate={item => pilihFaskes(item)}
        selectionTable
        onSelect={item => {
          console.log('onSelect ', item)
          pilihFaskes(item)
        }}
        backButton
        onBackPress={onCloseModal}
        // showOverTableActions={false}
      />
    </VmView>
  )
}

export default SearchFaskesRujukan
