import React, {createRef, useState, useEffect} from 'react'
import moment from 'moment'
import 'moment/locale/id'

import {
  VmText,
  VmView,
  VmModal,
  VmIcons,
  VmDivider,
  VmInput,
  VmButton,
} from 'components'
import './VmMobileFilter.css'
import {VmDatePicker} from 'molecules'

export default function VmMobileFilter({
  className = '',
  filterFields = [],
  onChange = () => {},
  onClear = () => {},
  dateFilter = true,
  filterValue = {
    filterFieldsValue: [],
    currentOpenedFilter: '',
    activeFilterHead: [],
    activeFilterHeadLabel: '',
    dateFilterFlag: '',
    sortIcon: '',
    activeFilter: '',
    currentHead: '',
    dateValue: '',
    clear: '',
  },
  filterHandler = () => {},
}) {
  const filterButtonStyle =
    'border rounded-xl border-gray-400 text-gray-500 mr-2 px-2 h-10 '
  const filterButtonStyleSelected =
    'border rounded-xl bg-blue-100 border-blue-500 text-blue-500 mr-2 px-2 h-10 '

  // ANCHOR - State UI
  const modalRef = createRef()

  // penampung list item filter
  const [filterFieldsValue, setFilterFieldsValue] = useState(
    filterValue.filterFieldsValue ?? []
  )

  const [currentOpenedFilter, setCurrentOpenedFilter] = useState(
    filterValue.currentOpenedFilter ?? ''
  )

  // state tampilan aktif/tidak aktif item filter
  const [activeFilterHead, setActiveFilterHead] = useState(
    filterValue.activeFilterHead ?? []
  )
  const [activeFilterHeadTemp, setActiveFilterHeadTemp] = useState([])

  // state untuk item filter yang di select
  const [activeFilterHeadLabel, setActiveFilterHeadLabel] = useState(
    filterValue.activeFilterHeadLabel ?? ''
  )
  const [activeFilterHeadLabelTemp, setActiveFilterHeadLabelTemp] = useState('')

  const [dateFilterFlag, setDateFilterFlag] = useState(
    filterValue.dateFilterFlag ?? ''
  )
  const [dateFilterFlagTemp, setDateFilterFlagTemp] = useState('')

  const [sortIcon, setSortIcon] = useState(filterValue.sortIcon ?? '')
  const [sortIconTemp, setSortIconTemp] = useState('')

  const [dateValue, setDateValue] = useState(filterValue.dateValue ?? '')
  const [dateValueTemp, setDateValueTemp] = useState('')

  // ANCHOR - State Data
  const [activeFilter, setActiveFilter] = useState(
    filterValue.activeFilter ?? ''
  )
  const [activeFilterTemp, setActiveFilterTemp] = useState('')

  // state untuk menentukan key dari object value filternya
  const [currentHead, setCurrentHead] = useState(filterValue.currentHead ?? '')

  const isClearAll =
    !dateFilterFlagTemp || dateFilterFlagTemp?.semua ? true : false

  const handleFilterItem = (headFilter, label, value, flag, dbColumn) => {
    if (headFilter === 'Semua Periode') {
      const initialDateValue =
        flag === 'bulan' || flag === 'tahun'
          ? new Date()
          : {start: new Date(), end: new Date()}

      const initialDateLabel =
        flag === 'bulan'
          ? moment().format('MMM-YYYY')
          : flag === 'tahun'
          ? moment().format('YYYY')
          : `${moment().format('DD-MMM-YYYY')} sd ${moment().format(
              'DD-MMM-YYYY'
            )}`

      const dateType = flag === 'bulan' ? 2 : flag === 'tahun' ? 1 : 3

      setDateFilterFlagTemp({[flag]: true})
      setActiveFilterHeadLabelTemp(s => ({
        ...s,
        [headFilter]: initialDateLabel,
      }))
      setActiveFilterTemp(s => ({
        ...s,
        date:
          flag === 'tanggal'
            ? {date: initialDateValue}
            : {[flag]: initialDateValue},
        dateType,
      }))
      setActiveFilterHeadTemp(s => [...s, headFilter])
      setDateValueTemp(
        flag === 'bulan' || flag === 'tahun'
          ? {datePickerValue: new Date()}
          : {dateStart: new Date(), dateEnd: new Date()}
      )
    } else {
      setActiveFilterHeadLabelTemp(s => ({...s, [headFilter]: label}))
      setActiveFilterHeadTemp(s => [...s, headFilter])

      if (headFilter === 'Sorting') {
        setActiveFilterTemp(s => ({...s, sort: value}))
      } else {
        setActiveFilterTemp(s => ({...s, [dbColumn]: value}))
      }
    }
  }

  const handleDatePicker = (
    headFilter,
    label,
    rawValue,
    dateType = activeFilterTemp?.dateType || ''
  ) => {
    setActiveFilterHeadTemp(s =>
      s.includes(headFilter) ? s : [...s, headFilter]
    )
    setActiveFilterHeadLabelTemp(s =>
      s[headFilter] !== label ? {...s, [headFilter]: label} : s
    )
    setActiveFilterTemp(s =>
      s.date !== rawValue ? {...s, date: rawValue, dateType} : {...s, dateType}
    )
  }

  const handleSort = (item, dbColumn) => {
    const getSortValue = (comparator, dbColumn) => {
      if (!comparator) {
        return 'ASC'
      } else {
        const comp = comparator.replace(dbColumn, '').trim()
        if (comparator === 'ASC' || comp === 'ASC') return 'DESC'
        if (comparator === 'DESC' || comp === 'DESC') return 'ASC'
        return 'ASC'
      }
    }
    setSortIconTemp({[item]: getSortValue(sortIconTemp[item], dbColumn)})
    setActiveFilterTemp({
      ...activeFilterTemp,
      sort: `${dbColumn} ${getSortValue(activeFilterTemp.sort, dbColumn)}`,
    })
  }

  const handleParentState = () => {
    filterHandler({
      filterFieldsValue,
      currentOpenedFilter,
      activeFilterHead: activeFilterHeadTemp,
      activeFilterHeadLabel: activeFilterHeadLabelTemp,
      dateFilterFlag: dateFilterFlagTemp,
      sortIcon: sortIconTemp,
      activeFilter: activeFilterTemp,
      currentHead,
      dateValue: dateValueTemp,
      clear: isClearAll,
    })
  }

  const openModal = (val, dbColumn) => {
    setCurrentOpenedFilter(val)
    syncTemp()
    setCurrentHead(dbColumn)
    modalRef?.current?.open()
  }

  const applyFilter = () => {
    handleParentState()
    onChange({...activeFilterTemp, clear: isClearAll})
    modalRef?.current?.close()
  }

  const initDefaultDateFilter = () => {
    const initialDateStart = filterValue?.dateValue?.dateStart
      ? moment(filterValue?.dateValue?.dateStart).format('DD-MMM-YYYY')
      : moment().format('DD-MMM-YYYY')
    const initialDateEnd = filterValue?.dateValue?.dateStart
      ? moment(filterValue?.dateValue?.dateEnd).format('DD-MMM-YYYY')
      : moment().format('DD-MMM-YYYY')
    const initialYearMonth = dateFilterFlag?.tahun
      ? moment(filterValue?.dateValue?.datePickerValue).format('YYYY')
      : moment(filterValue?.dateValue?.datePickerValue).format('MMM-YYYY')

    let initFlag = ''
    if (dateFilterFlag?.tahun) {
      initFlag = {tahun: true}
    } else if (dateFilterFlag?.bulan) {
      initFlag = {bulan: true}
    } else {
      initFlag = {tanggal: true}
    }

    setActiveFilterHead(s => [...s, 'Semua Periode'])
    setActiveFilterHeadLabel(s => ({
      ...s,
      'Semua Periode': filterValue?.dateValue?.datePickerValue
        ? initialYearMonth
        : `${initialDateStart} sd ${initialDateEnd}`,
    }))
    setDateFilterFlag(initFlag)
  }

  const clearFilter = (headFilter, dbColumn) => {
    if (headFilter === 'Semua Periode') {
      setDateFilterFlagTemp({semua: true})
      setDateValueTemp('')
    }
    setActiveFilterHeadTemp(s => s.filter(item => item !== headFilter))
    setActiveFilterHeadLabelTemp(s => ({...s, [headFilter]: ''}))
    setActiveFilterTemp(s => {
      if (headFilter === 'Semua Periode') {
        return {...s, date: {bulan: '', tahun: '', start: '', end: ''}}
      } else {
        return {...s, [dbColumn]: ''}
      }
    })
  }

  const clearAllFilter = () => {
    setCurrentOpenedFilter('')
    setCurrentHead('')
    setActiveFilter('')
    setActiveFilterHead('')
    setActiveFilterHeadLabel('')
    setDateFilterFlag('')
    setSortIcon('')
    setCurrentHead('')
    setDateValue('')
    onClear()
    clearTemp()
    filterHandler({
      filterFieldsValue: [],
      currentOpenedFilter: '',
      activeFilterHead: [],
      activeFilterHeadLabel: '',
      dateFilterFlag: '',
      sortIcon: '',
      activeFilter: '',
      currentHead: '',
      dateValue: '',
      clear: true,
    })
  }

  const clearTemp = () => {
    setActiveFilterTemp('')
    setActiveFilterHeadTemp('')
    setActiveFilterHeadLabelTemp('')
    setDateFilterFlagTemp('')
    setSortIconTemp('')
    setDateValueTemp('')
  }

  const syncTemp = () => {
    setActiveFilterTemp(filterValue.activeFilter ?? activeFilter)
    setActiveFilterHeadTemp(filterValue.activeFilterHead ?? activeFilterHead)
    setActiveFilterHeadLabelTemp(
      filterValue.activeFilterHeadLabel ?? activeFilterHeadLabel
    )
    setDateFilterFlagTemp(filterValue.dateFilterFlag ?? dateFilterFlag)
    setSortIconTemp(filterValue.sortIcon ?? sortIcon)
    setDateValueTemp(filterValue.dateValue ?? dateValue)
  }

  useEffect(() => {
    setFilterFieldsValue(() => {
      if (dateFilter)
        return [
          {
            'Semua Periode': [
              {value: '', label: 'Berdasarkan Tahun', flag: 'tahun'},
              {value: '', label: 'Berdasarkan Bulan', flag: 'bulan'},
              {value: '', label: 'Berdasarkan Tanggal', flag: 'tanggal'},
            ],
          },
          ...filterFields,
        ]
    })

    if (!filterValue.clear) {
      initDefaultDateFilter()
      moment.locale('id')
    }
  }, [])

  return filterFields?.length > 0 ? (
    <>
      <VmView
        className={
          'sticky flex flex-row bg-white w-[-webkit-fill-available] items-start flex-nowrap whitespace-nowrap ' +
          className
        }
      >
        {/* ANCHOR Clear All Filter */}
        {activeFilterHead && activeFilterHead.length > 0 ? (
          <VmView className="cursor-pointer">
            <VmView
              className={filterButtonStyle + '!border-red-500 bg-red-100'}
              onClick={clearAllFilter}
            >
              <VmIcons
                name="VmXMarkIcon"
                size={24}
                color="#ef4444"
                className="mt-1.5"
              />
            </VmView>
          </VmView>
        ) : null}

        {/* ANCHOR item filter */}
        <VmView className="vmMobileFilterHideScroll flex flex-row overflow-x-auto">
          {filterFieldsValue.map((item, i) => {
            const label = Object.keys(item)[0]
            return (
              <VmView key={i} className="flex flex-row cursor-pointer">
                <VmView
                  className={
                    (activeFilterHead.includes(label)
                      ? filterButtonStyleSelected
                      : filterButtonStyle) + 'flex flex-row items-center'
                  }
                  onClick={() => openModal(label, item.dbColumn)}
                >
                  {/* ANCHOR Sorting */}
                  {label === 'Sorting' ? (
                    <>
                      <VmText id="filterText" className="mr-1">
                        {activeFilterHeadLabel[label]
                          ? activeFilterHeadLabel[label]
                          : label}
                      </VmText>
                      <VmIcons
                        name={
                          !sortIcon
                            ? 'VmBarsArrowDownIcon'
                            : sortIcon && Object.values(sortIcon)[0] === 'ASC'
                            ? 'VmBarsArrowDownIcon'
                            : 'VmBarsArrowUpIcon'
                        }
                        size={25}
                        color={
                          activeFilterHead.includes(label)
                            ? '#3b82f6'
                            : 'rgb(107, 114, 128)'
                        }
                      />
                    </>
                  ) : (
                    <>
                      <VmText id="filterText" className="mr-1">
                        {activeFilterHeadLabel[label]
                          ? activeFilterHeadLabel[label]
                          : label}
                      </VmText>
                      <VmIcons
                        size={20}
                        name="VmChevronDownIcon"
                        color={
                          activeFilterHead.includes(label)
                            ? '#3b82f6'
                            : 'rgb(107, 114, 128)'
                        }
                      />
                    </>
                  )}
                </VmView>
              </VmView>
            )
          })}
        </VmView>
      </VmView>

      {/* ANCHOR Modal dan Backdrop */}
      <VmModal
        ref={modalRef}
        position="bottom"
        classNameContent="!left-0 !right-0 !bottom-0 !rounded-t-xl !rounded-b-none !overflow-auto z-50"
      >
        {/* ANCHOR Header Modal */}
        <VmView className="flex flex-row mt-2 items-center">
          <VmIcons
            name="VmXMarkIcon"
            size={22}
            color="#374151"
            onClick={() => modalRef?.current?.close()}
          />

          <VmText className="text-gray-700 font-bold ml-2 self-center">{`${
            currentOpenedFilter === 'Sorting' ? '' : 'Filter'
          } ${currentOpenedFilter.replace('Semua', '')}`}</VmText>
        </VmView>
        <VmView className="mt-5 mb-20">
          {/* ANCHOR Clear Filter */}
          {currentOpenedFilter === 'Sorting' ? null : (
            <ModalItem
              onClick={() => clearFilter(currentOpenedFilter, currentHead)}
              label={currentOpenedFilter}
              name="radioItems"
              checked={activeFilterHeadLabelTemp}
              currentOpenedFilter={currentOpenedFilter}
              currentOpenedDateFilter={dateFilterFlagTemp}
              flag="semua"
            />
          )}

          {filterFieldsValue.map(headItem =>
            headItem[currentOpenedFilter]
              ? headItem[currentOpenedFilter].map((item, i) => {
                  return (
                    <ModalItem
                      name="radioItems"
                      key={i}
                      onClick={() => {
                        if (
                          item.label ===
                            activeFilterHeadLabel[currentOpenedFilter] ||
                          (item?.value &&
                            item.value === activeFilter[currentOpenedFilter]) ||
                          currentOpenedFilter === activeFilterHead
                        )
                          return

                        handleFilterItem(
                          currentOpenedFilter,
                          item.label,
                          item.value,
                          item?.flag,
                          currentOpenedFilter === 'Sorting'
                            ? item?.dbColumn
                            : headItem.dbColumn
                        )
                      }}
                      label={item.label}
                      checked={activeFilterHeadLabelTemp}
                      currentOpenedFilter={currentOpenedFilter}
                      currentOpenedDateFilter={dateFilterFlagTemp}
                      flag={item?.flag}
                      onChangeDate={(val, rawVal, dateType) =>
                        handleDatePicker(
                          currentOpenedFilter,
                          val,
                          rawVal,
                          dateType
                        )
                      }
                      sortIcon={sortIconTemp}
                      onSortChange={() => {
                        if (currentOpenedFilter === 'Sorting')
                          handleSort(item.label, item.dbColumn)
                      }}
                      dateValue={dateValueTemp}
                      dateHandler={setDateValueTemp}
                    />
                  )
                })
              : null
          )}
        </VmView>
        <VmButton
          color="primary"
          className="absolute bottom-0 left-0 right-0 m-3 p-3 rounded-xl"
          onClick={applyFilter}
        >
          Terapkan
        </VmButton>
      </VmModal>
    </>
  ) : null
}

const ModalItem = ({
  onClick = () => {},
  onChangeDate = () => {},
  onSortChange = () => {},
  label = '',
  name = '',
  checked = false,
  currentOpenedFilter = '',
  currentOpenedDateFilter = '',
  flag = '',
  sortIcon = '',
  dateValue = {
    dateStart: '',
    dateEnd: '',
    datePickerValue: '',
  },
  dateHandler = () => {},
}) => {
  const {
    dateStart = new Date(),
    dateEnd = new Date(),
    datePickerValue = new Date(),
  } = dateValue

  useEffect(() => {
    dateHandler({...dateValue})
  }, [])

  return (
    <>
      <VmView
        onClick={() => {
          onClick()
          if (currentOpenedFilter === 'Sorting') onSortChange()
        }}
      >
        <VmView className="flex flex-row items-center justify-between">
          <VmText className="mb-2 mr-2 text-gray-700">{label}</VmText>

          {/* ANCHOR - radio button */}
          {currentOpenedFilter === 'Sorting' ? null : (
            <VmInput
              checked={
                // logic khusus item selain periode
                (checked[currentOpenedFilter] &&
                  checked[currentOpenedFilter] === label) ||
                // logic khusus jika item yang dipilih adalah SEMUA
                (!checked[currentOpenedFilter] &&
                  currentOpenedFilter === label) ||
                Object.keys(currentOpenedDateFilter)[0] === flag
                  ? true
                  : false
              }
              name={name}
              typeInput="radio"
              className="mb-2 accent-blue-500 focus:accent-blue-500 hover:accent-blue-700"
            />
          )}

          {/* ANCHOR - sorting item */}
          {currentOpenedFilter === 'Sorting' ? (
            <VmIcons
              name={
                !sortIcon[label]
                  ? 'VmArrowDownCircleIcon'
                  : sortIcon[label] === 'ASC'
                  ? 'VmArrowDownCircleIcon'
                  : 'VmArrowUpCircleIcon'
              }
              size={25}
              color={sortIcon[label] ? '#3b82f6' : 'rgb(107, 114, 128)'}
            />
          ) : null}
        </VmView>
      </VmView>

      {/* ANCHOR Date Picker */}
      {currentOpenedFilter === 'Semua Periode' &&
      label !== 'Semua Periode' &&
      currentOpenedDateFilter[flag] ? (
        <VmView className="pb-2">
          {flag === 'tahun' ? (
            <VmDatePicker
              year
              selected={datePickerValue}
              onChange={val => {
                dateHandler({datePickerValue: val, dateType: 1})
                onChangeDate(moment(val).format('YYYY'), {tahun: val}, 1)
              }}
            />
          ) : null}
          {flag === 'bulan' ? (
            <VmDatePicker
              month
              selected={datePickerValue}
              onChange={val => {
                dateHandler({datePickerValue: val, dateType: 2})
                onChangeDate(moment(val).format('MMM-YYYY'), {bulan: val}, 2)
              }}
            />
          ) : null}
          {flag === 'tanggal' ? (
            <VmView className="flex flex-row flex-nowrap overflow-x-auto items-center py-2 px-1">
              <VmDatePicker
                selected={dateStart}
                format="dd-MMM-yyyy"
                onChange={val => {
                  onChangeDate(
                    `${moment(val).format('DD-MMM-YYYY')} sd ${moment(
                      dateEnd
                    ).format('DD-MMM-YYYY')}`,
                    {start: val, end: dateEnd},
                    3
                  )
                  dateHandler(s => ({...s, dateStart: val, dateType: 3}))
                }}
              />
              <VmText className="font-bold mx-3">-</VmText>
              <VmDatePicker
                selected={dateEnd}
                format="dd-MMM-yyyy"
                onChange={val => {
                  onChangeDate(
                    `${moment(dateStart).format('DD-MMM-YYYY')} sd ${moment(
                      val
                    ).format('DD-MMM-YYYY')}`,
                    {start: dateStart, end: val},
                    3
                  )
                  dateHandler(s => ({...s, dateEnd: val, dateType: 3}))
                }}
              />
            </VmView>
          ) : null}
        </VmView>
      ) : null}
      <VmView className="py-2">
        <VmDivider />
      </VmView>
    </>
  )
}
