import {useMemo, useEffect} from 'react'
import {useParams, useLocation, useNavigate, Navigate} from 'react-router-dom'
import controllers from 'controllers/Cashier'
import FormPembayaran from './FormPembayaran'

const FormPembayaranIndex = () => {
  const pageTitle = 'Pembayaran'
  const navigate = useNavigate()
  const {search, pathname} = useLocation()
  const useQuery = () =>
    useMemo(
      () => new URLSearchParams(search),
      // eslint-disable-next-line
      [search]
    )
  const params = useParams()
  const query = useQuery()

  useEffect(() => {
    document.title = pageTitle
  }, [])

  /**
   * phone = Mobile
   * web = Tablet
   * desktop = Praktik Versi Desktop
   */
  if (!['phone', 'web', 'desktop'].includes(params?.mode)) {
    return <Navigate to={'/404'} replace />
  }
  return (
    <FormPembayaran
      controllers={controllers}
      navigate={navigate}
      params={params}
      pathname={pathname}
      queryParams={query}
      pageTitle={pageTitle}
    />
  )
}

export default FormPembayaranIndex
