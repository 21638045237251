import {VmButton, VmIcons, VmSpinner, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import Searchbar from './Searchbar'
import CardItem from './CardItem'
import {eventTrigerInput, isFunction} from 'utils'
import {getElement} from 'utils/get-element'
import usePullToRefresh from 'utils/usePullToRefresh'
import useAddEventListener from 'utils/useAddEventListener'
import {mSearchObat} from './api'
import {useParams} from 'react-router-dom'
import {DataMitraApotek, DataObat, DataPasien} from './classes'
import {generateFlags, useCarts} from './utils'
import between from 'utils/between'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'

export default function SearchObat({
  HeaderType = 'Pembuatan Resep',
  labelBackButton = 'modal-back-button',
  datamitra = new DataMitraApotek({}),
  datapasien = new DataPasien({}),
  keyword: propskeyword = '',
  optcarts = {
    name: 'pj-resep-carts',
    flags: 'flagcart',
    flagstok: 'obatid',
  },
  fieldkey = ['obatid', 'sodid'],
  cartracikan = false,
  ...props
}) {
  const showlog = false,
    contentRef = useRef(),
    limit = 10
  const dctid = parseInt(useParams().id)
  const [offset, setoffset] = useState(0)
  const [keyword, setkeyword] = useState(propskeyword)
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  const [loadmore, setloadmore] = useState(true)
  const [onloadmore, setonloadmore] = useState(true)
  const [data, setdata] = useState([])
  const [params, setparams] = useState({
    log: showlog,
    input: {
      id: dctid,
      limit,
      offset,
      a: datamitra?.defaultdata?.appid_apotek || '',
      reg: datamitra?.defaultdata?.regid_apotek,
      pasid: datapasien?.defaultdata?.pasid || '',
      carimobile: keyword,
      'show-error-log': `${showlog}`.toUpperCase(),
    },
  })
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const {
    cart,
    carts,
    cartstoks,
    addtocarts,
    edititemcarts,
    removetocarts,
    setcarts,
  } = useCarts(optcarts)
  const newdata = useMemo(() => {
    // Filter qty data in cart
    const redata = data.map(im => {
      const flags = generateFlags(im?.defaultdata, fieldkey)
      if (cart[flags]) {
        return cart[flags]
      }
      return new DataObat(im?.defaultdata)
    })
    return redata
  }, [carts, cart, data, fieldkey])

  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const onBackPress = () => {
    if (isFunction(props?.close)) {
      props?.close()
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel)
      }
      if (`${ariaLabel}`?.match(/^showsatuan-k-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `showsatuan-k-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) finsInputEl.click()
        return
      }
      if (`${ariaLabel}`?.match(/^delete-item-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `delete-item-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) finsInputEl.click()
        return
      }
      if (`${ariaLabel}`?.match(/^plus-item-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `plus-item-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) finsInputEl.click()
        return
      }
      if (`${ariaLabel}`?.match(/^minus-item-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `minus-item-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) finsInputEl.click()
        return
      }
      if (`${ariaLabel}`?.match(/^add-item-modal-obat/i)) {
        const idx = `${ariaLabel}`.split('-##-')[1]
        const item = data[idx]
        const konversi = parseFloat(item?.defaultdata?.sodhasilkonversi || 1)
        if (!!cartstoks[item[optcarts.flagstok]] && !cartracikan) {
          const {sisastok, stok, qtyincart} = cartstoks[item[optcarts.flagstok]]
          if (sisastok - 1 * (konversi ? konversi : 1) < 0) {
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  Stok obat <b>{item?.name}</b> tidak mencukupi, sisa stok ={' '}
                  <b>
                    {stok} {item?.satuan1}
                  </b>
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
            })
          }
        }
        const newitem = {
          ...item,
          qty: 1,
          flagcart: generateFlags(item?.defaultdata, fieldkey),
        }
        data[idx] = newitem
        return addtocarts(newitem)
      }
      if (ariaLabel === 'reset-modal-search-obat') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-modal-search-obat',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-modal-search-obat',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-modal-search-obat') {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onClickError', error)
      }
    }
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-modal-search-obat') {
        return setkeyword(value)
      }
      if (`${ariaLabel}`?.match(/^input-qty-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `input-qty-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal,
        })
        return Promise.all([eventTrigerInput(finsInputEl, value)]).finally(
          () => {
            var newtimeout = null
            newtimeout = setTimeout(() => {
              e.target.value = finsInputEl.value
              clearTimeout(newtimeout)
            }, 100)
          }
        )
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-modal-search-obat' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const fetchData = (params = {}) => {
    seterror(null)
    setparams(params)
    mSearchObat(params)
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          if (params?.input?.offset === 0) {
            setdata(resdata?.data)
          } else {
            setdata([...newdata, ...resdata.data])
          }
          if (resdata?.data?.length < limit) {
            setloadmore(false)
          }
        } else {
          if (loadmore) {
            seterror(resdata?.message || 'Gagal mengambil data!')
          }
          setloadmore(false)
        }
        if (showlog) console.log('fetchData:data', data)
      })
      .catch(ch => {
        if (showlog) console.log('fetchData:ch', ch)
        setloadmore(false)
        seterror('[C0] Gagal mengambil data!')
      })
      .finally(() => {
        if (showlog) console.log('fetchData:final')
        setloading(false)
        setonloadmore(false)
      })
  }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        carimobile: keyword,
      },
    })
  }
  const onFetchMore = () => {
    if (data.length > 0 && loadmore && !onloadmore) {
      setonloadmore(true)
      return fetchData({
        ...params,
        input: {
          ...params.input,
          offset: params.input.offset + limit,
        },
      })
    }
  }
  const onEndReached = el => {
    const isBottom = el?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      onFetchMore()
    }
  }
  const calcTopZero = elm => {
    return between(elm?.getBoundingClientRect()?.top - elm?.offsetTop, -5, 5)
      ? 0
      : 1
  }
  const {pullChange} = usePullToRefresh({
    topTarget: calcTopZero(contentRef.current?.children[1]),
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-modal-search-obat',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(params)
    return () => {}
  }, [])
  useEffect(() => {
    const finsInputEl = getElement({
      tag: 'input',
      by: 'ariaLabel',
      equal: 'input-modal-search-obat',
    })
    setkeyword(propskeyword)
    finsInputEl.value = propskeyword
    var newtimeout = null
    newtimeout = setTimeout(() => {
      const finsInputEl = getElement({
        tag: 'button',
        by: 'ariaLabel',
        equal: 'btn-modal-search-obat',
      })
      finsInputEl?.click()
      clearTimeout(newtimeout)
    }, 100)
    return () => {}
  }, [propskeyword])

  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  return (
    <VmView className="flex flex-col absolute inset-0 bg-blue4-payment px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={HeaderType}
          onBackPress={onBackPress}
          labelBackButton={labelBackButton}
        />
        {/* Searchbar */}
        <VmView className="flex flex-row h-auto justify-between">
          <VmView className="flex flex-col w-full max-w-[-webkit-fill-available] h-auto">
            <Searchbar keyword={keyword} label="modal-search-obat" />
          </VmView>
          <VmView className="md:flex hidden flex-col h-auto ml-2">
            <VmButton className={'!bg-red-500 py-1.5'} onClick={onBackPress}>
              Kembali
            </VmButton>
          </VmView>
        </VmView>
        {/* Content */}
        <VmView
          ref={contentRef}
          onScroll={onScroll}
          className="block overflow-y-scroll"
        >
          <VmView
            className="refresh-container w-fit -mt-10 m-auto"
            style={{marginTop: pullChange / 3.118 || ''}}
          >
            <VmView className="refresh-icon p-2 rounded-full">
              <VmIcons
                name="VmArrowPathIcon"
                style={{transform: `rotate(${pullChange}deg)`}}
                size={25}
              />
            </VmView>
          </VmView>
          {newdata.map((im, index) => {
            return (
              <CardItem
                label="-modal-obat"
                key={`CardItem-${index}`}
                index={index}
                data={im}
                cartracikan={cartracikan}
              />
            )
          })}
          {!loadmore ? (
            <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
              {error ? error : 'Sudah tidak ada data'}
            </VmView>
          ) : (
            <VmView
              key={'loadmore'}
              label="loadmore"
              className="relative self-center text-center text-gray2-payment last:mb-20 "
            >
              <VmSpinner className="text-blue1-payment" />
            </VmView>
          )}
        </VmView>
        {/* AlertForm */}
        <AlertForm {...propsalertform} />
        {/* Loading Full Screen */}
        <ProgressFullScreen visible={loading} />
      </VmView>
    </VmView>
  )
}
