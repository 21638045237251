class DataVisit {
  constructor(props = {}) {
    this.id = props?.kunid ? Number(props?.kunid) : null
    this.pid = props?.pasid ? Number(props?.pasid) : null
    this.status = props?.kunstatus ? Number(props?.kunstatus) : null
    this.number = props?.kunnomer || ''
    this.sequence = props?.kunurut || ''
    this.kunkeluhan = props?.kunkeluhan || ''
    this.date = props?.kuntgl || ''
    this.rm = props?.pasrm || ''
    this.patname = props?.pasnama || ''
    this.sex = props?.pasjk || ''
    this.address = props?.pasalamat || ''
    this.phone = props?.pastelp || ''
    this.born = props?.pastgllahir || ''
    this.defaultdata = props || {}
  }
}
export {DataVisit}
