import {oneOfType, string, func, any, symbol, arrayOf, shape} from 'prop-types'
import {isNull, isUndefined} from './typeof'

const TransitionPropTypeKeys = [
  'in',
  'mountOnEnter',
  'unmountOnExit',
  'appear',
  'enter',
  'exit',
  'timeout',
  'onEnter',
  'onEntering',
  'onEntered',
  'onExit',
  'onExiting',
  'onExited',
]

const FadeProps = [
  ...TransitionPropTypeKeys,
  'baseClass',
  'baseClassActive',
  'tag',
]

const canUseDOM = !!(
  !isUndefined(window) &&
  window.document &&
  window.document.createElement
)

const targetPropType = oneOfType([
  string,
  func,
  DOMElement,
  shape({current: any}),
])

const tagPropType = oneOfType([
  func,
  string,
  shape({$$typeof: symbol, render: func}),
  arrayOf(oneOfType([func, string, shape({$$typeof: symbol, render: func})])),
])

function DOMElement(props, propName, componentName) {
  if (!(props[propName] instanceof Element)) {
    return new Error(
      'Invalid prop `' +
        propName +
        '` supplied to `' +
        componentName +
        '`. Expected prop to be an instance of Element. Validation failed.'
    )
  }
}

function deprecated(propType, explanation) {
  return function validate(props, propName, componentName, ...rest) {
    if (!isNull(props[propName]) && !isUndefined(props[propName])) {
      console.error(
        `"${propName}" property of "${componentName}" has been deprecated.\n${explanation}`
      )
    }
    return propType(props, propName, componentName, ...rest)
  }
}

const eventTrigerInput = (
  inputElement = null,
  value = '',
  options = {bubbles: true},
  eventType = 'input'
) => {
  if (!inputElement) {
    throw new TypeError(`Is not valid id`)
  }
  try {
    var input = inputElement
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value'
    ).set
    nativeInputValueSetter.call(input, value)
    var ev2 = new Event(eventType, options)
    input.dispatchEvent(ev2)
  } catch (error) {
    throw new TypeError(error)
  }
}

const handlerResponse = (statusCode = 200, body, logUser = null) => {
  return {
    statusCode,
    body: JSON.stringify(body),
    data: body,
    logUser: logUser,
  }
}

export {
  TransitionPropTypeKeys,
  FadeProps,
  canUseDOM,
  targetPropType,
  tagPropType,
  DOMElement,
  deprecated,
  eventTrigerInput,
  handlerResponse,
}
