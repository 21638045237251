import React, {Fragment, useEffect, useMemo, useState} from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'

import {VmButton, VmSpinner, VmText, VmView} from 'components'
import {ItemCard, Items, defaultClassNames} from 'template/Form/HandlerForm'
import classNames from 'classnames'

const [containerClassName, titleClassName, classItem, classSparator] =
  defaultClassNames
export default function FakturRm({
  uid = null,
  pid = null,
  kid = null,
  rmid = null,
  rdata = {},
  lock = false,
  disabled = false,
  mode = 'web', // web | phone
  tindakanVisible = true,
  resepVisible = true,
  rujukanVisible = true,
}) {
  const navigate = useNavigate()
  const initialApiParams = {
    a: 'dk13',
    reg: 'dbdok',
    uid,
    pid,
    kid,
    rmid,
    kunpcarekunjungan: rdata?.kunpcarekunjungan || '',
    date: moment().format('YYYY-MM-DD'),
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    const kunpcarekunjungan = rdata?.kunpcarekunjungan || ''
    if (ariaLabel === 'btn-tambah-tindakan') {
      if(kunpcarekunjungan) return navigate(`/prd-tra-pembayaran/${mode}/${uid}?kid=${kid}&kunpcarekunjungan=${kunpcarekunjungan}`)
      return navigate(`/prd-tra-pembayaran/${mode}/${uid}?kid=${kid}`)
    }
    if (ariaLabel === 'btn-buat-resep') {
      return navigate(`/prd-penjualan-obat-resep/${uid}?kid=${kid}&pid=${pid}`)
    }
    if (ariaLabel === 'btn-tambah-rujukan') {
      return navigate(`/prd-rujukan/create/${uid}/${rmid}`)
    }
    if (ariaLabel === 'btn-update-rujukan') {
      return navigate(`/prd-rujukan/create/${uid}/${rmid}`)
    }
  }

  // UI State
  const [data, setData] = useState({})
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  // Api State
  const [apiParams, setApiParams] = useState(initialApiParams)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [])

  const handleError = (e = '') => {
    setError({e: e ?? '', message: 'Terjadi kesalahan saat mengambil data'})
  }
  const fetchData = async (additionalParams = {}, initial = false) => {
    const prevParams = initial ? {} : apiParams
    const params = qs.stringify({...prevParams, ...additionalParams})
    try {
      setLoading(true)
      const {data} = await axios.post(
        process.env.REACT_APP_BASE_URL_DOKTER + '/prd-lap-rekammedis/faktur-rm',
        params
      )

      if (data.status === 1) {
        setData(data.data)
      } else {
        handleError()
      }
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const listItems = useMemo(() => {
    const newlist = []

    if (tindakanVisible) {
      // Pengecekan Props baru (karena dibuat mode Tab)
      if (data?.billing?.length > 0) {
        newlist.push({
          type: 1,
          title: 'Perawatan / Tindakan',
          items: data.billing,
          button: false,
          btntitle: 'Tambah Tindakan',
          label: 'btn-tambah-tindakan',
        })
      } else {
        newlist.push({
          button: true,
          title: 'Perawatan / Tindakan',
          btntitle: 'Tambah Tindakan',
          label: 'btn-tambah-tindakan',
        })
      }
    }

    if (resepVisible) {
      // Pengecekan Props baru (karena dibuat mode Tab)
      if (data?.resep?.length > 0) {
        newlist.push({
          type: 1,
          title: 'Resep Dokter',
          items: data.resep,
        })
      } else {
        newlist.push({
          button: true,
          title: 'Resep Dokter',
          btntitle: 'Buat Resep',
          label: 'btn-buat-resep',
        })
      }
    }

    if (rujukanVisible) {
      // Pengecekan Props baru (karena dibuat mode Tab)
      if (data?.rujukan?.length > 0) {
        newlist.push({
          type: 3,
          title: 'Rujukan',
          items: data.rujukan,
        })
      } else {
        newlist.push({
          button: true,
          title: 'Rujukan',
          btntitle: 'Tambah Rujukan',
          label: 'btn-tambah-rujukan',
        })
      }
    }
    return newlist
  }, [data, tindakanVisible, resepVisible, rujukanVisible])

  return (
    <Fragment>
      {loading ? (
        <VmView className="flex justify-center items-center mb-5">
          <VmSpinner color="sky-500" />
        </VmView>
      ) : error ? (
        <VmView className="flex justify-center items-center mb-5">
          <VmText>{`${error}`}</VmText>
        </VmView>
      ) : (
        listItems?.length > 0 &&
        listItems.map((im, idx) => {
          const key = `f${im?.title}`
          if (im?.button) {
            return (
              <Fragment key={key}>
                {' '}
                {!disabled ? (
                  <>
                    <VmView className={classNames(classSparator, '')} />
                    <VmView className={containerClassName}>
                      <VmView
                        className={classNames(
                          titleClassName,
                          'flex justify-between items-center'
                        )}
                      >
                        <VmText className="">{im?.title || ''}</VmText>
                      </VmView>
                      <VmView className={containerClassName}>
                        <VmButton
                          className="w-full"
                          onClick={onClick}
                          label={im?.label}
                        >
                          {im?.btntitle}
                        </VmButton>
                      </VmView>
                    </VmView>
                  </>
                ) : (
                  <>
                    <VmView className={classNames(classSparator, '')} />
                    <VmView className={containerClassName}>
                      <VmView
                        className={classNames(
                          titleClassName,
                          'flex justify-between items-center'
                        )}
                      >
                        <VmText className="">{im?.title || ''}</VmText>
                      </VmView>
                      <VmView className={containerClassName}>
                        <VmText className="p-3 text-slate-500">
                          {'Tidak memiliki data ' + (im?.title || '') + '.'}
                        </VmText>
                      </VmView>
                    </VmView>
                  </>
                )}
              </Fragment>
            )
          }
          return (
            <Fragment key={key}>
              <VmView className={classNames(classSparator, '')} />
              <VmView className={containerClassName}>
                <VmView
                  className={classNames(
                    titleClassName,
                    'flex justify-between items-center'
                  )}
                >
                  <VmText className="">{im?.title || ''}</VmText>
                </VmView>
                {!im?.button && im?.label == 'btn-tambah-tindakan' && !disabled && (
                  <VmView className={containerClassName}>
                    <VmButton
                      className="w-full"
                      onClick={onClick}
                      label={im?.label}
                    >
                      {im?.btntitle}
                    </VmButton>
                  </VmView>
                )}
                {im?.type === 1
                  ? im?.items?.map((ims, idxs) => {
                      return (
                        <>
                        <VmView
                          key={`${JSON.stringify({ims})}${idxs}`}
                          className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3"
                        >
                          <VmText className="text-slate-600 font-bold text-base">
                            {ims?.faktur || ''}
                          </VmText>
                          {ims?.detail &&
                            ims?.detail?.map((imd, idxd) => {
                              return (
                                <VmView
                                  key={`${JSON.stringify({imd})}${idxd}`}
                                  className="flex justify-between text-slate-500 font-normal text-base"
                                >
                                  <VmText className="text-break-word">
                                    {imd?.nama || ''}
                                  </VmText>
                                  <VmText className="text-break-word">
                                    {imd?.qty || ''}
                                  </VmText>
                                </VmView>
                              )
                            })}
                        </VmView>
                        </>
                      )
                    })
                  : im?.type === 3
                  ? im?.items?.map((ims, idxs) => {
                      return (
                        <Fragment>
                          <VmView
                            key={`${JSON.stringify({im})}${idxs}`}
                            className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3"
                          >
                            <VmView className="grid grid-cols-2 gap-2">
                              <VmView className="flex flex-col">
                                <VmText className="text-slate-600 font-bold text-base">
                                  {'Tanggal Rujukan'}
                                </VmText>
                                <VmText className="text-slate-600 text-base text-break-word">
                                  {ims?.date
                                    ? moment(ims?.date).format('DD MMM YYYY')
                                    : '-'}
                                </VmText>
                              </VmView>
                              <VmView className="flex flex-col">
                                <VmText className="text-slate-600 font-bold text-base">
                                  {'No. Rujukan'}
                                </VmText>
                                <VmText className="text-slate-600 text-base text-break-word">
                                  {ims?.faktur || ''}
                                </VmText>
                              </VmView>
                            </VmView>
                            <VmView className="flex flex-col">
                              <VmText className="text-slate-600 font-bold text-base">
                                {'Nama Faskes'}
                              </VmText>
                              <VmText className="text-slate-600 text-base text-break-word">
                                {ims?.faskes || ''}
                              </VmText>
                            </VmView>
                            <VmView className="flex flex-col">
                              <VmText className="text-slate-600 font-bold text-base">
                                {'Nama Poli'}
                              </VmText>
                              <VmText className="text-slate-600 text-base text-break-word">
                                {ims?.poli || ''}
                              </VmText>
                            </VmView>
                            <VmView className="flex flex-col">
                              <VmText className="text-slate-600 font-bold text-base">
                                {'Nama Dokter'}
                              </VmText>
                              <VmText className="text-slate-600 text-base text-break-word">
                                {ims?.dokter || ''}
                              </VmText>
                            </VmView>
                            <VmView className="flex flex-col">
                              <VmText className="text-slate-600 font-bold text-base">
                                {'Diagnosa'}
                              </VmText>
                              <VmText className="text-slate-600 text-base text-break-word">
                                {ims?.diagnosa || ''}
                              </VmText>
                            </VmView>
                            <VmView className="flex flex-col">
                              <VmText className="text-slate-600 font-bold text-base">
                                {'Tindakan Yang Sudah Diberikan'}
                              </VmText>
                              <VmText className="text-slate-600 text-base text-break-word">
                                {ims?.tindakan || ''}
                              </VmText>
                            </VmView>
                          </VmView>
                          {!disabled && !lock && (
                            <VmView className={containerClassName}>
                              <VmButton
                                className="w-full"
                                onClick={onClick}
                                label={'btn-update-rujukan'}
                              >
                                {'Ubah Rujukan'}
                              </VmButton>
                            </VmView>
                          )}
                        </Fragment>
                      )
                    })
                  : im?.items?.map((ims, idxs) => {
                      return (
                        <VmView
                          key={`${JSON.stringify({ims})}${idxs}`}
                          className="flex flex-col gap-2 border-2 border-slate-200 rounded-xl m-4 p-3"
                        >
                          <VmText className="text-slate-600 font-bold text-base">
                            {ims?.faktur || ''}
                          </VmText>
                          {ims?.detail && (
                            <ol className="list-decimal list-inside text-slate-500 font-normal text-base">
                              {ims?.detail?.map((imd, idxd) => {
                                return (
                                  <li key={`${JSON.stringify({imd})}${idxd}`}>
                                    {imd?.nama || '-'}
                                  </li>
                                )
                              })}
                            </ol>
                          )}
                        </VmView>
                      )
                    })}
              </VmView>
            </Fragment>
          )
        })
      )}
    </Fragment>
  )
}
