import {epoin} from 'controllers/epoin'
import {modelData} from './model'
import {actionServer} from 'config/server'

const LogAktivitasController = (event, navigate) => {
  const {key, dispatch, _ldata} = event
  const newevent = modelData({key, data: event})
  const actionData = {
    dispatch,
    _ldata,
    key: key,
    useAlert: event?.useAlert,
    epoin: epoin[_ldata],
    data: {input: newevent},
  }
  switch (key) {
    case 'index':
      return actionServer(actionData, navigate, event?.num > 0 ? event.num : 0)
    default:
      break
  }
}

export default LogAktivitasController
