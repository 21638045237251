import {VmIcons, VmView} from 'components'
import {useEffect, useState} from 'react'

export default function usePullToRefresh({
  topTarget = window.scrollY,
  maxPullChange = 220,
  onTop = () => {},
  onRefresh = () => {},
  onFetchMore = () => {},
}) {
  const [startPoint, setStartPoint] = useState(0)
  const [pullChange, setPullChange] = useState()

  const pullStart = e => {
    const {screenY} = e.targetTouches[0]
    setStartPoint(screenY)
  }

  const pull = e => {
    const touch = e.targetTouches[0]
    const {screenY, target} = touch
    if (topTarget === 0 && target.id != 'filterText') {
      let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0
      setPullChange(pullLength)
    }
  }

  const endPull = () => {
    setStartPoint(0)
    setPullChange(0)
    if (pullChange > maxPullChange) onRefresh()
  }

  const onEndReached = endEl => {
    const isBottom =
      endEl?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }

  const onStartReached = startEl => {
    const isTop =
      startEl?.getBoundingClientRect()?.top - startEl?.offsetTop >= 0
    if (isTop) {
      return true
    }
    return false
  }

  const onScroll = e => {
    const {children} = e.target
    const endEl = children[children.length - 1]
    const startEl = children[0]
    const onendreached = onEndReached(endEl)
    const onstartreache = onStartReached(startEl)
    if (onendreached) {
      onFetchMore()
    }
    if (onstartreache) {
      onTop()
    }
  }

  useEffect(() => {
    window.addEventListener('touchstart', pullStart)
    window.addEventListener('touchmove', pull)
    window.addEventListener('touchend', endPull)
    return () => {
      window.removeEventListener('touchstart', pullStart)
      window.removeEventListener('touchmove', pull)
      window.removeEventListener('touchend', endPull)
    }
  })
  return {startPoint, pullChange, onScroll, onEndReached}
}

const RefreshComponen = ({pullChange = 0}) => (
  <VmView
    className="refresh-container w-fit -mt-10 m-auto"
    style={{marginTop: pullChange / 3.118 || ''}}
  >
    <VmView className="refresh-icon p-2 rounded-full">
      <VmIcons
        name="VmArrowPathIcon"
        style={{transform: `rotate(${pullChange}deg)`}}
        size={25}
      />
    </VmView>
  </VmView>
)

export {RefreshComponen}
