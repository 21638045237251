import qs from 'qs'
import axios from 'axios'
import {APIDOC} from 'config/apiurl'

const defError = (
  code = 'EDF',
  message = 'Gagal saat mengambil data, silakan coba kembali!'
) => ({
  status: 0,
  message: `[${code}] ` + message,
})

export const qIndex = async args => {
  try {
    const {data} = await axios.post(
      APIDOC + '/prd-lap-pembayaran-pemeriksaan/index-web',
      qs.stringify(args)
    )

    if (data?.status === 1 || data?.status === 2) return data
    return defError('EPSS-CE')
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}

export const qIndexCount = async args => {
  try {
    const {data} = await axios.post(
      APIDOC + '/prd-lap-pembayaran-pemeriksaan/index-web-count',
      qs.stringify(args)
    )

    if (data?.status === 1 || data?.status === 2) return data
    return defError('EPSS-CE')
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}

export const mExport = async (url, args) => {
  try {
    const {data} = await axios.post(url, qs.stringify(args))
    if (data?.status === 1 || data?.status === 2) return data
    return defError('EPSS-CE')
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}

export const configDetailItems = (args, id) => {
  return {
    defaultData: [],
    url:
      APIDOC + `/prd-lap-pembayaran-pemeriksaan/index-web-list-item?id=${id}`,
    method: 'POST',
    config: {
      data: qs.stringify(args),
    },
  }
}
