import React, {useState} from 'react'
import {VmButton, VmView} from 'components'
import {VmList} from 'components'

const usersAll = [
  {
    id: 0,
    name: 'John Doe',
    registered: '2018/01/01',
    role: 'Guest',
    status: 'warning',
  },
  {
    id: 1,
    name: 'Samppa Nori',
    registered: '2018/01/01',
    role: 'Member',
    status: 'success',
  },
  {
    id: 2,
    name: 'Estavan Lykos',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'danger',
  },
  {
    id: 3,
    name: 'Chetan Mohamed',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'secondary',
  },
  {
    id: 4,
    name: 'Derick Maximinus',
    registered: '2018/03/01',
    role: 'Member',
    status: 'warning',
  },
  {
    id: 5,
    name: 'Friderik Dávid',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'success',
  },
  {
    id: 6,
    name: 'Yiorgos Avraamu',
    registered: '2018/01/01',
    role: 'Member',
    status: 'success',
  },
  {
    id: 7,
    name: 'Avram Tarasios',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'danger',
  },
  {
    id: 8,
    name: 'Quintin Ed',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'secondary',
  },
  {
    id: 9,
    name: 'Enéas Kwadwo',
    registered: '2018/03/01',
    role: 'Member',
    status: 'warning',
  },
  {
    id: 10,
    name: 'Agapetus Tadeáš',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'success',
  },
  {
    id: 11,
    name: 'Carwyn Fachtna',
    registered: '2018/01/01',
    role: 'Member',
    status: 'success',
  },
  {
    id: 12,
    name: 'Nehemiah Tatius',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'danger',
  },
  {
    id: 13,
    name: 'Ebbe Gemariah',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'secondary',
  },
  {
    id: 14,
    name: 'Eustorgios Amulius',
    registered: '2018/03/01',
    role: 'Member',
    status: 'warning',
  },
  {
    id: 15,
    name: 'Leopold Gáspár',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'success',
  },
  {
    id: 16,
    name: 'Pompeius René',
    registered: '2018/01/01',
    role: 'Member',
    status: 'success',
  },
  {
    id: 17,
    name: 'Paĉjo Jadon',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'danger',
  },
  {
    id: 18,
    name: 'Micheal Mercurius',
    registered: '2018/02/01',
    role: 'Admin',
    status: 'secondary',
  },
  {
    id: 19,
    name: 'Ganesha Dubhghall',
    registered: '2018/03/01',
    role: 'Member',
    status: 'warning',
  },
  {
    id: 20,
    name: 'Hiroto Šimun',
    registered: '2018/01/21',
    role: 'Staff',
    status: 'success',
  },
  {
    id: 21,
    name: 'Vishnu Serghei',
    registered: '2018/01/01',
    role: 'Member',
    status: 'success',
  },
  {
    id: 22,
    name: 'Zbyněk Phoibos',
    registered: '2018/02/01',
    role: 'Staff',
    status: 'danger',
  },
  {
    id: 23,
    name: 'Aulus Agmundr',
    registered: '2018/01/01',
    role: 'Member',
    status: 'warning',
  },
  {
    id: 42,
    name: 'Ford Prefect',
    registered: '2001/05/25',
    role: 'Alien',
    status: 'info',
  },
]
export default function ExampleList() {
  const usersData = [
    {
      id: 0,
      name: 'John Doe',
      registered: '2018/01/01',
      role: 'Guest',
      status: 'warning',
    },
    {
      id: 1,
      name: 'Samppa Nori',
      registered: '2018/01/01',
      role: 'Member',
      status: 'success',
    },
    {
      id: 2,
      name: 'Estavan Lykos',
      registered: '2018/02/01',
      role: 'Staff',
      status: 'danger',
    },
    {
      id: 3,
      name: 'Chetan Mohamed',
      registered: '2018/02/01',
      role: 'Admin',
      status: 'secondary',
    },
    {
      id: 4,
      name: 'Derick Maximinus',
      registered: '2018/03/01',
      role: 'Member',
      status: 'warning',
    },
    {
      id: 5,
      name: 'Friderik Dávid',
      registered: '2018/01/21',
      role: 'Staff',
      status: 'success',
    },
    {
      id: 6,
      name: 'Yiorgos Avraamu',
      registered: '2018/01/01',
      role: 'Member',
      status: 'success',
    },
    {
      id: 7,
      name: 'Avram Tarasios',
      registered: '2018/02/01',
      role: 'Staff',
      status: 'danger',
    },
    {
      id: 8,
      name: 'Quintin Ed',
      registered: '2018/02/01',
      role: 'Admin',
      status: 'secondary',
    },
    {
      id: 9,
      name: 'Enéas Kwadwo',
      registered: '2018/03/01',
      role: 'Member',
      status: 'warning',
    },
    {
      id: 10,
      name: 'Agapetus Tadeáš',
      registered: '2018/01/21',
      role: 'Staff',
      status: 'success',
    },
    {
      id: 11,
      name: 'Carwyn Fachtna',
      registered: '2018/01/01',
      role: 'Member',
      status: 'success',
    },
    {
      id: 12,
      name: 'Nehemiah Tatius',
      registered: '2018/02/01',
      role: 'Staff',
      status: 'danger',
    },
  ]
  const [users, setUsers] = useState(usersData)
  const [hasMoreItems, setHasMoreItems] = useState(true)
  const [details, setDetails] = useState([])
  const toggleDetails = index => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }
  const fields = [
    {key: 'name', _style: {fontWeight: 'bold'}},
    {key: 'registered'},
    {key: 'role', _style: {}},
    {key: 'status', _style: {}},
  ]
  //   const getBadge = status => {
  //     switch (status) {
  //       case 'Active':
  //         return 'success'
  //       case 'Inactive':
  //         return 'secondary'
  //       case 'Pending':
  //         return 'warning'
  //       case 'Banned':
  //         return 'danger'
  //       default:
  //         return 'primary'
  //     }
  //   }

  return (
    <VmView className="p-5">
      <VmList
        hasMoreItems={hasMoreItems}
        loadMore={page => {
          let limit = 5
          let startIndex = users.length,
            endIndex = startIndex + limit
          let section = usersAll.slice(startIndex, endIndex)
          console.log({page, startIndex, endIndex, section})
          if (!section.length) return

          setUsers([...users, ...section])
          if (section.length < limit) setHasMoreItems(false)
        }}
        items={users}
        fields={fields}
        columnFilter
        tableFilter
        footer
        itemsPerPageSelect
        itemsPerPage={5}
        hover
        sorter
        pagination={{dots: true, limit: 5}}
        scopedSlots={{
          status: item => (
            <td>
              <VmButton color={item.status}>{item.status}</VmButton>
            </td>
          ),
          show_details: (item, index) => {
            return (
              <td className="py-2">
                <VmButton
                  color="primary"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    toggleDetails(index)
                  }}
                >
                  {details.includes(index) ? 'Hide' : 'Show'}
                </VmButton>
              </td>
            )
          },
          details: (item, index) => {
            if (details.includes(index)) {
              return (
                <VmButton onClick={() => alert(JSON.stringify({item, index}))}>
                  {JSON.stringify({item, index})}
                </VmButton>
              )
            } else {
              return <></>
            }
          },
        }}
      />
    </VmView>
  )
}
