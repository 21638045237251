import classNames from 'classnames'
import React from 'react'

export default function VmLoading({size = 'medium', containerClassName}) {
  let width, height
  switch (size) {
    case 'xsmall':
      width = '12px'
      height = '12px'
      break
    case 'small':
      width = '24px'
      height = '24px'
      break
    case 'medium':
      width = '48px'
      height = '48px'
      break
    case 'large':
      width = '72px'
      height = '72px'
      break

    default:
      break
  }

  return (
    <div className={classNames('flex justify-center', containerClassName)}>
      <div
        style={{
          width,
          height,
        }}
        className="loader"
      ></div>
    </div>
  )
}
