import * as React from 'react'

function VmSortAlphaDownAltIcon({title, titleId, ...props}, svgRef) {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      height="16"
      width="14"
      viewBox="0 0 448 512"
    >
      <path d="M176 352h-48V48a16 16 0 0 0 -16-16H80a16 16 0 0 0 -16 16v304H16c-14.2 0-21.4 17.2-11.3 27.3l80 96a16 16 0 0 0 22.6 0l80-96C197.4 369.3 190.2 352 176 352zm112-128h128a16 16 0 0 0 16-16v-32a16 16 0 0 0 -16-16h-56l61.3-70.5A32 32 0 0 0 432 65.6V48a16 16 0 0 0 -16-16H288a16 16 0 0 0 -16 16v32a16 16 0 0 0 16 16h56l-61.3 70.5A32 32 0 0 0 272 190.4V208a16 16 0 0 0 16 16zm159.1 234.6l-59.3-160A16 16 0 0 0 372.7 288h-41.4a16 16 0 0 0 -15.1 10.6l-59.3 160A16 16 0 0 0 272 480h24.8a16 16 0 0 0 15.2-11.1l4.4-12.9h71l4.4 12.9A16 16 0 0 0 407.2 480H432a16 16 0 0 0 15.1-21.4zM335.6 400L352 352l16.4 48z" />
    </svg>
  )
}

const ForwardRef = React.forwardRef(VmSortAlphaDownAltIcon)
export default ForwardRef
