import {ReportMobileControlled} from 'template'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {aliasFields, dataFields, filterFields} from './fields'
import {VmIcons, VmText, VmView} from 'components'

export default function PrdLapPenjualanObatResep() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const uid = searchParams.get('dctid')

  return (
    <ReportMobileControlled
      headerFields={[
        {name: 'rujtanggal', dateFormat: true, withTimeFormat: false},
        'rujno',
      ]}
      dataFields={dataFields}
      additionalApiParams={{uid, reg: 'dbdok'}}
      statusDbColumn="rujstatus"
      aliasFields={aliasFields}
      apiUrl={
        process.env.REACT_APP_BASE_URL_DOKTER + '/prd-lap-rujukan/index-mobile'
      }
      filterFields={filterFields}
      headerTitle="Laporan Rujukan"
      searchPlaceholder="Cari Nomor/Tindakan/Pasien/Faskes/Poli"
      detailAttr={{
        button: true,
        buttonLabel: 'Detail',
        buttonAction: item => navigate(`/prd-lap-rujukan/${item.rujid}`),
      }}
      countLabel="Total Rujukan"
      cardContentComponent={item => (
        <VmView className="text-gray-500">
          {[
            {
              icon: 'VmUserIcon',
              value: item?.pasnama || '-',
              className: 'font-semibold',
            },
            {
              icon: 'VmCarbonHospital',
              value: `${item?.farnama || '-'}/${item?.dornama || '-'}/${
                item?.pornama || '-'
              }`,
              className: '',
            },
            {
              icon: 'VmStetoskop',
              value: `${item?.icdkode || ''} - ${item?.icdnama}`,
              className: '',
            },
          ].map((item, i) => (
            <VmView className="flex flex-row items-baseline" key={i}>
              <VmView className="mr-2">
                <VmIcons name={item.icon} size={18} />
              </VmView>
              <VmText className={item.className}>{item.value}</VmText>
            </VmView>
          ))}
        </VmView>
      )}
      footerDividerVisible={false}
    />
  )
}
