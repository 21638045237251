import {removeLclStorage, setLclStorage} from 'utils/localstorage'
import {APP_VARIABLES, STRG_OPT} from './core'

const showSimpleToastApp = (message = '', type = 'Success') => {
  setLclStorage(
    APP_VARIABLES.SimpleToast,
    JSON.stringify({
      message,
      type: type || 'Warning',
    }),
    STRG_OPT
  )
}
const hideSimpleToastApp = () => {
  removeLclStorage(APP_VARIABLES.SimpleToast, STRG_OPT)
}
export {showSimpleToastApp, hideSimpleToastApp}
