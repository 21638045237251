import {epoin} from 'controllers/epoin'
import {modelData} from './model'
import {actionServer} from './server'

const UserController = async (event, navigate) => {
  // return {
  //   status: 1,
  //   data: {
  //     status: 10,
  //     name: 'Sutopo purnomo',
  //     spesialis: 'Poli umum',
  //     address: 'Jl. Mawar',
  //     province: '277',
  //     city: '2461',
  //     subdistrict: '34371',
  //   },
  //   message: 'success',
  // }

  const {key, dispatch, _ldata} = event
  const newevent = modelData({key, data: event})
  const actionData = {
    dispatch,
    _ldata,
    key: key,
    useAlert: event?.useAlert,
    epoin: epoin[_ldata],
    data: {input: newevent},
  }

  console.log(event?.user, 'event')
  switch (key) {
    case 'index-mKelurahan':
    case 'index-mSubdistrict':
    case 'index-mProvince':
    case 'index-mCity':
    case 'update':
    case 'index':
      return actionServer(actionData, navigate, event?.num > 0 ? event.num : 0)
    default:
      break
  }
}

export default UserController

// controllers(
//   {
//     key: 'index',
//     dispatch: () => {},
//     _ldata: 'mPatientVisitInfo',
//   },
//   navigate
// )
//   .then(res => {
//     console.log('res', res)
//   })
//   .catch(err => {
//     console.log('err', err)
//   })
//   .finally(() => {
//     console.log('finally')
//   })
