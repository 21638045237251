import {
  useCallback,
  // useRef,
  useState,
  Fragment,
  useEffect,
  createRef,
  useRef,
} from 'react'
import {TemplateForm} from 'template'
import {
  getKeyByValue,
  getParentNode,
  isEmpty,
  isFunction,
  calcAge,
  rgbToHex,
} from 'utils'
// import PatientInformation from './PatientInformation'
import PatExamFooters from 'template/PatientExaminationMobile/PatExamFooters'
import moment from 'moment'
import {FullErrorView} from 'molecules'
import {rgbcolors} from 'config/colors'
import {VmView, VmIcons, VmInput, VmText, VmButton} from 'components'
import {useParams, useLocation, useNavigate, Navigate} from 'react-router-dom'
import axios from 'axios'
import QueryString from 'qs'
import AlertOk from 'components/AlertOk'
import {catatLogDokter, empty} from 'utils/functions'
const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

export default function Index() {
  const {dctid, kunid} = useParams()

  const alert = useRef()
  const tFormRef = createRef()

  const navigate = useNavigate()
  const defaultFormData = (data, options, disableAll = false) => {
    const attrTextArea = {
        typeInput: 'textarea',
        Pascal: '-',
        propsInput: {
          disabled: disableAll,
          readOnly: disableAll,
          className: 'h-20 resize-none',
        },
      },
      attrText = {
        typeInput: 'text',
        Pascal: '-',
        propsInput: {
          disabled: disableAll,
          readOnly: disableAll,
        },
      },
      attrNumber = {
        typeInput: 'number',
        Pascal: '-',
        propsInput: {
          disabled: disableAll,
          readOnly: disableAll,
        },
      },
      attrOpt = {
        propsInput: {
          disabled: disableAll,
          readOnly: disableAll || true,
        },
      }
    // const txtdef = m => <VmText>{m}</VmText>
    // const outersuffix = ({label = '', message = ''}) => (
    //   <VmText style={{display: 'none'}} label={label}>
    //     {message}
    //   </VmText>
    // )
    return {
      pasien: {
        'no.-rm': {
          inputCustom: {
            ariaLabel: 'pasrm',
            value: data['pasrm'] ?? '',
            // required: true,
            ...attrText,
            propsInput: {
              disabled: true,
            },
            suffix: () => (
              <VmView className="flex">
                <VmButton
                  className={'!rounded-md'}
                  onClick={() => {
                    navigate(`/prd-tra-kunjungan-ubah/pilih-pasien/${dctid}`)
                  }}
                  color="primary"
                  tag={''}
                >
                  <VmIcons
                    name="VmMagnifyingGlassIcon"
                    size={24}
                    className="text-white font-bold"
                  />
                </VmButton>

                <VmButton
                  className={'!rounded-md'}
                  onClick={() => {
                    // clear prev localStorage data
                    localStorage.removeItem('tambah_relasi_pasien')
                    localStorage.removeItem('prev_data_tambah_pasien')
                    navigate(`/prd-tra-kunjungan-ubah/tambah-pasien/${dctid}`)
                  }}
                  color="primary"
                  tag={''}
                >
                  <VmIcons
                    name="VmPlusIcon"
                    size={24}
                    className="text-white font-bold"
                  />
                </VmButton>
              </VmView>
            ),
          },
        },
        'no.-bpjs': {
          inputCustom: {
            ariaLabel: 'pasnobpjs',
            value: data['pasnobpjs'] ?? '',
            // required: true,
            ...attrText,
            propsInput: {
              disabled: true,
            },
          },
        },
        'no.-ktp': {
          inputCustom: {
            ariaLabel: 'pasnoktp',
            value: data['pasnoktp'] ?? '',
            // required: true,
            ...attrText,
            propsInput: {
              disabled: true,
            },
          },
        },
        nama: {
          inputCustom: {
            ariaLabel: 'pasnama',
            value: data['pasnama'] ?? '',
            // required: true,
            ...attrText,
            propsInput: {
              disabled: true,
            },
          },
        },
        'jenis-kelamin': {
          inputCustom: {
            ariaLabel: 'pasjk',
            value: data['pasjk'] ?? '',
            // required: true,
            ...attrText,
            propsInput: {
              disabled: true,
            },
          },
        },
        alamat: {
          inputCustom: {
            ariaLabel: 'pasalamat',
            value: data['pasalamat'] ?? '',
            // required: true,
            ...attrTextArea,
            propsInput: {
              disabled: true,
            },
          },
        },
      },
    }
  }

  const [errorinitial, seterrorinitial] = useState(false)
  const [errormessage, seterrormessage] = useState('')
  const [loading, setLoading] = useState(!false)
  const [listForm, setListForm] = useState(defaultFormData({}, {}, false))
  const [data, setData] = useState({})

  useEffect(() => {
    // console.log({dctid, kunid})

    let ubah_kunjungan_pasien = localStorage.getItem('ubah_kunjungan_pasien')

    console.log({ubah_kunjungan_pasien})
    if (!empty(ubah_kunjungan_pasien)) {
      // Jika terdapat localStorage terset, maka tidak perlu get data dari API.

      setLoading(false)
      setListForm(defaultFormData(JSON.parse(ubah_kunjungan_pasien), {}, false))
    } else {
      // Get data Kunjungan
      getDataKunjungan()
    }
    return () => {}
  }, [])

  const getDataKunjungan = () => {
    return axios
      .post(
        `${BASE_URL}/kunjungan-v2/view?id=${kunid}`,
        QueryString.stringify({
          a: 'dk13',
          uid: dctid,
          reg: 'db',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log(res)
        // setData(res)
        setListForm(defaultFormData(res, {}, false))
      })
      .catch(e => {
        alert.current.show({message: 'Terjadi Kesalahan saat get Kunjungan'})
        console.log('Terjadi Kesalahan saat get Kunjungan')
      })
      .finally(() => {
        // alert.current.show({message: 'Berhasil Agan.'})
        setLoading(false)
      })
  }

  const onChangeCapture = (e, value) => {
    const {ariaLabel} = e.target
    // onChangeData({[ariaLabel]: value})
  }

  const footers = {
    title: {no: 'Batal', yes: 'Simpan'},
    className: {
      yes: 'md:flex-none md:w-1/6',
      no: 'bg-white md:flex-none md:w-1/6',
      container: 'md:relative',
    },
    color: {
      yes: 'success',
      no: 'danger',
    },
    onClick: {
      yes: () => {
        // Simpan data
        simpanData()
      },
      no: () => {
        // Clear localStorage, baru navigate back
        localStorage.removeItem('ubah_kunjungan_pasien')
        navigate(-1)
      },
    },
  }

  const simpanData = () => {
    let ubah_kunjungan_pasien = localStorage.getItem('ubah_kunjungan_pasien')
    if (empty(ubah_kunjungan_pasien)) {
      // return dan kasih alert untuk memilih pasien terlebih dahulu
      alert.current.show?.({message: 'Harap pilih pasien terlebih dahulu.'})
      return
    }
    ubah_kunjungan_pasien = JSON.parse(ubah_kunjungan_pasien)
    axios
      .post(
        `${BASE_URL}/kunjungan-v2/ubah-pasien`,
        QueryString.stringify({
          a: 'dk13',
          uid: dctid,
          reg: 'db',
          pasid: ubah_kunjungan_pasien.pasid,
          kunid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Ubah Kunjungan
        // Catat Log Dokter
        catatLogDokter({
          keterangan: `Mengubah Kunjungan`,
          menu: 'Pelayanan - Pemeriksaan Pasien',
          url: 'prd-tra-pemeriksaan-pasien',
          ip: '127.0.0.1',
          uid: dctid,
        })
        // Navigate Back
        navigate(-1)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat ubah kunjungan Pasien')
      })
  }

  return (
    <Fragment>
      <AlertOk ref={alert} />
      {errorinitial ? (
        <FullErrorView message={errormessage} />
      ) : (
        <>
          {/* <PatientInformation
        pathname={pathname}
        controllers={controllers}
        navigate={navigate}
        queryParams={queryParams}
        getDetailMedicalRecord={getDetailMedicalRecord}
        getMedicalRecords={getMedicalRecords}
        datamedicalrecord={datamedicalrecord}
        currentrm={datacurrentmedicalrecord}
        id={id}
        data={pdata}
        avatar={avatar}
        loadingrm={loadingrm}
      /> */}
          <TemplateForm
            ref={tFormRef}
            loading={loading}
            listForm={listForm}
            // onFocus={onFocus}
            // onBlur={onBlur}
            // onKeyDown={onKeyDown}
            onChangeCapture={onChangeCapture}
            // onClick={onClick}
          >
            <PatExamFooters {...Object.assign(!!listForm ? footers : {}, {})} />
          </TemplateForm>
        </>
      )}
    </Fragment>
  )
}
