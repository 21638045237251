import {VmEyeIcon, VmPhotoIcon, VmTrashIcon} from 'components/icons/outline'
import {VmText, VmView, VmInput, VmButton, VmMaps, VmIcons} from 'components'
import classNames from 'classnames'
import {brokenImage, encodeImageFileAsURL} from 'utils/functions'
import {VmChevronDownIcon} from 'components/icons/solid'
import {Fragment, memo, useRef, useState, useEffect} from 'react'
import {VmDatePicker, VmInputHandler} from 'molecules'
import moment from 'moment'
import {PageMaps} from 'pages'
import {Marker} from '@react-google-maps/api'
import {eventTrigerInput} from 'utils'
import {isFunction, isNode, isObject} from 'utils/typeof'
import InputType1 from './InputType1'
import InputType2 from './InputType2'

const hidering = '!ring-0 !ring-transparent !ring-offset-0'
export const defaultAdditionalButtonsConfig = {
  // name | variant | color | bgcolors
  plus: ['VmPlusSmallIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  minus: ['VmMinusSmallIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  view: ['VmEyeIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  search: ['VmMagnifyingGlassIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  trash: ['VmTrashIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  edit: ['VmPencilSquareIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
}
function ListInputItems({
  lstinput = [],
  data = {},
  scrollable = false,
  colors = 'text-blue-500',
  bgcolors = 'bg-gray-200',
  lstAdditionalButtonsConfig = defaultAdditionalButtonsConfig,
  ...props
}) {
  const [date, setdate] = useState(Date.now())
  const [mapstate, setmapstate] = useState({})
  const [mapsvisible, setmapsvisible] = useState(false)
  const imInputRef = useRef([])
  const textColor = colors || 'text-blue-500'
  const handleMessageRn = (e, data) => {
    // console.log('e', e)
    var actialfield = '',
      newvalue = '',
      newdata = {}
    try {
      // onChangeImage Handler
      if (`${e?.data}`?.match(/^onChangeImage:/i)) {
        newdata = JSON.parse(e.data.replace(/^onChangeImage:/i, ''))
        actialfield = newdata.field
        newvalue = newdata.value
        var input = document.getElementById(`${actialfield}`)
        eventTrigerInput(input, newvalue)
        // change image
        const content = input?.previousSibling
        const [content0, content1, content2] = content?.children
        content0.children[0].style.backgroundRepeat = 'no-repeat'
        content0.children[0].style.backgroundImage = `url(${newvalue})`
        // content0.children[0].style.backgroundSize = 'cover'
        content0.children[0].style.backgroundSize = 'contain'
        content0.children[0].style.backgroundPosition = 'center'
        content1.className += ' hidden'
        if (content2?.className?.includes('hidden')) {
          content2.className = content2.className.replace(' hidden', '')
        }
      }
    } catch (error) {
      if (e?.data?.match(/^onChangeImage:/i)) {
        return
      }
      alert('[MRN0] Telah terjadi kesalahan!')
    }
  }
  const handleValue = im => {
    var newvalue = data[im.field] || ''
    if (isFunction(im?.formatValue)) {
      newvalue = im.formatValue(data[im.field])
    }
    return newvalue
  }
  const onChangeCapture = async (e, im, index) => {
    const {nextSibling, value, ariaLabel} = e.target
    if (isFunction(props?.onChangeCapture)) {
      props.onChangeCapture(e, im, index)
    }
    if (im?.type === 1) {
      if (im?.usebtnremove) {
        if (value?.length > 0 && nextSibling?.className?.includes('hidden')) {
          nextSibling.className = nextSibling.className.replace('hidden', '')
        }
        if (value?.length <= 0) {
          nextSibling.className = 'hidden ' + nextSibling.className
        }
      }
    }
    if (im?.type === 6) {
      const imllabel = ariaLabel.replace('file-', '')
      const newvalue = await encodeImageFileAsURL(e.target)
      if (!!newvalue) {
        var input = document.getElementById(`${imllabel}`)
        eventTrigerInput(input, newvalue)
        // change image
        const content = input?.previousSibling
        const [content0, content1, content2] = content?.children
        content0.children[0].style.backgroundRepeat = 'no-repeat'
        content0.children[0].style.backgroundImage = `url(${newvalue})`
        content0.children[0].style.backgroundSize = 'cover'
        content0.children[0].style.backgroundPosition = 'center'
        content1.className += ' hidden'
        if (content2?.className?.includes('hidden')) {
          content2.className = content2.className.replace(' hidden', '')
        }
      } else {
        // alert('Is not valid image type!')
      }
    }
    if (im?.type === 9) {
      const {input1 = null, input2 = null} = im
      if (input1?.usebtnremove || input2?.usebtnremove) {
        if (value?.length > 0 && nextSibling?.className?.includes('hidden')) {
          nextSibling.className = nextSibling.className.replace('hidden', '')
        }
        if (value?.length <= 0) {
          nextSibling.className = 'hidden ' + nextSibling.className
        }
      }
    }
  }
  useEffect(() => {
    window.addEventListener('message', e => handleMessageRn(e, data))
    return () => {
      window.removeEventListener('message', e => handleMessageRn(e, data))
    }
  }, [data])
  if (lstinput?.length > 0) {
    var contentClassName = 'flex flex-col w-full'
    if (!!scrollable) {
      contentClassName += ' overflow-y-scroll'
    }
    return (
      <VmView
        {...{
          ...props,
          ...{
            className:
              `${contentClassName}` +
              (props?.className ? ` ${props?.className}` : ''),
          },
        }}
        key={`list-i-items-${date}`}
      >
        {lstinput.map((im, index) => {
          const ImIcon = im?.Icon,
            key = JSON.stringify({
              t: im?.title,
              index,
              data: data[im?.field] || Date.now(),
              timestamp: Date.now(),
            })

          // Custom Component Object
          if (im?.type === -1) {
            return im?.Component
          }
          // Custom Component
          if (im?.type === 0) {
            const CustomComponent = isNode(im?.Component)
              ? im.Component
              : VmView
            return (
              <CustomComponent
                im={im}
                key={key}
                data={data}
                ImIcon={ImIcon}
                bgcolors={bgcolors}
                textColor={textColor}
                imInputRef={imInputRef}
                handleValue={handleValue}
                onChangeCapture={onChangeCapture}
                lstAdditionalButtonsConfig={lstAdditionalButtonsConfig}
              />
            )
          }
          if (!im?.type) {
            return <Fragment key={key} />
          }
          // any type input
          if (im.type === 1) {
            return (
              <InputType1
                im={im}
                key={key}
                data={data}
                ImIcon={ImIcon}
                bgcolors={bgcolors}
                textColor={textColor}
                imInputRef={imInputRef}
                handleValue={handleValue}
                onChangeCapture={onChangeCapture}
                lstAdditionalButtonsConfig={lstAdditionalButtonsConfig}
              />
            )
          }
          // checkbox 1
          if (im.type === 2) {
            return (
              <InputType2
                im={im}
                key={key}
                data={data}
                ImIcon={ImIcon}
                bgcolors={bgcolors}
                textColor={textColor}
                imInputRef={imInputRef}
                handleValue={handleValue}
                onChangeCapture={onChangeCapture}
                lstAdditionalButtonsConfig={lstAdditionalButtonsConfig}
              />
            )
          }
          // file picker
          if (im.type === 3) {
            return (
              <VmView
                key={key}
                className="flex flex-nowrap flex-col p-2 justify-between"
              >
                <VmText className={`text-xs ${textColor}`}>{im.title}</VmText>
                <VmView className="flex flex-row mt-1">
                  <VmView
                    id={`img-${im.field}`}
                    label={`img-${im.field}`}
                    className="flex w-4/5 bg-contain bg-center border-xl hover:bg-contain"
                    style={{
                      backgroundRepeat: 'no-repeat',
                      backgroundImage: `url(${
                        !!data[`${im.field}-to-upload`]
                          ? data[`${im.field}-to-upload`]
                          : !!data[im.field]
                          ? data[im.field]
                          : brokenImage
                      })`,
                    }}
                  />
                  {(!!data[`${im.field}-to-upload`] || !!data[im.field]) && (
                    <VmView className="flex w-1/5 ml-2 flex-col justify-end mb-2">
                      <VmButton
                        label={`view-${im.label}`}
                        className={classNames(
                          hidering,
                          'w-11 h-11 p-3.5 !bg-white rounded-lg shadow-lg mb-2'
                        )}
                      >
                        <VmEyeIcon
                          aria-label={`view-${im.label}`}
                          titleId={`view-${im.label}`}
                          className={`w-full ${textColor}`}
                        />
                      </VmButton>
                      <VmButton
                        label={`remove-${im.label}`}
                        className={classNames(
                          hidering,
                          'w-11 h-11 p-3.5 !bg-red-400 rounded-lg shadow-lg'
                        )}
                      >
                        <VmTrashIcon
                          aria-label={`remove-${im.label}`}
                          titleId={`remove-${im.label}`}
                          className="w-full text-white "
                        />
                      </VmButton>
                    </VmView>
                  )}
                </VmView>
                <VmView className="flex flex-row mt-1">
                  <VmView
                    className={`p-3.5 h-12 w-12 ${bgcolors} rounded-l-md border-none ${textColor}`}
                  >
                    <VmPhotoIcon
                      className="w-full h-full"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.6"
                    />
                  </VmView>
                  <input
                    maxLength={im.maxLength}
                    aria-label={im.label}
                    type="file"
                    className={`items-center transparent-items center-items w-full h-12 rounded-l-none border-none ${bgcolors} leading-10`}
                  />
                </VmView>
              </VmView>
            )
          }
          // picker
          if (im.type === 4) {
            if (!im?.visible) {
              return <Fragment key={key} />
            }
            return (
              <VmView
                key={key}
                className="flex flex-nowrap flex-col p-2 justify-between"
              >
                <VmText className={`text-xs ${textColor}`}>{im.title}</VmText>
                <VmView
                  className={`relative flex flex-row mt-1 ${bgcolors} rounded-md`}
                >
                  {!!ImIcon ? (
                    <VmView
                      className={classNames(
                        im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                        `p-3.5 w-12 rounded-l-md border-none !${textColor}`
                      )}
                    >
                      <ImIcon
                        className={classNames(
                          im.typeInput === 'textarea' ? '' : 'h-full',
                          'w-full'
                        )}
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.6"
                      />
                    </VmView>
                  ) : (
                    <VmView />
                  )}
                  <VmInput
                    disabled={im?.disabled}
                    readonly={im?.readonly || true}
                    maxLength={im.maxLength}
                    typeInput={im.typeInput}
                    label={im.label}
                    className={classNames(
                      im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
                      hidering,
                      im?.classNameInput ? im.classNameInput : '',
                      `transparent-items center-items bg-transparent w-full rounded-none border-none`
                    )}
                    key={!!data[im.field] ? data[im.field] : '-'}
                    placeholder={im?.placeholder || ''}
                    value={handleValue(im)}
                  />
                  <VmView
                    key={`picker-${key}`}
                    className={classNames(
                      `w-8 py-3.5 px-2 absolute flex items-center top-0 right-0 bottom-0 !${textColor}`
                    )}
                  >
                    {!!data[im.field] ? (
                      <VmIcons
                        label={`remove-${im.label}`}
                        className={classNames(
                          im.typeInput === 'textarea' ? '' : 'h-full',
                          'w-full text-red5-payment'
                        )}
                        name="VmXCircleIcon"
                        variant="outline"
                        strokeWidth="1.6"
                      />
                    ) : (
                      <VmChevronDownIcon
                        className={classNames(
                          im.typeInput === 'textarea' ? '' : 'h-full',
                          'w-full'
                        )}
                        aria-label={im.label}
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1"
                      />
                    )}
                    <VmView
                      label={!!data[im.field] ? `remove-${im.label}` : im.label}
                      className={`absolute inset-0 bg-transparent`}
                    />
                  </VmView>
                </VmView>
              </VmView>
            )
          }
          // checkbox 2
          if (im.type === 5) {
            return (
              <VmView
                key={key}
                className="flex flex-nowrap flex-row p-2 pb-0 items-center"
              >
                <VmInput
                  maxLength={im.maxLength}
                  typeInput={'checkbox'}
                  label={im.label}
                  className="mr-2 flex self-start"
                  defaultChecked={data[im.field] || im?.checked}
                />
                <VmView>
                  <VmText className={`text-sm ${textColor}`}>
                    {im?.title || ''}
                  </VmText>
                  <VmText className="text-xs text-gray-400">
                    {im?.subtitle || ''}
                  </VmText>
                </VmView>
              </VmView>
            )
          }
          // file picker grid system
          if (im.type === 6) {
            const gridtype = `${im?.gridtemplate || 'grid-cols-2'}`
            return (
              <VmView
                key={key}
                className="flex flex-nowrap flex-col p-2 justify-between"
              >
                {im?.title && (
                  <VmText className={`text-sm mb-1 ${textColor}`}>
                    {im.title}
                  </VmText>
                )}
                {im?.list?.length > 0 && (
                  <VmView className={`grid ${gridtype} gap-5`}>
                    {im.list.map((iml, indexlist) => {
                      const ImlIcon = iml?.Icon || VmPhotoIcon
                      const ImlDeleteIcon = iml?.DeleteIcon || VmTrashIcon
                      const invalidataimg =
                        !data[`${iml.field}-to-upload`] && !data[iml.field]
                      return (
                        <VmView
                          key={`type-6-${indexlist}`}
                          className="flex flex-col mt-1"
                        >
                          {iml?.title && (
                            <VmText className={`text-xs ${textColor}`}>
                              {iml.title}
                            </VmText>
                          )}
                          <VmView
                            ref={ref =>
                              (imInputRef.current[`${index}-iml-${indexlist}`] =
                                ref)
                            }
                            id={`img-${iml.field}`}
                            label={`img-${iml.field}`}
                            className={`relative mt-1 overflow-hidden h-[-webkit-fill-available] w-[-webkit-fill-available] ${bgcolors} rounded-md border-none ${textColor}`}
                          >
                            <VmView className="flex w-full h-full">
                              <VmPhotoIcon
                                style={{
                                  backgroundRepeat: 'no-repeat',
                                  backgroundImage: `url(${
                                    !!data[`${iml.field}-to-upload`]
                                      ? data[`${iml.field}-to-upload`]
                                      : !!data[iml.field]
                                      ? data[iml.field]
                                      : null
                                  })`,
                                }}
                                className={`bg-cover hover:bg-cover bg-center p-3.5 h-full w-full`}
                                fill="none"
                                stroke="none"
                                strokeWidth="1.6"
                              />
                            </VmView>
                            <VmView
                              className={classNames(
                                'flex inset-0 p-auto h-[-webkit-fill-available] w-[-webkit-fill-available] justify-center items-center bg-transparent absolute',
                                invalidataimg ? '' : 'hidden'
                              )}
                              onClick={e => {
                                const emId = document.getElementById(
                                  `file-${iml.label}`
                                )
                                if (!!window?.ReactNativeWebView?.postMessage) {
                                  if (isObject(iml?.rnopt)) {
                                    window.ReactNativeWebView.postMessage(
                                      `${
                                        iml.rnopt?.rnMessage ||
                                        'onModalImagePicker'
                                      }:${
                                        iml.rnopt?.rnMessage ===
                                        'onModalImagePicker'
                                          ? iml.rnopt?.label
                                          : JSON.stringify(iml.rnopt)
                                      }`
                                    )
                                  } else {
                                    window.ReactNativeWebView.postMessage(
                                      `onModalImagePicker:${iml.label}`
                                    )
                                  }
                                  return emId?.blur()
                                }
                                return emId.click()
                              }}
                            >
                              <ImlIcon
                                className="p-4 max-w-[4rem] max-h-[4rem] rounded-md bg-white"
                                fill="none"
                                stroke={iml?.iconcolor || 'currentColor'}
                                strokeWidth="1.6"
                              />
                            </VmView>
                            {iml?.usebtnremove && (
                              <VmButton
                                label={`remove-${iml.label}`}
                                className={classNames(
                                  hidering,
                                  invalidataimg ? 'hidden' : '',
                                  'absolute top-0 right-0 w-8 h-8 !p-1.5 !bg-red5-payment !rounded-none !rounded-bl-lg'
                                )}
                                onClick={() => {
                                  // change image
                                  const content =
                                    imInputRef.current[
                                      `${index}-iml-${indexlist}`
                                    ]
                                  const [content0, content1, content2] =
                                    content.children
                                  content0.children[0].style = null
                                  content2.className =
                                    content2.className + ' hidden'
                                  if (content1?.className?.includes('hidden')) {
                                    content1.className =
                                      content1.className.replace(/hidden/gi, '')
                                  }
                                  var input = document.getElementById(
                                    `${iml.label}`
                                  )
                                  eventTrigerInput(input, '')
                                }}
                              >
                                <ImlDeleteIcon
                                  strokeWidth={
                                    iml?.strokewidthdeleteicon || '1.6'
                                  }
                                  aria-label={`remove-${iml.label}`}
                                  titleId={`remove-${iml.label}`}
                                  className="w-full h-full text-white "
                                />
                              </VmButton>
                            )}
                          </VmView>
                          <input
                            type="text"
                            className="hidden"
                            aria-label={`${iml.label}`}
                            id={`${iml.label}`}
                          />
                          <input
                            aria-label={`file-${iml.label}`}
                            id={`file-${iml.label}`}
                            onChangeCapture={e => onChangeCapture(e, im, index)}
                            type="file"
                            className="hidden"
                          />
                        </VmView>
                      )
                    })}
                  </VmView>
                )}
              </VmView>
            )
          }
          // maps view
          if (im.type === 7) {
            const mapprops = Object.assign(
              {
                containerStyle: Object.assign(
                  im?.mapprops?.containerStyle || {},
                  {marginBottom: '-24px'}
                ),
              },
              im?.mapprops || {}
            )
            return (
              <VmView
                key={key}
                className="relative flex flex-nowrap flex-col p-2 justify-between"
                ref={() => {
                  if (!!data[`${im.field}`] && !mapstate[`${im.field}`]) {
                    setmapstate({
                      ...mapstate,
                      [`${im.field}`]: JSON.parse(data[`${im.field}`]),
                    })
                  }
                  if (!mapstate[`${im.field}-${index}-props`]) {
                    setmapstate({
                      ...mapstate,
                      [`${im.field}-${index}-props`]: mapprops,
                    })
                  }
                }}
              >
                {im?.title && (
                  <VmText className={`text-sm mb-1 ${textColor}`}>
                    {im.title}
                  </VmText>
                )}
                <VmView className="overflow-hidden rounded-md">
                  {!!mapstate[`${im.field}`] && (
                    <VmMaps
                      {...{
                        ...mapprops,
                        center: mapstate[`${im.field}`].center,
                      }}
                    >
                      <Marker position={mapstate[`${im.field}`].center} />
                    </VmMaps>
                  )}
                </VmView>
                <VmButton
                  hidering={true}
                  className={classNames(
                    !!mapstate[`${im.field}`]
                      ? 'absolute inset-0 !bg-transparent !text-transparent'
                      : 'w-max',
                    'rounded-full text-sm px-5'
                  )}
                  onClick={() => {
                    setmapsvisible(`${im.field}-${index}`)
                  }}
                >
                  {im?.placeholder || 'Pilih Titik Lokasi'}
                </VmButton>
                <input
                  type="text"
                  className="hidden"
                  aria-label={`${im.label}`}
                  ref={ref =>
                    (imInputRef.current[`${im.field}-${index}`] = ref)
                  }
                />
              </VmView>
            )
          }
          // Date Picker
          if (im.type === 8) {
            imInputRef.current[`${im.label}-selected-date`] = new Date(
              data[im.field] ||
                imInputRef.current[`${im.label}-selected-date`] ||
                Date.now()
            )
            const propsdatepicker = im?.propsdatepicker || {}
            return (
              <VmDatePicker
                key={`c${key}-${
                  imInputRef.current[`${im.label}-selected-date`] || 'input'
                }`}
                customInput={
                  <VmView
                    key={key}
                    className={classNames(
                      im?.className ? im.className : '',
                      'flex flex-nowrap flex-col p-2 justify-between'
                    )}
                  >
                    <VmView className="flex">
                      <VmText className={classNames(`text-xs`, textColor)}>
                        {im?.title || ''}
                      </VmText>
                      {im?.required && (
                        <VmText className="px-2 text-xs text-red-500">*</VmText>
                      )}
                    </VmView>
                    <VmView
                      className={classNames(
                        im?.classNameContent ? im.classNameContent : '',
                        'relative flex flex-row mt-1'
                      )}
                    >
                      <VmInputHandler
                        ref={ref => {
                          imInputRef.current[`${im.label}`] = ref
                        }}
                        maxLength={im.maxLength}
                        typeInput={'text'}
                        disabled={im?.disabled}
                        readonly={true}
                        label={im.label}
                        classNameContainer={classNames(
                          im?.className ? im.className : '',
                          `relative flex flex-row h-fit w-[-webkit-fill-available] rounded-md`,
                          bgcolors
                        )}
                        className={classNames(
                          'h-12 !ml-0',
                          im?.classNameInput || ''
                        )}
                        hidering={true}
                        placeholder={im?.placeholder || ''}
                        value={
                          imInputRef.current[`${im.label}-selected-date`]
                            ? moment(
                                imInputRef.current[`${im.label}-selected-date`]
                              ).format(im?.formatdate || 'DD MMM YYYY')
                            : im?.inputprops?.defaultValue || ''
                        }
                        key={`${key}-${
                          imInputRef.current[`${im.label}-selected-date`] ||
                          'input'
                        }`}
                        prefix={() =>
                          !!ImIcon ? (
                            <VmView
                              className={classNames(
                                `h-12 !mr-0 p-3.5 w-12 rounded-l-md border-none !${textColor}`
                              )}
                            >
                              <ImIcon
                                className={'h-full w-full'}
                                strokeWidth="1.6"
                              />
                            </VmView>
                          ) : (
                            <VmView />
                          )
                        }
                        suffix={() => <></>}
                      />
                    </VmView>
                  </VmView>
                }
                onChange={e => {
                  var value = moment(e).format('YYYY-MM-DD')
                  var input = imInputRef.current[`${im.label}`].inputRef
                  imInputRef.current[`${im.label}-selected-date`] = e
                  eventTrigerInput(input, value)
                  setdate(Date.now())
                }}
                selected={imInputRef.current[`${im.label}-selected-date`]}
                format={im?.formatdatepicker || 'dd MMM yyyy'}
                {...{
                  ...propsdatepicker,
                }}
              />
            )
          }
          // any double type input
          if (im.type === 9) {
            const input1 = im?.input1
            const input2 = im?.input2
            const ImIcon1 = input1?.Icon
            const ImIcon2 = input2?.Icon
            const lstvariant = {
              v11: {cninput1: '', cninput2: '', cncontent: 'grid-cols-2'},
              v12: {
                cninput1: '',
                cninput2: 'col-span-2',
                cncontent: 'grid-cols-3',
              },
              v21: {
                cninput1: 'col-span-2',
                cninput2: '',
                cncontent: 'grid-cols-3',
              },
            }
            const variant = !!lstvariant[im?.variant]
              ? lstvariant[im.variant]
              : lstvariant.v11
            return (
              <VmView
                key={key}
                className={classNames('grid gap-4 p-2', variant.cncontent)}
              >
                {input1 && (
                  <VmView
                    className={classNames('flex flex-col', variant.cninput1)}
                  >
                    <VmText className={`text-xs ${textColor}`}>
                      {input1.title}
                    </VmText>
                    <VmView
                      className={classNames(
                        `relative flex flex-row mt-1 h-fit w-[-webkit-fill-available] ${bgcolors} rounded-md`,
                        input1?.classNameWinput ? input1.classNameWinput : ''
                      )}
                    >
                      {!!ImIcon1 && (
                        <VmView
                          className={classNames(
                            input1.typeInput === 'textarea' ? 'h-12' : 'h-12',
                            `p-3.5 w-12 rounded-l-md border-none !${textColor}`
                          )}
                        >
                          <ImIcon
                            className={classNames(
                              input1.typeInput === 'textarea' ? '' : 'h-full',
                              'w-full'
                            )}
                            strokeWidth="1.6"
                          />
                        </VmView>
                      )}
                      <VmInput
                        ref={ref =>
                          (imInputRef.current[`${input1.label}`] = ref)
                        }
                        maxLength={input1.maxLength}
                        typeInput={input1.typeInput}
                        disabled={input1?.disabled}
                        readonly={input1?.readonly}
                        label={input1.label}
                        className={classNames(
                          input1.typeInput === 'textarea'
                            ? !ImIcon1
                              ? 'h-24'
                              : 'h-24 pt-3'
                            : 'h-12',
                          hidering,
                          input1?.usebtnremove ? '!pr-8' : '',
                          input1?.classNameInput ? input1.classNameInput : '',
                          'transparent-items center-items bg-transparent w-full rounded-l-none border-none'
                        )}
                        placeholder={input1?.placeholder || ''}
                        defaultValue={handleValue(input1)}
                        key={data[input1.field] || ''}
                        onChangeCapture={e => onChangeCapture(e, im, index)}
                      />
                      {input1?.usebtnremove && (
                        <VmView
                          key={`remove-${key}`}
                          label={`remove-${input1.label}`}
                          className={classNames(
                            data[input1.field] ? '' : 'hidden',
                            'w-8 py-3.5 px-2 absolute flex items-start top-0 right-0 bottom-0'
                          )}
                          onClick={() => {
                            const input = imInputRef.current[`${input1.label}`]
                            eventTrigerInput(input, '')
                          }}
                        >
                          <VmIcons
                            label={`remove-${input1.label}`}
                            className="w-full h-full text-red5-payment"
                            name="VmXCircleIcon"
                            variant="outline"
                          />
                        </VmView>
                      )}
                      {input1?.usebtnpicker && (
                        <VmView
                          key={`picker-${key}`}
                          label={`picker-${input1.label}`}
                          className={classNames(
                            'w-8 py-3.5 px-2 absolute flex items-center top-0 right-0 bottom-0'
                          )}
                        >
                          <VmIcons
                            label={`picker-${input1.label}`}
                            className="w-full h-full text-black2-payment"
                            name="VmChevronDownIcon"
                            variant="outline"
                          />
                        </VmView>
                      )}
                    </VmView>
                  </VmView>
                )}
                {input2 && (
                  <VmView
                    className={classNames('flex flex-col', variant.cninput2)}
                  >
                    <VmText className={`text-xs ${textColor}`}>
                      {input2.title}
                    </VmText>
                    <VmView
                      className={classNames(
                        `relative flex flex-row mt-1 h-fit w-[-webkit-fill-available] ${bgcolors} rounded-md`,
                        input2?.classNameWinput ? input2.classNameWinput : ''
                      )}
                    >
                      {!!ImIcon2 && (
                        <VmView
                          className={classNames(
                            input2.typeInput === 'textarea' ? 'h-12' : 'h-12',
                            `p-3.5 w-12 rounded-l-md border-none !${textColor}`
                          )}
                        >
                          <ImIcon
                            className={classNames(
                              input2.typeInput === 'textarea' ? '' : 'h-full',
                              'w-full'
                            )}
                            strokeWidth="1.6"
                          />
                        </VmView>
                      )}
                      <VmInput
                        ref={ref =>
                          (imInputRef.current[`${input2.label}`] = ref)
                        }
                        maxLength={input2.maxLength}
                        typeInput={input2.typeInput}
                        disabled={input2?.disabled}
                        readonly={input2?.readonly}
                        label={input2.label}
                        className={classNames(
                          input2.typeInput === 'textarea'
                            ? !ImIcon2
                              ? 'h-24'
                              : 'h-24 pt-3'
                            : 'h-12',
                          hidering,
                          input2?.usebtnremove ? '!pr-12' : '',
                          input2?.classNameInput ? input2.classNameInput : '',
                          'transparent-items center-items bg-transparent w-full rounded-l-none border-none'
                        )}
                        placeholder={input2?.placeholder || ''}
                        defaultValue={handleValue(input2)}
                        key={data[input2.field] || ''}
                        onChangeCapture={e => onChangeCapture(e, im, index)}
                      />
                      {input2?.usebtnremove && (
                        <VmView
                          key={`remove-${key}`}
                          label={`remove-${input2.label}`}
                          className={classNames(
                            data[input2.field] ? '' : 'hidden',
                            'w-8 py-3.5 px-2 absolute flex items-start top-0 right-0 bottom-0'
                          )}
                          onClick={() => {
                            const input = imInputRef.current[`${input2.label}`]
                            eventTrigerInput(input, '')
                          }}
                        >
                          <VmIcons
                            label={`remove-${input2.label}`}
                            className="w-full h-full text-red5-payment"
                            name="VmXCircleIcon"
                            variant="outline"
                          />
                        </VmView>
                      )}
                      {input2?.usebtnpicker && (
                        <VmView
                          key={`picker-${key}`}
                          label={`picker-${input2.label}`}
                          className={classNames(
                            'w-8 py-3.5 px-2 absolute flex items-center top-0 right-0 bottom-0'
                          )}
                        >
                          <VmIcons
                            label={`picker-${input2.label}`}
                            className="w-full h-full text-black2-payment"
                            name="VmChevronDownIcon"
                            variant="outline"
                          />
                        </VmView>
                      )}
                    </VmView>
                  </VmView>
                )}
              </VmView>
            )
          }
          return <Fragment key={key} />
        })}
        {!!mapsvisible && (
          <PageMaps
            selectedMarker={mapstate[mapsvisible]}
            useSelectMapOnClick={
              mapstate[`${mapsvisible}-props`]?.useSelectMapOnClick
            }
            nearbysearch={mapstate[`${mapsvisible}-props`]?.useSelectMapOnClick}
            nearbysearchoptions={
              isObject(mapstate[`${mapsvisible}-props`]?.nearbysearchoptions)
                ? mapstate[`${mapsvisible}-props`].nearbysearchoptions
                : {}
            }
            searchboxoptions={
              isObject(mapstate[`${mapsvisible}-props`]?.searchboxoptions)
                ? mapstate[`${mapsvisible}-props`].searchboxoptions
                : {}
            }
            center={mapstate[mapsvisible]?.center}
            onBackPress={(event, data) => {
              if (!!data) {
                const {position} = data
                const center = {
                  lat: position.lat(),
                  lng: position.lng(),
                }
                const newdata = {...mapstate, [mapsvisible]: {...data, center}}
                setmapstate(newdata)
                const input = imInputRef.current[mapsvisible]
                eventTrigerInput(input, JSON.stringify(center))
              }
              setmapsvisible(false)
            }}
          />
        )}
      </VmView>
    )
  }
  return <Fragment key={`list-input-items-${Date.now()}`} />
}

export default memo(ListInputItems)
