import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {Amplify} from '@aws-amplify/core'

import './index.scss'
import 'react-spring-bottom-sheet/dist/style.css' // Import default styles
import App from './App'
import reportWebVitals from './reportWebVitals'
import awsconfig from './aws-exports'

/* Amplify Init */
Amplify.configure(awsconfig)

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
