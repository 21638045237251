import {
  VmButton,
  VmButtonGroup,
  VmButtonClose,
  VmText,
  VmView,
  VmIcons,
  VmInput,
  VmAccordion,
  VmDataAccordion,
  VmSpinner,
  VmModal,
  VmInputGroup,
} from 'components'
import {useState, useCallback, createRef, useRef} from 'react'
import {
  exportToExcel,
  exportToPdf,
  toCamelCase,
  toPascalCase,
  toSnakelCase,
} from 'utils'
import {iconNames, iconAvatarNames, iconAbstractNames} from 'components/VmIcons'
import classNames from 'classnames'
import VmInputCaption from 'components/VmInputCaption'
import VmToast from 'molecules/toast/VmToast'
import VmToastBody from 'molecules/toast/VmToastBody'
import VmToastHeader from 'molecules/toast/VmToastHeader'
import VmToaster from 'molecules/toast/VmToaster'
import VmInputHandler from 'molecules/input/VmInputHandler'
import {helloworldpdf} from 'utils/export-to-pdf'
import {
  exampletableexcel,
  exportTableToExcel,
  helloworldexcel,
} from 'utils/export-to-excel'
import {
  VmPencilSquareIcon,
  VmMapPinIcon,
  VmUserIcon,
  VmPhotoIcon,
  VmDeleteBinIcon,
} from 'components/icons/outline'
import {TemplateListInputItems} from 'template'

function Example() {
  const contentAccordion = 'p-4'

  const [toast, addToast] = useState(0)
  const toaster = useRef()
  const date = new Date()

  const modalRef = createRef()
  const modalTopRef = createRef()
  const modalBottomRef = createRef()
  const [data] = useState(null)
  const [filterIcon, setFilterIcon] = useState('')
  const onChangeFilterIcon = useCallback(
    e => {
      console.log('e', e)
      setFilterIcon(e.target.value)
    },
    // eslint-disable-next-line
    [filterIcon]
  )

  const exampleToast = (
    <VmToast show={true}>
      <VmToastHeader closeButton>
        <strong className="mr-auto">VmToast</strong>
        <small>
          on {date.toDateString()} {date.toLocaleTimeString()}
        </small>
      </VmToastHeader>
      <VmToastBody>Hello, world! This is a toast message.</VmToastBody>
    </VmToast>
  )
  const exampleColorToast = (color = 'primary') => (
    <VmToast className={color} show={true}>
      <VmToastHeader closeButton>
        <VmText className="mr-auto">VmToast</VmText>
        <VmText className="text-sm">
          on {date.toDateString()} {date.toLocaleTimeString()}
        </VmText>
      </VmToastHeader>
      <VmToastBody>Hello, world! This is a toast message.</VmToastBody>
    </VmToast>
  )

  const btns = [
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark',
    'light',
  ]
  const inputs = [
    'button',
    'checkbox',
    'color',
    'date',
    'datetime-local',
    'email',
    'file',
    'hidden',
    'image',
    'month',
    'number',
    'password',
    'radio',
    'range',
    'toggle',
    'reset',
    'search',
    'submit',
    'tel',
    'text',
    'time',
    'url',
    'week',
  ]
  const sizeInput = ['sm', '', 'lg', 'xl']
  const accordionData = [
    {
      title: 'Section 1',
      content: (
        <VmView calcMode={contentAccordion}>
          <VmInput />
          `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quis
          sapiente laborum cupiditate possimus labore, hic temporibus velit
          dicta earum suscipit commodi eum enim atque at? Et perspiciatis dolore
          iure voluptatem.`
        </VmView>
      ),
    },
    {
      title: 'Section 2',
      content: (
        <VmView className={contentAccordion}>
          `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Mollitia
          veniam reprehenderit nam assumenda voluptatem ut. Ipsum eius dicta,
          officiis quaerat iure quos dolorum accusantium ducimus in illum vero
          commodi pariatur? Impedit autem esse nostrum quasi, fugiat a aut error
          cumque quidem maiores doloremque est numquam praesentium eos
          voluptatem amet! Repudiandae, mollitia id reprehenderit a ab odit!`
        </VmView>
      ),
    },
    {
      title: 'Section 3',
      content: (
        <VmView className={contentAccordion}>
          `Sapiente expedita hic obcaecati, laboriosam similique omnis
          architecto ducimus magnam accusantium corrupti quam sint dolore
          pariatur perspiciatis, necessitatibus rem vel dignissimos dolor ut
          sequi minus iste? Quas?`
        </VmView>
      ),
    },
  ]
  const lstinputdata = {
    'pictur-1':
      'https://atkcqhvpuo.cloudimg.io/v7/vmedis.com/wp-content/uploads/2020/04/Vmedis-Klinik-Mobiles1.png?org_if_sml=0&w=368',
    foto: 'https://atkcqhvpuo.cloudimg.io/v7/vmedis.com/wp-content/uploads/2020/04/Vmedis-Klinik-Mobiles1.png?org_if_sml=0&w=368',
  }
  const additionalButtons = ['plus', 'minus', 'view', 'search', 'trash', 'edit']
  const CustomComponent = () => {
    return (
      <VmView className={contentAccordion}>
        <VmText type={'h1'}>{toCamelCase('to camel case')}</VmText>
        <VmText type={'h1'}>{toPascalCase('to pascal case')}</VmText>
        <VmText type={'h1'}>{toSnakelCase('to-snake-case', '-')}</VmText>
      </VmView>
    )
  }
  const lstinput = [
    {
      type: 0,
      Component: CustomComponent,
    },
    {
      type: 8,
      title: 'Tanggal Promo',
      label: 'input-tgl-promo',
      field: 'tgl-promo',
      typeInput: 'text',
    },
    {
      type: 1,
      title: 'Catatan Promo',
      Icon: VmPencilSquareIcon,
      label: 'input-catatan',
      field: 'catatan',
      typeInput: 'textarea',
      maxLength: 255,
      usebtnremove: true,
    },
    {
      type: 1,
      title: 'Catatan Promo',
      Icon: VmPencilSquareIcon,
      label: 'input-catatan',
      field: 'catatan',
      typeInput: 'password',
      maxLength: 255,
    },
    {
      type: 1,
      title: 'Catatan Promo',
      Icon: VmPencilSquareIcon,
      label: 'input-catatan',
      field: 'catatan',
      typeInput: 'textarea',
      maxLength: 255,
      additionalButtons,
    },
    {
      type: 1,
      title: 'Catatan Promo',
      label: 'input-catatan',
      field: 'catatan',
      typeInput: 'password',
      maxLength: 255,
      additionalButtons,
    },
    {
      type: 2,
      title: 'Ubah Password',
      Icon: VmUserIcon,
      label: 'combo-password',
      checked: false,
      field: '',
      typeInput: '',
      maxLength: 255,
    },
    {
      type: 3,
      title: 'Foto',
      Icon: VmUserIcon,
      label: 'input-foto',
      field: 'foto',
    },
    {
      type: 4,
      title: 'Provinsi',
      Icon: VmMapPinIcon,
      label: 'select-province',
      visible: !false,
      field: 'province',
      typeInput: 'text',
      maxLength: 100,
    },
    {
      type: 5,
      title: 'Tampilkan Catatan',
      subtitle: 'Opsi untuk menampilkan catatan pada struk.',
      Icon: VmPencilSquareIcon,
      label: 'input-checkbox-catatan',
      field: 'checkbox-catatan',
    },
    {
      type: 6,
      // title: 'Multi File picker',
      // label: 'input-pictur-1',
      // field: 'pictur-1',
      list: [
        {
          title: 'Foto 1',
          Icon: VmPencilSquareIcon,
          label: 'input-pictur-1',
          field: 'pictur-1',
          usebtnremove: true,
          DeleteIcon: VmDeleteBinIcon,
          strokewidthdeleteicon: 3,
        },
        {
          title: 'Foto 2',
          Icon: VmPencilSquareIcon,
          label: 'input-pictur-2',
          field: 'pictur-2',
          usebtnremove: true,
        },
        {
          title: 'Foto 3',
          Icon: VmPencilSquareIcon,
          label: 'input-pictur-3',
          field: 'pictur-3',
        },
        {
          title: 'Foto 4',
          Icon: VmPhotoIcon,
          label: 'input-pictur-4',
          field: 'pictur-4',
        },
        {
          title: 'Foto 5',
          Icon: VmPhotoIcon,
          label: 'input-pictur-5',
          field: 'pictur-5',
        },
        {
          title: 'Foto 6',
          Icon: VmPhotoIcon,
          label: 'input-pictur-6',
          field: 'pictur-6',
        },
      ],
    },
    {
      type: 7,
      title: 'Maps',
      label: 'input-customer',
      field: 'customer',
    },
    {
      type: 9,
      // v11 v12 v21 default v11
      variant: 'v12',
      input1: {
        title: 'input 1',
        label: 'input-input1',
        field: 'input1',
        typeInput: 'text',
        maxLength: 255,
        usebtnpicker: true,
      },
      input2: {
        title: 'input 2',
        label: 'input-input2',
        field: 'input2',
        typeInput: 'textarea',
        maxLength: 255,
      },
    },
  ]

  const accordionExample = [
    {
      title: 'Spinner',
      content: (
        <VmView className={contentAccordion}>
          <VmSpinner color="sky-500" size="sm" />
          <VmSpinner color="sky-500" grow size="sm" />
          <VmSpinner color="sky-500" />
          <VmSpinner color="sky-500" grow />
          <VmSpinner color="sky-500" size="lg" />
          <VmSpinner color="sky-500" grow size="lg" />
        </VmView>
      ),
    },
    {
      title: 'Accordion',
      content: (
        <VmView className={contentAccordion}>
          <VmAccordion />
          <VmDataAccordion data={accordionData} />
        </VmView>
      ),
    },
    {
      title: 'Input',
      content: (
        <VmView className={contentAccordion}>
          {inputs.map((mi, index) => {
            let propsInput = {}
            if (mi === 'button') {
              propsInput.value = mi
            }
            if (mi === 'number') {
              propsInput.placeholder = 123456789
            }
            if (mi === 'image') {
              propsInput.src =
                'https://atkcqhvpuo.cloudimg.io/v7/https://vmedis.com/wp-content/uploads/2019/05/LOGO-2000-e1590573782695.png'
            }
            if (
              ['email', 'password', 'search', 'tel', 'text', 'url'].includes(mi)
            ) {
              propsInput.placeholder = toPascalCase(mi) + '...'
            }
            if (['date'].includes(mi)) {
              propsInput.placeholder = toPascalCase(mi) + '...'
            }
            if (['time', 'date'].includes(mi)) {
              propsInput.className = 'reverse'
            }

            if (
              ![
                'button',
                'date',
                'datetime-local',
                'email',
                'file',
                'month',
                'number',
                'password',
                'reset',
                'search',
                'submit',
                'tel',
                'time',
                'url',
                'week',
                'text',
              ].includes(mi)
            ) {
              return (
                <VmView
                  key={`${mi}-${index}`}
                  className="flex flex-wrap flex-row p-2 justify-between bg-slate-100 odd:bg-slate-300"
                >
                  <VmText>{toPascalCase(mi)}</VmText>
                  <VmInput {...propsInput} typeInput={mi} />
                </VmView>
              )
            }
            return (
              <VmView
                key={`${mi}-${index}`}
                className="flex flex-wrap flex-row p-2 justify-between bg-slate-100 odd:bg-slate-300"
              >
                <VmText>{toPascalCase(mi)}</VmText>
                <VmView
                  key={`${mi}-${'sizeInputItem'}`}
                  className="flex flex-col justify-end"
                >
                  {sizeInput.map((sizeInputItem, index) => {
                    return (
                      <VmInput
                        {...propsInput}
                        key={`0-${JSON.stringify({sizeInputItem})}-${index}`}
                        className={classNames(
                          propsInput.className,
                          'm-1',
                          sizeInputItem
                        )}
                        typeInput={mi}
                      />
                    )
                  })}
                </VmView>
              </VmView>
            )
          })}
          {/* Input Caption */}
          <VmView className="flex flex-wrap flex-row p-2 justify-between bg-slate-100 odd:bg-slate-300">
            <VmText>{toPascalCase('text caption')}</VmText>
            <VmView
              key={`${'text'}${'sizeInputItem'}`}
              className="flex flex-col justify-end"
            >
              {sizeInput.map((sizeInputItem, index) => {
                return (
                  <VmInputCaption
                    // {...propsInput}
                    key={`1-${JSON.stringify({sizeInputItem})}-${index}`}
                    caption="Caption"
                    inputClassName={classNames(sizeInputItem)}
                    typeInput={'text'}
                  />
                )
              })}
            </VmView>
          </VmView>
          {/* Input Groups  */}
          <VmView className="flex flex-nowrap flex-row p-2 justify-between bg-slate-100 odd:bg-slate-300">
            <VmText>Input Group</VmText>

            <VmView className="flex flex-col items-end justify-end">
              <VmInputGroup className="mb-4 transparent-items center-items">
                <VmInput />
                <VmText className="px-2">x</VmText>
              </VmInputGroup>
              <VmInputGroup size="sm" className="mb-4 transparent-items">
                <VmInput />
                <VmButton color="secondary">Button</VmButton>
              </VmInputGroup>
              <VmInputGroup className="mb-4 transparent-items">
                <VmInput />
                <VmButton color="secondary">Button</VmButton>
              </VmInputGroup>
              <VmInputGroup size="lg" className="mb-4 transparent-items">
                <VmInput />
                <VmButton color="secondary">Button</VmButton>
              </VmInputGroup>
              <VmInputGroup size="xl" className="mb-4 transparent-items">
                <VmButton color="secondary">Button</VmButton>
                <VmInput />
              </VmInputGroup>
            </VmView>
          </VmView>
          {/* Input Handlers  */}
          <VmView className="flex flex-nowrap flex-row p-2 justify-between bg-slate-100 odd:bg-slate-300">
            <VmText>Input Handler</VmText>

            <VmView className="flex flex-col items-end justify-end">
              <VmInputHandler className="transparent-items center-items" />
            </VmView>
          </VmView>
        </VmView>
      ),
    },
    {
      title: 'List Input Items',
      content: (
        <VmView className={contentAccordion}>
          <TemplateListInputItems lstinput={lstinput} data={lstinputdata} />
        </VmView>
      ),
    },
    {
      title: 'Text',
      content: (
        <VmView className={contentAccordion}>
          <VmText type={'h1'}>{toCamelCase('to camel case')}</VmText>
          <VmText type={'h1'}>{toPascalCase('to pascal case')}</VmText>
          <VmText type={'h1'}>{toSnakelCase('to-snake-case', '-')}</VmText>
          <VmText type={'h1'}>Text 1</VmText>
          <VmText
            style={{fontFamily: 'PoppinsRegular'}}
            disabled
            onClick={first => {
              console.log('first', first)
            }}
          />
          <p className="text-3xl text-center">Tailwind</p>
          {!!data && <div>{data}</div>}
        </VmView>
      ),
    },
    {
      title: 'Buttons',
      content: (
        <VmView className={contentAccordion}>
          <VmView className="p-2">
            {btns.map((ibtn, index) => (
              <VmButton className="mx-1" key={`${ibtn}-${index}`} color={ibtn}>
                {ibtn}
              </VmButton>
            ))}
          </VmView>
          <VmView className="p-2">
            {btns.map((ibtn, index) => (
              <VmButton
                className="mx-1"
                key={`${ibtn}-outline-${index}`}
                color={ibtn}
                variant="outline"
              >
                {ibtn}
              </VmButton>
            ))}
          </VmView>
          <VmView className="p-2">
            {btns.map((ibtn, index) => (
              <VmButton
                className="mx-1"
                key={`${ibtn}-ghost-${index}`}
                color={ibtn}
                variant="ghost"
              >
                {ibtn}
              </VmButton>
            ))}
            <VmButton className="mx-1" disabled />
            <VmButton className="mx-1" to="#" />
          </VmView>
          <VmView className="">
            <VmButton full />
            <VmButton />
          </VmView>
          <VmButtonGroup className={'m-2'} rounded={'lg'}>
            <VmButton />
            <VmButton />
            <VmButton />
            <VmButtonClose />
          </VmButtonGroup>
        </VmView>
      ),
    },
    {
      title: 'Icons',
      content: (
        <VmView className={contentAccordion}>
          <VmView className="flex justify-center items-center">
            <VmText>Filter Icon : </VmText>
            <VmInput
              placeholder={'Search icon do you want . . .'}
              className="mx-2"
              onChange={onChangeFilterIcon}
            ></VmInput>
          </VmView>
          <VmText className="mx-4 PoppinsBold text-2xl">{'Abstract'}</VmText>
          <VmView className="grid grid-cols-4 gap-4">
            {iconAbstractNames.map((nameIcon, index) =>
              !nameIcon
                ?.toLowerCase()
                ?.includes(filterIcon?.toLowerCase()) ? null : (
                <VmView
                  key={`iconAbstractNames-${index}`}
                  className="p-2 justify-center grow"
                  style={{textAlign: '-webkit-center'}}
                >
                  <VmIcons
                    name={nameIcon}
                    title={nameIcon}
                    titleId={nameIcon}
                    size={100}
                    color="black"
                    variant="abstract"
                  />
                  <VmText className="break-words">{nameIcon}</VmText>
                </VmView>
              )
            )}
          </VmView>
          <VmText className="mx-4 PoppinsBold text-2xl">{'Avatars'}</VmText>
          <VmView className="grid grid-cols-4 gap-4">
            {iconAvatarNames.map((nameIcon, index) =>
              !nameIcon
                ?.toLowerCase()
                ?.includes(filterIcon?.toLowerCase()) ? null : (
                <VmView
                  key={`iconAvatarNames-${index}`}
                  className="p-2 justify-center grow"
                  style={{textAlign: '-webkit-center'}}
                >
                  <VmIcons
                    name={nameIcon}
                    title={nameIcon}
                    titleId={nameIcon}
                    size={60}
                    variant="avatar"
                  />
                  <VmText className="break-words">{nameIcon}</VmText>
                </VmView>
              )
            )}
          </VmView>
          <VmText className="mx-4 PoppinsBold text-2xl">{'Solid'}</VmText>
          <VmView className="grid grid-cols-4 gap-4">
            {iconNames.map((nameIcon, index) =>
              !nameIcon
                ?.toLowerCase()
                ?.includes(filterIcon?.toLowerCase()) ? null : (
                <VmView
                  key={`iconNames-${index}`}
                  className="p-2 justify-center grow"
                  style={{textAlign: '-webkit-center'}}
                >
                  <VmIcons
                    name={nameIcon}
                    title={nameIcon}
                    titleId={nameIcon}
                    size={60}
                    color="blue"
                  />
                  <VmText className="break-words">{nameIcon}</VmText>
                </VmView>
              )
            )}
          </VmView>
          <VmText className="mx-4 PoppinsBold text-2xl">{'Outline'}</VmText>
          <VmView className="grid grid-cols-4 gap-4">
            {iconNames.map((nameIcon, index) =>
              !nameIcon
                ?.toLowerCase()
                ?.includes(filterIcon?.toLowerCase()) ? null : (
                <VmView
                  key={`iconNames-outline-${index}`}
                  className="p-2 justify-center grow"
                  style={{textAlign: '-webkit-center'}}
                >
                  <VmIcons
                    name={nameIcon}
                    title={nameIcon}
                    titleId={nameIcon}
                    size={60}
                    variant="outline"
                    color="blue"
                  />
                  <VmText className="break-words">{nameIcon}</VmText>
                </VmView>
              )
            )}
          </VmView>
        </VmView>
      ),
    },
    {
      title: 'Modal',
      content: (
        <VmView className={contentAccordion}>
          <VmButton
            onClick={() => {
              console.log('modalRef?.current?.show()', modalRef?.current)
              modalRef?.current?.open()
            }}
            className="mx-2"
          >
            Open Modal
          </VmButton>
          <VmModal
            ref={modalRef}
            onClose={() => {
              console.log('e')
            }}
            header={<VmText>Header</VmText>}
            footer={<VmText>Footer</VmText>}
          >
            {'Content,'
              .repeat(99)
              .split(',')
              .map((im, index) => (
                <VmText
                  key={`${index}-Modal-${im}`}
                >{`${index}-Modal-${im}`}</VmText>
              ))}
            <VmButton
              onClick={() => {
                modalRef?.current?.close()
              }}
            >
              Close Modal
            </VmButton>
          </VmModal>
          <VmButton
            onClick={() => {
              console.log(
                'modalTopRef?.current?.show()',
                modalTopRef?.current?.modalref?.children
              )
              modalTopRef?.current?.open()
            }}
            className="mx-2"
          >
            Open Modal Top
          </VmButton>
          <VmModal
            ref={modalTopRef}
            onClose={() => {
              console.log('e')
            }}
            position="top"
            header={<VmText>HeaderTop</VmText>}
            footer={<VmText>FooterTop</VmText>}
          >
            {'Content,'
              .repeat(99)
              .split(',')
              .map((im, index) => (
                <VmText
                  key={`${index}-Modal-Top-${im}`}
                >{`${index}-Modal-Top-${im}`}</VmText>
              ))}
            <VmButton
              onClick={() => {
                modalTopRef?.current?.close()
              }}
            >
              Close Modal Top
            </VmButton>
          </VmModal>
          <VmButton
            onClick={() => {
              console.log(
                'modalBottomRef?.current?.show()',
                modalBottomRef?.current
              )
              modalBottomRef?.current?.open()
            }}
            className="mx-2"
          >
            Open Modal Bottom
          </VmButton>
          <VmModal
            ref={modalBottomRef}
            onClose={() => {
              console.log('e')
            }}
            position="bottom"
            header={<VmText>HeaderBottom</VmText>}
            footer={<VmText>FooterBottom</VmText>}
          >
            {'Content,'
              .repeat(99)
              .split(',')
              .map((im, index) => (
                <VmText
                  key={`${index}-Modal-Bottom-${im}`}
                >{`${index}-Modal-Bottom-${im}`}</VmText>
              ))}
            <VmButton
              onClick={() => {
                modalBottomRef?.current?.close()
              }}
            >
              Close Modal Bottom
            </VmButton>
          </VmModal>
        </VmView>
      ),
    },
    {
      title: 'Toast',
      content: (
        <VmView className={contentAccordion}>
          <VmButton
            onClick={() => {
              addToast(exampleToast)
            }}
          >
            Show a toast
          </VmButton>

          <VmView className="grid grid-cols-3 gap-4 py-4">
            {btns.map((ibtn, index) => (
              <VmButton
                className="mx-1"
                key={`${ibtn}-outline-${index}`}
                color={ibtn}
                variant="outline"
                onClick={() => {
                  addToast(exampleColorToast(`${ibtn}`))
                  // addToast(exampleColorToast(`${ibtn}-outline`))
                }}
              >
                Show a {ibtn} toast
              </VmButton>
            ))}
          </VmView>
          <VmToast autohide={false} show={true}>
            <VmToastHeader closeButton>
              <strong className="mr-auto">VmToast</strong>
              <small>7 min ago</small>
            </VmToastHeader>
            <VmToastBody>Hello, world! This is a toast message.</VmToastBody>
          </VmToast>
          {exampleToast}
        </VmView>
      ),
    },
    {
      title: 'Export To PDF',
      content: (
        <VmView className={contentAccordion}>
          <VmButton
            onClick={() => {
              exportToPdf(helloworldpdf, null, 'Export_To_PDF')
            }}
          >
            Export
          </VmButton>
        </VmView>
      ),
    },
    {
      title: 'Export To Excel',
      content: (
        <VmView className={contentAccordion}>
          <VmButton
            onClick={() => {
              exportToExcel(helloworldexcel, null, 'Export_To_Excel')
            }}
          >
            Export
          </VmButton>
          <VmButton
            onClick={() => {
              exportTableToExcel('data-table', null, 'exampletableexcel')
            }}
          >
            Table Export
          </VmButton>
          {exampletableexcel()}
        </VmView>
      ),
    },
  ]
  return (
    <>
      <VmToaster ref={toaster} push={toast} placement="top-end" />
      <VmDataAccordion data={accordionExample} />
    </>
  )
}

export default Example
