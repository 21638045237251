import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  createRef,
} from 'react'
import {useParams, useLocation, useNavigate, Navigate} from 'react-router-dom'
import {PrdDetailPembayaran, TemplateForm} from 'template'
import {FullErrorView} from 'molecules'
import {isNumber} from 'utils'
import {PembayaranController} from 'controllers'

const Index = () => {
  const pageTitle = 'Detail Pembayaran'
  const navigate = useNavigate()
  const {search, pathname} = useLocation()
  const useQuery = useMemo(
    () => new URLSearchParams(search),
    // eslint-disable-next-line
    [search]
  )
  const params = useParams()
  const query = useQuery
  const formRef = createRef()
  const [data, setdata] = useState({})
  const [datachange, setdatachange] = useState({})
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  // id Vlaidation Type
  const idInt = parseInt(params?.id)
  const isValidIdNum = !isNumber(idInt) || idInt <= 0

  useEffect(() => {
    document.title = pageTitle
    var error = false,
      errorMessage = ''
    PembayaranController(
      {
        id: idInt,
        key: 'index',
        dispatch: () => {},
        _ldata: 'mViewDetailPembayaran',
      },
      navigate
    )
      .then(res => {
        //console.log('res', res)
        setdata(res.data)
      })
      .catch(err => {
        //console.log('err', err)
        error = true
        errorMessage = '[C0] Gagal mengabil data!'
      })
      .finally(() => {})
  }, [])
  return (
    <TemplateForm>
      <PrdDetailPembayaran data={data} navigate={navigate} />
    </TemplateForm>
  )
}

export default Index
