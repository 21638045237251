/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const qIndexPatientExamination = /* GraphQL */ `
  query QIndexPatientExamination($input: inputReq) {
    qIndexPatientExamination(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const qPatientMedicalRecords = /* GraphQL */ `
  query QPatientMedicalRecords($input: inputReq) {
    qPatientMedicalRecords(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const qBiayaPemeriksaan = /* GraphQL */ `
  query QBiayaPemeriksaan($input: inputReq) {
    qBiayaPemeriksaan(input: $input) {
      statusCode
      body
      events
    }
  }
`;
