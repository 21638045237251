import React, {useCallback, useEffect, useState} from 'react'
import {API, graphqlOperation} from 'aws-amplify'
import {useSearchParams} from 'react-router-dom'

import {isFunction} from 'utils'
import {VmIcons, VmInput, VmView} from 'components'
import {ReportMobile} from 'template'
import {mPatientVisitInfo} from 'graphql/mutations'
import {ProgressFullScreen, VmMobileFilter, VmPulltoRefresh} from 'molecules'
import {Page500} from 'pages'
import QueryString from 'qs'
import axios from 'axios'
import {empty, ifempty, tglDDMMMYYYY, tglYMD} from 'utils/functions'
import moment from 'moment'

const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  REACT_APP_BASE_URL_SATUSEHAT: APISATUSEHAT,
} = process.env

export default function Export() {
  const [searchParams] = useSearchParams()
  const dctid = searchParams.get('dctid')
  const [loadingIdentitas, setLoadingIdentitas] = useState(true)
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState([])
  const [count, setCount] = useState(0)
  const [user, setUser] = useState({
    user_id: 0,
    kl_id: 0,
    app_id: 'dk13',
    username: '',
    nama_lengkap: '',
    email: '',
    user_nosip: '',
    user_nosip_en: '',
    user_noktp: '',
    user_noktp_en: '',
    alamat: '',
    provinsi: '277',
    kota: '2439',
    kecamatan: '34055',
    keterangan: '',
    user_nostr_en: '',
    user_wa: '085606021473',
    kecnama: '',
    kotanama: '',
  })

  useEffect(() => {
    console.log('masuk useefect cok')
    // Load Identitas
    getIdentitas()

    // Load Data
    fetchData()

    return () => {}
  }, [])

  useEffect(() => {
    if (data.length && user.user_id != 0) {
      // trigger print
      window.print()
    }

    return () => {}
  }, [user.user_id, data.length])

  const getIdentitas = () => {
    setLoadingIdentitas(true)
    axios
      .post(
        `${APIDOC}/ap-user-dokter/get-profil-by-id`,
        QueryString.stringify({
          uid: dctid,
          a: 'dk13',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.status == 1) {
          setUser(res.data)
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get Data Identitas')
      })
      .finally(() => {
        setLoadingIdentitas(false)
      })
  }

  const fetchData = async (additionalParams = {}, initial = false) => {
    // const prevParams = initial ? {} : apiParams
    // const params = {...prevParams, ...additionalParams}

    // console.log(searchParams,searchParams.toString())
    setLoadingData(true)
    try {
      const res = await axios
        .post(`${APIDOC}/prd-lap-pasien/index`, searchParams.toString())
        .then(({data: res}) => {
          // Berhasil Get Data
          return res
        })
        .catch(e => {
          console.log('Terjadi Kesalahan saat fetchData')
        })

      const resData = res
      console.log('resData', resData)
      if (resData && resData.status == 1) {
        const parsedData = resData.data
        const parsedCount = resData.count ?? 0
        // console.log({parsedData})
        setData(parsedData)
        setCount(parsedCount)
      }
      setLoadingData(false)
    } catch (e) {
      // let runtimeError = handleLambdaRuntimeExit(e)
      // if (runtimeError) {
      //   return refetchLambdaError(runtimeError, params)
      // }
      setLoadingData(false)
      // return handleError(e)
    }
  }

  let fontheader = 'font-size:68%;'
  let fontdata = 'font-size:70%;'
  let $periode = ''
  let $tahun = searchParams.get('tahun')
  let $bulan = searchParams.get('bulan')
  let $dateStart = searchParams.get('dateStart')
  let $dateEnd = searchParams.get('dateEnd')

  let $locale = {
    '01': 'JANUARI',
    '02': 'FEBRUARI',
    '03': 'MARET',
    '04': 'APRIL',
    '05': 'MEI',
    '06': 'JUNI',
    '07': 'JULI',
    '08': 'AGUSTUS',
    '09': 'SEPTEMBER',
    10: 'OKTOBER',
    11: 'NOVEMBER',
    12: 'DESEMBER',
  }

  if (!empty($tahun)) {
    // filter periode tahun
    let $dateObj = moment($tahun)

    $periode = 'PERIODE TAHUN ' + $dateObj.format('Y')
  } else if (!empty($bulan)) {
    // filter periode bulan
    let $dateObj = moment($bulan)

    $periode =
      'PERIODE BULAN ' + $locale[$dateObj.format('MM')] + $dateObj.format(' Y')
  } else if (!empty($dateStart) && !empty($dateEnd)) {
    // dd('halo cok');
    // filter periode tanggal
    let $dateStartObj = moment($dateStart)
    let $dateEndObj = moment($dateEnd)
    $periode =
      'PERIODE TANGGAL ' +
      $dateStartObj.format('DD ') +
      $locale[$dateStartObj.format('MM')] +
      $dateStartObj.format(' Y') +
      ' - ' +
      $dateEndObj.format('DD ') +
      $locale[$dateEndObj.format('MM')] +
      $dateEndObj.format(' Y')
  } else {
    // semua data
    $periode = 'SEMUA DATA'
  }

  if (loadingIdentitas || loadingData) {
    return <ProgressFullScreen useBgColor visible={true} className="!z-[100]" />
  }

  return (
    <div className="bodycetak">
      <div
        className="page"
        style={{
          width: '279mm',
          minHeight: '210mm',
          padding: '1mm',
          margin: '0mm auto',
          background: 'white',
        }}
      >
        <div
          className="subpage"
          style={{
            marginLeft: '15mm',
            marginRight: '5mm',
            marginTop: '5mm',
          }}
        >
          {/* Header / Kop Surat */}
          <table border="0" width="100%" style={{textAlign: 'left'}}>
            <thead>
              <tr>
                {/* <th align="center" width="13%">
                  <img />
                </th> */}
                <th>
                  <table border="0">
                    <thead>
                      <tr>
                        <th>
                          <h5
                            className="text-sm"
                            style={s.margin0}
                            align="left"
                          >
                            {user.nama_lengkap}
                          </h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <h6 className="text-xs" style={s.margin0}>
                            {user.alamat}, {user.kota}
                          </h6>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 className="text-xs" style={s.margin0}>
                            Telp. {user.user_wa}, Email: {user.email}
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </th>
              </tr>
            </thead>
          </table>

          <div className="py-2 border-y border-gray-400">
            <h5 align="center" style={{marginTop: 0}} className="text-sm">
              <b>LAPORAN DATA PASIEN</b>
            </h5>

            {!empty($periode) ? (
              <h5 align="center" style={{marginTop: 0}} className="text-sm">
                <b>{$periode.toUpperCase()}</b>
              </h5>
            ) : null}
          </div>

          <table
            border="0"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            style={{tableLayout: 'fixed'}}
          >
            <thead>
              <tr>
                <th
                  align="left"
                  style={{width: '3%', ...s.thcell, ...s.fontheader}}
                >
                  No
                </th>
                <th
                  align="left"
                  style={{width: '14%', ...s.thcell, ...s.fontheader}}
                >
                  Tgl. Registrasi
                </th>
                <th
                  align="left"
                  style={{width: '13%', ...s.thcell, ...s.fontheader}}
                >
                  No. RM
                </th>
                <th
                  align="left"
                  style={{width: '13%', ...s.thcell, ...s.fontheader}}
                >
                  Nama
                </th>

                <th
                  align="left"
                  style={{width: '6%', ...s.thcell, ...s.fontheader}}
                >
                  Gol. Darah
                </th>
                <th
                  align="left"
                  style={{width: '3%', ...s.thcell, ...s.fontheader}}
                >
                  L / P
                </th>
                <th
                  align="left"
                  style={{width: '17%', ...s.thcell, ...s.fontheader}}
                >
                  Tanggal Lahir / Umur
                </th>
                <th
                  align="left"
                  style={{width: '23%', ...s.thcell, ...s.fontheader}}
                >
                  Alamat
                </th>
                <th
                  align="left"
                  style={{width: '8%', ...s.thcell, ...s.fontheader}}
                >
                  No. Telepon
                </th>
                {/* <th
                  align="center"
                  style={{width: '13%', ...s.thcell, ...s.fontheader}}
                >
                  Nama Ibu / Ayah
                </th> */}
              </tr>
            </thead>

            <tbody>
              {data.map((val, i) => {
                let $no = i + 1
                if ($no == data.length) return // Data terakhir gak ditampilkan gan, karena strukturnya beda, di-render diluar loop

                let now = moment()
                let tgllahir = moment(val['pastgllahir'])
                let usia = now.diff(tgllahir, 'Y')
                return (
                  <tr key={i}>
                    <td align="left" style={s.tdcell}>
                      {$no}
                    </td>
                    <td style={s.tdcell}>{val['pastglreg']}</td>
                    <td style={s.tdcell}>{val['pasrm']}</td>
                    <td style={s.tdcell}>{val['pasnama']}</td>

                    <td style={s.tdcell}>{val['pasgoldarah']}</td>
                    <td style={s.tdcell}>
                      {val['pasjk'] == 'Laki-laki' ? 'L' : 'P'}
                    </td>
                    <td style={s.tdcell}>
                      {val['pastgllahir']
                        ? moment(val['pastgllahir']).format('DD MMM YYYY')
                        : '-'}{' '}
                      / {val['pastgllahir'] ? `${usia} Thn` : '-'}
                    </td>
                    <td style={s.tdcell}>{val['pasalamat']}</td>
                    <td style={s.tdcell}>{val['pastelp']}</td>
                    {/* <td style={{textAlign: 'center', ...s.tdcell}}>
                      {(() => {
                        let $ibu = val['pasibu']
                        let $ayah = val['pasayah']

                        if (!empty($ayah) && !empty($ibu)) {
                          return $ibu + ' / ' + $ayah
                        } else if ($ayah != '' || $ibu != '') {
                          return ifempty($ibu, $ayah)
                        }
                      })()}
                    </td> */}
                  </tr>
                )
              })}

              {/* Data baris terakhir struktur HTML-nya agak beda, jadi dipisah & tidak masuk loop */}
              {data.length ? (
                <tr>
                  <td colSpan="11">
                    <table
                      border="0"
                      width="100%"
                      cellPadding="0"
                      cellSpacing="0"
                      style={{tableLayout: 'fixed'}}
                    >
                      <tr>
                        <td align="left" style={{width: '3%', ...s.tdcell}}>
                          {data.length}
                        </td>
                        <td style={{width: '14%', ...s.tdcell}}>
                          {data[data.length - 1]['pastglreg']}
                        </td>
                        <td style={{width: '13%', ...s.tdcell}}>
                          {data[data.length - 1]['pasrm']}
                        </td>
                        <td style={{width: '13%', ...s.tdcell}}>
                          {data[data.length - 1]['pasnama']}
                        </td>

                        <td style={{width: '6%', ...s.tdcell}}>
                          {data[data.length - 1]['pasgoldarah']}
                        </td>
                        <td style={{width: '3%', ...s.tdcell}}>
                          {data[data.length - 1]['pasjk'] == 'Laki-laki'
                            ? 'L'
                            : 'P'}
                        </td>
                        <td style={{width: '17%', ...s.tdcell}}>
                          {(() => {
                            let now = moment()
                            let tgllahir = moment(
                              data[data.length - 1]['pastgllahir']
                            )
                            let usia = now.diff(tgllahir, 'Y')

                            let tgllahirtext = data[data.length - 1][
                                'pastgllahir'
                              ]
                                ? moment(
                                    data[data.length - 1]['pastgllahir']
                                  ).format('DD MMM YYYY')
                                : '-',
                              usiatext = data[data.length - 1]['pastgllahir']
                                ? `${usia} Thn`
                                : '-'
                            return `${tgllahirtext} / ${usiatext}`
                          })()}
                        </td>
                        <td style={{width: '23%', ...s.tdcell}}>
                          {data[data.length - 1]['pasalamat']}
                        </td>
                        <td style={{width: '8%', ...s.tdcell}}>
                          {data[data.length - 1]['pastelp']}
                        </td>
                        {/* <td style={{width: '13%', ...s.tdcell}}>
                          {(() => {
                            let $ibu = data[data.length - 1]['pasibu']
                            let $ayah = data[data.length - 1]['pasayah']

                            if (!empty($ayah) && !empty($ibu)) {
                              return $ibu + ' / ' + $ayah
                            } else if ($ayah != '' || $ibu != '') {
                              return ifempty($ibu, $ayah)
                            }
                          })()}
                        </td> */}
                      </tr>
                      {/* <!-- TTD --> */}
                      <tr>
                        <td colSpan="11">
                          <hr />{' '}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="11"> &nbsp; </td>
                      </tr>
                      <tr>
                        <td colSpan="6">&nbsp;</td>
                        <td
                          colSpan="5"
                          align="center"
                          style={{fontSize: '72%'}}
                        >
                          {user.kotanama}, {tglDDMMMYYYY()}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6">&nbsp;</td>
                        <td
                          align="center"
                          style={{fontSize: '72%'}}
                          colSpan="5"
                        >
                          Penanggung Jawab
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6">&nbsp;</td>
                        <td colSpan="5" style={{height: '20mm'}}>
                          &nbsp;
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6"> &nbsp; </td>
                        <td
                          align="center"
                          style={{fontSize: '72%'}}
                          colSpan="5"
                        >
                          <u>{user.nama_lengkap}</u>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const s = {
  fontheader: {
    fontSize: '68%',
  },
  fontdata: {
    fontSize: '70%',
  },
  thcell: {
    paddingVertical: '2px',
    paddingHorizontal: '3px',
    height: '0.7cm',
    borderBottom: '1px solid',
    borderTop: '1px solid',
  },
  tdcell: {
    fontSize: '70%',
    paddingVertical: '2px',
    paddingHorizontal: '3px',
    height: '0.7cm',
    // borderBottom: '1px solid',
    // borderTop: '1px solid',
  },
  cell: {
    paddingVertical: '2px',
    paddingHorizontal: '3px',
    fontSize: '70%',
  },
  margin0: {
    margin: 0,
  },
}
