import moment from 'moment'

const pluralize = (str, n) =>
  n > 1 ? `${n} ${str}` : n === 0 ? '' : `${n} ${str}`
/**
 *
 * @param {string} dob Date of Born
 * @param {string} Thn Label unit of age in Years
 * @param {string} Bln Label unit of age in Months
 * @param {string} Hr Label unit of age in Days
 * @returns
 */
const calcAge = (
  dob = moment().format('YYYY-MM-DD'),
  Thn = 'Thn',
  Bln = 'Bln',
  Hr = 'Hr'
) => {
  const age = moment.duration(moment().diff(moment(dob)))
  const [ageInYears, ageInMonths, ageInDays] = [
    Math.floor(age.asYears()),
    Math.floor(age.asMonths()),
    Math.floor(age.asDays()),
  ]
  if (parseInt(age) < 0) {
    return 'Date of Born is in the future!'
  }

  var [pluralYears, pluralMonth, pluralDays] = [
    pluralize(Thn, ageInYears),
    pluralize(Bln, age.months()),
    pluralize(Hr, age.days()),
  ]
  if (ageInYears >= 1) {
    return `${pluralYears} ${pluralMonth} ${pluralDays}`
  } else if (ageInYears < 1 && ageInMonths >= 1) {
    return `${pluralMonth} ${pluralDays}`
  } else {
    return pluralDays
  }
}

const calcAgeYearOnly = birthDate => moment().diff(birthDate, 'years')

export {pluralize, calcAge, calcAgeYearOnly}
