import {VmKeyIcon, VmUserIcon, VmUserGroupIcon} from 'components/icons/outline'

export const lstinput = [
  {
    type: 1,
    title: 'Client Key',
    Icon: VmUserIcon,
    label: 'clientId',
    field: 'clientId',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    required: true,
  },
  {
    type: 1,
    title: 'Secret Key',
    Icon: VmKeyIcon,
    label: 'secretKey',
    field: 'secretKey',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    required: true,
  },
  {
    type: 1,
    title: 'Organization ID',
    Icon: VmUserGroupIcon,
    label: 'organizationId',
    field: 'organizationId',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    required: true,
  },
]
