import axios from 'axios'
import classNames from 'classnames'
import {VmButton, VmInput, VmPicker, VmText, VmView} from 'components'
import {VmUserIcon} from 'components/icons/solid'
import ModalPembayaran from 'components/reusable_modal/ModalPembayaran'
import ModalPilihanPaket from 'components/reusable_modal/ModalPilihanPaket'
import VmInputCaption from 'components/VmInputCaption'
import {VmHeader} from 'molecules'
import QueryString from 'qs'
import React from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {empty, toCurrency} from 'utils/functions'
import {iconMap} from 'components/icons/IconMap'
import {hidering} from 'template/PrdStrukSetting'
import {renderField} from 'template/PrdUbahProfil/Index'
import {
  VmArchiveBoxIcon,
  VmClockIcon,
  VmReceiptPercentIcon,
  VmTagIcon,
} from 'components/icons/outline'
import moment from 'moment'
import QRCode from 'react-qr-code'

const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env
const bank_code_map = {
  2: 'bca',
  3: 'mandiri',
  4: 'bri',
  5: 'bni',
  6: 'permata',
}

export default function BayarPerpanjangan() {
  const [dataRegistrasi, setDataRegistrasi] = useState({})
  const [dataRegistrasiDetail, setDataRegistrasiDetail] = useState({})
  const [dataVa, setDataVa] = useState(null)
  let [searchParams, setSearchParams] = useSearchParams()
  const [device, setDevice] = useState('')

  const navigate = useNavigate()
  const [uid, setUid] = useState(searchParams.get('dctid'))
  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/backend-data-registrasi/find-faktur-bayar`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data}) => {
        console.log('Data Registrasi: ', data)

        let registrasiDetail = data.detail
        setDataRegistrasi(data.data)
        setDataRegistrasiDetail(registrasiDetail)

        if (registrasiDetail.regd_bayar != 12) {
          // Get Status Va
          axios
            .post(
              `${BASE_URL}/backend-data-registrasi/xendit-va`,
              QueryString.stringify({
                id: registrasiDetail.regd_idbayar,
              })
            )
            .then(({data: res}) => {
              // Berhasil Get Data
              setDataVa(res)
            })
            .catch(e => {
              console.log('Terjadi Kesalahan saat Get Detail Data Xendit')
            })
        }
      })

    // Detect device
    detectDevice()
  }, [])

  const detectDevice = () => {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    setDevice(isMobile ? 'mobile' : 'web')
    // this.setState({device: isMobile ? 'mobile' : 'web'}, this.getInitialData)
  }

  let hargaFinal =
    parseFloat(dataRegistrasiDetail?.regd_harga_paket) -
    parseFloat(dataRegistrasiDetail?.regd_diskon_paket)
  return (
    <>
      <div
        className="container px-4 pt-20 pb-24 !max-w-none"
        style={
          {
            // backgroundColor: 'lightgray',
            // backgroundImage:
            //   "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!-- Generator: Gravit.io --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='isolation:isolate' viewBox='0 0 360 538' width='360pt' height='538pt'%3E%3Cdefs%3E%3CclipPath id='_clipPath_HOQln3xPEuJQi1BVRsYHuJRL7LCJW0r6'%3E%3Crect width='360' height='538'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23_clipPath_HOQln3xPEuJQi1BVRsYHuJRL7LCJW0r6)'%3E%3ClinearGradient id='_lgradient_0' x1='0' y1='0.5' x2='1' y2='0.5' gradientTransform='matrix(360 0 0 180 0 0)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0%25' stop-opacity='1' style='stop-color:rgb(3 131 204)'/%3E%3Cstop offset='98.33333333333333%25' stop-opacity='1' style='stop-color:rgb(41 167 240)'/%3E%3C/linearGradient%3E%3Crect x='0' y='0' width='360' height='180' transform='matrix(1 0 0 1 0 0)' fill='url(%23_lgradient_0)'/%3E%3Cdefs%3E%3Cfilter id='sHJ0knPpD2PQfwQGcM1DmUlYeQQastcw' x='-200%25' y='-200%25' width='400%25' height='400%25' filterUnits='objectBoundingBox' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur xmlns='http://www.w3.org/2000/svg' stdDeviation='0'/%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23sHJ0knPpD2PQfwQGcM1DmUlYeQQastcw)'%3E%3Cpath d=' M 0 149 L 360 96 C 360 96 360 96 360 96 L 360 185 C 360 185 360 185 360 185 L 0 185 C 0 185 0 185 0 185 L 0 96 C 0 96 0 149 0 149 Z ' fill='rgb(7 131 202)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
            // backgroundAttachment: 'fixed',
            // backgroundRepeat: 'no-repeat',
          }
        }
      >
        <VmHeader
          style={
            {
              // backgroundImage:
              //   "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!-- Generator: Gravit.io --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='isolation:isolate' viewBox='0 0 360 538' width='360pt' height='538pt'%3E%3Cdefs%3E%3CclipPath id='_clipPath_DkJne1C2FPcxYgcX6T6yWMtE1onxqzkb'%3E%3Crect width='360' height='538'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23_clipPath_DkJne1C2FPcxYgcX6T6yWMtE1onxqzkb)'%3E%3ClinearGradient id='_lgradient_0' x1='0' y1='0.5' x2='1' y2='0.5' gradientTransform='matrix(360 0 0 233 0 0)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0%25' stop-opacity='1' style='stop-color:rgb(3 131 204)'/%3E%3Cstop offset='98.33333333333333%25' stop-opacity='1' style='stop-color:rgb(41 167 240)'/%3E%3C/linearGradient%3E%3Crect x='0' y='0' width='360' height='233' transform='matrix(1 0 0 1 0 0)' fill='url(%23_lgradient_0)'/%3E%3Cdefs%3E%3Cfilter id='ecfL346mKQxvgDtM4pwI4VHWkzRqEg99' x='-200%25' y='-200%25' width='400%25' height='400%25' filterUnits='objectBoundingBox' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur xmlns='http://www.w3.org/2000/svg' stdDeviation='0'/%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23ecfL346mKQxvgDtM4pwI4VHWkzRqEg99)'%3E%3Cpath d=' M 0 212 L 360 125 C 360 125 360 159 360 159 L 360 248 C 360 248 360 248 360 248 L 0 248 C 0 248 0 248 0 248 L 0 159 C 0 159 0 212 0 212 Z ' fill='rgb(7 131 202)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
              // backgroundAttachment: 'fixed',
            }
          }
          HeaderName={'Menu'}
          HeaderType={'Pembayaran'}
          className="fixed py-4 z-10 top-0 left-4 right-4 bg-white"
          classNameTitle="!pl-4"
          HeaderMode={'bg-blue-gradient'}
          onBackPress={_ => {
            if (window?.ReactNativeWebView?.postMessage) {
              // Send message untuk menutup webview
              window.ReactNativeWebView.postMessage('closeWebview:')
            } else {
              // Jika dibuka lewat web, maka navigate back
              navigate(-1)
            }
          }}
          showBack={device == 'mobile'}
        />

        <div id="card" className="p-4 shadow-2xl rounded-xl">
          {dataRegistrasiDetail?.regd_bayar == 12 ? (
            <>
              <Qris value={dataRegistrasiDetail?.regd_qrcode} device={device} />
            </>
          ) : (
            <>
              <Va
                va_code={dataRegistrasiDetail?.regd_va_number}
                bank_code={bank_code_map[dataRegistrasiDetail?.regd_bayar]}
                nominal={toCurrency(hargaFinal)}
              />
              <VmText className="text-center text-sm text-danger mb-2">
                {(first => {
                  let txt
                  if (dataVa) {
                    txt = `Bayar sebelum ${moment(
                      dataVa.expiration_date
                    ).format('DD MMM YYYY HH:mm')} WIB`
                  } else {
                    txt = 'Sedang Memuat...'
                  }
                  return txt
                })()}
              </VmText>
            </>
          )}

          <VmText className="text-center text-sm text-primary">
            Setelah Anda melakukan pembayaran Anda bisa login ke aplikasi Vmedis
            dan masa aktif akan otomatis diperpanjang
          </VmText>

          {/* Jika mode web, maka tidak tampilkan tombol selesai */}
          {device == 'mobile' && (
            <VmButton
              onClick={() => {
                // alert("TES")
                if (window?.ReactNativeWebView?.postMessage) {
                  // Send message untuk menutup webview
                  window.ReactNativeWebView.postMessage('closeWebview:')
                }
              }}
              style={{marginTop: 20}}
              full
              className={classNames('')}
            >
              {'Selesai'}
            </VmButton>
          )}
        </div>
      </div>
    </>
  )
}

const Va = ({va_code, bank_code, nominal}) => {
  let imgmap = {
    mandiri: 'https://register.vmedis.com/foto-bayar/mandiri.png',
    permata: 'https://register.vmedis.com/foto-bayar/permata.png',
    bni: 'https://register.vmedis.com/foto-bayar/bni.png',
    bri: 'https://register.vmedis.com/foto-bayar/bri.png',
    bca: 'https://register.vmedis.com/foto-bayar/bca.png',
  }
  return (
    <div>
      <img className="w-1/2 mx-auto mb-2" src={imgmap[bank_code]} />
      <p className="font-bold text-zinc-600 mb-2 text-sm">
        No. Virtual Account
      </p>
      <p className="text-zinc-600 mb-4 text-md">{va_code}</p>

      <p className="font-bold text-zinc-600 mb-2 text-sm">
        Nominal Yang Akan Dibayarkan
      </p>
      <p className="text-zinc-600 mb-4 text-md">Rp. {nominal}</p>
    </div>
  )
}

const Qris = ({value = '', device}) => {
  return (
    <div className="flex flex-col items-center mb-4">
      <img className="" src="https://register.vmedis.com/foto-bayar/qris.png" />
      <QRCode
        size={256}
        style={{
          height: 'auto',
          maxWidth: '100%',
          width: device == 'mobile' ? '100%' : '30%',
        }}
        value={value}
        viewBox={`0 0 256 256`}
      />
    </div>
  )
}
