import {VmText, VmView} from 'components'
import {FullErrorView, ProgressFullScreen, VmHeader} from 'molecules'
import React, {useEffect, useState} from 'react'
import Searchbar from './Searchbar'
import CardItemKategori from './CardItemKategori'
import {qCariKategori} from './api'
import {isFunction} from 'utils'
import useAddEventListener from 'utils/useAddEventListener'

export default function SearchKategori({
  labelBackButton = 'modal-back-button',
  HeaderType = 'Buat Racikan Baru',
  label = '',
  onSelect = () => {},
  ...props
}) {
  const [data, setdata] = useState([])
  const [keyword, setKeyword] = useState('')
  const [loading, setLoading] = useState(false)
  const defData = {a: props?.a, reg: props?.reg}
  const fetchData = async params => {
    setLoading(true)
    const data = await qCariKategori({...defData, ...params})
    if (data.statusCode != 200) setdata({error: true})
    else setdata(data.data.data)
    setLoading(false)
  }

  const handlePilih = item => {
    onSelect(item)
    onBackPress()
  }

  useEffect(() => {
    fetchData()
  }, [])

  const onBackPress = () => {
    if (isFunction(props?.close)) props?.close()
  }

  const onClick = e => {
    const {ariaLabel} = e?.target

    if (ariaLabel === 'btn-kategori-name-search') {
      fetchData({katonama: keyword})
    }
  }
  useAddEventListener({eventName: 'click', handler: onClick})

  return (
    <VmView className="fixed inset-0 bg-blue4-payment px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={HeaderType}
          onBackPress={onBackPress}
          labelBackButton={labelBackButton}
        />
        {data?.error ? (
          <FullErrorView
            message="Gagal saat mengambil data kategori !!!"
            showreload
            onClickRelaod={() => fetchData()}
          />
        ) : (
          <>
            {/* Searchbar */}
            <Searchbar
              placeholder="Cari Kategori"
              label={label}
              onChangeCaptureInput={e => setKeyword(e.target.value)}
            />
            {/* Content */}
            <ProgressFullScreen visible={loading} />
            <VmView className="block overflow-y-scroll">
              {data && data.length > 0 ? (
                data.map((im, index) => {
                  return (
                    <CardItemKategori
                      key={`CardItemKategori-${index}`}
                      index={index}
                      data={im}
                      onClick={() => handlePilih(im)}
                    />
                  )
                })
              ) : (
                <VmView className="h-[74vh] items-center justify-center flex">
                  <VmText className="text-black2-payment">
                    Tidak ada Data
                  </VmText>
                </VmView>
              )}
            </VmView>
          </>
        )}
      </VmView>
    </VmView>
  )
}
