import * as React from 'react'

function VmMaterialSymExportNotes({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 20 20',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props,
      {
        fill: 'currentColor',
        stroke: 'none',
      }
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M13.5 17.0833L15.8333 14.75V16.6667H16.6667V13.3333H13.3333V14.1667H15.25L12.9167 16.5L13.5 17.0833ZM4.16667 17.5C3.70833 17.5 3.31583 17.3369 2.98917 17.0108C2.6625 16.6847 2.49945 16.2922 2.5 15.8333V4.16667C2.5 3.70833 2.66333 3.31611 2.99 2.99C3.31667 2.66389 3.70889 2.50056 4.16667 2.5H15.8333C16.2917 2.5 16.6842 2.66333 17.0108 2.99C17.3375 3.31667 17.5006 3.70889 17.5 4.16667V9.75C17.2361 9.625 16.9653 9.5175 16.6875 9.4275C16.4097 9.3375 16.125 9.27139 15.8333 9.22917V4.16667H4.16667V15.8333H9.20833C9.25 16.1389 9.31611 16.4306 9.40667 16.7083C9.49722 16.9861 9.60472 17.25 9.72917 17.5H4.16667ZM4.16667 15V15.8333V4.16667V9.22917V9.16667V15ZM5.83333 14.1667H9.22917C9.27083 13.875 9.33695 13.5903 9.4275 13.3125C9.51806 13.0347 9.61861 12.7639 9.72917 12.5H5.83333V14.1667ZM5.83333 10.8333H10.9167C11.3611 10.4167 11.8575 10.0694 12.4058 9.79167C12.9542 9.51389 13.5411 9.32639 14.1667 9.22917V9.16667H5.83333V10.8333ZM5.83333 7.5H14.1667V5.83333H5.83333V7.5ZM15 19.1667C13.8472 19.1667 12.8647 18.7603 12.0525 17.9475C11.2403 17.1347 10.8339 16.1522 10.8333 15C10.8333 13.8472 11.2397 12.8647 12.0525 12.0525C12.8653 11.2403 13.8478 10.8339 15 10.8333C16.1528 10.8333 17.1356 11.2397 17.9483 12.0525C18.7611 12.8653 19.1672 13.8478 19.1667 15C19.1667 16.1528 18.7603 17.1356 17.9475 17.9483C17.1347 18.7611 16.1522 19.1672 15 19.1667Z',
    })
  )
}

const ForwardRef = React.forwardRef(VmMaterialSymExportNotes)
export default ForwardRef
