import classNames from 'classnames'
import {VmButton, VmView} from 'components'
import {forwardRef, useImperativeHandle, useState} from 'react'
import {isFunction} from 'utils'

function Tabs(
  {
    addtionalList = [],
    onChangeTab: propsonChangeTab = null,
    active = 0,
    prefix,
    suffix,
  },
  ref
) {
  const showlog = false,
    listTabs = [
      {
        title: 'Semua',
      },
      ...addtionalList,
    ]
  const [activetab, setactivetab] = useState(active)
  const onChangeTab = (tab, title = '') => {
    if (showlog) {
      console.log('tab, title', tab, title)
    }
    setactivetab(tab)
    if (isFunction(propsonChangeTab)) {
      propsonChangeTab(tab, tab === 0 ? '' : title)
    }
  }
  useImperativeHandle(
    ref,
    () => {
      if (showlog) {
        console.log('useImperativeHandle')
      }
      return {
        activetab,
        setactivetab,
      }
    },
    [activetab]
  )
  return (
    <VmView className="flex flex-row min-h-[2.5rem] mb-4 overflow-x-scroll">
      {prefix}
      {listTabs.map((im, index) => {
        const active = index === activetab
        return (
          <VmButton
            key={`im-listTabs-${im?.title || '-'}-${index}`}
            className={classNames(
              'flex justify-center items-center !flex-none text-sm p-2 px-4 mr-4 !min-w-[50px]',
              active
                ? '!bg-blue1-payment !text-white'
                : '!bg-white !text-black2-payment'
            )}
            hidering={true}
            onClick={() => !active && onChangeTab(index, im?.title)}
          >
            {im?.title || '-'}
          </VmButton>
        )
      })}
      {suffix}
    </VmView>
  )
}
export default forwardRef(Tabs)
