import {VmInputAttributes} from 'config/attributes'
import React from 'react'
import classNames from 'classnames'
import CurrencyInput from 'react-currency-input-field'
import VmInput from './input/VmInput'

const defaultProps = {
  disabled: false,
  className: '',
}
const funcNull = () => {}
const VmInputCaption = ({
  caption = '',
  captionClassName,
  containerClassName,
  inputClassName,
  captionStyle,
  containerStyle,
  inputStyle,
  disabled,
  typeInput = 'text',
  onClick = funcNull,
  onChange = funcNull,
  onChangeCapture = funcNull,
  onRateChange = funcNull,
  onRateChangeCapture = funcNull,
  onVolumeChange = funcNull,
  onVolumeChangeCapture = funcNull,
  onDurationChange = funcNull,
  onDurationChangeCapture = funcNull,
  allowDecimals = true,
  ...props
}) => {
  // console.log({typeInput, allowDecimals})
  return (
    <div style={containerStyle} className={classNames(containerClassName)}>
      {/* Tampilkan Caption untuk input selain 'hidden' */}
      {typeInput != 'hidden' ? (
        <p
          style={{...captionStyle}}
          className={classNames('mb-1', captionClassName)}
        >
          {caption}
        </p>
      ) : null}

      {(() => {
        if (typeInput == 'autonumeric') {
          // Input Autonumeric
          return (
            <CurrencyInput
              {...props}
              id="input-example"
              name="input-name"
              placeholder={''}
              decimalSeparator=","
              groupSeparator="."
              onValueChange={onChange}
              className={inputClassName}
              allowDecimals={allowDecimals}
              decimalScale={allowDecimals ? 2 : 0}
              style={inputStyle}
              allowNegativeValue={false}
            />
          )
        } else {
          return (
            <VmInput
              {...props}
              {...{
                typeInput: typeInput,
                className: classNames(
                  'rounded-xl',
                  inputClassName,
                  disabled ? 'opacity-25' : ''
                ),
                disabled,
                style: inputStyle,
                onClick: disabled ? funcNull : onClick,
                onChange: disabled ? funcNull : onChange,
                onChangeCapture: disabled ? funcNull : onChangeCapture,
                onRateChange: disabled ? funcNull : onRateChange,
                onRateChangeCapture: disabled ? funcNull : onRateChangeCapture,
                onVolumeChange: disabled ? funcNull : onVolumeChange,
                onVolumeChangeCapture: disabled
                  ? funcNull
                  : onVolumeChangeCapture,
                onDurationChange: disabled ? funcNull : onDurationChange,
                onDurationChangeCapture: disabled
                  ? funcNull
                  : onDurationChangeCapture,
              }}
            />
          )
        }
      })()}
    </div>
  )
}
VmInputCaption.propTypes = VmInputAttributes
VmInputCaption.defaultProps = defaultProps

export default VmInputCaption
