import {
  VmSuster,
  VmStetoskop,
  VmCalendarIcon,
  VmCarbonHospital,
  VmMagnifyingGlassPlusIcon,
} from 'components/icons/outline'
import {VmListPencilIcon} from 'components/icons/solid'

export const textclassname = '!text-slate-500 text-sm font-semibold'
export const titleclassname = 'font-bold'
export const lstmenu = [
  {
    type: 8,
    required: true,
    title: 'Tanggal Rujukan',
    Icon: VmCalendarIcon,
    label: 'input-date',
    field: 'date',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer border-0`,
    classNameInput: `${textclassname} cursor-pointer`,
    propsdatepicker: {
      minDate: new Date(),
    },
  },
  {
    type: 1,
    required: true,
    title: 'Nama Faskes',
    Icon: VmCarbonHospital,
    label: 'select-faskes',
    field: 'faskes',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer`,
    classNameInput: `${textclassname} cursor-pointer`,
    maxLength: 255,
    inputprops: {readonly: true, placeholder: 'Pilih Faskes'},
  },
  {
    type: 1,
    required: true,
    title: 'Poli',
    Icon: VmStetoskop,
    label: 'select-poli',
    field: 'poli',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer`,
    classNameInput: `${textclassname} cursor-pointer`,
    maxLength: 255,
    inputprops: {readonly: true, placeholder: 'Pilih Poli'},
  },
  {
    type: 1,
    required: true,
    title: 'Dokter',
    Icon: VmSuster,
    label: 'select-doctname',
    field: 'doctname',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer`,
    classNameInput: `${textclassname} cursor-pointer`,
    maxLength: 255,
    inputprops: {readonly: true, placeholder: 'Pilih Dokter'},
  },
  {
    type: 1,
    title: 'Diagnosa',
    Icon: VmMagnifyingGlassPlusIcon,
    label: 'input-diagnosa',
    field: 'diagnosa',
    typeInput: 'text',
    className: `${titleclassname} !text-slate-400 cursor-default`,
    classNameInput: `${textclassname} !text-slate-400 cursor-default`,
    classNameWinput: `opacity-80 !text-slate-400`,
    inputprops: {readonly: true, disabled: true, placeholder: 'Diagnosa'},
  },
  {
    type: 1,
    required: true,
    title: 'Tindakan Yang Sudah Diberikan',
    Icon: VmListPencilIcon,
    label: 'input-tindakan',
    field: 'tindakan',
    typeInput: 'textarea',
    placeholder: 'Isi tindakan',
    className: titleclassname,
    classNameInput: textclassname,
    maxLength: 255,
  },
]

export const lstmenuweb = [
  {
    type: 8,
    required: true,
    title: 'Tanggal Rujukan',
    Icon: VmCalendarIcon,
    label: 'input-date',
    field: 'date',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer border-0`,
    classNameInput: `${textclassname} cursor-pointer`,
    propsdatepicker: {
      minDate: new Date(),
    },
  },
  {
    type: 1,
    required: true,
    title: 'Nama Faskes',
    Icon: VmCarbonHospital,
    label: 'select-faskes',
    field: 'faskes',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer`,
    classNameInput: `${textclassname} cursor-pointer`,
    maxLength: 255,
    inputprops: {readonly: true, placeholder: 'Pilih Faskes'},
  },
  {
    type: 1,
    required: true,
    title: 'Poli',
    Icon: VmStetoskop,
    label: 'select-poli',
    field: 'poli',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer`,
    classNameInput: `${textclassname} cursor-pointer`,
    maxLength: 255,
    inputprops: {readonly: true, placeholder: 'Pilih Poli'},
  },
  {
    type: 1,
    required: true,
    title: 'Dokter',
    Icon: VmSuster,
    label: 'select-doctname',
    field: 'doctname',
    typeInput: 'text',
    className: `${titleclassname} cursor-pointer`,
    classNameInput: `${textclassname} cursor-pointer`,
    maxLength: 255,
    inputprops: {readonly: true, placeholder: 'Pilih Dokter'},
  }
]

export const lstmenuweb2 = 
[
  {
    type: 1,
    title: 'Diagnosa',
    Icon: VmMagnifyingGlassPlusIcon,
    label: 'input-diagnosa',
    field: 'diagnosa',
    typeInput: 'text',
    className: `${titleclassname} !text-slate-400 cursor-default !justify-normal`,
    classNameInput: `${textclassname} !text-slate-400 cursor-default !justify-normal`,
    classNameWinput: `opacity-80 !text-slate-400`,
    inputprops: {readonly: true, disabled: true, placeholder: 'Diagnosa'},
  },
  {
    type: 1,
    required: true,
    title: 'Tindakan Yang Sudah Diberikan',
    Icon: VmListPencilIcon,
    label: 'input-tindakan',
    field: 'tindakan',
    typeInput: 'textarea',
    placeholder: 'Isi tindakan',
    className: titleclassname,
    classNameInput: textclassname,
    maxLength: 255,
  },
]
