/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const mIndexPatientExamination = /* GraphQL */ `
  mutation MIndexPatientExamination($input: inputReq) {
    mIndexPatientExamination(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSearchDiagnosa = /* GraphQL */ `
  mutation MSearchDiagnosa($input: inputReq) {
    mSearchDiagnosa(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mPatientVisitInfo = /* GraphQL */ `
  mutation MPatientVisitInfo($input: inputReq) {
    mPatientVisitInfo(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mPatientMedicalRecords = /* GraphQL */ `
  mutation MPatientMedicalRecords($input: inputReq) {
    mPatientMedicalRecords(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mPatientDetailMedicalRecord = /* GraphQL */ `
  mutation MPatientDetailMedicalRecord($input: inputReq) {
    mPatientDetailMedicalRecord(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mInsertPatientExamination = /* GraphQL */ `
  mutation MInsertPatientExamination($input: inputReq) {
    mInsertPatientExamination(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mInsertBiayaPemeriksaan = /* GraphQL */ `
  mutation MInsertBiayaPemeriksaan($input: inputReq) {
    mInsertBiayaPemeriksaan(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpdateBiayaPemeriksaan = /* GraphQL */ `
  mutation MUpdateBiayaPemeriksaan($input: inputReq) {
    mUpdateBiayaPemeriksaan(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mDeleteBiayaPemeriksaan = /* GraphQL */ `
  mutation MDeleteBiayaPemeriksaan($input: inputReq) {
    mDeleteBiayaPemeriksaan(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mAktifkanBiayaPemeriksaan = /* GraphQL */ `
  mutation MAktifkanBiayaPemeriksaan($input: inputReq) {
    mAktifkanBiayaPemeriksaan(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mInsertDiagnosa = /* GraphQL */ `
  mutation MInsertDiagnosa($input: inputReq) {
    mInsertDiagnosa(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpdateDiagnosa = /* GraphQL */ `
  mutation MUpdateDiagnosa($input: inputReq) {
    mUpdateDiagnosa(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mDeleteDiagnosa = /* GraphQL */ `
  mutation MDeleteDiagnosa($input: inputReq) {
    mDeleteDiagnosa(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mAktifkanDiagnosa = /* GraphQL */ `
  mutation MAktifkanDiagnosa($input: inputReq) {
    mAktifkanDiagnosa(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mCetakPayment = /* GraphQL */ `
  mutation MCetakPayment($input: inputReq) {
    mCetakPayment(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUserInfo = /* GraphQL */ `
  mutation MUserInfo($input: inputReq) {
    mUserInfo(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpdateUserInfo = /* GraphQL */ `
  mutation MUpdateUserInfo($input: inputReq) {
    mUpdateUserInfo(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpdateAkunInfo = /* GraphQL */ `
  mutation MUpdateAkunInfo($input: inputReq) {
    mUpdateAkunInfo(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mInsertPembayaran = /* GraphQL */ `
  mutation MInsertPembayaran($input: inputReq) {
    mInsertPembayaran(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mSubdistrict = /* GraphQL */ `
  mutation MSubdistrict($input: inputReq) {
    mSubdistrict(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mProvince = /* GraphQL */ `
  mutation MProvince($input: inputReq) {
    mProvince(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mCity = /* GraphQL */ `
  mutation MCity($input: inputReq) {
    mCity(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mKelurahan = /* GraphQL */ `
  mutation MKelurahan($input: inputReq) {
    mKelurahan(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mStrukSetting = /* GraphQL */ `
  mutation MStrukSetting($input: inputReq) {
    mStrukSetting(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mUpdateStrukSetting = /* GraphQL */ `
  mutation MUpdateStrukSetting($input: inputReq) {
    mUpdateStrukSetting(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mViewDetailPembayaran = /* GraphQL */ `
  mutation MViewDetailPembayaran($input: inputReq) {
    mViewDetailPembayaran(input: $input) {
      statusCode
      body
      events
    }
  }
`;
export const mViewAktivitasUser = /* GraphQL */ `
  mutation MViewAktivitasUser($input: inputReq) {
    mViewAktivitasUser(input: $input) {
      statusCode
      body
      events
    }
  }
`;
