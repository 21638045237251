const listStatusMessage = [
  {
    title: 'Semua',
  },
  {
    title: 'Pengajuan Kerjasama',
  },
  {
    title: 'Kerjasama',
  },
  {
    title: 'Pengajuan Pemutusan',
  },
]

export {listStatusMessage}
