import {VmButton, VmIcons, VmInput, VmView} from 'components'

export default function SearchBar({
  keyword = '',
  label = 'search',
  placeholder = 'Cari...',
  reset = () => {},
  onSearchButton = () => {},
  onChangeCapture = () => {},
  onKeyDownCapture = () => {},
}) {
  return (
    <VmView className="flex flex-row rounded-full overflow-hidden">
      <VmView className="relative flex flex-row bg-[#F8FAFB] overflow-x-hidden !w-[-webkit-fill-available]">
        <VmInput
          typeInput="text"
          className="relative pl-6 pr-3 bg-[#F8FAFB] !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
          hidering={true}
          label={`input-${label}`}
          placeholder={placeholder}
          onChangeCapture={onChangeCapture}
          onKeyDownCapture={onKeyDownCapture}
        />
        {keyword?.length > 0 && (
          <VmView
            onClick={reset}
            className="relative flex items-center top-0 right-0 bottom-0 mr-2 cursor-pointer"
          >
            <VmIcons
              size={20}
              name="VmXCircleIcon"
              variant="outline"
              className="text-red5-payment"
            />
            <VmView className="absolute inset-0" label={`reset-${label}`} />
          </VmView>
        )}
      </VmView>
      <VmButton
        className="relative flex justify-center !flex-none p-3 !rounded-l-none !min-w-[50px] !bg-blue1-payment"
        hidering={true}
        label={`btn-${label}`}
        onClick={onSearchButton}
      >
        <VmIcons size={18} name="VmMagnifyingGlassIcon" color="#FFFFFF" />
        <VmView className="absolute inset-0" label={`btn-${label}`} />
      </VmButton>
    </VmView>
  )
}
