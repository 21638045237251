import axios from 'axios'
import {APIBPJS} from 'config/apiurl'
import qs from 'qs'

const defError = (
    code = 'WPC',
    message = 'Gagal saat mengambil data, silakan coba kembali!',
    withCode = true
  ) => ({
    status: 0,
    message: `${withCode ? '[' + code + ']' : ''}${message}`,
  }),
  dArgs = {
    type: 'provider',
    reg: 'dbdok',
    a: 'dk13',
  }
export const qIndex = async args => {
  try {
    const {data} = await axios.post(
      APIBPJS + '/bpjs-jkn/get-pendaftaran-pcare',
      qs.stringify({...args, ...dArgs})
    )

    // localStorage.setItem('resBpjs_pendaftaran', JSON.stringify(data))
    // const resBpjs_pendaftaran = JSON.parse(
    //   localStorage.getItem('resBpjs_pendaftaran')
    // )
    // return resBpjs_pendaftaran

    return data
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}
export const mDelete = async args => {
  try {
    const {data} = await axios.post(
      APIBPJS + '/bpjs-jkn/delete-pendaftaran-pcare',
      qs.stringify({
        ...args,
        ...dArgs,
        sl: {
          ket: `Menghapus Data Pendaftaran PCare${
            args?.noKartu ? ` dengan No. Kartu ${args?.noKartu}` : ''
          }`,
          url: 'pcare-data-entri-pendaftaran',
        },
      })
    )

    return data
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}

export const mBatalPendaftaran = async args => {
  try {
    const {data} = await axios.post(
      APIBPJS + '/bpjs-jkn/delete-pcare-from-kunjungan',
      qs.stringify({
        ...args,
        ...dArgs,
        sl: {
          ket: `Menghapus Data Pendaftaran PCare${
            args?.noKunjungan
              ? ` dengan No. Pendaftaran ${args?.noKunjungan}`
              : ''
          }`,
          url: 'pcare-data-entri-pendaftaran',
        },
      })
    )

    return data
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}
