import {VmView, VmText, VmIcons} from 'components'

const ErrorPage = ({code = '000', children}) => {
  return (
    <VmView className="flex flex-col fixed FullScreen justify-center items-center">
      <VmIcons
        name="VmPAnakGrayIcon"
        size={100}
        variant="avatar"
        style={{borderRadius: '50%'}}
      />
      <VmText className="text-stone-800 text-center font-bold text-4xl p-4">
        {code}
      </VmText>
      {children}
    </VmView>
  )
}

export default ErrorPage
