import {VmButton, VmIcons, VmSpinner, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {useEffect, useRef, useState} from 'react'
import Searchbar from './Searchbar'
import Tabs from './Tabs'
import CardItemMitra from './CardItemMitra'
import {eventTrigerInput, isFunction} from 'utils'
import usePullToRefresh from 'utils/usePullToRefresh'
import useAddEventListener from 'utils/useAddEventListener'
import {getElement} from 'utils/get-element'
import {useParams} from 'react-router-dom'
import {mSearchMitra, mSearchMitraKota} from './api'
import between from 'utils/between'

export default function SearchMitra({
  labelBackButton = 'modal-back-button',
  onSelectItem = (item = {}, type = '', handler = () => {}) => {},
  ...props
}) {
  const showlog = !false,
    contentRef = useRef(),
    limit = 10
  const dctid = parseInt(useParams().id)
  const [offset, setoffset] = useState(0)
  const [keyword, setkeyword] = useState('')
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  const [loadmore, setloadmore] = useState(true)
  const [onloadmore, setonloadmore] = useState(true)
  const [tablist, settablist] = useState([])
  const [data, setdata] = useState([])
  const [params, setparams] = useState({
    log: showlog,
    input: {
      id: dctid,
      limit,
      offset,
      carimobile: keyword,
      spdstatus: 2,
      'show-error-log': `${showlog}`.toUpperCase(),
    },
  })
  const onBackPress = () => {
    if (isFunction(props?.close)) {
      props?.close()
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel)
      }
      if (ariaLabel === 'reset-modal-search-mitra') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-modal-search-mitra',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-modal-search-mitra',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-modal-search-mitra') {
        return onSearchData()
      }
      if (`${ariaLabel}`?.match(/^add-mitra-/i)) {
        const item = data[`${ariaLabel}`?.replace(/^add-mitra-/i, '')]
        return onSelectItem(item, 'mitra', onBackPress)
      }
    } catch (error) {
      if (showlog) {
        console.log('onClickError', error)
      }
    }
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-modal-search-mitra') {
        return setkeyword(value)
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-modal-search-mitra' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const fetchData = (params = {}) => {
    seterror(null)
    setparams(params)
    mSearchMitra(params)
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          if (params?.input?.offset === 0) {
            setdata(resdata?.data)
          } else {
            setdata([...data, ...resdata.data])
          }
          if (resdata?.data?.length < limit) {
            setloadmore(false)
          }
        } else {
          if (loadmore) {
            seterror(resdata?.message || 'Gagal mengambil data!')
          }
          setloadmore(false)
        }
        if (showlog) console.log('fetchData:data', data)
      })
      .catch(ch => {
        if (showlog) console.log('fetchData:ch', ch)
        setloadmore(false)
        seterror('[C0] Gagal mengambil data!')
      })
      .finally(() => {
        if (showlog) console.log('fetchData:final')
        setloading(false)
        setonloadmore(false)
      })
  }
  const fetchTabList = () => {
    settablist([])
    mSearchMitraKota({input: {uid: dctid}})
      .then(({data}) => {
        if (data?.status === 1) {
          settablist(data?.data || [])
        }
        if (showlog) console.log('data', data)
      })
      .catch(ch => {
        if (showlog) console.log('ch', ch)
      })
      .finally(() => {
        if (showlog) console.log('final')
      })
  }
  const onChangeTab = (tab, title = '') => {
    if (showlog) {
      console.log('tab, title', tab, title)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        kota: title,
      },
    })
  }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        carimobile: keyword,
      },
    })
  }
  const onFetchMore = () => {
    if (data.length > 0 && loadmore && !onloadmore) {
      setonloadmore(true)
      return fetchData({
        ...params,
        input: {
          ...params.input,
          offset: params.input.offset + limit,
        },
      })
    }
  }
  const onEndReached = el => {
    const isBottom = el?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      onFetchMore()
    }
  }
  const calcTopZero = elm => {
    return between(elm?.getBoundingClientRect()?.top - elm?.offsetTop, -5, 5)
      ? 0
      : 1
  }
  const {pullChange} = usePullToRefresh({
    topTarget: calcTopZero(contentRef.current?.children[1]),
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-modal-search-mitra',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(params)
    fetchTabList()
    return () => {}
  }, [])
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  return (
    <VmView className="flex flex-col absolute inset-0 bg-blue4-payment px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Penjualan Obat Resep'}
          onBackPress={onBackPress}
          labelBackButton={labelBackButton}
        />

        <VmView className="flex flex-row items-start w-full">
          <VmView className="w-full">
            <Searchbar
              label="modal-search-mitra"
              placeholder="Cari Mitra"
              keyword={keyword}
            />
          </VmView>

          <VmButton
            onClick={onBackPress}
            color="danger"
            className="hidden md:block text-sm h-[2.4rem] ml-2"
          >
            Kembali
          </VmButton>
        </VmView>

        {/* Tabs Filter */}
        <Tabs addtionalList={tablist} onChangeTab={onChangeTab} />
        {/* Content */}
        <VmView
          ref={contentRef}
          onScroll={onScroll}
          className="block overflow-y-scroll"
        >
          <VmView
            className="refresh-container w-fit -mt-10 m-auto"
            style={{marginTop: pullChange / 3.118 || ''}}
          >
            <VmView className="refresh-icon p-2 rounded-full">
              <VmIcons
                name="VmArrowPathIcon"
                style={{transform: `rotate(${pullChange}deg)`}}
                size={25}
              />
            </VmView>
          </VmView>
          {data.map((im, index) => {
            return (
              <CardItemMitra
                key={`CardItemMitra-${index}`}
                index={index}
                data={im}
              />
            )
          })}
          {!loadmore ? (
            <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
              {error ? error : 'Sudah tidak ada data'}
            </VmView>
          ) : (
            <VmView
              key={'loadmore'}
              label="loadmore"
              className="relative self-center text-center text-gray2-payment last:mb-20 "
            >
              <VmSpinner className="text-blue1-payment" />
            </VmView>
          )}
        </VmView>
        {/* Loading Full Screen */}
        <ProgressFullScreen visible={loading} />
      </VmView>
    </VmView>
  )
}
