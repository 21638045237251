import {useSearchParams, useNavigate} from 'react-router-dom'
import {
  VmButton,
  VmDataTable,
  VmIcons,
  VmPagination,
  VmText,
  VmView,
} from 'components'
import {createRef, useEffect, useState} from 'react'
import classNames from 'classnames'
import moment from 'moment'
import {useMemo} from 'react'
import axios from 'axios'
import QueryString from 'qs'

import {
  customExportAttrWeb,
  customPrintAttrWeb,
  customPrintRmAttrWeb,
  pageTitle,
} from './fields'
import getYears from 'utils/get-years'
import {checkCustomBpjs, mExport, qIndex, qIndexCount} from './api'
import {VmPrinterIcon} from 'components/icons/solid'
import useAddEventListener from 'utils/useAddEventListener'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import {ProgressFullScreen, VmModalExport} from 'molecules'
import controllers from 'controllers/PatientExaminationMobile'
import {APISATSET} from 'config/apiurl'

export default function WebLapRekMed() {
  const navigate = useNavigate()
  const modalExport = createRef()
  const modalPrint = createRef()
  const [searchParams] = useSearchParams()
  const uid = searchParams.get('dctid'),
    defaultfilter = {
      tgl: '',
      noruj: '',
      norm: '',
      nik: '',
      nobpjs: '',
      nama: '',
      jk: '',
      alamat: '',
      icdkode: '',
      icdnama: '',
      stsruj: '',
    },
    today = moment().format('YYYY-MM-DD'),
    errorMessage = '[C0] Gagal mengambil data!',
    status0Message = '[S0] Gagal mengambil data!',
    lstperiode = ['Tanggal', 'Bulan', 'Tahun'],
    [periodes, setperiodes] = useState([]),
    lstyear = getYears(50).reverse(),
    [years, setyears] = useState([]),
    [periode, setperiode] = useState(1),
    [dateperiode, setdateperiode] = useState({
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('month').format('YYYY-MM-DD'),
    }),
    lsttype = ['Tunai', 'Piutang', 'Kartu'],
    lstjk = ['Laki-laki', 'Perempuan'],
    [types, settypes] = useState([]),
    [type, settype] = useState(-1),
    [jks, setjks] = useState([]),
    [jk, setjk] = useState(-1),
    [lsticd, setlsticd] = useState([]),
    [icd1s, seticd1s] = useState([]),
    [icd1, seticd1] = useState(-1),
    [icd2s, seticd2s] = useState([]),
    [icd2, seticd2] = useState(-1),
    [icdtxt1, seticdtxt1] = useState(''),
    [icdtxt2, seticdtxt2] = useState(''),
    [newlimit, setnewlimit] = useState(10),
    [newoffset, setnewoffset] = useState(0),
    [page, setpage] = useState(1),
    [date, setdate] = useState(Date.now()),
    [exportparams, setexportparams] = useState({}),
    [newvariables, setnewvariables] = useState({
      uid,
      limit: newlimit,
      offset: newoffset,
      ...defaultfilter,
      ...dateperiode,
    }),
    [data, setdata] = useState([]),
    [datacount, setdatacount] = useState(0),
    [message, setmessage] = useState(''),
    [loading, setloading] = useState(true),
    [customBpjs, setCustomBpjs] = useState(false),
    [loadingmore, setloadingmore] = useState(true),
    [onloadingmore, setonloadingmore] = useState(false),
    [error, seterror] = useState(false),
    [errordata, seterrordata] = useState(),
    [exportkey, setexportkey] = useState(null),
    [details, setDetails] = useState([]),
    [resetSorting, setresetSorting] = useState(null),
    [confirmProps, setconfirmProps] = useState(defaultpropsalertform),
    [alertProps, setAlertProps] = useState(defaultpropsalertform)
  const toggleDetails = index => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }
  let fields = [
    {
      key: 'no',
      label: 'No.',
      sorter: false,
      filter: false,
      _style: {width: '5%'},
    },
    {
      key: 'action',
      label: 'Action',
      sorter: false,
      filter: false,
      _style: {width: '10%'},
    },
    {key: 'tgl', label: 'Tanggal Rekam Medis', _style: {width: '20%'}},
    {key: 'tglkun', label: 'Tanggal Kunjungan', _style: {width: '20%'}},
    {
      key: 'nokun',
      label: 'No. Kunjungan',
      format: (val, item) => {
        return (
          <>
            <VmView className="flex flex-row gap-2">
              {item?.kunid_satusehat?.length > 0 && (
                <VmView className="border border-[#03B1AF] rounded-md bg-white">
                  <VmIcons
                    size={18}
                    name="VmSatuSehat"
                    className="stroke-[2.5]"
                  />
                </VmView>
              )}
              {val || '-'}
            </VmView>
          </>
        )
      },
      _style: {width: '40%'},
    },
    {
      key: 'nopcarebpjs',
      label: 'No. Pendaftaran Pcare',
      _style: {width: '40%'},
    },
    {key: 'kunpcarebpjs', label: 'No. Kunjungan Pcare', _style: {width: '40%'}},
    {key: 'norm', label: 'No. RM', _style: {width: '40%'}},
    {key: 'nik', label: 'NIK', sorter: false, _style: {width: '40%'}},
    {key: 'nobpjs', label: 'No. BPJS', _style: {width: '40%'}},
    {key: 'nama', label: 'Nama Pasien', _style: {width: '40%'}},
    {key: 'jk', label: 'Jenis Kelamin', _style: {width: '40%'}},
    {key: 'alamat', label: 'Alamat Pasien', _style: {width: '40%'}},
    {key: 'keluhanutama', label: 'Keluhan Utama', _style: {width: '40%'}},
    {key: 'keluhantambahan', label: 'Keluhan Tambahan', _style: {width: '40%'}},
    {key: 'icdutama', label: 'Diagnosa Utama', _style: {width: '40%'}},
    {key: 'icdtambahan', label: 'Diagnosa Sekunder', _style: {width: '40%'}},
    {key: 'nadi', label: 'Nadi', _style: {width: '40%'}},
    {key: 'suhu', label: 'Suhu', _style: {width: '40%'}},
    {key: 'nafas', label: 'Pernafasan', _style: {width: '40%'}},
    {key: 'tekdarah', label: 'Tek. Darah', _style: {width: '40%'}},
    {key: 'tinggi', label: 'Tinggi Badan', _style: {width: '40%'}},
    {key: 'berat', label: 'Berat Badan', _style: {width: '40%'}},
    {key: 'perut', label: 'Lingkar Perut', _style: {width: '40%'}},
    {key: 'hasil', label: 'Hasil Pemeriksaan', _style: {width: '40%'}},
  ]

  // check custom bpjs
  let menuCustomBpjs = ['nopcarebpjs', 'kunpcarebpjs']
  if (!customBpjs) fields = fields.filter(i => !menuCustomBpjs.includes(i.key))

  const LabelHeader = ({name = '', state = {}, SortIcon = null}) => {
    return (
      <div
        className={classNames(
          'flex flex-row items-center justify-between text-primary min-w-max text-prevent-select !text-sm'
        )}
      >
        {name}
        {SortIcon}
      </div>
    )
  }
  const classNameFilter = classNames(
      'flex flex-1 grow bg-gray-100 text-sm',
      'border py-2 px-3 text-grey-darkest focus:outline-none',
      'focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
      'focus:ring-offset-gray-100 rounded-lg'
    ),
    classDatePicker = classNames(
      'text-prevent-select !text-xs',
      'flex gap-3 bg-white relative',
      'border-2 py-2 px-5 border-slate-600',
      'text-grey-darkest focus:outline-none',
      'focus:ring-2 focus:ring-slate-500 focus:ring-offset-2',
      'focus:ring-offset-slate-100 rounded-xl hover:border-blue-500',
      'justify-between items-center text-slate-700 font-bold cursor-pointer'
    )
  const fetchData = args => {
    qIndex(args)
      .then(data => {
        // console.log('data', data)
        setmessage(data?.message || '')
        if (data?.status === 1) {
          setdata(
            data?.data?.length > 0
              ? data.data.map(im => ({
                  ...im,
                  _classdetail: 'bg-gray-100',
                  _tdclasses: 'align-top',
                }))
              : []
          )
        } else if (data?.status === 2) {
          seterrordata(data?.error)
          seterror(data?.message || status0Message)
        } else {
          seterrordata(data?.error)
          seterror(data?.message || status0Message)
        }
      })
      .catch(error => {
        // console.log('error', error)
        seterrordata(error)
        seterror(errorMessage)
      })
      .finally(() => {
        setonloadingmore(false)
        setloading(false)
      })
  }
  const fetchDataCount = args => {
      qIndexCount(args)
        .then(data => {
          // console.log('datacount', data)
          // setmessage(data?.message || '')
          if (data?.status === 1) {
            setdatacount(data?.data?.total || 0)
          } else {
            // seterrordata(data?.error)
            // seterror(data?.message || status0Message)
          }
        })
        .catch(error => {
          // console.log('errorcount', error)
          // seterrordata(error)
          // seterror(errorMessage)
        })
        .finally(() => {
          // setonloadingmore(false)
          // setloading(false)
        })
    },
    onActivePageChange = page => {
      // console.log('page', page, (page - 1) * newlimit)
      setpage(page)
      setonloadingmore(true)
      setDetails([])
      setnewvariables(prefVal => {
        const newVal = prefVal
        newVal['limit'] = newlimit
        newVal['offset'] = (page - 1) * newlimit
        return newVal
      })
      setdate(Date.now())
    },
    onSorterValueChange = e => {
      setonloadingmore(true)
      setDetails([])
      setnewvariables(prefVal => {
        const newVal = prefVal
        newVal['sorting'] = e
        newVal['limit'] = newlimit
        newVal['offset'] = (page - 1) * newlimit
        return newVal
      })
      setdate(Date.now())
    },
    onFilterValueChange = (key, value) => {
      // console.log('first', key, typeof newvariables[key], typeof value)
      if (newvariables[key] !== value) {
        setonloadingmore(true)
        setDetails([])
        setnewvariables(prefVal => {
          const newVal = prefVal
          newVal[key] = `${value}`.trim()
          newVal['limit'] = newlimit
          newVal['offset'] = 0
          return newVal
        })
        setdate(Date.now())
      }
    },
    onPeriodeChange = e => {
      if (e?.type === 'typ') {
        if (periodes?.length > 0) {
          setperiodes([])
        } else {
          setperiodes(lstperiode)
        }
        if (e?.actv === false && typeof e?.idx !== undefined) {
          var dtype = 'month'
          if (e?.idx === 2) {
            dtype = 'year'
          }
          const newperiode = {
            start: moment().startOf(dtype).format('YYYY-MM-DD'),
            end: moment().endOf(dtype).format('YYYY-MM-DD'),
          }
          setdateperiode(newperiode)
          setperiode(e?.idx)
        }
      }
    },
    Absearch = () => {
      setdata([])
      setDetails([])
      settype(-1)
      setnewvariables({
        limit: newlimit,
        offset: 0,
        ...defaultfilter,
        ...dateperiode,
        uid,
      })
      setdate(Date.now())
    },
    Abrefresh = () => {
      navigate(0)
      // const newperiode = {
      //   start: moment().startOf('month').format('YYYY-MM-DD'),
      //   end: moment().endOf('month').format('YYYY-MM-DD'),
      // }
      // setdata([])
      // setDetails([])
      // setperiode(0)
      // settype(-1)
      // setnewvariables({
      //   limit: newlimit,
      //   offset: 0,
      //   ...defaultfilter,
      //   ...newperiode,
      //   uid,
      // })
      // setdateperiode(newperiode)
      // setdate(Date.now())
    },
    Abprint = () => {
      modalPrint.current.open()
    },
    Abexport = () => {
      modalExport.current.open()
    },
    AbSyncSatusehat = () => {
      return setAlertProps({
        ...defaultpropsalertform,
        key: `${Date.now()}`,
        type: 4,
        yesText: 'Ya',
        noText: 'Tidak',
        title: 'Sinkron SATUSEHAT',
        position: 'fit',
        visible: true,
        closeable: false,
        children: (
          <>Apakah anda yakin ingin melanjutkan proses sinkron satusehat?</>
        ),
        onConfirm: SyncSatusehat,
        onClose: closeConfirm,
        onCancel: closeConfirm,
      })
    },
    closeAlert = () => setAlertProps(defaultpropsalertform),
    closeConfirm = () => setconfirmProps(defaultpropsalertform),
    closeModalExport = () => modalExport.current.close(),
    closeModalPrint = () => modalPrint.current.close(),
    errorAlert = caption => {
      return setAlertProps({
        ...alertProps,
        type: 4,
        visible: true,
        position: 'fit',
        children: <>{caption}</>,
        onConfirm: closeAlert,
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    },
    SyncSatusehat = async () => {
      setloading(true)
      axios
        .post(
          `${APISATSET}/satusehat-kunjungan/sync-data-encounter`,
          QueryString.stringify({
            userid: uid,
          })
        )
        .then(({data: res}) => {
          if (res?.status === 1) {
            setAlertProps({
              ...defaultpropsalertform,
              key: `${Date.now()}`,
              type: 1,
              title: 'Berhasil',
              position: 'fit',
              visible: true,
              children: <>Berhasil sinkron satusehat.</>,
              onConfirm: closeConfirm,
              onClose: closeConfirm,
              onCancel: closeConfirm,
            })
          } else {
            return setAlertProps({
              ...defaultpropsalertform,
              key: `${Date.now()}`,
              type: 3,
              position: 'fit',
              visible: true,
              children: (
                <>
                  {res?.message ||
                    '[S0] Terjadi kesalahan saat sinkron satusehat.'}
                </>
              ),
              onConfirm: closeConfirm,
              onClose: closeConfirm,
              onCancel: closeConfirm,
            })
          }
        })
        .catch(e => {
          return setAlertProps({
            ...defaultpropsalertform,
            key: `${Date.now()}`,
            type: 3,
            position: 'fit',
            visible: true,
            children: <>Terjadi kesalahan saat sinkron satusehat.</>,
            onConfirm: closeConfirm,
            onClose: closeConfirm,
            onCancel: closeConfirm,
          })
        })
        .finally(() => {
          setloading(false)
        })
    },
    handleExport = async (url, val) => {
      if (data.length > 0 && typeof url === 'string') {
        setexportparams(val)
        setexportkey(url)
      } else {
        handleExportError(true, true)
      }
    },
    handleExportError = (excel = false, empty = false) => {
      if (empty) {
        errorAlert(`Tidak ada data yang dapat di ${excel ? 'export' : 'cetak'}`)
      } else {
        errorAlert(
          `Gagal saat ${excel ? 'export' : 'cetak'}, silakan coba kembali`
        )
      }
    },
    getIcd = (v = {limit: 10, offset: 0}) => {
      // Get data diagnosa
      controllers(
        {
          key: 'search-diagnosa',
          dispatch: () => {},
          _ldata: 'mSearchDiagnosa',
          dctid: uid,
          ...v,
        },
        navigate
      )
        .then(({data: res, message = `[AE2] Terjadi Kesalahan!`}) => {
          if (res?.length > 0) {
            setlsticd(res)
          }
        })
        .catch(err => {
          // console.log('err', err)
        })
        .finally(() => {})
    }

  const onClick = e => {
    try {
      const {ariaLabel} = e?.target,
        lstactbtn = {
          'ab-search': Absearch,
          'ab-refresh': Abrefresh,
          'ab-print': Abprint,
          'ab-export': Abexport,
          'ab-sync-satusehat': AbSyncSatusehat,
          'dtp-periode': () => onPeriodeChange({type: 'typ'}),
          'dtp-year': () => {
            if (years?.length > 0) {
              setyears([])
            } else {
              setyears(lstyear)
            }
          },
          'typ-stsruj': () => {
            if (types?.length > 0) {
              settypes([])
            } else {
              settypes(lsttype)
            }
          },
          'typ-jk': () => {
            if (jks?.length > 0) {
              setjks([])
            } else {
              setjks(lstjk)
            }
          },
          'typ-icd1': () => {
            if (icd1s?.length > 0) {
              seticd1s([])
              seticdtxt1('')
            } else {
              if (lsticd?.length === 0) {
                getIcd()
              } else {
                seticd1s(lsticd)
              }
            }
          },
          'typ-icd2': () => {
            if (icd2s?.length > 0) {
              seticd2s([])
              seticdtxt2('')
            } else {
              if (lsticd?.length === 0) {
                getIcd()
              } else {
                seticd2s(lsticd)
              }
            }
          },
        }
      // console.log('ariaLabel', ariaLabel)
      if (typeof lstactbtn[ariaLabel] === 'function') {
        lstactbtn[ariaLabel]()
      } else {
        if (periodes?.length > 0) {
          setperiodes([])
        }
        if (types?.length > 0) {
          settypes([])
        }
        if (years?.length > 0) {
          setyears([])
        }
        if (jks?.length > 0) {
          setjks([])
        }
        if (icd1s?.length > 0 && ariaLabel !== 'input-icdutama') {
          seticdtxt1('')
          seticd1s([])
        }
        if (icd2s?.length > 0 && ariaLabel !== 'input-icdtambahan') {
          seticdtxt2('')
          seticd2s([])
        }
      }
    } catch (error) {}
  }
  useAddEventListener({eventName: 'click', handler: onClick})
  useEffect(() => {
    if (uid) {
      if (newvariables?.offset === 0) {
        setpage(1)
        setloading(true)
        setloadingmore(true)
        fetchDataCount(newvariables)
      }
      seterror(null)
      seterrordata(null)
      fetchData(newvariables)
    }
    return () => {}
  }, [newvariables, date, uid]) /* eslint-disable-line */
  useEffect(() => {
    // console.log('exportkey', loading, exportkey)
    if (
      typeof exportkey === 'string' &&
      exportkey?.length > 0 &&
      loading === false
    ) {
      setloading(true)
      mExport(exportkey, {
        uid,
        type: periode === 0 ? 3 : periode === 1 ? 2 : periode === 2 ? 1 : 0,
        ...newvariables,
        ...exportparams,
      })
        .then(data => {
          if (data.status === 1) {
            window.open(data.data, '_blank')
            setAlertProps({
              ...alertProps,
              type: 1,
              visible: true,
              position: 'fit',
              children: <>Data berhasil didownload.</>,
              onConfirm: closeAlert,
              onClose: closeAlert,
              onCancel: closeAlert,
            })
          } else {
            errorAlert(`${data?.message || '[S0] Gagal mengolah data!'}`)
          }
        })
        .catch(e => {
          handleExportError(true, false)
        })
        .finally(() => {
          setloading(false)
          setexportkey(null)
        })
    }
  }, [loading, exportkey, periode, newvariables, exportparams])

  useEffect(() => {
    async function customBpjs() {
      const cekCustom = await checkCustomBpjs(uid)
      setCustomBpjs(cekCustom)
    }
    customBpjs()
  }, [])

  const {pages, dtstart, dtend} = useMemo(() => {
    const dtperpage = page * newvariables?.limit
    return {
      pages: Math.ceil((datacount || 0) / (newvariables?.limit || 1)),
      dtstart: dtperpage - (newvariables?.limit - 1),
      dtend: dtperpage - newvariables?.limit + data.length,
    }
  }, [page, datacount, newvariables, data])

  return (
    <VmView className="bg-white p-5 useScrollBar">
      <VmView className="flex justify-center items-center pt-12 pb-6">
        <VmText className="text-lg text-slate-700 font-semibold">
          {pageTitle}
        </VmText>
      </VmView>
      <VmView className="flex gap-4 flex-col-reverse">
        {/* Count Data */}
        <p className="pt-4 pb-6 text-slate-700 text-sm">{`Menampilkan ${
          datacount > 0 ? ` ${dtstart} - ${dtend} data dari total` : ``
        } ${datacount} data`}</p>
        {/* Action Buttons */}
        <VmView className="flex gap-3 items-center">
          <VmButton
            className={
              'relative rounded-xl pr-8 pl-12 py-2 text-prevent-select !text-sm'
            }
            color="info"
            label="ab-search"
          >
            <VmIcons
              size={18}
              name="VmMagnifyingGlassIcon"
              className=" absolute left-5 stroke-[2.5]"
              variant="outline"
            />
            Cari
          </VmButton>
          <VmButton
            className={
              'relative rounded-xl pr-8 pl-12 py-2 text-prevent-select !text-sm'
            }
            color="light"
            label="ab-refresh"
          >
            <VmIcons
              size={18}
              name="VmArrowPathIcon"
              className=" absolute left-5 stroke-[2.5]"
              variant="outline"
            />
            Refresh
          </VmButton>
          <VmButton
            className={
              'relative rounded-xl pr-8 pl-12 py-2 text-prevent-select !text-sm'
            }
            color="warning"
            label="ab-print"
          >
            <VmIcons
              size={18}
              name="VmPrinterIcon"
              className=" absolute left-5 stroke-[2.5]"
            />
            Cetak
          </VmButton>
          <VmButton
            className={
              'relative rounded-xl pr-8 pl-[3rem] py-2 text-prevent-select !text-sm'
            }
            color="success"
            label="ab-export"
          >
            <VmIcons
              size={20}
              name="VmMaterialSymExportNotes"
              className=" absolute left-5 stroke-[2.5]"
              variant="outline"
            />
            Export
          </VmButton>
          <VmButton
            className={
              'relative rounded-xl pr-8 pl-[3rem] py-2 text-prevent-select !text-sm'
            }
            color="success"
            variant="outline"
            label="ab-sync-satusehat"
          >
            <VmIcons
              size={20}
              name="VmSatuSehat"
              className=" absolute left-5 stroke-[2.5]"
              // variant="outline"
            />
            Sinkron SATUSEHAT
          </VmButton>
        </VmView>
        {/* Filter Periode */}
        <VmView className="flex gap-4 items-center text-sm">
          <VmView className="text-slate-700 font-semibold">
            Pilih Periode
          </VmView>
          <VmView className="relative">
            <VmView
              label="dtp-periode"
              className={classNames(
                'flex flex-1 grow bg-white',
                'border-2 py-2 px-3 border-slate-600',
                'text-grey-darkest focus:outline-none',
                'focus:ring-2 focus:ring-slate-500 focus:ring-offset-2',
                'focus:ring-offset-slate-100 rounded-xl',
                'flex justify-between items-center min-w-[15rem]',
                'text-slate-700 font-bold cursor-pointer hover:border-blue-500',
                'text-prevent-select !text-xs'
              )}
            >
              {`Berdasarkan ${lstperiode[periode]}`}&nbsp;&nbsp;&nbsp;&nbsp;
              <VmIcons
                size={12}
                name="VmPlayIcon"
                className="stroke-[2.5] rotate-90"
              />
            </VmView>
            {periodes?.length > 0 && (
              <VmView className="absolute inset-0 top-11">
                <ul className="flex flex-col px-1 py-2 gap-2 bg-white rounded-lg shadow-md shadow-slate-600/50">
                  {periodes.map((im, idx) => {
                    const pactive = periode === idx
                    return (
                      <li
                        key={JSON.stringify({im, idx})}
                        onClick={() =>
                          onPeriodeChange({type: 'typ', actv: pactive, idx})
                        }
                        className={classNames(
                          pactive ? 'bg-slate-300/50 text-slate-800' : '',
                          'text-prevent-select !text-xs',
                          'whitespace-nowrap rounded-full p-2 px-4',
                          'overflow-ellipsis overflow-hidden hover:cursor-pointer',
                          'hover:bg-slate-300/50 text-slate-700 hover:text-slate-800'
                        )}
                      >
                        <strong>{im}</strong>
                      </li>
                    )
                  })}
                </ul>
              </VmView>
            )}
          </VmView>
          {periode === 0 ? (
            <>
              {/* Tanggal */}
              <VmView className={classDatePicker}>
                <VmIcons
                  size={16}
                  name="VmCalendarDaysIcon"
                  className="stroke-[2.5]"
                />
                {moment(dateperiode.start).format('DD MMM YYYY')}
                <input
                  type="date"
                  aria-label="date-start-picker"
                  className="absolute rounded-xl inset-0 bg-transparent text-transparent border-0 outline-0 text-center text-9xl input-date-picker-transparent"
                  max={today}
                  onChange={e => {
                    setdateperiode(pd => {
                      return {
                        ...pd,
                        start:
                          e?.target?.value || moment().format('YYYY-MM-DD'),
                      }
                    })
                  }}
                />
              </VmView>
              <VmView className={classDatePicker}>
                <VmIcons
                  size={16}
                  name="VmCalendarDaysIcon"
                  className="stroke-[2.5]"
                />
                {moment(dateperiode.end).format('DD MMM YYYY')}
                <input
                  type="date"
                  aria-label="date-end-picker"
                  className="absolute rounded-xl inset-0 bg-transparent text-transparent border-0 outline-0 text-center text-9xl input-date-picker-transparent"
                  max={today}
                  onChange={e => {
                    setdateperiode(pd => {
                      return {
                        ...pd,
                        end: e?.target?.value || moment().format('YYYY-MM-DD'),
                      }
                    })
                  }}
                />
              </VmView>
            </>
          ) : periode === 1 ? (
            <>
              {/* Bulan */}
              <VmView className={classDatePicker}>
                <VmIcons
                  size={16}
                  name="VmCalendarDaysIcon"
                  className="stroke-[2.5]"
                />
                {moment(dateperiode.start).format('MMMM YYYY')}
                <input
                  type="month"
                  aria-label="date-start-picker"
                  className="absolute rounded-xl inset-0 bg-transparent text-transparent border-0 outline-0 text-center text-9xl input-date-picker-transparent"
                  max={today}
                  onChange={e => {
                    setdateperiode(pd => {
                      return {
                        ...pd,
                        start:
                          moment(e?.target?.value)
                            .startOf('month')
                            .format('YYYY-MM-DD') ||
                          moment().startOf('month').format('YYYY-MM-DD'),
                        end:
                          moment(e?.target?.value)
                            .endOf('month')
                            .format('YYYY-MM-DD') ||
                          moment().endOf('month').format('YYYY-MM-DD'),
                      }
                    })
                  }}
                />
              </VmView>
            </>
          ) : periode === 2 ? (
            <>
              {/* Tahun */}
              <VmView className={classDatePicker} label="dtp-year">
                <VmIcons
                  size={16}
                  name="VmCalendarDaysIcon"
                  className="stroke-[2.5]"
                />
                {moment(dateperiode.start).format('YYYY')}
                {years?.length > 0 && (
                  <VmView
                    className={classNames(
                      'absolute inset-0 top-12 w-max h-60 bg-white',
                      'rounded-lg shadow-md shadow-slate-600/50 overflow-y-scroll'
                    )}
                  >
                    <ul className="grid grid-cols-4 px-1 py-2 gap-1">
                      {years.map((im, idx) => {
                        const yactive =
                          moment(dateperiode.start).format('YYYY') === `${im}`
                        return (
                          <li
                            key={JSON.stringify({im, idx})}
                            onClick={() => {
                              if (!yactive) {
                                setdateperiode(pd => {
                                  return {
                                    ...pd,
                                    start: moment(`01-01-${im}`)
                                      .startOf('year')
                                      .format('YYYY-MM-DD'),
                                    end: moment(`01-01-${im}`)
                                      .endOf('year')
                                      .format('YYYY-MM-DD'),
                                  }
                                })
                              }
                            }}
                            className={classNames(
                              yactive ? 'bg-slate-300/50 text-slate-800' : '',
                              'text-prevent-select !text-xs text-center',
                              'whitespace-nowrap rounded-lg p-1 px-3',
                              'overflow-ellipsis overflow-hidden hover:cursor-pointer',
                              'hover:bg-slate-300/50 text-slate-700 hover:text-slate-800'
                            )}
                          >
                            <strong>{im}</strong>
                          </li>
                        )
                      })}
                    </ul>
                  </VmView>
                )}
              </VmView>
            </>
          ) : (
            <></>
          )}
        </VmView>
      </VmView>
      <VmDataTable
        key={JSON.stringify({data, newvariables})}
        items={data}
        totalCount={data?.length}
        fields={fields}
        columnFilter
        activeNumbers={false}
        activeActions={false}
        showDataCount={false}
        showOverTableActions={false}
        sorter={{resetable: true}}
        resetSorting={resetSorting}
        onSorterValueChange={onSorterValueChange}
        containerClass={classNames(
          'min-h-[30rem]'
          // data?.length > 0 ? '' : '!overflow-hidden'
        )}
        noItemsViewSlot={
          <div className="relative h-[15rem]">
            <div className="flex flex-col items-center justify-center text-center absolute top-16 left-[20%] min-w-max">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
              >
                <path
                  d="M33.75 7.5C40.7119 7.5 47.3887 10.2656 52.3116 15.1884C57.2344 20.1113 60 26.7881 60 33.75C60 39.6375 58.125 45 54.7875 49.4625L57.7875 52.5H60L82.5 75L75 82.5L52.5 60V57.7875L49.4625 54.7875C44.9351 58.1948 39.4163 60.0256 33.75 60C26.7881 60 20.1113 57.2344 15.1884 52.3116C10.2656 47.3887 7.5 40.7119 7.5 33.75C7.5 26.7881 10.2656 20.1113 15.1884 15.1884C20.1113 10.2656 26.7881 7.5 33.75 7.5ZM18.75 30V37.5H48.75V30H18.75Z"
                  fill="#EF4444"
                />
              </svg>
              <h2 className="flex flex-wrap px-1 justify-center text-slate-700 text-2xl font-bold ">
                Data tidak ditemukan!
              </h2>
            </div>
          </div>
        }
        columnHeaderSlot={{
          tgl: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          tglkun: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          nokun: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          nopcarebpjs: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          kunpcarebpjs: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          norm: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          nobpjs: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          nama: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          jk: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          alamat: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          keluhanutama: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          keluhantambahan: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          icdutama: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          icdtambahan: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          nadi: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          suhu: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          nafas: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          tekdarah: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          tinggi: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          berat: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          perut: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          hasil: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
        }}
        columnFilterSlot={{
          tgl: (colName, state, SortIcon) => <div className="min-w-[11rem]" />,
          tglkun: (colName, state, SortIcon) => (
            <div className="min-w-[11rem]" />
          ),
          nokun: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          nopcarebpjs: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          kunpcarebpjs: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          norm: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          nik: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          nobpjs: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          nama: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          jk: (colName, state, SortIcon) => (
            <VmView className="relative">
              <VmView
                label="typ-jk"
                className={classNames(
                  classNameFilter,
                  'flex justify-between items-center min-w-[11rem] text-slate-700 font-bold cursor-pointer text-prevent-select !text-sm'
                )}
              >
                {lstjk[jk] || 'Semua'}
                <VmIcons
                  size={18}
                  name={lstjk[jk] ? 'VmXCircleIcon' : 'VmChevronDownIcon'}
                  className={classNames(
                    lstjk[jk] ? 'stroke-red-500' : '',
                    'stroke-[2.5]'
                  )}
                  label={lstjk[jk] ? 'typ-jk-pick' : 'typ-jk'}
                  variant="outline"
                  onClick={() => {
                    if (lstjk[jk]) {
                      onFilterValueChange(colName, ``)
                      setjk(-1)
                    }
                  }}
                />
              </VmView>
              {jks?.length > 0 && (
                <VmView className="absolute inset-0 top-12 text-prevent-select !text-sm z-[1]">
                  <ul className="flex flex-col py-4 px-2 gap-2 bg-white rounded-lg shadow-md shadow-slate-600/50">
                    {/* <li>
                      <input
                        className={classNames(classNameFilter, 'max-w-[8rem]')}
                        aria-label={`input-${colName}`}
                      />
                    </li> */}
                    {jks.map((im, idx) => {
                      const colors = [
                        'bg-slate-100 hover:bg-slate-300/50 text-slate-700 hover:text-slate-800',
                        'bg-slate-100 hover:bg-slate-300/50 text-slate-700 hover:text-slate-800',
                      ]
                      const activeColors = [
                        'bg-slate-300/50 text-slate-800',
                        'bg-slate-300/50 text-slate-800',
                      ]
                      const activejk = jk === idx
                      return (
                        <li
                          key={JSON.stringify({im, idx})}
                          className={classNames(
                            activejk ? activeColors[jk] : colors[idx],
                            'whitespace-nowrap rounded-full p-2',
                            'overflow-ellipsis overflow-hidden cursor-pointer text-center'
                          )}
                          onClick={() => {
                            if (!activejk) {
                              setjk(idx)
                              onFilterValueChange(
                                colName,
                                `${im}`.toLowerCase()
                              )
                            } else {
                              onFilterValueChange(colName, ``)
                              setjk(-1)
                            }
                          }}
                        >
                          <strong>{im}</strong>
                        </li>
                      )
                    })}
                  </ul>
                </VmView>
              )}
            </VmView>
          ),
          alamat: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          keluhanutama: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          keluhantambahan: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          icdutama: (colName, state, SortIcon) => {
            var icdnama = null,
              idxname = lsticd.findIndex(fn => fn?.id === icd1)
            if (idxname >= 0) {
              idxname = lsticd[lsticd.findIndex(fn => fn?.id === icd1)]
              icdnama = idxname?.code?.trim() + ' / ' + idxname?.name?.trim()
            }
            return (
              <VmView className="relative">
                <VmView
                  label="typ-icd1"
                  className={classNames(
                    classNameFilter,
                    'flex justify-between items-center min-w-[20rem] text-slate-700 font-bold cursor-pointer text-prevent-select !text-sm'
                  )}
                >
                  {icdnama || 'Semua'}
                  <VmIcons
                    size={18}
                    name={icdnama ? 'VmXCircleIcon' : 'VmChevronDownIcon'}
                    className={classNames(
                      icdnama ? 'stroke-red-500' : '',
                      'stroke-[2.5]'
                    )}
                    label={icdnama ? 'typ-icd1-pick' : 'typ-icd1'}
                    variant="outline"
                    onClick={() => {
                      if (icdnama) {
                        onFilterValueChange(colName, ``)
                        seticd1(-1)
                      }
                    }}
                  />
                </VmView>
                {icd1s?.length > 0 && (
                  <VmView className="absolute inset-0 top-12 text-prevent-select !text-sm z-[1] h-[20rem] overflow-y-scroll">
                    <ul className="flex flex-col py-4 px-2 gap-2 bg-white rounded-lg shadow-md shadow-slate-600/50">
                      <li className=" bg-white sticky top-0 py-2">
                        <input
                          placeholder="Cari Diagnosa"
                          onChangeCapture={v => {
                            seticdtxt1(v?.target?.value || '')
                          }}
                          className={classNames(
                            classNameFilter,
                            'min-w-[-webkit-fill-available] max-w-[8rem]'
                          )}
                          aria-label={`input-${colName}`}
                        />
                      </li>
                      {icd1s
                        .filter(fl =>
                          `${icdtxt1}`.trim()?.length > 0
                            ? `${fl?.code?.trim() + ' / ' + fl?.name?.trim()}`
                                .toLowerCase()
                                .includes(`${icdtxt1}`.toLowerCase())
                            : true
                        )
                        .map((im, idx) => {
                          const colors = [
                            'bg-slate-100 hover:bg-slate-300/50 text-slate-700 hover:text-slate-800',
                            'bg-slate-100 hover:bg-slate-300/50 text-slate-700 hover:text-slate-800',
                          ]
                          const activeColors = [
                            'bg-slate-300/50 text-slate-800',
                            'bg-slate-300/50 text-slate-800',
                          ]
                          const activeicd1 = icd1 === im?.id
                          return (
                            <li
                              key={JSON.stringify({im, idx})}
                              className={classNames(
                                activeicd1 ? activeColors[icd1] : colors[0],
                                'rounded-md p-2',
                                'overflow-ellipsis overflow-hidden cursor-pointer'
                              )}
                              onClick={() => {
                                if (!activeicd1) {
                                  seticd1(im?.id)
                                  onFilterValueChange(
                                    colName,
                                    `${im?.id}`.toLowerCase()
                                  )
                                } else {
                                  onFilterValueChange(colName, ``)
                                  seticd1(-1)
                                }
                              }}
                            >
                              <strong>
                                {im?.code?.trim() + ' / ' + im?.name?.trim()}
                              </strong>
                            </li>
                          )
                        })}
                    </ul>
                  </VmView>
                )}
              </VmView>
            )
          },
          icdtambahan: (colName, state, SortIcon) => {
            var icdnama = null,
              idxname = lsticd.findIndex(fn => fn?.id === icd2)
            if (idxname >= 0) {
              idxname = lsticd[lsticd.findIndex(fn => fn?.id === icd2)]
              icdnama = idxname?.code?.trim() + ' / ' + idxname?.name?.trim()
            }
            return (
              <VmView className="relative">
                <VmView
                  label="typ-icd2"
                  className={classNames(
                    classNameFilter,
                    'flex justify-between items-center min-w-[20rem] text-slate-700 font-bold cursor-pointer text-prevent-select !text-sm'
                  )}
                >
                  {icdnama || 'Semua'}
                  <VmIcons
                    size={18}
                    name={icdnama ? 'VmXCircleIcon' : 'VmChevronDownIcon'}
                    className={classNames(
                      icdnama ? 'stroke-red-500' : '',
                      'stroke-[2.5]'
                    )}
                    label={icdnama ? 'typ-icd2-pick' : 'typ-icd2'}
                    variant="outline"
                    onClick={() => {
                      if (icdnama) {
                        onFilterValueChange(colName, ``)
                        seticd2(-1)
                      }
                    }}
                  />
                </VmView>
                {icd2s?.length > 0 && (
                  <VmView className="absolute inset-0 top-12 text-prevent-select !text-sm z-[1] h-[20rem] overflow-y-scroll">
                    <ul className="flex flex-col py-4 px-2 gap-2 bg-white rounded-lg shadow-md shadow-slate-600/50">
                      <li className=" bg-white sticky top-0 py-2">
                        <input
                          placeholder="Cari Diagnosa"
                          onChangeCapture={v => {
                            seticdtxt2(v?.target?.value || '')
                          }}
                          className={classNames(
                            classNameFilter,
                            'min-w-[-webkit-fill-available] max-w-[8rem]'
                          )}
                          aria-label={`input-${colName}`}
                        />
                      </li>
                      {icd2s
                        .filter(fl =>
                          `${icdtxt2}`.trim()?.length > 0
                            ? `${fl?.code?.trim() + ' / ' + fl?.name?.trim()}`
                                .toLowerCase()
                                .includes(`${icdtxt2}`.toLowerCase())
                            : true
                        )
                        .map((im, idx) => {
                          const colors = [
                            'bg-slate-100 hover:bg-slate-300/50 text-slate-700 hover:text-slate-800',
                            'bg-slate-100 hover:bg-slate-300/50 text-slate-700 hover:text-slate-800',
                          ]
                          const activeColors = [
                            'bg-slate-300/50 text-slate-800',
                            'bg-slate-300/50 text-slate-800',
                          ]
                          const activeicd2 = icd2 === im?.id
                          return (
                            <li
                              key={JSON.stringify({im, idx})}
                              className={classNames(
                                activeicd2 ? activeColors[icd2] : colors[0],
                                'rounded-md p-2',
                                'overflow-ellipsis overflow-hidden cursor-pointer'
                              )}
                              onClick={() => {
                                if (!activeicd2) {
                                  seticd2(im?.id)
                                  onFilterValueChange(
                                    colName,
                                    `${im?.id}`.toLowerCase()
                                  )
                                } else {
                                  onFilterValueChange(colName, ``)
                                  seticd2(-1)
                                }
                              }}
                            >
                              <strong>
                                {im?.code?.trim() + ' / ' + im?.name?.trim()}
                              </strong>
                            </li>
                          )
                        })}
                    </ul>
                  </VmView>
                )}
              </VmView>
            )
          },
          nadi: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          suhu: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          nafas: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          tekdarah: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          tinggi: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          berat: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          perut: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
          hasil: (colName, state, SortIcon) => (
            <input
              className={classNames(classNameFilter, 'max-w-[11rem]')}
              aria-label={`input-${colName}`}
              key={JSON.stringify({[colName]: newvariables[colName] || ''})}
              defaultValue={newvariables[colName] || ''}
              onBlur={e => {
                onFilterValueChange(colName, e?.target?.value)
              }}
              onKeyDownCapture={e => {
                if (e.key === 'Enter') {
                  onFilterValueChange(colName, e?.target?.value)
                }
              }}
            />
          ),
        }}
        scopedSlots={{
          no: (item, no) => (
            <td className="p-3 text-center align-top text-prevent-select !text-sm">
              {Number(no) + ((page - 1) * newlimit + 1)}
            </td>
          ),
          action: (item, index) => (
            <td className="p-3 align-top">
              <VmView className="flex justify-center items-center w-fill-available gap-1">
                <VmPrinterIcon
                  height={`${24}px`}
                  width={`${24}px`}
                  className={classNames(
                    'p-0.5 text-white bg-yellow-500',
                    'rounded-md inline cursor-pointer'
                  )}
                  onClick={() => {
                    closeModalPrint()
                    return setconfirmProps({
                      ...alertProps,
                      type: 3,
                      title: 'Peringatan',
                      position: 'fit',
                      visible: true,
                      children: (
                        <>Apakah anda yakin ingin melanjutkan proses cetak?</>
                      ),
                      onConfirm: () =>
                        handleExport(customPrintRmAttrWeb.url, {
                          rmid: item?.id,
                        }),
                      onClose: () => {
                        closeConfirm()
                      },
                      onCancel: () => {
                        setexportparams({})
                        closeConfirm()
                      },
                    })
                  }}
                />
              </VmView>
            </td>
          ),
          tgl: (item, no) => (
            <td className="p-3 align-top text-prevent-select !text-sm">
              {moment(item?.tgl).format('DD MMM YYYY, HH:mm')}
            </td>
          ),
        }}
      />
      <VmPagination
        onActivePageChange={onActivePageChange}
        pages={pages}
        activePage={page}
        className="text-prevent-select !text-sm"
        {...{dots: true, limit: 5}}
      />
      <VmModalExport
        ref={modalExport}
        pdf={false}
        excel={false}
        customAttr={customExportAttrWeb}
        classNameBody="bg-white !p-7 rounded-2xl"
        onExportExcel={val => {
          closeModalExport()
          return setconfirmProps({
            ...alertProps,
            type: 3,
            title: 'Peringatan',
            position: 'fit',
            visible: true,
            children: <>Apakah anda yakin ingin melanjutkan proses export ?</>,
            onConfirm: () => handleExport(customExportAttrWeb[val]?.url),
            onClose: closeConfirm,
            onCancel: closeConfirm,
          })
        }}
      />
      <VmModalExport
        ref={modalPrint}
        pdf={false}
        excel={false}
        customAttr={customPrintAttrWeb}
        classNameBody="bg-white !p-7 rounded-2xl"
        onExportExcel={val => {
          closeModalPrint()
          return setconfirmProps({
            ...alertProps,
            type: 3,
            title: 'Peringatan',
            position: 'fit',
            visible: true,
            children: <>Apakah anda yakin ingin melanjutkan proses cetak?</>,
            onConfirm: () => handleExport(customPrintAttrWeb[val]?.url),
            onClose: () => {
              closeConfirm()
            },
            onCancel: () => {
              setexportparams({})
              closeConfirm()
            },
          })
        }}
      />
      <AlertForm {...alertProps} />
      <AlertForm {...confirmProps} />
      <ProgressFullScreen visible={loading || onloadingmore} />
    </VmView>
  )
}
