import classNames from 'classnames'
import { VmButton, VmIcons, VmText, VmView } from 'components'
import { VmViewAttribute } from 'config/attributes'
import { bool, func, number, string } from 'prop-types'
import { forwardRef, useRef, useImperativeHandle, useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { isFunction, isNumber } from 'utils'
import { RgxMobileDevice } from 'utils/core'

const getNewProps = props => {
  let newprops = props ? { ...props } : {}

  if (newprops?.classNameTitle) {
    delete newprops?.classNameTitle
  }
  if (newprops?.onBackPress) {
    delete newprops?.onBackPress
  }
  if (newprops?.HeaderMode) {
    delete newprops?.HeaderMode
  }
  if (newprops?.HeaderName) {
    delete newprops?.HeaderName
  }
  if (newprops?.HeaderType) {
    delete newprops?.HeaderType
  }
  if (isNumber(newprops?.HeaderButtonVersion)) {
    newprops['headerbuttonversion'] = newprops?.HeaderButtonVersion
    delete newprops?.HeaderButtonVersion
  }
  if (newprops?.circleButton) {
    delete newprops?.circleButton
  }
  if (newprops?.labelBackButton) {
    delete newprops?.labelBackButton
  }
  return newprops
}
const getClass = (props, type = 'button') => {
  let newclass = ''
  if (type === 'button') {
    if (props?.HeaderButtonVersion === 0) {
      newclass += 'bg-blue-clasic'
    }
    if (props?.HeaderButtonVersion === 1) {
      newclass += 'bg-blue-gradient'
    }
    if (props?.circleButton === true) {
      newclass += 'vmheader-btn-circle'
    }
  }
  return newclass
}
var VmHeader = {
  propTypes: {
    ...VmViewAttribute,
    classNameTitle: string,
    onBackPress: func,
    HeaderMode: string,
    HeaderName: string,
    HeaderType: string,
    HeaderButtonVersion: number,
    circleButton: bool,
    labelBackButton: string,
  },
}
VmHeader = forwardRef((props, ref) => {
  const headerRef = useRef(),
    [mode, setmode] = useState(props?.HeaderMode || ''),
    classBtn = getClass(props),
    { loadinginitial = false, HeaderName = 'Name', HeaderType = 'Type' } = props,
    navigate = useNavigate(),
    [device, setDevice] = useState(false)


  let { showBack = true } = props

  const detectDevice = () => {
    let isMobile = RgxMobileDevice.test(navigator.userAgent)
    setDevice(isMobile ? 'mobile' : 'web')
  }

  useEffect(() => {
    detectDevice()
    return () => {}
  }, [])

  const onBackPress = e => {
    const { ariaLabel } = e.target
    if (ariaLabel === 'bg-btn-gradient-header') {
      // do nothing
    } else {
      if (props?.onBackPress) {
        if (isFunction(props.onBackPress)) {
          props.onBackPress(e)
        } else {
          console.error('[Invalid Type] onBackPress is not function!')
        }
      } else {
        if (window?.ReactNativeWebView?.postMessage) {
          window.ReactNativeWebView.postMessage('onBackPress:')
        } else {
          navigate(-1)
        }
      }
    }
  }
  var newprops = useMemo(() => getNewProps(props), [props])
  useImperativeHandle(ref, () => ({
    headerRef: headerRef.current,
    onBackPress,
    mode,
    setmode,
  }))

  if (!device || device == 'web') return <></>

  return (
    <VmView
      {...newprops}
      ref={headerRef}
      className={classNames('VmHeader', mode, props?.className || '')}
    >
      {showBack && (
        <VmButton
          className={classNames(classBtn, mode)}
          color="#FFFFFFFF"
          onClick={onBackPress}
          label={props?.labelBackButton || 'head-back-button'}
        >
          <VmView className={classNames('bg-btn-header', mode)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="absolute hover:cursor-default -z-10"
              aria-label="bg-btn-gradient-header"
            >
              <defs>
                <linearGradient
                  x1="0%"
                  y1="0%"
                  x2="0%"
                  y2="100%"
                  id="MyBgGradient"
                >
                  <stop offset="0.31%" />
                  <stop offset="98.99%" />
                </linearGradient>
              </defs>
            </svg>
            <VmIcons size={22} name="VmChevronLeftIcon" />
          </VmView>
        </VmButton>
      )}
      <VmView
        className={classNames('title', mode, props?.classNameTitle || '')}
      >
        <VmText
          data-placeholder={loadinginitial}
          className={classNames('name', mode)}
        >
          {HeaderName || '-'}
        </VmText>
        <VmText
          data-placeholder={loadinginitial}
          className={classNames('type', mode)}
        >
          {HeaderType || '-'}
        </VmText>
      </VmView>
    </VmView>
  )
})

export default VmHeader
