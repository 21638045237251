import React from 'react'
import {VmIcons, VmView} from 'components'
import classNames from 'classnames'

const defaulttoaster = {
  /**
   * Success => VmCheckCircleIcon => bg-blue1-payment
   * Fail => VmXCircleIcon => bg-red5-payment
   */
  toastervisible: false,
  toasterbg: 'bg-blue1-payment',
  toastericon: 'VmCheckCircleIcon',
  toastertitle: 'Success',
  toastertype: 'Success',
  toasterposition: 'bottom',
}

export default function SimpleToast({
  toasterbg,
  toastericon,
  toastertitle,
  toastervisible,
  toastertype = 'Success',
  toasterposition = 'top',
} = defaulttoaster) {
  if (!toastervisible) {
    return <VmView />
  }
  const colors = {
    Success: 'text-white bg-green-700',
    Warning: 'text-white bg-orange-700',
    Info: 'text-white bg-sky-600',
    Fail: 'text-white bg-red-700',
  }
  const positions = {
    top: 'top-5',
    bottom: 'bottom-5',
  }
  return (
    <VmView
      className={classNames(
        `z-[900000] shadow-[0_5px_20px_1px_rgba(255,255,255,0.44)]`,
        colors[toastertype] ? colors[toastertype] : toasterbg,
        'text-white font-semibold text-sm',
        'fixed flex flex-row rounded-full px-3 py-2 left-5 right-5',
        toasterposition ? positions[toasterposition] : 'bottom-5'
      )}
    >
      <VmIcons
        name={toastericon}
        className="h-5 w-5"
        variant="outline"
        strokeWidth="2.2px"
      />
      <VmView className="ml-2">{toastertitle || '-'}</VmView>
    </VmView>
  )
}
