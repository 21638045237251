import React, {useRef, useState} from 'react'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'

import {VmButton, VmView, VmText, VmIcons} from 'components'
import './VmDatePicker.scss'

registerLocale('id', id)
export default function VmDatePicker({
  selected = '',
  onChange = () => {},
  format = '',
  year = false,
  month = false,
  customInput = null,
  maxDate = null,
  minDate = null,
}) {
  const ref = useRef()
  const [dateValue, setDateValue] = useState(new Date())

  return (
    <DatePicker
      ref={ref}
      shouldCloseOnSelect={false}
      customInput={!customInput ? <CustomInput /> : customInput}
      selected={selected ?? new Date()}
      onChange={val => setDateValue(val)}
      locale="id"
      dateFormat={year ? 'yyyy' : month ? 'MMM-yyyy' : format}
      showYearPicker={year ? true : false}
      showMonthYearPicker={month ? true : false}
      onKeyDown={e => e.preventDefault()}
      onChangeRaw={e => e.preventDefault()}
      withPortal
      {...Object.assign(maxDate ? {maxDate} : {}, minDate ? {minDate} : {})}
    >
      <VmView className="justify-between p-3 float-right clear-both">
        <VmButton
          variant="ghost"
          className="mr-3"
          onClick={() => ref.current?.setOpen(false)}
        >
          Batal
        </VmButton>
        <VmButton
          variant="ghost"
          onClick={() => {
            onChange(dateValue)
            ref.current?.setOpen(false)
          }}
        >
          Pilih
        </VmButton>
      </VmView>
    </DatePicker>
  )
}

const CustomInput = ({value, onClick}) => {
  return (
    <VmView
      onClick={onClick}
      className="!w-fit border rounded-xl border-gray-400 px-2 py-2 flex flex-row flex-nowrap whitespace-nowrap items-start justify-center"
    >
      <VmText className="text-gray-700 mr-2">{value}</VmText>
      <VmIcons name="VmCalendarIcon" color="rgb(55 65 81)" size={20} />
    </VmView>
  )
}
