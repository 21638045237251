import axios from 'axios'
import {APIBPJS} from 'config/apiurl'
import QueryString from 'qs'
import {handlerResponse} from 'utils'

const definput = {
  reg: 'dbdok',
  a: 'dk13',
}
// ======================= Obat Pcare =================================
const mSearchObatPcare = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const newkw = `${input?.carimobile}`.trim()
    var newoffset = input.offset + input.limit
    var page = 1
    if (newoffset > 0) {
      page = newoffset / input.limit
    }
    if (newkw?.length < 3) {
      return resolve(
        handlerResponse(200, {
          message:
            'Silakan lakukan pencarian obat, dengan memasukkan minimal 3 karakter.',
          data: [],
        })
      )
    }
    // Ambil data obat dari api bpjs
    const {data} = await axios
      .post(
        `${APIBPJS}/bpjs-jkn/get-data-pcare`,
        QueryString.stringify({
          ...definput,
          uid: input.id,
          url: `obat/dpho/${newkw}/${page}/${input.limit}`,
        })
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            response: {
              count: 0,
              list: [],
            },
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.response?.list?.length > 0) {
      newdata['data'] = data?.response?.list
      message = 'Berhasil mengambil data.'
    } else {
      newdata['data'] = []
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mGetObatPcare = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    // Ambil data obat dari api bpjs
    const {data} = await axios
      .post(
        `${APIBPJS}/bpjs-jkn/obat-pcare`,
        QueryString.stringify({...definput, ...input, method: 'GET'})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil pasien.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      //   newdata['data'] = data.data.map(im => new DataPasien(im))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mAddObatPcare = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    // Ambil data obat dari api bpjs
    const {data} = await axios
      .post(
        `${APIBPJS}/bpjs-jkn/obat-pcare`,
        QueryString.stringify({...definput, ...input, method: 'POST'})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil pasien.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      //   newdata['data'] = data.data.map(im => new DataPasien(im))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mDeleteObatPcare = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    // Ambil data obat dari api bpjs
    const {data} = await axios
      .post(
        `${APIBPJS}/bpjs-jkn/obat-pcare`,
        QueryString.stringify({...definput, ...input, method: 'DELETE'})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil pasien.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      //   newdata['data'] = data.data.map(im => new DataPasien(im))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })

export {mSearchObatPcare, mGetObatPcare, mAddObatPcare, mDeleteObatPcare}
