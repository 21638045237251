import {VmIcons} from 'components'
import React, {useEffect, useState, useRef} from 'react'
import './pulltoRefresh.css'

export default function Index({children, onRefresh, className}) {
  const [startPoint, setStartPoint] = useState(0)
  const [pullChange, setPullChange] = useState()
  const refreshCount = useRef(0)

  const pullStart = e => {
    const {screenY} = e.targetTouches[0]
    setStartPoint(screenY)
  }

  const pull = e => {
    const touch = e.targetTouches[0]
    const {screenY, target} = touch
    if (window.scrollY === 0 && target.id != 'filterText') {
      let pullLength = startPoint < screenY ? Math.abs(screenY - startPoint) : 0
      setPullChange(pullLength)
    }
  }

  const endPull = () => {
    setStartPoint(0)
    setPullChange(0)
    if (pullChange > 220) onRefresh()
  }

  useEffect(() => {
    window.addEventListener('touchstart', pullStart)
    window.addEventListener('touchmove', pull)
    window.addEventListener('touchend', endPull)
    return () => {
      window.removeEventListener('touchstart', pullStart)
      window.removeEventListener('touchmove', pull)
      window.removeEventListener('touchend', endPull)
    }
  })

  return (
    <div id="rootContainer" className={className}>
      <div
        ref={refreshCount}
        className="refresh-container w-fit -mt-10 m-auto"
        style={{marginTop: pullChange / 3.118 || ''}}
      >
        <div className="refresh-icon p-2 rounded-full ">
          <VmIcons
            name="VmArrowPathIcon"
            style={{transform: `rotate(${pullChange}deg)`}}
            size={25}
            className="md:hidden"
          />
        </div>
      </div>
      {children}
    </div>
  )
}
