import {VmPencilSquareIcon} from 'components/icons/outline'
import {VmText, VmView, VmInput, VmButton} from 'components'
import classNames from 'classnames'
import {Fragment} from 'react'

export const hidering = '!ring-0 !ring-transparent !ring-offset-0'
const WebStrukSetting = ({
  data = {},
  HeaderName = 'Menu',
  HeaderType = 'Pengaturan Cetak Web',
}) => {
  if (data === null) {
    return <></>
  }
  return (
    <VmView className=" bg-white">
      <VmView className="flex flex-col p-4">
        <VmView className="px-5 pb-5 pt-8 text-lg font-semibold text-black2-payment text-center w-fill-available">
          Pengaturan Struk
        </VmView>
        <VmView className="w-full">
          <ChangeSettingInput data={data} />
        </VmView>
        <VmView className="flex px-2 py-4">
          <VmView className="flex gap-4 px-4">
            <VmButton
              label="SimpanSetting"
              className={
                'font-semibold text-sm px-6 py-3 min-w-[8rem] border-2'
              }
              rounded="2xl"
              color="success"
            >
              Simpan
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
    </VmView>
  )
}

const ChangeSettingInput = ({data = null}) => {
  if (data === null) {
    return <></>
  }
  const lstmenu = [
    {
      type: 1,
      title: 'Catatan Promo',
      Icon: VmPencilSquareIcon,
      label: 'input-catatan',
      field: 'catatan',
      typeInput: 'textarea',
      maxLength: 255,
    },
    {
      type: 5,
      title: 'Tampilkan Catatan',
      subtitle: 'Opsi untuk menampilkan catatan pada struk.',
      Icon: VmPencilSquareIcon,
      label: 'input-checkbox-catatan',
      field: 'checkbox-catatan',
    },
    {
      type: 5,
      title: 'Tampilkan Terbilang',
      subtitle: 'Opsi untuk menampilkan bilangan harga pada struk.',
      Icon: VmPencilSquareIcon,
      label: 'input-checkbox-terbilang',
      field: 'checkbox-terbilang',
    },
  ]
  return (
    <VmView className="flex flex-col w-full mt-3 overflow-y-scroll px-4">
      {/* input - section */}
      {lstmenu.map((im, index) => {
        const ImIcon = im.Icon,
          key = JSON.stringify({t: im.title, index})
        if (im.type === 1) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-col p-2 justify-between"
            >
              <VmText className="text-sm text-slate-600 font-semibold">
                {im.title}
              </VmText>
              <VmView className="flex flex-row mt-1 bg-gray-200 rounded-md">
                <VmView
                  className={classNames(
                    im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                    'p-3.5 w-12 rounded-l-md border-none text-slate-600'
                  )}
                >
                  <ImIcon
                    className={classNames(
                      im.typeInput === 'textarea' ? '' : 'h-full',
                      'w-full'
                    )}
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.6"
                  />
                </VmView>
                <VmInput
                  maxLength={im.maxLength}
                  typeInput={im.typeInput}
                  label={im.label}
                  className={classNames(
                    im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
                    hidering,
                    'transparent-items center-items bg-transparent w-full rounded-l-none border-none resize-none'
                  )}
                  defaultValue={data[im.field] || '-'}
                  key={data[im.field] || '-'}
                />
              </VmView>
            </VmView>
          )
        }
        if (im.type === 5) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-row p-2 pb-1 items-center"
            >
              <VmInput
                maxLength={im.maxLength}
                typeInput={'checkbox'}
                label={im.label}
                className="mr-2 flex self-start cursor-pointer"
                defaultChecked={data[im.field] || im?.checked}
              />
              <VmView>
                <VmText className="text-sm text-slate-600 font-semibold">
                  {im?.title || '-'}
                </VmText>
                <VmText className="text-xs text-gray-400">
                  {im?.subtitle || '-'}
                </VmText>
              </VmView>
            </VmView>
          )
        }
        return <Fragment key={key} />
      })}
      <VmView className="mb-24" />
    </VmView>
  )
}

export default WebStrukSetting
