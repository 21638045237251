import {decode, encode} from './encryptions'

/**
 *
 * ==================== setLclStorage =====================
 * = type 1     => set with enc 1                         =
 * = type 2     => set with enc 2                         =
 * ========================================================
 * @param {String} name
 * @param {*} value
 * @param {Object} options
 * @returns new name
 */
const setLclStorage = (name, value, options = {type: 0}) => {
  const type = options?.type
  if (type === 1) {
    name = encode(name??'')
    localStorage.setItem(name, value)
    return name
  }
  if (type === 2) {
    value = encode(value??'')
    localStorage.setItem(name, value)
    return name
  }
  localStorage.setItem(name, value)
  return name
}

/**
 *
 * ==================== getLclStorage =====================
 * = type 1     => get with enc 1                         =
 * = type 2     => get with enc 2                         =
 * ========================================================
 * @param {String} name
 * @param {Object} options
 * @returns new value
 */
const getLclStorage = (name, options = {type: 0}) => {
  const type = options?.type
  if (type === 1) {
    name = decode(name)
    return localStorage.getItem(name)
  }
  if (type === 2) {
    return decode(localStorage.getItem(name))
  }
  return localStorage.getItem(name)
}

/**
 *
 * ================== removeLclStorage ====================
 * = type 1     => remove with enc 1                      =
 * = type 2     => remove with enc 2                      =
 * ========================================================
 * @param {String} name
 * @param {Object} options
 * @returns new value
 */
const removeLclStorage = (name, options = {type: 0}) => {
  const type = options?.type
  if (type === 1) {
    name = decode(name)
    return localStorage.removeItem(name)
  }
  if (type === 2) {
    return localStorage.removeItem(name)
  }
  return localStorage.removeItem(name)
}

export {setLclStorage, getLclStorage, removeLclStorage}
