import React from 'react'

const VmSatuSehat = ({className = '', titleId = '', svgRef, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    fill="currentColor"
    aria-hidden="true"
    {...{className, 'aria-labelledby': titleId, ref: svgRef, ...props}}
  >
    <path
      d="m24,24h-14.5905c-1.8177,0-3.4687-1.2324-3.829-3.0141-.4956-2.4507,1.3655-4.6036,3.7283-4.6036h7.0735v-6.9728c0-1.8177,1.2324-3.4687,3.0141-3.829,2.4507-.4956,4.6036,1.3655,4.6036,3.7283v14.6912Z"
      style={{stroke: '#FFFFFF', fill: '#D2DD27'}}
    />
    <path
      d="m24,24v-14.5905c0-1.8177,1.2324-3.4687,3.0141-3.829,2.4507-.4956,4.6036,1.3655,4.6036,3.7283v7.0735h6.9728c1.8177,0,3.4687,1.2324,3.829,3.0141.4956,2.4507-1.3655,4.6036-3.7283,4.6036h-14.6912Z"
      style={{stroke: '#FFFFFF', fill: '#03B1AF'}}
    />
    <path
      d="m24,24h14.5905c1.8177,0,3.4687,1.2324,3.829,3.0141.4956,2.4507-1.3655,4.6036-3.7283,4.6036h-7.0735v6.9728c0,1.8177-1.2324,3.4687-3.0141,3.829-2.4507.4956-4.6036-1.3655-4.6036-3.7283v-14.6912Z"
      style={{stroke: '#FFFFFF', fill: '#03B1AF'}}
    />
    <path
      d="m24,24v14.5905c0,1.8177-1.2324,3.4687-3.0141,3.829-2.4507.4956-4.6036-1.3655-4.6036-3.7283v-7.0735h-6.9728c-1.8177,0-3.4687-1.2324-3.829-3.0141-.4956-2.4507,1.3655-4.6036,3.7283-4.6036h14.6912Z"
      style={{stroke: '#FFFFFF', fill: '#03B1AF'}}
    />
    <circle
      cx="10"
      cy="10"
      r="4.5"
      style={{stroke: '#FFFFFF', fill: '#D2DD27'}}
    />
  </svg>
)
export default VmSatuSehat
