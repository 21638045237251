import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'

const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  REACT_APP_BASE_URL_SATUSEHAT: APISATUSEHAT,
} = process.env

const definput = {
  reg: 'dbdok',
  a: 'dk13',
  'show-error-log': 'TRUE',
}

const mCariIdSatusehatDokter = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        APISATUSEHAT + '/prd-satusehat/cari-id-satusehat',
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: {},
            error: err,
            message: `[C5] Gagal mengambil data.`,
          })
        )
      })
    let message = '[S1] Gagal mengambil data.'
    if (data?.status === 1) {
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...data,
      })
    )
  })

export {mCariIdSatusehatDokter}
