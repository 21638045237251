import {
  PrdLapPatientVisit,
  PrdLapRekammedis,
  PrdLapPatientVisitDetails,
  PatientExamination,
  Example,
  ExampleTable,
  ExampleList,
  TestComponent,
  PrdBiayaPemeriksaan,
  PrdBiayaPemeriksaanTambah,
  PrdBiayaPemeriksaanUbah,
  PatientExaminationMobile,
  PrdDiagnosa,
  PrdDiagnosaTambah,
  PrdDiagnosaUbah,
  Page404,
  Page500,
  PrdTraPembayaran,
  PrdTraKunjunganUbah,
  PrdTraKunjunganUbahPilihPasien,
  PrdTraKunjunganUbahTambahPasien,
  Perpanjangan,
  BayarPerpanjangan,
  MetodePembayaran,
  CetakPembayaran,
  PrdUbahProfil,
  PrdStrukSetting,
  PrdDetailPembayaran,
  PrdLapAktivitasUser,
  PrdDetailRekamMedis,
  PrdLapPembayaran,
  PrdLapPenjualanObatResep,
  PrdLapPenjualanObatResepDetail,
  SsSettings,
  PrdLapPasien,
  PrdLapPasienExport,
  PengaturanResep,
  PrdLapPasienDetail,
  PrdLapStatistikDiagnosa,
  PrdLapStatistikDiagnosaExport,
  PrdLapRujukanDetail,
  PrdLapRujukanDetailExport,
  PrdLapRujukan,
  PrdLapPenjualanObatResepCetak,
  PrdPengaturanPcare,
  PCareKelompokProlanis,
} from 'pages'
import {ResepAddMitraApotek, ResepMitraApotek} from 'pages/prd-pengajuan-dokter'
import {AddRacikan, PenjualanObatResep} from 'pages/prd-penjualan-obat-resep'
import CetakBarcode from 'pages/prd-penjualan-obat-resep/CetakBarcode'
import PrdRujukanPageCreate from 'pages/prd-rujukan/Create'
import Kyc from 'pages/satusehat/Kyc'
import PrdRegistrasiPasienIndex from 'pages/prd-registrasi-pasien/Index'
import PrdRegistrasiPasienPilihPasien from 'pages/prd-registrasi-pasien/PilihPasien'
import PrdRegistrasiPasienTambahPasien from 'pages/prd-registrasi-pasien/TambahPasien'
import PrdLapKun from 'pages/prd-lap-kunjungan'
import PcareDtEntriKun from 'pages/pcare-data-entri-kunjungan'
import PcareDtEntriPend from 'pages/pcare-data-entri-pendaftaran'
import Etiket from 'pages/prd-penjualan-obat-resep/Etiket'

const publicRoutes = [
  {
    route: '/',
    components: <Page404 />,
  },
  {
    route: '/404',
    components: <Page404 />,
  },
  {
    route: '/500',
    components: <Page500 />,
  },
]
const protectedRoutes = [
  {
    route: '/example',
    components: <Example />,
  },
  {
    route: '/example-table',
    components: <ExampleTable />,
  },
  {
    route: '/example-list',
    components: <ExampleList />,
  },
  {
    route: '/prd-lap-kunjungan-pasien',
    components: <PrdLapPatientVisit />,
  },
  {
    route: '/prd-lap-kunjungan',
    components: <PrdLapKun />,
  },
  {
    route: '/prd-lap-rekammedis',
    components: <PrdLapRekammedis />,
  },
  {
    route: '/prd-lap-pembayaran',
    components: <PrdLapPembayaran />,
  },
  {
    route: '/prd-lap-kunjungan-pasien-detail',
    components: <PrdLapPatientVisitDetails />,
  },
  {
    route: '/kl-pre-check',
    components: <PatientExamination />,
  },
  {
    route: '/prd-mas-biaya-pemeriksaan',
    components: <PrdBiayaPemeriksaan />,
  },
  {
    route: '/prd-mas-biaya-pemeriksaan/tambah',
    components: <PrdBiayaPemeriksaanTambah />,
  },
  {
    route: '/prd-mas-biaya-pemeriksaan/ubah/:id',
    components: <PrdBiayaPemeriksaanUbah />,
  },
  {
    route: '/prd-mas-data-diagnosa',
    components: <PrdDiagnosa />,
  },
  {
    route: '/prd-mas-data-diagnosa/tambah',
    components: <PrdDiagnosaTambah />,
  },
  {
    route: '/prd-mas-data-diagnosa/ubah/:id',
    components: <PrdDiagnosaUbah />,
  },
  {
    route: '/test-component',
    components: <TestComponent />,
  },
  {
    route: '/prd-tra-kunjungan-ubah/:dctid/:kunid',
    components: <PrdTraKunjunganUbah />,
  },
  {
    route: '/prd-tra-kunjungan-ubah/pilih-pasien/:dctid',
    components: <PrdTraKunjunganUbahPilihPasien />,
  },
  {
    route: '/prd-tra-kunjungan-ubah/tambah-pasien/:dctid',
    components: <PrdTraKunjunganUbahTambahPasien />,
  },
  {
    route: '/prd-tra-pemeriksaan-pasien/search',
    components: <PatientExaminationMobile />,
  },
  {
    route: '/prd-tra-pemeriksaan-pasien/:create',
    components: <PatientExaminationMobile />,
  },
  {
    route: '/prd-tra-pembayaran/:mode/:id',
    components: <PrdTraPembayaran />,
  },
  {
    route: '/prd-tra-pembayaran/cetak/:id',
    components: <CetakPembayaran />,
  },
  {
    route: '/prd-struk-setting/:id',
    components: <PrdStrukSetting />,
  },
  {
    route: '/site/perpanjangan',
    components: <Perpanjangan />,
  },
  {
    route: '/site/bayarperpanjangan',
    components: <BayarPerpanjangan />,
  },
  {
    route: '/perpanjangan/metode',
    components: <MetodePembayaran />,
  },
  {
    route: '/ap-user-dokter/:id',
    components: <PrdUbahProfil />,
  },
  {
    route: '/prd-detail-pembayaran/:id',
    components: <PrdDetailPembayaran />,
  },
  {
    route: '/prd-lap-aktivitas-user/:id',
    components: <PrdLapAktivitasUser />,
  },
  {
    route: '/prd-detail-rekam-medis',
    components: <PrdDetailRekamMedis />,
  },
  {
    route: '/prd-lap-pasien',
    child: [
      {route: '/:id', components: <PrdLapPasienDetail />},
      {route: '/', components: <PrdLapPasien />},
      {route: '/export', components: <PrdLapPasienExport />},
    ],
  },
  {
    route: '/prd-lap-rujukan',
    child: [
      {route: '/', components: <PrdLapRujukan />},
      {route: '/:id', components: <PrdLapRujukanDetail />},
      {route: '/export/:id', components: <PrdLapRujukanDetailExport />},
    ],
  },
  // ========================== MITRA ==============================
  {
    route: '/prd-pengajuan-dokter',
    child: [
      {
        route: '/:id',
        components: <ResepMitraApotek />,
      },
      {
        route: '/add-mitra/:id',
        components: <ResepAddMitraApotek />,
      },
    ],
  },
  // ========================== Pembuatan Resep ==============================
  {
    route: '/prd-penjualan-obat-resep',
    child: [
      {
        route: '/:id',
        components: <PenjualanObatResep />,
      },
      {
        route: '/cetakbarcode/:id',
        components: <CetakBarcode />,
      },
      {
        route: '/etiket',
        components: <Etiket />,
      },
    ],
  },
  // ========================== Page Racikan Dokter ==============================
  {
    route: '/racikan',
    child: [
      {
        route: '/',
        components: <Page404 />,
      },
      {
        route: '/create/:id',
        components: <AddRacikan />,
      },
    ],
  },
  //  Laporan Pembuatan Resep
  {
    route: '/prd-lap-penjualan-obat-resep',
    child: [
      {
        route: '/',
        components: <PrdLapPenjualanObatResep />,
      },
      {
        route: '/:id/:uid',
        components: <PrdLapPenjualanObatResepDetail />,
      },
      {
        route: '/cetak/:id/:uid',
        components: <PrdLapPenjualanObatResepCetak />,
      },
    ],
  },
  {
    route: '/satu-sehat',
    child: [
      {
        route: '/aktifitas',
        components: <CetakBarcode />,
      },
      {
        route: '/kyc/:id',
        components: <Kyc />,
      },
    ],
  },
  {
    route: '/pengaturan-satu-sehat',
    child: [
      {
        route: '/',
        components: <SsSettings />,
      },
    ],
  },
  {
    route: '/pengaturan-resep',
    child: [
      {
        route: '/:id',
        components: <PengaturanResep />,
      },
    ],
  },
  {
    route: '/prd-lap-statistik-diagnosa',
    child: [
      // {route: '/:id', components: <PrdLapPasienDetail />},
      {route: '/', components: <PrdLapStatistikDiagnosa />},
      {route: '/export', components: <PrdLapStatistikDiagnosaExport />},
    ],
  },
  // ========================== Buat Rujukan ==============================
  {
    route: '/prd-rujukan',
    child: [
      {
        route: '/create/:id/:rmid',
        components: <PrdRujukanPageCreate />,
      },
    ],
  },
  // Registrasi Pasien
  {
    route: '/prd-registrasi-pasien',
    child: [
      {
        route: '/:id',
        components: <PrdRegistrasiPasienIndex />,
      },
      {
        route: '/pilih-pasien/:id',
        components: <PrdRegistrasiPasienPilihPasien />,
      },
      {
        route: '/tambah-pasien/:id',
        components: <PrdRegistrasiPasienTambahPasien />,
      },
    ],
  },
  // ========================== PCare ==============================
  {
    route: '/pcare-data-entri-kunjungan',
    child: [
      {
        route: '/:id',
        components: <PcareDtEntriKun />,
      },
    ],
  },
  {
    route: '/pcare-data-entri-pendaftaran',
    child: [
      {
        route: '/:id',
        components: <PcareDtEntriPend />,
      },
    ],
  },
  // Pengaturan PCARE
  {
    route: '/prd-pengaturan-pcare',
    components: <PrdPengaturanPcare />,
  },
  {
    route: '/pcare-kelompok-prolanis',
    child: [
      {
        route: '/:id',
        components: <PCareKelompokProlanis />,
      },
    ],
  },
]

const ProtectedRoute = ({children}) => {
  return <>{children}</>
}

export {ProtectedRoute, protectedRoutes, publicRoutes}
