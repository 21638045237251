import {VmButton, VmIcons, VmInput, VmView} from 'components'

export default function Searchbar({
  keyword = '',
  label = 'search-mitra',
  placeholder = 'Cari Apotek / Klinik',
  reset = () => {},
  onSearchObat = () => {},
  onChangeCaptureInput = () => {},
  onKeyDownCaptureInput = () => {},
}) {
  return (
    <VmView className="flex flex-row mb-4 rounded-full !min-h-[2.3rem] overflow-hidden">
      <VmView className="relative flex flex-row bg-white overflow-x-hidden !w-[-webkit-fill-available]">
        <VmInput
          typeInput="text"
          className="absolute !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
          hidering={true}
          label={`input-${label}`}
          placeholder={placeholder}
          onChangeCapture={onChangeCaptureInput}
          onKeyDownCapture={onKeyDownCaptureInput}
        />
        {keyword?.length > 0 && (
          <VmView
            onClick={reset}
            className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
          >
            <VmIcons
              size={16}
              name="VmXCircleIcon"
              variant="outline"
              className="text-red5-payment"
            />
            <VmView className="absolute inset-0" label={`reset-${label}`} />
          </VmView>
        )}
      </VmView>
      <VmButton
        className="relative flex justify-center !flex-none p-3 !rounded-l-none !min-w-[50px] !bg-blue1-payment"
        hidering={true}
        label={`btn-${label}`}
        onClick={onSearchObat}
      >
        <VmIcons size={14} name="VmMagnifyingGlassIcon" color="#FFFFFF" />
        <VmView className="absolute inset-0" label={`btn-${label}`} />
      </VmButton>
    </VmView>
  )
}
