import moment from 'moment'
import 'moment/locale/en-sg'
import {listStatusMessage} from 'pages/prd-pengajuan-dokter/entries'
import {conditionlist, etiketlist} from './entries'

class DataMitraApotek {
  constructor(props = {}) {
    this.title = props?.kl_nama || '-'
    this.address = props?.kl_alamat || '-'
    this.responsible = props?.kl_penanggung || '-'
    this.telp = props?.kl_tlp_penanggung || '-'
    this.ket = props?.spdalasan || '-'
    this.statusMessage = props?.spdstatus
      ? listStatusMessage[parseInt(props.spdstatus)]?.title
        ? listStatusMessage[parseInt(props.spdstatus)].title
        : '-'
      : '-'
    this.insentif = props?.spdfeedokter || '0'
    this.defaultdata = props || {}
  }
}
class DataPasien {
  constructor(props = {}) {
    this.title = props?.pasnama || '-'
    this.address = props?.pasalamat || '-'
    this.telp = props?.pastelp || '-'
    this.norm = props?.pasrm || '-'
    this.defaultdata = props || {}
  }
}
class DataVisit {
  constructor(props = {}) {
    this.title = props?.pasnama || '-'
    this.telp = props?.pastelp || '-'
    this.norm = props?.pasrm || '-'
    this.kid = props?.kunid || '-'
    this.date = props?.kuntgl || '-'
    this.kunno = props?.kunnomer || '-'
    this.defaultdata = props || {}
  }
}
class AddDataPasien {
  constructor(props = {}) {
    this.pasrm = props?.rm || ''
    this.pasjk = props?.jk || ''
    this.pasnobpjs = props?.nobpjs || ''
    this.pasnokk = props?.nokk || ''
    this.pasnama = props?.nama || ''
    this.pasalamat = props?.alamat || ''
    this.pasidkec = props?.idkec || ''
    this.pastelp = props?.telp || ''
    this.pasemail = props?.email || ''
    this.pasalergi = props?.alergi || ''
    this.pasibu = props?.ibu || ''
    this.pasbbm = props?.bbm || ''
    this.pasayah = props?.ayah || ''
    this.pasgoldarah = props?.goldarah || ''
    this.pastgllahir = props?.tgllahir || ''
    this.paspekerjaan = props?.pekerjaan || ''
    this.passtatusnikah = props?.statusnikah || ''
    this.pasparentid = props?.parentid || ''
    this.passtatussambung = props?.statussambung || ''
    this.pasnoktp = props?.noktp || ''
    this.lat = props?.lat || ''
    this.lng = props?.lng || ''
  }
}
class DataObat {
  constructor(props = {}) {
    this.obatid = props?.obatid || ''
    this.name = props?.obatnama || ''
    this.code = props?.obatkode || ''
    this.bentuk = props?.obatbentuk || ''
    this.conditions = conditionlist[Number(props?.obatsyarat)]
      ? Number(props?.obatsyarat)
      : 0
    this.etiket =
      props?.obatjenis === 'RACIKAN'
        ? etiketlist[Number(props?.obatetiket)]?.type
          ? Number(props?.obatetiket)
          : 0
        : ''
    this.waktu1 = props?.obatwaktu1 || '00:00:00'
    this.waktu2 = props?.obatwaktu2 || '00:00:00'
    this.waktu3 = props?.obatwaktu3 || '00:00:00'
    this.waktu4 = props?.obatwaktu4 || '00:00:00'
    this.waktu5 = props?.obatwaktu5 || '00:00:00'
    this.notes = props?.notes || ''
    this.status = props?.obatstatus || ''
    this.jenis = props?.obatjenis || ''
    this.price = props?.hrgjual1 || ''
    this.stok = props?.stok ? parseFloat(props.stok) : ''
    this.satuan = props?.sonama || ''
    this.satuan1 = Number(props?.sodhasilkonversi) === 1 ? props?.sonama : ''
    this.qty = props?.qty || 0
    this.flagcart = props?.flagcart || undefined
    this.flax = props?.flax || undefined
    this.flaxR = props?.flaxR || undefined
    this.defaultdata = props || {}
  }
}
class DataObatResep {
  constructor(props = {}) {
    this.obatid = props?.obatid || ''
    this.name = props?.obatnama || ''
    this.code = props?.obatkode || ''
    this.bentuk = props?.pjdbentuk || ''
    this.conditions = conditionlist[Number(props?.pjdaturan)]
      ? Number(props?.pjdaturan)
      : 0
    this.etiket = etiketlist[Number(props?.pjdwaktu)]
      ? Number(props?.pjdwaktu)
      : 0
    this.notes = props?.pjdcatatan || ''
    this.status = props?.obatstatus || ''
    this.jenis = props?.obatjenis || ''
    this.price = props?.hrgjual1 || ''
    this.stok = props?.stok ? parseFloat(props.stok) : ''
    this.satuan = props?.sonama || ''
    this.satuan1 =
      Number(props?.pjdkonversi || props?.sodhasilkonversi) === 1
        ? props?.sonama
        : ''
    this.qty = props?.qty || 0
    this.flagcart = props?.flagcart || undefined
    this.flax = props?.pjdflax || undefined
    this.flaxR = props?.pjdflaxR || undefined
    this.defaultdata = props || {}
  }
}
class DataObatRacikan {
  constructor(props = {}) {
    this.code = props?.obatkode || ''
    this.name = props?.obatnama || ''
    this.kategori = props?.katonama || ''
    this.conditions = conditionlist[Number(props?.obatsyarat)]
      ? Number(props?.obatsyarat)
      : 0
    this.etiket = etiketlist[Number(props?.obatetiket)]?.type
      ? Number(props?.obatetiket)
      : 0
    this.waktu1 = props?.obatwaktu1 || '00:00:00'
    this.waktu2 = props?.obatwaktu2 || '00:00:00'
    this.waktu3 = props?.obatwaktu3 || '00:00:00'
    this.waktu4 = props?.obatwaktu4 || '00:00:00'
    this.waktu5 = props?.obatwaktu5 || '00:00:00'
    this.defaultdata = props || {}
  }
}
class DataResep {
  constructor(props) {
    this.name = props?.pasnama || ''
    this.fakturno = props?.pjnofaktur || ''
    this.resepno = props?.pjresep || ''
    this.defaultdata = props || {}
  }
}
class CreatePjData {
  constructor(props) {
    // moment().locale('en-sg')
    this.uid = props?.uid || ''
    this.kid = props?.kid || null
    this.dokid = props?.dokid || ''
    this.aptid = props?.aptkldata?.defaultdata?.app_id || ''
    this.aptreg = props?.aptkldata?.defaultdata?.regid_apotek || ''
    this.pasid = props?.pasiendata?.defaultdata?.pasid || ''
    this.pasnama = props?.pasiendata?.defaultdata?.pasnama || ''
    this.doknama = props?.datadoct?.name || ''
    this.pjresep = props['rcpno-name'] || ''
    this.pjpajak = props?.pjpajak || ''
    this.pjpajaktext = props?.pjpajaktext || ''
    this.pjnofaktur = props?.pjnofaktur || ''
    this.pjtanggal = moment().locale('en-sg').format('DD MMM YYYY')
  }
}
class CreatePjItemCarts {
  constructor(props) {
    this.obatid = props?.obatid || ''
    this.soid = props?.defaultdata?.soid || ''
    this.sodid = props?.defaultdata?.sodid || ''
    this.pjjumlah = props?.qty || ''
    this.pjhasilkonversi = props?.defaultdata?.sodhasilkonversi || '0'
    this.pjhargabeli = props?.pjhargabeli || '0'
    this.pjhpp = props?.pjhpp || '0'
    this.hrgjual = props?.hrgjual || '0'
    this.hargacom = props?.hargacom || '0'
    this[`hrgjual${this.hargacom}`] = props?.price || '0'
    this.pjharga = props?.pjharga || '0'
    this.pjdhrgcombo = props?.pjdhrgcombo || '0'
    this.pjsubtotal = props?.pjsubtotal || '0'
    this.pjdiskon = props?.pjdiskon || '0'
    this.pjdiskontext = props?.pjdiskontext || '0'
    this.pjtotal = props?.pjtotal || '0'
    this.pjexpired = props?.pjexpired || '-'
    this.pjnobatch = props?.pjnobatch || '-'
    this.noresep = props?.noresep || '-'
    this.tuslah = props?.tuslah || '0'
    this.embalaser = props?.embalaser || '0'
    this.ket = props?.ket || ''
    this.hrgid = props?.flax?.includes('racikan')
      ? props?.jenis === 'RACIKAN'
        ? props?.flax
        : null
      : null
    this.flax = props?.flax ? props?.flax : `obat${props?.obatid || ''}`
    this.flaxR = props?.flaxR ? props?.flaxR : `obat${props?.obatid || ''}`
    this.poin = props?.flax?.includes('racikan') ? '3' : '0'
    this.obatnama = props?.obatnama || ''
    this.gudid = props?.gudid || ''
    this.obatkode = props?.obatkode || ''
    this.sonama = props?.sonama || '-'
    this.golonama = props?.golonama || ''
    this.katonama = props?.katonama || ''
    this.pjdaturan =
      props?.jenis === 'RACIKAN'
        ? etiketlist[Number(props?.etiket)]?.type || 0
        : 0
    this.pjdbentuk = props?.bentuk || '-'
    this.pjdwaktu = props?.conditions || 0
    // this.pjdcatatan = [
    //   `${
    //     props?.jenis === 'RACIKAN'
    //       ? etiketlist[Number(props?.etiket)]?.title || null
    //       : props?.etiket || null
    //   }`,
    //   `${
    //     conditionlist[Number(props?.conditions)]?.title &&
    //     Number(props?.conditions) > 0
    //       ? conditionlist[Number(props?.conditions)]?.title + ' Makan'
    //       : null
    //   }`,
    //   `${props?.notes || null}`,
    // ]
    //   .filter(fl => typeof fl === 'string' && fl?.length > 0)
    //   .join(', ')
    this.pjdcatatan = props?.pjdcatatan || ''
    this.waktu1 = props?.waktu1 || null
    this.waktu2 = props?.waktu2 || null
    this.waktu3 = props?.waktu3 || null
    this.waktu4 = props?.waktu4 || null
    this.waktu5 = props?.waktu5 || null
  }
}
class CreatePenjualanResep {
  constructor(props) {
    // this.a = props?.a || ''
    // this.reg = props?.reg || ''
    this.uid = props?.uid || ''
    this.time = props?.time || moment().format('HH:mm:ss')
    this.model = props?.data || ''
    this.cart = props?.carts || ''
  }
}
export {
  DataMitraApotek,
  DataVisit,
  DataPasien,
  AddDataPasien,
  DataObat,
  DataObatResep,
  DataObatRacikan,
  DataResep,
  CreatePjData,
  CreatePjItemCarts,
  CreatePenjualanResep,
}
