import {VmView} from 'components'
import React from 'react'
import PatExamHeaders from './PatExamHeaders'
import PatExamContents from './PatExamContents'
import './patientExamination.scss'

const PatientExamination = ({titleHeaders = 'Headers'}) => {
  return (
    <VmView className="m-4">
      <VmView className="box">
        <PatExamHeaders title={titleHeaders} />
        <PatExamContents />
      </VmView>
    </VmView>
  )
}

export default PatientExamination
