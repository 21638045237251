import classNames from 'classnames'
import {VmButton, VmView} from 'components'
import React, {Fragment} from 'react'
import {useNavigate} from 'react-router-dom'
import {defaultClassNames} from 'template/Form/HandlerForm'

const [classSparator] = defaultClassNames
export default function ButtonTindakan({data = {}}) {
  const navigate = useNavigate()
  const onClick = e => {
    const {ariaLabel} = e.target
    if (ariaLabel === 'btn-tambah-tindakan') {
      return navigate(`/prd-rujukan/create/${data?.uid}/${data?.id}`)
    }
    if (ariaLabel === 'btn-buat-resep') {
      return navigate(
        `/prd-penjualan-obat-resep/${data?.uid}?kid=${data?.kid}&pid=${data?.pid}`
      )
    }
  }
  return (
    <Fragment>
      <VmView className={classNames(classSparator, '')} />
      <VmView className="flex justify-between m-4">
        <VmButton
          className="w-full"
          onClick={onClick}
          label="btn-tambah-tindakan"
        >
          Tambah Tindakan
        </VmButton>
        <VmView className="p-2" />
        <VmButton className="w-full" onClick={onClick} label="btn-buat-resep">
          Buat Resep
        </VmButton>
      </VmView>
    </Fragment>
  )
}
