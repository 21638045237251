import classNames from 'classnames'
import {VmButton, VmIcons, VmView} from 'components'

export default function CardItemResep({
  data: {name = '', fakturno = '', resepno = '', flagcart = ''},
  index = 0,
}) {
  return (
    <VmView className="relative !text-black2-payment bg-white mb-4 last:mb-20 rounded-xl rounded-t-2xl">
      <VmView className="px-4 py-3 rounded-xl">
        {/* Head */}
        <VmView className="block mr-auto max-w-[80%]">
          {/* Name */}
          <VmView className="pb-2 font-semibold !text-break-word">
            {name}
          </VmView>
          {/* Faktur No */}
          <VmView className="text-sm !text-break-word">{fakturno}</VmView>
          {/* Resep No */}
          <VmView className="flex flex-row items-center text-sm !text-break-word">
            {'No '}
            {resepno}
          </VmView>
        </VmView>
        <VmView className="flex items-center bottom-4 right-4">
          <VmButton
            className={classNames(
              'flex justify-center items-center !flex-none text-sm p-1 px-4 !min-w-[4rem]',
              '!bg-blue1-payment !text-white first:mr-3 last:!mr-0'
            )}
            hidering={true}
            label={`add-resep-${index}`}
          >
            Pilih
          </VmButton>
          {!!flagcart && (
            <VmView
              label={`delete-resep-##-${index}-##-${flagcart}`}
              className="flex p-[0.3rem] justify-center bg-red5-payment items-center rounded-md w-6 h-6"
            >
              <VmIcons
                label={`delete-resep-##-${index}-##-${flagcart}`}
                style={{strokeWidth: '4px'}}
                className="text-white w-full h-full"
                name="VmDeleteBinIcon"
                variant="outline"
              />
            </VmView>
          )}
        </VmView>
      </VmView>
    </VmView>
  )
}
