import {VmText, VmView, VmButton} from 'components'
import {VmHeader} from 'molecules'
import classNames from 'classnames'
import {TemplateListInputItems} from 'template'
import moment from 'moment'
import {lstmenu} from './entries'

const hidering = '!ring-0 !ring-transparent !ring-offset-0'
const isRn = !!window?.ReactNativeWebView
const PrdRujukanCreate = ({
  data = {},
  onBackPress = () => {},
  HeaderName = 'Menu',
  HeaderType = 'Rujukan Eksternal',
  FormName = 'Buat Rujukan Eksternal',
}) => {
  return (
    <VmView className="w-screen h-screen flex flex-col bg-[#edf3ff]">
      {/* Header */}
      <VmHeader
        HeaderName={HeaderName}
        HeaderType={HeaderType}
        className="absolute px-4 pt-4 w-full"
        classNameTitle="!pl-4"
        HeaderMode="!text-black2-payment"
        HeaderButtonVersion={0}
        onBackPress={onBackPress}
      />
      {/* Content */}
      <VmView className="flex flex-col p-3 flex-grow rounded-2xl mt-20 mx-5 bg-white shadow-md overflow-y-scroll">
        {/* Title */}
        <VmView className="py-3 px-2">
          <VmText className="text-slate-700 font-bold">{FormName}</VmText>
        </VmView>
        {/* Patient Informations */}
        <PatientInfo data={data} />
        {/* Forms */}
        <Forms data={data} />
      </VmView>
      {/* Footer */}
      <VmView className="p-4 relative flex justify-end">
        <VmButton
          label="SimpanRujukan"
          rounded="full"
          className={classNames(
            hidering,
            'shadow-lg max-h-20 bg-gradient-to-r from-blue-600 to-blue-500 py-3 px-10 text-sm text-white font-semibold block'
          )}
        >
          Simpan
        </VmButton>
      </VmView>
    </VmView>
  )
}
const PatientInfo = ({data = {}}) => {
  const lstinfo = [
    {
      title: 'No. RM',
      field: 'norm',
    },
    {
      title: 'Tanggal Lahir',
      field: 'born',
    },
    {
      title: 'Nama Lengkap',
      field: 'patname',
    },
    {
      title: 'Jenis Kelamin',
      field: 'sex',
    },
  ]
  return (
    <VmView className="grid grid-cols-2 gap-4 p-2">
      {lstinfo.map(({title, field}, index) => {
        const key = `pi-${index}`
        return (
          <VmView key={key} className="text-sm">
            <VmText className="text-slate-500 font-bold">{title}</VmText>
            <VmText className="text-slate-700">{data[field] || '-'}</VmText>
          </VmView>
        )
      })}
    </VmView>
  )
}
const Forms = ({data = {}}) => {
  return (
    <TemplateListInputItems
      colors="text-slate-500 !text-sm"
      bgcolors="!bg-gray0-payment"
      data={data}
      lstinput={lstmenu}
      key={`lst-inpt-prd-rujukan`}
    />
  )
}

export default PrdRujukanCreate
