import React from 'react'
import {ErrorPage} from 'template'

const Page500 = () => {
  return (
    <ErrorPage code={500}>
      <p className="text-center">Houston, we have a problem!</p>
      <p className="text-center">
        The page you are looking for is temporarily unavailable.
      </p>
    </ErrorPage>
  )
}

export default Page500
