import moment from 'moment'
import {listStatusMessage} from './entries'

class DataMitraApotek {
  constructor(props = {}) {
    this.title = props?.kl_nama || '-'
    this.address = props?.kl_alamat || '-'
    this.responsible = props?.kl_penanggung || '-'
    this.telp = props?.kl_tlp_penanggung || '-'
    this.ket = props?.spdalasan || '-'
    this.statusMessage = props?.spdstatus
      ? listStatusMessage[parseInt(props.spdstatus)]?.title
        ? listStatusMessage[parseInt(props.spdstatus)].title
        : '-'
      : '-'
    this.insentif = props?.spdfeedokter || '0'
    this.defaultdata = props || {}
  }
}
class AddMitraApotek {
  constructor(props = {}) {
    this.regid_apotek = props?.regid_apotek || ''
    this.appid_apotek = props?.app_id || ''
    this.spduid_dokter = props?.id || ''
    this.doknama = props?.namadoc || ''
    this.spdtanggal = moment().format('YYYY-MM-DD HH:mm:ss')
    this.spdfeedokter = props?.spdfeedokter || ''
    this.spdharga = props?.stghrgportal || ''
    this.spdjenis = '1'
    this.email = props?.email || ''
  }
}

export {DataMitraApotek, AddMitraApotek}
