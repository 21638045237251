import {VmArrowRightIcon} from 'components/icons/solid'
import React from 'react'

export default function Metode({name, imgUrl, onClick}) {
  return (
    <li>
      <a
        onClick={_ => onClick?.()}
        // href=""
        className="flex shadow-md items-center p-2 text-base font-bold text-gray-900 rounded-lg bg-white  group hover:shadow "
      >
        <div style={{width: 50, height: 35}} className="flex items-center">
          <img src={imgUrl} style={{}} />
        </div>
        <span className="flex-1 ml-3 whitespace-nowrap text-left text-sm text-zinc-500">
          {name}
        </span>

        <VmArrowRightIcon className={'text-gray-500'} width={24} height={24} />
      </a>
    </li>
  )
}
