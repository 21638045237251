import classNames from 'classnames'
import {VmButton, VmIcons, VmInput, VmText, VmView} from 'components'
import {AiFillCaretDown} from 'react-icons/ai'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {getLclStorage} from 'utils/localstorage'

const defaultData = {
  name: '-',
  code: '-',
  status: 0,
  percent: 0,
  jenis: '',
  flagcart: '-',
  price: '0.00',
  qty: 0,
  stok: 0,
}
export default function CardItem({
  showprice = true,
  showstok = true,
  showqty = true,
  showplus = true,
  showminus = true,
  showsatuan = true,
  showdelete = true,
  showedit = false,
  label = '',
  data = defaultData,
  index = 0,
  cartracikan = false,

  onSelectEtiket = () => {},
  showEtiket = false,
  defaultEtiket = '',
}) {
  const [
      {
        name,
        code,
        price,
        decimalprice = 0,
        satuan = '',
        qty = 0,
        stok = 0,
        flagcart = '',
        jenis,
        etiket,
        pjdcatatan,
      },
      setdata,
    ] = useState(data),
    classPM =
      'relative flex justify-center items-center rounded bg-white overflow-hidden border-[0.5px] min-w-[1.25rem] h-5 p-[1px]',
    classHTitle =
      'text-prevent-select text-xs font-medium text-gray2-payment mb-1',
    classCSect = 'relative flex flex-row justify-between p-4'
  const upJenis = `${jenis}`.toUpperCase()
  const isRacikan = upJenis === 'RACIKAN'
  const isResep = upJenis === 'RESEP'
  const isJasa = upJenis === 'JASA'
  const showtotal = showqty || showplus || showminus
  const havestok = isJasa || isResep || stok > 0

  const dokid = parseInt(useParams().id)
  let storedData = getLclStorage('data', {type: 2})
  storedData = storedData ? JSON.parse(storedData) : ''

  const navigate = useNavigate()
  const location = useLocation()

  const selectedIndex = location?.state?.index
  const selectedEtiket = location?.state?.etiketerangan

  useEffect(() => {
    setdata(data)
  }, [data])

  useEffect(() => {
    onSelectEtiket({
      selectedIndex,
      selectedEtiket,
      flagcart,
    })
  }, [selectedEtiket])

  return (
    <VmView className="relative flex flex-col overflow-hidden !text-black2-payment bg-gray7-payment mb-4 rounded-xl rounded-t-2xl">
      {/* Head */}
      <VmView className="relative flex flex-wrap bg-white px-4 py-3 rounded-xl text-prevent-select">
        <VmView className="flex flex-row justify-between w-full items-start">
          <VmView className="flex flex-col mr-auto">
            <VmView className="pb-2 font-semibold">{name}</VmView>
            <VmView className="flex flex-wrap text-sm">{code}</VmView>
          </VmView>

          {showdelete && qty > 0 && (
            <VmView className="min-w-[10%]">
              <VmView className="relative flex flex-row-reverse">
                <VmView
                  label={`delete-item${label}-##-${index}-##-${flagcart}`}
                  className="flex p-[0.3rem] justify-center bg-red5-payment items-center rounded-md w-6"
                >
                  <VmIcons
                    label={`delete-item${label}-##-${index}-##-${flagcart}`}
                    style={{strokeWidth: '4px'}}
                    className="text-white w-full h-full"
                    name="VmDeleteBinIcon"
                    variant="outline"
                  />
                </VmView>
              </VmView>
            </VmView>
          )}
        </VmView>

        {/* Left Header */}
        <VmView className="text-sm">
          {/* {showedit && (
            <VmView
              label={`edit-item${label}-##-${index}-##-${flagcart}`}
              className="flex justify-center items-center rounded border-[0.5px] w-5 h-5 p-[1px]"
            >
              <VmIcons
                label={`edit-item${label}-##-${index}-##-${flagcart}`}
                name="VmPencilIcon"
                variant="outline"
                size={12}
              />
            </VmView>
          )} */}
        </VmView>
      </VmView>
      {/* Footer */}
      <VmView className={`${classCSect}`}>
        {showtotal && (
          <VmView
            key={`showtotal-k${label}-##-${index}-##-${flagcart}-${qty}`}
            className="min-w-[40%] max-w-[-webkit-fill-available] pr-3 last:pr-0"
          >
            {(qty > 0 && havestok) || (qty > 0 && cartracikan) ? (
              <>
                <VmView className={classHTitle}>Jumlah</VmView>
                <VmView className="flex flex-row items-center">
                  <VmView className={classPM}>
                    <VmIcons
                      style={{strokeWidth: '4px'}}
                      name="VmMinusIcon"
                      variant="outline"
                      size={11}
                    />
                    {qty === 1 ? (
                      <VmView
                        className={'!bg-gray0-stripes absolute inset-0'}
                      />
                    ) : (
                      <VmView
                        label={`minus-item${label}-##-${index}-##-${flagcart}`}
                        className={'!bg-transparent absolute inset-0'}
                      />
                    )}
                  </VmView>
                  <VmView className="relative flex flex-row h-5 px-2 !w-[4rem] overflow-hidden">
                    <VmInput
                      typeInput="text"
                      label={`input-qty${label}-##-${index}-##-${flagcart}`}
                      className="absolute text-center !py-0 !px-0 !text-xs !w-[3rem] !h-[-webkit-fill-available] !bg-transparent border-0"
                      hidering={true}
                      defaultValue={qty}
                    />
                  </VmView>
                  <VmView className={classPM}>
                    <VmIcons
                      style={{strokeWidth: '4px'}}
                      label={`plus-item${label}-##-${index}-##-${flagcart}`}
                      name="VmPlusIcon"
                      variant="outline"
                      size={12}
                    />
                    {qty >= stok && !cartracikan ? (
                      <VmView
                        className={'!bg-gray0-stripes absolute inset-0'}
                      />
                    ) : (
                      <VmView
                        label={`plus-item${label}-##-${index}-##-${flagcart}`}
                        className={'!bg-transparent absolute inset-0'}
                      />
                    )}
                  </VmView>
                </VmView>
              </>
            ) : (
              <>
                <VmView className={classHTitle}>&nbsp;</VmView>
                <VmButton
                  label={`add-item${label}-##-${index}-##-${flagcart}`}
                  className="flex justify-center text-sm min-w-[-webkit-fill-available] !rounded-md font-semibold active:!bg-blue1-payment hover:!bg-blue1-payment !bg-blue1-payment first:!mr-0 first:ml-auto"
                  hidering={true}
                  disabled={!havestok && !cartracikan}
                >
                  Tambah
                </VmButton>
              </>
            )}
          </VmView>
        )}

        {showsatuan && !isRacikan && !isResep && (
          <VmView
            key={`showsatuan-k${label}-##-${index}-##-${flagcart}`}
            className="min-w-[40%] max-w-[-webkit-fill-available] pr-3 last:pr-0"
          >
            <VmView className={classHTitle}>Satuan</VmView>
            <VmView className="relative flex flex-row justify-between items-center bg-white rounded-md shadow px-3 py-1 text-xs">
              <VmView className="text-prevent-select">{satuan}</VmView>
              <AiFillCaretDown />
              <VmView
                label={`showsatuan-k${label}-##-${index}-##-${flagcart}`}
                className={'!bg-transparent absolute inset-0'}
              />
            </VmView>
          </VmView>
        )}

        {(isRacikan || isResep) && (
          <VmView
            key={`rsprck-k${label}-##-${index}-##-${flagcart}`}
            className="min-w-[40%] max-w-[-webkit-fill-available] pr-3 last:pr-0"
          >
            <VmView className={classHTitle}>&nbsp;</VmView>
            <VmView className={'flex flex-row-reverse items-center'}>
              <VmButton
                className={classNames(
                  'flex justify-center items-center !flex-none text-sm p-1 px-4 !min-w-[4rem]',
                  '!bg-blue1-payment !text-white'
                )}
                hidering={true}
                label={`detail-mitra${
                  isRacikan ? '-racikan' : isResep ? '-resep' : ''
                }${label}-##-${index}-##-${flagcart}`}
              >
                Detail
              </VmButton>
            </VmView>
          </VmView>
        )}
      </VmView>

      {/* section etiket */}
      {showEtiket && (
        <VmView className="p-4 pt-0">
          <VmView className="flex flex-row justify-between">
            <VmView className="min-w-[40%]" />
            <VmView className="min-w-[40%] max-w-[40%]">
              <VmText className={classHTitle}>Etiket</VmText>
              <VmView
                onClick={() =>
                  navigate('/prd-penjualan-obat-resep/etiket', {
                    state: {
                      data: storedData,
                      dokid,
                      index,
                    },
                  })
                }
                className="relative flex flex-row justify-between items-center bg-white rounded-md shadow px-3 py-1 text-xs"
              >
                <VmView className="text-prevent-select">
                  {!!defaultEtiket ? defaultEtiket : pjdcatatan || '-'}
                </VmView>
                <AiFillCaretDown />
              </VmView>
            </VmView>
          </VmView>
        </VmView>
      )}

      {!havestok && !isRacikan && !isResep && !cartracikan && (
        <VmView className="!bg-red6-stripes absolute inset-0" />
      )}
    </VmView>
  )
}
