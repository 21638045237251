import {VmButton, VmView} from 'components'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {FullErrorView, ProgressFullScreen, VmHeader} from 'molecules'
import {TemplateListInputItems} from 'template'
import useAddEventListener from 'utils/useAddEventListener'
import {lstinput} from './entries'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import {mDeleteSetting, mSubmitSetting, qIndex} from './api'

export default function MobileSettings() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const docid = searchParams.get('dctid')

  const [loading, setloading] = useState(false)
  const [resstatus, setresstatus] = useState(0)
  const [error, seterror] = useState('')
  const [form, setform] = useState('')
  const defaultFormValue = {
    clientId: '',
    secretKey: '',
    organizationId: '',
  }
  const [defaultForm, setdefaultForm] = useState(defaultFormValue)
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)

  useEffect(() => {
    initDefault()
  }, [])

  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const openAlert = (caption, type, onConfirm = closeAlert) => {
    return setpropsalertform({
      ...propsalertform,
      type,
      visible: true,
      children: <>{caption}</>,
      onConfirm: onConfirm,
      onClose: closeAlert,
      onCancel: closeAlert,
    })
  }

  const initDefault = async () => {
    setloading(true)
    const resIndex = await qIndex({klid: docid})
    setresstatus(
      resIndex?.status &&
        resIndex?.data?.clientkey?.length > 0 &&
        resIndex?.data?.secretkey?.length > 0 &&
        resIndex?.data?.organization_id?.length > 0
        ? 1
        : 0
    )
    if (resIndex?.status != 1 && resIndex?.status != 2) {
      seterror(resIndex?.message)
    } else if (resIndex?.status == 1) {
      const resFormValue = {
        clientId: resIndex?.data?.clientkey,
        secretKey: resIndex?.data?.secretkey,
        organizationId: resIndex?.data?.organization_id,
      }
      setdefaultForm(resFormValue)
      setform(resFormValue)
    }
    setloading(false)
  }

  const handleGoBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => window.ReactNativeWebView.postMessage('onBackPress:'))
    } else {
      navigate(-1)
    }
  }

  const onClick = async e => {
    const {ariaLabel} = e?.target
    if (ariaLabel === 'submitSetting') {
      if (!form?.clientId || !form?.secretKey || !form?.organizationId)
        return openAlert('Semua kolom harus diisi!', 3, initDefault)

      setloading(true)
      const resSubmit = await mSubmitSetting({
        data: {
          clientkey: form?.clientId,
          secretkey: form?.secretKey,
          organization_id: form?.organizationId,
          datetime: Date.now(),
        },
        dataLog: {
          before: JSON.stringify(defaultForm),
          after: JSON.stringify(form),
        },
        docid,
      })
      setloading(false)
      if (resSubmit?.status == 1)
        return openAlert(resSubmit?.message, 4, initDefault)

      return openAlert(resSubmit?.message, 3, initDefault)
    }
    if (ariaLabel === 'deleteSetting') {
      setloading(true)
      const resSubmit = await mDeleteSetting({
        data: {
          clientkey: form?.clientId,
          secretkey: form?.secretKey,
          organization_id: form?.organizationId,
          datetime: Date.now(),
        },
        dataLog: {
          before: JSON.stringify(defaultForm),
          after: JSON.stringify(defaultFormValue),
        },
        docid,
      })
      setloading(false)
      if (resSubmit?.status == 1) {
        const resFormValue = {
          clientId: '',
          secretKey: '',
          organizationId: '',
        }
        setdefaultForm(resFormValue)
        setform(resFormValue)
        return openAlert(resSubmit?.message, 0, initDefault)
      }

      return openAlert(resSubmit?.message, 2, initDefault)
    }
  }

  const onChange = e => {
    const {ariaLabel, value} = e?.target
    const whiteListForm = ['clientId', 'secretKey', 'organizationId']
    if (whiteListForm.includes(ariaLabel))
      setform(s => ({...s, [ariaLabel]: value}))
  }

  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})

  if (!!error)
    return (
      <VmView className="inset-0 bg-white p-4">
        <VmHeader
          aria-label="header-error-search-obat-penolakan"
          HeaderMode="!text-gray-700"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType="Laporan Penolakan Obat"
          onBackPress={handleGoBack}
        />
        <FullErrorView message={error} itemClassName="m-4" />
      </VmView>
    )

  return (
    <>
      <ProgressFullScreen visible={loading} />
      <VmView className="fixed inset-0 bg-blue4-payment">
        <VmView className="flex flex-col p-4">
          <VmHeader
            className="mb-5"
            HeaderMode="!text-black2-payment"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType={'Pengaturan Satu Sehat'}
            onBackPress={handleGoBack}
          />
          <VmView className="w-full bg-white rounded-xl p-4">
            <VmView className="text-xs font-bold text-black2-payment">
              Pengaturan
            </VmView>
            <TemplateListInputItems
              data={defaultForm}
              lstinput={lstinput}
              bgcolors={'bg-[#F8FAFB]'}
              colors={'text-black2-payment'}
            />
          </VmView>
        </VmView>
        <VmView className="fixed bottom-2 left-4 right-4 flex flex-row-reverse justify-between items-center py-2">
          <VmButton
            label="submitSetting"
            className={'font-semibold text-sm px-6 py-3 min-w-[8rem]'}
            rounded="full"
          >
            Simpan
          </VmButton>
          {resstatus === 1 && (
            <VmButton
              label="deleteSetting"
              className={'font-semibold text-sm px-6 py-3 min-w-[8rem]'}
              rounded="full"
              color="danger"
            >
              Hapus
            </VmButton>
          )}
        </VmView>
      </VmView>
      <AlertForm {...{...propsalertform, noText: false, yesText: 'Ok'}} />
    </>
  )
}
