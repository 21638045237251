import {epoin} from 'controllers/epoin'
import {modelData} from './model'
import {
  actionServer,
  // successHandler
} from './server'

const CashierController = (event, navigate) => {
  const {key, dispatch, _ldata} = event
  const newevent = modelData({key, data: event})
  if (!!event?.getModel) {
    return newevent
  } else {
    const actionData = {
      dispatch,
      _ldata,
      key: key,
      useAlert: event?.useAlert,
      epoin: epoin[_ldata],
      data: {input: newevent},
    }
    switch (key) {
      case 'patient-visit-info':
      case 'user-info':
      case 'insert':
      case 'cetak':
      case 'insert-payment':
        return actionServer(
          actionData,
          navigate,
          event?.num > 0 ? event.num : 0
        )
      default:
        break
    }
  }
}

export default CashierController
