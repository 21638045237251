import classNames from 'classnames'
import {VmButton, VmIcons, VmView} from 'components'
import {FaUserTie, FaTag, FaPercent} from 'react-icons/fa'

export default function CardItem({
  data: {
    title = '',
    address = '',
    responsible = '',
    telp = '',
    statusMessage = '',
    insentif = '',
    status = 0,
    percent = 0,
  },
  index = 0,
}) {
  return (
    <VmView className="relative !text-black2-payment bg-gray7-payment mb-4 last:mb-20 rounded-xl rounded-t-2xl">
      {/* Head */}
      <VmView className="bg-white px-4 py-3 rounded-xl">
        {/* Title */}
        <VmView className="pb-2 font-semibold">{title}</VmView>
        {/* Address */}
        <VmView className="flex flex-wrap content-center text-sm min-h-[4rem]">
          {address}
        </VmView>
      </VmView>
      <VmView className="px-4 pt-2 pb-3 text-sm">
        {/* Responsible */}
        <VmView className="flex flex-row items-center">
          <FaUserTie className="mr-1 !text-gray2-payment" size={12} />
          {responsible}
        </VmView>
        {/* Telp */}
        <VmView className="flex flex-row items-center">
          <VmIcons name="VmPhoneIcon" className="mr-1 !text-gray2-payment" />
          {telp}
        </VmView>
        {/* Status */}
        <VmView className="flex flex-row items-center">
          <FaTag className="mr-1 !text-gray2-payment" size={12} />
          {statusMessage}
        </VmView>
      </VmView>
      <VmView className="absolute bottom-2 right-2">
        {/* Percent */}
        <VmView className="flex flex-row justify-end items-center text-sm mx-2 mb-1">
          <FaPercent className="mr-2 !text-gray2-payment" size={12} />
          {insentif}
        </VmView>
        <VmButton
          className={classNames(
            'flex justify-center items-center !flex-none text-sm p-1 px-4 !min-w-[4rem]',
            '!bg-blue1-payment !text-white'
          )}
          hidering={true}
          label={`detail-mitra-${index}`}
        >
          Detail
        </VmButton>
      </VmView>
    </VmView>
  )
}
