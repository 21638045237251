import {useScroll} from 'utils'
import {
  VmView,
  VmFloatingInfo,
  VmSpinner,
  VmText,
  VmInput,
  VmHeader,
  VmIcons,
  VmButton,
} from 'components'
import {
  ProgressFullScreen,
  VmCardReport,
  VmMobileFilter,
  VmModalExport,
} from 'molecules'
import {useNavigate, useSearchParams} from 'react-router-dom'

import InfiniteScroll from 'react-infinite-scroller'
import {defaultpropsalertform} from 'entries/alertform'
import QueryString from 'qs'
import axios from 'axios'
import {createRef, useState} from 'react'
import Alert from 'template/Form/AlertForm'
import classNames from 'classnames'

export default function Index({
  // Data props
  customRender = false,
  data = [],
  dataFields = [],
  headerFields = [],
  aliasFields = '',
  imageComponent = '',
  filterComponentAttr = {
    filterValue: '',
    filterHandler: '',
    filterFields: '',
    onChange: '',
    onClear: '',
  },
  searchComponentAttr = {
    value: '',
    onChange: '',
    onKeyDown: '',
    placeholder: '',
  },
  detailAttr = {
    button: false,
    buttonLabel: 'Detail',
    buttonAction: () => {},
  },
  exportApiUrl = '',
  exportPdfApiUrl = '',
  exportApiParams = {},

  // UI Props
  filterComponent = false,
  searchComponent = false,
  onCardItemClick = () => {},
  loadMore = () => {},
  hasMore = '',
  empty = false,
  count = 0,
  withStatus = true,
  statusDbColumn = 'status',
  headerAttr = {
    enable: true,
    light: true,
    title: '',
    onClick: () => {},
  },
  classNameANCHORHeader = '',
  classNameANCHORContent = '',
  useWindowInfiniteScroll = true,
  loading: loadingprops = false,
  topHeaderComponent = <></>,
  sideDownloadComponent = <></>,
  ...props
}) {
  const navigate = useNavigate()
  const modalExport = createRef()
  const [loading, setloading] = useState(false)
  const [alertProps, setAlertProps] = useState(defaultpropsalertform)
  const [confirmProps, setconfirmProps] = useState(defaultpropsalertform)
  const {isScrolling} = useScroll()
  const [searchParams] = useSearchParams()
  const dctid = searchParams.get('dctid')
  const exportBtnClassnames =
    'sticky flex justify-center items-center ml-2 h-[2.5rem] w-[2.7rem] bg-blue-500 rounded-xl cursor-pointer'
  const searchInputStyle =
    'sticky w-full border-gray-400 text-gray-500 placeholder:text-gray-400 placeholder:text-sm h-10 rounded-xl p-2 mb-1 z-50'
  const contentContainerStyle = classNames(
    (headerAttr.enable ? 'pt-[9.4rem]' : 'pt-[5.5rem]') + ' px-3 pb-14',
    classNameANCHORContent
  )

  // console.log('ReportMobile', data)

  const closeAlert = () => setAlertProps(defaultpropsalertform)
  const closeConfirm = () => setconfirmProps(defaultpropsalertform)
  const onPrint = () => modalExport.current.open()
  const closeModalExport = () => modalExport.current.close()

  const errorAlert = caption => {
    return setAlertProps({
      ...alertProps,
      type: 4,
      visible: true,
      children: <>{caption}</>,
      onConfirm: closeAlert,
      onClose: closeAlert,
      onCancel: closeAlert,
    })
  }

  const handleExportExcel = async () => {
    if (data.length > 0) {
      try {
        setloading(true)
        const {data} = await axios.post(
          exportApiUrl,
          QueryString.stringify({...exportApiParams})
        )

        if (data.status === 1) {
          errorAlert(`${data.message}`)
        } else {
          errorAlert(`${data.message}`)
        }
      } catch (e) {
        handleExportError(true, false)
      } finally {
        setloading(false)
      }
    } else {
      handleExportError(true, true)
    }
  }

  const handleExportPdf = async () => {
    if (data.length > 0) {
      navigate(exportPdfApiUrl)
      // try {
      //   setloading(true)
      //   const {data} = await axios.post(
      //     exportApiUrl,
      //     QueryString.stringify({...exportApiParams})
      //   )

      //   if (data.status === 1) {
      //     errorAlert(`${data.message}`)
      //   } else {
      //     errorAlert(`${data.message}`)
      //   }
      // } catch (e) {
      //   handleExportError(true, false)
      // } finally {
      //   setloading(false)
      // }
    } else {
      handleExportError(true, true)
    }
  }

  const handleExportError = (excel = false, empty = false) => {
    if (empty) {
      errorAlert(`Tidak ada data yang dapat di ${excel ? 'export' : 'cetak'}`)
    } else {
      errorAlert(
        `Gagal saat ${excel ? 'export' : 'cetak'}, silakan coba kembali`
      )
    }
  }

  return (
    <>
      {/* ANCHOR - Header */}
      <VmView
        className={classNames(
          'fixed bg-white w-full items-center px-3 py-3 z-50 top-0',
          classNameANCHORHeader
        )}
      >
        {topHeaderComponent}

        {headerAttr.enable && (
          <VmHeader
            className="sticky pt-4 px-0 !justify-start"
            spacing="true"
            {...headerAttr}
          />
        )}
        <VmView className="flex flex-row">
          {!!searchComponent ? (
            searchComponent
          ) : (
            <VmInput
              {...searchComponentAttr}
              type="text"
              className={searchInputStyle}
              enterKeyHint="search"
            />
          )}
          {!!exportApiUrl && (
            <VmView onClick={onPrint} className={exportBtnClassnames}>
              <VmIcons name="VmArrowDownTrayIcon" color="#FFFFFF" size={24} />
            </VmView>
          )}

          {sideDownloadComponent}
        </VmView>
        {!!filterComponent ? (
          filterComponent
        ) : (
          <VmMobileFilter className="pt-1.5" {...filterComponentAttr} />
        )}
      </VmView>

      {/* ANCHOR - Content */}
      <VmView className={contentContainerStyle}>
        <VmFloatingInfo
          hidden={isScrolling}
          label={{key: 'Total Data', value: count}}
        />
        {!empty && data.length > 0 ? (
          <InfiniteScroll
            useWindow={useWindowInfiniteScroll} // ini default ke true, kalau diset ke false maka menggunakan DOM Parentnya, ini digunakan untuk mengatasi BUG ketika component ReportMobile ditaruh di dalam Modal, maka infinite scrollnya ndak jalan, sehingga harus diset ke false useWindow-nya agar dia bisa berfungsil
            pageStart={0}
            threshold={0}
            hasMore={hasMore}
            loadMore={() => {
              console.log('ReportMobile - infiniteScroller trigger loadMore')

              loadMore()
            }}
            loader={
              <VmView
                key={0}
                className="mt-6 mb-4 items-center justify-center text-center"
              >
                <VmSpinner size="md" color="primary" />
              </VmView>
            }
          >
            {data.map((item, i) => {
              if (customRender) {
                // Custom render component
                return customRender(item, i)
              }
              // let showButtonEdit = item.status == 1 // Bisa edit untuk status pemeriksaan yang belum diperiksa
              let showButtonEdit = false

              return (
                <VmCardReport
                  headerFields={headerFields}
                  item={item}
                  key={i}
                  index={i}
                  fields={dataFields}
                  onCardItemClick={onCardItemClick}
                  imageComponent={imageComponent}
                  aliasFields={aliasFields}
                  withStatus={withStatus}
                  statusDbColumn={statusDbColumn}
                  button={showButtonEdit || detailAttr.button}
                  buttonLabel={showButtonEdit ? 'Ubah' : detailAttr.buttonLabel}
                  buttonAction={
                    showButtonEdit
                      ? () => {
                          // clear prev localStorage
                          localStorage.removeItem('ubah_kunjungan_pasien')
                          // navigate
                          navigate(
                            `/prd-tra-kunjungan-ubah/${dctid}/${item.id}`
                          )
                        }
                      : detailAttr.buttonAction
                  }
                  buttonAdditionalProps={{variant: 'outline', color: 'primary'}}
                  {...props}
                />
              )
            })}
          </InfiniteScroll>
        ) : (
          <VmText className="text-gray-700 text-center mt-10">
            Tidak ada data
          </VmText>
        )}

        {!hasMore && data.length > 0 ? (
          <VmText className="text-gray-700 text-center mb-2">
            Sudah tidak ada data
          </VmText>
        ) : null}
      </VmView>
      <VmModalExport
        ref={modalExport}
        pdf={!!exportPdfApiUrl ? true : false}
        excel={!!exportApiUrl ? true : false}
        onExportExcel={() => {
          closeModalExport()
          return setconfirmProps({
            ...alertProps,
            type: 2,
            title: 'Peringatan',
            visible: true,
            children: <>Apakah anda yakin ingin melanjutkan proses export ?</>,
            onConfirm: handleExportExcel,
            onClose: closeConfirm,
            onCancel: closeConfirm,
          })
        }}
        onExportPdf={() => {
          closeModalExport()
          return setconfirmProps({
            ...alertProps,
            type: 2,
            title: 'Peringatan',
            visible: true,
            children: <>Apakah anda yakin ingin melanjutkan proses export ?</>,
            onConfirm: handleExportPdf,
            onClose: closeConfirm,
            onCancel: closeConfirm,
          })
        }}
      />
      <ProgressFullScreen visible={loading || loadingprops} />
      <Alert {...alertProps} />
      <Alert {...confirmProps} />
    </>
  )
}
