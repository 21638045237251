import {useMemo, useEffect, useState, useCallback, createRef} from 'react'
import {useParams, useLocation, useNavigate, Navigate} from 'react-router-dom'
import {VmView} from 'components'
import {isNumber} from 'utils'
import classNames from 'classnames'
import {TemplateForm, TemplatePrdStrukSetting} from 'template'
import {SettingController} from 'controllers'
import {FullErrorView} from 'molecules'

const MobStrukSetting = () => {
  const pageTitle = 'Struk Setting'
  const navigate = useNavigate()
  const {search, pathname} = useLocation()
  const useQuery = useMemo(
    () => new URLSearchParams(search),
    // eslint-disable-next-line
    [search]
  )
  const params = useParams()
  const query = useQuery
  const formRef = createRef()
  const [data, setdata] = useState({})
  const [datachange, setdatachange] = useState({})
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  // id Vlaidation Type
  const idInt = parseInt(params?.id)
  const isValidIdNum = !isNumber(idInt) || idInt <= 0

  const saveData = () => {
    setloading(true)
    var okRef = formRef.current.okRef
    SettingController(
      {
        id: idInt,
        key: 'update',
        change: datachange,
        dispatch: () => {},
        _ldata: 'mUpdateStrukSetting',
      },
      navigate
    )
      .then(res => {
        // console.log('res', res)
        if (res?.status === 1) {
          setdatachange({})
          okRef.show(
            {
              message: res?.message || 'Berhasil menyimpan data',
              title: 'Berhasil',
            },
            () => {},
            () => {}
          )
        } else {
          okRef.show(
            {
              message: res?.message || 'Gagal menyimpan data',
              title: 'Gagal',
            },
            () => {},
            () => {}
          )
        }
      })
      .catch(err => {
        // console.log('err', err)
        okRef.show(
          {
            message: '[C0] Gagal menyimpan data',
            title: 'Gagal',
          },
          () => {},
          () => {}
        )
      })
      .finally(() => {
        setloading(false)
      })
  }
  const onChangeData = useCallback(
    e => {
      setdatachange(t => {
        return Object.assign(t || {}, e)
      })
    },
    // eslint-disable-next-line
    [datachange]
  )
  const onChangeCapture = (e, value) => {
    const {ariaLabel} = e.target
    // onChangeCapture
    if (`${ariaLabel}` === 'input-catatan') {
      // onChange desc
      onChangeData({promoklinik: value.trim()})
      return
    }
  }
  const onClick = e => {
    const {ariaLabel, checked} = e.target
    // onClick
    if (`${ariaLabel}` === 'input-checkbox-terbilang') {
      onChangeData({terbilang1: checked ? '1' : '2'})
      return
    }
    if (`${ariaLabel}` === 'input-checkbox-catatan') {
      onChangeData({promostatus1: checked ? '1' : '2'})
      return
    }
    if (`${ariaLabel}` === 'SimpanSetting') {
      return saveData()
    }
  }

  useEffect(() => {
    document.title = pageTitle
    var error = false,
      errorMessage = ''
    SettingController(
      {
        id: idInt,
        key: 'index',
        dispatch: () => {},
        _ldata: 'mStrukSetting',
      },
      navigate
    )
      .then(res => {
        // console.log('res', res)
        if (res?.status === 1) {
          setdata({
            catatan: res?.data?.stgpromoklinik || '',
            'checkbox-catatan': res?.data?.stgpromostatus1 === 1 ? true : false,
            'checkbox-terbilang': res?.data?.stgterbilang1 === 1 ? true : false,
          })
        }
      })
      .catch(err => {
        // console.log('err', err)
        error = true
        errorMessage = '[C0] Gagal mengabil data!'
      })
      .finally(() => {
        setloading(false)
        setdatachange({})
        if (error) {
          seterror(errorMessage)
        }
      })
  }, [])
  if (!params?.id || isValidIdNum) {
    return <Navigate to={'/404'} replace />
  }

  if (error) {
    return <FullErrorView message={error} />
  }

  return (
    <TemplateForm
      ref={formRef}
      onClick={onClick}
      loading={loading}
      onChangeCapture={onChangeCapture}
    >
      <TemplatePrdStrukSetting data={data} />
    </TemplateForm>
  )
}

export default MobStrukSetting
