import {VmModal, VmView, VmText, VmButton, VmIcons} from 'components'
import {VmDatePicker, VmInputHandler} from 'molecules'
import moment from 'moment'
import {
  createRef,
  forwardRef,
  Fragment,
  useImperativeHandle,
  useCallback,
  useState,
  memo,
  useMemo,
  useRef,
  useEffect,
} from 'react'
import {isFunction} from 'utils'
import {toCurrency} from 'utils/functions'

const CashIcon = () => (
  <VmIcons
    size={20}
    color="var(--color-blue1-payment)"
    variant="outline"
    name="VmCashIcon"
  />
)
const DateIcon = () => (
  <VmIcons
    size={20}
    color="var(--color-blue1-payment)"
    variant="outline"
    name="VmCalendarDaysIcon"
  />
)
const CardIcon = () => (
  <VmIcons
    size={20}
    color="var(--color-blue1-payment)"
    variant="outline"
    name="VmCreditCardIcon"
  />
)
const CevronDwnIcon = () => (
  <VmIcons
    size={20}
    color="var(--color-blue1-payment)"
    variant="outline"
    name="VmChevronDownIcon"
  />
)
var EsectionCheckoutPayment = ({datacapt, subcapt1, subcapt2}) => {
  return (
    <VmView className="EsectionCheckoutPayment">
      <VmView className="EtotalcostCheckoutPayment min-w-0">
        <VmText>{datacapt?.capt1 || '-'}</VmText>
        <VmText className="break-words">{toCurrency(subcapt1)}</VmText>
      </VmView>
      <VmView className="EtotalchangeCheckoutPayment min-w-0">
        <VmText>{datacapt?.capt2 || '-'}</VmText>
        <VmText className="break-words">{toCurrency(subcapt2)}</VmText>
      </VmView>
    </VmView>
  )
}
var EcontendiscCheckoutPayment = ({disc, nominaldisc}) => {
  return (
    <VmView className="EcontendiscCheckoutPayment">
      <VmView className="EprctdiscCheckoutPayment">
        <VmText>Persentase</VmText>
        <VmInputHandler
          label="prcntdisc"
          value={disc || 0}
          prefix={CashIcon}
          suffix={() => <></>}
          onFocus={e => {
            e.target.select()
          }}
        />
      </VmView>
      <VmView className="EtotaldiscCheckoutPayment">
        <VmText>Nominal Diskon</VmText>
        <VmInputHandler
          label="nmnldisc"
          value={toCurrency(nominaldisc) || 0}
          prefix={CashIcon}
          suffix={() => <></>}
          onFocus={e => {
            e.target.select()
          }}
        />
      </VmView>
    </VmView>
  )
}
var EoptdiscCheckoutPayment = ({disc, actvdisc, nominaldisc}) => {
  return (
    <VmView className="EoptdiscCheckoutPayment">
      <VmView className="EtitlediscCheckoutPayment">
        <VmText>Ingin Menambahkan Diskon?</VmText>
        <VmText className="cursor-pointer" label="actvdisc">
          Tambah Diskon
        </VmText>
      </VmView>
      {actvdisc && <EcontendiscCheckoutPayment {...{disc, nominaldisc}} />}
    </VmView>
  )
}
var EoptpaymentCheckoutPayment = ({data, datacapt, props}) => {
  return (
    <VmView className="EoptpaymentCheckoutPayment">
      <VmText>Opsi Pembayaran</VmText>
      <VmView>
        {datacapt.optpayment.map(({name, Icon, id}, index) => {
          const label = `opt-payment-${index}-${id}`
          return (
            <VmButton
              key={label}
              label={label}
              active={id === datacapt.optactive}
            >
              <Icon />
              {name}
            </VmButton>
          )
        })}
      </VmView>
    </VmView>
  )
}
var EtotalcashCheckoutPayment = ({data, datacapt}) => {
  const jmltunai = toCurrency(
    datacapt.optactive === 1
      ? data.tunai
      : datacapt.optactive === 3
      ? data.kartucash
      : 0
  )
  return (
    <VmView className="EtotalcashCheckoutPayment">
      <VmText>Jumlah Tunai</VmText>
      <VmInputHandler
        label="jmltunai"
        value={jmltunai}
        prefix={CashIcon}
        suffix={() => <></>}
        onFocus={e => {
          e.target.select()
        }}
      />
    </VmView>
  )
}
var EtotaldownpaymentCheckoutPayment = ({data}) => {
  const jmldp = toCurrency(data?.dp || 0)
  return (
    <VmView className="EtotaldownpaymentCheckoutPayment">
      <VmText>Uang Muka</VmText>
      <VmInputHandler
        label="jmldp"
        value={jmldp}
        prefix={CashIcon}
        suffix={() => <></>}
        onFocus={e => {
          e.target.select()
        }}
      />
    </VmView>
  )
}
var EsugestioncostCheckoutPayment = ({datacapt, subcapt1}) => {
  const roundto = 5000
  const lstmoney = [5000, 10000, 20000, 50000, 100000]
  var sugestion = [],
    rounded = subcapt1 > 0 ? parseInt(subcapt1) : roundto,
    lastmoney = lstmoney.length - 1
  const calcsugestion = () => {
    if (rounded < lstmoney[lastmoney]) {
      var flmoney = lstmoney.filter(fl => fl > rounded)
      if (flmoney.length > 1) {
        var crounded = rounded / 10000
        var cfloor = Math.floor(crounded)
        var cltcoma9 = crounded - cfloor < 0.9 && crounded - cfloor > 0
        var roundup = Math.ceil(crounded) * 10000
        if (cltcoma9) {
          if (roundup === flmoney[0]) {
            return [rounded, roundup, flmoney[1]]
          } else {
            return [rounded, roundup, flmoney[0]]
          }
        } else {
          return [rounded, flmoney[0], flmoney[1]]
        }
      } else {
        return [rounded]
      }
    } else {
      var crounded = rounded / lstmoney[lastmoney]
      var cfloor = Math.floor(crounded)
      var cltcoma9 = crounded - cfloor < 0.9 && crounded - cfloor > 0
      if (cltcoma9) {
        var cplus = Math.ceil((crounded - cfloor) * 10) * 10000
        return [
          rounded,
          cfloor * lstmoney[lastmoney] + cplus,
          cfloor * lstmoney[lastmoney],
        ]
      } else {
        return [rounded, cfloor * lstmoney[lastmoney]]
      }
    }
    return sugestion
  }
  return (
    <VmView
      key={JSON.stringify({m: calcsugestion()})}
      className="EsugestioncostCheckoutPayment"
    >
      {subcapt1 > 500 &&
        calcsugestion().map((im, index) => {
          const dpprcnt = 20
          var numsuggestion =
            datacapt.optactive === 2 ? (im / 100) * dpprcnt : im
          if (index > 2 || im < rounded) {
            return <Fragment key={`sgs-mn-${numsuggestion}`} />
          }
          return (
            <VmButton
              key={`sgs-mn-${numsuggestion}`}
              label={`sugestion-${numsuggestion}`}
            >
              {toCurrency(numsuggestion)}
            </VmButton>
          )
        })}
    </VmView>
  )
}
var EduedateCheckoutPayment = ({data, onChangeDate}) => {
  const defaultDate = moment().add(1, 'days').toLocaleString()
  return (
    <VmView className="EduedateCheckoutPayment">
      <VmText>Jatuh Tempo</VmText>

      <VmDatePicker
        selected={new Date(data?.deadline || defaultDate)}
        format="dd MMM yyyy"
        minDate={new Date(defaultDate)}
        customInput={<VmInputHandler prefix={DateIcon} suffix={() => <></>} />}
        onChange={e => onChangeDate(e, 'deadline')}
      />
    </VmView>
  )
}
var EcontentcardCheckoutPayment = ({data}) => {
  const jmlkartu = toCurrency(data?.kartu || 0)
  return (
    <VmView className="EcontentcardCheckoutPayment">
      <VmView className="EtotalcardCheckoutPayment">
        <VmText>Jumlah Kartu</VmText>
        <VmInputHandler
          label="jmlkartu"
          value={jmlkartu}
          prefix={CashIcon}
          suffix={() => <></>}
          onFocus={e => {
            e.target.select()
          }}
        />
      </VmView>
      {/* <VmView className="EnamebankCheckoutPayment">
        <VmText>Nama Bank</VmText>
        <VmInputHandler suffix={CevronDwnIcon} />
      </VmView> */}
    </VmView>
  )
}
var EnamecardCheckoutPayment = () => {
  return (
    <VmView className="EnamecardCheckoutPayment">
      <VmText>Nama Bank / No. Kartu</VmText>
      <VmInputHandler label="bankname" prefix={CardIcon} suffix={() => <></>} />
    </VmView>
  )
}
var Body = ({
  data,
  datacapt,
  actvdisc,
  subcapt1,
  subcapt2,
  disc,
  nominaldisc,
  close,
  type,
  onChangeDate,
  props,
}) => {
  if (type === 'checkout') {
    return (
      <Fragment>
        <EsectionCheckoutPayment
          {...{data, datacapt, actvdisc, subcapt1, subcapt2, props}}
        />
        <EoptpaymentCheckoutPayment {...{data, datacapt, props}} />
        <EoptdiscCheckoutPayment {...{datacapt, actvdisc, disc, nominaldisc}} />
        {[1, 3].includes(datacapt?.optactive) && (
          <EtotalcashCheckoutPayment {...{data, datacapt, props}} />
        )}
        {datacapt?.optactive === 2 && (
          <EtotaldownpaymentCheckoutPayment {...{data, datacapt, props}} />
        )}
        {datacapt?.optactive === 3 && (
          <EcontentcardCheckoutPayment {...{data, datacapt, props}} />
        )}
        {datacapt?.optactive !== 2 && (
          <EsugestioncostCheckoutPayment
            {...{data, datacapt, subcapt1, props}}
          />
        )}
        {datacapt?.optactive === 2 && (
          <EduedateCheckoutPayment {...{data, datacapt, onChangeDate, props}} />
        )}
        {datacapt?.optactive === 3 && (
          <EnamecardCheckoutPayment {...{data, datacapt, props}} />
        )}
      </Fragment>
    )
  }
  if (type === 'disc') {
    return (
      <Fragment>
        <EsectionCheckoutPayment
          {...{data, datacapt, subcapt1, subcapt2, props}}
        />
        <EcontendiscCheckoutPayment {...{datacapt, disc, nominaldisc}} />
      </Fragment>
    )
  }
  if (type === 'delete') {
    return (
      <Fragment>
        <VmView className="EdeleteCheckoutPayment">
          <VmText>Apakah Anda yakin ingin menghapus layanan pemeriksaan</VmText>
          <VmText>
            {data?.type || ''} {data?.name || ''}?
          </VmText>
        </VmView>
      </Fragment>
    )
  }
  return <></>
}
var Foot = ({
  data,
  datacapt,
  subcapt1,
  subcapt2,
  disc,
  nominaldisc,
  close,
  type,
  props,
}) => {
  if (type === 'checkout') {
    return (
      <VmButton
        onClick={e => {
          if (isFunction(props.onSavePayment)) {
            props.onSavePayment(e, {
              ...data,
              type: datacapt.optactive,
              subcapt1,
            })
          } else {
            alert('An Internal Error When Saving!')
          }
        }}
        className="EbtnsubmitCheckoutPayment"
      >
        Proses Pembayaran
      </VmButton>
    )
  }
  if (type === 'disc') {
    return (
      <VmButton
        onClick={e => {
          if (isFunction(props.onSaveDiscItem)) {
            props.onSaveDiscItem(e, data)
          } else {
            alert('An Internal Error When Saving!')
          }
          close()
        }}
        className="EbtnsubmitCheckoutPayment"
      >
        Ubah Diskon
      </VmButton>
    )
  }
  if (type === 'delete') {
    return (
      <Fragment>
        <VmButton onClick={close} className="EbtnsubmitCheckoutPayment">
          Batal
        </VmButton>
        <VmButton
          onClick={e => {
            props.delToCart(e, data)
            close()
          }}
          className="EbtndeleteCheckoutPayment"
        >
          Hapus
        </VmButton>
      </Fragment>
    )
  }
  return <></>
}

const modelData = {
  checkout: {
    title: 'Data Pembayaran',
    capt1: 'Total Biaya',
    capt2: 'Kembalian',
    optactive: 1,
    optpayment: [
      {id: 1, name: 'Tunai', Icon: CashIcon},
      {id: 2, name: 'Piutang', Icon: CashIcon},
      {id: 3, name: 'Kartu', Icon: CashIcon},
    ],
  },
  disc: {
    title: 'Ubah Diskon Item',
    capt1: 'Biaya Awal',
    capt2: 'Biaya Setelah Diskon',
  },
  delete: {
    title: 'Hapus Pemeriksaan',
  },
}
var ModalPembayaran = {
  propTypes: {},
}
ModalPembayaran = forwardRef((props, ref) => {
  const formRef = useRef()
  const [actvdisc, setactvdisc] = useState(true)
  const [title, settitle] = useState('')
  const [type, settype] = useState('')
  const [data, setdata] = useState({})
  const [datacapt, setdatacapt] = useState({})
  const modalPaymentRef = createRef()

  // useEffect(() => {

  //   console.log('ModalPembayaran state data berubah ',data)
  //   return () => {

  //   }
  // }, [JSON.stringify(data)])

  const subcapt1 = useMemo(() => {
    var total = 0
    if (type === 'disc') {
      // capt1: 'Biaya Awal'
      var {price} = data
      total = price
    }
    if (type === 'checkout') {
      // capt1: 'Total Biaya'
      if (data?.chart?.length > 0) {
        for (let i = 0; i < data.chart.length; i++) {
          var {price, nominaldisc, value} = data.chart[i]
          total += (price - nominaldisc) * value
        }
        total -= data?.nominaldisc || 0
      }
    }
    return total
  }, [data, type])
  const subcapt2 = useMemo(() => {
    var total = 0
    if (type === 'disc') {
      // capt2: 'Biaya Setelah Diskon'
      var {price, nominaldisc, value} = data
      total = parseInt(price) - parseInt(nominaldisc)
    }
    if (type === 'checkout') {
      // capt2: 'Kembalian'
      if (datacapt.optactive === 1) {
        // Tunai
        total = data?.tunai || 0
      }
      if (datacapt.optactive === 2) {
        // Piutang
        total = data?.dp || 0
      }
      if (datacapt.optactive === 3) {
        // Kartu
        total = data?.kartucash || 0
        total += data?.kartu || 0
      }
      total -= subcapt1
      // Change to 0 if lt 0
      if (total < 0) {
        total = 0
      }
    }
    return total
  }, [data, type, subcapt1, datacapt])
  const disc = useMemo(() => {
    if (parseInt(data?.disc) === 100) {
      return '100%'
    }
    return `${data?.disc ? toCurrency(data?.disc, 2) : '0,00'}%`
  }, [data])
  const nominaldisc = useMemo(() => {
    return data?.nominaldisc || 0
  }, [data])
  const open = useCallback(
    (t = 'disc', data = {}) => {
      const {title, type} = {...modelData[t], type: t}
      setdatacapt(modelData[t])
      settitle(title)
      settype(type)
      setdata(data)
      modalPaymentRef.current.open()
    },
    [modalPaymentRef]
  )
  const close = useCallback(
    (t = '', data = {}) => {
      modalPaymentRef.current.close()
    },
    [modalPaymentRef]
  )
  const onClick = useCallback(
    e => {
      var newdatacapt = datacapt,
        newdata = data
      const {ariaLabel} = e.target
      if (`${ariaLabel}`.includes('opt-payment-')) {
        var active = ariaLabel.slice(-1)
        newdatacapt['optactive'] = parseInt(active)
        newdata['tunai'] = 0
        newdata['dp'] = 0
        newdata['kartucash'] = 0
        newdata['kartu'] = 0
        setdata({...newdata})
        return setdatacapt({...newdatacapt})
      }
      if (ariaLabel === 'actvdisc') {
        setactvdisc(!actvdisc)
      }
      if (`${ariaLabel}`.includes('sugestion-')) {
        var formArray = Object.values(formRef.current)
        // type tunai
        if (datacapt?.optactive === 1) {
          formArray = formArray.find(fn => fn?.ariaLabel === 'jmltunai')
          newdata['tunai'] = parseInt(`${ariaLabel}`.split('-')[1])
          newdata['dp'] = 0
          newdata['kartucash'] = 0
          newdata['kartu'] = 0
          formArray.value = toCurrency(newdata['tunai'])
        }
        // type kredit
        if (datacapt?.optactive === 2) {
          formArray = formArray.find(fn => fn?.ariaLabel === 'jmldp')
          newdata['dp'] = parseInt(`${ariaLabel}`.split('-')[1])
          newdata['kartucash'] = 0
          newdata['tunai'] = 0
          newdata['kartu'] = 0
          formArray.value = toCurrency(newdata['dp'])
        }
        // type kartu
        if (datacapt?.optactive === 3) {
          formArray = formArray.find(fn => fn?.ariaLabel === 'jmlkartu')
          newdata['kartu'] = parseInt(`${ariaLabel}`.split('-')[1])
          newdata['dp'] = 0
          newdata['tunai'] = 0
          formArray.value = toCurrency(newdata['kartu'])
        }
        setdata({...newdata})
      }
    },
    [data, datacapt, actvdisc]
  )

  const onChangeCapture = useCallback(
    (e, value) => {
      var {ariaLabel, value} = e.target,
        newdata = data,
        newprice = 0
      // get total keranjang
      if (type === 'checkout') {
        if (data?.chart?.length > 0) {
          for (let i = 0; i < data.chart.length; i++) {
            var {price, nominaldisc, value: qty} = data.chart[i]
            newprice += (price - nominaldisc) * qty
          }
        }
      }

      if (ariaLabel === 'prcntdisc') {
        // calc percent of disc
        if (type === 'disc') {
          newdata = props.onPrcntDiscItem(e, data)
        }
        if (type === 'checkout') {
          var limit = 0,
            newdisc2 = '',
            newdisc = 0
          newdisc = value
          limit = 5
          if (`${newdisc}`.includes('%')) {
            newdisc = newdisc.replace(/\%/g, '')
          }
          if (`${newdisc}`.includes(',')) {
            newdisc = newdisc.replace(/\,/g, '.')
            const spliter = newdisc.split(/\./g)
            if (parseInt(newdisc) < 100 && spliter[1].length > 2) {
              for (let i = 0; i < newdisc.length; i++) {
                if (newdisc[i] === '.') {
                  newdisc2 += newdisc[i + 1]
                  newdisc2 += newdisc[i]
                  i += 1
                } else {
                  newdisc2 += newdisc[i]
                }
              }
            } else {
              newdisc2 = newdisc
            }
          } else {
            newdisc2 = newdisc
          }
          newdisc2 = `${newdisc2}`.slice(0, limit)
          if (`${newdisc2}`.includes('-')) {
            newdisc2 = 0
          }
          if (newdisc2 > 100) {
            newdisc2 = 100
          }
          newdisc2 = parseFloat(newdisc2) || 0
          newdata['disc'] = `${newdisc2}`
          newdata['nominaldisc'] = (newdisc2 * newprice) / 100
        }
      }

      if (ariaLabel === 'bankname') {
        if (type === 'checkout') {
          newdata['ket'] = `${value}`.trim()
        }
      }
      if (ariaLabel === 'nmnldisc') {
        // calc nominal of disc
        if (type === 'disc') {
          newdata = props.onNmnalDiscItem(e, data)
        }
        if (type === 'checkout') {
          var newnominal = 0

          newnominal = `${value}`
          newnominal = newnominal.includes('.')
            ? newnominal.replace(/\./g, '')
            : newnominal
          if (parseInt(newnominal) > newprice) {
            newnominal = newprice
          }
          if (`${newnominal}`.includes('-')) {
            newnominal = 0
          }
          newnominal = newnominal || 0
          newdata['disc'] =
            newnominal > 0 ? `${parseFloat((newnominal / newprice) * 100)}` : 0
          newdata['nominaldisc'] = parseInt(newnominal)
        }
      }

      if (['jmltunai', 'jmlkartu', 'jmldp'].includes(`${ariaLabel}`)) {
        if (`${value}`.includes('.')) {
          value = `${value}`.replace(/\./g, '')
        }
        if (type === 'checkout') {
          if (`${value}`.length < 14) {
            if (datacapt.optactive === 1) {
              newdata['tunai'] = parseInt(value)
            }
            if (datacapt.optactive === 2) {
              newdata['dp'] = parseInt(value)
            }
            if (datacapt.optactive === 3) {
              if (ariaLabel === 'jmlkartu') {
                // Limit jumlah kartu ke value grandtotalnya
                if (parseInt(value) > subcapt1) {
                  // console.log('jumlah kartu: ', value)
                  value = subcapt1
                }
                // console.log('Grandtotal',subcapt1)
                newdata['kartu'] = parseInt(value)
              } else {
                newdata['kartucash'] = parseInt(value)
              }
            }
          }
        }
      }
      setdata({...newdata})
    },
    [data, type]
  )

  const onChangeDate = useCallback(
    (e, field) => {
      var value = moment(e).format('yyyy-MM-DD')
      setdata({...data, [field]: value})
    },
    [data, type]
  )

  useImperativeHandle(ref, () => ({
    modal: modalPaymentRef.current,
    open,
    close,
  }))
  return (
    <VmModal
      ref={modalPaymentRef}
      classNameContent="ModalCheckoutPayment"
      classNameBody="FormCheckoutPayment"
    >
      <form
        ref={formRef}
        onChangeCapture={e => onChangeCapture(e, e?.nativeEvent?.target?.value)}
        onClick={onClick}
      >
        {/* Header */}
        <VmView className="EheadCheckoutPayment">
          <VmView className="EtitleCheckoutPayment">
            <VmText>{title}</VmText>
          </VmView>
          <VmView
            onClick={close}
            className="EbtncloseCheckoutPayment cursor-pointer"
          >
            <VmIcons
              size={16}
              name="VmXMarkIcon"
              color="var(--color-red0-payment)"
            />
          </VmView>
        </VmView>
        {/* Body */}
        <VmView className="EbodyCheckoutPayment">
          <Body
            {...{
              data,
              actvdisc,
              datacapt,
              subcapt1,
              subcapt2,
              nominaldisc,
              disc,
              close,
              type,
              onChangeDate,
              props,
            }}
          />
        </VmView>
        {/* Footer */}
        <VmView className="EfootCheckoutPayment">
          <Foot
            {...{
              data,
              datacapt,
              subcapt1,
              subcapt2,
              disc,
              nominaldisc,
              close,
              type,
              props,
            }}
          />
        </VmView>
      </form>
    </VmModal>
  )
})

export default ModalPembayaran
