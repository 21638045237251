import moment from 'moment'

import {VmCardContainer, VmView, VmText, VmDivider, VmButton} from 'components'
import {reportDateFormat, reportDateFormatNoTime} from 'config/dateFormat'
import {isArray} from 'utils'
import classNames from 'classnames'
import {empty} from 'utils/functions'
import {Fragment} from 'react'

export default function VmCardReport({
  item,
  index = 0,
  classname = '',
  buttonLabel = 'Detail',
  imageComponent = '',
  buttonAction = () => {},
  onCardItemClick = () => {},

  dataNumber = true,
  button = false,
  buttonAdditionalProps = {},
  withStatus = false,

  headerFields = [],
  fields = [],
  aliasFields = [],
  statusDbColumn = 'status',
  footerLabel = '',
  footerValue = '',
  footerDividerVisible = true,
  customStatusComponent = false,
  cardContentComponent = false,
  cardHeaderComponent = () => {},
  footerContentComponent = () => {},
}) {
  const mainTextStyle = 'text-gray-700'
  const dimTextStyle = 'text-gray-500'

  const renderImageComponent = item => {
    const dbColumnValue = item[imageComponent.dynamicProps.dbColumn]
    const src = imageComponent.dynamicProps.alias.find(
      val => Object.keys(val)[0] === dbColumnValue
    )
    if (!src) return
    return imageComponent.component({
      ...imageComponent.props,
      [imageComponent.dynamicProps.forProps]: src[dbColumnValue],
    })
  }

  const handleAlias = (alias, item, fieldsItem) => {
    if (!alias) {
      const params = statusDbColumn?.customFormatParams ?? []
      return statusDbColumn?.customFormat
        ? statusDbColumn?.customFormat(item, ...params)
        : item
    }

    const aliasFields = alias.find(val => val.dbColumn === fieldsItem)
    return aliasFields.alias.find(val => (val.value === item ? val.label : ''))
      ?.label
  }

  const handleTextColor = (alias, item) => {
    if (statusDbColumn?.color) return statusDbColumn?.color
    const aliasFields = alias.find(val => val.dbColumn === statusDbColumn)
    return aliasFields.alias.find(val => (val.value === item ? val.color : ''))
      ?.color
  }

  const statusTextStyle =
    (isArray(aliasFields) || statusDbColumn?.color
      ? handleTextColor(aliasFields, item[statusDbColumn])
      : '') + ' p-1 rounded text-sxs'

  const handleFooterValue = () => {
    if (footerValue?.name) return item[footerValue?.name] ?? '-'
    return footerValue ?? '-'
  }

  const formatRow = (
    row,
    dateFormat = false,
    customFormat = false,
    customFormatParams = [],
    withTimeFormat = true
  ) => {
    if (!row) return '-'
    return dateFormat
      ? moment
          .utc(row)
          .format(withTimeFormat ? reportDateFormat : reportDateFormatNoTime)
      : customFormat
      ? customFormat(row, ...customFormatParams)
      : row
  }

  const renderRow = (fieldsData, isHeader = false) =>
    fieldsData.map((fieldsItem, i) =>
      fieldsItem.name ? (
        <VmText
          key={i}
          className={
            isHeader
              ? mainTextStyle
              : classNames(dimTextStyle, fieldsItem.className)
          }
        >
          {formatRow(
            item[fieldsItem.name],
            fieldsItem?.dateFormat,
            fieldsItem?.customFormat,
            fieldsItem?.customFormatParams,
            fieldsItem?.withTimeFormat
          )}
        </VmText>
      ) : fieldsItem?.children ? (
        <Fragment key={i}>
          {typeof fieldsItem?.children === 'function'
            ? fieldsItem?.children(item, fieldsItem?.dbColumn)
            : fieldsItem?.children}
        </Fragment>
      ) : (
        <VmText key={i} className={isHeader ? mainTextStyle : dimTextStyle}>
          {item[fieldsItem] ?? '-'}
        </VmText>
      )
    )

  return (
    <VmCardContainer classname={`my-4 ${classname}`} hover>
      <VmView onClick={() => onCardItemClick(item)}>
        {/* Section Card Header */}
        <VmView className="flex flex-row justify-between items-start">
          <VmView className="flex flex-row items-center min-w-fit">
            {/* Section Data Number */}
            {dataNumber ? (
              <VmView className="bg-blue-500 box-border mr-2 w-10 h-10 rounded-xl flex items-center justify-center">
                <VmText className="text-xs text-white">{index + 1}</VmText>
              </VmView>
            ) : null}

            {/* Section Header Text */}
            <VmView className="flex flex-col">
              {renderRow(headerFields, true)}
            </VmView>
          </VmView>

          <VmView className="flex flex-col items-end">
            {cardHeaderComponent(item)}
            {/* Section Text Status */}
            {withStatus && statusDbColumn ? (
              <VmView className={statusTextStyle}>
                {handleAlias(
                  aliasFields,
                  item[statusDbColumn?.name ?? statusDbColumn],
                  statusDbColumn?.name ?? statusDbColumn
                )}
              </VmView>
            ) : null}

            {!empty(customStatusComponent) ? customStatusComponent : <></>}
          </VmView>
        </VmView>
        <VmDivider className="mt-2 mb-2" />

        {/* Section Card Content */}
        <VmView className="flex flex-row items-center">
          {/* Section Foto / Gambar / Icon*/}
          {imageComponent ? renderImageComponent(item) : null}

          {/* Section List Data Content */}
          <VmView className="flex-1">
            {fields.length > 0 && !cardContentComponent
              ? renderRow(fields)
              : cardContentComponent
              ? cardContentComponent(item)
              : null}
          </VmView>
        </VmView>
      </VmView>

      {/* Section Footer */}
      {button || footerLabel ? (
        <>
          {footerDividerVisible ? (
            <VmDivider className="mt-2 mb-4" />
          ) : (
            <VmView className="my-2" />
          )}
          <VmView className=" flex flex-row justify-between items-start">
            {!!footerLabel && (
              <VmView className="flex flex-row items-start">
                <VmText className={mainTextStyle + ' mr-2'}>
                  {footerLabel}
                </VmText>
                <VmText className={dimTextStyle}>{handleFooterValue()}</VmText>
              </VmView>
            )}
            {button && (
              <VmButton
                className={classNames('hover:!bg-sky-600 ml-auto')}
                onClick={() => buttonAction(item)}
                {...buttonAdditionalProps}
              >
                {buttonLabel}
              </VmButton>
            )}
          </VmView>
        </>
      ) : (
        footerContentComponent(item)
      )}
    </VmCardContainer>
  )
}
