import moment from 'moment'
import {tglYMD} from 'utils/functions'

// import {tglDashymd} from '../../../config/dateFormat'
let today = tglYMD()

let deftgllahir = new Date()
// deftgllahir.setFullYear(2001)
// deftgllahir.setMonth(8)
// deftgllahir.setDate(11)

export const pasienDummy = {
  parentid: 1,
  parentrm: '',
  statussambung: '1',
  parentnama: '',
  pasnama: 'Muhammad Khoiron MM',
  pasrm: '',
  pasrmcard: '',
  pasid: 0,
  pasjaminan: '',
  pasalamat: 'Semolowaru Utara Rt 03 Rw 04',
  pasjk: 'Laki-laki',
  pastgllahir: deftgllahir,
  pasnobpjs: '88992271118271',
  pasnokk: '3515171882291',
  pasnoktp: '3515171889901',
  pasgoldarah: 'A+',
  pasumur: '',
  provinsi: {
    id: '0',
    nama: '',
  },
  kota: {
    id: '0',
    nama: '',
  },
  kecamatan: {
    id: '34055',
    nama: '',
  },
  agama: {
    id: '1',
    nama: 'Islam',
  },
  pendidikan: {
    id: '3',
    nama: 'SMK',
  },
  pasid_satusehat: '',
  agnama: '',
  pendnama: '',
  pastelp: '085774483389',
  paspekerjaan: 'Tukang Kebun',
  pasemail: 'asian@gmail.com',
  pasalergi: 'Oskadon Extra, Pil KB',
  pasalergimakanan: 'Singkong Goreng',
  pasconsent: 0,
  tahun: '0',
  bulan: '0',
  hari: '0',
  consent1: true,
  consent2: true,
  consent3: true,

  // General Consent
  pennama: '',
  pennik: '',
  penhubungan: '1',
  penjk: 'Laki-laki',
  pentelp: '',
  penalamat: '',
  consent_editable: true,
}

export const pasienEmpty = {
  parentid: 0,
  parentrm: '',
  parentnama: '',
  statussambung: 0,
  pasnama: '',
  pasrm: '',
  pasid: 0,
  pasjaminan: '',
  pasalamat: '',
  pasjk: 'Laki-laki',
  pastgllahir: deftgllahir,
  pasnobpjs: '',
  pasnokk: '',
  pasnoktp: '',
  pasgoldarah: '',
  umur: '',
  alamat: '',
  provinsi: {
    id: '0',
    nama: '',
  },
  kota: {
    id: '0',
    nama: '',
  },
  kecamatan: {
    id: '0',
    nama: '',
  },
  pendidikan: {
    id: '-1',
    nama: '',
  },
  agama: {
    id: '-1',
    nama: '',
  },

  pasid_satusehat: '',
  statussatusehat: false,
  agnama: '',
  pendnama: '',
  pastelp: '',
  paspekerjaan: '',
  pasemail: '',
  pasalergi: '',
  pasalergimakanan: '',
  pasconsent: 0,
  tahun: '0',
  bulan: '0',
  hari: '0',
  consent1: false,
  consent2: false,
  consent3: false,

  // General Consent
  pennama: '',
  pennik: '',
  penhubungan: '0',
  // penjk: 'Laki-laki',
  penjk: '0',
  pentelp: '',
  penalamat: '',
  consent_editable: true,
}
