import {APIDOC} from 'config/apiurl'

export const dataFields = ['pasnama', 'pasrm', 'total']

export const customExportAttr = [
  {
    url: APIDOC + '/prd-lap-pembayaran-pemeriksaan/export-web',
    title: 'Export rekap',
    downloadable: true,
  },
  {
    url: APIDOC + '/prd-lap-pembayaran-pemeriksaan/export-kategori-web',
    title: 'Export rekap detail',
    downloadable: true,
  },
]

const classExportAttrWeb = {
  content:
    'bg-white flex flex-row rounded-xl p-4 my-3 cursor-pointer shadow-md hover:scale-105 hover:bg-green-50/90',
  title: 'flex flex-col',
}
export const customExportAttrWeb = [
  {
    url: APIDOC + '/prd-lap-pembayaran-pemeriksaan/export-web',
    title: 'Export rekap',
    classNames: classExportAttrWeb,
  },
  {
    url: APIDOC + '/prd-lap-pembayaran-pemeriksaan/export-kategori-web',
    title: 'Export rekap detail',
    classNames: classExportAttrWeb,
  },
]

export const customPrintAttrWeb = [
  {
    url: APIDOC + '/prd-lap-pembayaran-pemeriksaan/print-web',
    title: 'Cetak rekap',
    desc: 'Fungsi untuk mengekspor data dalam bentuk pdf',
    type: 'PDF',
    color: 'red',
    classNames: classExportAttrWeb,
  },
]
export const customPrintItemAttrWeb = {
  url: APIDOC + '/prd-lap-pembayaran-pemeriksaan/print-item-web',
  title: 'Cetak',
  desc: 'Fungsi untuk mengekspor data dalam bentuk pdf',
  type: 'PDF',
  color: 'red',
  classNames: classExportAttrWeb,
}

export const filterFields = [
  {
    'Semua Jenis Pembayaran': [
      {value: 'tunai', label: 'Tunai'},
      {value: 'kartu', label: 'Kartu'},
      {value: 'piutang', label: 'Piutang'},
    ],
    dbColumn: 'pemjenis',
  },
  {
    Sorting: [
      {label: 'Tanggal Pembayaran', dbColumn: 'pemtanggal'},
      {label: 'Nama', dbColumn: 'pasnama'},
      {label: 'No. RM', dbColumn: 'pasrm'},
      {label: 'No. Faktur', dbColumn: 'pemnofaktur'},
    ],
  },
]

export const aliasFields = [
  {
    dbColumn: 'pemjenis',
    alias: [
      {
        value: 'tunai',
        label: 'Tunai',
        color: 'text-green-500 bg-green-200',
      },
      {
        value: 'kartu',
        label: 'Kartu',
        color: 'text-blue-500 bg-blue-200',
      },
      {
        value: 'piutang',
        label: 'Piutang',
        color: 'text-yellow-500 bg-yellow-200',
      },
    ],
  },
]
export const pageTitle = 'Laporan Pembayaran'
