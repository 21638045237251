import moment from 'moment'

const modelData = ({key, data}) => {
  const reg = 'dbdok'
  switch (key) {
    case 'search-diagnosa':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          dctid: data?.dctid,
          reg,
        }),
      }
    case 'detail-medical-record':
    case 'patient-medical-record':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          id: data?.id,
          kid: data?.kid,
          pid: data?.pid,
          dctid: data?.dctid,
          notid: data?.notid,
          limit: data?.limit,
          offset: data?.offset,
          status: data?.status ?? [3, 4],
          reg,
        }),
      }
    case 'patient-visit-info':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          id: data?.id,
          pid: data?.pid,
          dctid: data?.dctid,
          reg,
        }),
      }
    case 'index':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          dctid: data?.dctid,
          cari: data?.cari,
          status: data?.status,
          dateStart: data?.dateStart ?? moment().format('YYYY-MM-DD'),
          dateEnd: data?.dateEnd ?? moment().format('YYYY-MM-DD'),
          limit: data?.limit,
          offset: data?.offset,
          reg,
        }),
      }
    case 'insert':
      return {
        req: JSON.stringify({
          ep: data._ldata,
          app: data.app,
          reg,
          data: {
            // SOAP
            keluhanutama: data?.keluhanutama ?? '',
            keluhantambahan: data?.keluhantambahan ?? '',
            nadi: data?.nadi ?? '',
            suhu: data?.suhu ?? '',
            nafas: data?.nafas ?? '',
            tekanandarah: data?.tekanandarah ?? '',
            tinggi: data?.tinggi ?? '',
            berat: data?.berat ?? '',
            perut: data?.perut ?? '',
            rambut: data?.rambut ?? '',
            matapupil: data?.matapupil ?? '',
            matasclera: data?.matasclera ?? '',
            telingacerumen: data?.telingacerumen ?? '',
            telingamembran: data?.telingamembran ?? '',
            hidungpolip: data?.hidungpolip ?? '',
            hidungdeviasi: data?.hidungdeviasi ?? '',
            tengggorokan: data?.tengggorokan ?? '',
            gigicaries: data?.gigicaries ?? '',
            gigikelainan: data?.gigikelainan ?? '',
            leher: data?.leher ?? '',
            jantunghr: data?.jantunghr ?? '',
            jantungbunyi: data?.jantungbunyi ?? '',
            jantungmur: data?.jantungmur ?? '',
            paruvesikuler: data?.paruvesikuler ?? '',
            paruronchi: data?.paruronchi ?? '',
            palpasinyeri: data?.palpasinyeri ?? '',
            palpasibenjol: data?.palpasibenjol ?? '',
            palpasihepar: data?.palpasihepar ?? '',
            palpasilien: data?.palpasilien ?? '',
            auskultasi: data?.auskultasi ?? '',
            ataskelainan: data?.ataskelainan ?? '',
            ataskuku: data?.ataskuku ?? '',
            atasfisio: data?.atasfisio ?? '',
            ataspato: data?.ataspato ?? '',
            bawahkelainan: data?.bawahkelainan ?? '',
            bawahkuku: data?.bawahkuku ?? '',
            bawahfisio: data?.bawahfisio ?? '',
            bawahpato: data?.bawahpato ?? '',
            kulit: data?.kulit ?? '',
            diagnosa: data?.diagnosa ?? null,
            icdid: data?.icdid ?? null,
            hasil: data?.hasil ?? '',
            terapi: data?.terapi ?? '',
            anjuran: data?.anjuran ?? '',
            id: data?.id ?? null,
            kid: data?.kid ?? null,
            pid: data?.pid ?? null,
            dctid: data?.dctid ?? null,
            lock: data?.lock ?? null,
            customPcare: data?.customPcare ?? null,
            kunpcarekunjungan: data?.kunpcarekunjungan ?? null,
            kdkesadaran: data?.kdkesadaran ?? null,
            kdstatuspulang: data?.kdstatuspulang ?? null,
            kdkhusus: data?.kdkhusus ?? null,
            kdspesialis: data?.kdspesialis ?? null,
            kdsubspesialis: data?.kdsubspesialis ?? null,
            pcaretglpulang: data?.pcaretglpulang ?? null,
            kdsarana: data?.kdsarana ?? null,
            kddiag3: data?.kddiag3 ?? null,
            anamnesa: data?.anamnesa ?? null,
            kdprognosa: data?.kdprognosa ?? null,
            kdalergimakanan: data?.kdalergimakanan ?? null,
            kdalergiobat: data?.kdalergiobat ?? null,
            kdalergiudara: data?.kdalergiudara ?? null,
            terapinonobat: data?.terapinonobat ?? null,
            bmhp: data?.bmhp ?? null,
          },
        }),
      }
    default:
      return null
  }
}

export {modelData}
