import classNames from 'classnames'
import {VmButton, VmIcons, VmInput, VmView} from 'components'
import {LuCalendarPlus, LuCalendarClock} from 'react-icons/lu'
import {FaDiagnoses} from 'react-icons/fa'
import {VscReferences} from 'react-icons/vsc'
import {FaTransgender} from 'react-icons/fa'
import {useEffect, useState} from 'react'
import {calcAgeYearOnly} from 'utils/calc-age'
import moment from 'moment'

const defaultData = {
  // No Kunjungan
  noKunjungan: 'G1',
  // Nama Peserta
  nama: 'Pcare',
  // No Kartu
  noKartu: '0002079280574',
  // Tanggal Pelayanan
  tglDaftar: 'Tanggal Pelayanan',
  // Tanggal Entri
  status: 'Status',
  // Jenis Kelamin
  jk: 'Jenis Kelamin',
  // Diagnosa
  polikegiatan: 'Poli/Kegiatan',
  // Dirujuk
  dirujuk: 'Dirujuk',
}
export default function CardItem({
  label = '',
  data = defaultData,
  index = 0,
  onDelete = () => {},
}) {
  const [
      {
        noKunjungan = '',
        noKartu = '',
        nama = '',
        tglDaftar = '',
        status = '',
        jk = '',
        polikegiatan = '',
        showdelete = true,
      },
      setdata,
    ] = useState(data),
    classPM =
      'relative flex justify-center items-center rounded bg-white overflow-hidden border-[0.5px] min-w-[1.25rem] h-5 p-[1px]',
    classHTitle =
      'text-prevent-select text-xs font-medium text-gray2-payment mb-1',
    classCSect = 'relative flex flex-row justify-between p-4'

  useEffect(() => {
    setdata(data)
  }, [data])

  return (
    <VmView className="flex flex-col overflow-hidden !text-black2-payment bg-gray7-payment mb-4 last:mb-20 rounded-xl rounded-t-2xl gap-1">
      {/* Head */}
      <VmView className="relative flex flex-wrap bg-white text-prevent-select">
        {/* Right Header */}
        <VmView className="flex flex-col px-4 py-3 mr-auto">
          {/* noKunjungan */}
          <VmView className="pb-1 font-semibold">{noKunjungan}</VmView>
          {/* Nama */}
          <VmView className="flex flex-wrap text-sm">{nama}</VmView>
          {/* Nama */}
          <VmView className="flex flex-wrap text-sm">{noKartu}</VmView>
        </VmView>
        {/* Left Header */}
        <VmView className="text-sm p-1">
          {/* showdelete */}
          {showdelete && status != 'Sudah Dilayani' ? (
            <VmView
              label={`delete-item${label}-##-${index}-##-${noKunjungan}`}
              className="flex justify-center items-center text-red-600 rounded-lg w-8 h-8 p-[1px]"
              onClick={() => onDelete(data)}
            >
              <VmIcons
                onClick={() => onDelete(data)}
                label={`delete-item${label}-##-${index}-##-${noKunjungan}`}
                name="VmTrashIcon"
                variant="outline"
                size={20}
              />
            </VmView>
          ) : null}
        </VmView>
      </VmView>
      {/* Footer */}
      <VmView className="relative flex flex-wrap bg-white text-prevent-select">
        <VmView className="flex flex-col p-4 w-fill-available text-sm">
          <VmView className="grid grid-cols-2 gap-4">
            {/* Tanggal Pelayanan */}
            <VmView className="flex mr-auto gap-2">
              <VmView className="flex items-center w-7 h-7 pt-1">
                <LuCalendarClock size={25} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">{'Pelayanan'}</VmView>
                <VmView className="font-bold break-all">{tglDaftar}</VmView>
              </VmView>
            </VmView>
            {/* Status */}
            <VmView className="flex mr-auto gap-2">
              <VmView className="flex items-center w-7 h-7 pt-1">
                <LuCalendarPlus size={25} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">{'Status'}</VmView>
                <VmView className="font-bold break-all">{status}</VmView>
              </VmView>
            </VmView>
            {/* No urut */}
            <VmView className="flex mr-auto gap-2">
              <VmView className="flex items-center w-7 h-7 pt-1">
                <LuCalendarPlus size={25} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">No. Urut</VmView>
                <VmView className="font-bold break-all">
                  {data?.noUrut || '-'}
                </VmView>
              </VmView>
            </VmView>
            {/* Usia */}
            <VmView className="flex mr-auto gap-2">
              <VmView className="flex items-center w-7 h-7 pt-1">
                <LuCalendarPlus size={25} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">Usia</VmView>
                <VmView className="font-bold break-all">
                  {data?.defaultdata?.peserta?.tglLahir
                    ? calcAgeYearOnly(
                        moment(
                          data?.defaultdata?.peserta?.tglLahir,
                          'DD-MM-YYYY'
                        )
                      )
                    : '-'}
                </VmView>
              </VmView>
            </VmView>
            {/* Jenis Kelamin */}
            <VmView className="flex mr-auto gap-2">
              <VmView className="flex items-center w-7 h-7 pt-1">
                <FaTransgender size={25} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">{'Jenis Kelamin'}</VmView>
                <VmView className="font-bold break-all">
                  {jk?.length > 0 ? jk : '-'}
                </VmView>
              </VmView>
            </VmView>
            {/* Diagnosa */}
            <VmView className="flex mr-auto gap-2">
              <VmView className="flex items-center w-7 h-7 pt-1">
                <FaDiagnoses size={25} />
              </VmView>
              <VmView className="flex flex-col mr-auto">
                <VmView className="text-sm">{'Poli/Kegiatan'}</VmView>
                <VmView className="font-bold break-all">
                  {polikegiatan?.length > 0 ? polikegiatan : '-'}
                </VmView>
              </VmView>
            </VmView>
          </VmView>
        </VmView>
      </VmView>
    </VmView>
  )
}
