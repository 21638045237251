import {useState, useEffect} from 'react'

export default function useDebounce(value, milliSeconds) {
  const [debouncedValue, setDebouncedValue] = useState(value)
  const [loading, setloading] = useState(true)

  useEffect(() => {
    setloading(true)
    const handler = setTimeout(() => {
      setDebouncedValue(value)
      setloading(false)
    }, milliSeconds)

    return () => {
      clearTimeout(handler)
    }
  }, [value, milliSeconds])

  return {debouncedValue, loading}
}
