import {epoin} from 'controllers/epoin'
import {modelData} from './model'
import {
  actionServer,
  // successHandler
} from './server'

const PatientExaminationController = (event, navigate) => {
  const {key, dispatch, _ldata} = event
  const newevent = modelData({key, data: event})
  const actionData = {
    dispatch,
    _ldata,
    key: key,
    useAlert: event?.useAlert,
    epoin: epoin[_ldata],
    data: {input: newevent},
  }
  switch (key) {
    case 'search-diagnosa':
    case 'patient-visit-info':
    case 'patient-medical-record':
    case 'detail-medical-record':
    case 'search':
    case 'index':
    case 'insert':
      return actionServer(actionData, navigate, event?.num > 0 ? event.num : 0)
    default:
      break
  }
}

export default PatientExaminationController

// controllers(
//   {
//     key: 'index',
//     dispatch: () => {},
//     _ldata: 'mPatientVisitInfo',
//   },
//   navigate
// )
//   .then(res => {
//     console.log('res', res)
//   })
//   .catch(err => {
//     console.log('err', err)
//   })
//   .finally(() => {
//     console.log('finally')
//   })
