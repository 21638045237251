import React, {useEffect} from 'react'
import {
  node,
  oneOfType,
  oneOf,
  bool,
  string,
  array,
  object,
  func,
  number,
} from 'prop-types'
import classNames from 'classnames'
import {VmButton} from 'components'

const VmPagination = props => {
  const {
    className,
    innerRef,
    addListClass,
    activePage,
    size,
    firstButton,
    previousButton,
    nextButton,
    lastButton,
    dots,
    arrows,
    doubleArrows,
    limit,
    pages,
    align,
    onActivePageChange,
    variant = 'outline',
    ...attributes
  } = props

  // console.log('vmpagination top-level', pages)
  // useEffect(() => {
  //   console.log('VmPagination useEffect pages', pages)
  //   pages < activePage && onActivePageChange(pages, true)
  //   // eslint-disable-next-line
  // }, [pages])

  const listClasses = classNames(
    'flex flex-wrap place-items-center mt-5 mb-2',
    size && 'pagination-' + size,
    'justify-content-' + align,
    addListClass
  )

  const arrowClasses = ' mr-2'

  const backArrowsClasses = classNames(
    arrowClasses,
    activePage === 1 && 'disabled'
  )

  const nextArrowsClasses = classNames(
    arrowClasses,
    activePage === pages && 'disabled'
  )

  const showDots = (() => {
    return dots && limit > 4 && limit < pages
  })()
  const maxPrevItems = (() => {
    return Math.floor((limit - 1) / 2)
  })()
  const maxNextItems = (() => {
    return Math.ceil((limit - 1) / 2)
  })()
  const beforeDots = (() => {
    return showDots && activePage > maxPrevItems + 1
  })()
  const afterDots = (() => {
    return showDots && activePage < pages - maxNextItems
  })()
  const computedLimit = (() => {
    return limit - afterDots - beforeDots
  })()
  const range = (() => {
    return activePage + maxNextItems
  })()
  const lastItem = (() => {
    return range >= pages ? pages : range - afterDots
  })()
  const itemsAmount = (() => {
    return pages < computedLimit ? pages : computedLimit
  })()
  const items = (() => {
    if (activePage - maxPrevItems <= 1) {
      return Array.from(
        {
          length: itemsAmount,
        },
        (v, i) => i + 1
      )
    } else {
      return Array.from(
        {
          length: itemsAmount,
        },
        (v, i) => {
          return lastItem - i
        }
      ).reverse()
    }
  })()

  const setPage = number => {
    onActivePageChange(number)
  }

  if (pages < 1) {
    return
  }

  return (
    <nav
      className={className}
      aria-label="pagination"
      {...attributes}
      ref={innerRef}
    >
      <ul className={listClasses}>
        {doubleArrows && (
          <li className={backArrowsClasses}>
            <VmButton
              color="light"
              rounded="lg"
              onClick={() => setPage(1)}
              aria-label="Go to first page"
              aria-disabled={activePage === 1}
              disabled={activePage === 1}
              // variant={variant}
            >
              {firstButton}&nbsp;
            </VmButton>
          </li>
        )}
        {arrows && (
          <li className={backArrowsClasses}>
            <VmButton
              color="light"
              rounded="lg"
              onClick={() => setPage(activePage - 1)}
              aria-label="Go to previous page"
              aria-disabled={activePage === 1}
              disabled={activePage === 1}
              // variant={variant}
            >
              &nbsp;{previousButton}&nbsp;
            </VmButton>
          </li>
        )}
        {beforeDots && (
          <li role="separator" className=" mr-2 disabled">
            <VmButton
              color="light"
              rounded="lg"
              className="pointer-events-none"
              aria-label="..."
              // variant={variant}
            >
              {'...'}
            </VmButton>
          </li>
        )}
        {items.map(i => {
          let isActive = activePage == i
          return (
            <li className={`${isActive ? 'active' : ''}  mr-2`} key={i}>
              <VmButton
                className={'px-4'}
                color={isActive ? 'primary' : 'light'}
                rounded="lg"
                onClick={e => (isActive ? null : setPage(i, e))}
                aria-label={isActive ? `Current page ${i}` : `Go to page ${i}`}
                // active={isActive}
                // variant={variant}
              >
                {i}
              </VmButton>
            </li>
          )
        })}
        {afterDots && (
          <li role="separator" className=" mr-2 disabled">
            <VmButton
              color="light"
              rounded="lg"
              className="pointer-events-none"
              aria-label="..."
              // variant={variant}
            >
              {'...'}
            </VmButton>
          </li>
        )}
        {arrows && (
          <li className={nextArrowsClasses}>
            <VmButton
              color="light"
              rounded="lg"
              onClick={() => setPage(activePage + 1)}
              aria-label="Go to next page"
              aria-disabled={activePage === pages}
              disabled={activePage === pages}
              // variant={variant}
            >
              &nbsp;{nextButton}&nbsp;
            </VmButton>
          </li>
        )}
        {doubleArrows && (
          <li className={nextArrowsClasses}>
            <VmButton
              color="light"
              rounded="lg"
              onClick={() => setPage(pages)}
              aria-label="Go to last page"
              aria-disabled={activePage === pages}
              disabled={activePage === pages}
              // variant={variant}
            >
              &nbsp;{lastButton}
            </VmButton>
          </li>
        )}
      </ul>
    </nav>
  )
}

VmPagination.propTypes = {
  className: oneOfType([string, array, object]),
  innerRef: oneOfType([object, func]),
  activePage: number,
  dots: bool,
  arrows: bool,
  doubleArrows: bool,
  firstButton: oneOfType([node, string]),
  previousButton: oneOfType([node, string]),
  nextButton: oneOfType([node, string]),
  lastButton: oneOfType([node, string]),
  size: oneOf(['', 'sm', 'lg']),
  align: oneOf(['start', 'center', 'end']),
  addListClass: string,
  limit: number,
  pages: number,
  onActivePageChange: func.isRequired,
}

VmPagination.defaultProps = {
  activePage: 1,
  dots: true,
  arrows: true,
  doubleArrows: !true,
  limit: 5,
  firstButton: <>&laquo;</>,
  previousButton: <>&lsaquo;</>,
  nextButton: <>&rsaquo;</>,
  lastButton: <>&raquo;</>,
  align: 'start',
  pages: 10,
}

export default VmPagination
