import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
import {DataMitraApotek} from './classes'
const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  //   REACT_APP_BASE_URL_UTAMA_PHP5: API5,
} = process.env

const definput = {
  reg: 'dbdok',
  a: 'dk13',
  // 'show-error-log': 'TRUE',
}
const mSearchMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/index`,
        QueryString.stringify({...definput, ...input, reg_dokter: 'dbdok'})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil mitra.`,
          })
        )
      })
    var newdata = data
    if (data?.status === 1 && data?.data?.length > 0) {
      newdata['data'] = data.data.map(im => new DataMitraApotek(im))
    }
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil mitra.`,
        ...newdata,
      })
    )
  })

const mSearchNewMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/index-pengajuan`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil mitra.`,
          })
        )
      })
    var newdata = data
    if (data?.status === 1 && data?.data?.length > 0) {
      newdata['data'] = data.data.map(im => new DataMitraApotek(im))
    }
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil mitra.`,
        ...newdata,
      })
    )
  })

const mUpdateMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/update`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal memperbarui mitra.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        data,
        message: `Berhasil memperbarui mitra.`,
      })
    )
  })

const mAddMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/create-pengajuan`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal menambahkan mitra.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        ...data,
        message: `Berhasil menambahkan mitra.`,
      })
    )
  })

const mSetStatusMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/set-status`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal memperbarui mitra.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil memperbarui mitra.`,
        ...data,
      })
    )
  })

const mDeletePengajuan = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/delete-pengajuan`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal menghapus mitra.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        ...data,
        message: `Berhasil menghapus mitra.`,
      })
    )
  })

const mCheckTransaksi = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/check-transaksi`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(data)
  })

const mTidakSetuju = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/tidak-setuju`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal menyimpan data.`,
          })
        )
      })
    resolve(data)
  })

export {
  mSearchMitra,
  mSearchNewMitra,
  mUpdateMitra,
  mAddMitra,
  mSetStatusMitra,
  mDeletePengajuan,
  mCheckTransaksi,
  mTidakSetuju,
}
