import {VmView} from 'components'
import {FullErrorView, VmHeader} from 'molecules'
import React from 'react'

export default function ErrorLayout({
  onBackPress,
  message,
  onClickReload,
  showreload,
}) {
  return (
    <VmView className="fixed inset-0 bg-blue4-payment">
      <VmView className="flex flex-col p-4 h-screen">
        <VmHeader
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType="-"
          onBackPress={onBackPress}
          className="z-[5000]"
        />
        <FullErrorView
          itemClassName="m-4 z-[4900]"
          message={message}
          onClickRelaod={onClickReload}
          showreload={showreload}
        />
      </VmView>
    </VmView>
  )
}
