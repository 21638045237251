import * as React from 'react'

function VmCarbonHospital({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 20 20',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M 17.5,6.25 H 16.25 V 2.5 C 16.2497,2.16858 16.1179,1.85083 15.8835,1.61648 15.6492,1.38213 15.3314,1.25033 15,1.25 H 5 C 4.66858,1.25033 4.35083,1.38213 4.11648,1.61648 3.88213,1.85083 3.75033,2.16858 3.75,2.5 V 6.25 H 2.5 C 2.16858,6.25033 1.85083,6.38213 1.61648,6.61648 1.38213,6.85083 1.25033,7.16858 1.25,7.5 v 11.25 h 17.5 V 7.5 C 18.7497,7.16858 18.6179,6.85083 18.3835,6.61648 18.1492,6.38213 17.8314,6.25033 17.5,6.25 Z M 7.5190029,18.757395 7.5,13.125 C 7.5501594,12.844159 7.5519591,12.84008 7.6669236,12.696297 7.8351293,12.561476 7.8393255,12.556293 8.125,12.5 h 3.75 c 0.328951,0.06426 0.327528,0.06375 0.48084,0.207976 0.134352,0.147211 0.13251,0.149245 0.14416,0.417024 l 0.02701,5.623646 z M 10.055649,9.5026341 C 10.000209,6.413541 10.000209,6.9109069 10.000209,6.9109069 l -2.6945453,0.012151 c 1.0417394,-0.0014 1.6528109,-0.00768 2.6945423,-0.012151 l 0.01151,-2.4235965 -0.01151,2.4235965 2.65755,0.00174 -2.65755,-0.00174 c 0.01848,0.8639091 0.03696,1.7278182 0.05544,2.5917273 z',
    })
  )
}

const ForwardRef = React.forwardRef(VmCarbonHospital)
export default ForwardRef
