import {VmInput, VmPicker, VmText, VmView} from 'components'

const PatExamHeaders = ({title}) => {
  return (
    <VmView className="pt-3 px-3 pb-2 border-b border-r-indigo-900">
      <VmText className="PoppinsRegular text-2xl">{title}</VmText>
      <VmView className="flex flex-wrap items-center">
        <VmText className="PoppinsRegular mr-2">{title}</VmText>
        <VmPicker className="mr-2" />
        <VmInput
          className="mr-2"
          onClick={e => console.log(e)}
          typeInput="date"
        />
        <VmInput
          className="mr-2"
          onClick={e => console.log(e)}
          typeInput="date"
        />
      </VmView>
    </VmView>
  )
}
PatExamHeaders.defaultProps = {
  title: 'Title',
}
export default PatExamHeaders
