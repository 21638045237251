const getElement = ({
  parent = document,
  equal = 'label',
  tag = 'button',
  by = 'ariaLabel',
}) => {
  try {
    const allTag = Object.values(parent?.querySelectorAll(tag))
    return allTag.find(fn => fn[by] === equal)
  } catch (error) {
    console.error(error)
  }
}
const getAllElement = ({
  parent = document,
  equal = 'label',
  tag = 'button',
  by = 'ariaLabel',
}) => {
  try {
    const allTag = Object.values(parent?.querySelectorAll(tag))
    return allTag.filter(fn => fn[by] === equal)
  } catch (error) {
    console.error(error)
  }
}
export {getElement, getAllElement}
