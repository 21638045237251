import {
  useCallback,
  // useRef,
  useState,
  Fragment,
  useEffect,
  createRef,
  useRef,
} from 'react'
import {ReportMobile, TemplateForm} from 'template'
import {
  getKeyByValue,
  getParentNode,
  isEmpty,
  isFunction,
  calcAge,
  rgbToHex,
} from 'utils'
// import PatientInformation from './PatientInformation'
import PatExamFooters from 'template/PatientExaminationMobile/PatExamFooters'
import moment from 'moment'
import {FullErrorView, VmPulltoRefresh} from 'molecules'
import {rgbcolors} from 'config/colors'
import {VmView, VmIcons, VmInput, VmText, VmButton} from 'components'
import {
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import axios from 'axios'
import QueryString from 'qs'
import AlertOk from 'components/AlertOk'
const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

export default function PilihPasien() {
  const {dctid, kunid} = useParams()

  const navigate = useNavigate()
  const alert = useRef()

  const [searchParams, setSearchParams] = useSearchParams()
  // UI State
  const [error, setError] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(!false)
  const [fetchingMore, setFetchingMore] = useState(false)
  const [filter, setFilter] = useState('')
  const [cari, setCari] = useState('')
  const [cariTemp, setCariTemp] = useState('')
  const [count, setCount] = useState(0)
  const [gettingInitialData, setGettingInitialData] = useState(true)

  // Api State
  const [hasMore, setHasMore] = useState(true)
  // const [apiParams, setApiParams] = useState({...initialApiParams})

  const getListPasien = () => {
    return axios
      .post(
        `${BASE_URL}/pasien-v2/mobile-pasien`,
        QueryString.stringify({
          uid: dctid,
          device: 'mobile',
          namarm: cari,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('data List Pasien', res)

        if (res.length < 10) {
          // Halaman terakhir karena data < 10
          setHasMore(false)
        }
        setData(res)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get List Pasien')
        alert.current.show({message: 'Terjadi Kesalahan saat get List Pasien'})
      })
      .finally(() => {
        setLoading(false)
        setGettingInitialData(false)
      })
  }

  const fetchMore = () => {
    setFetchingMore(true)
    return axios
      .post(
        `${BASE_URL}/pasien-v2/mobile-pasien`,
        QueryString.stringify({
          uid: dctid,
          namarm: cari,
          offset: data.length,
          limit: 10,
          device: 'mobile',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('fetchMore data List Pasien', res)

        if (res.length < 10) {
          // Halaman terakhir karena data < 10
          setHasMore(false)
        }
        setData([...data, ...res])
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get List Pasien')
        alert.current.show({
          message: 'Terjadi Kesalahan saat fetchMore List Pasien',
        })
      })
      .finally(() => {
        setLoading(false)
        setFetchingMore(false)
      })
  }

  const onSubmitSearch = () => {
    setHasMore(true) // Has more jadi true lagi, karena melakukan pencarian dari awal.
    setCari(cariTemp)
  }

  useEffect(() => {
    getListPasien()

    return () => {}
  }, [cari])
  // Jika state cari berubah, maka akan memanggil kembali getListPasien()

  const searchInputStyle =
    'sticky w-full border-gray-400 text-gray-500 placeholder:text-gray-400 placeholder:text-sm h-10 rounded-xl p-2 mb-1 z-50'
  return (
    <VmPulltoRefresh onRefresh={() => {}}>
      <AlertOk ref={alert} />
      <ReportMobile
        data={data}
        statusDbColumn="status"
        headerFields={[{name: 'date', dateFormat: true}, 'number']}
        dataFields={['pasrm', 'pasnama', 'pasalamat', 'pastelp']}
        aliasFields={[
          {
            dbColumn: 'status',
            alias: [
              {
                value: 1,
                label: 'Belum Diperiksa',
                color: 'text-yellow-500 bg-yellow-200',
              },
              {
                value: 2,
                label: 'Dibatalkan',
                color: 'text-red-500 bg-red-200',
              },
              {
                value: 3,
                label: 'Sudah Diperiksa',
                color: 'text-blue-500 bg-blue-200',
              },
              {
                value: 4,
                label: (
                  <VmView className="flex flex-row items-center">
                    Sudah Disimpan
                    <VmIcons
                      size={12}
                      name="VmLockClosedIcon"
                      variant="outline"
                      className="ml-1 stroke-green-600"
                    />
                  </VmView>
                ),
                color: 'text-green-500 bg-green-200',
              },
            ],
          },
        ]}
        imageComponent={{
          component: props => <VmIcons {...props} />,
          props: {
            size: 65,
            className: 'mr-2',
            variant: 'avatar',
          },
          dynamicProps: {
            forProps: 'name',
            dbColumn: 'pasjk',
            alias: [
              {'Laki-laki': 'VmLDewasaColorIcon'},
              {Perempuan: 'VmPDewasaColorIcon'},
            ],
          },
        }}
        filterComponent={<VmView />}
        searchComponent={
          <VmInput
            value={cariTemp}
            onChange={e => setCariTemp(e.target.value)}
            type="text"
            className={searchInputStyle}
            placeholder="Cari No. WA / No. RM / Nama / Alamat Pelanggan"
            enterKeyHint="search"
            // onKeyDown={onSubmitSearch}
            onKeyUp={event => {
              if (event.key == 'Enter') {
                console.log('onSubmit editing')
                onSubmitSearch()
              }
            }}
            onBlur={event => {
              console.log('onSubmit editing')
              onSubmitSearch()
              // if (event.key == 'Enter') {
              // }
            }}
          />
        }
        hasMore={hasMore}
        loadMore={() => {
          // Jika sedang load new data / Get initial data maka ndak perlu panggil fetchMore lagi (Antisipasi data ndobel)
          if (!fetchingMore && !gettingInitialData) fetchMore()
        }}
        onCardItemClick={item => {
          let source = searchParams.get('source')
          if (source == 'TambahPasien') {
            // Set Local Storage (Source dari form tambah pasien - tambah relasi)
            localStorage.setItem('tambah_relasi_pasien', JSON.stringify(item))
          } else {
            localStorage.setItem('ubah_kunjungan_pasien', JSON.stringify(item))
          }
          navigate(-1)
        }}
        empty={data.length == 0 && !loading && !error}
        count={data.length}
        headerAttr={{enable: false}}
        // exportApiUrl={
        //   process.env.REACT_APP_BASE_URL_DOKTER +
        //   '/prd-lap-kunjungan-pasien/export'
        // }
        // exportApiParams={{
        //   ...initialApiParams,
        //   ...apiParams,
        //   ...cari,
        //   uid: dctid,
        // }}
        classNameANCHORHeader={'classNameANCHORHeader'}
        classNameANCHORContent={'classNameANCHORContent'}
      />
    </VmPulltoRefresh>
  )
}
