import {VmIcons, VmInput, VmSpinner, VmView, VmButton} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {eventTrigerInput, isFunction} from 'utils'
import {getElement} from 'utils/get-element'
import usePullToRefresh from 'utils/usePullToRefresh'
import useAddEventListener from 'utils/useAddEventListener'
import {useLocation} from 'react-router-dom'
import between from 'utils/between'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import {mSearchObatPcare} from './api'
import CardItem from './CardItem'
import classNames from 'classnames'
import {rgbcolors} from 'config/colors'
import {generateFlags, useCarts} from 'pages/prd-penjualan-obat-resep/utils'

export default function SearchObat({
  labelBackButton = 'modal-back-button',
  datamitra = {},
  datapasien = {},
  keyword: propskeyword = '',
  optcarts = {
    name: 'PcareExamntnCarts',
    flags: 'kdObat',
    flagstok: 'sedia',
  },
  fieldkey = ['kdObat'],
  cartracikan = false,
  ...props
}) {
  const showlog = false,
    contentRef = useRef(),
    limit = 100
  const {search} = useLocation()
  const useQuery = () =>
    useMemo(
      () => new URLSearchParams(search),
      // eslint-disable-next-line
      [search]
    )
  const query = useQuery()
  const dctid = parseInt(query.get('dctid'))
  const [offset, setoffset] = useState(0)
  const [keyword, setkeyword] = useState(propskeyword)
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  const [loadmore, setloadmore] = useState(true)
  const [onloadmore, setonloadmore] = useState(true)
  const [data, setdata] = useState([])
  const [params, setparams] = useState({
    log: showlog,
    input: {
      id: dctid,
      limit,
      offset,
      carimobile: keyword,
      'show-error-log': `${showlog}`.toUpperCase(),
    },
  })
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const {
    cart,
    carts,
    cartstoks,
    addtocarts,
    edititemcarts,
    removetocarts,
    setcarts,
  } = useCarts(optcarts)
  const newdata = useMemo(() => {
    // Filter qty data in cart
    const redata = data.map(im => {
      const flags = generateFlags(im, fieldkey)
      if (cart[flags]) {
        return cart[flags]
      }
      return {...im, flagcart: '', qty: 0}
    })
    return redata
  }, [carts, cart, data, fieldkey])

  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const onBackPress = () => {
    onCloseModal()
    if (isFunction(props?.close)) {
      props?.close()
    }
  }
  const onDelete = (item, onCancel = closeAlert) => {
    return setpropsalertform({
      ...propsalertform,
      type: 2,
      visible: true,
      title: 'Hapus Item',
      children: (
        <>
          Apakah anda akan menghapus obat <b>{item?.nmObat}</b>?
        </>
      ),
      onConfirm: () => removetocarts(item),
      onClose: closeAlert,
      onCancel: onCancel,
    })
  }
  const onCloseModal = () => {
    const event = new KeyboardEvent('keydown', {
      key: 'Escape',
      code: 'Escape',
      keyCode: 27,
      which: 27,
      bubbles: true,
    })
    document.dispatchEvent(event)
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel)
      }
      if (`${ariaLabel}`?.match(/^delete-item-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `delete-item-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) {
          finsInputEl.click()
        } else {
          return onDelete(item)
        }
        return
      }
      if (`${ariaLabel}`?.match(/^plus-item-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `plus-item-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) {
          finsInputEl.click()
        } else {
          item['qty'] += 1
          edititemcarts(item)
        }
        return
      }
      if (`${ariaLabel}`?.match(/^minus-item-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `minus-item-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) {
          finsInputEl.click()
        } else {
          item['qty'] -= 1
          edititemcarts(item)
        }
        return
      }
      if (`${ariaLabel}`?.match(/^add-item-modal-obat/i)) {
        const idx = `${ariaLabel}`.split('-##-')[1]
        const item = data[idx]
        const konversi = 1
        if (!!cartstoks[item[optcarts.flagstok]] && !cartracikan) {
          const {sisastok, stok, qtyincart} = cartstoks[item[optcarts.flagstok]]
          if (sisastok - 1 * (konversi ? konversi : 1) < 0) {
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  Stok obat <b>{item?.nmObat}</b> tidak mencukupi, sisa stok ={' '}
                  <b>{stok}</b>
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
            })
          }
        }
        const newitem = {
          ...item,
          qty: 1,
          stok: item?.sedia || 0,
          flagcart: generateFlags(item, fieldkey),
        }
        data[idx] = newitem
        return addtocarts(newitem)
      }
      if (ariaLabel === 'reset-modal-search-obat') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-modal-search-obat',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-modal-search-obat',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-modal-search-obat') {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onClickError', error)
      }
    }
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-modal-search-obat') {
        return setkeyword(value)
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onBlur = e => {
    // onBlur
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (`${ariaLabel}`?.match(/^input-qty-modal-obat-##/i)) {
        const splitters = `${ariaLabel}`.split('-##-')
        const idx = splitters[2]
        const item = cart[idx]
        const equal = `input-qty-##-${item?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.focus)) {
          return Promise.all([eventTrigerInput(finsInputEl, value)]).finally(
            () => {
              var newtimeout = null
              newtimeout = setTimeout(() => {
                e.target.value = finsInputEl.value
                clearTimeout(newtimeout)
              }, 100)
            }
          )
        } else {
          if (!!cartstoks[item[optcarts.flagstok]] && !cartracikan) {
            const {stok} = cartstoks[item[optcarts.flagstok]]
            if (Number(value) <= 0) {
              return onDelete(item, () => {
                e['target']['value'] = item?.qty || 1
                closeAlert()
              })
            }
            if (Number(value) <= stok) {
              item['qty'] = Number(value)
              edititemcarts(item)
              return
            } else {
              if (Number(value) === Number(stok)) {
                return
              }
              e['target']['value'] = stok
              item['qty'] = stok
              edititemcarts(item)
              return setpropsalertform({
                ...propsalertform,
                type: 4,
                visible: true,
                children: (
                  <>
                    Stok obat <b>{item?.nmObat}</b> tidak mencukupi, sisa stok ={' '}
                    <b>{stok}</b>
                  </>
                ),
                onConfirm: closeAlert,
                onClose: closeAlert,
              })
            }
          }
        }
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-modal-search-obat' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const fetchData = (params = {}) => {
    seterror(null)
    setparams(params)
    mSearchObatPcare(params)
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          if (params?.input?.offset === 0) {
            setdata(resdata?.data)
          } else {
            setdata([...newdata, ...resdata.data])
          }
          if (resdata?.data?.length < limit) {
            setloadmore(false)
          }
        } else {
          seterror(resdata?.message || 'Gagal mengambil data!')
          setloadmore(false)
        }
        if (showlog) console.log('fetchData:data', data)
      })
      .catch(ch => {
        if (showlog) console.log('fetchData:ch', ch)
        setloadmore(false)
        seterror('[C0] Gagal mengambil data!')
      })
      .finally(() => {
        if (showlog) console.log('fetchData:final')
        setloading(false)
        setonloadmore(false)
      })
  }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        carimobile: keyword,
      },
    })
  }
  const onFetchMore = () => {
    if (data.length > 0 && loadmore && !onloadmore) {
      setonloadmore(true)
      return fetchData({
        ...params,
        input: {
          ...params.input,
          offset: params.input.offset + limit,
        },
      })
    }
  }
  const onEndReached = el => {
    const isBottom = el?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      onFetchMore()
    }
  }
  const calcTopZero = elm => {
    return between(elm?.getBoundingClientRect()?.top - elm?.offsetTop, -5, 5)
      ? 0
      : 1
  }
  const {pullChange} = usePullToRefresh({
    topTarget: calcTopZero(contentRef.current?.children[1]),
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-modal-search-obat',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(params)
    return () => {}
  }, [])

  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'focusout', handler: onBlur})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  return (
    <VmView className="flex flex-col absolute inset-0 bg-white px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmView className="flex justify-between pb-4 pt-2">
          <VmView className="font-bold text-xls">Pilih Obat</VmView>
          <VmView
            className={classNames('cursor-pointer font-black text-xl')}
            style={{color: rgbcolors.danger}}
            onClick={onBackPress}
          >
            ⓧ
          </VmView>
        </VmView>
        {/* Searchbar */}
        <VmView className="flex flex-row mb-4 rounded-full !min-h-[2.3rem] overflow-hidden">
          <VmView className="relative flex flex-row bg-slate-50 overflow-x-hidden !w-[-webkit-fill-available]">
            <VmInput
              typeInput="text"
              className="absolute bg-slate-50 !text-xs !w-[-webkit-fill-available] !h-[-webkit-fill-available] border-0"
              hidering={true}
              placeholder={'Cari Obat'}
              label={`input-modal-search-obat`}
            />
            {keyword?.length > 0 && (
              <VmView
                label={`reset-modal-search-obat`}
                className="absolute flex items-center top-0 right-0 bottom-0 mr-2"
              >
                <VmIcons
                  size={16}
                  name="VmXCircleIcon"
                  variant="outline"
                  className="text-red5-payment"
                  label={`reset-modal-search-obat`}
                />
                <VmView
                  className="absolute inset-0"
                  label={`reset-modal-search-obat`}
                />
              </VmView>
            )}
          </VmView>
          <VmButton
            className="relative flex justify-center !flex-none p-3 !rounded-l-none !min-w-[50px] !bg-blue1-payment"
            hidering={true}
            label={`btn-modal-search-obat`}
          >
            <VmIcons
              size={14}
              name="VmMagnifyingGlassIcon"
              color="#FFFFFF"
              label={`btn-modal-search-obat`}
            />
            <VmView
              label={`btn-modal-search-obat`}
              className="absolute inset-0"
            />
          </VmButton>
        </VmView>
        {/* Content */}
        <VmView
          ref={contentRef}
          onScroll={onScroll}
          className="block overflow-y-scroll"
        >
          <VmView
            className="refresh-container w-fit -mt-10 m-auto"
            style={{marginTop: pullChange / 3.118 || ''}}
          >
            <VmView className="refresh-icon p-2 rounded-full">
              <VmIcons
                name="VmArrowPathIcon"
                style={{transform: `rotate(${pullChange}deg)`}}
                size={25}
              />
            </VmView>
          </VmView>
          {newdata.map((im, index) => {
            return (
              <CardItem
                label="-modal-obat"
                key={`CardItem-${index}`}
                index={index}
                data={im}
                cartracikan={cartracikan}
              />
            )
          })}
          {!loadmore ? (
            <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
              {error ? error : 'Sudah tidak ada data'}
            </VmView>
          ) : (
            <VmView
              key={'loadmore'}
              label="loadmore"
              className="relative self-center text-center text-gray2-payment last:mb-20 "
            >
              <VmSpinner className="text-blue1-payment" />
            </VmView>
          )}
        </VmView>
        {/* AlertForm */}
        <AlertForm {...propsalertform} />
        {/* Loading Full Screen */}
        <ProgressFullScreen visible={loading} />
      </VmView>
    </VmView>
  )
}
