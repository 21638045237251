import {VmButton, VmInput, VmSpinner, VmText, VmView} from 'components'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import VmHeader from 'components/layout/VmHeader'
import {VmFab, VmList, VmPicker} from 'components'
import {
  catatLogDokter,
  empty,
  tglDDMMMYYYY,
  tglDashNoSpace,
  tglMMMYYYY,
  tglYMD,
  toCurrency,
  triggerEventBus,
} from 'utils/functions'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {
  Navigate,
  redirect,
  useSearchParams,
  useNavigate,
} from 'react-router-dom'
import {
  VmCalendarDaysIcon,
  VmCheckIcon,
  VmMagnifyingGlassIcon,
  VmPhoneIcon,
  VmPinLocationIcon,
  VmPlusIcon,
  VmTrashIcon,
  VmXMarkIcon,
} from 'components/icons/solid'
import {API, graphqlOperation} from 'aws-amplify'
import {mAktifkanDiagnosa, mDeleteDiagnosa} from 'graphql/mutations'
import DatePicker, {registerLocale} from 'react-datepicker'
import CurrencyInput from 'react-currency-input-field'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from 'utils/localstorage'
import {APIBPJS, APIDOC, APISATSET} from 'config/apiurl'
// import {pasienDummy, pasienEmpty} from './dummy'
// import satusehaticon from '../../assets/satusehat.png'
// import bpjsicon from '../../../public/logo-bpjs-kesehatan.png'
import {VmLDewasaColorIcon} from 'components/icons/avatar'
import classNames from 'classnames'
import {ProgressFullScreen} from 'molecules'
import VmLoading from 'components/loader/VmLoading'

registerLocale('id', id)

const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

function ModalClubProlanis({visible, onClose}) {
  const [loadingDataClubProlanis, setLoadingDataClubProlanis] = useState(false)

  const getDataClubProlanis = () => {
    setLoadingDataClubProlanis(true)

    setTimeout(() => {
      setLoadingDataClubProlanis(false)
    }, 500)
  }

  useEffect(() => {
    if (visible) {
      getDataClubProlanis()
    }
    return () => {}
  }, [visible])

  return (
    <>
      {/* <!-- Modal toggle --> */}

      {/* <!-- Main modal --> */}
      <div
        id="default-modal"
        tabindex="-1"
        className={classNames(
          visible ? 'flex' : 'hidden',
          'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full flex'
        )}
        style={{background: 'rgba(0,0,0,0.5)'}}
        aria-modal="true"
        role="dialog"
      >
        <div className="relative p-4 flex-1  max-h-full">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            {/* <!-- Modal header --> */}
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Data Club Prolanis
              </h3>
              <button
                onClick={onClose}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="default-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-4 md:p-5 space-y-4">
              {!loadingDataClubProlanis ? (
                <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400 text-center">
                  Tidak ada data yang ditemukan.
                </p>
              ) : (
                <VmLoading size="xsmall" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default function Index() {
  const alertok = useRef()
  const params = useParams()
  const {id: dctid, nama_lengkap} = params
  const navigate = useNavigate()
  const [loading, setloading] = useState(false)
  const [activeTab, setActiveTab] = useState('entri_kegiatan')
  const [modalClubProlanisVisible, setModalClubProlanisVisible] =
    useState(false)

  useEffect(() => {
    console.log('useEffect dctid', dctid)

    if (dctid) {
      console.log('useEffect dctid Tidak Kosong', dctid)
    }
    return () => {}
  }, [dctid])

  const [formData, setFormData] = useState({
    tanggalPelaksanaan: new Date(),
    jenisKelompok: '0',
    pilihClubProlanis: '',
    jenisKegiatan: '0',
    materi: '',
    pembicara: '',
    lokasi: '',
    biayaHonorInternal: '0',
    biayaHonorEksternal: '0',
    biayaLainLain: '0',
    keterangan: '',
  })
  const [inputDaftarHadir, setInputDaftarHadir] = useState(false)
  const [nokartu, setNokartu] = useState('')
  const [loadingcaririwayat, setLoadingcaririwayat] = useState(false)
  const [loadingentrikegiatan, setLoadingentrikegiatan] = useState(false)

  const isRn = !!window?.ReactNativeWebView
  const [mode, setmode] = useState(isRn ? 'phone' : 'web')

  const handleChange = (field, value) => {
    setFormData({...formData, [field]: value})
    // Additional logic can be added here based on your requirements
  }

  const handleReset = () => {
    setFormData({
      tanggalPelaksanaan: new Date(),
      jenisKelompok: '0',
      pilihClubProlanis: '',
      jenisKegiatan: '0',
      materi: '',
      pembicara: '',
      lokasi: '',
      biayaHonorInternal: '0',
      biayaHonorEksternal: '0',
      biayaLainLain: '0',
      keterangan: '',
    })
  }

  const handleSimpan = () => {
    // Add logic to save the form data
    console.log('Form data saved:', formData)

    setLoadingentrikegiatan(true)
    sleep(1000).then(() => {
      setLoadingentrikegiatan(!true)

      // Catat Log Dokter
      catatLogDokter({
        keterangan: `Menambahkan entri kegiatan kelompok prolanis ${formData.materi}`,
        menu: 'PCare - Data Kelompok Prolanis',
        url: 'pcare-kelompok-prolanis',
        ip: '127.0.0.1',
        uid: dctid,
      })

      alertok.current?.show({
        message: 'Berhasil Menambahkan data Kegiatan Kelompok',
        type: 'success',
      })
      handleReset()
    })
  }

  useEffect(() => {
    console.log('Total Biaya', {
      biayaHonorInternal: formData.biayaHonorInternal,
      biayaHonorEksternal: formData.biayaHonorEksternal,
      biayaLainLain: formData.biayaLainLain,
    })
    return () => {}
  }, [
    formData.biayaHonorInternal,
    formData.biayaHonorEksternal,
    formData.biayaLainLain,
  ])

  let totalbiaya = useMemo(() => {
    return (
      parseFloat(formData.biayaHonorInternal?.replace(',', '.') ?? '0') +
      parseFloat(formData.biayaHonorEksternal?.replace(',', '.') ?? '0') +
      parseFloat(formData.biayaLainLain?.replace(',', '.') ?? '0')
    ).toFixed(2)
  }, [
    formData.biayaHonorInternal,
    formData.biayaHonorEksternal,
    formData.biayaLainLain,
  ])

  const onTabChange = (item, i) => {
    setActiveTab(item.value)
  }

  const entriKegiatan = () => {
    return (
      <>
        {/* Form Fields */}
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
          {/* Tanggal Pelaksanaan */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Tanggal Pelaksanaan
            </label>
            <DatePicker
              locale={'id'}
              selected={formData.tanggalPelaksanaan}
              onChange={date => handleChange('tanggalPelaksanaan', date)}
              className="text-xs w-full"
              wrapperClassName="w-full"
              withPortal
              customInput={
                <VmView className="w-full p-2.5 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 flex">
                  <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                  <VmView className="text-neutral-700 text-sm font-bold">
                    {tglDDMMMYYYY(formData.tanggalPelaksanaan)}
                  </VmView>
                </VmView>
              }
              // minDate={periodeawal}
              // maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            >
              <VmView />
            </DatePicker>
          </div>

          {/* Jenis Kelompok */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Jenis Kelompok
            </label>
            {/* Replace the options with your actual options */}
            <select
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.jenisKelompok}
              onChange={e => handleChange('jenisKelompok', e.target.value)}
            >
              <option value="0" disabled>
                Pilih Jenis Kelompok
              </option>
              <option value="01">Diabetes Melitus</option>
              <option value="02">Hipertensi</option>
              {/* Add your options here */}
            </select>
          </div>

          {/* Pilih CLUB PROLANIS */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Pilih CLUB PROLANIS
            </label>
            <VmView className="flex gap-2 items-center">
              <input
                placeholder="Nama Club (Nama Ketua)"
                type="text"
                className="flex-1 mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
                value={formData.pilihClubProlanis}
                onChange={e =>
                  handleChange('pilihClubProlanis', e.target.value)
                }
                readOnly
              />
              <VmButton
                onClick={() => {
                  openModalClubProlanis()
                  // getDataClubProlanis()
                }}
              >
                Cari
              </VmButton>
            </VmView>
          </div>

          {/* Jenis Kegiatan */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Jenis Kegiatan
            </label>
            {/* Replace the options with your actual options */}
            <select
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.jenisKegiatan}
              onChange={e => handleChange('jenisKegiatan', e.target.value)}
            >
              <option value="0" disabled>
                Pilih Jenis Kegiatan
              </option>
              <option value="01">Senam</option>
              <option value="10">Penyuluhan</option>
              <option value="11">Penyuluhan & Senam</option>
              {/* Add your options here */}
            </select>
          </div>

          {/* Materi */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Materi
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.materi}
              onChange={e => handleChange('materi', e.target.value)}
            />
          </div>

          {/* Pembicara */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Pembicara
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.pembicara}
              onChange={e => handleChange('pembicara', e.target.value)}
            />
          </div>

          {/* Lokasi */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Lokasi
            </label>
            <input
              type="text"
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.lokasi}
              onChange={e => handleChange('lokasi', e.target.value)}
            />
          </div>

          {/* Biaya Honor Internal */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Biaya Honor Internal (Rp.)
            </label>
            <CurrencyInput
              groupSeparator="."
              decimalSeparator=","
              allowNegativeValue={false}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.biayaHonorInternal}
              onValueChange={value => handleChange('biayaHonorInternal', value)}
            />
          </div>

          {/* Biaya Honor Eksternal */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Biaya Honor Eksternal (Rp.)
            </label>
            <CurrencyInput
              groupSeparator="."
              decimalSeparator=","
              allowNegativeValue={false}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.biayaHonorEksternal}
              onValueChange={value =>
                handleChange('biayaHonorEksternal', value)
              }
            />
          </div>

          {/* Biaya Lain-lain */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Biaya Lain-lain (Rp.)
            </label>
            <CurrencyInput
              groupSeparator="."
              decimalSeparator=","
              allowNegativeValue={false}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.biayaLainLain}
              onValueChange={value => handleChange('biayaLainLain', value)}
            />
          </div>

          {/* Total Biaya */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Total Biaya (Rp.)
            </label>
            <CurrencyInput
              groupSeparator="."
              decimalSeparator=","
              allowNegativeValue={false}
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={totalbiaya}
              readOnly
            />
          </div>

          {/* Keterangan */}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-600">
              Keterangan
            </label>
            <textarea
              className="mt-1 p-2 border rounded-md w-full focus:outline-none focus:border-blue-500"
              value={formData.keterangan}
              onChange={e => handleChange('keterangan', e.target.value)}
            />
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="flex justify-end mt-4">
          <button
            className="bg-green-500 text-white px-4 py-2 mr-2 rounded-md"
            onClick={handleSimpan}
          >
            Simpan {loadingentrikegiatan && <VmLoading size="xsmall" />}
          </button>
          <button
            className="bg-gray-200 text-gray-800 px-4 py-2 mr-2 rounded-md"
            onClick={handleReset}
          >
            Reset
          </button>
          {/* <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={() => console.log('Kembali button clicked')}
          >
            Kembali
          </button> */}
        </div>
      </>
    )
  }

  const riwayatKegiatan = () => {
    return (
      <>
        {/* Bulan */}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-600">
            Bulan
          </label>
          <DatePicker
            locale={'id'}
            selected={formData.tanggalPelaksanaan}
            onChange={date => handleChange('tanggalPelaksanaan', date)}
            className="text-xs w-full mb-4"
            wrapperClassName="w-full"
            withPortal
            customInput={
              <VmView className="w-full p-2.5 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 flex">
                <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                <VmView className="text-neutral-700 text-sm font-bold">
                  {tglMMMYYYY(formData.tanggalPelaksanaan)}
                </VmView>
              </VmView>
            }
            // minDate={periodeawal}
            // maxDate={new Date()}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          >
            <VmView />
          </DatePicker>
          <VmButton onClick={cariRiwayatKegiatan}>
            Cari {loadingcaririwayat && <VmLoading size="xsmall" />}
          </VmButton>
        </div>

        {/* Riwayat Kegiatan Kelompok */}
        <div className="mb-4">
          <VmText className="text-xl mb-4">Riwayat Kegiatan Kelompok</VmText>
          <VmText className="text-center">Tidak ada data.</VmText>
        </div>

        {/* Daftar Hadir */}
        <div className="mb-4">
          <VmText className="text-xl mb-4">Daftar Hadir</VmText>
          <VmView className="flex flex-col items-stretch md:flex-row md:items-center gap-2">
            <VmButton
              onClick={() => {
                setInputDaftarHadir(true)
              }}
            >
              Tambah Data Pasien
            </VmButton>

            {inputDaftarHadir && (
              <>
                <VmInput
                  value={nokartu}
                  className="my-2"
                  onChange={e => {
                    let val = e.target.value
                    setNokartu(val)
                  }}
                  placeholder={'No. Kartu'}
                />
                <VmButton
                  color="success"
                  onClick={() => {
                    validate()
                  }}
                >
                  Simpan
                </VmButton>
                <VmButton
                  color="danger"
                  onClick={() => {
                    setInputDaftarHadir(false)
                  }}
                >
                  Batal
                </VmButton>
              </>
            )}
          </VmView>
        </div>
      </>
    )
  }

  const validate = () => {
    if (empty(nokartu)) {
      alertok.current?.show({message: 'Harap isi field No. Kartu!'})
      return
    }
    alertok.current?.show({
      message: 'Silakan memilih Kegiatan Kelompok terlebih dahulu!',
    })
  }

  const cariRiwayatKegiatan = () => {
    setLoadingcaririwayat(true)
    sleep(1000).then(() => {
      setLoadingcaririwayat(false)
      alertok.current?.show({message: 'Data riwayat kegiatan tidak ditemukan'})
    })
  }

  const sleep = ms => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res()
      }, ms)
    })
  }

  const openModalClubProlanis = () => {
    setModalClubProlanisVisible(true)
  }

  return (
    <>
      <AlertOk ref={alertok} />

      <ModalClubProlanis
        visible={modalClubProlanisVisible}
        onClose={e => {
          setModalClubProlanisVisible(false)
        }}
      />

      <div className="container mx-auto mt-0 p-4">
        {/* Tab Container */}
        {mode == 'web' || true ? (
          <VmView id="tab-container" className="flex items-center my-6 gap-2">
            {(() => {
              let list_section = [
                {value: 'entri_kegiatan', text: 'Entri Kegiatan Kelompok'},
                {
                  value: 'riwayat_kegiatan',
                  text: 'Riwayat Kegiatan Kelompok & Daftar Hadir',
                },
              ]
              return list_section.map((item, i) => {
                return (
                  <VmButton
                    key={i}
                    onClick={() => onTabChange(item, i)}
                    variant={activeTab == item.value ? '' : 'outline'}
                    className={'p-4 font-bold md:text-base text-xs'}
                  >
                    {item.text}
                  </VmButton>
                )
              })
            })()}
          </VmView>
        ) : null}

        {activeTab == 'entri_kegiatan' ? entriKegiatan() : riwayatKegiatan()}
      </div>
    </>
  )
}
