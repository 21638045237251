import classNames from 'classnames'
import {VmButton, VmIcons, VmInput, VmText, VmView} from 'components'
import {array, bool, func, number, object, oneOfType, string} from 'prop-types'
import {useCallback, memo} from 'react'
import {isFunction, isObject} from 'utils'
import {toCurrency} from 'utils/functions'

var VmCartItem = {
  propTypes: {
    fields: array,
    item: object,
    index: oneOfType([string, number]),
    loadinginitial: bool,
    onClick: func,
    onCardItemClick: func,
    onClickChange: func,
    onClickDelete: func,
    onClickMinus: func,
    onClickPlus: func,
  },
  defaultProps: {
    fields: ['name', 'type', 'price', 'disc', 'value'],
    item: {
      name: 'Konsultasi Tanpa Usg',
      type: 'Pemeriksaan',
      price: '60.000,00',
      disc: 'Diskon 0,00%',
      value: '1',
    },
    index: 0,
    loadinginitial: false,
    onClick: () => {},
    onCardItemClick: () => {},
    onClickChange: () => {},
    onClickDelete: () => {},
    onClickMinus: () => {},
    onClickPlus: () => {},
  },
}

VmCartItem = memo(
  ({
    fields = ['name', 'type', 'price', 'disc', 'value'],
    item = {
      name: 'Konsultasi Tanpa Usg',
      type: 'Pemeriksaan',
      price: '60.000,00',
      disc: 'Diskon 0,00%',
      value: '1',
    },
    index = 0,
    loadinginitial = false,
    mode = '',
    label = '',
    ...props
  }) => {
    if (!isObject(item)) {
      throw new Error('Item is noat an Object!')
    }
    const data = {...item, index}
    const onClickProps = useCallback(
      (e, type = 'onClick') => {
        if (isFunction(props['onClick'])) {
          props['onClick'](e, data, type)
          return true
        }
        if (isFunction(props[type])) {
          props[type](e, data, type)
          return true
        }
      },
      [data, props]
    )
    return (
      <VmView
        onClick={e =>
          props?.onCardItemClick && onClickProps(e, 'onCardItemClick')
        }
        className={classNames(`VmCartItem`, mode)}
      >
        <VmView className={classNames('VmCartItemHead', mode)}>
          <VmView className="wrap-name ">
            {/* Title Single Line */}
            {item[fields[0]] && (
              <VmText
                typeText={'span'}
                className="name"
                data-placeholder={loadinginitial}
              >
                {item[fields[0]]}
              </VmText>
            )}
          </VmView>
          <VmView className="flex flex-row flex-wrap items-start justify-end">
            <VmButton
              onClick={e => onClickProps(e, 'onClickChange')}
              className="change"
              color="#FFFFFF"
            >
              <VmIcons
                size={14}
                name="VmPencilIcon"
                variant="outline"
                color="var(--color-blue0-payment)"
              />
            </VmButton>
            <VmButton
              onClick={e => onClickProps(e, 'onClickDelete')}
              className="delete"
              color="#FFFFFF"
            >
              <VmIcons
                size={14}
                name="VmTrashIcon"
                variant="outline"
                color="var(--color-red0-payment)"
              />
            </VmButton>
          </VmView>
        </VmView>
        <VmView className={classNames('VmCartItemFoot', mode)}>
          <VmView className="mr-auto pr-2 flex-1 ">
            {/* Price Single Line */}
            {item[fields[2]] && (
              <VmText className="price" data-placeholder={loadinginitial}>
                {toCurrency(item[fields[2]])}
              </VmText>
            )}
            {/* Disc Single Line */}
            {item[fields[3]] && (
              <VmText className="disc" data-placeholder={loadinginitial}>
                {item[fields[3]]}
              </VmText>
            )}
          </VmView>
          <VmView className="flex flex-row flex-wrap items-center justify-end">
            <VmButton
              onClick={e => onClickProps(e, 'onClickMinus')}
              className="buttonMinus"
            >
              <VmIcons size={17} name="VmMinusSmallIcon" />
            </VmButton>
            {/*  defaultValue={0} */}
            <VmInput
              readOnly={true}
              label={label}
              className="inputQty"
              value={item[fields[4]] || 0}
            />
            <VmButton
              onClick={e => onClickProps(e, 'onClickPlus')}
              className="buttonPlus"
            >
              <VmIcons size={17} name="VmPlusIcon" />
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
    )
  }
)
export default VmCartItem
