import React, {useMemo, useEffect, useRef, useState} from 'react'
import {useParams, useLocation, useNavigate, Navigate} from 'react-router-dom'
import controllers from 'controllers/PatientExaminationMobile/index.js'
import PatientExamination from './PatientExamination'
import SearchVisitorMobile from 'pages/prd-lap-kunjungan-pasien/Index'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {FullErrorView} from 'molecules'
import {isString} from 'utils'

const PatientExaminationIndex = () => {
  const navigate = useNavigate()
  const okRef = useRef()
  const yesNoRef = useRef()
  const {search, pathname} = useLocation()
  const useQuery = () =>
    useMemo(
      () => new URLSearchParams(search),
      // eslint-disable-next-line
      [search]
    )
  const query = useQuery()
  const {create} = useParams()
  const lstMenu = ['create', 'search', 'index', 'view', undefined]
  useEffect(() => {
    document.title = 'Rekam Medis'
  }, [])
  const dctid = query.get('dctid')
  const [error, setError] = useState(null)
  const [errorc, setErrorc] = useState(0)
  const onError = e => {
    if (errorc < 3) {
      setErrorc(errorc + 1)
      return window.location.reload()
    }
    if (isString(e)) {
      setError(e)
    } else {
      setError('Terjadi kesalahan ketika membuka menu!')
    }
  }
  if (!lstMenu.includes(create)) {
    return <Navigate to={'/404'} replace />
  }
  if (error) {
    return (
      <FullErrorView
        showreload={true}
        onClickRelaod={() => {
          window.location.reload()
        }}
        message={error}
      />
    )
  }
  const isValidDeps = !(
    [query.get('kid'), query.get('pid'), query.get('dctid')].findIndex(
      fn => !fn
    ) >= 0
  )
  const handleBackToRN = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage('onBackPress:')
    } else {
      navigate(-1)
    }
  }
  if (['create', 'view'].includes(create) && isValidDeps) {
    return (
      <PatientExamination
        controllers={controllers}
        pathname={pathname}
        navigate={navigate}
        queryParams={query}
        disabled={create === 'view'}
        headerAttr={{
          light: true,
          enable: true,
          title: 'Pemeriksaan Pasien',
          onClick: handleBackToRN,
        }}
      />
    )
  }
  const onCardItemClick = data => {
    if (data?.id && data?.pid) {
      navigate(
        `/prd-tra-pemeriksaan-pasien/create?kid=${data?.id}&pid=${data?.pid}&dctid=${dctid}`,
        {
          // replace: true,
        }
      )
    } else {
      okRef.current.show({message: 'Invalid kunjungan!'})
    }
  }
  return (
    <>
      <SearchVisitorMobile
        controllers={controllers}
        pathname={pathname}
        navigate={navigate}
        queryParams={query}
        onCardItemClick={onCardItemClick}
        dctid={dctid}
        onError={onError}
        headerAttr={{
          light: true,
          enable: isValidDeps ? true : false,
          title: 'Pemeriksaan Pasien',
          onClick: handleBackToRN,
        }}
        footerAttr={{
          enable: !false
        }}
      />
      <AlertOk ref={okRef} />
      <AlertYesNo ref={yesNoRef} />
    </>
  )
}

export default PatientExaminationIndex
