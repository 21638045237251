import {Fragment, useState, useRef, useLayoutEffect} from 'react'
import {VmView, VmText, VmButton, VmIcons, VmSpinner} from 'components'
import classNames from 'classnames'
import './PatientInformation.scss'

function PatientInformation({
  pathname = '',
  controllers = () => {},
  navigate = () => {},
  getMedicalRecords = () => {},
  getDetailMedicalRecord = () => {},
  id = null,
  datamedicalrecord = null,
  currentrm = null,
  loadingrm = null,
  data = {},
  avatar = 'VmLAnakColorIcon',
  mode,
}) {
  // console.log('PatientInformation', data)
  const [layout, setLayout] = useState({
    height: 0,
    left: 0,
    top: 0,
    width: 0,
    currentHeight: 0,
  })
  const patientInfoParent = document.getElementById('patient-info')
  const patientInfo = patientInfoParent?.children[0]
  window.onscroll = function () {
    if (mode === 'web') {
      // kalau mode web, matiikan fungst sticky-nya
      return
    }
    if (!!data) {
      scrollFunction()
    }
  }
  function scrollFunction() {
    const [scrollTopA, scrollTopB] = [
      document.body.scrollTop,
      document.documentElement.scrollTop,
    ]
    const dataTitle = patientInfo.children[0].children[0]
    const dataFoto = patientInfo.children[0].children[1].children[0]
    const dataInfo = patientInfo.children[0].children[1].children[1]
    const newchildrm =
      dataInfo.children[0].children[0].childNodes[0].textContent
    const newchildname =
      dataInfo.children[1].children[0].childNodes[0].textContent
    var patrm = dataInfo.children[0]
    var patname = dataInfo.children[1]
    var daterm = dataInfo.children[2]
    var norm = dataInfo.children[3]
    var bornrm = dataInfo.children[4]
    if (scrollTopA > 65 || scrollTopB > 65) {
    } else {
      // patientInfo.children[1].removeAttribute(patientInfo.children[1].ariaLabel)
      dataFoto.removeAttribute('style')
      // patientInfo.children[1].replaceChildren('Lihat Rekam Medis')
      patientInfo.children[1].removeAttribute('style')
      patientInfo.removeAttribute('style')
    }
    if (scrollTopA > 110 || scrollTopB > 110) {
      // Box Shadow
      patientInfoParent.style.position = 'fixed'
      patientInfoParent.style.marginTop = '3.5rem'
      patientInfo.style.boxShadow = '0px 15px 23px 1px rgba(0,0,0,0.10)'
      // -webkit-box-shadow: 0px 15px 23px 1px rgba(0,0,0,0.36);
      // -moz-box-shadow: 0px 15px 23px 1px rgba(0,0,0,0.36);
      patientInfo.children[0].style.width = '-webkit-fill-available'
      patientInfo.children[1].style.paddingTop = '0.5rem'
      // Info icon
      dataFoto.style.marginBlock = '0.5rem'
      dataFoto.style.width = 'auto'
      setLayout({
        ...layout,
        rootHeight: patientInfo?.clientHeight ?? 0,
      })
      // Header
      dataTitle.style.lineHeight = '0px'
      dataTitle.style.fontSize = '0px'
      dataTitle.style.borderBottomWidth = '0px'
      // Date
      daterm.style.lineHeight = '0px'
      daterm.style.fontSize = '0px'
      daterm.children[0].style.height = '0px'
      daterm.children[0].style.width = '0px'
      // No
      norm.style.lineHeight = '0px'
      norm.style.fontSize = '0px'
      norm.children[0].style.height = '0px'
      norm.children[0].style.width = '0px'
      // No.RM
      if (!newchildrm.includes(newchildname)) {
        dataInfo.children[0].children[0].replaceChildren(
          newchildrm + ' / ' + newchildname
        )
      }
      // Name
      patname.style.lineHeight = '0px'
      patname.style.fontSize = '0px'
      patname.children[0].style.height = '0px'
      patname.children[0].style.width = '0px'
      // Born
      bornrm.style.lineHeight = '0px'
      bornrm.style.fontSize = '0px'
      bornrm.children[0].style.height = '0px'
      bornrm.children[0].style.width = '0px'
      if (scrollTopA > 111 || scrollTopB > 111) {
        // Header
        dataTitle.style.display = 'none'
        // No
        norm.style.display = 'none'
        // Date
        daterm.style.display = 'none'
        // Name
        patname.style.display = 'none'
        // Born
        bornrm.style.display = 'none'
      } else {
        // Header
        dataTitle.style.display = 'unset'
        // Date
        daterm.style.display = 'unset'
        // No
        norm.style.display = 'unset'
        // Name
        patname.style.display = 'unset'
        // Born
        bornrm.style.display = 'unset'
      }
    } else {
      patientInfoParent.style.position = null
      patientInfoParent.style.marginTop = null
      setLayout({
        ...layout,
        rootHeight: patientInfo?.clientHeight ?? 0,
      })
      dataTitle.removeAttribute('style')
      daterm.removeAttribute('style')
      daterm.children[0].removeAttribute('style')
      norm.removeAttribute('style')
      norm.children[0].removeAttribute('style')
      bornrm.removeAttribute('style')
      bornrm.children[0].removeAttribute('style')
      // Name
      patname.style.display = null
      patname.style.lineHeight = null
      patname.style.fontSize = null
      patname.children[0].style.height = null
      patname.children[0].style.width = null
      // No.RM
      if (!!newchildrm.includes(newchildname)) {
        patrm.children[0].replaceChildren(
          newchildrm.replace(' / ' + newchildname, '')
        )
      }
    }
  }
  const refView = useRef(null)

  useLayoutEffect(() => {
    setLayout({
      height: refView.current?.clientHeight ?? 0,
      left: refView.current?.clientLeft ?? 0,
      top: refView.current?.clientTop ?? 0,
      width: refView.current?.clientWidth ?? 0,
      rootHeight: patientInfo?.clientHeight ?? 0,
      currentHeight:
        patientInfo?.clientHeight > layout?.currentHeight
          ? patientInfo?.clientHeight
          : layout?.currentHeight,
    })
  }, [refView, patientInfo?.clientHeight, layout.currentHeight])

  return (
    <Fragment>
      <VmView
        style={{
          height:
            patientInfoParent?.style?.position === 'fixed' ? '18rem' : '3rem',
          padding: '1rem',
        }}
      />
      <VmView id="patient-info">
        {!!data ? (
          <>
            <VmView className="flex flex-col border-blue-500 border-2 rounded-xl overflow-hidden">
              <VmView className="flex flex-col">
                <VmText className="mt-3 mx-4 mb-4 font-bold text-xl text-slate-600 text-center">
                  Informasi Pasien
                </VmText>
                <VmView className="flex flex-row mx-4 items-center">
                  <VmIcons
                    // className="h-[15rem] md:max-w-md"
                    variant="avatar"
                    name={avatar}
                    size={refView.current?.clientHeight ?? layout.height / 3}
                  />
                  <VmView
                    ref={ref => (refView.current = ref)}
                    className="flex flex-col mx-4 mr-auto"
                  >
                    {data.map(({title, icon = null}, index) => {
                      const color = '#585F7C'
                      return (
                        <VmView
                          key={`${title}-${index}`}
                          className={classNames(
                            'flex flex-row text-base items-center whitespace-nowrap'
                          )}
                        >
                          {icon && (
                            <VmIcons
                              className="p-1"
                              variant="outline"
                              color={color}
                              name={icon}
                              size={26}
                            />
                          )}
                          <VmText
                            className={classNames(
                              'overflow-hidden text-ellipsis inline-block whitespace-nowrap',
                              index === 0 ? 'font-bold' : ''
                            )}
                            style={{color}}
                          >
                            {title}
                          </VmText>
                        </VmView>
                      )
                    })}
                  </VmView>
                </VmView>
              </VmView>
              {datamedicalrecord?.length > 0 ? (
                <VmView className="flex flex-row p-4 !bg-transparent overflow-y-scroll whitespace-nowrap">
                  {currentrm?.id && (
                    <VmButton
                      key={`Sekarang${currentrm?.id}`}
                      variant="outline"
                      rounded="full"
                      // color="success"
                      className="btn flex flex-row items-center mr-2"
                      onClick={() =>
                        getDetailMedicalRecord({
                          id: currentrm.id,
                        })
                      }
                      {...{
                        active: currentrm.id === id || currentrm.id === 'new',
                      }}
                    >
                      Sekarang
                    </VmButton>
                  )}
                  {datamedicalrecord.map(im => {
                    if (im.title === 'Sekarang') {
                      return
                    }
                    return (
                      <VmButton
                        key={im.id}
                        variant="outline"
                        rounded="full"
                        className="btn flex flex-row items-center mr-2"
                        onClick={() => getDetailMedicalRecord({id: im.id})}
                        {...{active: id ? im.id === id : im.id === 'new'}}
                      >
                        {im.title}
                      </VmButton>
                    )
                  })}
                </VmView>
              ) : (
                <VmView className="p-4 flex flex-row">
                  {loadingrm ? (
                    <VmView className="flex justify-center w-full border py-2 rounded-full border-blue-500 items-center">
                      <VmSpinner size="sm" color="blue-500" />
                    </VmView>
                  ) : (
                    <VmButton
                      variant="outline"
                      rounded="xl"
                      className="btn btn-info w-full font-bold"
                      onClick={getMedicalRecords}
                    >
                      Lihat Rekam Medis
                    </VmButton>
                  )}
                </VmView>
              )}
            </VmView>
          </>
        ) : (
          <VmView className="flex justify-center w-full border py-2 rounded-xl border-blue-500 items-center">
            <VmSpinner size="sm" color="blue-500" />
          </VmView>
        )}
      </VmView>
    </Fragment>
  )
}

PatientInformation.propTypes = {}

export default PatientInformation
