import React, {Component} from 'react'
import VmHeader from 'components/layout/VmHeader'
import {VmFab, VmList, VmPicker} from 'components'
import {toCurrency} from 'utils/functions'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {Navigate, useSearchParams} from 'react-router-dom'
import {VmCheckIcon, VmTrashIcon} from 'components/icons/solid'
import {API, graphqlOperation} from 'aws-amplify'
import {
  mAktifkanBiayaPemeriksaan,
  mDeleteBiayaPemeriksaan,
} from 'graphql/mutations'
const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

class PrdBiayaPemeriksaan extends Component {
  fields = [
    // {key: 'index', _style: {fontWeight: 'bold'}, className: 'text-zinc-600'},
    {
      key: 'bianama',
      _style: {fontWeight: 'bold'},
      className: 'text-zinc-600 mt-6',
    },
    {
      key: 'biatarif',
      format: val => {
        return toCurrency(val)
      },
      className: 'text-blue-500',
      type: 'icon',
      iconConfig: {
        name: 'tag',
        width: 20,
        height: 20,
        className: 'text-blue-500',
      },
    },
    {
      key: 'biadiskon',
      format: val => {
        return toCurrency(val, 2)
      },
      className: 'text-red-500 font-bold',
      type: 'icon',
      iconConfig: {
        name: 'receiptpercent',
        width: 20,
        height: 20,
        className: 'text-red-500',
      },
      containerClassName: 'pb-2 border-blue-500 border-b-2',
    },
  ]

  fieldsDesktop = [
    // {key: 'index', _style: {fontWeight: 'bold'}, className: 'text-zinc-600'},
    {
      key: 'biaicdkode',
      label: 'Kode ICD',
      _style: {fontWeight: 'bold'},
      className: 'text-zinc-600 mt-6',
    },
    {
      key: 'biakodebpjs',
      label: 'Kode BPJS',
      _style: {fontWeight: 'bold'},
      className: 'text-zinc-600 mt-6',
    },
    {
      key: 'bianama',
      label: 'Nama',
      _style: {fontWeight: 'bold'},
      className: 'text-zinc-600 mt-6',
    },
    {
      key: 'biatarif',
      label: 'Tarif',
      format: val => {
        return toCurrency(val)
      },
      className: 'text-blue-500',
      type: 'icon',
      filter: false,
      align: 'right',
    },
    {
      key: 'biaaktif',
      type: 'map',
      mapValue: {1: 'Aktif', 2: 'Non Aktif'},
      label: 'Status',
      sorter: false,
      filterType: 'select',
      filterValue: [
        {value: 1, text: 'Aktif'},
        {value: 2, text: 'Non Aktif'},
      ],
    },
  ]

  constructor(props) {
    super(props)

    this.state = {
      ubahId: 0,
      tambah: false,
      ubah: false,
      limit: 10, // Limit data per page
      data: [],
      itemsCount: 0,
      fetchingMore: false,
      gettingInitialData: true,
      hasMoreItems: true,
      device: 'mobile', // Bisa Web (Tabel) / Mobile (List)
      cari: '', // Value pencarian user
      biaaktif: 1, // Filter flag status
      uid: props.searchParams.get('dctid'),

      // Filter untuk pencarian web
      sort: '',
      activePage: 1,
      bianama: '',
      biaicdkode: '',
      biakodebpjs: '',

      custom: '',
    }
    // console.log('constructor params', props.something)
  }

  componentDidMount() {
    // console.log('cdm', this.props.params)
    // Detect device
    this.detectDevice()
  }

  fetchCustom = async () => {
    try {
      const {data} = await axios.post(
        BASE_URL + '/prd-lap-kunjungan-pasien/custom-bpjs',
        QueryString.stringify({uid: this.state.uid})
      )
      return data
    } catch (e) {}
  }

  detectDevice = () => {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    this.setState({device: isMobile ? 'mobile' : 'web'}, this.getInitialData)
  }

  getInitialData = () => {
    const {bianama, biaicdkode, biakodebpjs, sort} = this.state
    // console.log({BASE_URL})
    let limit = 10
    this.setState({gettingInitialData: true})
    this.fetchCustom()
      .then(val => this.setState({custom: val}))
      .catch(() => {})

    let params = {
      a: 'dk13',
      uid: this.state.uid,
      biaaktif: this.state.biaaktif,
      device: this.state.device,
      limit,
    }
    if (this.state.device == 'web') {
      let offset = (this.state.activePage - 1) * limit
      params = {...params, offset, bianama, biaicdkode, biakodebpjs, sorting: sort}
    } else {
      params = {...params, cari: this.state.cari, offset: 0}
    }
    axios
      .post(`${BASE_URL}/biaya-v2/index`, QueryString.stringify(params))
      .then(({data: res}) => {
        // console.log('Data Perawatan / Tindakan', res)
        this.setState({data: res})

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Perawatan / Tindakan', err.message)
      })
      .finally(_ => {
        this.setState({gettingInitialData: false})
      })

    axios
      .post(
        `${BASE_URL}/biaya-v2/index-count-v2`,
        QueryString.stringify(params)
      )
      .then(({data: res}) => {
        // console.log('Data Perawatan / Tindakan', res)
        this.setState({itemsCount: res.total})
      })
      .catch(err => {
        console.log('Error get Data Perawatan / Tindakan count', err.message)
      })
  }

  refreshAfterActivateOrDelete = () => {
    if (this.state.device == 'web') {
      this.setState({activePage: 1}, this.getInitialData)
    } else {
      this.getInitialData()
    }
  }

  aktifkan = item => {
    API.graphql(
      graphqlOperation(mAktifkanBiayaPemeriksaan, {
        input: {
          req: JSON.stringify({
            reg: 'dbdok',
            a: 'dk13',
            data: {
              biaid: item.biaid,
            },
            uid: this.state.uid,
          }),
        },
      })
    )
      .then(res => {
        console.log('Berhasil aktifkan Perawatan / Tindakan', res)
        this.refreshAfterActivateOrDelete()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.aktifkan(item)
        } else {
          console.log('Error mengaktifkan Perawatan / Tindakan', errMsg)
        }
      })
  }

  hapus = item => {
    API.graphql(
      graphqlOperation(mDeleteBiayaPemeriksaan, {
        input: {
          req: JSON.stringify({
            reg: 'dbdok',
            a: 'dk13',
            data: {
              biaid: item.biaid,
            },
            uid: this.state.uid,
          }),
        },
      })
    )
      .then(res => {
        console.log('Hapus Data Perawatan / Tindakan', res)
        this.refreshAfterActivateOrDelete()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.hapus(item)
        } else {
          this.alert.show({
            message:
              'Terjadi kesalahan saat menghapus data Perawatan / Tindakan. [BP-01]',
          })
          console.log('Error menghapus Perawatan / Tindakan', errMsg)
        }
      })
  }

  fetchMore = () => {
    const {data, cari} = this.state
    // console.log({data})
    this.setState({fetchingMore: true})
    axios
      .post(
        `${BASE_URL}/biaya-v2/index`,
        QueryString.stringify({
          a: 'dk13',
          uid: this.state.uid,
          cari,
          biaaktif: this.state.biaaktif,
          offset: data.length,
        })
      )
      .then(({data: res}) => {
        console.log('Fetchmore Data Perawatan / Tindakan', res)
        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
        this.setState({data: [...data, ...res]})
      })
      .catch(err => {
        console.log('Error get Data Perawatan / Tindakan', err.message)
      })
      .finally(() => {
        this.setState({fetchingMore: false})
      })
  }

  cari = () => {
    const {cari} = this.state
    axios
      .post(
        `${BASE_URL}/biaya-v2/index`,
        QueryString.stringify({
          a: 'dk13',
          uid: this.state.uid,
          cari,
        })
      )
      .then(({data: res}) => {
        console.log('Cari Data Perawatan / Tindakan', res)
        this.setState({data: res})

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Perawatan / Tindakan', err.message)
      })
  }

  render() {
    const {
      data,
      itemsCount,
      hasMoreItems,
      device,
      tambah,
      ubah,
      ubahId,
      biaaktif,
      uid,
      custom,
    } = this.state
    return (
      <>
        <AlertOk ref={ref => (this.alert = ref)} />
        <AlertYesNo ref={ref => (this.prompt = ref)} />

        {/* Component untuk navigasi */}
        {tambah ? (
          <Navigate
            to={`/prd-mas-biaya-pemeriksaan/tambah?dctid=${uid}`}
            replace={true}
            state={{custom}}
          />
        ) : null}

        {/* Component untuk navigasi */}
        {ubah ? (
          <Navigate
            to={`/prd-mas-biaya-pemeriksaan/ubah/${ubahId}?dctid=${uid}`}
            replace={true}
            state={{custom}}
          />
        ) : null}

        <VmIndexRead
          headerTitle={'Data Perawatan / Tindakan'}
          items={data}
          itemsCount={itemsCount}
          fields={device == 'mobile' ? this.fields : this.fieldsDesktop}
          listItemContainerClassName={'p-4 border shadow-lg m-4 rounded'}
          listSearchbar={{placeholder: 'Cari Nama Perawatan / Tindakan'}}
          // listSearchbarLeft={<p>Test halo</p>}
          listSearchbarRight={
            <VmPicker
              style={{
                // flex: 0.2,
                background:
                  'url(https://cdn-icons-png.flaticon.com/512/32/32195.png) var(--sizeIco) no-repeat #FFF',
                paddingRight: '4px !important',
                paddingLeft: 4,
              }}
              items={[
                {value: 1, text: 'Aktif'},
                {value: 2, text: 'Tidak Aktif'},
              ]}
              onChange={e => {
                let val = e.target.value
                this.setState(
                  {biaaktif: val, hasMoreItems: true},
                  this.getInitialData
                )
                // console.log('onChange gan', val)
              }}
              placeholder={null}
              value={biaaktif}
              className="rounded-xl text-sm mb-2 py-2"
            />
          }
          listSearchbarClassname="" // Class Untuk inputan filter search bar
          onItemPress={item => {}}
          editButton={item => {
            // Menampilkan button hanya untuk data yang biaaktifnya == 1
            return item.biaaktif == 1
          }}
          deleteButton={item => {
            // Menampilkan button hanya untuk data yang biaaktifnya == 1
            return item.biaaktif == 1
          }}
          customButton={item => {
            // Menampilkan button hanya untuk data yang biaaktifnya == 1
            if (item.biaaktif == 1) return
            return (
              <div
                onClick={() => {
                  this.aktifkan(item)
                }}
                className="flex flex-1 items-center justify-center rounded bg-white ml-2 py-1"
              >
                <VmCheckIcon
                  height={`${24}px`}
                  width={`${24}px`}
                  className="text-green-500"
                />
                <p className="text-green-500">Aktifkan</p>
              </div>
            )
          }}
          onEditItemPress={item => {
            this.setState({ubahId: item.biaid, ubah: true})
          }}
          onDeleteItemPress={item => {
            this.prompt.show(
              {message: `Apakah anda yakin ingin menghapus ${item.bianama}?`},
              () => {
                this.hapus(item)
              }
            )
          }}
          // Start List Props
          hasMoreItems={hasMoreItems}
          loadMore={() => {
            // Jika sedang load new data / Get initial data maka ndak perlu panggil fetchMore lagi (Antisipasi data ndobel)
            if (!this.state.fetchingMore && !this.state.gettingInitialData)
              this.fetchMore()
          }}
          // End List Props
          // Start Table Props

          // End Table Props
          onFabPress={() => {
            // console.log('Fab Press')
            // redirect('/prd-mas-biaya-pemeriksaan/tambah')
            this.setState({tambah: true})
          }}
          device={device}
          onSearchSubmit={value => {
            this.setState(
              {cari: value, hasMoreItems: true},
              this.getInitialData
            )
            // this.cari(value)
          }}
          /**
           * Parameter Untuk DataTable (Versi Desktop Web)
           */
          loadingFetchData={this.state.gettingInitialData}
          totalPage={Math.ceil(this.state.itemsCount / 10)}
          activePage={this.state.activePage}
          onActivePageChange={page => {
            console.log('page change gan ', page)
            this.setState({activePage: page}, this.getInitialData)
          }}
          onColumnFilterChange={data => {
            console.log('onColumnFilterChange ', data)

            let prevFilter = {
              biaicdkode: this.state.biaicdkode,
              biakodebpjs: this.state.biakodebpjs,
              bianama: this.state.bianama,
              biaaktif: this.state.biaaktif,
            }
            if (JSON.stringify(data) != JSON.stringify(prevFilter)) {
              // Search hanya jika value filter kolom ada yang berubah
              this.setState({...data, activePage: 1}, this.getInitialData)
            }
          }}
          onSorterValueChange={sortData => {
            this.setState(
              {sort: `${sortData.column} ${sortData.asc ? 'asc' : 'desc'}`},
              this.getInitialData
            )
            console.log('onSorterValueChange', sortData)
          }}
          totalCount={this.state.itemsCount}
          title="Data Perawatan / Tindakan"
          onRefreshPress={() => {
            // Reset filter, sorting & paging
            this.setState(
              {
                // Filter untuk pencarian web
                sort: '',
                activePage: 1,
                bianama: '',
                biaicdkode: '',
                biakodebpjs: '',
                biaaktif: 1,
              },
              this.getInitialData
            )
          }}
          columnFilterValue={{
            biaicdkode: '',
            biakodebpjs: '',
            bianama: '',
            biaaktif: 1,
          }}
          sorterValue={{column: 'bianama', asc: true}}
          statusColName="biaaktif"
          onActivate={item => this.aktifkan(item)}
        />
      </>
    )
  }
}

function withParams(Component) {
  return props => {
    const [searchParams, setSearchParams] = useSearchParams()
    // console.log('withParams', searchParams)
    return <Component {...props} searchParams={searchParams} />
  }
}

function withSomething(Component) {
  return props => <Component {...props} something="something" />
}

export default withSomething(withParams(PrdBiayaPemeriksaan))
