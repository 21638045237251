import {useNavigate, useParams} from 'react-router-dom'
import {VmButton, VmView} from 'components'
import {useEffect, useState} from 'react'
import {FullErrorView, ProgressFullScreen, VmHeader} from 'molecules'
import textToBase64Barcode from 'utils/text-to-barcode'
import {mCetakBarcode} from './api'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import moment from 'moment'

export default function CetakBarcode() {
  const title = 'Resep Dokter Online'
  const params = useParams(),
    navigate = useNavigate(),
    showlog = !false,
    datastatics = {
      subtitle: 'Silakan Datang ke Apotek/Klinik',
      footer: ['Supported By VMEDIS', 'Aplikasi Apotek & Klinik Terbaik'],
      info: [
        `Simpan Bukti ${title} ini dengan baik.`,
        'Tunjukan Kode Barcode kepada Kasir Di Apotek/Klinik untuk menebus Resep.',
        `Jika Bukti ${title} ini Hilang, Anda masih bisa memberikan informasi Nama Anda, No. WA Anda atau Nama Dokter kepada Kasir Di Apotek/Klinik untuk menebus Resep.`,
        'Dicetak pada ' + moment().format('DD MMM YY HH:mm:ss'),
      ],
    }
  const [dctid, id] = params.id.split('-')
  const [data, setdata] = useState(null)
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState(false)
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const closeAlert = () => setpropsalertform(defaultpropsalertform)

  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onFetchBarcode = (count = 0) => {
    var error = false,
      errorMsg = ''
    setloading(true)
    return mCetakBarcode({input: {pjid: id}})
      .then(({data: resdata = {}}) => {
        if (resdata?.pjid === id) {
          setdata(resdata)
        } else {
          if (count > 10) {
            return setpropsalertform({
              ...propsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  Gagal mengambil informasi user!
                  <br />
                  Silakan hubungi <b>Admin</b> jika user anda masih terkendala.
                </>
              ),
              onConfirm: goBack,
              onClose: goBack,
              onCancel: goBack,
            })
          }
          setpropsalertform({
            ...propsalertform,
            type: 3,
            visible: true,
            children: (
              <>
                Gagal mengambil informasi user!
                <br />
                Apakah anda ingin mengulangi proses?
              </>
            ),
            onConfirm: () => onFetchBarcode(count + 1),
            onClose: closeAlert,
            onCancel: goBack,
          })
        }
        if (showlog) console.log('onCreateNewResep:data', resdata)
      })
      .catch(ch => {
        error = true
        errorMsg = '[C0] Gagal mengambil data.'
        if (showlog) console.log('onCreateNewResep:ch', ch)
      })
      .finally(() => {
        setloading(false)
        if (error) seterror(errorMsg)
        if (showlog) console.log('onCreateNewResep:final')
      })
  }
  const onPrint = () => {
    window.print()
    var col3 = [2, 29],
      col2 = [10, 21],
      col1 = [31],
      doubleline = '=============================',
      singleline = '-----------------------------',
      blankrow1 = ' \n ',
      blankrow2 = ' \r\n '

    const b64barcode = textToBase64Barcode(data.pjnofaktur, {
      height: 50,
    })
    const newdataprintmob = [
      /**
       * ===========================================
       * = [Type, [Width],[Align],[Text],{option}] =
       * = Type @string                            =
       * = Width @integer                          =
       * = Align LEFT:0 CENTER:1 RIGHT:2           =
       * = Text @string                            =
       * ===========================================
       */
      // Header
      ['printColumn', col1, [1], [title], {}],
      ['printColumn', col1, [1], [singleline], {}],
      ['printColumn', col1, [0], [datastatics.subtitle], {}],
      [
        'printColumn',
        col2,
        [0, 0],
        ['Nama', data?.namaapt ? `: ${data.namaapt}` : '-'],
        {},
      ],
      [
        'printColumn',
        col2,
        [0, 0],
        [
          'No. WA',
          data?.kl_tlp_penanggung ? `: ${data.kl_tlp_penanggung}` : '-',
        ],
        {},
      ],
      [
        'printColumn',
        col2,
        [0, 0],
        ['Alamat', data?.alamatapt ? `: ${data.alamatapt}` : '-'],
        {},
      ],
      ['printColumn', col1, [1], [doubleline], {}],
      // Barcode
      [
        'printPic',
        b64barcode.includes(';base64,')
          ? b64barcode.split(';base64,')[1]
          : b64barcode,
        {},
      ],
      ['printColumn', col1, [1], [doubleline], {}],
      // Struk Info
      ...datastatics.info.map(im => [
        'printColumn',
        col3,
        [0, 0],
        ['-', im],
        {},
      ]),
      // Footer
      ['printColumn', col1, [1], [singleline], {}],
      ['printColumn', col1, [1], [' '], {}],
      ...datastatics.footer.map(im => ['printColumn', col1, [1], [im], {}]),
      ['printColumn', col1, [1], [blankrow2], {}],
    ]
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        'screenCetak:' + JSON.stringify(newdataprintmob)
      )
    }
  }
  useEffect(() => {
    onFetchBarcode()
    return () => {}
  }, [])

  if (error || !dctid || !id) {
    return (
      <FullErrorView message={!dctid || !id ? '[0] Url tidak valid!' : error} />
    )
  }
  if (loading) {
    return <ProgressFullScreen visible={true} />
  }

  return (
    <>
      {window?.ReactNativeWebView && (
        <VmHeader
          className="!notprint p-5 bg-blue4-payment"
          classNameTitle="!notprint"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Pembuatan Resep'}
          onBackPress={goBack}
        />
      )}
      <VmView className="RootBodyPrint">
        <VmView className="CetakPembayaran text-xs">
          {/* Header */}
          <table
            width="100%"
            align="center"
            style={{
              textAlign: 'center',
              fontSize: '100%',
              fontWeight: 'bold',
              paddingBottom: '3px',
            }}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td style={{fontWeight: 'bold'}}>{title}</td>
              </tr>
            </tbody>
          </table>
          <hr style={{marginTop: 1, color: '#000000', marginBottom: 1}} />
          <br style={{marginTop: 1, color: '#000000', marginBottom: 5}} />
          <table
            width="100%"
            style={{fontSize: '100%', fontWeight: 'bold', marginTop: '0px'}}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td width="100%" align="left" valign="top">
                  {datastatics.subtitle}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            width="100%"
            style={{fontSize: '100%', fontWeight: 'bold', paddingTop: '5px'}}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td width="22%" align="left" valign="top">
                  Nama
                </td>
                <td width="2%" align="left" valign="top">
                  :
                </td>
                <td style={{paddingLeft: '5px'}}>{data?.namaapt || ''}</td>
              </tr>
              <tr>
                <td width="22%" align="left" valign="top">
                  No. WA
                </td>
                <td width="2%" align="left" valign="top">
                  :
                </td>
                <td style={{paddingLeft: '5px'}} align="left" valign="top">
                  {data?.kl_tlp_penanggung || ''}
                </td>
              </tr>
              <tr>
                <td width="22%" align="left" valign="top">
                  Alamat
                </td>
                <td width="2%" align="left" valign="top">
                  :
                </td>
                <td style={{paddingLeft: '5px'}} align="left" valign="top">
                  {data?.alamatapt || ''}
                </td>
              </tr>
            </tbody>
          </table>
          <hr style={{marginTop: 5, color: '#000000', marginBottom: 1}} />
          <hr style={{marginTop: 1, color: '#000000', marginBottom: 5}} />
          {/* Barcode */}
          <table
            width="100%"
            style={{
              fontSize: '100%',
              fontWeight: 'bold',
              marginBottom: '20px',
              marginTop: '20px',
            }}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td width="100%">
                  {data?.pjnofaktur && (
                    <img
                      src={textToBase64Barcode(data.pjnofaktur, {
                        height: 50,
                      })}
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <hr style={{marginTop: 5, color: '#000000', marginBottom: 1}} />
          <hr style={{marginTop: 1, color: '#000000', marginBottom: 5}} />
          {/* Info */}
          <table
            width="100%"
            style={{fontSize: '100%', fontWeight: 'bold', marginBottom: '5px'}}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              {datastatics.info.map((im, i) => {
                return (
                  <tr key={`${i}-${im}`}>
                    <td width="1%" align="left" valign="top">
                      -&nbsp;
                    </td>
                    <td style={{verticalAlign: 'top'}}>{im}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          <hr style={{marginTop: 1, color: '#000000', marginBottom: 1}} />
          {/* Footer */}
          <table
            width="100%"
            style={{fontSize: '100%', fontWeight: 'bold', paddingTop: '8px'}}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              {datastatics.footer.map((im, i) => {
                return (
                  <tr key={`${i}-${im}`}>
                    <td
                      width="100%"
                      style={{textAlign: 'center', paddingTop: '2px'}}
                    >
                      {im}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </VmView>
        <VmView className="notprint fixed text-right bottom-5 left-0 right-5">
          <VmButton
            color="danger"
            className="mr-4"
            onClick={() => navigate(-1)}
          >
            Kembali
          </VmButton>
          <VmButton onClick={onPrint}>Cetak</VmButton>
        </VmView>
      </VmView>
      <VmView className="!notprint">
        {/* AlertForm */}
        <AlertForm {...propsalertform} />
      </VmView>
    </>
  )
}
