import moment from 'moment'
import 'moment/locale/en-sg'

class PcareDataEntriPen {
  constructor(props = {}) {
    var birthdate = moment(props?.peserta?.tglLahir)
    var today = moment()
    var age = today.diff(birthdate, 'years')

    const lstjk = {L: 'Laki-laki', P: 'Perempuan'}
    // No Urut
    this.noUrut = props?.noUrut || ''
    // Tanggal Daftar
    this.tglDaftar = props?.tglDaftar
      ? moment(
          `${props?.tglDaftar}`.trim().split(/\-/).reverse().join('-')
        ).format('DD MMM YYYY')
      : ''
    // Nama Peserta
    this.nama = props?.peserta?.nama || ''
    // No Kartu
    this.noKartu = props?.peserta?.noKartu || ''
    // Jenis Kelamin
    this.jk = props?.peserta?.sex
      ? lstjk[`${props?.peserta?.sex}`.toUpperCase()] || props?.peserta?.sex
      : ''
    // status
    this.status = props?.status || ''
    this.usia = props?.peserta?.tglLahir ? age || '' : ''
    // polikegiatan
    this.polikegiatan = props?.poli?.nmPoli || ''
    // nourut
    this.noUrut = props?.noUrut || ''
    // kdpoli
    this.kdPoli = props?.poli?.kdPoli || ''

    this.defaultdata = props || {}
  }
}
export {PcareDataEntriPen}
