import React from 'react'
import {oneOfType, oneOf, array, object, string, bool, func} from 'prop-types'
import classNames from 'classnames'
import {tagPropType} from 'utils'
import rounded from 'config/rounded'
import VmLink from 'components/VmLink'

export const hidering = '!ring-0 !ring-transparent !ring-offset-0'
const btncolors = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'dark',
  'light',
]
var VmButton = {
  propTypes: {
    tag: tagPropType,
    className: oneOfType([string, array, object]),
    innerRef: oneOfType([object, func]),
    active: bool,
    full: bool,
    rounded: oneOf(Object.keys(rounded)),
    /**
     * one Of ['', 'ghost', 'outline']
     */
    variant: oneOf(['', 'ghost', 'outline']),
    color: string,
    disabled: bool,
    onClick: func,
    size: string,
    hidering: bool,
  },
}

VmButton = React.forwardRef(
  (
    {
      className,
      onClick,
      disabled,
      active,
      full,
      color = 'primary',
      size,
      rounded: propsrounded = 'xl',
      variant,
      children = 'Button',
      ...attributes
    },
    innerRef
  ) => {
    const click = e => !disabled && onClick && onClick(e)
    const isLink = attributes.to || attributes.href
    const classes = classNames(
      'btn',
      !!attributes?.hidering ? hidering : '',
      className,
      active ? 'active' : false,
      variant || color
        ? btncolors.includes(color)
          ? `${color}${variant ? '-' + variant : ''}`
          : `bg-[${color}]`
        : false,
      size ? `${size}` : false,
      full ? 'w-full' : false,
      propsrounded ? rounded[`${propsrounded}`] : false,
      disabled && !isLink ? 'opacity-25' : false,
      // 'PoppinsRegular'
    )
    if (!!attributes?.hidering) {
      delete attributes.hidering
    }
    if (isLink) {
      return (
        <VmLink
          {...attributes}
          active={active}
          disabled={disabled}
          className={classes}
          onClick={click}
          innerRef={innerRef}
          children={children}
        />
      )
    } else {
      return React.createElement(
        'button',
        Object.assign(
          attributes,
          attributes?.label ? {'aria-label': attributes.label} : {},
          {
            className: classes,
            type: 'button',
            disabled: disabled,
            onClick: click,
            ref: innerRef,
            children: children,
          }
        )
      )
    }
  }
)

export default VmButton
