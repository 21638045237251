import {VmIcons, VmText, VmView} from 'components'
import ErrorLayout from 'components/layout/ErrorLayout'
import {ProgressFullScreen, VmHeader} from 'molecules'

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {fetchViewV2} from './api'
import moment from 'moment'
import {reportDateFormat, reportDateFormatNoTime} from 'config/dateFormat'
import {empty, ifempty} from 'utils/functions'

export default function Detail() {
  const navigate = useNavigate()
  const {id} = useParams()
  const [error, seterror] = useState(null)
  const [data, setdata] = useState({
    'data-pasien': '',
    'data-hubungan': '',
    'data-pj': '',
  })
  const [loading, setloading] = useState(false)

  const handleBack = () => navigate(-1)
  const handleInit = async () => {
    setloading(true)
    const resData = await fetchViewV2({a: 'dk13', reg: 'dbdok', pasid: id})
    setloading(false)
    if (resData.status == 0) {
      return seterror({e: true, ...resData})
    } else {
      seterror(null)
      setdata({...resData?.data})
    }
  }

  useEffect(() => {
    handleInit()
    moment.locale('id')
  }, [])

  if (error?.e)
    return (
      <>
        <ProgressFullScreen visible={loading} />
        <ErrorLayout
          onBackPress={handleBack}
          showreload={true}
          onClickReload={handleInit}
          message={error?.message}
        />
      </>
    )

  return (
    <>
      <VmView className="fixed inset-0 bg-blue4-payment">
        <VmView className="fixed top-4 left-4 right-4 flex flex-col">
          <VmHeader
            className="mb-5"
            HeaderMode="!text-black2-payment"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType="Laporan Pasien Detail"
            onBackPress={handleBack}
          />
        </VmView>

        {/* Section scroll wrapper */}
        <VmView className="absolute inset-0 mt-20 mb-4 mx-4 overflow-y-auto">
          {/* Section card items */}

          {/* Section 1 */}
          <VmView className="p-6 py-8 bg-white rounded-2xl">
            <VmText className="text-black0-payment font-bold mb-6 text-sm">
              Data Pasien
            </VmText>

            <RowData
              label={['Tgl. Registrasi', 'Golongan Darah']}
              data={[
                !!data['data-pasien']?.pastglreg
                  ? moment(data['data-pasien'].pastglreg).format(
                      reportDateFormat
                    )
                  : '-',
                data['data-pasien']?.pasgoldarah,
              ]}
            />
            <RowData
              label={['No. RM', 'Jenis Kelamin']}
              data={[data['data-pasien']?.pasrm, data['data-pasien']?.pasjk]}
            />
            <RowData
              label={['No. BPJS', 'Tgl. Lahir']}
              data={[
                data['data-pasien']?.pasnobpjs,
                !!data['data-pasien'].pastgllahir
                  ? moment(data['data-pasien'].pastgllahir).format(
                      'DD MMM YYYY'
                    )
                  : '-',
              ]}
            />
            <RowData
              label={['No. KK', 'Umur']}
              data={[
                data['data-pasien']?.pasnokk,
                data['data-pasien']?.umur || data['data-pasien']?.umur == 0
                  ? data['data-pasien']?.umur
                  : '-',
              ]}
            />
            <RowData
              label={['NIK', 'Pendidikan']}
              data={[
                data['data-pasien']?.pasnoktp,
                ifempty(data['data-pasien']?.pendid, '-'),
              ]}
            />
            <RowData
              label={['Nama', 'Agama']}
              data={[
                data['data-pasien']?.pasnama,
                ifempty(data['data-pasien']?.agid, '-'),
              ]}
            />
            <RowData
              label={['ID Satu Sehat', 'Pekerjaan']}
              data={[
                data['data-pasien']?.pasid_satusehat,
                data['data-pasien']?.paspekerjaan,
              ]}
            />
            <RowData
              label={['No. WA', 'Email']}
              data={[
                data['data-pasien']?.pastelp,
                data['data-pasien']?.pasemail,
              ]}
            />
            <RowData
              label={['Alamat', 'Alergi Obat']}
              data={[
                data['data-pasien']?.pasalamat,
                data['data-pasien']?.pasalergi,
              ]}
            />

            <VmText className="text-black1-payment text-xs mt-6 font-semibold mb-4">
              Informasi Persetujuan Umum (General Consent)
            </VmText>

            <VmView className="flex flex-row justify-center items-start">
              <CustomCheckbox className="mt-1" />
              <VmText className="text-black1-payment text-xs mb-2 ml-4">
                Saya menjamin bahwa pasien sudah membaca dan menandatangani form
                consent pembukaan data dari SATUSEHAT
              </VmText>
            </VmView>

            <VmView className="flex flex-row justify-center items-start">
              <CustomCheckbox className="mt-1" />
              <VmText className="text-black1-payment text-xs mb-2 ml-4">
                Saya menjamin bahwa pasien menyetujui untuk menerima dan membuka
                data pasien dari fasilitas pelayanan kesehatan lainnya melalui
                SATUSEHAT untuk kepentingan pelayanan kesehatan dan atau rujukan
              </VmText>
            </VmView>

            <VmView className="flex flex-row justify-center items-start">
              <CustomCheckbox className="mt-1" />
              <VmText className="text-black1-payment text-xs mb-2 ml-4">
                Saya menjamin bahwa pasien menyetujui untuk menerima dan membuka
                data pasien dari fasilitas pelayanan kesehatan lainnya melalui
                SATUSEHAT untuk kepentingan pelayanan kesehatan dan atau rujukan
              </VmText>
            </VmView>
          </VmView>

          {/* Section 2 */}
          <VmView className="p-6 py-8 mt-6 bg-white rounded-2xl text-sm">
            <VmText className="text-black0-payment font-bold mb-6">
              Data Penanggung Jawab Pasien
            </VmText>

            <RowData
              label={['NIK', 'Jenis Kelamin']}
              data={[data['data-pj']?.pasnoktp, data['data-pj']?.pasjk]}
            />
            <RowData
              label={['Nama', 'No. Telepon']}
              data={[data['data-pj']?.pasnama, data['data-pj']?.pastelp]}
            />
            <RowData
              label={['Hubungan Keluarga', 'Alamat']}
              data={[
                data['data-pj']?.passtatussambung,
                data['data-pj']?.pasalamat,
              ]}
            />
          </VmView>

          {/* Section 3 */}
          <VmView className="p-6 py-8 mt-6 bg-white rounded-2xl text-sm">
            <VmText className="text-black0-payment font-bold mb-6">
              Hubungan Pasien Lain
            </VmText>

            {/* Section Card Hubungan Pasien */}
            {data['data-hubungan'] && data['data-hubungan'].length > 0 ? (
              data['data-hubungan'].map((item, i) => (
                <HubunganPasienCard key={i} item={item} />
              ))
            ) : (
              <VmText className="text-black1-payment mb-6 mt-12 text-xs text-center">
                Tidak ada data
              </VmText>
            )}
          </VmView>
        </VmView>
      </VmView>
      <ProgressFullScreen visible={loading} />
    </>
  )
}

function RowData({data = [], label = []}) {
  return (
    <VmView className="flex flex-row justify-between">
      <VmView className="basis-1/2 mb-4">
        <VmText className="text-black1-payment font-semibold mb-2 text-xs">
          {label[0]}
        </VmText>
        <VmText className="text-black1-payment text-xs">
          {!!data[0] ? data[0] : '-'}
        </VmText>
      </VmView>

      <VmView className="basis-1/2 mb-4">
        <VmText className="text-black1-payment font-semibold mb-2 text-xs">
          {label[1]}
        </VmText>
        <VmText className="text-black1-payment text-xs">
          {!!data[1] ? data[1] : '-'}
        </VmText>
      </VmView>
    </VmView>
  )
}

function CustomCheckbox({checked = true, onChange = () => {}, className = ''}) {
  return (
    <VmView className={className}>
      <input
        type="checkbox"
        id="gc-checkbox"
        className="w-4 h-4 border-0 focus:ring-0 focus:accent-blue1-payment  accent-blue1-payment"
        checked={checked}
        onChange={onChange}
      />
    </VmView>
  )
}

function HubunganPasienCard({item}) {
  return (
    <VmView className="border-color-gray0-payment border rounded-2xl mb-6 pt-6 px-6 pb-4">
      <VmView className="flex flex-row items-center">
        {/* Section Left */}
        <VmView className="flex flex-col mr-4 items-center justify-center">
          <VmIcons name="VmUserIcon" size={70} className="text-blue1-payment" />
          <VmText className="text-black1-payment font-semibold text-xs mt-2">
            {!!item?.umur || item?.umur == 0 ? `${item?.umur} Tahun` : '-'}
          </VmText>
        </VmView>

        {/* Section right */}
        <VmView>
          <VmText className="text-black1-payment font-semibold text-xs">
            {item?.pasrm || '-'}
          </VmText>
          <VmText className="text-black1-payment text-xs mb-4">
            {item?.pasnama || '-'}
          </VmText>

          <VmView className="flex flex-row mb-2">
            <VmView>
              <VmIcons
                name="VmPinLocationIcon"
                size={20}
                className="text-black1-payment"
              />
            </VmView>
            <VmText className="text-black1-payment text-xs ml-2">
              {item?.pasalamat || '-'}
            </VmText>
          </VmView>

          <VmView className="flex flex-row">
            <VmIcons
              name="VmPhoneIcon"
              size={20}
              className="text-black1-payment"
            />
            <VmText className="text-black1-payment text-xs ml-2">
              {item?.pastelp || '-'}
            </VmText>
          </VmView>

          <VmView className="flex w-28 p-1 bg-blue1-payment rounded-lg mt-4 items-center justify-center">
            <VmText className="text-white">
              {item?.passtatussambung || '-'}
            </VmText>
          </VmView>
        </VmView>
      </VmView>
    </VmView>
  )
}
