const defaultpropssimpletoast = {
  /**
   * Success => VmCheckCircleIcon => bg-blue1-payment
   * Fail => VmXCircleIcon => bg-red5-payment
   */
  toastervisible: false,
  toasterbg: 'bg-blue1-payment',
  toastericon: 'VmCheckCircleIcon',
  toastertitle: 'Success',
}
export {defaultpropssimpletoast}
