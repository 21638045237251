import React, {useEffect, useMemo, useRef, useState} from 'react'

import {VmModal, VmText, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import Searchbar from './Searchbar'
import CardItem from './CardItem'
import Footer from './Footer'
import {BottomSheet} from 'react-spring-bottom-sheet'
import useAddEventListener from 'utils/useAddEventListener'
import {useLocation, useNavigate} from 'react-router-dom'
import {defaultpropsbottomsheet} from 'entries/bottomsheet'
import classNames from 'classnames'
import {
  conditionlist,
  etiketlist,
  inputRacikanInf,
  lstAdditionalButtonsConfig,
} from './entries'
import {TemplateListInputItems} from 'template'
import AlertForm from 'template/Form/AlertForm'
import SearchKategori from './SearchKategori'
import SearchObat from './SearchObat'
import ListConditions from './ListConditions'
import ListEtiket from './ListEtiket'
import {useCarts} from './utils'
import {defaultpropsalertform} from 'entries/alertform'
import {mCreateRacikan, mSatuanObat, qObatKode} from './api'
import {removeLclStorage} from 'utils/localstorage'
import {isFunction} from 'utils'

export default function AddRacikan({
  HeaderType = 'Buat Racikan Baru',
  labelBackButton = 'modal-back-button',
}) {
  const modalRef = useRef(),
    fieldkey = ['obatid', 'sodid'],
    navigate = useNavigate(),
    location = useLocation()
  const defaultdata = {
    conditions: conditionlist[0].type,
    conditionsTypeTitle: conditionlist[0].title,
    etiket: etiketlist[0].type,
    etiketTypeTitle: etiketlist[0].title,
    'condition-type': 'Makan',
  }
  const defaultForm = {
    'kategori-name': '',
    'search-obat': '',
    'cart-item-qty': [],
    'obat-name': '',
  }
  const parentParams = {
    a: location.state?.aptkldata?.defaultdata?.app_id,
    reg: location.state?.aptkldata?.defaultdata?.regid_apotek,
    dctid: location.state?.dctid,
  }
  const optcarts = {
    name: 'add-racikan-carts',
    flags: 'flagcart',
    flagstok: 'obatid',
  }
  const {cart, carts, edititemcarts, removetocarts, clearlclcarts, setupdate} =
    useCarts(optcarts)
  const [form, setForm] = useState(defaultForm)
  const [loading, setLoading] = useState(false)
  const [data, setdata] = useState(defaultdata)
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [collapse, setcollapse] = useState(false)
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )
  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const closeBs = () => setpropsbottomsheet(defaultpropsbottomsheet)
  const defaultModalClose = () => modalRef.current?.close()
  const lstinput = useMemo(() => {
    var newlstinput = inputRacikanInf.lstinput
    if (data?.etiket <= 0 || data?.etiket > 5) {
      // Hide Syarat
      newlstinput[newlstinput.length - 1].type = null
    } else {
      // Show Syarat
      newlstinput[newlstinput.length - 1].type = 9
    }
    return newlstinput
  }, [inputRacikanInf, data])
  const onClick = e => {
    try {
      const {ariaLabel} = e?.target
      if (ariaLabel === 'add-resep') onAdd()
      if (ariaLabel === 'remove-input-kategori-name')
        setdata({...data, 'kategori-name': '', katoid: ''})
      if (`${ariaLabel}`?.match(/^showsatuan-k-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        return onFetchSatuan(
          {
            a: parentParams.a,
            id: item?.defaultdata?.obatid,
            reg: parentParams.reg,
          },
          idx
        )
      }
      if (
        !`${ariaLabel}`?.match(/^input-qty-##/i) &&
        !`${ariaLabel}`?.match(/^minus-item-##/i) &&
        !`${ariaLabel}`?.match(/^plus-item-##/i)
      ) {
        if (form['cart-item-qty'].length > 0) {
          form['cart-item-qty'].map(item => {
            const idx = Object.keys(item)[0]
            const newCart = {...cart[idx], qty: parseFloat(item[idx].qty)}
            edititemcarts(newCart, optcarts.flags)
          })
        }
      }
      if (e?.target?.dataset?.rsbsBackdrop === 'true') {
        // onClick backdrop Bottom Sheet
        return setpropsbottomsheet(defaultpropsbottomsheet)
      }
      if (`${ariaLabel}`?.match(/^delete-item-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        const item = cart[idx]
        return setpropsalertform({
          ...propsalertform,
          type: 2,
          title: 'Peringatan',
          visible: true,
          children: (
            <>
              Apakah anda yakin ingin menghapus item ini (<b>{item?.name}</b>)?
            </>
          ),
          onConfirm: () => removetocarts(item),
          onClose: closeAlert,
          onCancel: closeAlert,
        })
      }
      if (`${ariaLabel}`?.match(/^plus-item-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        let item = cart[idx]
        item = {...item, qty: parseFloat(item?.qty) + 1}
        edititemcarts(item, optcarts.flags)
        return
      }
      if (`${ariaLabel}`?.match(/^minus-item-##/i)) {
        const idx = `${ariaLabel}`.split('-##-')[2]
        let item = cart[idx]
        item = {...item, qty: parseFloat(item?.qty) - 1}
        edititemcarts(item, optcarts.flags)
        return
      }
      if (
        ariaLabel === 'search-input-kategori-name' ||
        ariaLabel === 'input-kategori-name'
      ) {
        // Open Modal Pencarian kategori
        const modalPenjualan = (
          <SearchKategori
            labelBackButton="search-input-kategori-name"
            label="kategori-name-search"
            HeaderType={HeaderType}
            close={defaultModalClose}
            onSelect={item =>
              setdata({
                ...data,
                'kategori-name': item.katonama,
                'obat-name': form['obat-name'],
                katoid: item.katoid,
              })
            }
            {...parentParams}
          />
        )
        return modalRef.current.open(modalPenjualan)
      }
      if (ariaLabel === 'btn-search-obat') {
        // Open Modal Pencarian Obat
        const modalPenjualan = (
          <SearchObat
            HeaderType={HeaderType}
            close={defaultModalClose}
            keyword={form['search-obat']}
            fieldkey={fieldkey}
            optcarts={optcarts}
            datamitra={location.state.aptkldata}
            datapasien={location.state.pasiendata}
            cartracikan
          />
        )
        return modalRef.current.open(modalPenjualan)
      }
      if (ariaLabel === 'collapse-hdr') {
        return setcollapse(!collapse)
      }
      if (['input-etiket', 'picker-input-etiket'].includes(ariaLabel)) {
        // Open Bs etiket type
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          onClick: closeBs,
          children: (
            <ListEtiket
              data={data}
              setdata={setdata}
              list={etiketlist}
              closeBs={closeBs}
            />
          ),
        })
      }
      if (['input-conditions', 'picker-input-conditions'].includes(ariaLabel)) {
        // Open Bs conditions type
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          onClick: closeBs,
          children: (
            <ListConditions
              data={data}
              setdata={d => {
                setdata({
                  ...d,
                  'obat-name': form['obat-name'],
                })
              }}
              list={conditionlist}
              closeBs={closeBs}
            />
          ),
        })
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const onChange = e => {
    const {ariaLabel, value} = e.target
    const whiteList = [
      'input-kategori-name',
      'input-kode',
      'input-obat-name',
      'input-search-obat',
    ]

    if (`${ariaLabel}`?.match(/^input-Waktu/i)) {
      const idPicker = ariaLabel.replace('input-Waktu', '')
      setForm({...form, ['obatwaktu' + idPicker]: value})
      data['Waktu' + idPicker] = value
    }
    if (whiteList.includes(ariaLabel))
      setForm({...form, [ariaLabel.replace('input-', '')]: value})
    if (`${ariaLabel}`?.match(/^input-qty-##/i)) {
      const idx = `${ariaLabel}`.split('-##-')[2]
      setForm({
        ...form,
        'cart-item-qty': [...form['cart-item-qty'], {[idx]: {qty: value}}],
      })
      return
    }
  }
  const onKeypress = e => {
    const {ariaLabel} = e?.target
    if (ariaLabel === 'input-search-obat' && e?.keyCode === 13) {
      const modalPenjualan = (
        <SearchObat
          HeaderType={HeaderType}
          close={defaultModalClose}
          keyword={form['search-obat']}
          fieldkey={fieldkey}
          optcarts={optcarts}
          datamitra={location.state.aptkldata}
          datapasien={location.state.pasiendata}
          cartracikan
        />
      )
      return modalRef.current.open(modalPenjualan)
    }
  }
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  const onFetchSatuan = (input = {id: 0, reg: '', a: ''}, flagcart = '') => {
    mSatuanObat({input}).then(({data: resdata = {}}) => {
      if (resdata?.data?.length > 0) {
        const list = resdata?.data
        const children = (
          <VmView className="px-4 pb-2">
            <VmText className="text-center font-bold uppercase pb-4">
              Satuan
            </VmText>
            {list?.length > 0 ? (
              list.map(({sodid: type, sonama: title, ...datasatuan}, index) => {
                const sodid = cart[flagcart]?.defaultdata?.sodid || {}
                const active = type?.toLowerCase() === `${sodid}`?.toLowerCase()
                return (
                  <VmView
                    className={classNames(
                      'border rounded-xl text-center mb-2 py-1 font-semibold',
                      active
                        ? 'bg-blue1-payment text-white'
                        : 'text-blue1-payment bg-white'
                    )}
                    onClick={() => {
                      let newitem = cart[flagcart]
                      newitem = {
                        ...newitem,
                        satuan: title,
                        defaultdata: {
                          ...newitem?.defaultdata,
                          ...datasatuan,
                          sodid: type,
                          sonama: title,
                        },
                        fieldkey,
                      }
                      edititemcarts(newitem, optcarts.flags)
                    }}
                    key={type + index}
                  >
                    {title}
                  </VmView>
                )
              })
            ) : (
              <VmView
                className={classNames(
                  'border rounded-xl text-center mb-2 py-1 font-semibold'
                )}
              >
                List kosong!
              </VmView>
            )}
          </VmView>
        )
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          onClick: closeBs,
          children,
        })
      }
    })
  }
  const onAdd = async () => {
    const newCarts = []
    carts.map(item =>
      newCarts.push({jumlah: item.qty, kodesatuan: item.defaultdata.sodid})
    )
    const params = {
      a: parentParams.a,
      reg: parentParams.reg,
      data: {
        obatkode: data?.kode || '',
        obatnama: form['obat-name'] || '',
        katoid: data?.katoid || '',
        obatetiket: data.etiket || '',
        obatwaktu1: data.etiket > 0 ? data?.Waktu1 || '' : '',
        obatwaktu2: data.etiket > 1 ? data?.Waktu2 || '' : '',
        obatwaktu3: data.etiket > 2 ? data?.Waktu3 || '' : '',
        obatwaktu4: data.etiket > 3 ? data?.Waktu4 || '' : '',
        obatwaktu5: data.etiket > 4 ? data?.Waktu5 || '' : '',
        obatsyarat: data.conditions || '',
      },
      cart: newCarts,
    }

    const onAddAlert = caption => {
      return setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: <>{caption}</>,
        onConfirm: closeAlert,
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    }
    if (!form['obat-name'])
      return onAddAlert('Nama Obat Racikan tidak boleh kosong')
    if (carts.length == 0) return onAddAlert('Anda belum memilih obat')
    setLoading(true)
    const res = await mCreateRacikan({...params})
    setLoading(false)
    if (res.statusCode == 200) {
      setForm(defaultForm)
      setdata(defaultdata)
      fetchKodeObat()
      removeLclStorage(optcarts.name)
      removeLclStorage(`${optcarts.name}-t`)
      onAddAlert('Berhasil menyimpan racikan')
      backToSearchRacikan()
    } else {
      onAddAlert('Gagal menyimpan racikan')
    }
  }
  const fetchKodeObat = async () => {
    const res = await qObatKode({a: parentParams.a, reg: parentParams.reg})
    if (res.statusCode == 200) setdata({...data, kode: res.data.data})
    else setdata({...data, kode: ''})
  }
  useEffect(() => {
    if (!data?.kode) setdata({...data, kode: 'Memuat kode obat...'})
    fetchKodeObat()
  }, [])

  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        backToSearchRacikan()
      })
    } else {
      backToSearchRacikan()
    }
  }
  const backToSearchRacikan = () => {
    navigate('/prd-penjualan-obat-resep/' + parentParams.dctid, {
      state: {modalSearchRacikan: true},
      replace: true,
    })
  }
  const onBackPress = () => {
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      children: 'Apakah anda yakin akan keluar menu ini?',
      onConfirm: goBack,
      onClose: () => setpropsalertform(defaultpropsalertform),
      onCancel: () => setpropsalertform(defaultpropsalertform),
    })
  }
  const onHardwareBackPress = event => {
    const {data} = event
    if (
      `${data}`?.match(/^onHardwareBackPress:/i) &&
      `${location?.pathname}` === '/racikan/create/'+parentParams.dctid
    ) {
      const rbsPortal = document.getElementsByTagName('reach-portal')
      if (rbsPortal[0]?.children[0]?.dataset?.rsbsState === 'open') {
        rbsPortal[0]?.children[0]?.children[0]?.click()
        return
      }
      if (modalRef.current?.isOpen) {
        if (isFunction(modalRef.current?.close)) {
          return modalRef.current.close()
        }
      }
      return onBackPress()
    }
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })

  return (
    <VmView className="fixed inset-0 bg-blue4-payment px-5 pt-5">
      {/* Headers */}
      <VmHeader
        className="mb-5"
        HeaderMode="!text-black2-payment"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType={HeaderType}
        onBackPress={onBackPress}
        labelBackButton={labelBackButton}
      />
      {/* Racikan Information */}
      <VmView
        key={`collapse-hdr-${collapse}`}
        className={classNames(
          'relative flex flex-col bg-blue1-payment rounded-3xl mb-5',
          collapse ? 'py-5' : 'pb-3 pt-5'
        )}
      >
        <VmView className="flex flex-row justify-between px-5 text-sm text-white">
          <VmView className="font-semibold">Informasi Racikan</VmView>
          <VmView
            label={`collapse-hdr`}
            className="flex font-semibold bg-yellow2-payment items-center rounded-full min-w-[30%] justify-center text-xs cursor-pointer"
          >
            {collapse ? 'Buka' : 'Tutup'}
          </VmView>
          <VmView
            label={`collapse-hdr`}
            className="absolute top-0 right-0 w-1/3 h-16"
          />
        </VmView>
        {/* List Informasi Racikan */}
        <TemplateListInputItems
          colors="text-white"
          bgcolors="!bg-white"
          className={classNames(
            collapse ? '!h-0 overflow-hidden z-[-1]' : `px-3`
          )}
          data={data}
          lstinput={lstinput}
          lstAdditionalButtonsConfig={lstAdditionalButtonsConfig}
          key={`lst-inpt-cutmr-${collapse}`}
        />
      </VmView>
      {/* Searchbar */}
      <Searchbar label="search-obat" />
      <VmView className="flex flex-col h-[-webkit-fill-available] overflow-y-scroll">
        {/* Content */}
        <VmView className="relative mb-80">
          {carts.map((im, index) => {
            return (
              <CardItem
                key={`CardItem-${index}`}
                index={index}
                data={im}
                cartracikan
              />
            )
          })}
        </VmView>
      </VmView>

      <Footer
        title="Simpan"
        backButton
        backButtonTitle="Batal"
        backHandler={onBackPress}
      />

      {/* Bottom Sheet */}
      <BottomSheet {...propsbottomsheet} />
      {/* Modal */}
      <VmModal
        className="!inset-0"
        classNameContent="absolute !inset-0 !h-screen max-h-none !rounded-none"
        classNameBody="absolute !inset-0"
        ref={modalRef}
      />
      {/* AlertForm */}
      <AlertForm {...propsalertform} />
      <ProgressFullScreen visible={loading} />
    </VmView>
  )
}
