import {ReportMobileControlled} from 'template'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {aliasFields, dataFields, filterFields} from './fields'
import {toCurrency} from 'utils/functions'

export default function PrdLapPenjualanObatResep() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const uid = searchParams.get('dctid')

  return (
    <ReportMobileControlled
      headerFields={[{name: 'pjtanggal', dateFormat: true}, 'pjnofaktur']}
      dataFields={dataFields}
      additionalApiParams={{uid, reg: 'dbdok'}}
      statusDbColumn="pjdelivery"
      aliasFields={aliasFields}
      apiUrl={
        process.env.REACT_APP_BASE_URL_DOKTER + '/prd-lap-penjualan-resep/index'
      }
      filterFields={filterFields}
      headerTitle="Laporan Resep Dokter"
      searchPlaceholder="Cari Faktur / Resep / Pasien "
      detailAttr={{
        button: true,
        buttonLabel: 'Detail',
        buttonAction: item =>
          navigate(`/prd-lap-penjualan-obat-resep/${item.pjid}/${uid}`),
      }}
      footerLabel="No Resep: "
      footerValue={{name: 'pjresep'}}
      footerDividerVisible={false}
      exportApiUrl={
        process.env.REACT_APP_BASE_URL_DOKTER +
        '/prd-lap-penjualan-resep/export'
      }
      exportApiParams={{uid}}
      countLabel="Total Insentif"
      customCountValue={{getter: val => toCurrency(val, 2)}}
    />
  )
}
