import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'

import {VmCardContainer, VmHeader, VmText, VmView} from 'components'

export default function Index() {
  const {state = ''} = useLocation()
  const navigate = useNavigate()
  return (
    <>
      <VmHeader
        title={state?.title}
        onClick={() => navigate('/kl-lap-kunjungan-pasien')}
        chevron
        light
      />
      <VmView className="pt-16 px-3">
        <VmCardContainer>
          {state.detailFields.map((item, i) => (
            <VmView key={i} className="mb-2">
              <VmText className="font-bold">{item.label}</VmText>
              <VmText>{state?.detailData[item.value]}</VmText>
            </VmView>
          ))}
        </VmCardContainer>
      </VmView>
    </>
  )
}
