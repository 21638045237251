import {APIDOC} from 'config/apiurl'

export const dataFields = ['pasnama', 'pasrm', 'total']

export const customExportAttr = [
  {
    url: APIDOC + '/prd-lap-pasien/export',
    title: 'Export rekap',
  },
  {
    url: APIDOC + '/prd-lap-pasien/export-kategori',
    title: 'Export rekap detail',
  },
]

const classExportAttrWeb = {
  content:
    'bg-white flex flex-row rounded-xl p-4 my-3 cursor-pointer shadow-md hover:scale-105 hover:bg-green-50/90',
  title: 'flex flex-col',
}
export const customExportAttrWeb = [
  {
    url: APIDOC + '/prd-lap-pasien/export-web',
    title: 'Export rekap',
    classNames: classExportAttrWeb,
  },
]

export const customPrintAttrWeb = [
  {
    url: APIDOC + '/prd-lap-pasien/print-web',
    title: 'Cetak rekap',
    desc: 'Fungsi untuk mengekspor data dalam bentuk pdf',
    type: 'PDF',
    color: 'red',
    classNames: classExportAttrWeb,
  },
]

export const pageTitle = 'Laporan Data Pasien'
