import {FullErrorView, ProgressFullScreen} from 'molecules'
import {createRef, useCallback, useEffect, useState, useMemo} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {TemplateForm} from 'template'
import MobPrdRujukanCreate from 'template/PrdRujukan/MobCreate'
import WebPrdRujukanCreate from 'template/PrdRujukan/WebCreate'
import moment from 'moment'
import SearchContent from 'template/Modal/SearchContent'
import FormContent from 'template/Modal/FormContent'
import {prdRujukanCreate, prdRujukanIndex} from './api'
import {VmSuster, VmStetoskop, VmCarbonHospital} from 'components/icons/outline'
import {APIDOC} from 'config/apiurl'
import {VmButton, VmView} from 'components'
import classNames from 'classnames'
import {defaultpropsalertform} from 'entries/alertform'
import useAddEventListener from 'utils/useAddEventListener'
import {isFunction} from 'utils'
import {showSimpleToastApp} from 'utils/simpletoast'
import {RgxMobileDevice} from 'utils/core'

const textclassname = '!text-slate-500 text-sm font-semibold'
const titleclassname = 'font-bold'

const Items = ({data = [], onPick = () => {}, selected = null}) => {
  return data.map((im, idx) => {
    const key = `k-${idx}.${Date.now()}`,
      active = JSON.stringify({im: selected}) === JSON.stringify({im})
    return (
      <VmView
        key={key}
        onClick={() => (active ? onPick() : onPick(im))}
        className={classNames(
          'cursor-pointer',
          active ? 'border-2 border-sky-500 bg-sky-100' : '',
          'p-3 border rounded-2xl'
        )}
      >
        <VmView>{im?.nama || '-'}</VmView>
      </VmView>
    )
  })
}

export default function Create() {
  const pageTitle = 'Rujukan Eksternal',
    navigate = useNavigate(),
    params = useParams(),
    formRef = createRef()
  const id = params?.id
  const rmid = params?.rmid
  const [data, setdata] = useState({
    norm: '',
    born: '',
    patname: '',
    sex: '',
    date: moment().format('YYYY-MM-DD'),
    faskes: '',
    poli: '',
    doctname: '',
    diagnosa: '',
    tindakan: '',
  })
  const [datachange, setdatachange] = useState({...data}),
    [error, seterror] = useState(false),
    [loading, setloading] = useState(true),
    [initialLoading, setInitialLoading] = useState(true),
    [cetak, setcetak] = useState(true),
    [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [device, setDevice] = useState(null)
  const detectDevice = () => {
    let isMobile = RgxMobileDevice.test(navigator.userAgent)
    setDevice(isMobile ? 'mobile' : 'web')
  }

  const loadData = () => {
    var error = false,
      errorMsg = ''
    seterror(false)

    return prdRujukanIndex({input: {uid: id, rmid}})
      .then(({data: res}) => {
        if (res?.status === 1) {
          res['data']['date'] = moment(res['data']?.date).format('YYYY-MM-DD')
          if (res['data']['born']) {
            // res['data']['date'] = moment().format('YYYY-MM-DD')
            res['data']['dateborn'] = res['data']['born']
            res['data']['born'] = moment(res['data']['born']).format(
              'DD MMM YYYY'
            )
          }
          setdata(res.data)
          setdatachange(res.data)
        } else {
          error = true
          errorMsg = res?.message || '[S0] Gagal saat mengambil data!'
        }
      })
      .catch(err => {
        // console.log('err', err)
        error = true
        errorMsg = '[E0] Gagal saat mengambil data!'
      })
      .finally(() => {
        setInitialLoading(false)
        setloading(false)
        if (error) {
          seterror(errorMsg)
        }
      })
  }
  const saveData = input => {
    var error = false,
      errorMsg = ''
    seterror(false)
    setloading(true)
    return prdRujukanCreate({input})
      .then(({data: res}) => {
        if (res?.status === 1) {
          showSimpleToastApp(res?.message || 'Berhasil', 'Success')
          navigate(`/prd-lap-rujukan/${res?.id}`, {replace: true})
        } else {
          error = true
          errorMsg = res?.message || '[S0] Gagal saat menyimpan data!'
        }
      })
      .catch(err => {
        // console.log('err', err)
        error = true
        errorMsg = '[E0] Gagal saat menyimpan data!'
      })
      .finally(() => {
        setloading(false)
        if (error) {
          showSimpleToastApp(errorMsg, 'Fail')
        }
      })
  }
  const onClick = e => {
    const {ariaLabel} = e.target
    var actialfield = '',
      message = '',
      error = false,
      newvalue = null,
      {yesNoRef, okRef, handleModal, modalRef} = formRef.current
    const [type, field] = `${ariaLabel}-`.split('-')
    if (type === 'select') {
      const listmodalprops = {
        faskes: {
          key: Date.now(),
          url: APIDOC + '/prd-rujukan/search-faskes',
          variables: {uid: id, reg: 'dbdok'},
          fieldsearch: 'cari',
          placeholdersearch: 'Cari Faskes',
          title: 'Pilih Faskes',
          selected: datachange?.dfaskes,
          renderItems: props => (
            <Items
              {...props}
              onPick={im => {
                props.onPick(im)
                setdatachange(pref => {
                  return {
                    ...pref,
                    dfaskes: im,
                    faskes: im?.nama || '',
                  }
                })
                if (im) {
                  modalRef.close()
                }
              }}
            />
          ),
          AdditionalComponents: ({refetch = () => null}) => {
            const onClick = () =>
              formRef.current.handleModal(
                <FormContent
                  key={Date.now()}
                  onSuccess={() => {
                    formRef.current.handleModal(
                      <SearchContent
                        {...listmodalprops.faskes}
                        variables={{
                          ...listmodalprops.faskes.variables,
                          offset: 0,
                        }}
                        key={Date.now()}
                      />,
                      'fit'
                    )
                  }}
                  label="select-faskes"
                  url={APIDOC + '/prd-rujukan/add-faskes'}
                  variables={{nama: '', uid: id}}
                  title="Tambah Faskes"
                  btntitle="Tambah Faskes"
                  confirmtitle="Tambah"
                  lstmenu={[
                    {
                      type: 1,
                      required: true,
                      title: 'Nama Faskes',
                      Icon: VmCarbonHospital,
                      label: `input-nama`,
                      field: `nama`,
                      typeInput: 'text',
                      className: titleclassname,
                      classNameInput: textclassname,
                      maxLength: 100,
                    },
                  ]}
                />,
                'fit'
              )
            return (
              <VmView className="flex">
                <VmButton
                  onClick={onClick}
                  rounded="full"
                  label={`create-${field}`}
                >
                  {'Tambah'}
                </VmButton>
              </VmView>
            )
          },
        },
        poli: {
          key: Date.now(),
          url: APIDOC + '/prd-rujukan/search-poli',
          variables: {uid: id, reg: 'dbdok'},
          fieldsearch: 'cari',
          placeholdersearch: 'Cari Poli',
          title: 'Pilih Poli',
          selected: datachange?.dpoli,
          renderItems: props => (
            <Items
              {...props}
              onPick={im => {
                props.onPick(im)
                setdatachange(pref => {
                  return {
                    ...pref,
                    dpoli: im,
                    poli: im?.nama || '',
                  }
                })
                if (im) {
                  modalRef.close()
                }
              }}
            />
          ),
          AdditionalComponents: ({refetch = () => null}) => {
            const onClick = () =>
              formRef.current.handleModal(
                <FormContent
                  key={Date.now()}
                  onSuccess={() => {
                    formRef.current.handleModal(
                      <SearchContent
                        {...listmodalprops.poli}
                        variables={{
                          ...listmodalprops.poli.variables,
                          offset: 0,
                        }}
                        key={Date.now()}
                      />,
                      'fit'
                    )
                  }}
                  label="select-poli"
                  url={APIDOC + '/prd-rujukan/add-poli'}
                  variables={{nama: '', uid: id}}
                  title="Tambah Poli"
                  btntitle="Tambah Poli"
                  confirmtitle="Tambah"
                  lstmenu={[
                    {
                      type: 1,
                      required: true,
                      title: 'Nama Poli',
                      Icon: VmStetoskop,
                      label: `input-nama`,
                      field: `nama`,
                      typeInput: 'text',
                      className: titleclassname,
                      classNameInput: textclassname,
                      maxLength: 100,
                    },
                  ]}
                />,
                'fit'
              )
            return (
              <VmView className="flex">
                <VmButton
                  onClick={onClick}
                  rounded="full"
                  label={`create-${field}`}
                >
                  {'Tambah'}
                </VmButton>
              </VmView>
            )
          },
        },
        doctname: {
          key: Date.now(),
          url: APIDOC + '/prd-rujukan/search-dokter',
          variables: {uid: id, reg: 'dbdok'},
          fieldsearch: 'cari',
          placeholdersearch: 'Cari Dokter',
          title: 'Pilih Dokter',
          selected: datachange?.ddoc,
          renderItems: props => (
            <Items
              {...props}
              onPick={im => {
                props.onPick(im)
                setdatachange(pref => {
                  return {
                    ...pref,
                    ddoc: im,
                    doctname: im?.nama || '',
                  }
                })
                if (im) {
                  modalRef.close()
                }
              }}
            />
          ),
          AdditionalComponents: ({refetch = () => null}) => {
            const onClick = () =>
              formRef.current.handleModal(
                <FormContent
                  key={Date.now()}
                  onSuccess={() => {
                    formRef.current.handleModal(
                      <SearchContent
                        {...listmodalprops.doctname}
                        variables={{
                          ...listmodalprops.doctname.variables,
                          offset: 0,
                        }}
                        key={Date.now()}
                      />,
                      'fit'
                    )
                  }}
                  label="select-doctname"
                  url={APIDOC + '/prd-rujukan/add-dokter'}
                  variables={{nama: '', uid: id}}
                  title="Tambah Dokter"
                  btntitle="Tambah Dokter"
                  confirmtitle="Tambah"
                  lstmenu={[
                    {
                      type: 1,
                      required: true,
                      title: 'Nama Dokter',
                      Icon: VmSuster,
                      label: `input-nama`,
                      field: `nama`,
                      typeInput: 'text',
                      className: titleclassname,
                      classNameInput: textclassname,
                      maxLength: 100,
                    },
                  ]}
                />,
                'fit'
              )
            return (
              <VmView className="flex">
                <VmButton
                  onClick={onClick}
                  rounded="full"
                  label={`create-${field}`}
                >
                  {'Tambah'}
                </VmButton>
              </VmView>
            )
          },
        },
      }
      const propsmodal = listmodalprops[field] || {}
      // newvalue = value
      // e.target.value = newvalue
      // return onChangeData({[field]: newvalue})
      return handleModal(
        <SearchContent {...propsmodal} key={Date.now()} />,
        'fit'
      )
    }
    if (ariaLabel === 'SimpanRujukan') {
      if (!datachange?.tindakan) {
        error = true
        message = 'Harap Isi Tindakan.'
      }
      if (!datachange?.ddoc?.id) {
        error = true
        message = 'Harap Pilih Dokter.'
      }
      if (!datachange?.dpoli?.id) {
        error = true
        message = 'Harap Pilih Poli.'
      }
      if (!datachange?.dfaskes?.id) {
        error = true
        message = 'Harap Pilih Faskes.'
      }
      if (!datachange?.date) {
        error = true
        message = 'Harap Pilih Tanggal.'
      }
      if (error) {
        showSimpleToastApp(message, 'Fail')
        return
      }
      const newdata = {
        uid: id,
        rmid,
        farid: datachange?.dfaskes?.id,
        porid: datachange?.dpoli?.id,
        dorid: datachange?.ddoc?.id,
        rujtanggal: datachange?.date,
        rujtindakan: datachange?.tindakan,
      }
      return saveData(newdata)
    }
  }
  const onChangeData = useCallback(
    e => {
      setdatachange(t => {
        return Object.assign(t || {}, e)
      })
    },
    // eslint-disable-next-line
    [datachange]
  )
  const onChangeCapture = async (e, value) => {
    const {ariaLabel} = e.target
    var actialfield = '',
      newvalue = ''
    // console.log('ariaLabel', ariaLabel, e, value)
    const [type, field] = `${ariaLabel}`.split('-')
    if (type === 'input') {
      newvalue = value
      e.target.value = newvalue
      return onChangeData({[field]: newvalue})
    }
  }
  const onErrorCapture = e => {
    const {ariaLabel} = e.target
  }
  const onCloseModal = e => {
    const el = Object.values(formRef?.current?.formRef)?.find(
      fl => fl?.ariaLabel === e?.props?.label
    )
    if (el && el?.click) {
      el.click()
    }
  }
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        navigate(-1)
      })
    } else {
      navigate(-1)
    }
  }
  const onBackPress = () => {
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      children: 'Apakah anda yakin akan keluar menu ini?',
      onConfirm: goBack,
      onClose: () => setpropsalertform(defaultpropsalertform),
      onCancel: () => setpropsalertform(defaultpropsalertform),
    })
  }
  const onHardwareBackPress = event => {
    const {data} = event,
      {modalRef} = formRef.current
    if (`${data}`?.match(/^onHardwareBackPress:/i)) {
      if (modalRef?.isOpen) {
        if (isFunction(modalRef?.close)) {
          return modalRef.close()
        }
      }
      return onBackPress()
    }
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })
  useAddEventListener({
    eventName: 'click',
    handler: onClick,
  })
  useEffect(() => {
    document.title = pageTitle
    if (data?.norm == '') {
      detectDevice()
      loadData()
    }
    return () => {}
  }, [])
  if (error) {
    return <FullErrorView message={error} />
  }

  if (!device) {
    return <ProgressFullScreen visible={true} />
  }

  const MobRujukan = () => {
    return (
      <TemplateForm
        ref={formRef}
        loading={initialLoading || loading}
        propsalertform={propsalertform}
        onChangeCapture={onChangeCapture}
        onErrorCapture={onErrorCapture}
        onCloseModal={onCloseModal}
      >
        <MobPrdRujukanCreate
          onBackPress={onBackPress}
          HeaderType={pageTitle}
          data={datachange}
          cetak={cetak}
        />
      </TemplateForm>
    )
  }

  const WebRujukan = () => {
    return (
      <TemplateForm
        ref={formRef}
        loading={initialLoading || loading}
        propsalertform={propsalertform}
        onChangeCapture={onChangeCapture}
        onErrorCapture={onErrorCapture}
        onCloseModal={onCloseModal}
      >
        <WebPrdRujukanCreate
          onBackPress={onBackPress}
          HeaderType={pageTitle}
          data={datachange}
          cetak={cetak}
        />
      </TemplateForm>
    )
  }

  if(device == 'mobile'){
    return <MobRujukan/>
  }else if(device == 'web'){
    return <WebRujukan/>
  }else{
    console.log(device,'device');
  }

  // return (
  //   <TemplateForm
  //     ref={formRef}
  //     loading={initialLoading || loading}
  //     propsalertform={propsalertform}
  //     onChangeCapture={onChangeCapture}
  //     onErrorCapture={onErrorCapture}
  //     onCloseModal={onCloseModal}
  //   >
  //     <MobPrdRujukanCreate
  //       onBackPress={onBackPress}
  //       HeaderType={pageTitle}
  //       data={datachange}
  //       cetak={cetak}
  //     />
  //   </TemplateForm>
  // )
}
