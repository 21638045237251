import {VmIcons, VmView} from 'components'
import {APIDOC} from 'config/apiurl'

export const headerFields = [
  {name: 'rmtgl', dateFormat: true},
  {
    children: (item, col) => (
      <VmView className="flex flex-row gap-1 text-gray-700">
        {item?.kunid_satusehat?.length > 0 && (
          <VmView className="flex items-center justify-center border border-[#03B1AF] rounded-md bg-white">
            <VmIcons size={18} name="VmSatuSehat" className="stroke-[2.5]" />
          </VmView>
        )}
        {item?.pasrm || '-'}
      </VmView>
    ),
    dbColumn: 'pasrm',
  },
]
export const dataFields = ['pasnama', 'rmkeluhanutama', 'icdkodenama']
export const dataVitals = [
  {
    icon: {
      name: 'FaTachometerAlt',
      // size: 12,
      className: 'text-gray-600 !h-[1em] !min-w-[1em]',
    },
    name: 'rmtekanandarah',
  },
  {
    icon: {
      name: 'FaHeartbeat',
      // size: 12,
      className: 'text-gray-600 !h-[1em] !min-w-[1em]',
    },
    name: 'rmnadi',
  },
  {
    icon: {
      name: 'FaThermometerHalf',
      // size: 12,
      className: 'text-gray-600 !h-[1em] !min-w-[1em]',
    },
    name: 'rmsuhu',
  },
  {
    icon: {
      name: 'FaLungs',
      // size: 12,
      className: 'text-gray-600 !h-[1em] !min-w-[1em]',
    },
    name: 'rmnafas',
  },
]
export const filterFields = [
  {
    'Semua Jenis Kelamin': [
      {value: 'Laki-laki', label: 'Laki laki'},
      {value: 'Perempuan', label: 'Perempuan'},
    ],
    dbColumn: 'pasjk',
  },
  {
    Sorting: [
      {label: 'Tanggal Rekam Medis', dbColumn: 'rmtgl'},
      {label: 'Nama', dbColumn: 'pasnama'},
      {label: 'No. RM', dbColumn: 'pasrm'},
    ],
  },
]
export const imageComponent = {
  component: props => <VmIcons {...props} />,
  props: {
    size: 65,
    className: 'mr-2',
    variant: 'avatar',
  },
  dynamicProps: {
    forProps: 'name',
    dbColumn: 'pasjk',
    alias: [
      {'Laki-laki': 'VmLDewasaColorIcon'},
      {Perempuan: 'VmPDewasaColorIcon'},
    ],
  },
}
export const pageTitle = 'Laporan Rekam Medis'
const classExportAttrWeb = {
  content:
    'bg-white flex flex-row rounded-xl p-4 my-3 cursor-pointer shadow-md hover:scale-105 hover:bg-green-50/90',
  title: 'flex flex-col',
}
export const customExportAttrWeb = [
  {
    url: APIDOC + '/prd-lap-rekammedis/export-web',
    title: 'Export rekap',
    classNames: classExportAttrWeb,
  },
  // {
  //   url: APIDOC + '/prd-lap-rekammedis/export-kategori-web',
  //   title: 'Export rekap detail',
  //   classNames: classExportAttrWeb,
  // },
]

export const customPrintAttrWeb = [
  {
    url: APIDOC + '/prd-lap-rekammedis/print-web',
    title: 'Cetak rekap',
    desc: 'Fungsi untuk mengekspor data dalam bentuk pdf',
    type: 'PDF',
    color: 'red',
    classNames: classExportAttrWeb,
  },
]
export const customPrintRmAttrWeb = {
  url: APIDOC + '/prd-lap-rekammedis/print-rm-web',
  title: 'Cetak rekap',
  desc: 'Fungsi untuk mengekspor data dalam bentuk pdf',
  type: 'PDF',
  color: 'red',
  classNames: classExportAttrWeb,
}
