import React, {useCallback, useEffect, useRef, useState} from 'react'
import {API, graphqlOperation} from 'aws-amplify'
import {useNavigate, useSearchParams} from 'react-router-dom'
import axios from 'axios'
import QueryString from 'qs'

import {isFunction} from 'utils'
import {VmButton, VmDivider, VmIcons, VmInput, VmText, VmView} from 'components'
import {ReportMobile} from 'template'
import {mPatientVisitInfo} from 'graphql/mutations'
import {ProgressFullScreen, VmMobileFilter, VmPulltoRefresh} from 'molecules'
import {Page500} from 'pages'
import {APIDOC, APISATSET, APIBPJS} from 'config/apiurl'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {catatLogDokter, empty, tglDashNoSpace, tglYMD, triggerEventBusAntrean} from 'utils/functions'
import satusehaticon from '../../assets/satusehat.png'
import satusehatminiicon from '../../assets/satusehaticon.ico'
import moment from 'moment'
import {VmArrowPathRoundedSquareIcon} from 'components/icons/solid'
import classNames from 'classnames'
import { getLclStorage, setLclStorage } from 'utils/localstorage'

export default function Index({
  classNameANCHORHeader = '',
  classNameANCHORContent = '',
  headerAttr = {enable: false},
  footerAttr = {enable: true},
  ...props
}) {
  const alertok = useRef(null)
  const alertyesno = useRef(null)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dctid = searchParams.get('dctid') ?? props.dctid
  // const dctid = 337
  const initialApiParams = Object.assign(
    {
      reg: 'dbdok',
      dctid,
      limit: 10,
      offset: 0,
      dateStart: new Date(moment().format('YYYY-MM-01')),
      dateEnd: new Date(),
      dateType: 2,
      bulan: moment().format('YYYY-MM-01'),
      tahun: '',
      a: 'dk13',
      date: '',
    },
    props?.dctid ? {dctid: props.dctid} : {}
  )
  const initialFilter = {
    filterFieldsValue: [
      {
        'Semua Periode': [
          {
            value: '',
            label: 'Berdasarkan Tahun',
            flag: 'tahun',
          },
          {
            value: '',
            label: 'Berdasarkan Bulan',
            flag: 'bulan',
          },
          {
            value: '',
            label: 'Berdasarkan Tanggal',
            flag: 'tanggal',
          },
        ],
      },
    ],
    currentOpenedFilter: 'Semua Periode',
    activeFilterHead: ['Semua Periode'],
    activeFilterHeadLabel: {
      'Semua Periode': `${moment(initialApiParams.dateStart).format(
        'MMM-YYYY'
      )}`,
    },
    dateFilterFlag: {bulan: true},
    sortIcon: '',
    activeFilter: {
      date: {
        bulan: new Date(initialApiParams.dateStart),
      },
      dateType: initialApiParams.dateType,
    },
    dateValue: {
      datePickerValue: new Date(initialApiParams.dateStart),
    },
  }

  // UI State
  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(initialFilter)
  const [cari, setCari] = useState('')
  const [count, setCount] = useState(0)

  // Api State
  const [hasMore, setHasMore] = useState(true)
  const [apiParams, setApiParams] = useState({...initialApiParams})

  useEffect(() => {
    fetchData()
    // console.log('masuk sini kok')
    // console.error('apiParams', apiParams)
  }, [])

  // Handler on Error Props
  useEffect(() => {
    if (error && !loading) {
      if (isFunction(props?.onError)) {
        // props.onError(error)
      }
    }
  }, [error, loading])

  const onCardItemClick = useCallback(data => {
    if(apiParams?.custom && data?.pasnobpjs && data?.status == 1 && !data?.kunpcareno){
      if(alertyesno?.current == null) entriPcare(data)
      alertyesno.current?.show(
        {
          message: `Pasien ini belum terdaftar di PCare, apakah Anda ingin mendaftarkan Pasien ini di PCare?`,
        },
        () => {
          entriPcare(data)
        },
        () => {
          if (isFunction(props.onCardItemClick)) {
            props.onCardItemClick(data)
          }
        }
      )
      return
    }

    if (isFunction(props.onCardItemClick)) {
      props.onCardItemClick(data)
    }
  })

  const fetchData = async (additionalParams = {}, initial = false) => {
    setLoading(true)
    const custom = !!apiParams?.custom ? apiParams?.custom : await fetchCustom()
    const prevParams = initial ? {} : apiParams
    const params = {...prevParams, ...additionalParams, custom}
    setApiParams(s => ({...s, custom}))

    // console.log('params fetchData', JSON.stringify((params)))
    try {
      const res = await API.graphql(
        graphqlOperation(mPatientVisitInfo, {
          input: {req: JSON.stringify(params)},
        })
      )

      const resData = res.data.mPatientVisitInfo
      if (resData && resData.statusCode === 200) {
        const parsedData = JSON.parse(resData.body).data
        const parsedCount = JSON.parse(resData.body).count
        setData(parsedData)
        setCount(parsedCount[0]?.id)
      } else {
        handleError()
      }
      setLoading(false)
    } catch (e) {
      let runtimeError = handleLambdaRuntimeExit(e)
      if (runtimeError) {
        return refetchLambdaError(runtimeError, params)
      } else {
        setLoading(false)
        return handleError(e)
      }
    }
  }
  const handleError = (e = '') => {
    setError({e: e ?? '', message: 'Terjadi kesalahan saat mengambil data'})
  }

  const fetchMore = async () => {
    const params = {...apiParams, offset: data.length}
    try {
      const res = await API.graphql(
        graphqlOperation(mPatientVisitInfo, {
          input: {req: JSON.stringify(params)},
        })
      )

      const resData = res.data.mPatientVisitInfo
      if (resData && resData.statusCode === 200) {
        const parsedData = JSON.parse(resData.body).data
        if (!parsedData) return setHasMore(false)

        setData([...data, ...parsedData])

        if (parsedData.length < 10) return setHasMore(false)
      } else {
        handleError()
      }
    } catch (e) {
      let runtimeError = handleLambdaRuntimeExit(e)
      if (!runtimeError) return handleError(e)
      refetchLambdaError(runtimeError, params)
    }
  }

  const fetchCustom = async () => {
    try {
      const {data} = await axios.post(
        APIDOC + '/prd-lap-kunjungan-pasien/custom-bpjs',
        QueryString.stringify({uid: dctid})
      )
      return Object.keys(data)[0]
    } catch (e) {}
  }

  const onChangeFilter = val => {
    const cleanObject = {dateStart: '', dateEnd: '', date: ''}
    let initialDate = {
      dateStart: val?.date?.start ?? new Date(),
      dateEnd: val?.date?.end ?? new Date(),
    }

    if (val?.clear) initialDate = {dateStart: '', dateEnd: ''}
    let params = {...val, ...initialDate, date: '', bulan: '', tahun: ''}

    if (val?.date?.bulan)
      params = {...val, bulan: val?.date?.bulan, tahun: '', ...cleanObject}
    if (val?.date?.tahun)
      params = {...val, tahun: val?.date?.tahun, bulan: '', ...cleanObject}

    setApiParams({...initialApiParams, ...params})
    setHasMore(true)
    fetchData({...initialApiParams, ...params})
  }

  const batalKunjungan = item => {
    axios
      .post(
        `${APIDOC}/prd-lap-kunjungan-pasien/batal`,
        QueryString.stringify({
          kunid: item.id,
          uid: dctid,
        })
      )
      .then(({data: res}) => {

        if(apiParams?.custom){
          let params_antrean_fktp = {
            appid: 'dk13',
            uid: dctid,
            tanggalperiksa: tglYMD(item?.kuntgl) ?? tglYMD(new Date()),
            kodepoli: '001',
            nomorkartu: item?.pasnobpjs ?? '',
            alasan: 'Antrean dibatalkan',
          }
  
          console.log('batalAntrean', params_antrean_fktp)
          triggerEventBusAntrean(
            'update-antrean',
            'prd-antrean/batal-antrean-fktp',
            params_antrean_fktp
          )
        }

        // Berhasil Get Data
        catatLogDokter({
          keterangan: `Membatalkan Kunjungan ${item.number}`,
          menu: 'Pelayanan - Laporan Kunjungan',
          url: 'prd-lap-kunjungan',
          ip: '127.0.0.1',
          uid: dctid,
        })
        refetchWithCurrentParams()
      })
      .catch(e => {
        alertok.current?.show({
          message: 'Terjadi Kesalahan saat Batal Kunjungan',
        })
        // console.log('Terjadi Kesalahan saat Batal Kunjungan')
      })
  }

  const hadirKunjungan = (item, status) => {
    let url = status == 1 ? `${APIBPJS}/bpjs-jkn/add-pendaftaran-pcare` : `${APIDOC}/prd-lap-kunjungan-pasien/batal`
    let dataPcare = {
      kdProviderPeserta: '',
      tglDaftar: tglDashNoSpace(item?.kuntgl) ?? '',
      noKartu: item?.pasnobpjs ?? '',
      kdPoli: '001',
      keluhan: '',
      kunjSakit: 1,
      sistole: 0,
      diastole: 0,
      beratBadan: 0,
      tinggiBadan: 0,
      respRate: 0,
      lingkarPerut: 0,
      heartRate: 0,
      rujukBalik: 0,
      kdTkp: '10',
    }

    // console.error('hadirKunjungan', dataPcare)

    axios
      .post(url,
        QueryString.stringify({
          a: 'dk13',
          uid: dctid,
          kunid: item.id,
          data: dataPcare,
        })
      )
      .then(({data: res}) => {

        if(apiParams?.custom){
          let params_antrean_fktp = {
            appid: 'dk13',
            reg: 'dbdok',
            uid: dctid,
            tanggalperiksa: tglYMD(item?.kuntgl) ?? tglYMD(new Date()),
            kodepoli: '001',
            nomorkartu: item?.pasnobpjs ?? '',
            status
          }
  
          console.log('panggilAntrean', params_antrean_fktp)
          triggerEventBusAntrean(
            'update-antrean',
            'prd-antrean/panggil-antrean-fktp',
            params_antrean_fktp
          )
        }

        // Berhasil Get Data
        catatLogDokter({
          keterangan: status == 1 ? `Melakukan Hadir Kunjungan ${item.number}` : `Melakukan Tidak Hadir Kunjungan ${item.number}`,
          menu: 'Pelayanan - Laporan Kunjungan',
          url: 'prd-lap-kunjungan',
          ip: '127.0.0.1',
          uid: dctid,
        })
        if(status == 1) navigate(`/prd-tra-pemeriksaan-pasien/create?kid=${item?.id}&pid=${item?.pid}&dctid=${dctid}`)
        if(status == 2) refetchWithCurrentParams()
      })
      .catch(e => {
        alertok.current?.show({
          message: 'Terjadi Kesalahan saat melakukan Kehadiran Kunjungan',
        })
        console.log('Terjadi Kesalahan saat melakukan Kehadiran Kunjungan')
      })
  }

  const entriPcare = item => {
    let regpasien_data = JSON.parse(getLclStorage('regpasien_data', {type: 2}))
    let filterPasien = regpasien_data?.filter(data => data?.pasnobpjs == item?.pasnobpjs)
    let filterKunjungan = data.filter(data => data.number == item?.number)

    // setLclStorage('regpasien_tanggalkunjungan', '2024-09-09', {type: 2})
    setLclStorage('regpasien_nokunjungan', filterKunjungan[0]?.number, {type: 2})
    setLclStorage('regpasien_tanggalkunjungan', filterKunjungan[0]?.date, {type: 2})
    setLclStorage('regpasien_keluhan', filterKunjungan[0]?.kunkeluhan, {type: 2})
    setLclStorage('regpasien_pasid', filterPasien[0]?.pasid, {type: 2})
    setLclStorage('regpasien_pasnobpjs', filterPasien[0]?.pasnobpjs, {type: 2})
    setLclStorage('regpasien_pasnokk', filterPasien[0]?.pasnokk, {type: 2})
    setLclStorage('regpasien_pasnama', filterPasien[0]?.pasnama, {type: 2})
    setLclStorage('regpasien_pasgoldarah', filterPasien[0]?.pasgoldarah, {type: 2})
    setLclStorage('regpasien_pasjk', filterPasien[0]?.pasjk, {type: 2})
    setLclStorage('regpasien_pastgllahir', filterPasien[0]?.pastgllahir, {type: 2})
    setLclStorage('regpasien_pasalamat', filterPasien[0]?.pasalamat, {type: 2})
    setLclStorage('regpasien_pasidkec', filterPasien[0]?.idkecamatan, {type: 2})
    setLclStorage('regpasien_pastelp', filterPasien[0]?.pastelp, {type: 2})
    setLclStorage('regpasien_paspekerjaan', filterPasien[0]?.paspekerjaan, {type: 2})
    setLclStorage('regpasien_pasemail', filterPasien[0]?.pasemail, {type: 2})
    setLclStorage('regpasien_pasalergi', filterPasien[0]?.pasalergi, {type: 2})
    setLclStorage('regpasien_pasalergimakanan', filterPasien[0]?.pasalergimakanan, {type: 2})
    setLclStorage('regpasien_pasnoktp', filterPasien[0]?.pasnoktp, {type: 2})
    setLclStorage('regpasien_pasrm', filterPasien[0]?.pasrm, {type: 2})
    setLclStorage('regpasien_pasid_satusehat', filterPasien[0]?.pasid_satusehat, {type: 2})
    setLclStorage('regpasien_pasconsent', filterPasien[0]?.pasconsent?.toString(), {type: 2})
    setLclStorage('regpasien_pasumur', filterPasien[0]?.umur, {type: 2})
    setLclStorage('regpasien_agid', filterPasien[0]?.agid, {type: 2})
    setLclStorage('regpasien_pendid', filterPasien[0]?.pendid, {type: 2})
    setLclStorage('regpasien_penid', filterPasien[0]?.penid, {type: 2})
    setLclStorage('regpasien_pennama', filterPasien[0]?.pennama, {type: 2})
    setLclStorage('regpasien_pennik', filterPasien[0]?.pennik, {type: 2})
    setLclStorage('regpasien_penhubungan', filterPasien[0]?.penhubungan, {type: 2})
    setLclStorage('regpasien_penjk', filterPasien[0]?.penjk, {type: 2})
    setLclStorage('regpasien_pentelp', filterPasien[0]?.pentelp, {type: 2})
    setLclStorage('regpasien_penalamat', filterPasien[0]?.penalamat, {type: 2})

    navigate(
      `/prd-registrasi-pasien/${dctid}`
      // `/prd-tra-pemeriksaan-pasien/create?kid=${item?.id}&pid=${item?.pid}&dctid=${dctid}`
    )
  }
    

  const onClearFilter = () => {
    const params = {
      ...initialApiParams,
      dateStart: '',
      dateEnd: '',
      dateType: '',
    }
    setApiParams(params)
    setHasMore(true)
    fetchData(params, true)
    setCari('')
  }

  const onSubmitSearch = e => {
    if (e.key === 'Enter') {
      setApiParams({...apiParams, cari: e.target.value})
      fetchData({cari: e.target.value})
    }
  }

  const refetchWithCurrentParams = () => {
    setHasMore(true)
    fetchData()
  }

  const onRefresh = () => {
    setFilter(initialFilter)
    setCari('')
    setApiParams(initialApiParams)
    setHasMore(true)
    fetchData(initialApiParams, true)
  }

  const handleLambdaRuntimeExit = error => {
    if (error?.errors?.length > 0)
      return (
        error?.errors[0]?.message.includes(
          'Error: Runtime exited with error: exit status 129'
        ) && error?.errors[0]?.errorType === 'Lambda:Unhandled'
      )
    return false
  }

  const refetchLambdaError = async (runtimeError, params) => {
    let limitCall = 0
    while (runtimeError) {
      if (limitCall === 10) {
        runtimeError = false
        return
      }
      limitCall++
      await fetchData(params)
    }
  }

  if (loading)
    return (
      <ProgressFullScreen useBgColor visible={loading} className="!z-[100]" />
    )

  if (error && !loading) return <Page500 />

  if (data) {
    const searchInputStyle =
      'sticky w-full border-gray-400 text-gray-500 placeholder:text-gray-400 placeholder:text-sm h-10 rounded-xl p-2 mb-1 z-50'

    const headerFields = [{name: 'date', dateFormat: true}, 'number']
    const dataFields = apiParams?.custom ? ['rm', 'nik', 'patname', 'pasnobpjs', 'kunpcareno', 'kunpcarekunjungan'] : ['rm', 'nik', 'patname']
    const aliasFields = [
      {
        dbColumn: 'status',
        alias: [
          {
            value: 1,
            label: 'Belum Diperiksa',
            color: 'text-yellow-500 bg-yellow-200',
          },
          {
            value: 2,
            label: 'Dibatalkan',
            color: 'text-red-500 bg-red-200',
          },
          {
            value: 3,
            label: 'Sudah Diperiksa',
            color: 'text-blue-500 bg-blue-200',
          },
          {
            value: 4,
            label: (
              <VmView className="flex flex-row items-center">
                Sudah Disimpan
                <VmIcons
                  size={12}
                  name="VmLockClosedIcon"
                  variant="outline"
                  className="ml-1 stroke-green-600"
                />
              </VmView>
            ),
            color: 'text-green-500 bg-green-200',
          },
        ],
      },
    ]
    const imageComponent = {
      component: props => <VmIcons {...props} />,
      props: {
        size: 65,
        className: 'mr-2',
        variant: 'avatar',
      },
      dynamicProps: {
        forProps: 'name',
        dbColumn: 'sex',
        alias: [
          {'Laki-laki': 'VmLDewasaColorIcon'},
          {Perempuan: 'VmPDewasaColorIcon'},
        ],
      },
    }
    const withStatus = true
    const statusDbColumn = 'status'

    const syncData = () => {
      axios
        .post(
          `${APISATSET}/satusehat-kunjungan/sync-data-encounter`,
          QueryString.stringify({
            userid: dctid,
          })
        )
        .then(({data: res}) => {
          // Berhasil Get Data
          alertok.current?.show({
            message: 'Berhasil melakukan Sync Data Kunjungan SatuSehat',
            type: 'success',
          })
        })
        .catch(e => {
          // console.log('Terjadi Kesalahan saat Sync Data Kunjungan SatuSehat')
          alertok.current?.show({
            message: 'Terjadi kesalahan saat Sync Data Kunjungan SatuSehat',
          })
        })

      alertok.current?.show({
        message: 'Sedang melakukan Sync Data Kunjungan SatuSehat',
        type: 'success',
      })
    }

    const syncBtnClassnames =
      'sticky flex justify-center items-center ml-2 h-[2.5rem] w-[2.7rem] bg-white border border-2 border-solid border-green-500 rounded-xl cursor-pointer'
    return (
      <>
        {footerAttr?.enable && <AlertOk ref={alertok} />}
        {footerAttr?.enable && <AlertYesNo ref={alertyesno} />}

        <VmPulltoRefresh onRefresh={onRefresh}>
          <ReportMobile
            sideDownloadComponent={
              <>
                {/* Tombol Sync All Encounter yang belum punya id encounter SatuSehat */}
                {footerAttr?.enable && (
                  <VmView
                    onClick={() => {
                      // console.log('Test Sync Data Kunjungan ke SatuSehat.')
                      alertyesno.current?.show(
                        {
                          message:
                            'Anda yakin akan melakukan sinkronisasi data kunjungan ke SatuSehat?',
                        },
                        syncData
                      )
                    }}
                    className={syncBtnClassnames}
                  >
                    <img
                      width={24}
                      src={satusehatminiicon}
                      alt="satusehat-icon"
                      className=""
                    />
                  </VmView>
                )}
              </>
            }
            data={data}
            statusDbColumn={statusDbColumn}
            headerFields={headerFields}
            dataFields={dataFields}
            aliasFields={aliasFields}
            // customRender={(item, i) => {
            //   let terhubung_satusehat = !empty(item.kunid_satusehat)
            //   return (
            //     <VmCardReport
            //       button={true}
            //       buttonLabel="Detail"
            //       buttonAction={onCardItemClick}
            //       footerDividerVisible={false}
            //       headerFields={headerFields}
            //       item={item}
            //       key={i}
            //       index={i}
            //       fields={dataFields}
            //       onCardItemClick={onCardItemClick}
            //       imageComponent={imageComponent}
            //       aliasFields={aliasFields}
            //       withStatus={withStatus}
            //       statusDbColumn={statusDbColumn}
            //       customStatusComponent={
            //         terhubung_satusehat ? (
            //           <VmView className="flex items-center">
            //             <VmText className="text-xs">Terhubung</VmText>
            //             <img
            //               width={36}
            //               src={satusehaticon}
            //               alt="satusehat-icon"
            //             />
            //           </VmView>
            //         ) : (
            //           <></>
            //         )
            //       }
            //       // button={showButtonEdit || detailAttr.button}
            //       // buttonLabel={showButtonEdit ? 'Ubah' : detailAttr.buttonLabel}
            //       // buttonAction={() => {}}
            //       // buttonAdditionalProps={{variant: 'outline', color: 'primary'}}
            //     />
            //   )
            // }}
            imageComponent={imageComponent}
            filterComponent={
              <VmMobileFilter
                className="pt-1.5"
                filterValue={filter}
                filterHandler={setFilter}
                filterFields={[
                  {
                    'Semua Jenis Kelamin': [
                      {value: 'Laki-laki', label: 'Laki laki'},
                      {value: 'Perempuan', label: 'Perempuan'},
                    ],
                    dbColumn: 'sex',
                  },
                  {
                    'Semua Status': [
                      {value: 1, label: 'Belum Diperiksa'},
                      {value: 2, label: 'Dibatalkan'},
                      {value: 3, label: 'Sudah Diperiksa'},
                      {value: 4, label: 'Sudah Disimpan'},
                    ],
                    dbColumn: 'status',
                  },
                  {
                    Sorting: [
                      {label: 'Tanggal Kunjungan', dbColumn: 'kuntgl'},
                      {label: 'Nama', dbColumn: 'pasnama'},
                      {label: 'No. RM', dbColumn: 'pasrm'},
                      {label: 'No. Kunjungan', dbColumn: 'kunnomer'},
                    ],
                  },
                ]}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            }
            searchComponent={
              <VmInput
                value={cari}
                onChange={e => setCari(e.target.value)}
                type="text"
                className={searchInputStyle}
                placeholder= {apiParams?.custom ? "Cari Nama / No. RM / NIK / No. BPJS / No. Pendaftaran Pcare / No. Kunjungan Pcare" : "Cari Nama / No. RM / NIK"}
                enterKeyHint="search"
                onKeyDown={onSubmitSearch}
              />
            }
            hasMore={hasMore}
            loadMore={fetchMore}
            onCardItemClick={onCardItemClick}
            empty={count <= 0 && !loading && !error}
            count={count ?? 0}
            headerAttr={headerAttr}
            exportApiUrl={
              apiParams?.custom
                ? process.env.REACT_APP_BASE_URL_DOKTER +
                  '/prd-lap-kunjungan-pasien/export-custom-pcare'
                : process.env.REACT_APP_BASE_URL_DOKTER +
                  '/prd-lap-kunjungan-pasien/export'
            }
            exportApiParams={{
              ...initialApiParams,
              ...apiParams,
              ...cari,
              uid: dctid,
            }}
            classNameANCHORHeader={classNameANCHORHeader}
            classNameANCHORContent={classNameANCHORContent}
            footerContentComponent={item => {
              // console.error('footerContentComponent called', item)
              // if (
              //   // (item?.status != 1 && !item?.pasnobpjs && !item?.kunpcareno) ||
              //   !apiParams?.custom ||
              //   !footerAttr.enable
              // )
              //   return null

              return (
                <>
                  <VmDivider className="mt-2 mb-4" />
                  <VmView className="flex flex-row justify-end items-start">
                    {item?.pasnobpjs && item?.status == 1 && !item?.kunpcareno && apiParams?.custom ? (
                      <>
                        <VmButton
                          className="hover:!bg-sky-600"
                          onClick={() =>
                            entriPcare(item)
                          }
                          variant="outline"
                          color="primary"
                        >
                          {item?.number?.includes("JKN") ? "Hadir" : "PCare"}
                        </VmButton>
                        {/* <VmButton
                          className="hover:!bg-sky-600 ml-4"
                          onClick={() =>
                            hadirKunjungan(item, 1)
                          }
                          variant="outline"
                          color="primary"
                        >
                          Hadir
                        </VmButton> */}
                        <VmButton
                          className="hover:!bg-yellow-600 ml-4"      
                          onClick={() => {
                            if(alertyesno?.current == null) hadirKunjungan(item, 2)
                            alertyesno.current?.show(
                              {
                                message: `Apakah Anda yakin Kunjungan ${item.number} Pasien tidak hadir?`,
                              },
                              () => {
                                hadirKunjungan(item, 2)
                              }
                            )
                          }}
                          variant="outline"
                          color="warning"
                        >
                          Tidak Hadir
                        </VmButton>
                        <VmButton
                          className="hover:!bg-red-600 ml-4"
                          onClick={() => {
                            if(alertyesno?.current == null) batalKunjungan(item)
                            alertyesno.current?.show(
                              {
                                message: `Apakah Anda yakin akan membatalkan Kunjungan ${item.number} ?`,
                              },
                              () => {
                                batalKunjungan(item)
                              }
                            )
                          }}
                          variant="outline"
                          color="danger"
                        >
                          Batal
                        </VmButton>
                      </>
                    ) : null}
                    {item?.status == 1 && false ? (
                      <VmButton
                        className="hover:!bg-red-600 ml-4"
                        onClick={() => {
                          // localStorage.removeItem('ubah_kunjungan_pasien')
                          // navigate(`/prd-tra-kunjungan-ubah/${dctid}/${item.id}`)

                          // Konfirmasi Batalkan Kunjungan
                          alertyesno.current?.show(
                            {
                              message: `Apakah Anda yakin akan membatalkan Kunjungan ${item.number} ?`,
                            },
                            () => {
                              // Batalkan Kunjungan
                              batalKunjungan(item)
                            }
                          )
                        }}
                        variant="outline"
                        color="danger"
                      >
                        Batal
                      </VmButton>
                    ) : null}
                  </VmView>
                </>
              )
            }}
            cardContentComponent={item => {
              const rowItems = [
                {
                  icon: 'VmCarbonHospital',
                  value: item?.rm,
                  className: 'font-semibold',
                },
                {
                  icon: 'VmIdentificationIcon',
                  value: item?.nik,
                  className: '',
                },
                {
                  icon: 'VmUserIcon',
                  value: item?.patname,
                  className: '',
                },
              ]

              if (apiParams?.custom)
                rowItems.push(
                  ...[
                    {
                      icon: 'VmBpjsIcon',
                      value: item?.pasnobpjs,
                      className: '',
                    },
                    {
                      icon: 'VmBpjsIcon',
                      value: item?.kunpcareno,
                      className: '',
                    },
                    {
                      icon: 'VmBpjsIcon',
                      value: item?.kunpcarekunjungan,
                      className: '',
                    },
                  ]
                )

              if (!empty(item?.pasid_satusehat))
                rowItems.unshift({
                  icon: 'VmSatuSehat',
                  value: item?.pasid_satusehat,
                  className: '',
                })

              return (
                <VmView className="text-gray-500">
                  {rowItems.map((item, i) => (
                    <VmView
                      className="flex flex-row items-baseline gap-2"
                      key={i}
                    >
                      <VmIcons
                        name={item.icon}
                        size={20}
                        className="self-center"
                      />
                      <VmText className={item.className}>
                        {item?.value || '-'}
                      </VmText>
                    </VmView>
                  ))}
                </VmView>
              )
            }}
            cardHeaderComponent={item => {
              // console.log('cardHeaderComponent called', item)
              if (!empty(item.kunid_satusehat)) {
                return (
                  <VmView className="flex items-center">
                    <VmText className="text-sxs">Terhubung</VmText>
                    <img width={36} src={satusehaticon} alt="satusehat-icon" />
                  </VmView>
                )
              }
              return <></>
            }}
            useWindowInfiniteScroll={props.useWindowInfiniteScroll}
          />
        </VmPulltoRefresh>
      </>
    )
  }
}
