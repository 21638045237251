import useFetchData from 'utils/useFetchData'
import React, {memo} from 'react'

function VmWFetch({config, children}) {
  const [data, mdata, ldata, edata, eddata] = useFetchData(config)
  if (ldata) {
    return (
      <>
        {typeof children === 'function'
          ? children(data, mdata, ldata, edata, eddata)
          : children}
      </>
    )
  }
  return (
    <>
      {typeof children === 'function'
        ? children(data, mdata, ldata, edata, eddata)
        : children}
    </>
  )
}

export default memo(VmWFetch)
