import {createElement, useState, useEffect, useCallback} from 'react'
import {oneOfType, string, array, object, func, oneOf} from 'prop-types'
import classNames from 'classnames'

const VmToaster = props => {
  const {className, innerRef, position, push = null, ...attributes} = props
  const [toastlist, settoastlist] = useState([])
  const classes = classNames(
    'toaster',
    position && position !== 'static' && 'toaster-' + position,
    className
  )
  const addToast = useCallback(
    push => {
      settoastlist([...toastlist, push])
    },
    [toastlist]
  )
  useEffect(() => {
    if (!!push) {
      addToast(push)
    }
    // eslint-disable-next-line
  }, [push])

  return createElement(
    'div',
    {
      className: classes,
      ref: innerRef,
      ...attributes,
    },
    [...toastlist]
  )
}

VmToaster.propTypes = {
  className: oneOfType([string, array, object]),
  innerRef: oneOfType([func, object]),
  position: oneOf([
    '',
    'static',
    'top-right',
    'top-left',
    'top-center',
    'top-full',
    'bottom-right',
    'bottom-left',
    'bottom-center',
    'bottom-full',
  ]),
}

VmToaster.defaultProps = {
  position: 'top-right',
}

export default VmToaster
