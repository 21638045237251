import React, {createRef, useEffect, useState} from 'react'
import {useParams, useNavigate} from 'react-router-dom'
import {ReportMobile} from 'template'
import {LogAktivitasController} from 'controllers'
import {VmPulltoRefresh, ProgressFullScreen, VmMobileFilter} from 'molecules'
import {VmIcons, VmInput, VmModal, VmText, VmView} from 'components'
import {isFunction} from 'utils'
import moment from 'moment'

export default function Index(props) {
  const pageTitle = 'Log Aktivitas'
  const initialApiParams = {
    limit: 10,
    offset: 0,
    dateStart: new Date(moment().format('YYYY-MM-01')),
    dateEnd: new Date(),
    bulan: '',
    tahun: '',
    sort: 0,
  }
  const initialFilter = {
    filterFieldsValue: [
      {
        'Semua Periode': [
          {
            value: '',
            label: 'Berdasarkan Tahun',
            flag: 'tahun',
          },
          {
            value: '',
            label: 'Berdasarkan Bulan',
            flag: 'bulan',
          },
          {
            value: '',
            label: 'Berdasarkan Tanggal',
            flag: 'tanggal',
          },
        ],
      },
    ],
    currentOpenedFilter: 'Semua Periode',
    activeFilterHead: ['Semua Periode'],
    activeFilterHeadLabel: {
      'Semua Periode': `${moment(initialApiParams.dateStart).format(
        'MMM-YYYY'
      )}`,
    },
    dateFilterFlag: {bulan: true},
    sortIcon: '',
    activeFilter: {
      date: {
        bulan: new Date(initialApiParams.dateStart),
      },
      dateType: initialApiParams.dateType,
    },
    dateValue: {
      datePickerValue: new Date(initialApiParams.dateStart),
    },
  }

  const navigate = useNavigate()
  const params = useParams()
  const [data, setdata] = useState({})
  const [cari, setCari] = useState('')
  const [filter, setFilter] = useState(initialFilter)
  const [count, setCount] = useState(0)
  // id Vlaidation Type
  const idInt = parseInt(params?.id)
  const [loading, setLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [error, setError] = useState('')

  const modalRef = createRef()
  const [apiParams, setApiParams] = useState(initialApiParams)

  useEffect(() => {
    onPageLoad()
  }, [])

  useEffect(() => {
    if (error && !loading) {
      if (isFunction(props?.onError)) {
        props.onError(error)
      }
    }
  }, [error, loading])

  const onPageLoad = (additionalParams = {}) => {
    const params = {...apiParams, ...additionalParams}
    document.title = pageTitle
    var error = false,
      errorMessage = ''
    LogAktivitasController(
      {
        ...params,
        id: idInt,
        key: 'index',
        dispatch: () => {},
        _ldata: 'mViewAktivitasUser',
      },
      navigate,
      setLoading(true)
    )
      .then(res => {
        //console.log('sukses!', res?.count[0].jmlh)
        if (res?.data?.listdata?.length > 0) {
          setdata(res.data.listdata)
          setCount(res?.count[0]?.jmlh)
        } else {
          setdata([])
        }
        setLoading(false)
      })
      .catch(err => {
        // console.log('error!', err)
        error = true
        errorMessage = '[C0] Gagal mengabil data!'
        setLoading(false)
      })
      .finally(() => {})
  }

  const onLoadMore = () => {
    const params = {...apiParams, offset: data.length}
    document.title = pageTitle
    var error = false,
      errorMessage = ''
    LogAktivitasController(
      {
        ...params,
        id: idInt,
        key: 'index',
        dispatch: () => {},
        _ldata: 'mViewAktivitasUser',
      },
      navigate
    )
      .then(res => {
        const datas = res?.data?.listdata
        // console.log('sukses!', datas)
        if (datas) {
          setdata([...data, ...datas])
        } else {
          setHasMore(false)
        }
      })
      .catch(err => {
        // console.log('error!', err)
        error = true
        errorMessage = '[C0] Gagal mengabil data!'
      })
      .finally(() => {})
  }

  const onChangeFilter = val => {
    const cleanObject = {dateStart: '', dateEnd: '', date: ''}

    const lstcol = ['logdate DESC', 'logdate ASC']

    let params = {
      ...val,
      dateStart: val?.date?.start
        ? val?.date?.start
        : apiParams?.clear
        ? ''
        : new Date(),
      dateEnd: val?.date?.end
        ? val?.date?.end
        : apiParams?.clear
        ? ''
        : new Date(),
      date: '',
      bulan: '',
      tahun: '',
      sort: lstcol.indexOf(val?.sort),
    }
    if (val?.date?.bulan)
      params = {...val, bulan: val?.date?.bulan, tahun: '', ...cleanObject}
    if (val?.date?.tahun)
      params = {...val, tahun: val?.date?.tahun, bulan: '', ...cleanObject}

    setApiParams({...initialApiParams, ...params, clear: apiParams?.clear})
    setHasMore(true)
    onPageLoad({...initialApiParams, ...params, clear: apiParams?.clear})
  }

  const onClearFilter = () => {
    const params = {
      ...initialApiParams,
      dateStart: '',
      dateEnd: '',
      sort: '',
      clear: true,
    }
    setApiParams(params)
    setHasMore(true)
    onPageLoad(params)
  }

  const onSubmitSearch = e => {
    if (e.key === 'Enter') {
      setdata([])
      setCount(0)
      setHasMore(true)
      setApiParams(s => ({...s, cari: e.target.value}))
      onPageLoad({cari: e.target.value})
    }
  }

  const onRefresh = () => {
    onPageLoad()
  }

  const handleBackToRN = () => {
    navigate(-1)
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage('onBackPress:')
    }
  }

  const handleLogBeforAfter = (val, valPasangan) => {
    let entries = [],
      entriesPasangan = [],
      parsedPasangan
    try {
      entries = Object.entries(JSON.parse(val))
      entriesPasangan = Object.entries(JSON.parse(valPasangan))
      parsedPasangan = JSON.parse(valPasangan)
    } catch (e) {
      entries = []
      entriesPasangan = []
      parsedPasangan = {}
    }
    // console.log('log data before after', {entries, entriesPasangan})

    if (entries.length > 0)
      return entries.map((item, i) => {
        if (item[0] in parsedPasangan && item[1] == parsedPasangan[item[0]]) {
          // Jika value sama, maka tak perlu ditampilkan, skip saja.
          return
        }
        return (
          <VmView key={i} className="flex">
            <VmText className="text-black1-payment inline">{`${item[0]} : `}</VmText>
            <VmView
              className="text-black1-payment font-semibold inline"
              // dangerouslySetInnerHTML={{__html: item[1]}}
            >
              <VmText>{item[1]}</VmText>
            </VmView>
          </VmView>
        )
      })
    return '-'
  }

  if (loading)
    return (
      <ProgressFullScreen useBgColor visible={loading} className="!z-[100]" />
    )

  if (data) {
    const searchInputStyle =
      'w-full border-gray-400 text-gray-500 placeholder:text-gray-400 placeholder:text-sm h-10 rounded-xl p-2 mt-3 mb-1 z-50'
    return (
      <>
        <VmPulltoRefresh onRefresh={onRefresh}>
          <ReportMobile
            key={JSON.stringify(data)}
            device="mobile"
            data={data}
            headerFields={[{name: 'logdate', dateFormat: true}, 'logmen']}
            dataFields={['logket']}
            headerAttr={{
              light: true,
              enable: true,
              title: 'Laporan Aktivitas User',
              onClick: handleBackToRN,
            }}
            hasMore={hasMore}
            loadMore={onLoadMore}
            count={count ?? 0}
            empty={!data && !loading && !error}
            filterComponent={
              <VmMobileFilter
                className="pt-1.5"
                filterValue={filter}
                filterHandler={setFilter}
                filterFields={[
                  {
                    Sorting: [
                      {value: '', label: 'Tanggal', dbColumn: 'logdate'},
                    ],
                  },
                ]}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            }
            searchComponent={
              <VmInput
                value={cari}
                onChange={e => setCari(e.target.value)}
                type="text"
                className={searchInputStyle}
                placeholder="Cari Menu"
                enterKeyHint="search"
                onKeyDown={onSubmitSearch}
              />
            }
            detailAttr={{
              button: true,
              buttonLabel: 'Detail',
              buttonAction: val => {
                return modalRef?.current?.open(
                  <>
                    <VmView className="px-2 py-4">
                      <VmView className="flex flex-row-reverse">
                        <VmIcons
                          name="VmXMarkIcon"
                          size={22}
                          color="#494949"
                          onClick={() => modalRef?.current?.close()}
                        />
                      </VmView>
                    </VmView>
                    {/* Section Modal Item */}
                    {val?.log_after || true ? (
                      <>
                        <VmText className="text-black1-payment font-semibold">
                          Before
                        </VmText>
                        {handleLogBeforAfter(
                          val?.log_before || {},
                          val?.log_after || {}
                        )}
                        <VmText className="text-black1-payment font-semibold mt-4">
                          After
                        </VmText>
                        {handleLogBeforAfter(
                          val?.log_after || {},
                          val?.log_before || {}
                        )}
                      </>
                    ) : (
                      <VmText className="text-black1-payment text-center mt-4">
                        Tidak ada data
                      </VmText>
                    )}
                  </>
                )
              },
            }}
          />
        </VmPulltoRefresh>

        {/* Section Modal */}
        <VmModal ref={modalRef} />
      </>
    )
  }
}
