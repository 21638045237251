import React, {useCallback, useEffect, useState} from 'react'
import axios from 'axios'
import qs from 'qs'
import moment from 'moment'
import {useSearchParams, useNavigate} from 'react-router-dom'

import {VmText, VmView} from 'components'
import {ReportMobile} from 'template'
import {ProgressFullScreen, VmPulltoRefresh} from 'molecules'
import {
  dataFields,
  dataVitals,
  filterFields,
  headerFields,
  imageComponent,
} from './fields'
import {
  persistPageState,
  getPersistentPageState,
  clearPersistedPageState,
} from 'utils'
import Item from './Item'
import Alert from 'template/Form/AlertForm'
import satusehatminiicon from '../../assets/satusehaticon.ico'
import {defaultpropsalertform} from 'entries/alertform'
import {APISATSET} from 'config/apiurl'

export default function MobLapRekMed(props) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dctid = searchParams.get('dctid')
  const prevState = getPersistentPageState()

  const initialApiParams = {
    reg: 'dbdok',
    limit: 10,
    offset: 0,
    dateStart: new Date(moment().format('YYYY-MM-01')),
    dateEnd: new Date(),
    dateType: 2,
    bulan: moment().format('YYYY-MM-01'),
    a: 'dk13',
    uid: props?.dctid ?? dctid,
    sort: 'rmtgl DESC',
  }
  const initialFilter = {
    filterFieldsValue: [
      {
        'Semua Periode': [
          {
            value: '',
            label: 'Berdasarkan Tahun',
            flag: 'tahun',
          },
          {
            value: '',
            label: 'Berdasarkan Bulan',
            flag: 'bulan',
          },
          {
            value: '',
            label: 'Berdasarkan Tanggal',
            flag: 'tanggal',
          },
        ],
      },
    ],
    currentOpenedFilter: 'Semua Periode',
    activeFilterHead: ['Semua Periode'],
    activeFilterHeadLabel: {
      'Semua Periode': `${moment(initialApiParams.dateStart).format(
        'MMM-YYYY'
      )}`,
    },
    dateFilterFlag: {bulan: true},
    sortIcon: '',
    activeFilter: {
      date: {
        bulan: new Date(initialApiParams.dateStart),
      },
      dateType: initialApiParams.dateType,
    },
    dateValue: {
      datePickerValue: new Date(initialApiParams.dateStart),
    },
  }

  // UI State
  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [Loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(prevState?.filter ?? initialFilter)
  const [cari, setCari] = useState('')
  const [count, setCount] = useState(0)
  const [custom, setcustom] = useState(''),
    [loading, setloading] = useState(false),
    [alertProps, setAlertProps] = useState(defaultpropsalertform),
    closeConfirm = () => setAlertProps(defaultpropsalertform)

  // Api State
  const [hasMore, setHasMore] = useState(true)
  const [apiParams, setApiParams] = useState(
    prevState?.apiParams ?? {...initialApiParams}
  )

  useEffect(() => {
    fetchData()
    if (prevState) clearPersistedPageState()
    // eslint-disable-next-line
  }, [])

  // eslint-disable-next-line
  const onCardItemClick = useCallback(data => {
    persistPageState({filter, apiParams})
    navigate(
      `/prd-tra-pemeriksaan-pasien/view?kid=${data.kunid}&pid=${data.pasid}&dctid=${dctid}`
    )
  })

  const handleError = (e = '') => {
      setError({e: e ?? '', message: 'Terjadi kesalahan saat mengambil data'})
    },
    SyncSatusehat = async () => {
      setloading(true)
      axios
        .post(
          `${APISATSET}/satusehat-kunjungan/sync-data-encounter`,
          qs.stringify({
            userid: dctid,
          })
        )
        .then(({data: res}) => {
          if (res?.status === 1) {
            setAlertProps({
              ...defaultpropsalertform,
              key: `${Date.now()}`,
              type: 1,
              title: 'Berhasil',
              position: 'fit',
              visible: true,
              children: <>Berhasil sinkron satusehat.</>,
              onConfirm: closeConfirm,
              onClose: closeConfirm,
              onCancel: closeConfirm,
            })
          } else {
            return setAlertProps({
              ...defaultpropsalertform,
              key: `${Date.now()}`,
              type: 3,
              position: 'fit',
              visible: true,
              children: (
                <>
                  {res?.message ||
                    '[S0] Terjadi kesalahan saat sinkron satusehat.'}
                </>
              ),
              onConfirm: closeConfirm,
              onClose: closeConfirm,
              onCancel: closeConfirm,
            })
          }
        })
        .catch(e => {
          return setAlertProps({
            ...defaultpropsalertform,
            key: `${Date.now()}`,
            type: 3,
            position: 'fit',
            visible: true,
            children: <>Terjadi kesalahan saat sinkron satusehat.</>,
            onConfirm: closeConfirm,
            onClose: closeConfirm,
            onCancel: closeConfirm,
          })
        })
        .finally(() => {
          setloading(false)
        })
    },
    AbSyncSatusehat = () => {
      return setAlertProps({
        ...defaultpropsalertform,
        key: `${Date.now()}`,
        type: 4,
        yesText: 'Ya',
        noText: 'Tidak',
        title: 'Sinkron SATUSEHAT',
        position: 'fit',
        visible: true,
        closeable: false,
        children: (
          <>Apakah anda yakin ingin melanjutkan proses sinkron satusehat?</>
        ),
        onConfirm: SyncSatusehat,
        onClose: closeConfirm,
        onCancel: closeConfirm,
      })
    }

  const fetchData = async (additionalParams = {}, initial = false) => {
    const prevParams = initial ? {} : apiParams
    const params = qs.stringify({...prevParams, ...additionalParams})
    try {
      setLoading(true)
      const {data} = await axios.post(
        process.env.REACT_APP_BASE_URL_DOKTER + '/prd-lap-rekammedis/index-mob',
        params
      )

      if (data.status === 1) {
        setData(data.data)
        setCount(data.count)
        setcustom(data.custom)
      } else {
        handleError()
      }
    } catch (e) {
      handleError(e)
    } finally {
      setLoading(false)
    }
  }

  const fetchMore = async () => {
    try {
      const params = qs.stringify({...apiParams, offset: data.length})
      const res = await axios.post(
        process.env.REACT_APP_BASE_URL_DOKTER + '/prd-lap-rekammedis/index-mob',
        params
      )

      const resData = res.data
      if (resData.status === 1) {
        if (!resData) {
          return setHasMore(false)
        }

        setData([...data, ...resData.data])

        if (resData.data.length < 10) {
          return setHasMore(false)
        }
      } else {
        handleError()
      }
    } catch (e) {
      handleError(e)
    }
  }

  const onChangeFilter = val => {
    let initialDate = {
      dateStart: val?.date?.start
        ? moment(val?.date?.start).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      dateEnd: val?.date?.end
        ? moment(val?.date?.end).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
    }

    if (val?.clear) initialDate = {dateStart: '', dateEnd: ''}
    let params = {...val, ...initialDate}

    if (val?.date?.bulan)
      params = {
        ...val,
        bulan: moment(val?.date?.bulan).format('YYYY-MM-DD'),
      }
    if (val?.date?.tahun)
      params = {
        ...val,
        tahun: moment(val?.date?.tahun).format('YYYY-MM-DD'),
      }

    const isDateEmpty =
      !val?.date?.bulan &&
      !val?.date?.tahun &&
      !val?.date?.bulan &&
      !val?.date?.date?.start &&
      !val?.date?.date?.end &&
      !val?.date?.start &&
      !val?.date?.end

    const finalParams = {
      ...initialApiParams,
      ...params,
      dateType: val?.dateType || '',
    }

    setApiParams(finalParams)
    setHasMore(true)
    fetchData(finalParams)
  }

  const onClearFilter = () => {
    const params = {
      ...initialApiParams,
      dateStart: '',
      dateEnd: '',
      dateType: '',
    }
    setApiParams(params)
    setHasMore(true)
    fetchData(params, true)
    setCari('')
  }

  const onSubmitSearch = e => {
    if (e.key === 'Enter') {
      setApiParams({...apiParams, cari: e.target.value})
      fetchData({cari: e.target.value})
    }
  }

  const onRefresh = () => {
    setFilter(prevState?.filter || initialFilter)
    setCari('')
    setApiParams(initialApiParams)
    setHasMore(true)
    fetchData(initialApiParams, true)
  }
  const customRender = (item, i) => {
    return (
      <Item
        headerFields={headerFields}
        item={item}
        key={i}
        index={i}
        fields={dataFields}
        vitals={dataVitals}
        onCardItemClick={onCardItemClick}
        imageComponent={imageComponent}
        button={true}
        buttonLabel={'Detail'}
        buttonAction={onCardItemClick}
        buttonAdditionalProps={{variant: 'outline', color: 'primary'}}
        custom={custom}
      />
    )
  }
  const handleBackToRN = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage('onBackPress:')
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      {Loading ? (
        <ProgressFullScreen visible={Loading} className="!z-[100]" />
      ) : data ? (
        <VmPulltoRefresh onRefresh={onRefresh}>
          <ReportMobile
            data={data}
            headerFields={headerFields}
            dataFields={dataFields}
            withDetail
            filterComponentAttr={{
              filterValue: filter,
              filterHandler: setFilter,
              filterFields: filterFields,
              onChange: onChangeFilter,
              onClear: onClearFilter,
            }}
            customRender={customRender}
            searchComponentAttr={{
              value: cari,
              onChange: e => setCari(e.target.value),
              onKeyDown: onSubmitSearch,
              placeholder: 'Cari Nama / RM / Keluhan / Diganosa',
            }}
            imageComponent={imageComponent}
            hasMore={hasMore}
            loadMore={fetchMore}
            onCardItemClick={onCardItemClick}
            empty={count <= 0 && !Loading && !error}
            count={count}
            withStatus={false}
            detailAttr={{
              button: true,
              buttonLabel: 'Detail',
              buttonAction: onCardItemClick,
            }}
            headerAttr={{
              light: true,
              enable: true,
              title: 'Laporan Rekam Medis',
              onClick: handleBackToRN,
            }}
            exportApiUrl={
              process.env.REACT_APP_BASE_URL_DOKTER +
              '/prd-lap-rekammedis/export'
            }
            exportApiParams={{
              ...initialApiParams,
              ...apiParams,
              ...cari,
              uid: dctid,
            }}
            classNameANCHORHeader="pt-2"
            classNameANCHORContent=""
            footerDividerVisible={false}
            loading={loading}
            sideDownloadComponent={
              <>
                {/* Tombol Sync All yang belum punya id SatuSehat */}
                <VmView
                  onClick={AbSyncSatusehat}
                  className="sticky flex justify-center items-center ml-2 min-h-[2.5rem] min-w-[2.7rem] h-[2.5rem] w-[2.7rem] bg-white border-2 border-solid border-green-500 rounded-xl cursor-pointer"
                >
                  <img
                    width={24}
                    src={satusehatminiicon}
                    alt="satusehat-icon"
                    className=""
                  />
                </VmView>
              </>
            }
          />
        </VmPulltoRefresh>
      ) : (
        <VmView className="flex flex-col p-4 gap-4 justify-center items-center">
          <VmText className="font-bold text-slate-500">
            {error || 'Data tidak ditemukan!'}
          </VmText>
        </VmView>
      )}
      <Alert {...alertProps} />
    </>
  )
}
