import {VmView} from 'components'
import React, {useState, useEffect} from 'react'
import moment from 'moment'

export default function Index({data}) {
  const dateline = String(data.deadline)
  const datestart = String(data.date)

  const datelineFormat = 'DD-MM-YY HH:mm'
  const datestartFormat = 'D MMM YYYY'

  const datelineFix = moment(dateline, datelineFormat)
  const datestartFix = moment(datestart, datestartFormat)

  const newdateline = datelineFix.toISOString()
  const newdatestart = datestartFix.toISOString()

  const daterange = () => {
    const startDateObj = new Date(newdatestart)
    const endDateObj = new Date(newdateline)
    const diffTime = Math.abs(endDateObj - startDateObj)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
  }

  return (
    <>
      <VmView className="flex flex-row">
        <VmView className="flex basis-1/2">
          <p className="text-blue-500">Uang Muka</p>
        </VmView>
        <VmView className="flex basis-1/2 text-right text-gray-500">
          <p className="w-full">{data.dp}</p>
        </VmView>
      </VmView>
      <VmView className="flex flex-row">
        <VmView className="flex basis-1/2">
          <p className="text-blue-500">Jatuh Tempo</p>
        </VmView>
        <VmView className="flex basis-1/2 text-right text-gray-500">
          <p className="w-full">
            {moment(new Date(datelineFix)).format('DD MMM YYYY') +
              ` ( ${daterange()} hari )`}
          </p>
        </VmView>
      </VmView>
    </>
  )
}
