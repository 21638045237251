import classNames from 'classnames'
import {VmText, VmView} from 'components'
import {Fragment} from 'react'
import InputType1 from 'template/Form/InputType1'
import {calcAge} from 'utils'
import {tglYMD, toCurrency} from 'utils/functions'
const lstAdditionalButtonsConfig = {
  search: [
    'VmMagnifyingGlassIcon',
    'outline',
    '#FFFFFF',
    'bg-yellow2-payment !max-h-[2.5rem] !max-w-[2.5rem] rounded-xl !p-3',
    '3',
  ],
}
const InputEtiket = props => {
  const {textColor, bgcolors} = props || {
    textColor: 'text-white',
    bgcolors: '!bg-white',
  }
  return (
    <VmView className={'grid grid-cols-3'}>
      <VmView className="col-span-2 flex">
        <InputType1
          im={{
            type: 1,
            title: 'Aturan Pakai',
            placeholder: '-',
            label: 'input-etiket',
            field: 'etiket',
            typeInput: 'text',
            maxLength: 255,
            usebtnpicker: true,
            readonly: true,
            classNameInput: 'text-prevent-select text-xs !max-h-10',
            classNameWinput: '!rounded-xl',
            className: '!w-full',
          }}
          data={props?.data || {}}
          textColor={textColor}
          bgcolors={bgcolors}
        />
        <VmView
          className={classNames(
            textColor,
            'flex flex-col py-2 justify-center items-center text-sm font-semibold'
          )}
        >
          <VmView>&nbsp;</VmView>
          <VmView>&nbsp;x&nbsp;</VmView>
        </VmView>
      </VmView>
      <InputType1
        im={{
          type: 1,
          title: <>&nbsp;</>,
          placeholder: 'Sehari',
          label: 'input-etiket-type',
          field: 'etiket-type',
          typeInput: 'text',
          maxLength: 255,
          readonly: true,
          classNameInput: 'text-prevent-select text-xs !max-h-10 !text-center',
          classNameWinput: '!rounded-xl',
        }}
        textColor={textColor}
        bgcolors={bgcolors}
        data={{'etiket-type': 'Sehari'}}
      />
    </VmView>
  )
}
const InputEtiketTime = props => {
  if (props?.data?.etiket <= 0) {
    return <></>
  }
  const data = 'Waktu,'.repeat(props?.data?.etiket || 0)
  const {textColor, bgcolors} = props || {
    textColor: 'text-white',
    bgcolors: '!bg-white',
  }
  return (
    <VmView className={'relative w-auto mx-2 flex flex-row overflow-x-scroll'}>
      {data.split(',').map((time, idxtime) => {
        const timetitle = `${time} ${idxtime + 1}`
        if (!time) {
          return <Fragment key={timetitle} />
        }
        return (
          <InputType1
            key={timetitle}
            im={{
              type: 1,
              title: timetitle,
              placeholder: timetitle,
              label: `input-${timetitle}`.replace(' ', ''),
              field: `${timetitle}`.replace(' ', ''),
              typeInput: 'time',
              classNameInput:
                'text-prevent-select text-xs !max-h-10 !text-center',
              classNameWinput: '!rounded-xl',
              className: '!min-w-fit !p-0 !py-2 !pr-4',
            }}
            textColor={textColor}
            bgcolors={bgcolors}
            data={props?.data || {}}
          />
        )
      })}
    </VmView>
  )
}
const viewAge = props => {
  if (!props?.data?.pastgllahir) {
    return <></>
  }
  const {textColor, bgcolors} = props || {
    textColor: 'text-white',
    bgcolors: '!bg-white',
  }
  return (
    <VmView>
      <InputType1
        im={{
          type: 1,
          title: <>Umur&nbsp;</>,
          placeholder: 'Umur',
          label: 'input-age',
          field: 'age',
          typeInput: 'text',
          maxLength: 255,
          readonly: true,
          classNameInput: 'text-prevent-select text-xs !max-h-10',
          classNameWinput: '',
        }}
        textColor={textColor}
        bgcolors={bgcolors}
        data={{age: calcAge(props?.data?.pastgllahir)}}
      />
    </VmView>
  )
}
const viewInfoGolDrh = props => {
  return (
    <VmText className="!text-[0.5rem] px-2 pb-2 text-black2-payment">
      | A | B | AB | O [+-]
    </VmText>
  )
}
const inputPatient = {
    type: 1,
    title: 'Pasien',
    placeholder: 'Pilih Pasien',
    label: 'input-pasien-name',
    field: 'pasien-name',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    readonly: true,
    classNameInput: 'text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
    additionalButtons: ['search'],
  },
  inputApotekName = {
    type: 1,
    title: 'Apotek/Klinik',
    placeholder: 'Pilih Apotek/Klinik',
    label: 'input-aptkl-name',
    field: 'aptkl-name',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    readonly: true,
    classNameInput: 'text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
    additionalButtons: ['search'],
  },
  inputResep = {
    type: 1,
    title: 'No. Resep',
    placeholder: 'No. Resep',
    label: 'input-rcpno-name',
    field: 'rcpno-name',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    classNameInput: '!uppercase text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
    className: '!min-w-[13.5rem]',
  },
  inputDoktetName = {
    type: 1,
    title: 'Dokter',
    placeholder: 'Pilih Dokter',
    label: 'input-doct-name',
    field: 'doct-name',
    typeInput: 'text',
    maxLength: 255,
    readonly: true,
    disabled: true,
    classNameInput: 'text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
    className: '!min-w-[13.5rem]',
  },
  inputWarehouse = {
    type: 1,
    title: 'Gudang',
    placeholder: 'Nama Gudang',
    label: 'input-wh-name',
    field: 'wh-name',
    typeInput: 'text',
    maxLength: 255,
    readonly: true,
    disabled: true,
    classNameInput: '!uppercase text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
    className: '!min-w-[13.5rem]',
  },
  inputVisiting = {
    type: 1,
    title: 'No Kunjungan',
    placeholder: 'No Kunjungan',
    label: 'input-kunno',
    field: 'kunno',
    typeInput: 'text',
    maxLength: 255,
    readonly: true,
    disabled: true,
    classNameInput: '!uppercase text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
    className: '!min-w-[13.5rem]',
  },
  // Edit Obat
  inputObatName = {
    type: 1,
    title: 'Nama Obat',
    placeholder: 'Nama Obat',
    label: 'input-name',
    field: 'name',
    typeInput: 'text',
    maxLength: 255,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
  },
  inputObatCode = {
    type: 1,
    title: 'Kode Obat',
    placeholder: 'Kode Obat',
    label: 'input-code',
    field: 'code',
    typeInput: 'text',
    maxLength: 255,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
  },
  inputObatUnit = {
    type: 9,
    // v11 v12 v21 default v11
    variant: 'v12',
    input1: {
      title: 'Jumlah',
      placeholder: '0',
      label: 'input-qty',
      field: 'qty',
      typeInput: 'text',
      maxLength: 255,
      formatValue: toCurrency,
      // usebtnremove: false,
      // readonly: true,
      classNameInput: 'text-xs !max-h-10',
      classNameWinput: '!rounded-xl',
    },
    input2: {
      title: 'Satuan',
      placeholder: 'Pilih Satuan',
      label: 'input-satuan',
      field: 'satuan',
      typeInput: 'text',
      maxLength: 255,
      usebtnpicker: true,
      readonly: true,
      classNameInput: 'text-xs !max-h-10',
      classNameWinput: '!rounded-xl',
    },
  },
  inputObatPriceType = {
    type: 1,
    title: 'Pilih Harga',
    placeholder: 'Pilih Harga',
    label: 'input-price-type',
    field: 'price-type',
    typeInput: 'text',
    maxLength: 255,
    usebtnpicker: true,
    readonly: true,
    visible: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputObatPrice = {
    type: 1,
    title: 'Harga',
    placeholder: 'Harga',
    label: 'input-price',
    field: 'price',
    typeInput: 'text',
    maxLength: 255,
    formatValue: toCurrency,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputObatDisc = {
    type: 9,
    // v11 v12 v21 default v11
    variant: 'v12',
    input1: {
      title: 'Diskon',
      placeholder: '0%',
      label: 'input-disc',
      field: 'disc',
      typeInput: 'text',
      formatValue: num => `${toCurrency(num, num >= 100 ? 0 : 2)}%`,
      maxLength: 8,
      // readonly: true,
      classNameInput: 'text-xs !max-h-10',
    },
    input2: {
      title: 'Nominal Diskon',
      placeholder: 'Nominal Diskon',
      label: 'input-numdisc',
      field: 'numdisc',
      typeInput: 'text',
      formatValue: toCurrency,
      maxLength: 255,
      // readonly: true,
      classNameInput: 'text-xs !max-h-10',
    },
  },
  inputObatTotal = {
    type: 1,
    title: 'Total',
    placeholder: 'Total',
    label: 'input-total',
    field: 'total',
    typeInput: 'text',
    maxLength: 255,
    formatValue: toCurrency,
    // usebtnremove: true,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputObatSediaan = {
    type: 1,
    title: 'Bentuk Sediaan',
    placeholder: 'Bentuk Sediaan',
    label: 'input-bentuk',
    field: 'bentuk',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
  },
  inputObatNotes = {
    type: 1,
    title: 'Catatan',
    placeholder: 'Catatan',
    label: 'input-notes',
    field: 'notes',
    typeInput: 'textarea',
    maxLength: 255,
    classNameInput: 'text-xs !h-20 resize-none',
    classNameWinput: '!rounded-xl',
  },
  // Add Pasien
  inputPasNoRm = {
    type: 1,
    title: 'No. RM',
    value: 'No. RM Terisi Otomatis',
    placeholder: 'No. RM Terisi Otomatis',
    label: 'input-pasrm',
    field: 'pasrm',
    typeInput: 'text',
    maxLength: 255,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasNama = {
    type: 1,
    title: 'Nama',
    placeholder: 'Nama Pasien',
    label: 'input-pasnama',
    field: 'pasnama',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasNoWa = {
    type: 1,
    title: 'No. WA',
    placeholder: 'No. WA Pasien',
    label: 'input-pastelp',
    field: 'pastelp',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasGolDrh = {
    type: 1,
    title: 'Golongan Darah',
    placeholder: 'Golongan Darah Pasien',
    label: 'input-pasgoldarah',
    field: 'pasgoldarah',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10 !uppercase',
  },
  inputInfoGolDrh = {
    type: 0,
    Component: viewInfoGolDrh,
  },
  inputPasJk = {
    type: 1,
    title: 'Jenis Kelamin',
    placeholder: 'Jenis Kelamin Pasien',
    label: 'input-pasjk',
    field: 'pasjk',
    typeInput: 'text',
    maxLength: 255,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasBorn = {
    type: 1,
    title: 'Tanggal Lahir',
    placeholder: 'Tanggal Lahir Pasien',
    label: 'input-pastgllahir',
    field: 'pastgllahir',
    typeInput: 'date',
    max: tglYMD(),
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10 !text-left',
  },
  inputAge = {
    type: 0,
    Component: viewAge,
  },
  inputPasAddress = {
    type: 1,
    title: 'Alamat',
    placeholder: 'Alamat Pasien',
    label: 'input-pasalamat',
    field: 'pasalamat',
    typeInput: 'textarea',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-20',
  },
  inputPasCity = {
    type: 1,
    title: 'Kota',
    placeholder: 'Kota Pasien',
    label: 'input-paskota',
    field: 'paskota',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasMap = {
    type: 7,
    title: 'Pilih Titik Alamat',
    label: 'input-pasmap',
    field: 'pasmap',
    classNameInput: 'text-xs !max-h-10',
    mapprops: {
      zoom: 12,
      showCurrentLocation: false,
      nearbysearch: false,
      useSelectMapOnClick: true,
      containerStyle: {
        width: 'auto',
        height: '10rem',
      },
      nearbysearchoptions: {
        type: undefined,
      },
      searchboxoptions: {
        types: [
          'accounting',
          'airport',
          'amusement_park',
          'aquarium',
          'art_gallery',
          'atm',
          'bakery',
          'bank',
          'bar',
          'beauty_salon',
          'bicycle_store',
          'book_store',
          'bowling_alley',
          'bus_station',
          'cafe',
          'campground',
          'car_dealer',
          'car_rental',
          'car_repair',
          'car_wash',
          'casino',
          'cemetery',
          'church',
          'city_hall',
          'clothing_store',
          'convenience_store',
          'courthouse',
          'dentist',
          'department_store',
          'doctor',
          'drugstore',
          'electrician',
          'electronics_store',
          'embassy',
          'fire_station',
          'florist',
          'funeral_home',
          'furniture_store',
          'gas_station',
          'gym',
          'hair_care',
          'hardware_store',
          'hindu_temple',
          'home_goods_store',
          'hospital',
          'insurance_agency',
          'jewelry_store',
          'laundry',
          'lawyer',
          'library',
          'light_rail_station',
          'liquor_store',
          'local_government_office',
          'locksmith',
          'lodging',
          'meal_delivery',
          'meal_takeaway',
          'mosque',
          'movie_rental',
          'movie_theater',
          'moving_company',
          'museum',
          'night_club',
          'painter',
          'park',
          'parking',
          'pet_store',
          'pharmacy',
          'physiotherapist',
          'plumber',
          'police',
          'post_office',
          'primary_school',
          'real_estate_agency',
          'restaurant',
          'roofing_contractor',
          'rv_park',
          'school',
          'secondary_school',
          'shoe_store',
          'shopping_mall',
          'spa',
          'stadium',
          'storage',
          'store',
          'subway_station',
          'supermarket',
          'synagogue',
          'taxi_stand',
          'tourist_attraction',
          'train_station',
          'transit_station',
          'travel_agency',
          'university',
          'veterinary_care',
          'zoo',
        ],
      },
    },
  },
  inputPasStatusMrd = {
    type: 1,
    title: 'Status Perkawinan',
    placeholder: 'Status Perkawinan Pasien',
    label: 'input-passtatusnikah',
    field: 'passtatusnikah',
    typeInput: 'text',
    maxLength: 255,
    readonly: true,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasKerja = {
    type: 1,
    title: 'Pekerjaan',
    placeholder: 'Pekerjaan Pasien',
    label: 'input-paspekerjaan',
    field: 'paspekerjaan',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasIbu = {
    type: 1,
    title: 'Nama Ibu',
    placeholder: 'Nama Ibu Pasien',
    label: 'input-pasibu',
    field: 'pasibu',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasAyah = {
    type: 1,
    title: 'Nama Ayah',
    placeholder: 'Nama Ayah Pasien',
    label: 'input-pasayah',
    field: 'pasayah',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasKK = {
    type: 1,
    title: 'No. KK',
    placeholder: 'No. KK Pasien',
    label: 'input-pasnokk',
    field: 'pasnokk',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasKTP = {
    type: 1,
    title: 'NIK / No. KTP',
    placeholder: 'NIK / No. KTP Pasien',
    label: 'input-pasnoktp',
    field: 'pasnoktp',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasEmail = {
    type: 1,
    title: 'Email',
    placeholder: 'Email Pasien',
    label: 'input-pasemail',
    field: 'pasemail',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  inputPasAlergiObt = {
    type: 1,
    title: 'Alergi Obat',
    placeholder: 'Alergi Obat',
    label: 'input-pasalergi',
    field: 'pasalergi',
    typeInput: 'text',
    maxLength: 255,
    classNameInput: 'text-xs !max-h-10',
  },
  // Add Racikan
  inputRckKodeObt = {
    type: 1,
    title: 'Kode Obat Racikan',
    placeholder: 'Kode Obat Racikan',
    label: 'input-kode',
    field: 'kode',
    typeInput: 'text',
    maxLength: 255,
    readonly: true,
    classNameInput: 'text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
  },
  inputRckNamaObt = {
    type: 1,
    title: 'Nama Obat Racikan',
    placeholder: 'Nama Obat Racikan',
    label: 'input-obat-name',
    field: 'obat-name',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    classNameInput: 'text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
  },
  inputRckKat = {
    type: 1,
    title: 'Kategori',
    placeholder: 'Pilih Kategori',
    label: 'input-kategori-name',
    field: 'kategori-name',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    readonly: true,
    classNameInput: 'text-prevent-select text-xs !max-h-10',
    classNameWinput: '!rounded-xl',
    additionalButtons: ['search'],
  },
  inputEtiket = {
    type: 1,
    title: 'Aturan Pakai',
    placeholder: 'Aturan Pakai',
    label: 'input-etiket',
    field: 'etiket',
    typeInput: 'textarea',
    maxLength: 255,
    classNameInput: 'text-xs !h-20 resize-none',
    classNameWinput: '!rounded-xl',
  },
  inputEtiketRacikan = {
    type: 0,
    Component: InputEtiket,
  },
  inputEtiketTime = {
    type: 0,
    Component: InputEtiketTime,
  },
  inputEtiketConditions = {
    type: 9,
    // v11 v12 v21 default v11
    variant: 'v21',
    input1: {
      title: 'Syarat',
      placeholder: 'Pilih Syarat',
      label: 'input-conditions',
      field: 'conditionsTypeTitle',
      typeInput: 'text',
      maxLength: 255,
      usebtnpicker: true,
      readonly: true,
      classNameInput: 'text-prevent-select text-xs !max-h-10',
      classNameWinput: '!rounded-xl',
    },
    input2: {
      title: <>&nbsp;</>,
      placeholder: 'Condition',
      label: 'input-condition-type',
      field: 'condition-type',
      typeInput: 'text',
      maxLength: 255,
      readonly: true,
      classNameInput: 'text-prevent-select text-xs !max-h-10 !text-center',
      classNameWinput: '!rounded-xl',
    },
  }

const inputCardCustomer = {
    key: '',
    title: '',
    lstinput: [inputPatient, inputApotekName],
  },
  inputRcpDocWh = {
    key: '',
    title: '',
    lstinput: [inputResep, inputDoktetName, inputWarehouse],
  },
  inputEditObat = {
    key: '',
    title: '',
    lstinput: [
      inputObatCode,
      inputObatName,
      inputObatUnit,
      inputEtiket,
      inputObatSediaan,
      inputEtiketConditions,
      inputObatNotes,
    ],
  },
  inputEditRacikan = {
    key: '',
    title: '',
    lstinput: [inputEtiketRacikan, inputEtiketTime, inputEtiketConditions],
  },
  addPasien = {
    key: '',
    title: '',
    lstinput: [
      inputPasNoRm,
      inputPasKK,
      inputPasKTP,
      inputPasNama,
      inputPasBorn,
      {type: -2},
      inputPasNoWa,
      inputPasGolDrh,
      inputInfoGolDrh,
      inputPasJk,
      inputAge,
      inputPasAddress,
      inputPasCity,
      inputPasMap,
      inputPasStatusMrd,
      inputPasKerja,
      inputPasIbu,
      inputPasAyah,
      inputPasEmail,
      inputPasAlergiObt,
    ],
  },
  inputRacikanInf = {
    key: '',
    title: '',
    lstinput: [
      inputRckKodeObt,
      inputRckNamaObt,
      inputRckKat,
      inputEtiketRacikan,
      // inputEtiketTime,
      // inputEtiketConditions,
    ],
  }
const replacer = {
  obatnama: '##obatnama##',
  stok: '##stok##',
  satuan: '##satuan##',
}
const hdr = {
  save: `Konfirmasi perubahan transaksi`,
  delete: `Konfirmasi perubahan transaksi`,
  warn: `Peringatan`,
}
const msg = {
  save: `Apakah anda yakin untuk melakukan perubahan pada penjualan obat (##obatnama##)?`,
  delete: `Apakah anda yakin akan menghapus item (##obatnama##)?`,
  back: `Apakah anda yakin tidak menyimpan perubahan?`,
  overStok: `Stok tidak mencukupi (##obatnama##)!`,
  useAllStok: `Apakah anda yakin akan menggunakan semua stok (##obatnama##)?`,
  removeOnCart: `Apakah anda yakin akan menghapus (##obatnama##) dari keranjang?`,
  failGetHrg: `Gagal mengambil informasi harga satuan ##satuan##!`,
  percentageMax: `Persentase diskon tidak boleh lebih dari 100%!`,
  percentageMin: `Persentase diskon tidak boleh kurang dari 0%!`,
  qtyMin: `Jumlah tidak boleh kurang dari 0!`,
  lockDiskon: `Apakah anda yakin akan mengunci diskon?`,
}
const conditionlist = [
    {title: '-', type: 0},
    {title: 'Sebelum', type: 1},
    {title: 'Sesudah', type: 2},
    {title: 'Bersama', type: 3},
  ],
  etiketlist = [
    {title: '-', type: 0},
    {title: '1 x Sehari', type: 1},
    {title: '2 x Sehari', type: 2},
    {title: '3 x Sehari', type: 3},
    {title: '4 x Sehari', type: 4},
    {title: '5 x Sehari', type: 5},
  ],
  genderlist = [
    {type: 0, title: 'Perempuan'},
    {type: 1, title: 'Laki-laki'},
  ],
  statusmeriedlist = [
    {type: 0, title: 'Belum Kawin'},
    {type: 1, title: 'Sudah Kawin'},
    {type: 2, title: 'Janda'},
    {type: 3, title: 'Duda'},
  ]
export {
  replacer,
  hdr,
  msg,
  inputCardCustomer,
  inputEditObat,
  inputEditRacikan,
  inputRacikanInf,
  lstAdditionalButtonsConfig,
  inputRcpDocWh,
  addPasien,
  conditionlist,
  etiketlist,
  genderlist,
  statusmeriedlist,
  inputVisiting,
}
