import {API, graphqlOperation} from 'aws-amplify'

const aksesServer = async ({epoin, data}) =>
  API.graphql(graphqlOperation(epoin, data))

const successHandler = (postData, navigete, body) => {
  const {key} = postData
  switch (key) {
    default:
      return body
  }
}

const actionServer = async (postData, navigete, num = 0) =>
  aksesServer(postData)
    .then(res => {
      // console.log('res', res)
      const newres = res.data[postData._ldata]
      const body = JSON.parse(newres.body)
      const message =
        body?.message || '[RS0] Gagal terhubung, silakan periksa koneksi anda!'
      const passed1 = newres.statusCode === 200
      if (!!passed1) {
        return successHandler(postData, navigete, {...body, message})
      } else {
        if (postData?.useAlert) {
          alert(message)
        }
        return false
      }
    })
    .catch(err => {
      if (num < 5) {
        // console.log(err, 'err')
        return actionServer(postData, navigete, num + 1)
      }
      return false
    })

export {aksesServer, actionServer, successHandler}
