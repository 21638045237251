import {VmButton, VmText, VmView} from 'components'
import {VmHeader} from 'molecules'
import {useState, useEffect, createRef} from 'react'
import {Page500} from 'pages'
import axios from 'axios'
import qs from 'qs'
import {useParams, useNavigate} from 'react-router-dom'
import {toCurrency} from 'utils/functions'
import colors from 'config/colors'

export default function Index() {
  const baseUrl = process.env.REACT_APP_BASE_URL_DOKTER
  const params = useParams()
  const idPenjualan = parseInt(params?.id)
  const textHeading = 'text-xs font-[600] text-[#3B4054]'
  const textContent = 'text-xs text-[#3B4054]'

  const [data, setData] = useState('')
  const [cart, setCart] = useState([])

  const clrStatusBayar = data.pjdelivery == 1 ? '#ffc107' : '#42ba96'

  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const params = qs.stringify({
      id: idPenjualan,
    })
    try {
      const {data} = await axios.post(
        baseUrl + '/prd-lap-penjualan-resep/detail',
        params
      )

      if (data.status === 1) {
        setData(data.dataHead)
        setCart(data.dataDetail)
      } else {
        console.log('Failed get data! [0]')
      }
    } catch (e) {
      console.log('Failed get data! [1]', e)
    } finally {
    }
  }

  const statusLabel = {
    pjstatus: {1: 'Sudah diambil', 25: 'Belum diambil', 26: 'Dibatalkan'},
    pjdelivery: {1: 'Belum dibayar', 2: 'Sudah dibayar'},
  }

  const handleEtiket = str => {
    const arrEtiket = str
      .split(',')
      .map(item =>
        !item || item.trim() == 'null' || item.trim() == 'undefined'
          ? '-'
          : item
      )

    return `${arrEtiket[0]}, ${arrEtiket[1]}, ${arrEtiket[2]}`
  }

  return (
    <>
      <VmView className="fixed p-5 pb-0 top-0 left-0 right-0 z-50">
        <VmHeader
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Laporan Resep Dokter'}
        />
      </VmView>

      <VmView className="fixed pt-[5.3rem] md:pt-5 pb-[4.5rem] bg-blue4-payment p-5 h-screen left-0 right-0">
        <VmView className="overflow-y-auto h-full">
          {/* section header */}
          <VmView className="bg-white flex flex-col p-5 rounded-xl">
            <VmView className={textHeading}>Informasi Resep</VmView>

            {/* detail-item-wrapper */}
            <VmView className="flex flex-col mt-5">
              {/* detail-item-1 */}
              <VmView className="flex flex-row space-x-5">
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>Tanggal</VmView>
                  <VmView className={textContent}>{data.pjtanggal}</VmView>
                </VmView>
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>No. Faktur</VmView>
                  <VmView className={textContent}>{data.pjnofaktur}</VmView>
                </VmView>
              </VmView>
              {/* detail-item-2 */}
              <VmView className="flex flex-row mt-2 space-x-5">
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>No. Resep</VmView>
                  <VmView className={textContent}>{data.pjresep}</VmView>
                </VmView>
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>Nama Pasien</VmView>
                  <VmView className={textContent}>{data.pasnama}</VmView>
                </VmView>
              </VmView>
              {/* detail-item-3 */}
              <VmView className="flex flex-row mt-2 space-x-5">
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>No. WA</VmView>
                  <VmView className={textContent}>{data.pastelp || '-'}</VmView>
                </VmView>
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>Alamat Pasien</VmView>
                  <VmView className={textContent}>
                    {data.pasalamat || '-'}
                  </VmView>
                </VmView>
              </VmView>
              {/* detail-item-4 */}
              <VmView className="flex flex-row mt-2 space-x-5">
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>Status Pembayaran</VmView>
                  <VmView className={textContent}>
                    {data.pjstatus ? statusLabel.pjstatus[data.pjstatus] : '-'}
                  </VmView>
                </VmView>
                <VmView className="flex basis-1/2 flex-col">
                  <VmView className={textHeading}>Status Insentif</VmView>
                  <VmView className={textContent}>
                    {data.pjdelivery
                      ? statusLabel.pjdelivery[data.pjdelivery]
                      : '-'}
                  </VmView>
                </VmView>
              </VmView>
            </VmView>
          </VmView>

          {/* section item */}
          <VmView className="bg-white flex flex-col p-5 pb-0 rounded-xl mt-5 overflow-y-scroll h-[35%]">
            {cart.map(item => {
              const handleResep = (jumlahResep, jumlahAmbil, satuan) => {
                if (data.pjstatus == 26) return `(Tidak Diambil)`
                if (data.pjstatus == 25) return `(Belum Diambil)`

                if (jumlahAmbil == jumlahResep)
                  return `(Sudah Terpenuhi ${jumlahAmbil} ${satuan})`
                if (jumlahAmbil < jumlahResep)
                  return `(Terpenuhi Sebagian ${jumlahAmbil} ${satuan})`

                return `(Tidak Diambil)`
              }

              return (
                <VmView className="mb-4">
                  <VmView className="flex flex-row justify-between items-center mb-2">
                    <VmText className={textHeading}>
                      <span>R/ </span>
                      {item.pjdnama}
                    </VmText>
                  </VmView>

                  <VmView className="flex flex-row justify-between items-center">
                    <VmText className={textContent}>{`${item.pjdjumlahresep} ${
                      item.pjdsatuan || '-'
                    } ${handleResep(
                      item.pjdjumlahresep,
                      item.pjdjumlah,
                      item.pjdsatuan
                    )}`}</VmText>
                  </VmView>

                  <VmText className={textContent}>
                    <span className={textHeading}>Aturan Pakai : </span>
                    {item.pjdcatatan}
                  </VmText>
                </VmView>
              )
            })}
          </VmView>

          {/* section footer */}
          {data.pjstatus == 1 && (
            <VmView className="bg-white flex flex-col p-5 rounded-xl mt-5">
              <VmView className="md:grid md:grid-cols-2 md:gap-4">
                <VmView className="hidden md:block" />

                <VmView>
                  <VmView className="flex w-full flex-row font-semibold text-[#3B4054]">
                    <VmView className={`flex basis-1/2`}>Total</VmView>
                    <VmView className={`flex basis-1/2`}>
                      <VmView className={`w-full text-right`}>
                        {toCurrency(data.pjgrandtotal)}
                      </VmView>
                    </VmView>
                  </VmView>
                  <VmView className="flex w-full flex-row font-semibold text-[#3B4054]">
                    <VmView className={`flex basis-1/2`}>
                      {`Insentif Dokter (${toCurrency(data.pjfee)}%)`}
                    </VmView>
                    <VmView className={`flex basis-1/2`}>
                      <VmView className={`w-full text-right`}>
                        {toCurrency(data.pjfeetext)}
                      </VmView>
                    </VmView>
                  </VmView>
                </VmView>
              </VmView>
            </VmView>
          )}

          {/* section action button */}
          <VmView className="fixed bottom-0 left-0 right-0 p-5 bg-blue4-payment">
            <VmView className="flex flex-row items-center">
              <VmButton
                className="hidden md:block w-full mr-4"
                onClick={() => navigate(-1)}
                color="danger"
              >
                Kembali
              </VmButton>
              <VmButton
                className="w-full"
                onClick={() =>
                  navigate(
                    `/prd-lap-penjualan-obat-resep/cetak/${idPenjualan}/${params.uid}`
                  )
                }
              >
                Cetak
              </VmButton>
            </VmView>
          </VmView>
        </VmView>
      </VmView>
    </>
  )
}
