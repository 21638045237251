import {useNavigate, useParams} from 'react-router-dom'
import {VmButton, VmDataTable, VmIcons, VmText, VmView} from 'components'
import {useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import {mBatalPendaftaran, mDelete, qIndex} from './api'
import {useMemo} from 'react'
import useAddEventListener from 'utils/useAddEventListener'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import {ProgressFullScreen} from 'molecules'
import {PcareDataEntriPen} from './classes'
import {VmTrashIcon} from 'components/icons/outline'
import {getElement} from 'utils/get-element'
import moment from 'moment'
import { tglDashNoSpace } from 'utils/functions'

export default function WebDataEntriPendaftaran() {
  const navigate = useNavigate(),
    params = useParams(),
    globalRef = useRef({}).current
  const uid = params?.id,
    // const uid = 355,
    defaultfilter = {
      tglDaftar: tglDashNoSpace(new Date()),
      // tglDaftar: '18-03-2024',
      startLimit: '0',
      endLimit: '10',
    },
    errorMessage = '[C0] Gagal mengambil data!',
    [date, setdate] = useState(Date.now()),
    [newvariables, setnewvariables] = useState({
      uid,
      ...defaultfilter,
    }),
    [data, setdata] = useState([]),
    // [message, setmessage] = useState(''),
    [loading, setloading] = useState(true),
    [loadingmore, setloadingmore] = useState(true),
    [onloadingmore, setonloadingmore] = useState(false),
    [error, seterror] = useState(false),
    [confirmProps, setconfirmProps] = useState(defaultpropsalertform),
    [alertProps, setAlertProps] = useState(defaultpropsalertform)
  const fields = [
    {
      key: 'no',
      label: 'No.',
      sorter: false,
      filter: false,
      _style: {width: '5%', textAlign: 'left'},
    },
    {
      key: 'noUrut',
      label: 'No Urut',
      sorter: false,
      filter: false,
      _style: {width: '10%'},
    },
    {
      key: 'tglDaftar',
      label: 'Tanggal Daftar',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'noKartu',
      label: 'No Kartu',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'nama',
      label: 'Nama Peserta',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'jk',
      label: 'Jenis Kelamin',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'usia',
      label: 'Usia',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'polikegiatan',
      label: 'Poli/Kegiatan',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'status',
      label: 'Status',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
    {
      key: 'hapus',
      label: 'Hapus',
      sorter: false,
      filter: false,
      _style: {width: '20%'},
    },
  ]
  const LabelHeader = ({name = '', state = {}, SortIcon = null}) => {
    return (
      <div
        className={classNames(
          'flex flex-row items-center justify-between min-w-max text-prevent-select !text-sm'
        )}
      >
        {name}
        {/* {SortIcon} */}
      </div>
    )
  }
  const classNameFilter = classNames(
      'flex flex-1 grow bg-gray-100 text-sm',
      'border py-2 px-3 text-grey-darkest focus:outline-none',
      'focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
      'focus:ring-offset-gray-100 rounded-lg'
    ),
    classDatePicker = classNames(
      'text-prevent-select !text-xs',
      'flex gap-3 bg-white relative',
      'border-2 py-2 px-5 border-slate-600',
      'text-grey-darkest focus:outline-none',
      'focus:ring-2 focus:ring-slate-500 focus:ring-offset-2',
      'focus:ring-offset-slate-100 rounded-xl hover:border-blue-500',
      'justify-between items-center text-slate-700 font-bold cursor-pointer'
    ),
    today = moment().format('YYYY-MM-DD')
  const [dateperiode, setdateperiode] = useState({
    start: today,
    end: today,
  })

  const fetchData = args => {
    qIndex(args)
      .then(
        ({
          status = 0,
          response: resdata = {},
          message = null,
          metaData = {},
        }) => {
          // console.log('resdata', resdata)
          // console.log('metaData', metaData)
          if (metaData?.code == 200) {
            if (resdata?.count > 0) {
              // console.log('resdata?.list', resdata?.list)
              // localStorage.setItem(
              //   'dataBpjs_pendaftaran_provider',
              //   JSON.stringify(resdata?.list)
              // )
              setdata(resdata?.list || [])
            }
          } else {
            if (loadingmore) {
              seterror(metaData?.message || 'Tidak ada data')
              if (metaData?.message == 'NO_CONTENT') seterror('Tidak ada data')
            }
          }
          setloadingmore(false)
        }
      )
      .catch(error => {
        // console.log('error', error)
        seterror(errorMessage)
      })
      .finally(() => {
        setloadingmore(false)
        setonloadingmore(false)
        setloading(false)
      })
  }
  const deleteData = args => {
    mDelete(args)
      .then(res => {
        if (res?.metaData?.code == 200) {
          mBatalPendaftaran(args)
            .then(() => {})
            .catch(() => {})
          setAlertProps({
            ...defaultpropsalertform,
            type: 1,
            visible: true,
            children: (
              <>
                Berhasil Menghapus Data Pendaftaran Pcare{' '}
                {args?.noUrut ? `dengan No. Urut ${args?.noUrut}` : ''}
              </>
            ),
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        } else {
          setAlertProps({
            ...defaultpropsalertform,
            type: 4,
            visible: true,
            children: (
              <>
                {(res?.response?.message
                  ? res?.response?.message
                  : res?.metaData?.message) ||
                  `Gagal Menghapus Pendaftaran Pcare ${args?.noUrut ? `dengan No. Urut ${args?.noUrut}` : ''}`}
              </>
            ),
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        }
      })
      .catch(error => {
        // console.log('error', error)
        setAlertProps({
          ...defaultpropsalertform,
          type: 4,
          visible: true,
          children: (
            <>{'[C0] Gagal menghapus data, silakan coba beberapa saat lagi.'}</>
          ),
          onConfirm: closeAlert,
          onClose: closeAlert,
          onCancel: closeAlert,
        })
      })
      .finally(() => {})
  }
  const onSglGetValue = (id, type = '') => {
    const getElementId = document.getElementById(id)
    var value = ''

    if (getElementId) {
      if (type == 'text') {
        value = getElementId?.textContent
      } else {
        value = getElementId?.value
      }
    }
    return value
  }
  const onSorterValueChange = e => {
      // console.log('oke')
      //   setdate(Date.now())
    },
    onFilterValueChange = (key, values) => {
      const value = onSglGetValue('endLimit')
      let resMessage = false
      if (value > 15) {
        resMessage = 'Maksimal jumlah data yang ditampilkan adalah 15.'
      } else if (value == 0) {
        resMessage = 'Silakan mengisi jumlah data yang akan ditampilkan.'
      }

      if (resMessage) {
        setAlertProps({
          ...defaultpropsalertform,
          type: 4,
          visible: true,
          children: <>{resMessage}</>,
          onConfirm: closeAlert,
          onClose: closeAlert,
          onCancel: closeAlert,
        })
        return
      }

      setonloadingmore(true)
      setnewvariables({
        ...newvariables,
        endLimit: `${value}`.trim(),
        tglDaftar: moment(dateperiode.start).format('DD-MM-YYYY'),
      })
    },
    Absearch = () => {
      setdata([])

      onFilterValueChange('', '')
    },
    closeAlert = () => setAlertProps(defaultpropsalertform),
    closeConfirm = () => setconfirmProps(defaultpropsalertform),
    toggleDelete = index => {
      return setconfirmProps({
        ...alertProps,
        type: 2,
        title: 'Peringatan',
        position: 'fit',
        visible: true,
        children: (
          <>Apakah Anda yakin akan menghapus data entri pendaftaran ini?.</>
        ),
        onConfirm: () =>
          deleteData({
            uid,
            noKartu: newdata[index].noKartu,
            tglDaftar: moment(newdata[index]?.tglDaftar).format('DD-MM-YYYY'),
            noUrut: newdata[index].noUrut,
            kdPoli: newdata[index].kdPoli,
          }),
        onClose: closeConfirm,
        onCancel: closeConfirm,
      })
    }

  const onClick = e => {
    try {
      const {ariaLabel} = e?.target,
        lstactbtn = {
          'ab-search': Absearch,
        }

      if (typeof lstactbtn[ariaLabel] === 'function') {
        lstactbtn[ariaLabel]()
      }
      const lst = Object.keys(globalRef)
      if (lst?.length > 0) {
        // console.log('document.activeElement', document.activeElement)
        lst.map(im => im?.blur?.())
      }
    } catch (error) {}
  }
  useAddEventListener({eventName: 'click', handler: onClick})
  useEffect(() => {
    if (uid) {
      if (newvariables?.offset === 0) {
        setloading(true)
        setloadingmore(true)
      }
      seterror(null)
      fetchData(newvariables)
    }
    return () => {}
  }, [newvariables, uid]) /* eslint-disable-line */

  const newdata = useMemo(() => {
    return data.map(im => {
      const newim = new PcareDataEntriPen(im)
      return newim
    })
  }, [data])

  return (
    <VmView className="bg-white rounded-2xl p-5 useScrollBar">
      <VmView className="flex justify-center items-center pt-12 pb-6">
        <VmText className="text-lg text-slate-700 font-semibold">
          Data Entri Pendaftaran
        </VmText>
      </VmView>
      <VmView className="flex gap-4 flex-col-reverse">
        {/* Count Data */}
        <p className="pt-4 pb-6 text-slate-700 text-sm">{`Menampilkan ${
          data?.length || 0
        } data`}</p>
        {/* Action Buttons */}
        <VmView className="flex gap-3 items-center"></VmView>
        {/* Pencarian No Kartu */}
        <VmView className="flex gap-4 items-center text-sm">
          <VmView className="text-slate-700 font-semibold">Tanggal</VmView>
          <VmView className="relative">
            <VmView className={classDatePicker}>
              <VmIcons
                size={16}
                name="VmCalendarDaysIcon"
                className="stroke-[2.5]"
              />
              {moment(dateperiode.start).format('DD MMM YYYY')}
              <input
                type="date"
                aria-label="date-start-picker"
                className="absolute rounded-xl inset-0 bg-transparent text-transparent border-0 outline-0 text-center text-9xl input-date-picker-transparent"
                max={today}
                onChange={e => {
                  setdateperiode(pd => {
                    return {
                      ...pd,
                      start: e?.target?.value || moment().format('YYYY-MM-DD'),
                    }
                  })
                  // onFilterValueChange('tglDaftar', moment(e?.target?.value).format('DD-MM-YYYY'))
                }}
              />
            </VmView>
          </VmView>
          <VmView className="text-slate-700 font-semibold">Jumlah Data</VmView>
          <VmView className="relative">
            <VmView
              label="dtp-periode"
              className={classNames(
                'flex flex-1 grow bg-white',
                'border-2 border-slate-600',
                'text-grey-darkest focus:outline-none',
                'focus:ring-2 focus:ring-slate-500 focus:ring-offset-2',
                'focus:ring-offset-slate-100 rounded-xl',
                'flex justify-between items-center min-w-[10rem]',
                'text-slate-700 font-bold cursor-pointer hover:border-blue-500',
                'text-prevent-select !text-xs overflow-hidden'
              )}
            >
              <input
                className={classNames(classNameFilter, '!bg-white !py-1')}
                placeholder="Jumlah Data"
                id="endLimit"
                aria-label={`input-endLimit`}
                defaultValue={newvariables?.endLimit || ''}
                onBlur={e => {
                  // onFilterValueChange('endLimit', e?.target?.value)
                }}
                onKeyDownCapture={e => {
                  if (e.key === 'Enter') {
                    // onFilterValueChange('endLimit', e?.target?.value)
                  }
                }}
              />
            </VmView>
          </VmView>
          <VmButton
            className={
              'relative rounded-xl pr-8 pl-12 py-2 text-prevent-select !text-sm'
            }
            color="info"
            label="ab-search"
          >
            <VmIcons
              size={18}
              name="VmMagnifyingGlassIcon"
              className=" absolute left-5 stroke-[2.5]"
              variant="outline"
            />
            Cari
          </VmButton>
        </VmView>
      </VmView>
      <VmDataTable
        key={JSON.stringify({data, newvariables})}
        items={newdata}
        totalCount={newdata?.length}
        fields={fields}
        columnFilter={false}
        activeNumbers={false}
        activeActions={false}
        showDataCount={false}
        showOverTableActions={false}
        sorter={{resetable: true}}
        onSorterValueChange={onSorterValueChange}
        containerClass={classNames(
          'min-h-[30rem]'
          // data?.length > 0 ? '' : '!overflow-hidden'
        )}
        columnHeaderSlot={{
          noUrut: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          tglDaftar: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          noKartu: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          nama: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          jk: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          usia: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          polikegiatan: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
          hapus: (name, state, SortIcon) => (
            <LabelHeader state={state} SortIcon={SortIcon} name={name} />
          ),
        }}
        scopedSlots={{
          no: (item, idx) => (
            <td className="p-3 text-center align-top text-prevent-select !text-sm">
              {Number(idx) + 1}
            </td>
          ),
          noUrut: (item, idx) => (
            <td className="p-3 align-top text-prevent-select !text-sm">
              {item?.noUrut || ''}
            </td>
          ),
          tglDaftar: (item, idx) => (
            <td className="p-3 align-top text-prevent-select !text-sm">
              {item?.tglDaftar || ''}
            </td>
          ),
          noKartu: (item, idx) => (
            <td className="p-3 align-top text-prevent-select !text-sm">
              {item?.noKartu || ''}
            </td>
          ),
          nama: (item, idx) => (
            <td className="p-3 align-top text-prevent-select !text-sm">
              {item?.nama || ''}
            </td>
          ),
          jk: (item, idx) => (
            <td className="p-3 align-top text-prevent-select !text-sm">
              {item?.jk || ''}
            </td>
          ),
          usia: (item, idx) => (
            <td className="p-3 align-top text-prevent-select !text-sm">
              {item?.usia || ''}
            </td>
          ),
          polikegiatan: (item, idx) => (
            <td className="p-3 text-center align-top text-prevent-select !text-sm">
              {item?.polikegiatan || ''}
            </td>
          ),
          hapus: (item, idx) => {
            if (item.status == 'Sudah Dilayani') return <></>
            return (
              <td className="p-3 align-top">
                <VmView className="flex justify-center items-center w-fill-available gap-1">
                  <VmTrashIcon
                    height={`${24}px`}
                    width={`${24}px`}
                    className={classNames(
                      'rounded-md p-1 bg-red-500 text-white inline cursor-pointer'
                    )}
                    onClick={() => {
                      toggleDelete(idx)
                    }}
                  />
                </VmView>
              </td>
            )
          },
        }}
        noItemsViewSlot={
          <div className="relative h-[15rem]">
            <div className="flex flex-col items-center justify-center text-center absolute top-16 left-[20%] min-w-max">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="90"
                height="90"
                viewBox="0 0 90 90"
                fill="none"
              >
                <path
                  d="M33.75 7.5C40.7119 7.5 47.3887 10.2656 52.3116 15.1884C57.2344 20.1113 60 26.7881 60 33.75C60 39.6375 58.125 45 54.7875 49.4625L57.7875 52.5H60L82.5 75L75 82.5L52.5 60V57.7875L49.4625 54.7875C44.9351 58.1948 39.4163 60.0256 33.75 60C26.7881 60 20.1113 57.2344 15.1884 52.3116C10.2656 47.3887 7.5 40.7119 7.5 33.75C7.5 26.7881 10.2656 20.1113 15.1884 15.1884C20.1113 10.2656 26.7881 7.5 33.75 7.5ZM18.75 30V37.5H48.75V30H18.75Z"
                  fill="#EF4444"
                />
              </svg>
              <h2 className="flex flex-wrap px-1 justify-center text-slate-700 text-2xl font-bold ">
                {error || 'Tidak ada data'}
              </h2>
            </div>
          </div>
        }
      />
      <AlertForm {...alertProps} />
      <AlertForm {...confirmProps} />
      <ProgressFullScreen visible={loading || onloadingmore} />
    </VmView>
  )
}
