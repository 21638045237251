import {VmButton, VmIcons, VmText, VmView} from 'components'
import ErrorLayout from 'components/layout/ErrorLayout'
import {ProgressFullScreen, VmHeader} from 'molecules'

import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {fetchViewV2} from './api'
import moment from 'moment'
import {reportDateFormat, reportDateFormatNoTime} from 'config/dateFormat'
import {empty, ifempty, tglDDMMMYYYY, tglYMD} from 'utils/functions'
import vmedisicon from 'assets/vmedisicon.png'
import QueryString from 'qs'
import {exportToPdf} from 'utils'
import {exportToPdfFromHtml} from 'utils/export-to-pdf'
import {calcAgeYearOnly} from 'utils/calc-age'
const {REACT_APP_BASE_URL_DOKTER: APIDOC} = process.env

export default function Export() {
  const navigate = useNavigate()
  const {id} = useParams()
  const [data, setdata] = useState({
    rujid: 0,
    rujtanggal: '',
    rujno: '',
    rujuid: '',
    farid: 0,
    farnama: '',
    porid: 0,
    pornama: '',
    dorid: 0,
    dornama: '',
    rujtindakan: '',
    rujstatus: 0,
    pasnama: '',
    pasumur: 0,
    pasrm: '',
    pastgllahir: '',
    pasjk: '',
    pasalamat: '',
    icdkode: '',
    icdnama: '',
  })
  const [user, setUser] = useState({
    user_id: 0,
    kl_id: 0,
    app_id: 'dk13',
    username: '',
    nama_lengkap: '',
    email: '',
    user_nosip: '',
    user_nosip_en: '',
    user_noktp: '',
    user_noktp_en: '',
    alamat: '',
    provinsi: '277',
    kota: '2439',
    kecamatan: '34055',
    keterangan: '',
    user_nostr_en: '',
    user_wa: '085606021473',
    kecnama: '',
    kotanama: '',
  })
  const [loading, setloading] = useState(false)

  const handleInit = async () => {
    setloading(true)
    const resData = await fetchViewV2({rujid: id, reg: 'dbdok'})
    setloading(false)
    if (resData.status == 0) {
      // return seterror({e: true, ...resData})
    } else {
      // seterror(null)
      setdata({...resData?.data})
      setUser(resData?.user)
    }
  }

  useEffect(() => {
    handleInit()
    // getIdentitas()
    moment.locale('id')
  }, [])

  useEffect(() => {
    if (user.user_id != 0 && data.rujid != 0) {
      window.print()
    }
    return () => {}
  }, [user.user_id, data.rujid])

  const renderPreview = () => {
    return (
      <VmView className="p-[2.5cm] bg-white" id="cetak">
        <VmView className="flex justify-center">
          <img src={vmedisicon} className="w-16" />
        </VmView>
        <p className="text-base text-center font-bold mb-6 mt-2">
          Dokter Praktik Mandiri
        </p>

        <p className="text-base text-center font-bold mb-2">
          Surat Keterangan Rujukan
        </p>
        <p className="text-sm text-center mb-4">No. Surat : {data.rujno}</p>

        <p className="text-sm mb-2">Kepada Yth,</p>

        <VmView className="flex mb-2">
          <p className="text-sm flex-[0.4]">Nama Faskes</p>
          <p className="text-sm flex-[0.6]"> : {data.farnama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sm flex-[0.4]">Poli</p>
          <p className="text-sm flex-[0.6]"> : {data.pornama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sm flex-[0.4]">Dokter</p>
          <p className="text-sm flex-[0.6]"> : {data.dornama}</p>
        </VmView>

        <p className="text-sm ">Dengan Hormat,</p>
        <p className="text-sm mb-2">
          Bersama dengan surat ini, kami rujuk pasien
        </p>

        <VmView className="flex mb-2">
          <p className="text-sm flex-[0.4]">Nama</p>
          <p className="text-sm flex-[0.6]">: {data.pasnama}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sm flex-[0.4]">Umur</p>
          <p className="text-sm flex-[0.6]">
            : {calcAgeYearOnly(data.pastgllahir) + ' Tahun'}
          </p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sm flex-[0.4]">Tempat Tinggal</p>
          <p className="text-sm flex-[0.6]">: {data.pasalamat}</p>
        </VmView>

        <VmView className="flex mb-2">
          <p className="text-sm flex-[0.4]">Diagnosa</p>
          <p className="text-sm flex-[0.6]">
            : {data.icdkode} - {data.icdnama}
          </p>
        </VmView>

        <VmView className="flex mb-4">
          <p className="text-sm flex-[0.4]">Tindakan yang sudah diberikan</p>
          <p className="text-sm flex-[0.6]">: {data.rujtindakan}</p>
        </VmView>
        <p className="text-sm mb-4">
          Mohon pemeriksaan dan penanganan selanjutnya, Atas perhatian dan
          kerjasamanya, kami ucapkan terima kasih.
        </p>

        <p className="text-sm mb-16 text-right">
          {user.kotanama}, {tglDDMMMYYYY()}
        </p>
        <p className="text-sm mb-2 text-right font-bold">{user.nama_lengkap}</p>
      </VmView>
    )
  }

  return <div className="w-[210mm]">{renderPreview()}</div>
}
