import qs from 'qs'
import axios from 'axios'
import {APISATSET} from 'config/apiurl'

const defError = (
  code = 'EDF',
  message = 'Gagal saat mengambil data, silakan coba kembali!'
) => ({
  status: 0,
  message: `[${code}] ` + message,
})

export const mSubmitSetting = async args => {
  try {
    const {data} = await axios.post(
      APISATSET + '/pengaturan-satu-sehat/update-mob',
      qs.stringify(args)
    )

    if (data?.status == 1 || data?.status == 2) return data
    return defError('EPSS-CE-3', data?.message)
  } catch (e) {
    return defError(
      'EPSS-CE-4',
      'Gagal saat menyimpan data, silakan coba kembali!'
    )
  }
}

export const mDeleteSetting = async args => {
  try {
    const {data} = await axios.post(
      APISATSET + '/pengaturan-satu-sehat/delete-mob',
      qs.stringify(args)
    )

    if (data?.status == 1 || data?.status == 2) return data
    return defError('EPSS-CE-3', data?.message)
  } catch (e) {
    return defError(
      'EPSS-CE-4',
      'Gagal saat menghapus data, silakan coba kembali!'
    )
  }
}

export const qIndex = async args => {
  try {
    const {data} = await axios.post(
      APISATSET + '/pengaturan-satu-sehat/index-mob',
      qs.stringify(args)
    )

    if (data?.status == 1 || data?.status == 2) return data
    return defError('EPSS-CE')
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}
