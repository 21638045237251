import classNames from 'classnames'
import {VmView, VmButton, VmIcons, VmText, VmModal} from 'components'
import {colors, defaultpropsalertform, types} from 'entries/alertform'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {isFunction} from 'utils'

function AlertForm(
  {
    onClose = () => {},
    onConfirm = () => {},
    onCancel = () => {},
    visible = !true,
    children = <></>,
    title = null,
    iconName = null,
    iconClassName = null,
    yesText = null,
    noText = null,
    classNameHeader = null,
    closeable = true,
    /**
     * =====================
     * || Type || Notes
     * || 1    || Success
     * || 2    || Danger
     * || 3    || Warning
     * || 4    || Info
     * || 5    || Notifikasi
     */
    type = 1,
    /**
     * =====================
     * || Alert position
     * || top | bottom | fit | middle
     */
    position = null,
  },
  ref
) {
  const modalRef = useRef()
  const [template, settemplate] = useState(types[0])
  const setAlertFormTemplate = () => {
    // Set Template
    var newtemplate = {}
    try {
      const newtype = parseInt(type) - 1
      if (!!types[newtype]) {
        newtemplate = types[newtype]
      } else {
        newtemplate = types[0]
      }
      if (!!iconName) {
        newtemplate['iconName'] = iconName
      }
      if (!!yesText || yesText === false) {
        newtemplate['yesText'] = yesText
      }
      if (!!noText || noText === false) {
        newtemplate['noText'] = noText
      }
      if (!!title) {
        newtemplate['title'] = title
      }
      if (!!iconClassName) {
        newtemplate['iconClassName'] = iconClassName
      }
      if (!!classNameHeader) {
        newtemplate['classNameHeader'] = classNameHeader
      }
      settemplate(newtemplate)
    } catch (error) {
      throw new Error(error)
    }
  }
  useEffect(() => {
    setAlertFormTemplate()
    return () => {
      onClose()
    }
  }, [type])
  useEffect(() => {
    if (visible === true) {
      if (isFunction(modalRef.current?.open)) {
        modalRef.current.open()
      }
    }
    return () => {}
  }, [visible, modalRef, template])
  useImperativeHandle(
    ref,
    () => {
      return {
        modalref: modalRef.current,
        visible,
        template,
        settemplate,
      }
    },
    [modalRef, visible, template, settemplate, setAlertFormTemplate]
  )

  return (
    <VmModal
      ref={modalRef}
      classNameContent="!top-auto !bottom-auto"
      classNameHeader={classNames(
        'flex border-none bg-gray7-payment item-center',
        template?.classNameHeader
      )}
      classNameFooter="border-none"
      closeable={closeable}
      onClose={onClose}
      position={position}
      header={
        <>
          <VmIcons
            size={22}
            variant="outline"
            name={template?.iconName || 'VmInformationCircleIcon'}
          />
          <VmText className="font-semibold text-sm ml-2">
            {template?.title || 'Peringatan'}
          </VmText>
        </>
      }
      footer={
        (template?.noText || template?.yesText) && (
          <VmView className="flex">
            {template?.noText && (
              <VmButton
                color={colors[template.color]?.noTextColor || 'secondary'}
                className="p-2 flex-1 mr-2 text-sm"
                onClick={e =>
                  Promise.all([onCancel(e)]).finally(() =>
                    modalRef.current?.close()
                  )
                }
                hidering={true}
              >
                {template?.noText}
              </VmButton>
            )}
            {template?.yesText && (
              <VmButton
                color={colors[template.color]?.yesTextColor || 'danger'}
                className="p-2 flex-1 text-sm"
                onClick={e =>
                  Promise.all([onConfirm(e)]).finally(() =>
                    modalRef.current?.close()
                  )
                }
                hidering={true}
              >
                {template?.yesText}
              </VmButton>
            )}
          </VmView>
        )
      }
    >
      <VmView className="text-sm">{children}</VmView>
    </VmModal>
  )
}

export default forwardRef(AlertForm)
