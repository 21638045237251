import {Routes, Route, Navigate} from 'react-router-dom'
import {useJsApiLoader} from '@react-google-maps/api'

import {protectedRoutes, publicRoutes} from './config'
import {FullErrorView, ProgressFullScreen} from 'molecules'
import {getValue} from 'utils/datausr'

function App() {
  const pathname = window.location?.pathname
  const referrer = document.referrer
  const {isLoaded, loadError} = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places', 'geometry'],
  })
  const whitelist = process.env.REACT_APP_WHITE_LIST
  const whitelistpathname = process.env.REACT_APP_WHITE_LIST_PATHNAME
  if (process.env.NODE_ENV !== 'development') {
    if (referrer?.length > 0) {
      const splitwhitelist = `${whitelist}`.split('-')
      const regex = /^https:\/\//g
      const isSafe = v => `${v}`.match(regex)
      if (
        splitwhitelist.findIndex(
          fn => referrer.includes(fn) && isSafe(referrer)
        ) < 0
      ) {
        window.location = 'https://vmedis.com'
        return <></>
      }
    } else {
      const val = getValue()
      if (!val?.a && !val?.reg) {
        const splitwhitelistpathname = `${whitelistpathname}`.split('|')
        if (
          splitwhitelistpathname?.length > 0
            ? splitwhitelistpathname.findIndex(fn => pathname.includes(fn)) < 0
            : true
        ) {
          window.location = 'https://vmedis.com'
          return <></>
        }
      }
    }
  }
  if (!isLoaded) {
    return <ProgressFullScreen visible={true} />
  }
  if (loadError) {
    return <FullErrorView message={'[G0] Gagal menginisialisasi App'} />
  }
  return (
    <Routes>
      <Route path="*" element={<Navigate to="/404" />} />
      {protectedRoutes.map((item, i) => {
        const Layout = item?.layout || false
        return (
          <Route
            path={item.route}
            key={i}
            // element={<ProtectedRoute>{item.components}</ProtectedRoute>}
            element={
              Layout ? <Layout>{item.components}</Layout> : item.components
            }
          >
            {!!item?.child && item?.child?.length > 0
              ? item.child.map((childItem, i) => {
                  const ChildLayout = childItem?.layout ?? false
                  return (
                    <Route
                      path={item.route + childItem.route}
                      key={i}
                      element={
                        ChildLayout ? (
                          <ChildLayout>{childItem.components}</ChildLayout>
                        ) : (
                          childItem.components
                        )
                      }
                    />
                  )
                })
              : null}
          </Route>
        )
      })}
      {publicRoutes.map((item, i) => (
        <Route path={item.route} key={i} element={item.components} />
      ))}
    </Routes>
  )
}

export default App
