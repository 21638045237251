import classNames from 'classnames'
import {VmButton, VmView} from 'components'

export default function CardItemKategori({
  data: {katonama = ''},
  index = 0,
  onClick = () => {},
}) {
  return (
    <VmView className="relative !text-black2-payment bg-gray7-payment mb-4 last:mb-20 rounded-xl rounded-t-2xl">
      {/* Head */}
      <VmView className="flex justify-between items-center bg-white px-4 py-3 rounded-xl">
        {/* Title */}
        <VmView className="font-semibold">{katonama || '-'}</VmView>
        <VmButton
          className={classNames(
            'flex justify-center items-center !flex-none text-sm p-1 px-4 !min-w-[4rem]',
            '!bg-blue1-payment !text-white'
          )}
          hidering={true}
          label={`add-kategori-${index}`}
          onClick={onClick}
        >
          Pilih
        </VmButton>
      </VmView>
    </VmView>
  )
}
