import {useState} from 'react'
import {ReportMobileControlled} from 'template'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {aliasFields, customExportAttr, dataFields, filterFields} from './fields'
import {APIDOC, APISATSET} from 'config/apiurl'
import {VmIcons, VmView} from 'components'
import axios from 'axios'
import QueryString from 'qs'

import Alert from 'template/Form/AlertForm'
import satusehatminiicon from '../../assets/satusehaticon.ico'
import {defaultpropsalertform} from 'entries/alertform'

export default function MobLapPem() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams(),
    [alertProps, setAlertProps] = useState(defaultpropsalertform),
    [loading, setloading] = useState(false),
    closeConfirm = () => setAlertProps(defaultpropsalertform)
  const uid = searchParams.get('dctid'),
    SyncSatusehat = async () => {
      setloading(true)
      axios
        .post(
          `${APISATSET}/satusehat-kunjungan/sync-data-procedure`,
          QueryString.stringify({
            userid: uid,
          })
        )
        .then(({data: res}) => {
          if (res?.status === 1) {
            setAlertProps({
              ...defaultpropsalertform,
              key: `${Date.now()}`,
              type: 1,
              title: 'Berhasil',
              position: 'fit',
              visible: true,
              children: <>Berhasil sinkron satusehat.</>,
              onConfirm: closeConfirm,
              onClose: closeConfirm,
              onCancel: closeConfirm,
            })
          } else {
            return setAlertProps({
              ...defaultpropsalertform,
              key: `${Date.now()}`,
              type: 3,
              position: 'fit',
              visible: true,
              children: (
                <>
                  {res?.message ||
                    '[S0] Terjadi kesalahan saat sinkron satusehat.'}
                </>
              ),
              onConfirm: closeConfirm,
              onClose: closeConfirm,
              onCancel: closeConfirm,
            })
          }
        })
        .catch(e => {
          return setAlertProps({
            ...defaultpropsalertform,
            key: `${Date.now()}`,
            type: 3,
            position: 'fit',
            visible: true,
            children: <>Terjadi kesalahan saat sinkron satusehat.</>,
            onConfirm: closeConfirm,
            onClose: closeConfirm,
            onCancel: closeConfirm,
          })
        })
        .finally(() => {
          setloading(false)
        })
    },
    AbSyncSatusehat = () => {
      return setAlertProps({
        ...defaultpropsalertform,
        key: `${Date.now()}`,
        type: 4,
        yesText: 'Ya',
        noText: 'Tidak',
        title: 'Sinkron SATUSEHAT',
        position: 'fit',
        visible: true,
        closeable: false,
        children: (
          <>Apakah anda yakin ingin melanjutkan proses sinkron satusehat?</>
        ),
        onConfirm: SyncSatusehat,
        onClose: closeConfirm,
        onCancel: closeConfirm,
      })
    }

  return (
    <>
      <ReportMobileControlled
        headerFields={[
          {name: 'pemtanggal', dateFormat: true},
          {
            children: (item, col) => (
              <VmView className="flex flex-row gap-1 text-gray-700">
                {item?.pemid_satusehat?.length > 0 && (
                  <VmView className="flex items-center justify-center border border-[#03B1AF] rounded-md bg-white">
                    <VmIcons
                      size={18}
                      name="VmSatuSehat"
                      className="stroke-[2.5]"
                    />
                  </VmView>
                )}
                {item?.pemnofaktur || '-'}
              </VmView>
            ),
          },
        ]}
        dataFields={dataFields}
        additionalApiParams={{uid, reg: 'dbdok'}}
        statusDbColumn="pemjenis"
        aliasFields={aliasFields}
        apiUrl={APIDOC + '/prd-lap-pembayaran-pemeriksaan/index-mob'}
        filterFields={filterFields}
        headerTitle="Laporan Pembayaran"
        searchPlaceholder="Cari Faktur/Nama/RM/Tindakan"
        detailAttr={{
          button: true,
          buttonLabel: 'Detail',
          buttonAction: item => {
            navigate(`/prd-detail-pembayaran/${item.pemid}`)
          },
        }}
        loading={loading}
        footerDividerVisible={false}
        exportApiParams={{uid}}
        customExportAttr={customExportAttr}
        sideDownloadComponent={
          <>
            {/* Tombol Sync All yang belum punya id SatuSehat */}
            <VmView
              onClick={AbSyncSatusehat}
              className="sticky flex justify-center items-center ml-2 min-h-[2.5rem] min-w-[2.7rem] h-[2.5rem] w-[2.7rem] bg-white border-2 border-solid border-green-500 rounded-xl cursor-pointer"
            >
              <img
                width={24}
                src={satusehatminiicon}
                alt="satusehat-icon"
                className=""
              />
            </VmView>
          </>
        }
      />
      <Alert {...alertProps} />
    </>
  )
}
