import {VmButton, VmIcons, VmSpinner, VmText, VmView} from 'components'
import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  Fragment,
} from 'react'
import SearchBar from './SearchBar'
import axios from 'axios'
import QueryString from 'qs'
import {isFunction} from 'utils'
import usePullToRefresh, {RefreshComponen} from 'utils/usePullToRefresh'
import classNames from 'classnames'
import { getLclStorage, setLclStorage } from 'utils/localstorage'

function SearchContent(
  {
    title = '-',
    defaultData = [],
    variables = {},
    config = {},
    method = 'POST',
    url = '',
    errorMessage = '[C0] Gagal mengambil data!',
    status0Message = '[S0] Gagal mengambil data!',
    renderItems = null,
    fieldsearch = 'search',
    placeholdersearch = 'Cari',
    limit = 10,
    offset = 0,
    AdditionalComponents = () => <></>,
    onCloseModal = () => <></>,
    children = null,
    type = '',
    valuesearch = '',
    ...attributes
  },
  ref
) {
  const [keyword, setkeyword] = useState(''),
    [newlimit, setnewlimit] = useState(limit),
    [newoffset, setnewoffset] = useState(offset),
    [date, setdate] = useState(Date.now()),
    [newvariables, setnewvariables] = useState(variables),
    [data, setdata] = useState(defaultData),
    [message, setmessage] = useState(''),
    [loading, setloading] = useState(true),
    [loadingmore, setloadingmore] = useState(true),
    [onloadingmore, setonloadingmore] = useState(false),
    [error, seterror] = useState(false),
    [errordata, seterrordata] = useState(),
    [selected, setselected] = useState(attributes?.selected)

  const fetchData = (config, url, variables, method) => {
    const req = {
      ...config,
      url,
      method,
    }
    if (!config?.data) {
      req['data'] = QueryString.stringify(variables)
    }

    if (type == 'bpjs') {
      const lname = `dataBpjs_${variables?.type}`
      const lsearch = valuesearch
      const dataBpjs = getLclStorage(lname, {type: 2})
      const ldataBpjs = dataBpjs ? JSON.parse(dataBpjs) : []

      // console.log(ldataBpjs,'ldataBpjs')
      // console.log(lsearch,'lsearch')

      if (ldataBpjs && ldataBpjs.length > 0 && variables?.type != 'diagnosa') {
        const filterDataBpjs =
          variables?.search != undefined
            ? ldataBpjs?.filter(
                item =>
                  item[lsearch]
                    .toLowerCase()
                    .includes(variables?.search.toLowerCase()) ||
                  item[lsearch.replace('nm', 'kd')]
                    .toLowerCase()
                    .includes(variables?.search.toLowerCase())
              )
            : ldataBpjs

        if (filterDataBpjs?.length < (variables?.limit || newlimit)) {
          setloadingmore(false)
        }

        if(filterDataBpjs?.length == 0){
          seterrordata('Data Tidak Ditemukan')
          seterror('Data Tidak Ditemukan')
        }

        if (variables?.offset === 0) {
          setdata(filterDataBpjs)
        } else {
          setdata(prevdata => [...prevdata, ...filterDataBpjs])
        }
      } else {
        axios
          .request(req)
          .then(({data}) => {
            // console.log(data, 'response')
            // console.log(lname, 'lname')
            setmessage(data?.metaData?.message || '')
            if (data?.metaData?.code == 200) {
              // localStorage.setItem(lname, JSON.stringify(data?.response?.list))
              setLclStorage(lname, JSON.stringify(data?.response?.list), {type: 2})

              if(variables.type == 'diagnosa'){
                const filterDataBpjs =
                variables?.search != undefined
                  ? data?.response?.list?.filter(
                      item =>
                        item[lsearch]
                          .toLowerCase()
                          .includes(variables?.search.toLowerCase()) ||
                        item[lsearch.replace('nm', 'kd')]
                          .toLowerCase()
                          .includes(variables?.search.toLowerCase())
                    )
                  : data?.response?.list

                  if (filterDataBpjs?.length < (variables?.limit || newlimit)) {
                    setloadingmore(false)
                  }
          
                  if(filterDataBpjs?.length == 0){
                    seterrordata('Data Tidak Ditemukan')
                    seterror('Data Tidak Ditemukan')
                  }
          
                  if (variables?.offset === 0) {
                    setdata(filterDataBpjs)
                  } else {
                    setdata(prevdata => [...prevdata, ...filterDataBpjs])
                  }

                  return
              }

              if (
                data?.response?.list?.length < (variables?.limit || newlimit)
              ) {
                setloadingmore(false)
              }
              if (variables?.offset === 0) {
                setdata(data?.response?.list)
              } else {
                setdata(prevdata => [...prevdata, ...data?.response?.list])
              }
            } else {
              seterrordata(data?.response)
              seterror(data?.metaData?.message || status0Message)
            }
          })
          .catch(error => {
            seterrordata(error)
            seterror(errorMessage)
          })
          .finally(() => {
            setonloadingmore(false)
            setloading(false)
          })
      }
    } else {
      axios
        .request(req)
        .then(({data}) => {
          console.log(data, 'response')
          if (type == 'bpjs') {
            setmessage(data?.metaData?.message || '')
            if (data?.metaData?.code == 200) {
              if (
                data?.response?.list?.length < (variables?.limit || newlimit)
              ) {
                setloadingmore(false)
              }
              if (variables?.offset === 0) {
                setdata(data?.response?.list)
              } else {
                setdata(prevdata => [...prevdata, ...data?.response?.list])
              }
            } else {
              seterrordata(data?.response)
              seterror(data?.metaData?.message || status0Message)
            }
          } else {
            setmessage(data?.message || '')
            if (data?.status === 1) {
              if (data?.data?.length < (variables?.limit || newlimit)) {
                setloadingmore(false)
              }
              if (variables?.offset === 0) {
                setdata(data?.data)
              } else {
                setdata(prevdata => [...prevdata, ...data?.data])
              }
            } else if (data?.status === 2) {
              seterrordata(data?.error)
              seterror(data?.message || status0Message)
            } else {
              seterrordata(data?.error)
              seterror(data?.message || status0Message)
            }
          }
        })
        .catch(error => {
          seterrordata(error)
          seterror(errorMessage)
        })
        .finally(() => {
          setonloadingmore(false)
          setloading(false)
        })
    }
  }
  const fetchMore = () => {
    setonloadingmore(true)
    setnewvariables(prefVal => {
      const newVal = prefVal
      newVal[fieldsearch] = keyword
      newVal['limit'] = newlimit
      newVal['offset'] = !newVal['offset'] ? newoffset : newVal['offset'] || 0
      newVal['offset'] += Number(newlimit)
      return newVal
    })
    setdate(Date.now())
  }
  const refetch = () => {
    setdata([])
    setnewvariables(prefVal => {
      const newVal = prefVal
      newVal[fieldsearch] = keyword
      newVal['limit'] = newlimit
      newVal['offset'] = 0
      return newVal
    })
    setdate(Date.now())
  }
  const onResetKeyword = e => {
    if (e?.target?.parentNode?.previousSibling?.value) {
      e.target.parentNode.previousSibling.value = ''
      setkeyword('')
    }
  }
  const onSearchButton = () => {
    setdata([])
    setnewvariables(prefVal => {
      const newVal = prefVal
      newVal[fieldsearch] = keyword
      newVal['limit'] = newlimit
      newVal['offset'] = 0
      return newVal
    })
    setdate(Date.now())
  }
  const onChangeCapture = e => {
    setkeyword(e?.target?.value || '')
  }
  const onKeyDownCapture = e => {
    if (`${e?.key}`.toLowerCase() === 'enter') {
      onSearchButton()
    }
  }
  const onPick = data => {
    setselected(data)
    if (isFunction(attributes?.onPick)) {
      attributes.onPick(data)
    }
  }
  const response = {
    data,
    message,
    loading,
    error,
    errordata,
    fetchData,
    fetchMore,
    refetch,
    onChangeCapture,
    onKeyDownCapture,
    limit: newlimit,
    setlimit: setnewlimit,
    offset: newoffset,
    setoffset: setnewoffset,
    selected,
    valuesearch,
    onPick,
    onCloseModal,
  }
  const {pullChange, onScroll} = usePullToRefresh({
    maxPullChange: 110,
    onRefresh: refetch,
    onFetchMore: loadingmore && !onloadingmore ? fetchMore : () => {},
  })
  useImperativeHandle(ref, () => response)
  useEffect(() => {
    if (newvariables?.offset === 0) {
      setloading(true)
      setloadingmore(true)
    }
    seterror(null)
    seterrordata(null)
    fetchData(config, url, newvariables, method)
    return () => {}
  }, [newvariables, date])

  return (
    <VmView className="flex flex-col p-2 gap-4 overflow-auto">
      {/* Header */}
      <VmView className="flex justify-between items-center">
        {/* Title */}
        <VmText className="text-[#3B4054] font-bold">{title}</VmText>
        {/* Close */}
        {/* {isFunction(attributes?.onClose) && (
          <VmView
            onClick={() => attributes.onClose()}
            className="relative flex items-center top-0 right-0 bottom-0 cursor-pointer"
          >
            <VmIcons
              size={24}
              name="VmXCircleIcon"
              variant="outline"
              className="text-red5-payment"
            />
          </VmView>
        )} */}
      </VmView>
      <VmView>
        {/* Search Bar */}
        <SearchBar
          keyword={keyword}
          placeholder={placeholdersearch}
          key={`${title}`}
          reset={onResetKeyword}
          onChangeCapture={onChangeCapture}
          onKeyDownCapture={onKeyDownCapture}
          onSearchButton={onSearchButton}
        />
      </VmView>
      {/* Additional Components */}
      <AdditionalComponents
        {...{
          data,
          message,
          loading,
          error,
          errordata,
          fetchData,
          fetchMore,
          onChangeCapture,
          onKeyDownCapture,
          limit: newlimit,
          setlimit: setnewlimit,
          offset: newoffset,
          setoffset: setnewoffset,
          refetch: refetch,
          onCloseModal:onCloseModal
        }}
      />
      {/* List Items */}
      {error || data?.length === 0 ? (
        <VmView className="flex flex-col p-4 gap-4 justify-center items-center">
          <VmText className="font-bold text-slate-500">{error}</VmText>
        </VmView>
      ) : (
        <VmView
          className="flex flex-col gap-3 overflow-y-scroll"
          onScroll={onScroll}
        >
          <RefreshComponen pullChange={pullChange} />
          {renderItems ? renderItems(response) : <Items {...response} />}
          <VmView className="flex flex-col p-4 gap-4 justify-center items-center">
            {loadingmore && !onloadingmore && (
              <VmSpinner color="sky-500" size="md" />
            )}
          </VmView>
        </VmView>
      )}
      {children}
    </VmView>
  )
}
const Items = ({data = [], onPick = () => {}, selected = null}) => {
  return data.map((im, idx) => {
    const key = `k-${idx}`,
      keys = Object.keys(im),
      active = JSON.stringify({im: selected}) === JSON.stringify({im})
    return (
      <VmView
        key={key}
        onClick={() => (active ? onPick() : onPick(im))}
        className={classNames(
          active ? 'border-2 border-sky-500 bg-sky-100' : '',
          'p-3 border rounded-2xl'
        )}
      >
        <VmView key={key} className="flex justify-between items-center">
          <VmView>{'No'}</VmView>
          <VmView>{idx + 1}</VmView>
        </VmView>
        {keys?.length > 0
          ? keys.map((km, kidx) => {
              const key = `k-${kidx}`
              return (
                <VmView key={key} className="flex justify-between items-center">
                  <VmView>{km || '-'}</VmView>
                  <VmView className="text-end">{im[km] || '-'}</VmView>
                </VmView>
              )
            })
          : '-'}
      </VmView>
    )
  })
}

export default forwardRef(SearchContent)
