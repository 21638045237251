import axios from 'axios'
import AlertOk from 'components/AlertOk'
import {ProgressFullScreen} from 'molecules'
import QueryString from 'qs'
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'

const BASE_SATUSEHAT = process.env.REACT_APP_BASE_URL_SATUSEHAT
const {REACT_APP_BASE_URL_DOKTER: APIDOC} = process.env

export default function Kyc() {
  const alertok = useRef()
  const [user, setUser] = useState({
    user_id: 0,
    kl_id: 0,
    app_id: 'dk13',
    username: '',
    nama_lengkap: '',
    email: '',
    user_nosip: '',
    user_nosip_en: '',
    user_noktp: '',
    user_noktp_en: '',
    alamat: '',
    provinsi: '277',
    kota: '2439',
    kecamatan: '34055',
    keterangan: '',
    user_nostr_en: '',
    user_wa: '085606021473',
    kecnama: '',
    kotanama: '',
  })
  const navigate = useNavigate()
  const [url, setUrl] = useState('https://www.vmedis.com')
  const [loadingIdentitas, setLoadingIdentitas] = useState(true)
  const [loadingGenerateUrl, setLoadingGenerateUrl] = useState(true)
  const params = useParams()
  const dokid = parseInt(params?.id)

  useEffect(() => {
    // Get data user
    getDataDokter()
    return () => {}
  }, [])

  useEffect(() => {
    if (user.user_id != 0) {
      // Generate URL KYC
      generateUrlKyc()
    }
    return () => {}
  }, [user.user_id])

  const getDataDokter = () => {
    axios
      .post(
        `${APIDOC}/ap-user-dokter/get-profil-by-id`,
        QueryString.stringify({
          uid: dokid,
          a: 'dk13',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.status == 1) {
          setUser(res.data)
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get Data Identitas')
      })
      .finally(() => {
        setLoadingIdentitas(false)
      })
  }

  const generateUrlKyc = () => {
    axios
      .post(
        `${BASE_SATUSEHAT}/satusehat-kyc/generate-url`,
        QueryString.stringify({
          a: 'dk13',
          kl_id: dokid,
          userid: dokid,
          agent_name: user.nama_lengkap,
          agent_nik: user.user_noktp,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.status == 0) {
          alertok.current.show({
            message:
              'Maaf, Anda belum terhubung dengan SATUSEHAT. Silakan melengkapi data di menu Pengaturan SATUSEHAT',
          })
          return
        }
        // navigate('/ap-user-dokter/'+dokid)
        window.location.href = res.url
      })
      .catch(e => {
        alertok.current.show({
          message: 'Terjadi Kesalahan saat Generate URL KYC',
        })
        console.log('Terjadi Kesalahan saat Generate URL KYC')
      })
      .finally(() => {
        setLoadingGenerateUrl(false)
      })
  }

  return (
    <>
      <ProgressFullScreen visible={loadingIdentitas || loadingGenerateUrl} />
      <AlertOk ref={alertok} />
      {/* <iframe width={'100%'} style={{height: '100vh'}} src={url} /> */}
    </>
  )
}
