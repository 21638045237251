import axios from 'axios'
import {useEffect, useState} from 'react'

export default function useFetchData(
  {
    url,
    config,
    method,
    headers,
    variables,
    defaultData,
    errorMessage = '[C0] Gagal mengambil data!',
    status0Message = '[S0] Gagal mengambil data!',
    ...props
  },
  deps
) {
  const [data, setdata] = useState(defaultData)
  const [message, setmessage] = useState('')
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState(false)
  const [errordata, seterrordata] = useState()

  const getData = () => {
    axios
      .request({...config, url, headers, method})
      .then(({data}) => {
        setmessage(data?.message || '')
        if (data?.status === 1) {
          setdata(data?.data)
        } else if (data?.status === 2) {
          seterrordata(data?.error)
          seterror(data?.message || status0Message)
          setdata(data?.data || defaultData)
        } else {
          seterrordata(data?.error)
          seterror(data?.message || status0Message)
        }
      })
      .catch(error => {
        seterrordata(error)
        seterror(errorMessage)
      })
      .finally(() => {
        setloading(false)
      })
  }
  useEffect(() => {
    setloading(true)
    getData()
    return () => {}
  }, deps || []) /* eslint-disable-line */

  return [data, message, loading, error, errordata, getData]
}
