import {VmButton, VmInput, VmText, VmView} from 'components'
import React, {useEffect, useRef, useState} from 'react'
import {useParams, useNavigate, useSearchParams} from 'react-router-dom'
import VmHeader from 'components/layout/VmHeader'
import {VmFab, VmList, VmPicker} from 'components'
import {
  catatLogDokter,
  empty,
  tglDDMMMYYYY,
  tglYMD,
  toCurrency,
  validateNumericInput,
} from 'utils/functions'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {
  VmCalendarDaysIcon,
  VmCheckIcon,
  VmMagnifyingGlassIcon,
  VmPlusIcon,
  VmTrashIcon,
} from 'components/icons/solid'
import {API, graphqlOperation} from 'aws-amplify'
import {mAktifkanDiagnosa, mDeleteDiagnosa} from 'graphql/mutations'
import DatePicker, {registerLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import id from 'date-fns/locale/id'
import {pasienDummy, pasienEmpty} from './dummy'
import {API5, APIBPJS, APIDOC, APISATSET} from 'config/apiurl'
import moment from 'moment'
import VmLoading from 'components/loader/VmLoading'
import classNames from 'classnames'
import {
  getLclStorage,
  removeLclStorage,
  setLclStorage,
} from 'utils/localstorage'
registerLocale('id', id)

export default function TambahPasien() {
  const alertok = useRef(null)
  const prompt = useRef(null)

  const [gettingId, setgettingId] = useState(false)
  const [loading, setloading] = useState(false)

  const [searchParams, setSearchParams] = useSearchParams()
  let mode = searchParams.get('mode')
  // const [mode, setmode] = useState(qmode ?? 'tambah_pasien')
  // let initial = pasienDummy
  let initial = pasienEmpty
  const [pasnama, setpasnama] = useState(initial.pasnama)
  const [pasrm, setpasrm] = useState(initial.pasrm)
  const [pasid, setpasid] = useState(initial.pasid)
  const [pasalamat, setpasalamat] = useState(initial.pasalamat)
  const [pasjk, setpasjk] = useState(initial.pasjk)
  const [pastgllahir, setpastgllahir] = useState(initial.pastgllahir)
  const [pasnobpjs, setpasnobpjs] = useState(initial.pasnobpjs)
  const [pasnokk, setpasnokk] = useState(initial.pasnokk)
  const [pasnoktp, setpasnoktp] = useState(initial.pasnoktp)
  const [pasgoldarah, setpasgoldarah] = useState(initial.pasgoldarah)
  const [penanggungjawabPrev, setpenanggungjawabPrev] = useState({})
  const [penid, setpenid] = useState(0)

  const [provinsi, setprovinsi] = useState(initial.provinsi)
  const [kota, setkota] = useState(initial.kota)
  const [kecamatan, setkecamatan] = useState(initial.kecamatan)
  const [agama, setagama] = useState(initial.agama)
  const [pendidikan, setpendidikan] = useState(initial.pendidikan)
  const [pasid_satusehat, setpasid_satusehat] = useState(
    initial.pasid_satusehat
  )
  const [pasconsent, setpasconsent] = useState(initial.pasconsent)
  const [agnama, setagnama] = useState(initial.agnama) // State untuk menyimpan nama Agama jika user memilih Lain-lain isi sendiri
  const [pendnama, setpendnama] = useState(initial.pendnama) // State untuk menyimpan nama Pendidikan jika user memilih Lain-lain isi sendiri
  const [pastelp, setpastelp] = useState(initial.pastelp)
  const [paspekerjaan, setpaspekerjaan] = useState(initial.paspekerjaan)
  const [pasemail, setpasemail] = useState(initial.pasemail)
  const [pasalergi, setpasalergi] = useState(initial.pasalergi)
  const [pasalergimakanan, setpasalergimakanan] = useState(
    initial.pasalergimakanan
  )
  const [tahun, settahun] = useState(initial.tahun)
  const [bulan, setbulan] = useState(initial.bulan)
  const [hari, sethari] = useState(initial.hari)
  const [pennama, setpennama] = useState(initial.pennama)
  const [pennik, setpennik] = useState(initial.pennik)
  const [penhubungan, setpenhubungan] = useState(initial.penhubungan)
  const [penjk, setpenjk] = useState(initial.penjk)
  const [pentelp, setpentelp] = useState(initial.pentelp)
  const [penalamat, setpenalamat] = useState(initial.penalamat)
  const [dataAgama, setdataAgama] = useState([]),
    [dataPendidikan, setdataPendidikan] = useState([]),
    [dataProvinsi, setdataProvinsi] = useState([]),
    [dataKota, setdataKota] = useState([]),
    [dataKecamatan, setdataKecamatan] = useState([]),
    [loadingkota, setloadingkota] = useState(false),
    [loadingkecamatan, setloadingkecamatan] = useState(false),
    [consent1, setconsent1] = useState(initial.consent1),
    [consent2, setconsent2] = useState(initial.consent2),
    [consent3, setconsent3] = useState(initial.consent3),
    [penpasienpenanggungjawab, setpenpasienpenanggungjawab] = useState('')

  /**
   * State untuk Custom BPJS PCare
   */
  const [customPcare, setCustomPcare] = useState(!true),
    [checkingState, setCheckingState] = useState({
      checkingPasienPcareByBpjs: false,
      checkingPasienPcareByNik: false,
    }),
    [validityState, setValidityState] = useState({
      pasienPcareValidByBpjs: false,
      pasienPcareValidByNik: false,
    }),
    [loadingGetCustom, setLoadingGetCustom] = useState(true)
  // State untuk menampung data sebelumnya, untuk ngelog v2 pada mode ubah
  // SUDAH NDAK KEPAKE LAGI, sekarang get dari db dulu sebelum ngelog
  const [prevData, setPrevData] = useState({})

  const params = useParams()
  const {id: dctid} = params
  const navigate = useNavigate()

  const originalLog = console.log;  
  console.log = function(...args) {
    const allowedLogs = ['Log:'];
    if (allowedLogs.some(log => args.includes(log))) {
      originalLog.apply(console, args);
    }
  };

  useEffect(() => {
    // console.log('useEffect pastgllahir', pastgllahir)
    // Calculate usia setelah mengubah tgl lahir
    let current = moment()
    let tgllahir = moment(pastgllahir)
    let tahun = current.diff(tgllahir, 'years')
    current.subtract(tahun, 'years')

    let bulan = current.diff(tgllahir, 'months')
    current.subtract(bulan, 'months')

    let hari = current.diff(tgllahir, 'days')
    settahun(tahun.toString())
    setbulan(bulan.toString())
    sethari(hari.toString())
    return () => {}
  }, [pastgllahir])

  useEffect(() => {
    // console.log('useEffect mode', mode)

    if (mode == 'tambah_pasien') {
      // Get RM Pasien kalao mode tambah pasien baru
      getRm()

      // Get data dari localStorage
      let pasid = getLclStorage('regpasien_tambah_pasid', {type: 2})
      let pasnobpjs = getLclStorage('regpasien_tambah_pasnobpjs', {type: 2})
      let pasnokk = getLclStorage('regpasien_tambah_pasnokk', {type: 2})
      let pasnama = getLclStorage('regpasien_tambah_pasnama', {type: 2})
      let pasgoldarah = getLclStorage('regpasien_tambah_pasgoldarah', {type: 2})
      let pasjk =
        getLclStorage('regpasien_tambah_pasjk', {type: 2}) || 'Laki-laki'
      let pastgllahir = getLclStorage('regpasien_tambah_pastgllahir', {type: 2})
      let pasalamat = getLclStorage('regpasien_tambah_pasalamat', {type: 2})
      let pasidkec = getLclStorage('regpasien_tambah_pasidkec', {type: 2})
      let idprovinsi =
        getLclStorage('regpasien_tambah_idprovinsi', {type: 2}) || 0
      let provinsi = getLclStorage('regpasien_tambah_provinsi', {type: 2})
      let idkota = getLclStorage('regpasien_tambah_idkota', {type: 2})
      let kota = getLclStorage('regpasien_tambah_kota', {type: 2})
      let kecamatan = getLclStorage('regpasien_tambah_kecamatan', {type: 2})
      let pastelp = getLclStorage('regpasien_tambah_pastelp', {type: 2})
      let paspekerjaan = getLclStorage('regpasien_tambah_paspekerjaan', {
        type: 2,
      })
      let pasemail = getLclStorage('regpasien_tambah_pasemail', {type: 2})
      let pasalergi = getLclStorage('regpasien_tambah_pasalergi', {type: 2})
      let pasalergimakanan = getLclStorage(
        'regpasien_tambah_pasalergimakanan',
        {
          type: 2,
        }
      )
      let pasnoktp = getLclStorage('regpasien_tambah_pasnoktp', {type: 2})
      let pasrm = getLclStorage('regpasien_tambah_pasrm', {type: 2})
      let pasid_satusehat = getLclStorage('regpasien_tambah_pasid_satusehat', {
        type: 2,
      })
      let pasconsent = getLclStorage('regpasien_tambah_pasconsent', {type: 2})
      let pasumur = getLclStorage('regpasien_tambah_pasumur', {type: 2})
      let agid = getLclStorage('regpasien_tambah_agid', {type: 2}) || -1
      let pendid = getLclStorage('regpasien_tambah_pendid', {type: 2}) || -1

      let penid = getLclStorage('regpasien_tambah_penid', {type: 2})
      let pennama = getLclStorage('regpasien_tambah_pennama', {type: 2})
      let pennik = getLclStorage('regpasien_tambah_pennik', {type: 2})
      let penhubungan =
        getLclStorage('regpasien_tambah_penhubungan', {type: 2}) || '0'
      let penjk = getLclStorage('regpasien_tambah_penjk', {type: 2}) || '0'
      let pentelp = getLclStorage('regpasien_tambah_pentelp', {type: 2})
      let penalamat = getLclStorage('regpasien_tambah_penalamat', {type: 2})
      let penpasienpenanggungjawab = getLclStorage(
        'regpasien_tambah_penpasienpenanggungjawab',
        {type: 2}
      )

      let d_pastgllahir = pastgllahir ? new Date(pastgllahir) : new Date()
      // console.log('useEffect kosong', {
      //   pasid,
      //   pasnama,
      //   idprovinsi,
      //   idkota,
      //   pasidkec,
      //   pastgllahir,
      // })

      setpasnama(pasnama)
      setpasrm(pasrm)
      setpasid(pasid)
      setpasalamat(pasalamat)
      setpasjk(pasjk)

      // Khusus setpastgllahir dikasih timeout agar tidak ter-override oleh useEffect tahun/bulan/hari untuk clculateTglLahir
      setTimeout(() => {
        setpastgllahir(d_pastgllahir)
      }, 1000)
      setpasnobpjs(pasnobpjs)
      setpasnokk(pasnokk)
      setpasnoktp(pasnoktp)
      setpasgoldarah(pasgoldarah)
      setpenanggungjawabPrev({
        penid,
        pennama,
        pennik,
        penhubungan,
        penjk,
        pentelp,
        penalamat,
      })
      setpenid(penid)
      setprovinsi({id: idprovinsi})
      setTimeout(() => {
        // Di timeout agar karena jika langsung diset maka ke-clear kena useEffect dari setprovinsi
        setkota({id: idkota})
      }, 1000)

      setTimeout(() => {
        // Di timeout agar karena jika langsung diset maka ke-clear kena useEffect dari setkota
        setkecamatan({
          id: pasidkec,
        })
      }, 2000)
      setagama({
        id: agid,
      })
      setpendidikan({
        id: pendid,
      })
      setpasid_satusehat(pasid_satusehat)
      setpasconsent(pasconsent)
      // setagnama()
      // setpendnama()
      setpastelp(pastelp)
      setpaspekerjaan(paspekerjaan)
      setpasemail(pasemail)
      setpasalergi(pasalergi)
      setpasalergimakanan(pasalergimakanan)
      setpenid(penid)
      setpennama(pennama)
      setpennik(pennik)
      setpenhubungan(penhubungan)
      setpenjk(penjk)
      setpentelp(pentelp)
      setpenalamat(penalamat)
      setpenpasienpenanggungjawab(penpasienpenanggungjawab)

      setconsent1(pasconsent == 1)
      setconsent2(pasconsent == 1)
      setconsent3(pasconsent == 1)
    }
    loadAgama()
    loadPendidikan()
    loadProvinsi()

    // Cek Custom BPJS PCare
    cekCustomBpjsPcare()

    if (mode == 'ubah_pasien') {
      // Get data dari localStorage
      let pasid = getLclStorage('regpasien_ubah_pasid', {type: 2})
      let pasnobpjs = getLclStorage('regpasien_ubah_pasnobpjs', {type: 2})
      let pasnokk = getLclStorage('regpasien_ubah_pasnokk', {type: 2})
      let pasnama = getLclStorage('regpasien_ubah_pasnama', {type: 2})
      let pasgoldarah = getLclStorage('regpasien_ubah_pasgoldarah', {type: 2})
      let pasjk = getLclStorage('regpasien_ubah_pasjk', {type: 2})
      let pastgllahir = getLclStorage('regpasien_ubah_pastgllahir', {type: 2})
      let pasalamat = getLclStorage('regpasien_ubah_pasalamat', {type: 2})
      let pasidkec = getLclStorage('regpasien_ubah_pasidkec', {type: 2})
      let idprovinsi = getLclStorage('regpasien_ubah_idprovinsi', {type: 2})
      let provinsi = getLclStorage('regpasien_ubah_provinsi', {type: 2})
      let idkota = getLclStorage('regpasien_ubah_idkota', {type: 2})
      let kota = getLclStorage('regpasien_ubah_kota', {type: 2})
      let kecamatan = getLclStorage('regpasien_ubah_kecamatan', {type: 2})
      let pastelp = getLclStorage('regpasien_ubah_pastelp', {type: 2})
      let paspekerjaan = getLclStorage('regpasien_ubah_paspekerjaan', {type: 2})
      let pasemail = getLclStorage('regpasien_ubah_pasemail', {type: 2})
      let pasalergi = getLclStorage('regpasien_ubah_pasalergi', {type: 2})
      let pasalergimakanan = getLclStorage('regpasien_ubah_pasalergimakanan', {
        type: 2,
      })
      let pasnoktp = getLclStorage('regpasien_ubah_pasnoktp', {type: 2})
      let pasrm = getLclStorage('regpasien_ubah_pasrm', {type: 2})
      let pasid_satusehat = getLclStorage('regpasien_ubah_pasid_satusehat', {
        type: 2,
      })
      let pasconsent = getLclStorage('regpasien_ubah_pasconsent', {type: 2})
      let pasumur = getLclStorage('regpasien_ubah_pasumur', {type: 2})
      let agid = getLclStorage('regpasien_ubah_agid', {type: 2}) || -1
      let pendid = getLclStorage('regpasien_ubah_pendid', {type: 2}) || -1

      let penid = getLclStorage('regpasien_ubah_penid', {type: 2})
      let pennama = getLclStorage('regpasien_ubah_pennama', {type: 2})
      let pennik = getLclStorage('regpasien_ubah_pennik', {type: 2})
      let penhubungan = getLclStorage('regpasien_ubah_penhubungan', {type: 2})
      let penjk = getLclStorage('regpasien_ubah_penjk', {type: 2})
      let pentelp = getLclStorage('regpasien_ubah_pentelp', {type: 2})
      let penalamat = getLclStorage('regpasien_ubah_penalamat', {type: 2})
      let penpasienpenanggungjawab = getLclStorage(
        'regpasien_ubah_penpasienpenanggungjawab',
        {type: 2}
      )

      let d_pastgllahir = new Date(pastgllahir)
      // console.log('useEffect kosong', {
      //   idprovinsi,
      //   idkota,
      //   pasidkec,
      //   pastgllahir,
      // })

      setPrevData({
        pasnobpjs,
        pasnokk,
        pasnama,
        pasgoldarah,
        pasjk,
        pastgllahir,
        pasalamat,
        pasidkec,
        idprovinsi,
        provinsi,
        idkota,
        kota,
        kecamatan,
        pastelp,
        paspekerjaan,
        pasemail,
        pasalergi,
        pasalergimakanan,
        pasnoktp,
        pasrm,
        pasid_satusehat,
        pasumur,
        agid,
        pendid,
        pennama,
        pennik,
        penhubungan,
        penjk,
        pentelp,
        penalamat,
      })

      setpasnama(pasnama)
      setpasrm(pasrm)
      setpasid(pasid)
      setpasalamat(pasalamat)
      setpasjk(pasjk)

      // Khusus setpastgllahir dikasih timeout agar tidak ter-override oleh useEffect tahun/bulan/hari untuk clculateTglLahir
      setTimeout(() => {
        setpastgllahir(d_pastgllahir)
      }, 1000)
      setpasnobpjs(pasnobpjs)
      setpasnokk(pasnokk)
      setpasnoktp(pasnoktp)
      setpasgoldarah(pasgoldarah)
      setpenanggungjawabPrev({
        penid,
        pennama,
        pennik,
        penhubungan,
        penjk,
        pentelp,
        penalamat,
      })
      setpenid(penid)
      setprovinsi({id: idprovinsi})
      setTimeout(() => {
        // Di timeout agar karena jika langsung diset maka ke-clear kena useEffect dari setprovinsi
        setkota({id: idkota})
      }, 1000)

      setTimeout(() => {
        // Di timeout agar karena jika langsung diset maka ke-clear kena useEffect dari setkota
        setkecamatan({
          id: pasidkec,
        })
      }, 2000)
      setagama({
        id: agid,
      })
      setpendidikan({
        id: pendid,
      })
      setpasid_satusehat(pasid_satusehat)
      setpasconsent(pasconsent)
      // setagnama()
      // setpendnama()
      setpastelp(pastelp)
      setpaspekerjaan(paspekerjaan)
      setpasemail(pasemail)
      setpasalergi(pasalergi)
      setpasalergimakanan(pasalergimakanan)
      setpenid(penid)
      setpennama(pennama)
      setpennik(pennik)
      setpenhubungan(penhubungan)
      setpenjk(penjk)
      setpentelp(pentelp)
      setpenalamat(penalamat)
      setpenpasienpenanggungjawab(penpasienpenanggungjawab)

      setconsent1(pasconsent == 1)
      setconsent2(pasconsent == 1)
      setconsent3(pasconsent == 1)
    }
    return () => {}
  }, [])

  useEffect(() => {
    // console.log('useEffect tahun/bulan/hari')
    // hitung tgl lahir tiap kali value tahun/bulan/hari berubah
    calculateTglLahir()
    return () => {}
  }, [tahun, bulan, hari])

  useEffect(() => {
    // console.log('useEffect provinsi.id', provinsi.id)
    if (provinsi.id == 0) {
      // kalo masih initial value maka gk perlu lakukan apa-apa
      return
    }
    // Reset value kota & kecamatan
    setkota({
      id: 0,
      nama: '',
    })
    setkecamatan({
      id: 0,
      nama: '',
    })
    // reload data kota
    loadKota()
    return () => {}
  }, [provinsi.id])

  useEffect(() => {
    // console.log('useEffect kota.id', kota.id)
    if (kota.id == 0) {
      // kalo masih initial value/ke-clear maka gk perlu lakukan apa-apa
      return
    }
    // Reset value kecamatan
    setkecamatan({
      id: 0,
      nama: '',
    })
    // reload data kecamatan
    loadKecamatan()
    return () => {}
  }, [kota.id])

  const cekCustomBpjsPcare = async _ => {
    const uid = dctid
    axios
      .post(
        `${APIDOC}/backend-data-registrasi/get-custom`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        // console.log('cekCustomBpjsPcare', res)
        if (res.data) {
          if (res.data['121']) {
            // Berarti punya custom
            setCustomPcare(true)
          }
        }
      })
      .catch(e => {
        alertok.current?.show({
          message: 'Terjadi Kesalahan saat Cek Custom BPJS PCare',
        })
        // console.log('Terjadi Kesalahan saat Cek Custom BPJS PCare')
      })
      .finally(() => {
        setLoadingGetCustom(false)
      })
  }

  /**
   *
   * @param {*} type bpjs | nik
   * @returns
   */
  const cekPasienPcare = (type, replaceValue = true, showAlert = true) => {
    let valueToCheck,
      caption,
      stateToUpdate = '',
      validityStateToUpdate = ''
    switch (type) {
      case 'bpjs':
        valueToCheck = pasnobpjs
        caption = 'No. BPJS'
        stateToUpdate = 'checkingPasienPcareByBpjs'
        validityStateToUpdate = 'pasienPcareValidByBpjs'
        break

      case 'nik':
        valueToCheck = pasnoktp
        caption = 'No. NIK'
        stateToUpdate = 'checkingPasienPcareByNik'
        validityStateToUpdate = 'pasienPcareValidByNik'
        break
    }
    if (!valueToCheck) {
      alertok.current?.show({message: `Harap isi ${caption}`})
      return {status: 0, message: `Harap isi ${caption}`}
    }

    const uid = dctid
    setCheckingState({...checkingState, [stateToUpdate]: true})

    let params = {
      a: 'dk13',
      // a: 'v31d8',
      tglPelayanan: tglYMD(),
      noKartu: valueToCheck,
      type, // bpjs / nik
      services: 'pcare', // pcare / vclaim
      uid,
    }

    // console.log('cekPasienPcare - params', params)
    let base = APIBPJS
    // let base = 'https://apibpjs.vmedis.com:7777' // Coba cek data pasien bisa pake master biar ga kena limit
    return axios
      .post(`${base}/bpjs-jkn/get-pasien-bpjs`, QueryString.stringify(params))
      .then(({data: check}) => {
        // Berhasil Get Data
        let metadata = check['metaData']?.code
        let peserta = check['response']
        let pasienAktif = peserta?.['ketAktif']

        // console.log('Log:', check, 'check')
        // console.log('Log:', peserta?.message)

        if (metadata == '200' && pasienAktif == 'AKTIF') {
          // console.log('cekPasienPcare - pasien aktif', peserta)
          if (showAlert) alertok.current?.show({message: 'Pasien BPJS berhasil ditemukan'})

          setValidityState({...validityState, [validityStateToUpdate]: true})

          // Tetap Replace Value NIK & No. BPJS terlepas replaceVlaue = true/false
          setpasnoktp(peserta?.noKTP || pasnoktp) 
          setpasnobpjs(peserta?.noKartu)

          if (replaceValue) {
            // Auto Fill Field jika replaceValue=true
            let sex = peserta['sex'] == 'L' ? 'Laki-laki' : 'Perempuan'
            let reversed = peserta?.tglLahir?.split('-').reverse().join('-')

            setpasnama(peserta?.nama)

            setpaspekerjaan(peserta?.['jnsPeserta']['nama'])
            setpasgoldarah(!empty(peserta?.golDarah) ? peserta?.golDarah : '')
            setpastelp(peserta?.noHP)
            setpasjk(sex)
            setpastgllahir(new Date(reversed))
          }

          return {
            status: 1,
            message: 'Pasien aktif',
            pasnoktp: peserta?.noKTP,
            pasnobpjs: peserta?.noKartu,
          }
        } else {
          let message
          if (!empty(pasienAktif) && pasienAktif != 'AKTIF') {
            message = '[CPC01] Pasien BPJS tidak aktif : ' + pasienAktif
            if (showAlert) {
              alertok.current?.show({
                message,
              })
            }
          } else {
            // message = `[CPC02] Pasien BPJS tidak ditemukan, harap periksa kembali ${caption} yang anda inputkan dan silakan coba lagi`
            message = peserta?.message ? `${peserta.field} ${peserta.message}`: ''
            let metadataMessage = check['metaData'] && check['metaData'].message ? check['metaData'].message : ''
            if (!message && metadataMessage && metadataMessage != 'NO_CONTENT') {
              message = metadataMessage
            }
            message = message ? `[BPJS] ${message}` : `[CPC01] Pasien BPJS tidak ditemukan`

            if(mode != 'ubah_pasien') {
              setpasnoktp('') 
              setpasnobpjs('')

              if(replaceValue) {
                setpasnama('')
                setpaspekerjaan('')
                setpasgoldarah('')
                setpastelp('')
                setpasjk('')
                setpastgllahir(new Date())
              }
            }

            if (showAlert) {
              alertok.current?.show({
                message,
              })
            }
          }

          // Set pasien tidak valid
          setValidityState({...validityState, [validityStateToUpdate]: false})
          return {status: 0, message}
        }
      })
      .catch(e => {
        let message = '[CPC03] Terjadi kesalahan saat mencari Pasien BPJS'
        // console.log(message, e.response)
        alertok.current?.show({
          message,
        })
        // Set pasien tidak valid
        setValidityState({...validityState, [validityStateToUpdate]: false})

        return {status: 0, message}
      })
      .finally(() => {
        setCheckingState({...checkingState, [stateToUpdate]: false})
      })
  }

  const loadAgama = _ => {
    axios
      .post(
        `${API5}/pasien/get-agama`,
        QueryString.stringify({
          reg: 'db',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let dataAgama = [
          ...res.data,
          {agid: 0, agnama: 'Lain-lain (Isi Sendiri)'},
        ]

        setdataAgama(dataAgama)
      })
      .catch(e => {
        // console.log('Terjadi Kesalahan saat get Agama')
      })
  }

  const loadProvinsi = () => {
    let url = `${API5}/backend-tiket/findprovinsi`
    // console.log(url)
    axios
      .post(
        url,
        QueryString.stringify({
          a: 'default',
          provinsiaktif: 1,
        })
      )
      .then(({data}) => {
        let dataProvinsi = [...data.data]
        setdataProvinsi(dataProvinsi)
      })
      .catch(e => {
        // console.log(
        //   'Error saat Get data Provinsi',
        //   e.response?.data?.message ?? e.message,
        //   e.message
        // )
      })
  }

  const loadKota = () => {
    setloadingkota(true)
    axios
      .post(
        `${API5}/backend-tiket/findkota-t`,
        QueryString.stringify({
          a: 'default',
          id: provinsi.id,
        })
      )
      .then(({data}) => {
        if (data.status == 0) {
          // Tidak ada data kota
          setdataKota([])
          return
        }
        let dataKota = [...data.data]
        setdataKota(dataKota)
      })
      .catch(e => {
        // console.log(
        //   'Error saat Get data Kota',
        //   e.response?.data?.message ?? e.message,
        //   e.message
        // )
      })
      .finally(() => {
        setloadingkota(false)
      })
  }

  const loadKecamatan = () => {
    // console.log('params kec', {
    //   a: 'default',
    //   id: kota.id,
    // })
    setloadingkecamatan(true)
    axios
      .post(
        `${API5}/backend-tiket/findkecamatan-t`,
        QueryString.stringify({
          a: 'default',
          id: kota.id,
        })
      )
      .then(({data}) => {
        if (data.status == 0) {
          // Tidak ada data kecamatan
          setdataKecamatan([])
          return
        }
        let dataKecamatan = [...data.data]
        setdataKecamatan(dataKecamatan)
      })
      .catch(e => {
        // console.log(
        //   'Error saat Get data Kecamatan',
        //   e.response?.data?.message ?? e.message,
        //   e.message
        // )
      })
      .finally(() => {
        setloadingkecamatan(false)
      })
  }

  const loadPendidikan = _ => {
    axios
      .post(
        `${API5}/pasien/get-pendidikan`,
        QueryString.stringify({
          reg: 'db',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let dataPendidikan = [
          ...res.data,
          {pendid: 0, pendnama: 'Lain-lain (Isi Sendiri)'},
        ]
        setdataPendidikan(dataPendidikan)
      })
      .catch(e => {
        // console.log('Terjadi Kesalahan saat get pendidikan')
      })
  }

  const getRm = async () => {
    let uid = dctid

    // console.log('uid gan', uid)
    axios
      .post(
        `${APIDOC}/pasien/get-rm`,
        QueryString.stringify({
          uid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        // console.log('Berhasil get DATA RM', res)
        setpasrm(res)
      })
      .catch(e => {
        // console.log(
        //   'Terjadi Kesalahan saat Get RM',
        //   e.response?.data ?? e.message
        // )
      })
  }
  /**
   * Kalkulasi Tgl Lahir dari inputan Tahun, Bulan, & Hari
   */
  const calculateTglLahir = () => {
    let current = moment()
    current.subtract(tahun, 'years')
    current.subtract(bulan, 'months')
    current.subtract(hari, 'days')

    setpastgllahir(current.toDate())
  }

  const validate = async () => {
    if (empty(pasnama)) {
      alertok.current?.show({message: 'Nama Lengkap harus diisi'})
      return
    } else if (empty(pasrm)) {
      alertok.current?.show({message: 'No. RM harus diisi'})
      return
    }

    // if (!empty(parentid) && empty(statussambung)) {
    //   // Jika mengisi relasi parent id, maka wajib mengisi Hubungan dengan Pasien
    //   alertok.current?.show({message: 'Relasi dengan pasien lain wajib diisi.'})
    //   return
    // }

    if (empty(kecamatan.id)) {
      alertok.current?.show({
        message: 'Harap isi data Provinsi, Kota, dan Kecamatan.',
      })
      return
    }

    // Jika memilih pendidikan / agama lain-lain, maka harus diisi, atau diisi yang Lainnya
    if (pendidikan.id == 0 && empty(pendnama)) {
      alertok.current?.show({
        message: 'Harap isi field pendidikan!',
      })
      return
    } else if (agama.id == 0 && empty(agnama)) {
      alertok.current?.show({
        message: 'Harap isi field agama!',
      })
      return
    }

    // Validasi Data Penanggung Jawab
    // Update terbaru, data penanggungjawab dibuat opsional semuanya, gk perlu validasi
    // if (empty(nama_consent)) {
    //   alertok.current?.show({
    //     message: 'Harap isi field Nama Penanggung Jawab!',
    //   })
    //   return
    // } else if (empty(jk_consent)) {
    //   alertok.current?.show({
    //     message: 'Harap isi field Jenis Kelamin Penanggung Jawab!',
    //   })
    //   return
    // } else if (empty(statussambung)) {
    //   alertok.current?.show({
    //     message: 'Harap isi field Hubungan Keluarga Penanggung Jawab!',
    //   })
    //   return
    // } else if (empty(notelp_consent)) {
    //   alertok.current?.show({
    //     message: 'Harap isi field No. Telepon Penanggung Jawab!',
    //   })
    //   return
    // } else if (empty(alamat_consent)) {
    //   alertok.current?.show({
    //     message: 'Harap isi field Alamat Penanggung Jawab!',
    //   })
    //   return
    // } else if (!consent1 || !consent2 || !consent3) {
    //   alertok.current?.show({
    //     message: 'Harap Setujui Informasi Persetujuan Umum di bawah!',
    //   })
    //   return
    // }

    let noktp = pasnoktp,
      nobpjs = pasnobpjs
    // Cek Jika dokter mempunyai custom pcare, maka validasi apakah dokter sudah melakukan validasi data pasien
    if (customPcare) {
      if (
        !validityState.pasienPcareValidByBpjs &&
        !validityState.pasienPcareValidByNik
      ) {
        // Jika Belum Ngecek validitas No BPJS, maka Cek-kan
        let resBpjs = await cekPasienPcare('bpjs', false, false)
        if (resBpjs.status == 0) {
          // Status Gagal, maka cek by NIK
          let resNik = await cekPasienPcare('nik', false, false)
          if (resNik.status == 0) {
            alertok.current?.show({
              message:
                'Data No. BPJS maupun NIK yang anda inputkan tidak valid.',
            })
            return
          } else {
            // Berarti NIK aktif, Replace Value dengan Result Get Pasien
            noktp = resNik.pasnoktp
            nobpjs = resNik.pasnobpjs
          }
        } else {
          // Berarti No. bpjs aktif, Replace Value dengan Result Get Pasien
          noktp = resBpjs.pasnoktp
          nobpjs = resBpjs.pasnobpjs
        }
      }
    } else {
      // Jika tidak custom pcare, maka nik boleh dikosongi
      if (empty(pasnoktp)) {
        prompt.current?.show(
          {
            message:
              'Silakan isi No. KTP untuk mempermudah pencarian data pasien. Apakah anda yakin akan menambah pasien tanpa ktp ?',
          },
          () => {
            simpan(noktp, nobpjs)
          }
        )
        return
      }
    }

    simpan(noktp, nobpjs)
  }

  const getIdPasienSatuSehat = _ => {
    if (empty(pasnama) || empty(pasnoktp) || empty(pastgllahir)) {
      alertok.current?.show({
        message:
          'Harap isi Nama Pasien, NIK, dan Tangal Lahir untuk mencari ID SatuSehat Pasien',
      })
      return
    }

    setgettingId(true)
    return axios
      .post(
        `${APISATSET}/satusehat-pasien/cari-id-satusehat-v2`,
        QueryString.stringify({
          reg: 'dbdok',
          a: 'dk13',
          kl_id: '19',
          userid: dctid,
          pasnoktp,
          pasnama,
          pastgllahir: tglYMD(pastgllahir),
        })
      )
      .then(res => {
        const result = res?.data
        // Berhasil Get Data
        if (result?.status == 1) {
          if (result.data.total > 0) {
            setpasid_satusehat(result.data.entry[0].resource.id)
          } else {
            alertok.current?.show({
              message:
                'Pasien dengan NIK ' +
                pasnoktp +
                ', Nama ' +
                pasnama +
                ', Tanggal Lahir ' +
                moment(pastgllahir).format('YYYY-MM-DD') +
                ' tidak ditemukan di Satu Sehat',
            })
            setpasid_satusehat('')
          }
        } else {
          setpasid_satusehat('')
          alertok.current?.show({
            message: result?.message || 'Gagal menghubungkan ke Satu Sehat',
          })
        }
      })
      .catch(e => {
        alertok.current?.show({
          message: 'Terjadi kesalahan saat get ID SatuSehat.',
        })
        // console.warn(
        //   'Terjadi Kesalahan saat get ID Pasien SatuSehat.',
        //   e.response?.data ?? e.message
        // )
      })
      .finally(() => {
        setgettingId(!true)
      })
  }

  const getPasienByRm = () => {
    return axios
      .post(
        `${APIDOC}/pasien-v2/mobile-pasien`,
        QueryString.stringify({
          uid: dctid,
          pasrm: pasrm,
          device: 'web',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        return res[0]
      })
      .catch(e => {
        // console.log('Terjadi Kesalahan saat getPasienByRm')
        return {}
      })
  }

  const simpan = async (noktp, nobpjs) => {
    setloading(true)
    let tgl = moment().format('YYYY-MM-DD HH:mm')
    // onConfirm

    let pasconsent = consent1 || consent2 || consent3 ? 1 : 0 // Jika salah satu centang, maka anggap centang semua
    if (mode == 'tambah_pasien') {
      // tambah pasien
      let params = {
        uid: dctid,
        tgl: tgl,
        data: {
          pasnobpjs: nobpjs,
          pasnokk,
          pasnama,
          pasgoldarah,
          pasjk,
          pastgllahir: tglYMD(pastgllahir),
          pasalamat,
          pasidkec: kecamatan.id,
          pastelp,
          paspekerjaan,
          pasemail,
          pasalergi,
          pasalergimakanan,
          pasnoktp: noktp,
          pasrm,
          // ['pasparentid']: parentid,
          ['passtatussambung']: 0, // Value lama, sudah tidak dipakai lagi, kasih 0
          agid: agama.id,
          pendid: pendidikan.id,
          pendnama,
          agnama,
          pasid_satusehat,
          pasconsent,

          // data penanggungjawab
          pennama,
          pennik,
          penhubungan,
          penjk,
          pentelp,
          penalamat,
        },
      }
      axios
        .post(`${APIDOC}/pasien-v2/create`, QueryString.stringify(params))
        .then(({data: res}) => {
          // Berhasil POST
          if (res.status != 1) {
            setloading(false)
            alertok.current?.show({message: res.message})
            return
          }

          // Catat Log Dokter
          catatLogDokter({
            keterangan: `Menambahkan pasien ${pasnama}`,
            menu: 'Pelayanan - Registrasi Pasien',
            url: 'prd-tra-registrasi-pasien',
            ip: '127.0.0.1',
            uid: dctid,
          })

          if (!empty(pasid_satusehat)) {
            // Update consent pasien satu sehat
            updateConsent()
          }

          setLclStorage('regpasien_pasid', res.data, {type: 2})
          setLclStorage('regpasien_pasnobpjs', nobpjs, {type: 2})
          setLclStorage('regpasien_pasnokk', pasnokk, {type: 2})
          setLclStorage('regpasien_pasnama', pasnama, {type: 2})
          setLclStorage('regpasien_pasgoldarah', pasgoldarah, {type: 2})
          setLclStorage('regpasien_pasjk', pasjk, {type: 2})
          setLclStorage('regpasien_pastgllahir', tglYMD(pastgllahir), {type: 2})
          setLclStorage('regpasien_pasalamat', pasalamat, {type: 2})
          setLclStorage('regpasien_pasidkec', kecamatan.id, {type: 2})
          setLclStorage('regpasien_pastelp', pastelp, {type: 2})
          setLclStorage('regpasien_paspekerjaan', paspekerjaan, {type: 2})
          setLclStorage('regpasien_pasemail', pasemail, {type: 2})
          setLclStorage('regpasien_pasalergi', pasalergi, {type: 2})
          setLclStorage('regpasien_pasalergimakanan', pasalergimakanan, {
            type: 2,
          })
          setLclStorage('regpasien_pasnoktp', noktp, {type: 2})
          setLclStorage('regpasien_pasrm', pasrm, {type: 2})
          setLclStorage('regpasien_pasid_satusehat', pasid_satusehat, {type: 2})
          setLclStorage('regpasien_pasconsent', pasconsent.toString(), {
            type: 2,
          })
          setLclStorage('regpasien_pasumur', tahun, {type: 2})
          setLclStorage('regpasien_agid', agama.id, {type: 2})
          setLclStorage('regpasien_pendid', pendidikan.id, {type: 2})

          setLclStorage('regpasien_penid', res.penid, {type: 2})
          setLclStorage('regpasien_pennama', pennama, {type: 2})
          setLclStorage('regpasien_pennik', pennik, {type: 2})
          setLclStorage('regpasien_penhubungan', penhubungan, {type: 2})
          setLclStorage('regpasien_penjk', penjk, {type: 2})
          setLclStorage('regpasien_pentelp', pentelp, {type: 2})
          setLclStorage('regpasien_penalamat', penalamat, {type: 2})

          // Clear Temp data
          clearLocalStorage()

          navigate('/prd-registrasi-pasien/' + dctid)
          // this.props.navigation.navigate('menuRegistrasiPasienDokterPribadi', {
          //   pasien: {
          //     ...this.state.pasien,
          //     pasnobpjs: bpjs,
          //     pasnokk: kk,
          //     pasnoktp: nik,
          //     pasgoldarah: goldarah,
          //     pastelp: wa,
          //     paspekerjaan: pekerjaan,
          //     pasemail: email,
          //     pasalergi: alergi,
          //     provinsi: provinsi.nama,
          //     kota: kota.nama,
          //     kecamatan: kecamatan.nama,
          //     pasid: res.data,
          //     umur: tahun,

          //     //data penanggungjawab
          //     pennama: nama_consent,
          //     pennik: nik_consent,
          //     penhubungan: statussambung,
          //     penjk: jk_consent,
          //     pentelp: notelp_consent,
          //     penalamat: alamat_consent,
          //   },
          // })
        })
        .catch(e => {
          setloading(false)
          alertok.current?.show({
            message: 'Terjadi kesalahan saat Menambahkan Pasien.',
          })
          // console.log(
          //   'Terjadi Kesalahan saat Menambahkan Pasien',
          //   e.response?.data ?? e.message
          // )
        })
    } else {
      // Ubah Pasien
      let prevPas = await getPasienByRm()
      // console.log('pasid gan', pasid)
      axios
        .post(
          `${APIDOC}/pasien-v2/update?id=${pasid}`,
          QueryString.stringify({
            uid: dctid,
            data: {
              pasnobpjs: nobpjs,
              pasnokk,
              pasnama,
              pasgoldarah,
              pasjk,
              pastgllahir: tglYMD(pastgllahir),
              pasalamat,
              pasidkec: kecamatan.id,
              pastelp,
              paspekerjaan,
              pasemail,
              pasalergi,
              pasalergimakanan,
              pasnoktp: noktp,
              pasrm,
              // ['pasparentid']: parentid,
              ['passtatussambung']: 0, // Value lama, sudah tidak dipakai lagi, kasih 0
              agid: agama.id,
              pendid: pendidikan.id,
              pendnama,
              agnama,
              pasid_satusehat,
              pasconsent,

              // data penanggungjawab
              pennama,
              pennik,
              penhubungan,
              penjk,
              pentelp,
              penalamat,
              penid,
              insertpen: pennik != penanggungjawabPrev.pennik ? 1 : 0,
            },
          })
        )
        .then(async ({data: res}) => {
          // Berhasil POST
          if (res.status != 1) {
            setloading(false)
            alertok.current?.show({message: res.message + ' [TP0002]'})
            return
          }

          // Catat Log Dokter
          let agafter, pendafter, provafter, kotafter, kecafter
          if (agama.id == '0') {
            // Isi agama lain-lain
            agafter = agnama
          } else {
            // Ambil dari current data agama pada List
            agafter = dataAgama.find(ag => ag.agid == agama.id)?.agnama
          }

          if (pendidikan.id == '0') {
            // Isi pendidikan lain-lain
            pendafter = pendnama
          } else {
            // Ambil dari current data pendidikan pada List
            pendafter = dataPendidikan.find(
              pend => pend.pendid == pendidikan.id
            )?.pendnama
          }

          provafter = dataProvinsi.find(
            prov => prov.idprovinsi == provinsi.id
          )?.provinsi
          kotafter = dataKota.find(kot => kot.idkota == kota.id)?.kota
          kecafter = dataKecamatan.find(
            kec => kec.idkecamatan == kecamatan.id
          )?.kecamatan

          let hubunganmap = {
            1: 'Orang Tua',
            2: 'Anak',
            3: 'Pasangan (Suami/Istri)',
            4: 'Saudara',
            5: 'Lainnya',
          }

          catatLogDokter({
            keterangan: `Mengubah pasien ${pasnama}`,
            before: JSON.stringify({
              'No. BPJS': prevPas.pasnobpjs,
              'No. KK': prevPas.pasnokk,
              NIK: prevPas.pasnoktp,
              'Nama Pasien': prevPas.pasnama,
              'Tanggal Lahir': tglDDMMMYYYY(prevPas.pastgllahir),
              'ID Satu Sehat': prevPas.pasid_satusehat,
              'Golongan Darah': prevPas.pasgoldarah,
              'Alamat Lengkap': prevPas.pasalamat,
              Provinsi: prevPas.provinsi,
              Kota: prevPas.kota,
              Kecamatan: prevPas.kecamatan,
              'No. WA': prevPas.pastelp,
              Agama: prevPas.pasagama,
              Pendidikan: prevPas.paspendidikan,
              Pekerjaan: prevPas.paspekerjaan,
              'E-mail': prevPas.pasemail,
              'Alergi Obat': prevPas.pasalergi,
              'Alergi Makanan': prevPas.pasalergimakanan,
              'Nama Penanggungjawab': prevPas.pennama,
              'NIK Penanggungjawab': prevPas.pennik,
              'Hubungan Penanggungjawab': hubunganmap[prevPas.penhubungan],
              'Jenis Kelamin Penanggungjawab': prevPas.penjk,
              'No. Telepon Penanggungjawab': prevPas.pentelp,
              'Alamat Penanggungjawab': prevPas.penalamat,
            }),
            after: JSON.stringify({
              'No. BPJS': pasnobpjs,
              'No. KK': pasnokk,
              NIK: pasnoktp,
              'Nama Pasien': pasnama,
              'Tanggal Lahir': tglDDMMMYYYY(pastgllahir),
              'ID Satu Sehat': pasid_satusehat,
              'Golongan Darah': pasgoldarah,
              'Alamat Lengkap': pasalamat,
              Provinsi: provafter,
              Kota: kotafter,
              Kecamatan: kecafter,
              'No. WA': pastelp,
              Agama: agafter,
              Pendidikan: pendafter,
              Pekerjaan: paspekerjaan,
              'E-mail': pasemail,
              'Alergi Obat': pasalergi,
              'Alergi Makanan': pasalergimakanan,
              'Nama Penanggungjawab': pennama,
              'NIK Penanggungjawab': pennik,
              'Hubungan Penanggungjawab': hubunganmap[penhubungan],
              'Jenis Kelamin Penanggungjawab': penjk,
              'No. Telepon Penanggungjawab': pentelp,
              'Alamat Penanggungjawab': penalamat,
            }),
            menu: 'Pelayanan - Registrasi Pasien',
            url: 'prd-tra-registrasi-pasien',
            ip: '127.0.0.1',
            uid: dctid,
          })

          if (!empty(pasid_satusehat)) {
            // Update consent pasien satu sehat
            updateConsent()
          }

          // Clear Temp data
          clearLocalStorage()

          // navigate('/prd-registrasi-pasien/pilih-pasien/' + dctid)
          navigate(-1)
          // this.props.navigation.navigate(
          //   'menuPilihPasienRegistrasiDokterPribadi',
          //   {shouldReload: true}
          // )
        })
        .catch(e => {
          setloading(false)
          alertok.current?.show({
            message: 'Terjadi kesalahan saat Mengubah Pasien. [TP0001]',
          })
          // console.log(
          //   'Terjadi Kesalahan saat Mengubah Pasien',
          //   e.response?.data ?? e.message
          // )
        })
    }
  }

  const updateConsent = () => {
    axios
      .post(
        `${APISATSET}/satusehat-pasien/update-consent`,
        QueryString.stringify({
          reg: 'dbdok',
          a: 'dk13',
          kl_id: '19',
          pasid_satusehat,
          userid: dctid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        // console.log('berhasil update consent Pasien', res)
      })
      .catch(e => {
        // console.log('Terjadi Kesalahan saat Update Consent [TP006]', e.message)
      })
  }

  // if (loadingGetCustom) {
  //   return <ProgressFullScreen visible />
  // }
  const clearLocalStorage = () => {
    // Remove LocalStorage Tambah
    removeLclStorage('regpasien_tambah_pasnobpjs', {type: 2})
    removeLclStorage('regpasien_tambah_pasnokk', {type: 2})
    removeLclStorage('regpasien_tambah_pasnama', {type: 2})
    removeLclStorage('regpasien_tambah_pasgoldarah', {type: 2})
    removeLclStorage('regpasien_tambah_pasjk', {type: 2})
    removeLclStorage('regpasien_tambah_pastgllahir', {type: 2})
    removeLclStorage('regpasien_tambah_pasalamat', {type: 2})
    removeLclStorage('regpasien_tambah_pasidkec', {type: 2})
    removeLclStorage('regpasien_tambah_idprovinsi', {type: 2})
    removeLclStorage('regpasien_tambah_idkota', {type: 2})
    removeLclStorage('regpasien_tambah_provinsi', {type: 2})
    removeLclStorage('regpasien_tambah_kota', {type: 2})
    removeLclStorage('regpasien_tambah_kecamatan', {type: 2})
    removeLclStorage('regpasien_tambah_pastelp', {type: 2})
    removeLclStorage('regpasien_tambah_paspekerjaan', {type: 2})
    removeLclStorage('regpasien_tambah_pasemail', {type: 2})
    removeLclStorage('regpasien_tambah_pasalergi', {type: 2})
    removeLclStorage('regpasien_tambah_pasalergimakanan', {type: 2})
    removeLclStorage('regpasien_tambah_pasnoktp', {type: 2})
    removeLclStorage('regpasien_tambah_pasrm', {type: 2})
    removeLclStorage('regpasien_tambah_pasid_satusehat', {type: 2})
    removeLclStorage('regpasien_tambah_pasconsent', {type: 2})
    removeLclStorage('regpasien_tambah_pasumur', {type: 2})
    removeLclStorage('regpasien_tambah_agid', {type: 2})
    removeLclStorage('regpasien_tambah_pendid', {type: 2})
    removeLclStorage('regpasien_tambah_penid', {type: 2})
    removeLclStorage('regpasien_tambah_pennama', {type: 2})
    removeLclStorage('regpasien_tambah_pennik', {type: 2})
    removeLclStorage('regpasien_tambah_penhubungan', {type: 2})
    removeLclStorage('regpasien_tambah_penjk', {type: 2})
    removeLclStorage('regpasien_tambah_pentelp', {type: 2})
    removeLclStorage('regpasien_tambah_penalamat', {type: 2})
    removeLclStorage('regpasien_tambah_penpasienpenanggungjawab', {type: 2})

    // Remove LocalStorage Ubah
    removeLclStorage('regpasien_ubah_pasnobpjs', {type: 2})
    removeLclStorage('regpasien_ubah_pasnokk', {type: 2})
    removeLclStorage('regpasien_ubah_pasnama', {type: 2})
    removeLclStorage('regpasien_ubah_pasgoldarah', {type: 2})
    removeLclStorage('regpasien_ubah_pasjk', {type: 2})
    removeLclStorage('regpasien_ubah_pastgllahir', {type: 2})
    removeLclStorage('regpasien_ubah_pasalamat', {type: 2})
    removeLclStorage('regpasien_ubah_pasidkec', {type: 2})
    removeLclStorage('regpasien_ubah_idprovinsi', {type: 2})
    removeLclStorage('regpasien_ubah_idkota', {type: 2})
    removeLclStorage('regpasien_ubah_provinsi', {type: 2})
    removeLclStorage('regpasien_ubah_kota', {type: 2})
    removeLclStorage('regpasien_ubah_kecamatan', {type: 2})
    removeLclStorage('regpasien_ubah_pastelp', {type: 2})
    removeLclStorage('regpasien_ubah_paspekerjaan', {type: 2})
    removeLclStorage('regpasien_ubah_pasemail', {type: 2})
    removeLclStorage('regpasien_ubah_pasalergi', {type: 2})
    removeLclStorage('regpasien_ubah_pasalergimakanan', {type: 2})
    removeLclStorage('regpasien_ubah_pasnoktp', {type: 2})
    removeLclStorage('regpasien_ubah_pasrm', {type: 2})
    removeLclStorage('regpasien_ubah_pasid_satusehat', {type: 2})
    removeLclStorage('regpasien_ubah_pasconsent', {type: 2})
    removeLclStorage('regpasien_ubah_pasumur', {type: 2})
    removeLclStorage('regpasien_ubah_agid', {type: 2})
    removeLclStorage('regpasien_ubah_pendid', {type: 2})
    removeLclStorage('regpasien_ubah_penid', {type: 2})
    removeLclStorage('regpasien_ubah_pennama', {type: 2})
    removeLclStorage('regpasien_ubah_pennik', {type: 2})
    removeLclStorage('regpasien_ubah_penhubungan', {type: 2})
    removeLclStorage('regpasien_ubah_penjk', {type: 2})
    removeLclStorage('regpasien_ubah_pentelp', {type: 2})
    removeLclStorage('regpasien_ubah_penalamat', {type: 2})
    removeLclStorage('regpasien_ubah_penpasienpenanggungjawab', {type: 2})
  }

  const pilihHubunganPenanggungjawab = () => {
    // console.log({
    //   pastgllahir,
    //   idkec: kecamatan.id,
    //   idprov: provinsi.id,
    //   idkot: kota.id,
    // })
    if (mode == 'tambah_pasien') {
      // Tambah
      // Set Local Storage, agar perubahan terakhir kesimpen
      setLclStorage('regpasien_tambah_pasnobpjs', pasnobpjs, {type: 2})
      setLclStorage('regpasien_tambah_pasnokk', pasnokk, {type: 2})
      setLclStorage('regpasien_tambah_pasnama', pasnama, {type: 2})
      setLclStorage('regpasien_tambah_pasgoldarah', pasgoldarah, {type: 2})
      setLclStorage('regpasien_tambah_pasjk', pasjk, {type: 2})
      setLclStorage('regpasien_tambah_pastgllahir', tglYMD(pastgllahir), {
        type: 2,
      })
      setLclStorage('regpasien_tambah_pasalamat', pasalamat, {type: 2})
      setLclStorage('regpasien_tambah_pasidkec', kecamatan.id, {type: 2})
      setLclStorage('regpasien_tambah_idprovinsi', provinsi.id, {type: 2})
      setLclStorage('regpasien_tambah_idkota', kota.id, {type: 2})
      setLclStorage('regpasien_tambah_provinsi', provinsi, {type: 2})
      setLclStorage('regpasien_tambah_kota', kota, {type: 2})
      setLclStorage('regpasien_tambah_kecamatan', kecamatan, {type: 2})
      setLclStorage('regpasien_tambah_pastelp', pastelp, {type: 2})
      setLclStorage('regpasien_tambah_paspekerjaan', paspekerjaan, {type: 2})
      setLclStorage('regpasien_tambah_pasemail', pasemail, {type: 2})
      setLclStorage('regpasien_tambah_pasalergi', pasalergi, {type: 2})
      setLclStorage('regpasien_tambah_pasalergimakanan', pasalergimakanan, {
        type: 2,
      })
      setLclStorage('regpasien_tambah_pasnoktp', pasnoktp, {type: 2})
      setLclStorage('regpasien_tambah_pasrm', pasrm, {type: 2})
      setLclStorage('regpasien_tambah_pasid_satusehat', pasid_satusehat, {
        type: 2,
      })
      setLclStorage('regpasien_tambah_pasconsent', pasconsent.toString(), {
        type: 2,
      })
      setLclStorage('regpasien_tambah_pasumur', tahun, {type: 2})
      setLclStorage('regpasien_tambah_agid', agama.id, {type: 2})
      setLclStorage('regpasien_tambah_pendid', pendidikan.id, {type: 2})

      setLclStorage('regpasien_tambah_penid', penid, {type: 2})
      setLclStorage('regpasien_tambah_pennama', pennama, {type: 2})
      setLclStorage('regpasien_tambah_pennik', pennik, {type: 2})
      setLclStorage('regpasien_tambah_penhubungan', penhubungan, {type: 2})
      setLclStorage('regpasien_tambah_penjk', penjk, {type: 2})
      setLclStorage('regpasien_tambah_pentelp', pentelp, {type: 2})
      setLclStorage('regpasien_tambah_penalamat', penalamat, {type: 2})
    } else {
      // return
      // Ubah
      // Set Local Storage, agar perubahan terakhir kesimpen
      setLclStorage('regpasien_ubah_pasnobpjs', pasnobpjs, {type: 2})
      setLclStorage('regpasien_ubah_pasnokk', pasnokk, {type: 2})
      setLclStorage('regpasien_ubah_pasnama', pasnama, {type: 2})
      setLclStorage('regpasien_ubah_pasgoldarah', pasgoldarah, {type: 2})
      setLclStorage('regpasien_ubah_pasjk', pasjk, {type: 2})
      setLclStorage('regpasien_ubah_pastgllahir', tglYMD(pastgllahir), {
        type: 2,
      })
      setLclStorage('regpasien_ubah_pasalamat', pasalamat, {type: 2})
      setLclStorage('regpasien_ubah_pasidkec', kecamatan.id, {type: 2})
      setLclStorage('regpasien_ubah_idprovinsi', provinsi.id, {type: 2})
      setLclStorage('regpasien_ubah_idkota', kota.id, {type: 2})
      setLclStorage('regpasien_ubah_provinsi', provinsi, {type: 2})
      setLclStorage('regpasien_ubah_kota', kota, {type: 2})
      setLclStorage('regpasien_ubah_kecamatan', kecamatan, {type: 2})
      setLclStorage('regpasien_ubah_pastelp', pastelp, {type: 2})
      setLclStorage('regpasien_ubah_paspekerjaan', paspekerjaan, {type: 2})
      setLclStorage('regpasien_ubah_pasemail', pasemail, {type: 2})
      setLclStorage('regpasien_ubah_pasalergi', pasalergi, {type: 2})
      setLclStorage('regpasien_ubah_pasalergimakanan', pasalergimakanan, {
        type: 2,
      })
      setLclStorage('regpasien_ubah_pasnoktp', pasnoktp, {type: 2})
      setLclStorage('regpasien_ubah_pasrm', pasrm, {type: 2})
      setLclStorage('regpasien_ubah_pasid_satusehat', pasid_satusehat, {
        type: 2,
      })
      setLclStorage('regpasien_ubah_pasconsent', pasconsent.toString(), {
        type: 2,
      })
      setLclStorage('regpasien_ubah_pasumur', tahun, {type: 2})
      setLclStorage('regpasien_ubah_agid', agama.id, {type: 2})
      setLclStorage('regpasien_ubah_pendid', pendidikan.id, {type: 2})

      setLclStorage('regpasien_ubah_penid', penid, {type: 2})
      setLclStorage('regpasien_ubah_pennama', pennama, {type: 2})
      setLclStorage('regpasien_ubah_pennik', pennik, {type: 2})
      setLclStorage('regpasien_ubah_penhubungan', penhubungan, {type: 2})
      setLclStorage('regpasien_ubah_penjk', penjk, {type: 2})
      setLclStorage('regpasien_ubah_pentelp', pentelp, {type: 2})
      setLclStorage('regpasien_ubah_penalamat', penalamat, {type: 2})
    }
    navigate(
      `/prd-registrasi-pasien/pilih-pasien/${dctid}?from=${mode}&pasid_exception=${pasid}`
    ) // pasid_exception agar pasien yang sudah ada tidak muncul di pilh pasien)
  }

  return (
    <VmView>
      <AlertOk ref={alertok} />
      <AlertYesNo ref={prompt} />
      <VmText className="text-center text-2xl mt-12">
        {mode == 'tambah_pasien' ? 'Tambah Pasien Baru' : 'Ubah Pasien'}
      </VmText>

      <VmView className="ColContainer p-3 mt-4 justify-start items-start gap-6 flex">
        <VmView className="Right grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
          {mode == 'tambah_pasien' && (
            <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
              <VmView className="NoRm text-neutral-700 text-sm font-normal">
                No. RM
              </VmView>
              <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
                <VmInput
                  onChange={e => setpasrm(e.target.value)}
                  typeInput={'text'}
                  className="w-full py-1.5"
                  value={pasrm}
                />
              </VmView>
            </VmView>
          )}

          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="NoBpjs text-neutral-700 text-sm font-normal">
              No. BPJS
            </VmView>
            <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
              <VmInput
                onKeyDown={validateNumericInput}
                onChange={e => setpasnobpjs(e.target.value)}
                typeInput={'text'}
                className="w-full py-1.5"
                value={pasnobpjs}
              />
              {customPcare && (
                <VmView
                  onClick={e => {
                    cekPasienPcare('bpjs')
                  }}
                  className="IconsearchWrap p-2.5 bg-blue-500 rounded-xl justify-center items-center gap-2.5 flex cursor-pointer"
                >
                  <VmView className="Cari text-white text-sm font-normal flex items-center gap-2">
                    <VmText>Cari</VmText>
                    {checkingState.checkingPasienPcareByBpjs ? (
                      <VmLoading size="xsmall" />
                    ) : null}
                  </VmView>
                </VmView>
              )}
            </VmView>
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="NoKk text-neutral-700 text-sm font-normal">
              No. KK
            </VmView>
            <VmInput
              onKeyDown={validateNumericInput}
              onChange={e => setpasnokk(e.target.value)}
              value={pasnokk}
              typeInput={'text'}
              className="w-full py-1.5"
            />
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="Nik text-neutral-700 text-sm font-normal">
              NIK
            </VmView>
            <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
              <VmInput
                onKeyDown={validateNumericInput}
                onChange={e => setpasnoktp(e.target.value)}
                value={pasnoktp}
                typeInput={'text'}
                className="w-full py-1.5"
              />
              {customPcare && (
                <VmView
                  onClick={e => {
                    cekPasienPcare('nik')
                  }}
                  className="IconsearchWrap p-2.5 bg-blue-500 rounded-xl justify-center items-center gap-2.5 flex cursor-pointer"
                >
                  <VmView className="Cari text-white text-sm font-normal flex items-center gap-2">
                    <VmText>Cari</VmText>
                    {checkingState.checkingPasienPcareByNik ? (
                      <VmLoading size="xsmall" />
                    ) : null}
                  </VmView>
                </VmView>
              )}
            </VmView>
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="NamaPasien text-neutral-700 text-sm font-normal">
              Nama Pasien
            </VmView>
            <VmInput
              onChange={e => setpasnama(e.target.value)}
              value={pasnama}
              typeInput={'text'}
              className="w-full py-1.5"
            />
          </VmView>

          <VmView className="self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="text-neutral-700 text-sm font-normal">
              Tanggal Lahir
            </VmView>

            <VmView className="w-full">
              <DatePicker
                locale={'id'}
                selected={pastgllahir}
                onChange={date => {
                  setpastgllahir(date)

                  // setPeriodeakhir(date)
                }}
                className="text-xs w-full"
                wrapperClassName="w-full"
                withPortal
                showMonthDropdown
                showYearDropdown
                yearDropdownItemNumber={60}
                scrollableYearDropdown
                customInput={
                  <VmView className="w-full p-3 bg-white rounded-xl border-2 border-gray-200 justify-start items-center gap-2 inline-flex">
                    <VmCalendarDaysIcon className="text-gray-700 w-[20px]" />
                    <VmView className="text-neutral-700 text-sm font-medium">
                      {tglDDMMMYYYY(pastgllahir)}
                    </VmView>
                  </VmView>
                }
                maxDate={new Date()}
                minDate={new Date(1800, 0, 1)}
                dropdownMode="select"
              >
                <VmView />
              </DatePicker>
            </VmView>
          </VmView>

          <VmView className="UmurWrap self-stretch flex-col justify-start items-start gap-2 flex">
            <VmView className="Umur text-black text-sm font-normal">
              Umur
            </VmView>
            <VmView className="TahunBulanHariWrap self-stretch justify-center items-center gap-6 flex">
              <VmView className="TextinputGroup grow shrink basis-0 justify-start items-center gap-2 flex">
                <VmInput
                  value={tahun}
                  onChange={e => {
                    let val = e.target.value
                    // console.log(val,typeof val)
                    settahun(val)
                  }}
                  typeInput={'number'}
                  className="w-full py-1.5"
                  min={'0'}
                />
                <VmView className="Tahun text-neutral-700 text-sm font-normal">
                  Tahun
                </VmView>
              </VmView>
              <VmView className="TextinputGroup grow shrink basis-0 justify-start items-center gap-2 flex">
                <VmInput
                  value={bulan}
                  typeInput={'number'}
                  className="w-full py-1.5"
                  onChange={e => {
                    let val = e.target.value
                    // console.log(val,typeof val)
                    setbulan(val)
                  }}
                  min={'0'}
                />
                <VmView className="Bulan text-neutral-700 text-sm font-normal">
                  Bulan
                </VmView>
              </VmView>
              <VmView className="TextinputGroup grow shrink basis-0 justify-start items-center gap-2 flex">
                <VmInput
                  value={hari}
                  typeInput={'number'}
                  className="w-full py-1.5"
                  onChange={e => {
                    let val = e.target.value
                    // console.log(val,typeof val)
                    sethari(val)
                  }}
                  min={'0'}
                />
                <VmView className="Hari text-neutral-700 text-sm font-normal">
                  Hari
                </VmView>
              </VmView>
            </VmView>
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="IdSatuSehat text-neutral-700 text-sm font-normal">
              ID Satu Sehat
            </VmView>
            <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
              <VmInput
                value={pasid_satusehat}
                readonly
                typeInput={'text'}
                className="w-full py-1.5"
              />
              <VmView
                onClick={e => {
                  getIdPasienSatuSehat()
                }}
                className="IconsearchWrap p-2.5 bg-blue-500 rounded-xl justify-center items-center gap-2.5 flex cursor-pointer"
              >
                <VmView className="Cari text-white text-sm font-normal flex items-center gap-2">
                  <VmText>Cari</VmText>
                  {gettingId ? <VmLoading size="xsmall" /> : null}
                </VmView>
              </VmView>
            </VmView>
          </VmView>
          <VmView className="GoldarahJkWrap self-stretch justify-center items-center gap-6 flex">
            <VmView className="TextinputGroup grow shrink basis-0 flex-col justify-center items-start gap-2 flex">
              <VmView className="GolonganDarah text-neutral-700 text-sm font-normal">
                Golongan Darah
              </VmView>
              <VmInput
                onChange={e => {
                  let val = e.target.value
                  setpasgoldarah(val)
                }}
                value={pasgoldarah}
                typeInput={'text'}
                className="w-full py-1.5"
              />
            </VmView>
            <VmView className="TextinputGroup grow shrink basis-0 flex-col justify-center items-start gap-2 flex">
              <VmView className="JenisKelamin text-neutral-700 text-sm font-normal">
                Jenis Kelamin
              </VmView>
              <select
                onChange={e => {
                  setpasjk(e.target.value)
                }}
                value={pasjk}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option value="Laki-laki">Laki-laki</option>
                <option value="Perempuan">Perempuan</option>
              </select>
              {/* <VmView className="SearchBox self-stretch p-2.5 bg-neutral-100 rounded-lg justify-start items-center gap-2 flex">
                <VmView className="LakiLaki grow shrink basis-0 text-neutral-700 text-sm font-medium">
                  Laki-laki
                </VmView>
              </VmView> */}
            </VmView>
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
              Alamat Lengkap
            </VmView>
            <VmInput
              onChange={e => {
                let val = e.target.value
                setpasalamat(val)
              }}
              value={pasalamat}
              typeInput={'textarea'}
              className="w-full py-1.5"
            />
          </VmView>
          <VmView className="ProvinsiKotaKecamatanWrap self-stretch justify-center items-center gap-6 flex">
            <VmView className="TextinputGroup grow shrink basis-0 flex-col justify-center items-start gap-2 flex">
              <VmView className="Provinsi text-neutral-700 text-sm font-normal">
                Provinsi
              </VmView>

              <select
                onChange={e => {
                  setprovinsi({
                    id: e.target.value,
                    nama: '',
                  })
                }}
                value={provinsi.id}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option disabled value={0}>
                  {'Pilih Provinsi'}
                </option>
                {dataProvinsi.map(prov => (
                  <option key={prov.idprovinsi} value={prov.idprovinsi}>
                    {prov.provinsi}
                  </option>
                ))}
              </select>
            </VmView>
            <VmView className="TextinputGroup grow shrink basis-0 flex-col justify-center items-start gap-2 flex">
              <VmView className="Kota text-neutral-700 text-sm font-normal flex items-center gap-2">
                <VmText>Kota</VmText>
                {loadingkota ? <VmLoading size="xsmall" /> : null}
              </VmView>

              <select
                onChange={e => {
                  setkota({
                    id: e.target.value,
                    nama: '',
                  })
                }}
                value={kota.id}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option disabled value={0}>
                  {'Pilih Kota'}
                </option>
                {dataKota.map(kot => (
                  <option key={kot.idkota} value={kot.idkota}>
                    {kot.kota}
                  </option>
                ))}
              </select>
            </VmView>
            <VmView className="TextinputGroup grow shrink basis-0 flex-col justify-center items-start gap-2 flex">
              <VmView className="Kecamatan text-neutral-700 text-sm font-normal flex items-center gap-2">
                <VmText>Kecamatan</VmText>
                {loadingkecamatan ? <VmLoading size="xsmall" /> : null}
              </VmView>

              <select
                onChange={e => {
                  setkecamatan({
                    id: e.target.value,
                    nama: '',
                  })
                }}
                value={kecamatan.id}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option disabled value={0}>
                  {'Pilih Kecamatan'}
                </option>
                {dataKecamatan.map(kec => (
                  <option key={kec.idkecamatan} value={kec.idkecamatan}>
                    {kec.kecamatan}
                  </option>
                ))}
              </select>
            </VmView>
          </VmView>
        </VmView>
        <VmView className="Left grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="NoWa text-neutral-700 text-sm font-normal">
              No. WA
            </VmView>
            <VmInput
              onChange={e => {
                let val = e.target.value
                let containNonNumeric = /[^0-9]/.test(val)
                if (isNaN(val) || containNonNumeric) {
                  return
                }
                // console.log(val,typeof val)
                setpastelp(val)
              }}
              value={pastelp}
              typeInput={'text'}
              className="w-full py-1.5"
              maxLength={14}
            />
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="Agama text-neutral-700 text-sm font-normal">
              Agama
            </VmView>
            <select
              onChange={e => {
                setagama({
                  id: e.target.value,
                  nama: '',
                })
              }}
              value={agama.id}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={-1} disabled>
                {'Pilih Agama'}
              </option>
              {dataAgama.map(ag => (
                <option key={ag.agid} value={ag.agid}>
                  {ag.agnama}
                </option>
              ))}
            </select>
            {/* <VmView className="SearchBox self-stretch p-2.5 bg-neutral-100 rounded-lg justify-start items-center gap-2 flex">
              <VmView className="PilihAgama grow shrink basis-0 text-neutral-700 text-sm font-medium">
                Pilih Agama
              </VmView>
            </VmView> */}
            {agama.id == '0' && (
              <VmInput
                onChange={e => {
                  let val = e.target.value
                  // let containNonNumeric = /[^0-9]/.test(val)
                  // if (isNaN(val) || containNonNumeric) {
                  //   return
                  // }
                  // console.log(val,typeof val)
                  setagnama(val)
                }}
                value={agnama}
                typeInput={'text'}
                className="w-full py-1.5"
                maxLength={22}
              />
            )}
          </VmView>

          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="Pendidikan text-neutral-700 text-sm font-normal">
              Pendidikan
            </VmView>

            <select
              onChange={e => {
                setpendidikan({
                  id: e.target.value,
                  nama: '',
                })
              }}
              value={pendidikan.id}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-1.5 px-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={-1} disabled>
                {'Pilih Pendidikan'}
              </option>
              {dataPendidikan.map(pend => (
                <option key={pend.pendid} value={pend.pendid}>
                  {pend.pendnama}
                </option>
              ))}
            </select>

            {pendidikan.id == '0' && (
              <VmInput
                onChange={e => {
                  let val = e.target.value
                  // let containNonNumeric = /[^0-9]/.test(val)
                  // if (isNaN(val) || containNonNumeric) {
                  //   return
                  // }
                  // console.log(val,typeof val)
                  setpendnama(val)
                }}
                value={pendnama}
                typeInput={'text'}
                className="w-full py-1.5"
                maxLength={22}
              />
            )}
          </VmView>

          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="Pekerjaan text-neutral-700 text-sm font-normal">
              Pekerjaan
            </VmView>
            <VmInput
              onChange={e => setpaspekerjaan(e.target.value)}
              value={paspekerjaan}
              typeInput={'text'}
              className="w-full py-1.5"
            />
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="EMail text-neutral-700 text-sm font-normal">
              E-mail
            </VmView>
            <VmInput
              onChange={e => setpasemail(e.target.value)}
              value={pasemail}
              typeInput={'email'}
              className="w-full py-1.5"
            />
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="AlergiObat text-neutral-700 text-sm font-normal">
              Alergi Obat
            </VmView>
            <VmInput
              onChange={e => setpasalergi(e.target.value)}
              value={pasalergi}
              typeInput={'textarea'}
              className="w-full py-1.5"
            />
          </VmView>
          <VmView className="TextinputGroup self-stretch pt-12 flex-col justify-center items-start gap-2 flex">
            <VmView className="AlergiMakanan text-neutral-700 text-sm font-normal">
              Alergi Makanan
            </VmView>
            <VmInput
              onChange={e => setpasalergimakanan(e.target.value)}
              value={pasalergimakanan}
              typeInput={'textarea'}
              className="w-full py-1.5"
            />
          </VmView>
        </VmView>
      </VmView>

      <VmView className="DividerWrap px-2.5 py-10 justify-start items-start gap-2.5 flex">
        <VmView className="Divider grow shrink basis-0 border border-neutral-700"></VmView>
      </VmView>

      <VmView className="OuterWrapperLabelPenanggungjawab px-4 flex-col justify-start items-start gap-2.5 flex">
        <VmView className="LabelPenanggungjawab self-stretch justify-start items-start gap-2.5 flex">
          <VmView className="PenanggungJawabDariPasien grow shrink basis-0 text-center text-neutral-700 text-2xl font-normal">
            Penanggung Jawab dari Pasien
          </VmView>
        </VmView>
      </VmView>

      <VmView className="ColContainer p-3 justify-start items-start gap-6 flex">
        <VmView className="Right grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
          <VmView className="TextinputGroup self-stretch h-16 flex-col justify-center items-start gap-2 flex">
            <VmView className="NamaLengkap text-neutral-700 text-sm font-normal">
              Hubungan Dengan Pasien Lain
            </VmView>

            <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
              <VmButton
                className={'text-left text-sm py-3'}
                onClick={() => {
                  // onPress?.()
                  pilihHubunganPenanggungjawab()
                }}
                color="light"
                full
                tag={''}
              >
                {penpasienpenanggungjawab ||
                  'Pilih hubungan dengan pasien lain ->'}
              </VmButton>
            </VmView>
          </VmView>

          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="NamaLengkap text-neutral-700 text-sm font-normal">
              Nama Lengkap
            </VmView>
            <VmView className="SearchWrapper self-stretch justify-start items-center gap-2 flex">
              <VmInput
                onChange={e => setpennama(e.target.value)}
                value={pennama}
                typeInput={'text'}
                className="w-full py-1.5"
              />
            </VmView>
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="Nik text-neutral-700 text-sm font-normal">
              NIK
            </VmView>
            <VmInput
              onChange={e => setpennik(e.target.value)}
              value={pennik}
              typeInput={'text'}
              className="w-full py-1.5"
            />
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="HubunganKeluarga text-neutral-700 text-sm font-normal">
              Hubungan Keluarga
            </VmView>

            <select
              onChange={e => {
                setpenhubungan(e.target.value)
              }}
              value={penhubungan}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="0" disabled>
                Pilih Hubungan Keluarga
              </option>
              {[
                {value: 1, text: 'Orang Tua'},
                {value: 2, text: 'Anak'},
                {value: 3, text: 'Pasangan (Suami/Istri)'},
                {value: 4, text: 'Saudara'},
                {value: 5, text: 'Lainnya'},
              ].map((hub, i) => (
                <option key={i} value={hub.value}>
                  {hub.text}
                </option>
              ))}
            </select>
          </VmView>
        </VmView>
        <VmView className="Left grow shrink basis-0 flex-col justify-center items-center gap-6 flex">
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="TanggalRegistrasi text-neutral-700 text-sm font-normal">
              Jenis Kelamin
            </VmView>
            <select
              onChange={e => {
                setpenjk(e.target.value)
              }}
              value={penjk}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="0" disabled>
                Pilih Jenis Kelamin
              </option>
              <option value="Laki-laki">Laki-laki</option>
              <option value="Perempuan">Perempuan</option>
            </select>
            {/* <VmView className="SearchBox self-stretch p-2.5 bg-neutral-100 rounded-lg justify-start items-center gap-2 flex">
              <VmView className="LakiLaki grow shrink basis-0 text-neutral-700 text-sm font-medium">
                Laki-laki
              </VmView>
            </VmView> */}
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="NoTelepon text-neutral-700 text-sm font-normal">
              No. Telepon
            </VmView>
            <VmInput
              onChange={e => {
                let val = e.target.value
                let containNonNumeric = /[^0-9]/.test(val)
                if (isNaN(val) || containNonNumeric) {
                  return
                }
                setpentelp(val)
              }}
              value={pentelp}
              typeInput={'text'}
              className="w-full py-1.5"
            />
          </VmView>
          <VmView className="TextinputGroup self-stretch flex-col justify-center items-start gap-2 flex">
            <VmView className="Alamat text-neutral-700 text-sm font-normal">
              Alamat
            </VmView>
            <VmInput
              onChange={e => setpenalamat(e.target.value)}
              value={penalamat}
              typeInput={'textarea'}
              className="w-full py-1.5"
            />
          </VmView>
        </VmView>
      </VmView>

      <VmView className="ConsentWrap p-3 justify-start items-start gap-3 flex">
        <VmView className="CheckboxWrap py-1 justify-start items-start gap-2.5 flex">
          {/* <VmView className="Checkbox w-4 bg-zinc-300" /> */}
          <input
            className="w-4 h-4"
            type="checkbox"
            disabled={pasconsent == 1} // jika dia sudah setuju sebelumnya, maka disabled (ini berfungsi ketika ubah pasien)
            name=""
            id=""
            checked={consent1}
            onChange={e => setconsent1(e.target.checked)}
          />
        </VmView>
        <VmView className="SayaMenjaminBahwaPasienSudahMembacaDanMenandatanganiFormConsentPembukaanDataDariSatusehat grow shrink basis-0 text-neutral-700 text-sm font-medium">
          Saya menjamin bahwa pasien sudah membaca dan menandatangani form
          consent pembukaan data dari SATUSEHAT
        </VmView>
      </VmView>
      <VmView className="ConsentWrap p-3 justify-start items-start gap-3 flex">
        <VmView className="CheckboxWrap py-1 justify-start items-start gap-2.5 flex">
          {/* <VmView className="Checkbox w-4 bg-zinc-300" /> */}
          <input
            className="w-4 h-4"
            type="checkbox"
            disabled={pasconsent == 1} // jika dia sudah setuju sebelumnya, maka disabled (ini berfungsi ketika ubah pasien)
            name=""
            id=""
            checked={consent2}
            onChange={e => setconsent2(e.target.checked)}
          />
        </VmView>
        <VmView className="SayaMenjaminBahwaPasienMenyutujuiUntukMenerimaDanMembukaDataPasienDariFasilitasPelayananKesehatanLainnyaMelaluiSatusehatUntukKepentinganPelayananKesahatanDanAtauRujukan grow shrink basis-0 text-neutral-700 text-sm font-medium">
          Saya menjamin bahwa pasien menyutujui untuk menerima dan membuka data
          pasien dari fasilitas pelayanan kesehatan lainnya melalui SATUSEHAT
          untuk kepentingan pelayanan kesahatan dan/atau rujukan
        </VmView>
      </VmView>
      <VmView className="ConsentWrap p-3 justify-start items-start gap-3 flex">
        <VmView className="CheckboxWrap py-1 justify-start items-start gap-2.5 flex">
          {/* <VmView className="Checkbox w-4 bg-zinc-300" /> */}
          <input
            className="w-4 h-4"
            type="checkbox"
            disabled={pasconsent == 1} // jika dia sudah setuju sebelumnya, maka disabled (ini berfungsi ketika ubah pasien)
            name=""
            id=""
            checked={consent3}
            onChange={e => setconsent3(e.target.checked)}
          />
        </VmView>
        <VmView className="SayaMenjaminBahwaPasienMenyutujuiDanMengetahuiBahwaBerdasarkanPeraturanMentriKesehatanNomor24Tahun2022TentangRekamMedisFasilitasPelayananKesehatanWajibMembukaAksesDanMengirimDataRekamMedisKepadaKementrianKesehatanMelaluiPlatformSatusehat grow shrink basis-0 text-neutral-700 text-sm font-medium">
          Saya menjamin bahwa pasien menyutujui dan mengetahui bahwa berdasarkan
          peraturan mentri kesehatan nomor 24 tahun 2022 tentang rekam medis,
          fasilitas pelayanan kesehatan wajib membuka akses dan mengirim data
          rekam medis kepada kementrian kesehatan melalui platform SATUSEHAT
        </VmView>
      </VmView>

      <VmView className="BtnContainer justify-between items-start flex flex-row-reverse p-3">
        <VmButton
          variant="outline"
          className={classNames('mr-2 rounded-lg px-4 py-2')}
          onClick={() => {
            navigate(-1)
          }}
          color="danger"
          tag={''}
          disabled={false}
        >
          {'Batal'}
        </VmButton>

        <VmButton
          className={classNames('ml-2 rounded-lg px-4 py-2')}
          onClick={() => {
            validate()
          }}
          color="success"
          tag={''}
          disabled={false}
        >
          {'Simpan'}
        </VmButton>
        {/* <VmView className="BtnSimpan w-20 px-4 py-2.5 bg-emerald-500 rounded-lg justify-center items-center gap-2.5 flex">
          <VmView className="Simpan text-white text-sm font-medium">
            Simpan
          </VmView>
        </VmView>
        <VmView className="BtnBatal px-4 py-2.5 bg-white rounded-lg border border-red-500 justify-center items-center gap-2 flex">
          <VmView className="Batal text-red-500 text-sm font-medium">
            Batal
          </VmView>
        </VmView> */}
      </VmView>
    </VmView>
  )
}
