import * as React from 'react'

function VmSuster({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        fill: 'none',
        viewBox: '0 0 20 20',
        strokeWidth: 1.5,
        stroke: 'currentColor',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      d: 'M 10,0 C 8.38461,0 7.41769,0.246154 6.80307,0.553077 6.49538,0.706923 6.27692,0.868462 6.13,1.03385 6.02911,1.15674 5.94789,1.29453 5.88923,1.44231 5.86511,1.50409 5.84907,1.56872 5.84153,1.63462 L 5.4323,6.89923 c -0.00538,0.03231 0.00539,0.08462 0,0.12 L 5.38461,7.54846 c -0.00448,0.04785 -0.00448,0.096 0,0.14385 0,2.53849 2.07692,4.61539 4.61539,4.61539 2.5385,0 4.6154,-2.0769 4.6154,-4.61539 v -0.12 c 1e-4,-0.00795 1e-4,-0.0159 0,-0.02385 L 14.1585,1.63462 c -0.0076,-0.0659 -0.0236,-0.13053 -0.0477,-0.19231 0,0 -0.0931,-0.24308 -0.2408,-0.40846 C 13.7231,0.867692 13.5046,0.706154 13.1969,0.553077 12.5823,0.245385 11.6154,0 10,0 Z m 0,12.3077 c -5.07693,0 -9.230774,2.3892 -9.230774,4.5431 V 20 H 19.2308 v -3.1492 c 0,-2.0462 -3.7554,-4.3 -8.4854,-4.5193 C 10.4973,12.3152 10.2487,12.3072 10,12.3077 Z M 10,1.53846 c 1.4615,0 2.1915,0.23077 2.5,0.38462 0.1031,0.05154 0.1154,0.07154 0.1446,0.09615 l 0.3123,3.94231 C 12.2492,6.00462 11.2846,6.15385 10,6.15385 8.71538,6.15385 7.75153,6.00462 7.04307,5.96154 L 7.35538,2.01923 C 7.38384,1.99462 7.39692,1.97462 7.5,1.92308 7.80846,1.76923 8.53846,1.53846 10,1.53846 Z M 9.37461,2.30769 V 3.22154 H 8.46153 v 1.25 H 9.37538 V 5.38462 H 10.6246 V 4.47077 h 0.9139 V 3.22154 H 10.6246 V 2.30769 Z M 7.6923,14.0385 9.66384,18.4615 H 2.30769 v -1.6107 c 0,-0.5731 1.96154,-2.2516 5.38461,-2.8123 z m 4.6154,0 c 3.4231,0.5615 5.3846,2.2392 5.3846,2.8123 v 1.6107 h -7.3562 z',
    })
  )
}

const ForwardRef = React.forwardRef(VmSuster)
export default ForwardRef
