import {VmModal} from 'components'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import HandlerForm from './HandlerForm'
import {ProgressFullScreen} from 'molecules'
import {
  useRef,
  forwardRef,
  createRef,
  useImperativeHandle,
  Fragment,
  useMemo,
  useState,
} from 'react'
import {DomAttributes} from 'config/attributes'
import {func, string} from 'prop-types'
import {defaultpropssimpletoast} from 'entries/simpletoast'
import {getLclStorage, removeLclStorage} from 'utils/localstorage'
import {APP_VARIABLES, STRG_OPT} from 'utils/core'
import SimpleToast from 'components/SimpleToast'
import AlertForm from './AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import {isArray} from 'utils'
import {isFunction, isNode} from 'utils/typeof'

const defaultFunct = e => {}

var FormTemplate = {
  propTypes: {...DomAttributes, ref: func, label: string},
}
FormTemplate = forwardRef(
  (
    {
      // Function Handler
      /**
       * Function (Html event)
       */
      onKeyDown = defaultFunct,
      /**
       * Function (Html event, value)
       */
      onChangeCapture = defaultFunct,
      listForm = null,
      loading = false,
      label = 'FormTemplate',
      children,
      propsalertform = defaultpropsalertform,
      onCloseModal = () => {},
      ...attributes
    },
    ref
  ) => {
    const formRef = useRef(),
      yesNoRef = useRef(),
      okRef = useRef(),
      modalRef = createRef(),
      [propssimpletoast, setpropssimpletoast] = useState(
        defaultpropssimpletoast
      ),
      [modalposition, setmodalposition] = useState(null)

    const onShowToast = ({
      message = '-',
      type = 'Success',
      descriptions = null,
    }) => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        setpropssimpletoast(defaultpropssimpletoast)
        clearTimeout(newtimeout)
      }, 3000)
      var toastericon = 'VmCheckCircleIcon'
      if (type === 'Fail') {
        toastericon = 'VmXCircleIcon'
      }
      if (type === 'Warning') {
        toastericon = 'VmExclamationCircleIcon'
      }
      if (type === 'Info') {
        toastericon = 'VmInformationCircleIcon'
      }
      return setpropssimpletoast({
        toastericon,
        toastertitle: message,
        toastervisible: true,
        toastertype: type,
        toasterdesc: descriptions,
      })
    }
    const listenSimpleToast = () => {
      var toastData = getLclStorage(APP_VARIABLES.SimpleToast, STRG_OPT)
      if (toastData === null) {
        toastData = {length: false}
      }
      var newtimeout = null
      newtimeout = setTimeout(() => {
        if (toastData?.length) {
          toastData = JSON.parse(toastData)
          if (typeof toastData?.message === 'string') {
            onShowToast(toastData)
            removeLclStorage(APP_VARIABLES.SimpleToast, STRG_OPT)
          }
        }
        clearTimeout(newtimeout)
        listenSimpleToast()
      }, 500)
    }
    const handleModal = (e, position = null) => {
      if (position) {
        setmodalposition(position)
      }
      if (!!e) {
        if (![isArray(e), isNode(e), isFunction(e)].includes(true)) {
          modalRef.current.close()
          setmodalposition(null)
        } else {
          modalRef.current.open(e)
        }
      } else {
        if (!!modalRef?.current?.isOpen) {
          modalRef.current.close()
        }
      }
    }
    useMemo(() => {
      listenSimpleToast()
    }, []) /* eslint-disable-line */
    useImperativeHandle(ref, () => ({
      formRef: formRef.current,
      modalRef: modalRef.current,
      yesNoRef: yesNoRef.current,
      okRef: okRef.current,
      onShowToast: onShowToast,
      handleModal,
    }))
    return (
      <Fragment>
        <form
          {...attributes}
          ref={formRef}
          aria-label={label}
          onChangeCapture={e =>
            onChangeCapture(e, e?.nativeEvent?.target?.value)
          }
          onKeyDown={e => onKeyDown(e, formRef.current)}
          dangerouslySetInnerHTML={undefined}
        >
          {listForm === null ? <></> : <HandlerForm data={listForm} />}
          {children}
        </form>
        <AlertYesNo ref={yesNoRef} />
        <AlertOk ref={okRef} />
        <VmModal
          // key={`m-${Date.now()}`}
          ref={modalRef}
          position={modalposition}
          onClose={onCloseModal}
        />
        <ProgressFullScreen visible={loading} />
        <SimpleToast {...propssimpletoast} />
        <AlertForm {...propsalertform} />
      </Fragment>
    )
  }
)

export default FormTemplate
