import classNames from 'classnames'
import {VmButton} from 'components/button'
import {VmArchiveBoxIcon} from 'components/icons/outline'
import VmText from 'components/VmText'
import React, {useState} from 'react'
import {toCurrency} from 'utils/functions'
import Metode from './Metode'

const vaMap = {
  mandiri: {
    nama: 'MANDIRI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka aplikasi Mandiri Online dan login menggunakan USERNAME dan PASSWORD Anda',
      'Pilih "Bayar"',
      'Pilih "Multipayment"',
      'Pilih Xendit 88608 atau Xendit 88908 sebagai penyedia jasa, sesuai dengan 5 digit awal dari Nomor Virtual Account yang tampil',
      'Masukkan Nomor Virtual Account yang tampil',
      'Tekan Lanjut',
      'Tinjau dan konfirmasi detail transaksi anda, lalu tekan Konfirmasi',
      'Selesaikan transaksi dengan memasukkan MPIN Anda',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  bri: {
    nama: 'BRI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka aplikasi BRI Mobile Banking dan login menggunakan USER ID dan PIN Anda',
      'Pilih "Pembayaran" dan pilih "Briva"',
      'Masukkan Nomor Virtual Account yang tampil',
      'Masukkan PIN Mobile Banking BRI',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  bni: {
    nama: 'BNI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password Anda',
      'Pilih Menu "Transfer"',
      'Pilih Menu "Virtual Account Billing" kemudian pilih rekening debet',
      'Masukkan Nomor Virtual Account yang tampil',
      'Konfirmasi transaksi Anda',
      'Masukkan Password Transaksi',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  permata: {
    nama: 'PERMATA VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka Aplikasi PermataMobile Internet dan login menggunakan User ID dan Password Anda',
      'Pilih "Pembayaran Tagihan"',
      'Pilih "Virtual Account"',
      'Masukkan Nomor Virtual Account yang tampil',
      'Masukkan otentikasi transaksi/token',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  qris: {
    nama: 'Generate QRIS',
    langkah: [
      'Pastikan kamu memiliki aplikasi pembayaran bank atau e-wallet yang mendukung QRIS.',
      'Setelah klik “Bayar”, kamu akan menerima QR code.',
    ],
  },
}
export default function ModalPilihanPaket({
  visible,
  onClose,
  selectedPaket,
  data,
  onSelected = () => {},
  ...props
}) {
  const [selectedMethod, setSelectedMethod] = useState('')

  return (
    <div
      className={classNames('relative z-20', {
        hidden: !visible,
        block: visible,
      })}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
          {/* <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      --> */}

          <div
            id="modal-content"
            className="flex-1 mx-0 relative bg-white rounded-xl shadow overflow-hidden"
          >
            {/* <!-- Modal header --> */}
            <div
              style={{
                backgroundImage: 'linear-gradient(to left, #3ab6fe, #0185d0) ',
              }}
              className="flex justify-between items-center px-6 py-4 border-b "
            >
              <h3 className="text-white text-base font-semibold text-gray-900 lg:text-lg">
                Daftar Pilihan Paket
              </h3>
              <button
                onClick={_ => onClose?.()}
                type="button"
                className="text-danger bg-white hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-2 ml-auto inline-flex items-center"
                data-modal-toggle="crypto-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            {/* <!-- Modal body --> */}
            <div className="p-6">
              {data.map((pkt, i) => {
                return (
                  <ItemPaket
                    onClick={e => {
                      onSelected(pkt)
                    }}
                    key={i}
                    data={pkt}
                  ></ItemPaket>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ItemPaket = ({onClick, data, ...props}) => {
  const {pktNama, pktHarga, pktDiskon} = data
  let hargaFinal = parseFloat(pktHarga) - parseFloat(pktDiskon)
  return (
    <div
      className="flex item p-4 items-center justify-start rounded-xl shadow-lg"
      onClick={onClick}
    >
      <VmArchiveBoxIcon
        className={classNames('text-primary w-6 h-6')}
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
      ></VmArchiveBoxIcon>
      <div className="flex-1 ml-4">
        <VmText className="text-xs">{pktNama}</VmText>
        <VmText className="text-sm text-danger">
          {toCurrency(hargaFinal)}
        </VmText>
        <VmText className="text-xs text-secondary line-through">
          {toCurrency(pktHarga)}
        </VmText>
      </div>
    </div>
  )
}
