import classNames from 'classnames'
import {VmView} from 'components'

const ListEtiket = ({
  list = [],
  data = {},
  setdata = () => {},
  closeBs = () => {},
}) => {
  return (
    <VmView className="px-4 pb-2">
      {list?.length > 0 ? (
        list.map(({type, title}, index) => {
          const active = type === data?.etiket
          return (
            <VmView
              className={classNames(
                'border rounded-xl text-center mb-2 py-1 font-semibold',
                active
                  ? 'bg-blue1-payment text-white'
                  : 'text-blue1-payment bg-white'
              )}
              onClick={e => {
                closeBs(e)
                setdata({
                  ...data,
                  etiket: type,
                  etiketTypeTitle: title,
                  timestamp: Date.now(),
                })
              }}
              key={`${type} + ${index} + ${title}`}
            >
              {title}
            </VmView>
          )
        })
      ) : (
        <VmView
          className={classNames(
            'border rounded-xl text-center mb-2 py-1 font-semibold'
          )}
        >
          List kosong!
        </VmView>
      )}
    </VmView>
  )
}

export default ListEtiket
