import qs from 'qs'
import axios from 'axios'
import {APIBPJS} from 'config/apiurl'
import {defError} from 'utils/functions'

export const qKodeBiayaBpjs = async args => {
  try {
    const {data} = await axios.post(
      APIBPJS + '/bpjs-jkn/get-data-pcare',
      qs.stringify({
        ...args,
        url: 'tindakan/kdTkp/10/0/10',
        reg: 'dbdok',
        a: 'dk13',
      })
    )
    return data

    // return {
    //   status: 1,
    //   response: {
    //     list: JSON.parse(localStorage.getItem('tempkdbpjs')),
    //   },
    // metaData: {
    //   message: 'NO_CONTENT',
    // },
    // }
  } catch (e) {
    return defError('EB-2', 'Gagal saat mengambil data, silakan coba kembali!')
  }
}
