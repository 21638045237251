import classNames from 'classnames'
import {VmButton, VmText, VmView} from 'components'
import {memo} from 'react'

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  // console.log('prevProps, nextProps 1', prevProps, nextProps)
}

export default memo(function VmWrapCart({
  children,
  mode = '',
  title = 'Detail Biaya',
  buttonTitle = 'Bayar',
  priceSubTitile = '420.000',
  priceTitle = 'Total Biaya',
  onClickCheckout = () => {},
  visibleprops = {},
  height = '-webkit-fill-available',
}) {
  const wrapmode = mode === 'phone' ? '' : 'flex-1'
  const wrapleft = classNames(wrapmode, 'flex flex-col items-start')
  const wrapright = classNames(wrapmode, 'flex')
  const visible = {header: true, body: true, footer: true, ...visibleprops}
  return (
    <VmView style={{height}} className={classNames('VmWrapCart', mode)}>
      {visible?.header && (
        <VmText
          style={{
            background: 'linear-gradient(90.73deg, #0084CF 0%, #3CB7FF 100%)',
          }}
          className={classNames('header !text-left !text-white', mode)}
        >
          {title}
        </VmText>
      )}
      {visible?.body && (
        <VmView className={classNames('body', mode)}>{children}</VmView>
      )}

      {/* Tampilkan Footer pada card kalau mode Tablet (web) */}
      {visible?.footer && mode == 'web' ? (
        <VmView className={classNames('footer', mode)}>
          <VmView className={wrapleft}>
            <VmText className={classNames('totalTitle', mode)}>
              {priceTitle}
            </VmText>
            <VmText className={classNames('total', mode)}>
              {priceSubTitile}
            </VmText>
          </VmView>
          <VmView className={wrapright}>
            <VmButton
              onClick={onClickCheckout}
              full
              className={classNames('bayar', mode)}
            >
              {buttonTitle}
            </VmButton>
          </VmView>
        </VmView>
      ) : null}
    </VmView>
  )
},
areEqual)
