const colors = {
  // variant default
  primary: `bg-blue-500 hover:bg-blue-600 text-slate-50`,
  secondary: `bg-stone-400 hover:bg-stone-500 text-white`,
  success: `bg-emerald-500 hover:bg-emerald-600 text-white`,
  danger: `bg-red-500 hover:bg-red-600 text-white`,
  warning: `bg-yellow-500 hover:bg-yellow-600 text-white`,
  info: `bg-sky-500 hover:bg-sky-600 text-white`,
  dark: `bg-slate-500 hover:bg-slate-600 text-white`,
  light: `bg-slate-50 hover:bg-slate-200 text-slate-500`,
  // variant outline
  'primary-outline': `border border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-slate-50`,
  'secondary-outline': `border border-stone-400 hover:bg-stone-400 text-stone-400 hover:text-white`,
  'success-outline': `border border-emerald-500 hover:bg-emerald-500 text-emerald-500 hover:text-white`,
  'danger-outline': `border border-red-500 hover:bg-red-500 text-red-500 hover:text-white`,
  'warning-outline': `border border-yellow-500 hover:bg-yellow-500 text-yellow-500 hover:text-white`,
  'info-outline': `border border-sky-500 hover:bg-sky-500 text-sky-500 hover:text-white`,
  'dark-outline': `border border-slate-500 hover:bg-slate-500 text-slate-500 hover:text-white`,
  'light-outline': `border border-slate-50 hover:bg-slate-50 text-slate-100 hover:text-slate-600`,
  // variant ghost
  'primary-ghost': `hover:bg-blue-500 text-blue-500 hover:text-white`,
  'secondary-ghost': `hover:bg-stone-400 text-stone-400 hover:text-white`,
  'success-ghost': `hover:bg-emerald-500 text-emerald-500 hover:text-white`,
  'danger-ghost': `hover:bg-red-500 text-red-500 hover:text-white`,
  'warning-ghost': `hover:bg-yellow-500 text-yellow-500 hover:text-white`,
  'info-ghost': `hover:bg-sky-500 text-sky-500 hover:text-white`,
  'dark-ghost': `hover:bg-slate-500 text-slate-500 hover:text-white`,
  'light-ghost': `hover:bg-slate-50 text-slate-100 hover:text-slate-600`,
}

const rgbcolors = {
  primary: 'rgb(59, 130, 246)',
  secondary: 'rgb(168, 162, 158)',
  success: 'rgb(16, 185, 129)',
  danger: 'rgb(239, 68, 68)',
}

export {rgbcolors}
export default colors
