import {VmView} from 'components'

export default function Index({data}) {
  return (
    <>
      <VmView className="flex flex-row">
        <VmView className="flex basis-1/2">
          <p className="text-blue-500">Jumlah Kartu</p>
        </VmView>
        <VmView className="flex basis-1/2 text-right text-gray-500">
          <p className="w-full">{data.card}</p>
        </VmView>
      </VmView>
      <VmView className="flex flex-row">
        <VmView className="flex basis-1/2">
          <p className="text-blue-500">Jumlah Tunai</p>
        </VmView>
        <VmView className="flex basis-1/2 text-right text-gray-500">
          <p className="w-full">{data.cardcash}</p>
        </VmView>
      </VmView>
      {data.cashback > 0 ? (
        <VmView className="flex flex-row">
          <VmView className="flex basis-1/2">
            <p className="text-blue-500">Kembalian</p>
          </VmView>
          <VmView className="flex basis-1/2 text-right text-gray-500">
            <p className="w-full">{data.cashback}</p>
          </VmView>
        </VmView>
      ) : (
        ''
      )}
    </>
  )
}
