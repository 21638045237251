import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmIcons, VmInput, VmModal, VmText, VmView} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {catatLog, empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
import {BASE_URL} from './PrdBiayaPemeriksaan'
import QueryString from 'qs'
import {Navigate, useLocation, useSearchParams} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {API, graphqlOperation} from 'aws-amplify'
import {mInsertBiayaPemeriksaan} from '../../graphql/mutations'
import ModalItem from './ModalKodeBiayaBpjs/ModalItem'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'

const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  REACT_APP_BASE_URL_SATUSEHAT: APISATUSEHAT,
} = process.env

export default function PrdBiayaPemeriksaanTambah(props) {
  // const [bianama, setBianama] = useState('')
  // const [biatarif, setBiatarif] = useState('')
  // const [biadiskon, setBiadiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [alertProps, setAlertProps] = useState(defaultpropsalertform),
    [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [uid, setUid] = useState(searchParams.get('dctid'))
  const [device, setDevice] = useState('')
  const location = useLocation()
  const custom = location.state?.custom
  const closeAlert = () => setAlertProps(defaultpropsalertform)

  const alert = useRef(null)
  const prompt = useRef(null)

  // custom bpjs pcare
  const [customTindakanPcare, setCustomTindakanPcare] = useState('')
  const dataModalRef = useRef()
  const resetbiatarif = useRef()
  const entriesCustomPcare = custom?.['121']
    ? {
        key: 'biakodepbpjs',
        caption: 'Kode Biaya BPJS',
        type: 'custom',
        Component: () => (
          <>
            <VmText>Kode Biaya BPJS</VmText>
            <VmView
              onClick={() => {
                resetbiatarif.current = false
                dataModalRef.current.open()
              }}
              className="flex justify-between w-full border-[#e5e7eb] border rounded-xl p-2 mb-4 mt-1"
            >
              <VmText className="text-black1-payment">
                {`${customTindakanPcare?.kdTindakan || ' '} - ${
                  customTindakanPcare?.nmTindakan || ' '
                }`}
              </VmText>
              <VmIcons name="VmChevronDownIcon" size={18} className="w-10" />
            </VmView>
            <VmModal ref={dataModalRef}>
              <ModalItem
                uid={uid}
                onClose={() => dataModalRef.current.close()}
                onSelect={val => {
                  dataModalRef.current.close()
                  setCustomTindakanPcare(val)
                }}
              />
            </VmModal>
          </>
        ),
      }
    : {}

  const detectDevice = () => {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    setDevice(isMobile ? 'mobile' : 'web')
  }

  useEffect(() => {
    detectDevice()
    return () => {}
  }, [])

  let simpan = data => {
    let {bianama, biatarif, biadiskon, biaicdkode} = data
    biadiskon = biadiskon?.replace(',', '.')
    bianama = bianama.trim()
    biaicdkode = biaicdkode.trim()
    // console.log({bianama, biatarif, biadiskon})
    // return
    axios
      .post(
        `${APIDOC}/biaya-v2/create`,
        QueryString.stringify({
          uid,
          a: 'dk13',
          data: {
            bianama,
            biatarif,
            biadiskon,
            biaicdkode,
            biakodebpjs: customTindakanPcare?.kdTindakan || '',
          },
        })
      )
      .then(({data: res}) => {
        const resData = res

        if (!resData) {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Perawatan / Tindakan',
          })
        } else if (resData?.status != 1) {
          alert.current.show({
            message:
              resData?.message ||
              'Terjadi kesalahan saat Tambah Perawatan / Tindakan',
          })
        } else {
          let dataLog = {
            keterangan: `Menambahkan data Biaya Pemeriksaan ${bianama}`,
            menu: 'Billing - Perawatan / Tindakan',
            mn_id: 23,
            url: 'prd-mas-biaya-pemeriksaan',
            before: JSON.stringify({
              '-': '',
            }),
            after: JSON.stringify({
              Nama: bianama,
              Tarif: toCurrency(biatarif),
              Diskon: `${biadiskon}%`,
              'Kode ICD-9-CM': biaicdkode,
            }),
            kl_id: 19,
            // ip: publicIp,
            a: 'dk13',
            uid: uid,
            reg: 'db',
          }
          catatLog(dataLog).finally(() => setRedirect(true))

          // getInitialData()
        }
        setSaving(false)
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Perawatan / Tindakan',
          })
          console.log('Error menambahkan Perawatan / Tindakan', errMsg)
        }
      })
  }
  let icd9cmValidation = data => {
    axios
      .post(
        `${APIDOC}/biaya-v2/is-valid-icd9cm?kode=${data.biaicdkode}`,
        QueryString.stringify({
          uid,
          a: 'dk13',
        })
      )
      .then(res => {
        let resData = res?.data
        if (resData?.data === 2) {
          setAlertProps({
            ...defaultpropsalertform,
            type: 3,
            visible: true,
            children: (
              <>
                {resData?.message ||
                  'Kode ICD-9-CM tidak valid, Apakah ingin memproses data?'}
              </>
            ),
            onConfirm: () => {
              // update Data ke Database
              setSaving(true)
              simpan(data)
            },
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        } else {
          // update Data ke Database
          setSaving(true)
          simpan(data)
        }
      })
      .catch(err => {
        setAlertProps({
          ...defaultpropsalertform,
          type: 4,
          visible: true,
          children: <>{'Gagal memvalidasi data ICD-9-CM!'}</>,
          onConfirm: closeAlert,
          onClose: closeAlert,
          onCancel: closeAlert,
        })
      })
  }

  return (
    <>
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {redirect ? (
        <Navigate
          to={`/prd-mas-biaya-pemeriksaan?dctid=${uid}`}
          replace={true}
        />
      ) : null}

      <VmForm
        headerTitle={'Tambah Data Perawatan / Tindakan'}
        onFabPress={() => {}}
        contentContainerClassName="px-4"
        footerOptions={{
          visible: true,
          btnSimpanTitle: 'Simpan',
          btnSimpanDisabled: saving ? true : false,
          btnBatalDisabled: saving ? true : false,
          btnBatalTitle: 'Batal',
          onCancel: () => {
            setRedirect(true)
          },
        }}
        fields={[
          {
            key: 'biaicdkode',
            caption: 'Kode ICD-9-CM',
            type: 'input',
            variant: 'text',
            _style: {fontWeight: 'bold'},
            className: '',
          },
          {
            key: 'bianama',
            caption: 'Nama',
            type: 'input',
            variant: 'text',
            _style: {fontWeight: 'bold'},
            className: '',
          },
          {...entriesCustomPcare},
          {
            key: 'biatarif',
            caption: 'Tarif',
            type: 'input',
            variant: 'autonumeric',
            allowDecimals: false,
            min: 0,
            customValue: state => {
              if (!!customTindakanPcare?.maxTarif && !resetbiatarif.current) {
                resetbiatarif.current = true
                return (state.biatarif = customTindakanPcare?.maxTarif)
              }
              if (!resetbiatarif.current) resetbiatarif.current = true

              return state.biatarif
            },
          },
          {
            mode: 'horizontal',
            content: [
              {
                key: 'biadiskon',
                caption: 'Diskon',
                type: 'input',
                variant: 'autonumeric',
                defaultValue: '0.00',
                containerClassName: 'flex-1 mr-2',
                max: 100,
                allowDecimals: true,
              },
              {
                key: 'bianominaldiskon',
                caption: 'Nominal Diskon',
                type: 'input',
                variant: 'text',
                defaultValue: '0',
                containerClassName: 'flex-1 ml-2',
                readOnly: true,
                disabled: true,
                customValue: state => {
                  let {biatarif, biadiskon} = state
                  // console.log('original', {biatarif, biadiskon})
                  if (empty(biatarif)) {
                    // Jika kosong, kasih default 0
                    biatarif = '0'
                  }
                  if (empty(biadiskon)) {
                    // Jika kosong, kasih default 0
                    biadiskon = '0'
                  }
                  // console.log('after modified', {biatarif, biadiskon})

                  let diskon = biadiskon.replace(',', '.')
                  let nominaldiskon =
                    (parseFloat(diskon) / 100) * parseFloat(biatarif)

                  let formatted = formatValue({
                    value: `${nominaldiskon}`,
                    groupSeparator: '.',
                    decimalSeparator: ',',
                    prefix: '',
                    decimalScale: 0,
                  })
                  // console.log({nominaldiskon, formatted})
                  return formatted
                },
              },
            ],
          },
        ]}
        onSimpan={data => {
          // do Something
          const {bianama, biatarif, biadiskon} = data
          // console.log({
          //   bianama,
          //   biatarif,
          //   biadiskon,
          // })
          // return
          // Cek Data Kosong
          if (!bianama.length) {
            alert.current.show({message: 'Nama Biaya harus diisi'})
            return
          } else if (!biatarif) {
            alert.current.show({message: 'Tarif Biaya harus diisi'})
            return
          } else if (!biadiskon) {
            alert.current.show({message: 'Diskon Biaya harus diisi'})
            return
          }

          // Cek value minus
          if (parseFloat(biatarif) < 0) {
            alert.current.show({message: 'Tarif Biaya tidak boleh minus.'})
            return
          } else if (parseFloat(biadiskon) < 0) {
            alert.current.show({message: 'Diskon Biaya tidak boleh minus.'})
            return
          }
          if (data?.biaicdkode?.length > 0) {
            // Validasi Icd9cm
            icd9cmValidation(data)
          } else {
            // update Data ke Database
            setSaving(true)
            simpan(data)
          }
        }}
        device={device}
      ></VmForm>

      {/* AlertForm */}
      <AlertForm {...alertProps} />
      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
