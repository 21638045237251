import React, {Component} from 'react'
import VmHeader from 'components/layout/VmHeader'
import {VmFab, VmList, VmPicker, VmView} from 'components'
import {toCurrency} from 'utils/functions'
import VmIndexRead from 'components/template/VmIndexRead'
import axios from 'axios'
import QueryString from 'qs'
import AlertYesNo from 'components/AlertYesNo'
import AlertOk from 'components/AlertOk'
import {Navigate, redirect, useSearchParams} from 'react-router-dom'
import {VmCheckIcon, VmTrashIcon} from 'components/icons/solid'
import {API, graphqlOperation} from 'aws-amplify'
import {mAktifkanDiagnosa, mDeleteDiagnosa} from 'graphql/mutations'
const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

const dateColWidth = 'min-w-[200px]'
const namaAptColWidth = 'min-w-[160px]'

class PrdDiagnosa extends Component {
  fields = [
    {
      key: 'icdkode',
      _style: {fontWeight: 'bold'},
      className: 'text-zinc-600 mt-6',
    },
    {key: 'icdnama', _style: {fontWeight: 'bold'}, className: 'text-zinc-600'},
    {
      key: 'icdket',
      _style: {fontWeight: 'normal'},
      className: 'text-zinc-600 pb-2 border-blue-500 border-b-2',
      // containerClassName: '',
    },
  ]
  fieldsDesktop = [
    {
      key: 'icdkode',
      label: 'Kode',
      _style: {fontWeight: 'bold'},
      className: 'text-[#3B82F6] mt-6',
      sorter: true,
    },
    {
      key: 'icdnama',
      label: 'Nama',
      _style: {fontWeight: 'bold'},
      className: 'text-[#3B82F6]',
      sorter: true,
    },
    {
      key: 'icdket',
      label: 'Keterangan',
      _style: {fontWeight: 'bold'},
      className: 'text-[#3B82F6] pb-2 border-blue-500 border-b-2',
      sorter: true,
      // containerClassName: '',
    },
    {
      key: 'icdaktif',
      type: 'map',
      mapValue: {1: 'Aktif', 2: 'Non Aktif'},
      label: 'Status',
      _style: {fontWeight: 'bold'},
      className: 'text-[#3B82F6] pb-2 border-blue-500 border-b-2',
      sorter: false,
      filterType: 'select',
      filterValue: [
        {value: 1, text: 'Aktif'},
        {value: 2, text: 'Non Aktif'},
      ],
      // containerClassName: '',
    },
  ]

  constructor(props) {
    super(props)

    const {searchParams} = props
    this.state = {
      ubahId: 0,
      tambah: false,
      ubah: false,
      limit: 10, // Limit data per page
      data: [],
      itemsCount: 0,
      uid: searchParams.get('dctid'),
      fetchingMore: false,
      gettingInitialData: true,
      hasMoreItems: true,
      device: 'mobile', // Bisa Web (Tabel) / Mobile (List)
      cari: '', // Value pencarian user
      icdaktif: 1, // Filter flag status

      // Filter untuk pencarian web
      sort: '',
      activePage: 1,
      icdnama: '',
      icdkode: '',
      icdket: '',
    }
  }

  componentDidMount() {
    // console.log('cdm')

    // Detect device
    this.detectDevice()
  }

  detectDevice = () => {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    this.setState({device: isMobile ? 'mobile' : 'web'}, this.getInitialData)
  }

  getInitialData = () => {
    const {icdnama, icdket, icdkode, sort} = this.state
    // console.log('getInitialData', {BASE_URL})
    let limit = 10
    this.setState({gettingInitialData: true})

    let params = {
      a: 'dk13',
      uid: this.state.uid,
      icdaktif: this.state.icdaktif,
      device: this.state.device,
      limit,
    }
    if (this.state.device == 'web') {
      let offset = (this.state.activePage - 1) * limit
      params = {...params, offset, icdnama, icdkode, icdket, sorting: sort}
    } else {
      params = {...params, cari: this.state.cari, offset: 0}
    }
    axios
      .post(`${BASE_URL}/diagnosa/index`, QueryString.stringify(params))
      .then(({data: res}) => {
        // console.log('Data Diagnosa', res)
        this.setState({data: res})

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        } else {
          this.setState({hasMoreItems: true})
        }
      })
      .catch(err => {
        console.log('Error get Data Diagnosa', err.message)
      })
      .finally(() => {
        this.setState({gettingInitialData: false})
      })

    axios
      .post(`${BASE_URL}/diagnosa/index-count`, QueryString.stringify(params))
      .then(({data: res}) => {
        // console.log('Data Diagnosa', res)
        this.setState({itemsCount: res.total})
      })
      .catch(err => {
        console.log('Error get Data Diagnosa count', err.message)
      })
  }

  refreshAfterActivateOrDelete = () => {
    if (this.state.device == 'web') {
      this.setState({activePage: 1}, this.getInitialData)
    } else {
      this.getInitialData()
    }
  }

  aktifkan = item => {
    API.graphql(
      graphqlOperation(mAktifkanDiagnosa, {
        input: {
          req: JSON.stringify({
            reg: 'dbdok',
            a: 'dk13',
            data: {
              icdid: item.icdid,
            },
            uid: this.state.uid,
          }),
        },
      })
    )
      .then(res => {
        console.log('Berhasil aktifkan Diagnosa', res)
        this.refreshAfterActivateOrDelete()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.aktifkan(item)
        } else {
          this.alert.show({
            message: 'Terjadi kesalahan saat mengaktifkan Diagnosa',
          })
          console.log('Error mengaktifkan Diagnosa', errMsg)
        }
      })
  }

  hapus = item => {
    API.graphql(
      graphqlOperation(mDeleteDiagnosa, {
        input: {
          req: JSON.stringify({
            reg: 'dbdok',
            a: 'dk13',
            data: {
              icdid: item.icdid,
            },
            uid: this.state.uid,
          }),
        },
      })
    )
      .then(res => {
        console.log('Hapus Data Diagnosa', res)
        this.refreshAfterActivateOrDelete()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          this.hapus(item)
        } else {
          this.alert.show({
            message: 'Terjadi kesalahan saat menghapus data Diagnosa. [BP-01]',
          })
          console.log('Error menghapus Data Diagnosa', errMsg)
        }
      })
  }

  fetchMore = () => {
    const {data, cari} = this.state
    // console.log({data})
    this.setState({fetchingMore: true})
    axios
      .post(
        `${BASE_URL}/diagnosa/index`,
        QueryString.stringify({
          a: 'dk13',
          uid: this.state.uid,
          offset: data.length,
          icdaktif: this.state.icdaktif,
          cari,
        })
      )
      .then(({data: res}) => {
        // console.log('Fetchmore Data Diagnosa', res)
        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
        this.setState({data: [...data, ...res]})
      })
      .catch(err => {
        console.log('Error get Data Diagnosa', err.message)
      })
      .finally(() => {
        this.setState({fetchingMore: false})
      })
  }

  cari = () => {
    const {cari} = this.state
    axios
      .post(
        `${BASE_URL}/diagnosa/index`,
        QueryString.stringify({
          a: 'dk13',
          uid: 36,
          cari,
        })
      )
      .then(({data: res}) => {
        console.log('Cari Data Diagnosa', res)
        this.setState({data: res})

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Diagnosa', err.message)
      })
  }

  render() {
    const {
      data,
      hasMoreItems,
      device,
      tambah,
      ubah,
      ubahId,
      icdaktif,
      itemsCount,
      uid,
    } = this.state
    // console.log('hasMoreItems', hasMoreItems)
    return (
      <VmView
        className=""
        // style={{fontFamily: ''}}
      >
        <AlertOk ref={ref => (this.alert = ref)} />
        <AlertYesNo ref={ref => (this.prompt = ref)} />

        {/* Component untuk navigasi */}
        {tambah ? (
          <Navigate
            to={`/prd-mas-data-diagnosa/tambah?dctid=${uid}`}
            replace={true}
          />
        ) : null}

        {/* Component untuk navigasi */}
        {ubah ? (
          <Navigate
            to={`/prd-mas-data-diagnosa/ubah/${ubahId}?dctid=${uid}`}
            replace={true}
          />
        ) : null}

        {/* <p>{this.state.device}</p> */}

        <VmIndexRead
          headerTitle={'Data Diagnosa'}
          items={data}
          itemsCount={itemsCount}
          fields={device == 'mobile' ? this.fields : this.fieldsDesktop}
          listItemContainerClassName={'p-4 border shadow-lg m-4 rounded'}
          listSearchbar={{placeholder: 'Cari Data Diagnosa'}}
          // listSearchbarLeft={<p>Test halo</p>}
          listSearchbarRight={
            <VmPicker
              style={{
                // flex: 0.2,
                background:
                  'url(https://cdn-icons-png.flaticon.com/512/32/32195.png) var(--sizeIco) no-repeat #FFF',
                paddingRight: '4px !important',
                paddingLeft: '4px',
              }}
              items={[
                {value: 1, text: 'Aktif'},
                {value: 2, text: 'Non Aktif'},
              ]}
              onChange={e => {
                let val = e.target.value
                this.setState({icdaktif: val}, this.getInitialData)
                // console.log('onChange gan', val)
              }}
              placeholder={null}
              value={icdaktif}
              className="rounded-xl text-sm mb-2 py-2"
            />
          }
          listSearchbarClassname="mr-2" // Class Untuk inputan filter search bar
          onItemPress={item => {}}
          editButton={item => {
            // Menampilkan button hanya untuk data yang icdaktifnya == 1
            return item.icdaktif == 1
          }}
          deleteButton={item => {
            // Menampilkan button hanya untuk data yang icdaktifnya == 1
            return item.icdaktif == 1
          }}
          customButton={item => {
            // Menampilkan button hanya untuk data yang icdaktifnya == 1
            if (item.icdaktif == 1) return
            return (
              <div
                onClick={() => {
                  this.aktifkan(item)
                }}
                className="flex flex-1 items-center justify-center rounded bg-green-500 ml-2 py-1"
              >
                <VmCheckIcon
                  color="white"
                  height={`${24}px`}
                  width={`${24}px`}
                />
                <p className="text-white">Aktifkan</p>
              </div>
            )
          }}
          onEditItemPress={item => {
            this.setState({ubahId: item.icdid, ubah: true})
          }}
          onDeleteItemPress={item => {
            this.prompt.show(
              {message: `Apakah anda yakin ingin menghapus ${item.icdnama}?`},
              () => {
                this.hapus(item)
              }
            )
          }}
          // Start List Props
          hasMoreItems={hasMoreItems}
          loadMore={() => {
            // console.log('Load More', this.state.fetchingMore, this.state.gettingInitialData)
            // Jika sedang load new data / get Initial data maka ndak perlu panggil fetchMore lagi (Antisipasi data ndobel)
            if (!this.state.fetchingMore && !this.state.gettingInitialData)
              this.fetchMore()
          }}
          // End List Props
          // Start Table Props

          // End Table Props
          onFabPress={() => {
            // console.log('Fab Press')
            // redirect('/prd-mas-biaya-pemeriksaan/tambah')
            this.setState({tambah: true})
          }}
          device={device}
          onSearchSubmit={value => {
            this.setState({cari: value}, this.getInitialData)
            // this.cari(value)
          }}
          /**
           * Parameter Untuk DataTable (Versi Desktop Web)
           */
          loadingFetchData={this.state.gettingInitialData}
          totalPage={Math.ceil(this.state.itemsCount / 10)}
          activePage={this.state.activePage}
          onActivePageChange={page => {
            console.log('page change gan ', page)
            this.setState({activePage: page}, this.getInitialData)
          }}
          onColumnFilterChange={data => {
            console.log('onColumnFilterChange ', data)

            let prevFilter = {
              icdkode: this.state.icdkode,
              icdnama: this.state.icdnama,
              icdket: this.state.icdket,
              icdaktif: this.state.icdaktif,
            }
            if (JSON.stringify(data) != JSON.stringify(prevFilter)) {
              // Search hanya jika value filter kolom ada yang berubah
              this.setState({...data, activePage: 1}, this.getInitialData)
            }
          }}
          onSorterValueChange={sortData => {
            this.setState(
              {sort: `${sortData.column} ${sortData.asc ? 'asc' : 'desc'}`},
              this.getInitialData
            )
            console.log('onSorterValueChange', sortData)
          }}
          totalCount={this.state.itemsCount}
          title="Data Diagnosa"
          onRefreshPress={() => {
            // Reset filter, sorting & paging
            this.setState(
              {
                // Filter untuk pencarian web
                sort: '',
                activePage: 1,
                icdnama: '',
                icdkode: '',
                icdket: '',
                icdaktif: 1,
              },
              this.getInitialData
            )
          }}
          columnFilterValue={{
            icdkode: '',
            icdnama: '',
            icdket: '',
            icdaktif: 1,
          }}
          sorterValue={{column: 'icdnama', asc: true}}
          statusColName="icdaktif"
          onActivate={item => this.aktifkan(item)}
        />
      </VmView>
    )
  }
}

function withParams(Component) {
  return props => {
    const [searchParams, setSearchParams] = useSearchParams()
    // console.log('withParams', searchParams)
    return <Component {...props} searchParams={searchParams} />
  }
}

export default withParams(PrdDiagnosa)
