import axios from 'axios'
import {API7, APIDOC} from 'config/apiurl'
import qs from 'qs'

const defError = (
  code = 'EDF',
  message = 'Gagal saat mengambil data, silakan coba kembali!',
  withCode = true
) => ({
  status: 0,
  message: `${withCode ? '[' + code + ']' : ''}${message}`,
})

export const fetchViewV2 = async args => {
  try {
    const {data} = await axios.post(
      API7 + '/prd-kunjungan/view-v2',
      qs.stringify(args)
    )

    if (data?.status == 1) return data
    return defError(
      !!data?.message ? '' : 'EDPC2',
      data?.message || 'Gagal saat mengambil data, silakan coba kembali!',
      !!data?.message ? false : true
    )
  } catch (e) {
    return defError('EDPC4', 'Gagal saat menyimpan data, silakan coba kembali!')
  }
}

export const qIndex = async args => {
  try {
    const {data} = await axios.post(
      APIDOC + '/prd-lap-kunjungan/index-web',
      qs.stringify(args)
    )

    if (data?.status === 1 || data?.status === 2) return data
    return defError('EPSS-CE')
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}

export const qIndexCount = async args => {
  try {
    const {data} = await axios.post(
      APIDOC + '/prd-lap-kunjungan/index-web-count',
      qs.stringify(args)
    )

    if (data?.status === 1 || data?.status === 2) return data
    return defError('EPSS-CE')
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}

export const mExport = async (url, args) => {
  try {
    const {data} = await axios.post(url, qs.stringify(args))
    if (data?.status === 1 || data?.status === 2) return data
    return defError('EPSS-CE')
  } catch (e) {
    return defError('EPSS-CE-2')
  }
}

export const configDetailItems = (args, id) => {
  return {
    defaultData: [],
    url: APIDOC + `/prd-lap-kunjungan/index-web-list-item?id=${id}`,
    method: 'POST',
    config: {
      data: qs.stringify(args),
    },
  }
}

export const checkCustomBpjs = async uid => {
  try {
    const res = await axios
      .post(
        `${APIDOC}/backend-data-registrasi/get-custom`,
        qs.stringify({
          uid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.data) {
          if (res.data['121']) {
            // Berarti punya custom
            return true
          } else {
            // Tidak punya custom
            return false
          }
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Cek Custom BPJS PCare')
        return false
      })
    return res
  } catch (e) {
    return defError('EDPC4', 'Gagal saat menyimpan data, silakan coba kembali!')
  }
}
