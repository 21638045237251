import {VmModal, VmView, VmText, VmIcons} from 'components'
import {
  createRef,
  forwardRef,
  Fragment,
  useImperativeHandle,
  useState,
  useMemo,
  useEffect,
} from 'react'
import SearchVisitorMobile from 'pages/prd-lap-kunjungan-pasien/Index'

const modelData = {
  srcvisit: {
    title: 'Data Kunjungan Pasien',
  },
}
var ModalPencarian = {
  propTypes: {},
}
ModalPencarian = forwardRef((props, ref) => {
  const [title, settitle] = useState('')
  const [type, settype] = useState('')
  const [data, setdata] = useState({})
  const modalPaymentRef = createRef()
  const open = (t = 'disc', data = {}) => {
    const {title, type} = {...modelData[t], type: t}
    settitle(title)
    settype(type)
    setdata(data)
    modalPaymentRef.current.open()
  }
  const close = (t = '', data = {}) => {
    settitle('')
    settype('')
    setdata({})
    modalPaymentRef.current.close()
  }

  useImperativeHandle(ref, () => ({
    modal: modalPaymentRef.current,
    open,
    close,
  }))

  useEffect(() => {
    // console.log('useEffect ModalPencarian',{dctid:props.dctid})
    return () => {}
  }, [props.dctid])

  var Body = useMemo(() => {
    if (type === 'srcvisit') {
      return (
        <Fragment>
          <SearchVisitorMobile
            key={`${type}-${props.dms}`}
            controllers={props.controllers}
            pathname={props.pathname}
            navigate={props.navigate}
            queryParams={props.params}
            onCardItemClick={props.onItemModalSearchClick}
            dctid={props.dctid}
            classNameANCHORHeader="sticky pt-3"
            classNameANCHORContent="!pt-0"
            useWindowInfiniteScroll={false}
            footerAttr={{enable: false}}
          />
          <VmView className='h-[50vh]'></VmView>
        </Fragment>
      )
    }
    return <></>
  }, [props, data, type])
  var Foot = () => {
    return <></>
  }
  return (
    <VmModal
      ref={modalPaymentRef}
      classNameContent="ModalSearch"
      classNameBody="FormSearch"
    >
      <VmView className="EheadSearch">
        <VmView className="EtitleSearch">
          <VmText>{title}</VmText>
        </VmView>
        <VmView onClick={close} className="EbtncloseSearch cursor-pointer">
          <VmIcons
            size={16}
            name="VmXMarkIcon"
            color="var(--color-red0-payment)"
          />
        </VmView>
      </VmView>
      <VmView className="EbodySearch overflow-auto">{Body}</VmView>

      <VmView className="EfootSearch">
        <Foot />
      </VmView>
    </VmModal>
  )
})

export default ModalPencarian
