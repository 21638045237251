import {VmText, VmView} from 'components'
import HStack from 'components/HStack'
import vmedisicon from 'assets/vmedisicon.png'
import {ProgressFullScreen} from 'molecules'
import {fetchCetak} from './api'
import trans_bg from 'assets/trans_bg.png'

import moment from 'moment'
import React, {useState} from 'react'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'

export default function Cetak() {
  const params = useParams()
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState({
    detail: {
      head: {
        pjid: '',
      },
      child: [],
    },
  })

  useEffect(() => {
    const init = async () => {
      setloading(true)
      const resCetak = await fetchCetak(params.uid, params.id)
      setdata(resCetak)
      setloading(false)
    }
    init()
  }, [])

  useEffect(() => {
    if (data?.detail?.head?.pjid && !loading && !data?.e) window.print()
  }, [data?.detail?.head?.pjid])

  if (loading) return <ProgressFullScreen visible={loading} />

  if (data?.e)
    return (
      <VmView className="fixed h-full inset-0 text-center flex items-center">
        <VmText>Terjadi kesalahan saat cetak, silakan coba kembali</VmText>
      </VmView>
    )

  return (
    <VmView className="m-5">
      <VmView className="flex justify-center">
        <img src={vmedisicon} alt="vmedis-icon" className="w-24" />
      </VmView>
      <p className="text-xs text-center font-bold mb-8">Resep Digital</p>

      <p className="text-sxs font-bold">No. Resep</p>
      <HStack justify="between">
        <p className="text-sxs text-center">
          {data?.detail?.head.pjnofaktur || '-'}
        </p>
        <p className="text-sxs text-center">
          {data?.detail?.head?.pjtanggal
            ? moment(data.detail.head.pjtanggal).format('DD MMM YYYY')
            : '-'}
        </p>
      </HStack>

      <VmView className="border-y border-black py-4 my-4 grid grid-cols-2 items-start">
        <VmView>
          <table className="border-none">
            <tbody>
              <tr className="align-top">
                <td>
                  <p className="text-sxs font-bold mr-2">Dokter</p>
                </td>
                <td>
                  <p className="text-sxs">
                    {data?.identitas?.nama_lengkap || '-'}
                  </p>
                </td>
              </tr>
              <tr className="align-top">
                <td>
                  <p className="text-sxs font-bold mr-2">SIP</p>
                </td>
                <td>
                  <p className="text-sxs ">
                    {data?.identitas?.user_nosip || '-'}
                  </p>
                </td>
              </tr>
              <tr className="align-top">
                <td>
                  <p className="text-sxs font-bold mr-2">Spesialis</p>
                </td>
                <td>
                  <p className="text-sxs ">
                    {data?.identitas?.ket_suspend || '-'}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </VmView>

        <VmView>
          <table className="border-none">
            <tbody>
              <tr className="align-top">
                <td>
                  <p className="text-sxs font-bold mr-2">Pasien</p>
                </td>
                <td>
                  <p className="text-sxs">
                    {data?.detail?.head?.pasnama || '-'}
                  </p>
                </td>
              </tr>
              <tr className="align-top">
                <td>
                  <p className="text-sxs font-bold mr-2">Jenis Kelamin</p>
                </td>
                <td>
                  <p className="text-sxs ">
                    {data?.detail?.head?.pasjk || '-'}
                  </p>
                </td>
              </tr>
              <tr className="align-top">
                <td>
                  <p className="text-sxs font-bold mr-2">Usia</p>
                </td>
                <td>
                  <p className="text-sxs ">
                    {data.detail.head.pastgllahir
                      ? moment().diff(data.detail.head.pastgllahir, 'years') +
                        ' Tahun'
                      : '-'}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </VmView>
      </VmView>

      <HStack className={'mb-4'}>
        <p className="flex-[0.4] text-sxs font-bold">Obat</p>
        <p className="flex-[0.4] text-sxs font-bold mx-2">Aturan Pakai</p>
        <p className="flex-[0.2] text-sxs font-bold">Jumlah</p>
      </HStack>

      {data.detail?.child.length > 0 &&
        data.detail?.child.map((item, i) => (
          <HStack align="start" className={'mb-4'} key={i}>
            <p className="flex-[0.4] text-sxs font-bold">
              {item.pjdnama ? `R/ ${item.pjdnama}` : '-'}
            </p>
            <p className="flex-[0.4] text-sxs mx-2">{item.pjdcatatan || '-'}</p>
            <p className="flex-[0.2] text-sxs ">{`${item.pjdjumlah} ${item.pjdsatuan}`}</p>
          </HStack>
        ))}

      <VmView className="relative flex justify-end mt-4">
        {(() => {
          const ttd = data?.stg?.stgttddokter != 0
          const stempel = data?.stg?.stgstempeldokter != 0
          if (ttd && stempel) {
            return (
              <>
                <img
                  alt="ttd-dokter"
                  className="absolute right-0 bottom-0 w-40 z-10 object-cover"
                  src={data?.stg?.user_ttd || trans_bg}
                />
                <img
                  alt="stempel-dokter"
                  className="right-0 top-0 w-40 opacity-25 object-cover"
                  src={data?.stg?.user_stempel || trans_bg}
                />
              </>
            )
          } else if (ttd) {
            return (
              <img
                alt="ttd-dokter"
                className="right-0 top-0 w-40 object-cover"
                src={data?.stg?.user_ttd || trans_bg}
              />
            )
          } else if (stempel) {
            return (
              <img
                alt="stempel-dokter"
                className="right-0 top-0 w-40 opacity-25 object-cover"
                src={data?.stg?.user_stempel || trans_bg}
              />
            )
          }
        })()}
      </VmView>
      <p className="text-right text-xs">{data?.identitas?.nama_lengkap}</p>
    </VmView>
  )
}
