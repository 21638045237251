import {useNavigate, useParams} from 'react-router-dom'
import {VmButton, VmView} from 'components'
import {useEffect, useState, Fragment} from 'react'
import CashierController from 'controllers/Cashier'
import {FullErrorView, ProgressFullScreen} from 'molecules'

export default function CetakPembayaran() {
  const params = useParams()
  const navigate = useNavigate()
  const [dctid, id] = params.id.split('-')
  const [data, setdata] = useState(null)
  const [loading, setloading] = useState(true)
  const [error, seterror] = useState(false)
  useEffect(() => {
    var error = false,
      errorMsg = ''
    CashierController(
      {
        key: 'cetak',
        _ldata: 'mCetakPayment',
        dispatch: () => {},
        id,
        dctid,
      },
      navigate
    )
      .then(res => {
        // console.log('res', res)
        if (res?.message === 'success') {
          setdata(res.data)
        } else {
          error = true
          errorMsg = res?.message || '[CPE0] Gagal mengambil data!'
        }
      })
      .catch(err => {
        // console.log('err', err)
        error = true
        errorMsg = '[CPE1] Gagal mengambil data!'
      })
      .finally(() => {
        // console.log('Finally')
        if (error) {
          seterror(errorMsg)
        }
        setloading(false)
      })

    return () => {}
  }, [])
  const onPrint = () => {
    window.print()
    var col3 = [13, 2, 17],
      col2 = [21, 10],
      col1 = [31],
      doubleline = '=============================',
      singleline = '-----------------------------',
      blankrow1 = ' \n ',
      blankrow2 = ' \r\n '

    const cart = data.cart.map(im => {
      var field1 = `${im?.name || '-'}`.trim(),
        field2 = `${im?.qty || '0'} x ${im?.price || '0'}`,
        field3 = `Diskon ${im?.nominaldisc || '0'}`,
        rescart = []
      rescart = [
        ['printColumn', col2, [0, 2], [field1, im?.total || '0'], {}],
        ['printColumn', col2, [0, 2], [field2, ' '], {}],
      ]
      if (im?.nominaldisc > 0) {
        rescart = [...rescart, ['printColumn', col2, [0, 2], [field3, ' '], {}]]
      }
      return rescart
    })
    const newdataprintmob = [
      /**
       * ===========================================
       * = [Type, [Width],[Align],[Text],{option}] =
       * = Type @string                            =
       * = Width @integer                          =
       * = Align LEFT:0 CENTER:1 RIGHT:2           =
       * = Text @string                            =
       * ===========================================
       */
      // Header
      ['printColumn', col1, [1], [data?.name || '-'], {}],
      ['printColumn', col1, [1], [data?.address || '-'], {}],
      ['printColumn', col1, [1], [data?.phone || '-'], {}],
      ['printColumn', col1, [1], [' '], {}],
      // Struk Info
      ...data.fieldsInfo.map(im => [
        'printColumn',
        col3,
        [0, 1, 0],
        [im.label, ':', data[im.fields] || '-'],
        {},
      ]),
      ['printColumn', col1, [1], [singleline], {}],
      // Item Biaya
      ['printColumn', col3, [0, 1, 0], ['Detail Biaya ', ':', ' '], {}],
      ...cart.reduce((a, b) => a.concat(b), []),
      ['printColumn', col1, [1], [' '], {}],
      // Total Payment
      ...data.fieldsTotal.map(im => [
        'printColumn',
        col3,
        [0, 1, 2],
        [im.label, ':', data[im.fields] || '-'],
        {},
      ]),
      // Footer
      ['printColumn', col1, [1], [' '], {}],
      ['printerAlign', 1],
      ['printText', `${data?.foottext || doubleline}\r\n`, {}],
      ['printColumn', col1, [1], [blankrow2], {}],
    ]
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(
        'screenCetak:' + JSON.stringify(newdataprintmob)
      )
    }
  }

  const onBack = () => {
    navigate(-1)
  }

  if (error || !dctid || !id) {
    return (
      <FullErrorView message={!dctid || !id ? '[0] Url tidak valid!' : error} />
    )
  }
  if (loading) {
    return <ProgressFullScreen visible={true} />
  }

  return (
    <VmView className="RootBodyPrint">
      <VmView className="CetakPembayaran">
        {/* Header */}
        <table
          width="100%"
          align="center"
          style={{textAlign: 'center', fontSize: '12px', marginBottom: '10px'}}
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            <tr>
              <td style={{fontWeight: '700'}}>{data?.name || ''}</td>
            </tr>
            <tr>
              <td>{data?.address || ''}</td>
            </tr>
            <tr>
              <td>{data?.phone || ''}</td>
            </tr>
          </tbody>
        </table>
        {/* Struk Info */}
        <table
          width="100%"
          style={{fontSize: '10px'}}
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            {data?.fieldsInfo ? (
              data.fieldsInfo.map(im => {
                return (
                  <tr key={JSON.stringify(im)}>
                    <td
                      style={{fontWeight: '700'}}
                      width="40%"
                      align="left"
                      valign="top"
                    >
                      {im.label}
                    </td>
                    <td width="2%" align="left" valign="top">
                      :
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        paddingLeft: '5px',
                      }}
                    >
                      {data[im.fields] || '-'}
                    </td>
                  </tr>
                )
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
        <hr
          style={{
            marginBlock: 7,
            marginInline: 5,
            color: '#000000',
            borderTop: 'dashed 0.5px',
          }}
        />
        {/* Item Biaya */}
        <table
          width="100%"
          style={{fontSize: '10px', marginBottom: 7}}
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            <tr>
              <td
                style={{fontWeight: '700'}}
                width="40%"
                align="left"
                valign="top"
              >
                Detail Biaya
              </td>
              <td width="2%" align="left" valign="top">
                :
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        <table
          width="100%"
          style={{fontSize: '10px', marginBottom: '10px'}}
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            {data?.cart?.length > 0 ? (
              data.cart.map(im => {
                return (
                  <Fragment key={JSON.stringify(im)}>
                    <tr style={{fontWeight: 400}}>
                      <td colSpan={3}>{im?.name || '-'} </td>
                    </tr>
                    <tr style={{fontWeight: 400}}>
                      <td align="left" width="60%">
                        {im?.qty || 0}
                        {' x '}
                        {im?.price || 0}{' '}
                      </td>
                      <td width="5%" />
                      <td align="right" width="25%">
                        {im?.total || 0}{' '}
                      </td>
                    </tr>
                    {im?.nominaldisc > 0 && (
                      <tr style={{fontWeight: 400}}>
                        <td colSpan={3}>Diskon {im?.nominaldisc} </td>
                      </tr>
                    )}
                  </Fragment>
                )
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
        {/* Total Payment */}
        <table
          width="100%"
          style={{fontSize: '10px', marginBottom: '10px'}}
          border={0}
          cellPadding={0}
          cellSpacing={0}
        >
          <tbody>
            {data?.fieldsTotal ? (
              data.fieldsTotal.map(im => {
                return (
                  <tr key={JSON.stringify(im)}>
                    <td
                      style={{fontWeight: '700'}}
                      width="40%"
                      align="left"
                      valign="top"
                    >
                      {im.label}
                    </td>
                    <td width="2%" align="left" valign="top">
                      :
                    </td>
                    <td
                      style={{
                        fontWeight: 400,
                        paddingLeft: '5px',
                        textAlign: 'end',
                      }}
                    >
                      {data[im.fields] || '-'}
                    </td>
                  </tr>
                )
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
        {/* Footer */}
        {data?.showfoottext == 1 && (
          <table
            width="100%"
            align="center"
            style={{
              textAlign: 'center',
              fontSize: '12px',
              marginBottom: '10px',
            }}
            border={0}
            cellPadding={0}
            cellSpacing={0}
          >
            <tbody>
              <tr>
                <td>{data?.foottext || ''}</td>
              </tr>
            </tbody>
          </table>
        )}
      </VmView>
      <VmView className="notprint fixed text-right bottom-5 left-0 right-5 gap-2 flex justify-end">
        <VmButton onClick={onPrint}>Cetak</VmButton>
        <VmButton color="danger" onClick={onBack}>
          Kembali
        </VmButton>
      </VmView>
    </VmView>
  )
}
