import classNames from 'classnames'
import {VmText, VmView} from 'components'
import {VmInputHandler} from 'molecules'
import {useRef} from 'react'
import {textCase} from './HandlerForm'

export default function InputCustom({
  data,
  classItem,
  label,
  title,
  childLength = 0,
  propsInput = {},
  usetitle = true,
  prefix = null,
  suffix = null,
  outerprefix = null,
  outersuffix = null,
}) {
  const handlerRef = useRef()
  const {placeholder, required, className, typeInput, style} = {
    placeholder: ' ',
    required: false,
    className: '',
    typeInput: 'text',
    style: {width: '-webkit-fill-available'},
    ...data,
  }
  const classInput = classNames(`flex mt-1 `, className)
  const listCNs = ['', '', 'md:px-[10px]', 'md:px-[20px]']

  return (
    <VmView className={classNames(classItem, listCNs[childLength] || '')}>
      {usetitle && (
        <VmText className={classNames('')}>
          {textCase({Pascal: '-'}, title)}
        </VmText>
      )}
      <VmView className={classInput}>
        <VmInputHandler
          ref={handlerRef}
          placeholder={placeholder}
          typeInput={typeInput}
          required={required}
          style={style}
          defaultValue={data?.value || ''}
          label={label}
          suffix={suffix}
          prefix={prefix}
          outerprefix={outerprefix}
          outersuffix={outersuffix}
          usesuffix={false}
          {...{
            ...propsInput,
            style: {
              background:
                propsInput?.disabled || propsInput?.readOnly
                  ? '#efefef4d'
                  : null,
            },
          }}
        />
      </VmView>
    </VmView>
  )
}
