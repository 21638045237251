import {VmButton, VmIcons, VmSpinner, VmText, VmView} from 'components'
import {ProgressFullScreen, VmHeader, VmPulltoRefresh} from 'molecules'
import React, {useEffect, useRef, useState} from 'react'
import Searchbar from './Searchbar'
import Tabs from './Tabs'
import CardItem from './CardItem'
import Footer from './Footer'
import {BottomSheet} from 'react-spring-bottom-sheet'
import useAddEventListener from 'utils/useAddEventListener'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {defaultpropsbottomsheet} from 'entries/bottomsheet'
import classNames from 'classnames'
import {
  mCheckTransaksi,
  mDeletePengajuan,
  mSearchMitra,
  mSetStatusMitra,
} from './api'
import {getElement} from 'utils/get-element'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import {eventTrigerInput, isEmpty} from 'utils'
import {getValue} from 'utils/datausr'
import SimpleToast from 'components/SimpleToast'
import {defaultpropssimpletoast} from 'entries/simpletoast'
import usePullToRefresh from 'utils/usePullToRefresh'
import {UserController} from 'controllers'

export default function MitraApotek() {
  const showlog = false,
    limit = 10,
    navigate = useNavigate(),
    location = useLocation(),
    bottomSheetRef = useRef()
  // State
  const dctid = parseInt(useParams().id)
  const [propssimpletoast, setpropssimpletoast] = useState(
    defaultpropssimpletoast
  )
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [offset, setoffset] = useState(0)
  const [alasan, setalasan] = useState('')
  const [keyword, setkeyword] = useState('')
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  const [loadingdoc, setloadingdoc] = useState(true)
  const [loadmore, setloadmore] = useState(true)
  const [onloadmore, setonloadmore] = useState(true)
  const [data, setdata] = useState([])
  const [datadoc, setdatadoc] = useState(getValue() || {})
  const [datacount, setdatacount] = useState(0)
  const [params, setparams] = useState({
    log: showlog,
    input: {
      // a: '',
      id: dctid,
      limit,
      offset,
      spdstatus: 0,
      carimobile: keyword,
      'show-error-log': `${showlog}`.toUpperCase(),
    },
  })
  const onClickSearchBtn = () => {
    var newtimeout = null
    newtimeout = setTimeout(() => {
      const finsInputEl = getElement({
        tag: 'button',
        by: 'ariaLabel',
        equal: 'btn-search-mitra',
      })
      finsInputEl?.click()
      clearTimeout(newtimeout)
    }, 100)
  }
  const onShowToast = ({message = '-', type = ''}) => {
    var newtimeout1 = null
    newtimeout1 = setTimeout(() => {
      setpropssimpletoast(defaultpropssimpletoast)
      clearTimeout(newtimeout1)
    }, 3000)

    return setpropssimpletoast({
      toasterbg: type === 'Fail' ? 'bg-red5-payment' : 'bg-blue1-payment',
      toastericon: type === 'Fail' ? 'VmXCircleIcon' : 'VmCheckCircleIcon',
      toastertitle: message,
      toastervisible: true,
    })
  }
  const onThenDeletePengajuan = ({data}) => {
    if (data?.status === 1) {
      onClickSearchBtn()
      onShowToast({
        message: <>{data?.message || 'Pengajuan berhasil dibatalkan.'}</>,
      })
    } else {
      onShowToast({
        type: 'Fail',
        message: <>{data?.message || 'Pengajuan gagal dibatalkan.'}</>,
      })
    }
  }
  const onThenSetStatus = ({data}) => {
    if (data?.status === 1) {
      onClickSearchBtn()
      onShowToast({
        message: <>{data?.message || 'Pengajuan berhasil dibatalkan.'}</>,
      })
    } else {
      onShowToast({
        type: 'Fail',
        message: <>{data?.message || 'Pengajuan gagal dibatalkan.'}</>,
      })
    }
  }
  const onTolak = (data, eventBtn) => {
    var newtimeout1 = null
    const datamitra = data?.defaultdata
    const flagjenis = `${datamitra?.spdjenis}${datamitra?.spdstatus}`
    const defaulttolakform = {
      title: 'Peringatan',
      yesText: 'Ya',
      noText: 'Tidak',
      onClose: () => {
        setpropsalertform(defaultpropsalertform)
      },
      onCancel: () => {
        eventBtn.target.click()
        setpropsalertform(defaultpropsalertform)
      },
    }

    setpropsbottomsheet(defaultpropsbottomsheet)
    if (showlog) {
      console.log('flagjenis', flagjenis)
    }
    if (['12', '22'].includes(flagjenis)) {
      const onConfirm = () => {
        const finsInputEl = getElement({
          tag: 'textarea',
          by: 'ariaLabel',
          equal: 'alasan',
        })
        const alasan = finsInputEl?.value || ''
        if (isEmpty(alasan)) {
          return Promise.all([
            setpropsalertform(defaultpropsalertform),
          ]).finally(() => {
            return setpropsalertform({
              ...propsalertform,
              ...defaulttolakform,
              type: 2,
              visible: true,
              closeable: false,
              title: 'Peringatan',
              yesText: 'Ok',
              noText: false,
              children: <>{'Alasan tidak boleh kosong'}</>,
              onConfirm: () => setpropsalertform(defaultpropsalertform),
            })
          })
        }
        setpropsalertform(defaultpropsalertform)
        if (flagjenis === '12') {
          setloading(true)
          return mCheckTransaksi({
            input: {
              uid: dctid,
              appid_apotek: datamitra?.appid_apotek,
              regid_apotek: datamitra?.regid_apotek,
            },
          })
            .then(res => {
              var pesanlog =
                'Melakukan Pengajuan Pemutusan Kerjasama Resep Dokter Online ke ' +
                datamitra?.kl_nama +
                ', dengan Alasan ' +
                alasan
              const onConfirmPutus = () => {
                setloading(true)
                mSetStatusMitra({
                  input: Object.assign(
                    {
                      newstatus: 3,
                      pesan: pesanlog,
                      id: datamitra?.spdid,
                      appid_apotek: datamitra?.appid_apotek,
                      regid_apotek: datamitra?.regid_apotek,
                      spdstatus: datamitra?.spdstatus,
                      spdjenis: datamitra?.spdjenis,
                      nama_apotek: datamitra?.kl_nama,
                      doknama: datadoc?.nama_lengkap,
                      dokter: datadoc?.nama_lengkap,
                      status: datamitra?.spdstatus,
                      email: datadoc?.email || '',
                      nosip: datadoc?.nosip || '',
                      nostr: datadoc?.keterangan || '',
                      uid: dctid,
                      alasan,
                    },
                    flagjenis === '22'
                      ? {
                          spdjenis: 1,
                        }
                      : {}
                  ),
                })
                  .then(onThenSetStatus)
                  .finally(() => {
                    setloading(!true)
                  })
              }
              if (res.status == 2) {
                setpropsalertform({
                  ...propsalertform,
                  ...defaulttolakform,
                  type: 2,
                  visible: true,
                  closeable: false,
                  yesText: 'Ok',
                  noText: false,
                  children: res.message,
                  onConfirm: () => {
                    setpropsalertform(defaultpropsalertform)
                  },
                })
              } else if (res.status == 3) {
                return Promise.all([
                  setpropsalertform(defaultpropsalertform),
                ]).finally(() => {
                  return setpropsalertform({
                    ...propsalertform,
                    ...defaulttolakform,
                    type: 3,
                    visible: true,
                    closeable: false,
                    title: 'Peringatan',
                    yesText: 'Proses',
                    noText: 'Batal',
                    children: <>{res?.message || 'Apakah anda yakin?'}</>,
                    onConfirm: onConfirmPutus,
                  })
                })
              } else {
                onConfirmPutus()
              }
            })
            .finally(() => {
              setloading(!true)
            })
        }
        var pesanlog =
          'Membatalkan Pengajuan Pemutusan Kerjasama Resep Dokter Online ke ' +
          datamitra?.kl_nama +
          '.'

        setloading(true)
        mSetStatusMitra({
          input: Object.assign(
            {
              newstatus: 3,
              pesan: pesanlog,
              id: datamitra?.spdid,
              appid_apotek: datamitra?.appid_apotek,
              regid_apotek: datamitra?.regid_apotek,
              spdstatus: datamitra?.spdstatus,
              spdjenis: datamitra?.spdjenis,
              nama_apotek: datamitra?.kl_nama,
              doknama: datadoc?.nama_lengkap,
              dokter: datadoc?.nama_lengkap,
              status: datamitra?.spdstatus,
              email: datadoc?.email || '',
              nosip: datadoc?.nosip || '',
              nostr: datadoc?.keterangan || '',
              uid: dctid,
              alasan,
            },
            flagjenis === '22'
              ? {
                  spdjenis: 1,
                }
              : {}
          ),
        })
          .then(onThenSetStatus)
          .finally(() => {
            setloading(!true)
          })
      }
      const popupinputalasan = {
        ...propsalertform,
        ...defaulttolakform,
        type: 3,
        visible: true,
        closeable: false,
        title: 'Alasan Pemutusan',
        yesText: 'Proses',
        noText: 'Batal',
        children: (
          <>
            <br />
            <textarea
              aria-label="alasan"
              placeholder="Alasan anda"
              style={{width: '100%', height: '100px'}}
            />
          </>
        ),
        onConfirm,
      }
      return setpropsalertform(popupinputalasan)
    }
    const msg = ['21', '23'].includes(flagjenis)
      ? // `Apakah anda yakin akan menolaknya?`
        parseInt(datamitra?.spdstatus) === 1
        ? 'Apakah anda yakin untuk menolak pengajuan kerjasama dengan apotek ' +
          datamitra?.kl_nama +
          '?'
        : 'Apakah anda yakin untuk menolak pengajuan pemutusan kerjasama dengan apotek ' +
          datamitra?.kl_nama +
          '?'
      : ['13'].includes(flagjenis)
      ? // 'Apakah anda yakin akan membatalkannya?'
        'Apakah anda yakin untuk membatalkan pengajuan pemutusan kerjasama dengan apotek ' +
        datamitra?.kl_nama +
        '?'
      : // 'Apakah anda yakin akan memutuskannya?'
        'Apakah anda yakin untuk membatalkan pengajuan kerjasama dengan apotek ' +
        datamitra?.kl_nama +
        '?'
    const onConfirm = () => {
      // akan menolaknya
      if (['21', '23'].includes(flagjenis)) {
        if (flagjenis === '21') {
          return Promise.all([
            setpropsalertform({...defaultpropsalertform}),
          ]).finally(() => {
            setpropsalertform({
              ...propsalertform,
              ...defaulttolakform,
              type: 3,
              visible: true,
              closeable: false,
              title: 'Alasan Penolakan',
              yesText: 'Proses',
              noText: 'Batal',
              children: (
                <>
                  <br />
                  <textarea
                    aria-label="alasan"
                    placeholder="Alasan anda"
                    style={{width: '100%', height: '100px'}}
                  />
                </>
              ),
              onConfirm: () => {
                const finsInputEl = getElement({
                  tag: 'textarea',
                  by: 'ariaLabel',
                  equal: 'alasan',
                })
                const alasan = finsInputEl?.value || ''
                if (isEmpty(alasan)) {
                  return Promise.all([
                    setpropsalertform(defaultpropsalertform),
                  ]).finally(() => {
                    return setpropsalertform({
                      ...propsalertform,
                      ...defaulttolakform,
                      type: 2,
                      visible: true,
                      closeable: false,
                      title: 'Peringatan',
                      yesText: 'Ok',
                      noText: false,
                      children: <>{'Alasan tidak boleh kosong'}</>,
                      onConfirm: () => setpropsalertform(defaultpropsalertform),
                    })
                  })
                }
                var pesan1 =
                  'Menolak Pengajuan Kerjasama Resep Dokter Online dari ' +
                  datamitra?.kl_nama +
                  ', dengan Fee ' +
                  datamitra?.spdfeedokter +
                  '%, dengan alasan ' +
                  alasan
                var pesan2 =
                  datadoc?.nama_lengkap +
                  ' Menolak Pengajuan Kerjasama Resep Dokter Online, dengan Fee ' +
                  datamitra?.spdfeedokter +
                  '%, dengan alasan ' +
                  alasan
                setloading(true)
                mDeletePengajuan({
                  ...params,
                  input: {
                    id: datamitra?.spdid,
                    pesan1,
                    pesan2,
                    doknama: datadoc?.nama_lengkap,
                    appid_apotek: datamitra?.appid_apotek,
                    regid_apotek: datamitra?.regid_apotek,
                    status: datamitra?.spdstatus,
                    spdstatus: datamitra?.spdstatus,
                    spdjenis: datamitra?.spdjenis,
                    nama_apotek: datamitra?.kl_nama,
                    uid: dctid,
                  },
                })
                  .then(onThenDeletePengajuan)
                  .catch(ch => {
                    if (showlog) {
                      console.log('ch', ch)
                    }
                    onShowToast({
                      type: 'Fail',
                      message: <>{'[C0] Pengajuan gagal dibatalkan.'}</>,
                    })
                  })
                  .finally(() => {
                    setloading(!true)
                  })
              },
            })
          })
        } else {
          var pesanlog =
            'Menolak Pengajuan Pemutusan Kerjasama Resep Dokter Online dari ' +
            datamitra?.kl_nama
          setloading(true)
          mSetStatusMitra({
            input: {
              newstatus: 2,
              pesan: pesanlog,
              id: datamitra?.spdid || '',
              appid_apotek: datamitra?.appid_apotek || '',
              regid_apotek: datamitra?.regid_apotek,
              spdstatus: datamitra?.spdstatus || '',
              spdjenis: datamitra?.spdjenis || '',
              nama_apotek: datamitra?.kl_nama || '',
              doknama: datadoc?.nama_lengkap,
              dokter: datadoc?.nama_lengkap,
              status: datamitra?.spdstatus,
              email: datadoc?.email || '',
              nosip: datadoc?.nosip || '',
              nostr: datadoc?.keterangan || '',
              uid: dctid,
            },
          })
            .then(onThenSetStatus)
            .catch(ch => {
              if (showlog) {
                console.log('ch', ch)
              }
              onShowToast({
                type: 'Fail',
                message: <>{'[C0] Proses gagal.'}</>,
              })
            })
            .finally(() => {
              setloading(!true)
            })
        }
        return
      } else if (['11', '13'].includes(flagjenis)) {
        // akan membatalkannya
        if (flagjenis === '11') {
          setloading(true)
          var pesan1 =
            'Membatalkan Pengajuan Kerjasama Resep Dokter Online ke ' +
            datamitra?.kl_nama
          var pesan2 =
            datadoc?.nama_lengkap +
            ' Membatalkan Pengajuan Kerjasama Resep Dokter Online'
          setpropsalertform(defaultpropsalertform)
          return mDeletePengajuan({
            input: {
              id: datamitra?.spdid,
              pesan1: pesan1,
              pesan2: pesan2,
              appid_apotek: datamitra?.appid_apotek,
              regid_apotek: datamitra?.regid_apotek,
              spdstatus: datamitra?.spdstatus,
              spdjenis: datamitra?.spdjenis,
              nama_apotek: datamitra?.kl_nama,
              doknama: datadoc?.nama_lengkap,
              status: datamitra?.spdstatus,
              uid: dctid,
            },
          })
            .then(onThenDeletePengajuan)
            .catch(ch => {
              if (showlog) {
                console.log('ch', ch)
              }
              onShowToast({
                type: 'Fail',
                message: <>{'[C0] Proses gagal.'}</>,
              })
            })
            .finally(() => {
              setloading(!true)
            })
        } else {
          var pesanlog =
            'Membatalkan Pengajuan Pemutusan Kerjasama Resep Dokter Online ke ' +
            datamitra?.kl_nama +
            '.'
          setloading(true)
          mSetStatusMitra({
            input: {
              newstatus: 2,
              pesan: pesanlog,
              id: datamitra?.spdid,
              appid_apotek: datamitra?.appid_apotek,
              regid_apotek: datamitra?.regid_apotek,
              spdstatus: datamitra?.spdstatus,
              spdjenis: datamitra?.spdjenis,
              nama_apotek: datamitra?.kl_nama,
              doknama: datadoc?.nama_lengkap,
              dokter: datadoc?.nama_lengkap,
              status: datamitra?.spdstatus,
              email: datadoc?.email || '',
              nosip: datadoc?.nosip || '',
              nostr: datadoc?.keterangan || '',
              alasan,
              uid: dctid,
            },
          })
            .then(onThenSetStatus)
            .finally(() => {
              setloading(!true)
            })
        }
        return
      } else {
        // akan memutuskannya
      }
    }
    setpropsalertform({
      ...propsalertform,
      ...defaulttolakform,
      type: 3,
      visible: true,
      closeable: false,
      children: msg,
      onConfirm,
    })
  }
  const onTerima = (data, eventBtn) => {
    setpropsbottomsheet(defaultpropsbottomsheet)
    const datamitra = data?.defaultdata
    const flagjenis = `${datamitra?.spdjenis}${datamitra?.spdstatus}`
    var pesan =
      datamitra?.spdstatus == 1
        ? 'Apakah anda yakin untuk menerima pengajuan kerjasama dengan apotek ' +
          datamitra?.kl_nama +
          '?'
        : 'Apakah anda yakin untuk menerima pengajuan pemutusan kerjasama dengan apotek ' +
          datamitra?.kl_nama +
          '?'
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      closeable: false,
      children: pesan,
      onConfirm: () => {
        setpropsalertform(defaultpropsalertform)
        if (flagjenis === '21') {
          var pesanlog =
            'Menerima Pengajuan Kerjasama Resep Dokter Online dari ' +
            datamitra?.kl_nama +
            ', dengan Insentif ' +
            datamitra?.spdfeedokter +
            '%'
          setloading(true)
          mSetStatusMitra({
            input: {
              newstatus: 2,
              pesan: pesanlog,
              id: datamitra?.spdid,
              appid_apotek: datamitra?.appid_apotek,
              regid_apotek: datamitra?.regid_apotek,
              spdstatus: datamitra?.spdstatus,
              spdjenis: datamitra?.spdjenis,
              nama_apotek: datamitra?.kl_nama,
              doknama: datadoc?.nama_lengkap,
              dokter: datadoc?.nama_lengkap,
              status: datamitra?.spdstatus,
              email: datadoc?.email || '',
              nosip: datadoc?.nosip || '',
              nostr: datadoc?.keterangan || '',
              uid: dctid,
              user_gideon: 1,
            },
          })
            .then(onThenSetStatus)
            .finally(() => {
              setloading(!true)
            })
        } else {
          setloading(true)
          mCheckTransaksi({
            input: {
              uid: dctid,
              appid_apotek: datamitra?.appid_apotek,
              regid_apotek: datamitra?.regid_apotek,
            },
          })
            .then(res => {
              if (res.status == 2) {
                setpropsalertform({
                  ...propsalertform,
                  type: 2,
                  visible: true,
                  closeable: false,
                  children: res.message,
                  onConfirm: () => {
                    setpropsalertform(defaultpropsalertform)
                  },
                  onClose: () => {
                    setpropsalertform(defaultpropsalertform)
                  },
                  onCancel: () => {
                    setpropsalertform(defaultpropsalertform)
                  },
                })
              } else if (res.status == 3) {
                setpropsalertform({
                  ...propsalertform,
                  type: 3,
                  visible: true,
                  closeable: false,
                  children: res.message,
                  onConfirm: () => {
                    var pesan1 =
                      'Menerima Pengajuan Pemutusan Kerjasama Resep Dokter Online dari ' +
                      datamitra?.kl_nama
                    var pesan2 =
                      datamitra?.kl_nama +
                      ' Menerima Pengajuan Pemutusan Kerjasama Resep Dokter Online'
                    setloading(true)
                    setpropsalertform(defaultpropsalertform)
                    mDeletePengajuan({
                      input: {
                        id: datamitra?.spdid,
                        pesan1: pesan1,
                        pesan2: pesan2,
                        appid_apotek: datamitra?.appid_apotek,
                        regid_apotek: datamitra?.regid_apotek,
                        spdstatus: datamitra?.spdstatus,
                        spdjenis: datamitra?.spdjenis,
                        doknama: datadoc?.nama_lengkap,
                        status: datamitra?.spdstatus,
                        nama_apotek: datamitra?.kl_nama,
                        uid: dctid,
                        user_gideon: 2,
                      },
                    })
                      .then(onThenDeletePengajuan)
                      .catch(ch => {
                        if (showlog) {
                          console.log('ch', ch)
                        }
                        onShowToast({
                          type: 'Fail',
                          message: <>{'[C0] Proses gagal.'}</>,
                        })
                      })
                      .finally(() => {
                        setloading(!true)
                      })
                  },
                  onClose: () => {
                    setpropsalertform(defaultpropsalertform)
                  },
                  onCancel: () => {
                    setpropsalertform(defaultpropsalertform)
                  },
                })
              } else {
                var pesan1 =
                  'Menerima Pengajuan Pemutusan Kerjasama Resep Dokter Online dari ' +
                  datamitra?.kl_nama
                var pesan2 =
                  datadoc?.nama_lengkap +
                  ' Menerima Pengajuan Pemutusan Kerjasama Resep Dokter Online'
                mDeletePengajuan({
                  input: {
                    id: datamitra?.spdid,
                    pesan1: pesan1,
                    pesan2: pesan2,
                    appid_apotek: datamitra?.appid_apotek,
                    regid_apotek: datamitra?.regid_apotek,
                    spdstatus: datamitra?.spdstatus,
                    spdjenis: datamitra?.spdjenis,
                    nama_apotek: datamitra?.kl_nama,
                    doknama: datadoc?.nama_lengkap,
                    status: datamitra?.spdstatus,
                    uid: dctid,
                    user_gideon: 2,
                  },
                })
                  .then(onThenDeletePengajuan)
                  .catch(ch => {
                    if (showlog) {
                      console.log('ch', ch)
                    }
                    onShowToast({
                      type: 'Fail',
                      message: <>{'[C0] Proses gagal.'}</>,
                    })
                  })
                  .finally(() => {
                    setloading(!true)
                  })
              }
            })
            .finally(() => {
              setloading(!true)
            })
        }
      },
      onClose: () => {
        setpropsalertform(defaultpropsalertform)
      },
      onCancel: () => {
        eventBtn.target.click()
        setpropsalertform(defaultpropsalertform)
      },
    })
  }
  const onBackPress = e => {
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      children: 'Apakah anda yakin akan keluar menu ini?',
      onConfirm: goBack,
      onClose: () => setpropsalertform(defaultpropsalertform),
      onCancel: () => setpropsalertform(defaultpropsalertform),
    })
  }
  const fetchData = (params = {}) => {
    seterror(null)
    setparams(params)
    mSearchMitra(params)
      .then(({data: resdata = {}, statusCode = 0}) => {
        if (showlog) {
          console.log('resdata', resdata)
        }
        if (statusCode === 200 && resdata?.status === 1) {
          if (params?.input?.offset === 0) {
            setdata(resdata?.data)
            setdatacount(resdata?.count || 0)
          } else {
            setdata([...data, ...resdata.data])
          }
          if (resdata?.data?.length < limit) {
            setloadmore(false)
          }
        } else {
          if (loadmore) {
            seterror(resdata?.message || 'Gagal mengambil data!')
          }
          setloadmore(false)
        }
      })
      .catch(ch => {
        if (showlog) {
          console.log('ch', ch)
        }
        setloadmore(false)
        seterror('[C0] Gagal mengambil data!')
      })
      .finally(() => {
        if (showlog) {
          console.log('finish')
        }
        setloading(false)
        setonloadmore(false)
      })
  }
  const onChangeTab = (tab, title = '') => {
    if (showlog) {
      console.log('tab, title', tab, title)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    setdatacount(0)
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        spdstatus: tab,
      },
    })
  }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    setdatacount(0)
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        carimobile: keyword,
      },
    })
  }
  const onFetchMore = () => {
    if (data.length > 0 && loadmore && !onloadmore) {
      setonloadmore(true)
      return fetchData({
        ...params,
        input: {
          ...params.input,
          offset: params.input.offset + limit,
        },
      })
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel)
      }
      if (ariaLabel === 'reset-search-mitra') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-search-mitra',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-search-mitra',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-search-mitra') {
        return onSearchData()
      }
      if (e?.target?.dataset?.rsbsBackdrop === 'true') {
        // onClick backdrop Bottom Sheet
        return setpropsbottomsheet(defaultpropsbottomsheet)
      }
      if (ariaLabel === 'add-mitra') {
        const newpath =
          location.pathname.replace(dctid, '') + ariaLabel + '/' + dctid
        return navigate(newpath)
      }
      if (`${ariaLabel}`.match(/^detail-mitra-/i)) {
        const idx = `${ariaLabel}`.replace(/^detail-mitra-/i, '')
        // console.log('idx', data[idx])
        // - Terima :
        //     - ketika spdjenis = apotek(2) Dan spdstatus = mengajukan kerjasama(1) atau mengajukan pemutusan(3)
        // - Batal :
        //     - ketika spdjenis = dokter(1) Dan spdstatus = mengajukan kerjasama(1) atau mengajukan pemutusan(3)
        // - Tolak :
        //     - ketika spdjenis = apotek(2) Dan spdstatus = mengajukan kerjasama(1) atau mengajukan pemutusan(3)
        // - Putus :
        //     - ketika spdstatus = sudah terjalin kerjasama(2)
        const flagjenis = `${data[idx]?.defaultdata?.spdjenis}${data[idx]?.defaultdata?.spdstatus}`
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          snapPoints: ({minHeight, maxHeight}) => [
            maxHeight / 1.5,
            maxHeight / 1.5,
          ],
          children: (
            <VmView className="z-[2] rounded-t-2xl text-left">
              <VmView className="fixed z-[2] rounded-t-2xl top-0 left-0 right-0 bg-gray7-payment px-5 py-4">
                Detail Mitra
              </VmView>
              <VmView className="relative px-5 pb-4">Detail Mitra</VmView>
              <VmView className="relative flex-col overflow-scroll p-5 pb-[5rem] h-[-webkit-fill-available] min-h-[70%] bg-white cursor-pointer">
                <VmView className="flex flex-col overflow-y-scroll">
                  <VmView className="text-sm">
                    <VmText className="pb-0 text-gray2-payment">Nama</VmText>
                    <VmText className="pb-2">{data[idx]?.title || '-'}</VmText>
                  </VmView>
                  <VmView className="text-sm">
                    <VmText className="pb-0 text-gray2-payment">Alamat</VmText>
                    <VmText className="pb-2">
                      {data[idx]?.address || '-'}
                    </VmText>
                  </VmView>
                  <VmView className="text-sm">
                    <VmText className="pb-0 text-gray2-payment">
                      Penanggung Jawab
                    </VmText>
                    <VmText className="pb-2">
                      {data[idx]?.responsible || '-'}
                    </VmText>
                  </VmView>
                  <VmView className="text-sm">
                    <VmText className="pb-0 text-gray2-payment">
                      No. Telp
                    </VmText>
                    <VmText className="pb-2">{data[idx]?.telp || '-'}</VmText>
                  </VmView>
                  <VmView className="text-sm">
                    <VmText className="pb-0 text-gray2-payment">
                      Insentif
                    </VmText>
                    <VmText className="pb-2">
                      {data[idx]?.insentif || '0'} %
                    </VmText>
                  </VmView>
                  <VmView className="text-sm">
                    <VmText className="pb-0 text-gray2-payment">
                      Keterangan
                    </VmText>
                    <VmText className="pb-2">{data[idx]?.ket || '-'}</VmText>
                  </VmView>
                  <VmView className="text-sm">
                    <VmText className="pb-0 text-gray2-payment">Status</VmText>
                    <VmText className="pb-2">
                      {data[idx]?.statusMessage || '-'}
                    </VmText>
                  </VmView>
                </VmView>
              </VmView>
              <VmView className="absolute flex flex-row justify-between backdrop-blur-sm bg-white/10 p-5 bottom-0 left-0 right-0">
                {['21', '23', '11', '13', '12', '22'].includes(flagjenis) && (
                  <VmButton
                    label="bs-dontagree"
                    className={classNames(
                      'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[47.5%] !bg-red-600 !text-white'
                    )}
                    hidering={true}
                    onClick={() => onTolak(data[idx], {target: e?.target})}
                  >
                    {['21', '23'].includes(flagjenis)
                      ? `Tolak`
                      : ['11', '13'].includes(flagjenis)
                      ? 'Batal'
                      : 'Putus'}
                  </VmButton>
                )}
                {['21', '23'].includes(flagjenis) && (
                  <VmButton
                    label="bs-agree"
                    className={classNames(
                      'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[47.5%] !bg-green-600 !text-white'
                    )}
                    hidering={true}
                    onClick={() => onTerima(data[idx], {target: e?.target})}
                  >
                    {`Terima`}
                  </VmButton>
                )}
              </VmView>
            </VmView>
          ),
        })
      }
    } catch (error) {
      if (showlog) {
        console.log('onClickError', error)
      }
    }
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-search-mitra') {
        return setkeyword(value)
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-search-mitra' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const onEndReached = etbodytablecost => {
    const isBottom =
      etbodytablecost?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      onFetchMore()
    }
  }
  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        window.ReactNativeWebView.postMessage('onBackPress:')
      })
    } else {
      navigate(-1)
    }
  }
  const onHardwareBackPress = event => {
    const {data} = event
    if (
      `${data}`?.match(/^onHardwareBackPress:/i) &&
      `${location?.pathname}`?.split('/').length === 3
    ) {
      const rbsPortal = document.getElementsByTagName('reach-portal')
      if (rbsPortal[0]?.children[0]?.dataset?.rsbsState === 'open') {
        rbsPortal[0]?.children[0]?.children[0]?.click()
        return
      }
      return onBackPress()
    }
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  // document.addEventListener('keypress')
  const {pullChange} = usePullToRefresh({
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-search-mitra',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(params)
    const showerrorloaddoc = (code = '') =>
      setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: (
          <>
            {code} Gagal mengambil informasi user!
            <br />
            Silakan hubungi <b>Admin</b> jika user anda masih terkendala.
          </>
        ),
        onConfirm: goBack,
        onClose: goBack,
        onCancel: goBack,
      })
    UserController(
      {
        id: dctid,
        key: 'index',
        dispatch: () => {},
        _ldata: 'mUserInfo',
        field: [
          'status',
          'name',
          'spesialis',
          'address',
          'province',
          'city',
          'subdistrict',
          'email',
          'nosip',
          'nostr',
          'keterangan',
          'user_gideon',
        ],
      },
      () => {}
    )
      .then(resdata => {
        if (resdata?.status === 1) {
          console.error(resdata, ' ૮ ˙Ⱉ˙ ა rawr!')
          setdatadoc(
            Object.assign(datadoc, resdata?.data ? resdata?.data : {}, {
              nama_lengkap: resdata?.data?.name,
              nostr: datadoc?.nostr || resdata?.data?.nostr,
              user_gideon: resdata?.data?.user_gideon || 2,
            })
          )
        } else {
          return showerrorloaddoc()
        }
      })
      .catch(ch => {
        return showerrorloaddoc('[C0]')
      })
      .finally(() => {
        setloadingdoc(false)
      })
    return () => {}
  }, [])
  console.error(datadoc, 'datadoc ૮ ˙Ⱉ˙ ა rawr!')
  return (
    <VmView className="fixed inset-0 bg-blue4-payment px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Mitra Apotek / Klinik'}
          onBackPress={onBackPress}
        />
        {/* Searchbar */}
        <Searchbar keyword={keyword} />
        {/* Tabs */}
        <Tabs onChangeTab={onChangeTab} />
        {/* Content */}
        <VmView onScroll={onScroll} className="flex flex-col overflow-y-scroll">
          <VmView
            className="refresh-container w-fit -mt-10 m-auto"
            style={{marginTop: pullChange / 3.118 || ''}}
          >
            <VmView className="refresh-icon p-2 rounded-full">
              <VmIcons
                name="VmArrowPathIcon"
                style={{transform: `rotate(${pullChange}deg)`}}
                size={25}
              />
            </VmView>
          </VmView>
          {data.map((im, index) => {
            return (
              <CardItem key={`CardItem-${index}`} index={index} data={im} />
            )
          })}
          {!loadmore ? (
            <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
              {error ? error : 'Sudah tidak ada data'}
            </VmView>
          ) : (
            <VmView
              key={'loadmore'}
              label="loadmore"
              className="relative self-center text-center text-gray2-payment last:mb-20 "
            >
              <VmSpinner className="text-blue1-payment" />
            </VmView>
          )}
        </VmView>
        {/* Footer */}
        {datadoc.user_gideon == 2 && <Footer />}
        {/* Simple Toast */}
        <SimpleToast {...propssimpletoast} />
        {/* Bottom Sheet */}
        <BottomSheet ref={bottomSheetRef} {...propsbottomsheet} />
        {/* AlertForm */}
        <AlertForm key={JSON.stringify({propsalertform})} {...propsalertform} />
        {/* Loading Full Screen */}
        <ProgressFullScreen visible={loading || loadingdoc} />
      </VmView>
    </VmView>
  )
}
