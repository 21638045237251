import {
  useCallback,
  // useRef,
  useState,
  Fragment,
  useEffect,
  createRef,
  useRef,
} from 'react'
import {ReportMobile, TemplateForm} from 'template'
import {
  getKeyByValue,
  getParentNode,
  isEmpty,
  isFunction,
  calcAge,
  rgbToHex,
} from 'utils'
// import PatientInformation from './PatientInformation'
import PatExamFooters from 'template/PatientExaminationMobile/PatExamFooters'
import moment from 'moment'
import {FullErrorView, VmPulltoRefresh} from 'molecules'
import {rgbcolors} from 'config/colors'
import {VmView, VmIcons, VmInput, VmText, VmButton, VmModal} from 'components'
import {useParams, useLocation, useNavigate, Navigate} from 'react-router-dom'
import axios from 'axios'
import QueryString from 'qs'
import AlertOk from 'components/AlertOk'
import {catatLogDokter, empty} from 'utils/functions'
import {defaultClassNames} from 'template/Form/HandlerForm'
import InputCustom from 'template/Form/InputCustom'
import classNames from 'classnames'
import {pasienDummy, pasienEmpty} from './Dummy'
import AlertYesNo from 'components/AlertYesNo'
const {
  REACT_APP_BASE_URL_DOKTER: BASE_URL,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_API_UTAMA,
} = process.env

const [containerClassName, titleClassName] = defaultClassNames
const attrTextArea = {
    typeInput: 'textarea',
    Pascal: '-',
    propsInput: {
      disabled: false,
      readOnly: false,
      className: 'h-20 resize-none',
    },
  },
  attrText = {
    typeInput: 'text',
    Pascal: '-',
    propsInput: {
      disabled: false,
      readOnly: false,
    },
  },
  attrDate = {
    typeInput: 'date',
    Pascal: '-',
    propsInput: {
      disabled: false,
      readOnly: false,
    },
  }
export default function TambahPasien() {
  const {dctid} = useParams()

  const alert = useRef()
  const prompt = useRef()
  const formRef = createRef()
  const modalProvinsiRef = createRef()
  const modalKotaRef = createRef()
  const modalKecamatanRef = createRef()
  const modalAgamaRef = createRef()
  const modalPendidikanRef = createRef()

  const navigate = useNavigate()

  const [errorinitial, seterrorinitial] = useState(false)
  const [errormessage, seterrormessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [provinsi, setProvinsi] = useState([])
  const [kota, setKota] = useState([])
  const [kecamatan, setKecamatan] = useState([])
  // const [listForm, setListForm] = useState(defaultFormData({}, {}, false))
  //   const [data, setData] = useState({})
  const [data, setData] = useState(pasienEmpty)

  // Data Relasi dipisah, untuk menghindari konflik set state setData karena penggunaan multiple useEffect
  const [dataRelasi, setDataRelasi] = useState({
    parentid: '',
    parentrm: '',
    parentnama: '',
  })

  useEffect(() => {
    // console.log({dctid, kunid})

    // getDataKunjungan()
    // Load Data Provinsi
    loadProvinsi()

    let relasi_pasien = localStorage.getItem('tambah_relasi_pasien')
    // console.log({relasi_pasien})
    if (!empty(relasi_pasien)) {
      // Intent dari Pilih Pasien (untuk Hubungan dengan pasien lain)
      // Set State Relasi
      let relasi_pasien_parsed = JSON.parse(relasi_pasien)
      let prev_filled_data = JSON.parse(
        localStorage.getItem('prev_data_tambah_pasien')
      )
      // console.log(relasi_pasien_parsed)
      setDataRelasi(prev => ({
        ...prev,
        parentid: relasi_pasien_parsed.pasid,
        parentrm: relasi_pasien_parsed.pasrm,
        parentnama: relasi_pasien_parsed.pasnama,
      }))
      setData(prev_filled_data)

      // console.log('prev_filled_data', prev_filled_data)
      setTimeout(() => {
        setData(prev => ({
          ...prev,
          kota: prev_filled_data.kota,
        }))
      }, 256)
      setTimeout(() => {
        setData(prev => ({
          ...prev,
          kecamatan: prev_filled_data.kecamatan,
        }))
      }, 512)
    } else {
      // Baru buka
      // Get No. Rm Pasien
      getRm()
    }

    return () => {}
  }, [])

  useEffect(() => {
    // Setiap kali value provinsi berubah, eksekusi gan

    console.log('data Provinsi berubah', data.provinsi)
    if (!empty(data.provinsi.id)) {
      // Habis pilih provinsi, maka reload data kota, clear state kota & kec.
      loadKota()
      setData({
        ...data,
        kota: {id: '', nama: ''},
        kecamatan: {id: '', nama: ''},
      })
    } else {
      // Habis clear provinsi, maka clear state kota & kecamatan, clear list kota & kecamatan
      // console.log('habis clear ya kota')
      setData(prev => ({
        ...prev,
        kota: {id: '', nama: ''},
        kecamatan: {id: '', nama: ''},
      }))
      setKota([])
      setKecamatan([])
    }
    return () => {}
  }, [data.provinsi.id])

  useEffect(() => {
    // Setiap kali value kota berubah, eksekusi gan

    console.log('data kota berubah', data.kota)
    if (!empty(data.kota.id)) {
      // Habis pilih kota, maka reload data kec, clear state kec.
      loadKecamatan()
      setData({
        ...data,
        kecamatan: {id: '', nama: ''},
      })
    } else {
      // console.log('habis clear ya kecamatan')
      // Habis clear kota, maka clear state kecamatan, clear list kecamatan
      setData(prev => ({
        ...prev,
        kecamatan: {id: '', nama: ''},
      }))
      setKecamatan([])
    }
    return () => {}
  }, [data.kota.id])

  const getRm = () => {
    console.log('dctid gan', dctid)
    axios
      .post(
        `${BASE_URL}/pasien/get-rm`,
        QueryString.stringify({
          uid: dctid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        console.log('Berhasil get DATA RM', res)
        setData({
          ...data,
          pasrm: res,
        })
      })
      .catch(e => {
        console.log(
          'Terjadi Kesalahan saat Get RM',
          e.response?.data ?? e.message
        )
      })
  }

  const validate = () => {
    const {pasnama, pasrm, pasnoktp, kecamatan, statussambung} = data
    const {parentid} = dataRelasi
    if (empty(pasnama)) {
      alert.current?.show({message: 'Nama Lengkap harus diisi'})
      return
    } else if (empty(pasrm)) {
      alert.current?.show({message: 'No. RM harus diisi'})
      return
    }

    if (!empty(parentid) && empty(statussambung)) {
      // Jika mengisi relasi parent id, maka wajib mengisi Hubungan dengan Pasien
      alert.current?.show({message: 'Relasi dengan pasien lain wajib diisi.'})
      return
    }

    if (empty(kecamatan.id)) {
      alert.current?.show({
        message: 'Harap isi data Provinsi, Kota, dan Kecamatan.',
      })
      return
    }

    if (empty(pasnoktp)) {
      prompt.current?.show(
        {
          message:
            'Silakan isi No. KTP untuk mempermudah pencarian data pasien. Apakah anda yakin akan menambah pasien tanpa ktp ?',
        },
        simpan
      )
      return
    }

    simpan()
  }

  const simpan = () => {
    setLoading(true)
    const {
      pasnama,
      pasrm,
      pasalamat,
      pasjk,
      pastgllahir,
      pasnobpjs,
      pasnokk,
      pasnoktp,
      pasgoldarah,
      kecamatan,
      pastelp,
      pekerjaan,
      pasemail,
      pasalergi,
      statussambung,
    } = data
    const {parentid} = dataRelasi
    let tgl = moment().format('YYYY-MM-DD HH:mm')
    // onConfirm
    axios
      .post(
        `${BASE_URL}/pasien-v2/create`,
        QueryString.stringify({
          uid: dctid,
          tgl: tgl,
          data: {
            ['pasnobpjs']: pasnobpjs,
            ['pasnokk']: pasnokk,
            ['pasnama']: pasnama,
            ['pasgoldarah']: pasgoldarah,
            ['pasjk']: pasjk,
            ['pastgllahir']: pastgllahir,
            ['pasalamat']: pasalamat,
            ['pasidkec']: kecamatan.id,
            ['pastelp']: pastelp,
            ['paspekerjaan']: pekerjaan,
            ['pasemail']: pasemail,
            ['pasalergi']: pasalergi,
            ['pasnoktp']: pasnoktp,
            ['pasrm']: pasrm,
            ['pasparentid']: parentid,
            ['passtatussambung']: statussambung,
          },
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data

        // Catat Log Dokter
        catatLogDokter({
          keterangan: `Menambahkan pasien ${pasnama}`,
          menu: 'Pelayanan - Registrasi Pasien',
          url: 'prd-tra-registrasi-pasien',
          ip: '127.0.0.1',
          uid: dctid,
        })

        // set localStorage untuk menyimpan data gan,
        localStorage.setItem(
          'ubah_kunjungan_pasien',
          JSON.stringify({...data, pasid: res.data})
        )
        // Clear localStorage relasi pasien & prev filled data, baru navigate back
        localStorage.removeItem('tambah_relasi_pasien')
        localStorage.removeItem('prev_data_tambah_pasien')
        navigate(-1)
      })
      .catch(e => {
        alert.current?.show({
          message: 'Terjadi kesalahan saat Menambahkan Pasien.',
        })
        console.log(
          'Terjadi Kesalahan saat Menambahkan Pasien',
          e.response?.data ?? e.message
        )
      })
  }

  const openModalProvinsi = e => {
    // console.log('provinsi gan', e, e.target.nextElementSibling)

    // Fill body content modal Provinsi
    const newClear = e.target.nextElementSibling
    var newContent = document.createElement('div', {})
    newContent.className = 'bg-white rounded-xl pt-[3.5rem]'
    var newHeader = document.createElement('div', {})
    newHeader.className =
      'bg-white absolute flex p-4 top-0 left-0 right-0 w-full'
    var newClose = document.createElement('input', {})
    newClose.type = 'button'
    newClose.style.color = rgbcolors.danger
    newClose.style.border = 0
    newClose.value = 'ⓧ'
    newClose.onclick = () => {
      // Tutup modal
      modalProvinsiRef.current.close?.()
    }
    var newFilters = document.createElement('input', {})
    newFilters.style.width = 'inherit'
    newFilters.style.marginInline = '10px'
    newFilters.ariaLabel = 'Cari Provinsi'
    newFilters.placeholder = 'Cari Provinsi'
    newFilters.onchange = function (ef) {
      const arrData = ef.target.parentNode?.nextElementSibling?.childNodes
      const lenData = arrData.length
      for (let i = 0; i < lenData; i++) {
        const element = arrData[i]
        if (element.tagName === 'P') {
          if (
            !element.ariaLabel
              ?.toLocaleLowerCase()
              ?.includes(ef.target.value.toLocaleLowerCase())
          ) {
            element.style.display = 'none'
          } else {
            element.removeAttribute('style')
          }
        }
      }
      ef.target.focus()
    }
    var newEndOfLine = document.createElement('p', {})
    newEndOfLine.className =
      'text-center mx-1 my-2 primary-ghost PoppinsRegular'
    newEndOfLine.replaceChildren('Sudah tidak ada data!')
    newHeader.replaceChildren(...[newFilters, newClose])
    newContent.replaceChildren(
      ...[
        ...provinsi.map(im => {
          var newChild = document.createElement('p', {})
          newChild.className =
            'btn mx-1 primary-ghost PoppinsRegular rounded-xl'
          newChild.replaceChildren(im.provinsi)
          newChild.ariaLabel = im.provinsi
          newChild.onclick = function () {
            // var newClear = e.target.parentNode?.childNodes[1]
            const parentNodeE = e.target.parentNode
            if (parentNodeE?.className?.includes(' flex-col')) {
              parentNodeE.className = parentNodeE.className.replace(
                ' flex-col',
                ''
              )
            }
            if (!e.target?.className?.includes(' mr-2')) {
              e.target.className += ' mr-2'
            }
            // Set State & Tutup Modal
            setData({...data, provinsi: {id: im.idprovinsi, nama: im.provinsi}})
            modalProvinsiRef.current.close?.()
            // e.target.value = em.target.ariaLabel
            // e.target.focus()
            // e.target.blur()
          }
          return newChild
        }),
      ]
    )
    modalProvinsiRef.current.open?.([newHeader, newContent, newEndOfLine])
    newFilters.focus({preventScroll: true})
    if (!!e.target.value) {
      newClear.style.color = rgbcolors['danger']
      newClear.value = 'ⓧ'
    } else {
      newClear.style.color = rgbcolors['primary']
      newClear.value = '▼'
    }
  }

  const openModalKota = e => {
    // console.log('kota gan', e, e.target.nextElementSibling)

    // Fill body content modal kota
    const newClear = e.target.nextElementSibling
    var newContent = document.createElement('div', {})
    newContent.className = 'bg-white rounded-xl pt-[3.5rem]'
    var newHeader = document.createElement('div', {})
    newHeader.className =
      'bg-white absolute flex p-4 top-0 left-0 right-0 w-full'
    var newClose = document.createElement('input', {})
    newClose.type = 'button'
    newClose.style.color = rgbcolors.danger
    newClose.style.border = 0
    newClose.value = 'ⓧ'
    newClose.onclick = () => {
      // Tutup modal
      modalKotaRef.current.close?.()
    }
    var newFilters = document.createElement('input', {})
    newFilters.style.width = 'inherit'
    newFilters.style.marginInline = '10px'
    newFilters.ariaLabel = 'Cari Kota'
    newFilters.placeholder = 'Cari Kota'
    newFilters.onchange = function (ef) {
      const arrData = ef.target.parentNode?.nextElementSibling?.childNodes
      const lenData = arrData.length
      for (let i = 0; i < lenData; i++) {
        const element = arrData[i]
        if (element.tagName === 'P') {
          if (
            !element.ariaLabel
              ?.toLocaleLowerCase()
              ?.includes(ef.target.value.toLocaleLowerCase())
          ) {
            element.style.display = 'none'
          } else {
            element.removeAttribute('style')
          }
        }
      }
      ef.target.focus()
    }
    var newEndOfLine = document.createElement('p', {})
    newEndOfLine.className =
      'text-center mx-1 my-2 primary-ghost PoppinsRegular'
    newEndOfLine.replaceChildren('Sudah tidak ada data!')
    newHeader.replaceChildren(...[newFilters, newClose])
    newContent.replaceChildren(
      ...[
        ...kota.map(im => {
          var newChild = document.createElement('p', {})
          newChild.className =
            'btn mx-1 primary-ghost PoppinsRegular rounded-xl'
          newChild.replaceChildren(im.kota)
          newChild.ariaLabel = im.kota
          newChild.onclick = function () {
            // var newClear = e.target.parentNode?.childNodes[1]
            const parentNodeE = e.target.parentNode
            if (parentNodeE?.className?.includes(' flex-col')) {
              parentNodeE.className = parentNodeE.className.replace(
                ' flex-col',
                ''
              )
            }
            if (!e.target?.className?.includes(' mr-2')) {
              e.target.className += ' mr-2'
            }
            // Set State & Tutup Modal
            setData({...data, kota: {id: im.idkota, nama: im.kota}})
            modalKotaRef.current.close?.()
            // e.target.value = em.target.ariaLabel
            // e.target.focus()
            // e.target.blur()
          }
          return newChild
        }),
      ]
    )
    modalKotaRef.current.open?.([newHeader, newContent, newEndOfLine])
    newFilters.focus({preventScroll: true})
    if (!!e.target.value) {
      newClear.style.color = rgbcolors['danger']
      newClear.value = 'ⓧ'
    } else {
      newClear.style.color = rgbcolors['primary']
      newClear.value = '▼'
    }
  }

  const openModalKecamatan = e => {
    // console.log('kecamatan gan', e, e.target.nextElementSibling)

    // Fill body content modal kecamatan
    const newClear = e.target.nextElementSibling
    var newContent = document.createElement('div', {})
    newContent.className = 'bg-white rounded-xl pt-[3.5rem]'
    var newHeader = document.createElement('div', {})
    newHeader.className =
      'bg-white absolute flex p-4 top-0 left-0 right-0 w-full'
    var newClose = document.createElement('input', {})
    newClose.type = 'button'
    newClose.style.color = rgbcolors.danger
    newClose.style.border = 0
    newClose.value = 'ⓧ'
    newClose.onclick = () => {
      // Tutup modal
      modalKecamatanRef.current.close?.()
    }
    var newFilters = document.createElement('input', {})
    newFilters.style.width = 'inherit'
    newFilters.style.marginInline = '10px'
    newFilters.ariaLabel = 'Cari Kecamatan'
    newFilters.placeholder = 'Cari Kecamatan'
    newFilters.onchange = function (ef) {
      const arrData = ef.target.parentNode?.nextElementSibling?.childNodes
      const lenData = arrData.length
      for (let i = 0; i < lenData; i++) {
        const element = arrData[i]
        if (element.tagName === 'P') {
          if (
            !element.ariaLabel
              ?.toLocaleLowerCase()
              ?.includes(ef.target.value.toLocaleLowerCase())
          ) {
            element.style.display = 'none'
          } else {
            element.removeAttribute('style')
          }
        }
      }
      ef.target.focus()
    }
    var newEndOfLine = document.createElement('p', {})
    newEndOfLine.className =
      'text-center mx-1 my-2 primary-ghost PoppinsRegular'
    newEndOfLine.replaceChildren('Sudah tidak ada data!')
    newHeader.replaceChildren(...[newFilters, newClose])
    newContent.replaceChildren(
      ...[
        ...kecamatan.map(im => {
          var newChild = document.createElement('p', {})
          newChild.className =
            'btn mx-1 primary-ghost PoppinsRegular rounded-xl'
          newChild.replaceChildren(im.kecamatan)
          newChild.ariaLabel = im.kecamatan
          newChild.onclick = function () {
            // var newClear = e.target.parentNode?.childNodes[1]
            const parentNodeE = e.target.parentNode
            if (parentNodeE?.className?.includes(' flex-col')) {
              parentNodeE.className = parentNodeE.className.replace(
                ' flex-col',
                ''
              )
            }
            if (!e.target?.className?.includes(' mr-2')) {
              e.target.className += ' mr-2'
            }
            // Set State & Tutup Modal
            setData({
              ...data,
              kecamatan: {id: im.idkecamatan, nama: im.kecamatan},
            })
            modalKecamatanRef.current.close?.()
            // e.target.value = em.target.ariaLabel
            // e.target.focus()
            // e.target.blur()
          }
          return newChild
        }),
      ]
    )
    modalKecamatanRef.current.open?.([newHeader, newContent, newEndOfLine])
    newFilters.focus({preventScroll: true})
    if (!!e.target.value) {
      newClear.style.color = rgbcolors['danger']
      newClear.value = 'ⓧ'
    } else {
      newClear.style.color = rgbcolors['primary']
      newClear.value = '▼'
    }
  }

  const loadProvinsi = () => {
    // console.log(url)
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findprovinsi`,
        QueryString.stringify({
          a: 'default',
          provinsiaktif: 1,
        })
      )
      .then(({data}) => {
        let dataProvinsi = data.data
        // console.log(dataProvinsi)
        setProvinsi(dataProvinsi)
      })
      .catch(e => {
        console.log(
          'Error saat Get data Provinsi',
          e.response?.data?.message ?? e.message,
          e.message
        )
      })
  }

  const loadKota = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkota-t`,
        QueryString.stringify({
          a: 'default',
          id: data.provinsi.id,
        })
      )
      .then(({data}) => {
        let dataKota = data.data
        setKota(dataKota)
      })
      .catch(e => {
        console.log(
          'Error saat Get data Kota',
          e.response?.data?.message ?? e.message,
          e.message
        )
      })
  }

  const loadKecamatan = () => {
    axios
      .post(
        `${BASE_URL_API_UTAMA}/backend-tiket/findkecamatan-t`,
        QueryString.stringify({
          a: 'default',
          id: data.kota.id,
        })
      )
      .then(({data}) => {
        let dataKecamatan = data.data
        setKecamatan(dataKecamatan)
      })
      .catch(e => {
        console.log(
          'Error saat Get data Kecamatan',
          e.response?.data?.message ?? e.message,
          e.message
        )
      })
  }

  const footers = {
    title: {no: 'Batal', yes: 'Simpan'},
    className: {
      yes: 'md:flex-none md:w-1/6',
      no: 'bg-white md:flex-none md:w-1/6',
      container: 'md:relative',
    },
    color: {
      yes: 'success',
      no: 'danger',
    },
    onClick: {
      yes: () => {
        console.log(data)
        validate()
      },
      no: () => {
        // Clear localStorage, baru navigate back
        localStorage.removeItem('tambah_relasi_pasien')
        localStorage.removeItem('prev_data_tambah_pasien')
        navigate(-1)
      },
    },
  }

  return (
    <Fragment>
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />
      {errorinitial ? (
        <FullErrorView message={errormessage} />
      ) : (
        <>
          {/* <PatientInformation
          pathname={pathname}
          controllers={controllers}
          navigate={navigate}
          queryParams={queryParams}
          getDetailMedicalRecord={getDetailMedicalRecord}
          getMedicalRecords={getMedicalRecords}
          datamedicalrecord={datamedicalrecord}
          currentrm={datacurrentmedicalrecord}
          id={id}
          data={pdata}
          avatar={avatar}
          loadingrm={loadingrm}
        /> */}
          <form
            {...{}}
            ref={formRef}
            aria-label={''}
            // onChangeCapture={e =>
            //   onChangeCapture(e, e?.nativeEvent?.target?.value)
            // }
            // onKeyDown={e => onKeyDown(e, formRef.current)}
            // dangerouslySetInnerHTML={undefined}
          >
            {/* {listForm === null ? <></> : <HandlerForm data={listForm} />} */}
            <Fragment>
              <VmView className={classNames('', 'border-t-8')} />
              <VmView className={containerClassName}>
                <VmText className={titleClassName}>Tambah Pasien</VmText>

                <InputCustom
                  data={{
                    ariaLabel: 'pasrm',
                    value: data['pasrm'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'No. Rm'}
                  usetitle={true}
                  label={'pasrm'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasrm: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasnobpjs',
                    value: data['pasnobpjs'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'No. BPJS'}
                  usetitle={true}
                  label={'pasnobpjs'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasnobpjs: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasnokk',
                    value: data['pasnokk'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'No. KK'}
                  usetitle={true}
                  label={'pasnokk'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasnokk: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasnoktp',
                    value: data['pasnoktp'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'No. NIK'}
                  usetitle={true}
                  label={'pasnoktp'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasnoktp: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasnama',
                    value: data['pasnama'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'Nama Pasien'}
                  usetitle={true}
                  label={'pasnama'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasnama: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasgoldarah',
                    value: data['pasgoldarah'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'Golongan Darah'}
                  usetitle={true}
                  label={'pasgoldarah'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasgoldarah: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                {/* Jenis Kelamin */}
                <VmView
                  className={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                >
                  <VmText className={classNames('md:w-[30%]')}>
                    {'Jenis Kelamin'}
                  </VmText>
                </VmView>
                <VmView className="m-4">
                  {['Laki-laki', 'Perempuan'].map((item, i) => {
                    return (
                      <VmButton
                        key={i}
                        className={'mr-2'}
                        onClick={() => {
                          setData({...data, pasjk: item})
                        }}
                        variant={data['pasjk'] == item ? '' : 'outline'}
                      >
                        {item}
                      </VmButton>
                    )
                  })}
                </VmView>

                <InputCustom
                  data={{
                    ariaLabel: 'pastgllahir',
                    value: data['pastgllahir'] ?? '',
                    // required: true,
                    ...attrDate,
                  }}
                  title={'Tanggal Lahir'}
                  usetitle={true}
                  label={'pastgllahir'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pastgllahir: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasalamat',
                    value: data['pasalamat'] ?? '',
                    // required: true,
                    ...attrTextArea,
                  }}
                  title={'Alamat Lengkap'}
                  usetitle={true}
                  label={'pasalamat'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasalamat: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasprovinsi',
                    // required: true,
                    ...attrText,
                  }}
                  title={'Provinsi'}
                  usetitle={true}
                  label={'pasprovinsi'}
                  propsInput={{
                    onFocus: e => {
                      // Buka Modal Provinsi
                      openModalProvinsi(e)
                    },
                    value: data['provinsi']['nama'] ?? '',
                    readonly: true,
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                  suffix={() => {
                    return (
                      <VmInput
                        onFocus={e => {
                          // console.log("Button arrow onFocus gan!", e,e.target.previousSibling)
                          if (!empty(data['provinsi']['id'])) {
                            // Jika sudah terpilih, maka clear value
                            setData({...data, provinsi: {nama: '', id: ''}})
                          } else {
                            // Jika belum terpilih, maka open modal dengan trigger focus ke prev siblingnya.
                            e.target.previousSibling.focus?.()
                          }
                        }}
                        {...{
                          label: `suffix-btn-provinsi`,
                          typeInput: 'button',
                          style: {
                            color: !empty(data['provinsi']['id'])
                              ? rgbcolors['danger']
                              : rgbcolors['primary'],
                          },
                          defaultValue: !empty(data['provinsi']['id'])
                            ? 'ⓧ'
                            : '▼',
                        }}
                      />
                    )
                  }}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'paskota',
                    // required: true,
                    ...attrText,
                  }}
                  title={'Kota'}
                  usetitle={true}
                  label={'paskota'}
                  propsInput={{
                    onFocus: e => {
                      // console.log('onFocus gan!!!')
                      openModalKota(e)
                    },
                    readonly: true,
                    value: data['kota']['nama'] ?? '',
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                  suffix={() => {
                    return (
                      <VmInput
                        onFocus={e => {
                          // console.log("Button arrow onFocus gan!", e,e.target.previousSibling)
                          if (!empty(data['kota']['id'])) {
                            // Jika sudah terpilih, maka clear value
                            setData({...data, kota: {nama: '', id: ''}})
                          } else {
                            // Jika belum terpilih, maka open modal dengan trigger focus ke prev siblingnya.
                            e.target.previousSibling.focus?.()
                          }
                        }}
                        {...{
                          label: `suffix-btn-kota`,
                          typeInput: 'button',
                          style: {
                            color: !empty(data['kota']['id'])
                              ? rgbcolors['danger']
                              : rgbcolors['primary'],
                          },
                          defaultValue: !empty(data['kota']['id']) ? 'ⓧ' : '▼',
                        }}
                      />
                    )
                  }}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'paskecamatan',
                    // required: true,
                    ...attrText,
                  }}
                  title={'Kecamatan'}
                  usetitle={true}
                  label={'paskecamatan'}
                  propsInput={{
                    onFocus: e => {
                      console.log('onFocus Kecamatan gan!!!')
                      openModalKecamatan(e)
                    },
                    readonly: true,
                    value: data['kecamatan']['nama'] ?? '',
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                  suffix={() => {
                    return (
                      <VmInput
                        onFocus={e => {
                          // console.log("Button arrow onFocus gan!", e,e.target.previousSibling)
                          if (!empty(data['kecamatan']['id'])) {
                            // Jika sudah terpilih, maka clear value
                            setData({...data, kecamatan: {nama: '', id: ''}})
                          } else {
                            // Jika belum terpilih, maka open modal dengan trigger focus ke prev siblingnya.
                            e.target.previousSibling.focus?.()
                          }
                        }}
                        {...{
                          label: `suffix-btn-kecamatan`,
                          typeInput: 'button',
                          style: {
                            color: !empty(data['kecamatan']['id'])
                              ? rgbcolors['danger']
                              : rgbcolors['primary'],
                          },
                          defaultValue: !empty(data['kecamatan']['id'])
                            ? 'ⓧ'
                            : '▼',
                        }}
                      />
                    )
                  }}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pastelp',
                    value: data['pastelp'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'No. WA'}
                  usetitle={true}
                  label={'pastelp'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pastelp: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'paspekerjaan',
                    value: data['paspekerjaan'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'Pekerjaan'}
                  usetitle={true}
                  label={'paspekerjaan'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, paspekerjaan: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasemail',
                    value: data['pasemail'] ?? '',
                    // required: true,
                    ...attrText,
                  }}
                  title={'E-mail'}
                  usetitle={true}
                  label={'pasemail'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasemail: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                <InputCustom
                  data={{
                    ariaLabel: 'pasalergi',
                    value: data['pasalergi'] ?? '',
                    // required: true,
                    ...attrTextArea,
                  }}
                  title={'Alergi Obat'}
                  usetitle={true}
                  label={'pasalergi'}
                  propsInput={{
                    onChange: e => {
                      let val = e.target.value
                      setData({...data, pasalergi: val})
                    },
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                />

                {/* Hubungan dengan pasien lain -- */}
                <InputCustom
                  data={{
                    ariaLabel: 'pashubungan',
                    // required: true,
                    ...attrText,
                  }}
                  title={'Hubungan dengan pasien lain'}
                  usetitle={true}
                  label={'pashubungan'}
                  propsInput={{
                    onFocus: () => {
                      console.log('onFocus gan!!!')
                      localStorage.setItem(
                        'prev_data_tambah_pasien',
                        JSON.stringify(data)
                      )
                      navigate(
                        `/prd-tra-kunjungan-ubah/pilih-pasien/${dctid}?source=TambahPasien`
                      )
                    },
                    readonly: true,
                    value: dataRelasi['parentnama'] ?? '',
                  }}
                  classItem={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                  suffix={() => {
                    return (
                      <VmInput
                        onFocus={e => {
                          // console.log("Button arrow onFocus gan!", e,e.target.previousSibling)
                          if (!empty(dataRelasi['parentid'])) {
                            // Jika sudah terpilih, maka clear value
                            setDataRelasi({
                              parentid: '',
                              parentrm: '',
                              parentnama: '',
                            })
                          } else {
                            // Jika belum terpilih, maka open modal dengan trigger focus ke prev siblingnya.
                            e.target.previousSibling.focus?.()
                          }
                        }}
                        {...{
                          label: `suffix-btn-hubungan`,
                          typeInput: 'button',
                          style: {
                            color: !empty(dataRelasi['parentid'])
                              ? rgbcolors['danger']
                              : rgbcolors['primary'],
                          },
                          defaultValue: !empty(dataRelasi['parentid'])
                            ? 'ⓧ'
                            : '🔍',
                        }}
                      />
                    )
                  }}
                />

                {/* Status Hubungan dengan pasien */}
                <VmView
                  className={classNames(
                    defaultClassNames[2],
                    'm-4 md:flex-row py-1 text-slate-500 font-normal text-base '
                  )}
                >
                  <VmText className={classNames('md:w-[30%]')}>
                    {'Hubungan Dengan Pasien'}
                  </VmText>
                </VmView>
                <VmView className="m-4">
                  {[
                    {value: 1, text: 'Orang Tua'},
                    {value: 2, text: 'Anak'},
                    {value: 3, text: 'Pasangan (Suami/Istri)'},
                  ].map((relasi, i) => {
                    return (
                      <VmButton
                        key={i}
                        className={'mr-2 mb-2'}
                        onClick={() => {
                          setData({...data, statussambung: relasi.value})
                        }}
                        variant={
                          data['statussambung'] == relasi.value ? '' : 'outline'
                        }
                      >
                        {relasi.text}
                      </VmButton>
                    )
                  })}
                </VmView>
              </VmView>
            </Fragment>

            <PatExamFooters {...Object.assign(footers, {})} />
          </form>

          <VmModal ref={modalProvinsiRef} />
          <VmModal ref={modalKotaRef} />
          <VmModal ref={modalKecamatanRef} />
          <VmModal ref={modalAgamaRef} />
          <VmModal ref={modalPendidikanRef} />
        </>
      )}
    </Fragment>
  )
}
