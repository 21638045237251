import {useEffect, useMemo, useState} from 'react'
import {ProgressFullScreen} from 'molecules'
import {RgxMobileDevice} from 'utils/core'
import MobDataEntriPendaftaran from './MobDataEntriPendaftaran'
import WebDataEntriPendaftaran from './WebDataEntriPendaftaran'

export default function PcareDtEntriKun() {
  const [device, setDevice] = useState(null)
  const detectDevice = () => {
    let isMobile = RgxMobileDevice.test(navigator.userAgent)
    setDevice(isMobile ? 'mobile' : 'web')
  }
  const Content = useMemo(() => {
    const lstContent = {
      mobile: MobDataEntriPendaftaran,
      web: WebDataEntriPendaftaran,
    }
    if (lstContent[device]) {
      return lstContent[device]
    }
    return <></>
  }, [device])
  useEffect(() => {
    detectDevice()
    return () => {}
  }, [])
  if (!device) {
    return <ProgressFullScreen visible={true} />
  }

  return <Content />
}
