import {VmButton, VmIcons, VmSpinner, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {Fragment, useEffect, useRef, useState} from 'react'
import Searchbar from './Searchbar'
import Tabs from './Tabs'
import CardItemPasien from './CardItemPasien'
import Footer from './Footer'
import {eventTrigerInput, isFunction} from 'utils'
import useAddEventListener from 'utils/useAddEventListener'
import {getElement} from 'utils/get-element'
import usePullToRefresh from 'utils/usePullToRefresh'
import {mSearchPasien, mSearchPasienKota, mSearchVisit} from './api'
import {useParams} from 'react-router-dom'
import between from 'utils/between'
import classNames from 'classnames'

export default function SearchPasien({
  labelBackButton = 'modal-back-button',
  onSelectItem = (item = {}, type = '', handler = () => {}) => {},
  ...props
}) {
  const showlog = !false,
    contentRef = useRef(),
    tabsRef = useRef(),
    limit = 10
  const dctid = parseInt(useParams().id)
  const [offset, setoffset] = useState(0)
  const [svisit, setsvisit] = useState(false)
  const [keyword, setkeyword] = useState('')
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  const [loadmore, setloadmore] = useState(true)
  const [onloadmore, setonloadmore] = useState(true)
  const [tablist, settablist] = useState([])
  const [params, setparams] = useState({
    log: showlog,
    input: {
      uid: dctid,
      limit,
      offset,
      carimobile: keyword,
      'show-error-log': `${showlog}`.toUpperCase(),
    },
  })
  const [data, setdata] = useState([])
  const onBackPress = () => {
    if (isFunction(props?.close)) {
      props?.close()
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel)
      }
      if (ariaLabel === 'reset-modal-search-pasien') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-modal-search-pasien',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-modal-search-pasien',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-modal-search-pasien') {
        return onSearchData()
      }
      if (`${ariaLabel}`?.match(/^add-pasien-/i)) {
        const item = data[`${ariaLabel}`?.replace(/^add-pasien-/i, '')]
        return onSelectItem(item, 'pasien', onBackPress)
      }
    } catch (error) {
      if (showlog) {
        console.log('onClickError', error)
      }
    }
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-modal-search-pasien') {
        return setkeyword(value)
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-modal-search-pasien' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const fetchData = (params = {}, isSvisit = false) => {
    seterror(null)
    setparams(params)
    const act = {mSearchPasien, mSearchVisit}
    act[isSvisit ? 'mSearchVisit' : 'mSearchPasien'](params)
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          if (params?.input?.offset === 0) {
            setdata(resdata?.data)
          } else {
            setdata([...data, ...resdata.data])
          }
          if (resdata?.data?.length < limit) {
            setloadmore(false)
          }
        } else {
          if (loadmore) {
            seterror(resdata?.message || 'Gagal mengambil data!')
          }
          setloadmore(false)
        }
        if (showlog) console.log('fetchData:data', data)
      })
      .catch(ch => {
        if (showlog) console.log('fetchData:ch', ch)
        setloadmore(false)
        seterror('[C0] Gagal mengambil data!')
      })
      .finally(() => {
        if (showlog) console.log('fetchData:final')
        setloading(false)
        setonloadmore(false)
      })
  }
  const fetchTabList = () => {
    settablist([])
    mSearchPasienKota({input: {uid: dctid}})
      .then(({data}) => {
        if (data?.status === 1) {
          settablist(data?.data || [])
        }
        if (showlog) console.log('data', data)
      })
      .catch(ch => {
        if (showlog) console.log('ch', ch)
      })
      .finally(() => {
        if (showlog) console.log('final')
      })
  }
  const onChangeTab = (tab, title = '') => {
    console.log('tab, title', tab, title)
    if (showlog) {
      console.log('tab, title', tab, title)
    }
    if (tab === -1) {
      tabsRef.current.setactivetab(-1)
      setsvisit(true)
    } else {
      setsvisit(false)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    fetchData(
      {
        ...params,
        input: {
          ...params.input,
          offset: 0,
          kota: title,
        },
      },
      tab === -1
    )
  }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    fetchData(
      {
        ...params,
        input: {
          ...params.input,
          offset: 0,
          carimobile: keyword,
        },
      },
      svisit
    )
  }
  const onFetchMore = () => {
    if (data.length > 0 && loadmore && !onloadmore) {
      setonloadmore(true)
      return fetchData(
        {
          ...params,
          input: {
            ...params.input,
            offset: params.input.offset + limit,
          },
        },
        svisit
      )
    }
  }
  const onEndReached = el => {
    const isBottom = el?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      onFetchMore()
    }
  }
  const calcTopZero = elm => {
    return between(elm?.getBoundingClientRect()?.top - elm?.offsetTop, -5, 5)
      ? 0
      : 1
  }
  const {pullChange} = usePullToRefresh({
    topTarget: calcTopZero(contentRef.current?.children[1]),
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-modal-search-pasien',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(params)
    fetchTabList()
    return () => {}
  }, [])
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  return (
    <VmView className="flex flex-col absolute inset-0 bg-blue4-payment px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Pembuatan Resep'}
          onBackPress={onBackPress}
          labelBackButton={labelBackButton}
        />
        {/* Searchbar */}
        <Searchbar
          label="modal-search-pasien"
          placeholder="Cari Pasien"
          keyword={keyword}
        />
        {/* Tabs Filter */}
        <Tabs
          ref={ref => (tabsRef.current = ref)}
          prefix={
            <Fragment>
              <VmButton
                key={`im-listTabs-kunjungan`}
                className={classNames(
                  'flex justify-center items-center !flex-none text-sm p-2 px-4 mr-4 !min-w-[50px]',
                  svisit
                    ? '!bg-blue1-payment !text-white'
                    : '!bg-white !text-black2-payment'
                )}
                hidering={true}
                onClick={() => onChangeTab(-1)}
              >
                Kunjungan
              </VmButton>
              <VmView className="border-r-2 border-slate-300 mr-4" />
            </Fragment>
          }
          addtionalList={tablist}
          onChangeTab={onChangeTab}
        />
        {/* Content */}
        <VmView
          ref={contentRef}
          onScroll={onScroll}
          className="block overflow-y-scroll"
        >
          <VmView
            className="refresh-container w-fit -mt-10 m-auto"
            style={{marginTop: pullChange / 3.118 || ''}}
          >
            <VmView className="refresh-icon p-2 rounded-full">
              <VmIcons
                name="VmArrowPathIcon"
                style={{transform: `rotate(${pullChange}deg)`}}
                size={25}
              />
            </VmView>
          </VmView>
          {data.map((im, index) => {
            return (
              <CardItemPasien
                key={`CardItemPasien-${index}`}
                index={index}
                data={im}
              />
            )
          })}
          {!loadmore ? (
            <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
              {error ? error : 'Sudah tidak ada data'}
            </VmView>
          ) : (
            <VmView
              key={'loadmore'}
              label="loadmore"
              className="relative self-center text-center text-gray2-payment last:mb-20 "
            >
              <VmSpinner className="text-blue1-payment" />
            </VmView>
          )}
        </VmView>
        {/* Footer */}
        <Footer
          label="add-pasien"
          labelLoading="add-pasien-loading"
          title="Tambah Pasien"
          backButton
          backHandler={onBackPress}
        />
        {/* Loading Full Screen */}
        <ProgressFullScreen visible={loading} />
      </VmView>
    </VmView>
  )
}
