import {VmModal} from 'components'
import VmCardFilePlaceholder from 'molecules/card/VmCardFilePlaceholder'
import {createRef, forwardRef, useImperativeHandle} from 'react'

var VmModalExport = forwardRef(
  (
    {
      onExportExcel = () => {},
      onExportPdf = () => {},
      pdf = true,
      excel = true,
      customAttr = '',
      ...props
    },
    ref
  ) => {
    const modalExport = createRef()
    const classNameCard = {
      content: 'bg-white flex flex-row rounded-lg p-4 my-2 cursor-pointer',
      title: 'flex flex-col',
    }

    useImperativeHandle(ref, () => ({
      ...modalExport.current,
    }))

    if (!!customAttr)
      return (
        <VmModal
          ref={modalExport}
          classNameContent={
            props?.classNameContent ||
            '!bg-transparent !relative !top-0 !left-0 !px-4'
          }
          classNameBody={props?.classNameBody || ''}
        >
          {customAttr.map((item, i) => (
            <VmCardFilePlaceholder
              key={i}
              className={item?.classNames?.content || classNameCard.content}
              classNameTitle={item?.classNames?.title || classNameCard.title}
              title={item?.title || 'Export ke Excel'}
              desc={
                item?.desc || 'Fungsi untuk mengekspor data dalam bentuk xlsx'
              }
              type={item?.type || 'XLSX'}
              color={item?.color || '#22C55E'}
              IconSize={item?.iconSize || '2rem'}
              IconClassName={item?.IconClassName || 'ml-2 mr-4'}
              onClick={() => onExportExcel(i)}
            />
          ))}
        </VmModal>
      )

    return (
      <VmModal
        ref={modalExport}
        classNameContent="!bg-transparent !relative !top-0 !left-0 !px-4"
      >
        {excel && (
          <VmCardFilePlaceholder
            className={classNameCard.content}
            classNameTitle={classNameCard.title}
            title="Export ke Excel"
            desc="Fungsi untuk mengekspor data dalam bentuk xlsx"
            type="XLSX"
            color="#22C55E"
            IconSize="2rem"
            IconClassName="ml-2 mr-4"
            onClick={onExportExcel}
          />
        )}
        {pdf && (
          <VmCardFilePlaceholder
            className={classNameCard.content}
            classNameTitle={classNameCard.title}
            title="Export ke PDF"
            desc="Fungsi untuk mengekspor data dalam bentuk pdf"
            type="PDF"
            color="#F40F02"
            IconSize="2rem"
            IconClassName="ml-2 mr-4"
            onClick={onExportPdf}
          />
        )}
      </VmModal>
    )
  }
)

export default VmModalExport
