import {Fragment} from 'react'
// eslint-disable-next-line
import {VmView, VmText} from 'components'
import {isObject, toPascalCase} from 'utils'
import classNames from 'classnames'
import InputCustom from './InputCustom'

export const textCase = (d, txt) => {
  if (!!d?.Pascal) {
    if (txt.includes(d.Pascal)) {
      return txt
        .split(d.Pascal)
        .map(nt => toPascalCase(nt))
        .join(' ')
    }
    return toPascalCase(d.Pascal + txt, d.Pascal)
  }
  return txt
}

export const defaultClassNames = [
  /**
   * containerClassName
   */
  // 'm-4 border-2 border-slate-200 rounded-2xl overflow-hidden md:px-[5%] md:py-[2.5%] md:rounded-3xl',
  'm-4 border-2 border-slate-200 rounded-2xl overflow-hidden md:rounded-3xl',
  /**
   * titleClassName
   */
  'p-4 text-left justify-start text-white bg-blue-500 font-bold text-2xl',
  /**
   * classItem
   */
  'flex flex-col md:justify-between ',
  /**
   * classSparator
   */
  'w-full first:border-t-0 border-slate-200 ',
]
// default class name
const [containerClassName, titleClassName] = defaultClassNames

export const classSparator = defaultClassNames[3]
export const Items = (data, index) => {
  const childLength = index?.split('Child-')?.length
  var classItem = defaultClassNames[2]
  classItem +=
    childLength === 2
      ? 'my-2 '
      : childLength === 3
      ? 'ml-4 md:ml-0 my-2 '
      : 'm-4 '
  const subdata = Object.keys(data)
  const isSingle = d => d.match(/^[a-z]{1}$/),
    isCustomView = data?.customView === true

  if (isCustomView) {
    return <Fragment key={`Item-${index}`}>{data.children(index)}</Fragment>
  }
  return (
    <Fragment key={`Item-${index}`}>
      {subdata.map((sm, subindex) => {
        if (sm === 'visible') {
          // Jika properti bernama visible, maka abaikan saja karena tidak ditujukan untuk menampilkan field (untuk state saja)
          return
        }

        const isRow = data[sm].mode == 'row' // Config untuk input yang bisa jejer secara horizontal
        if (isRow) {
          let itemkeys = Object.keys(data[sm].items)
          return (
            <VmView className='flex flex-col md:flex-row md:items-start text-slate-500'>
              {itemkeys.map((item, i) => {
                let inputData = data[sm].items[item]
                return (
                  <InputCustom
                    key={i}
                    data={inputData.inputCustom}
                    title={textCase('Pascal', item)}
                    usetitle={true}
                    label={inputData?.inputCustom?.ariaLabel || sm}
                    prefix={inputData.inputCustom?.prefix}
                    suffix={inputData.inputCustom?.suffix}
                    outerprefix={inputData.inputCustom?.outerprefix}
                    outersuffix={inputData.inputCustom?.outersuffix}
                    propsInput={inputData.inputCustom?.propsInput}
                    classItem={`${classItem} flex-1`}
                    // childLength={childLength}
                  />
                )
              })}
            </VmView>
          )
        }

        const isInputCustom = !!data[sm]?.inputCustom,
          isSingleInputCustom = sm === 'inputCustom',
          isChildren = sm === 'children',
          title = textCase(data[sm][isInputCustom ? 'input' : ''], sm),
          key = `Sub-${index}-${subindex}`
        if (isChildren) {
          return <Fragment key={key}>{data.children()}</Fragment>
        }
        if (!isObject(data[sm])) {
          return <VmView key={key}>{title}</VmView>
        }
        if (isInputCustom || isSingleInputCustom) {
          classItem += 'py-1 text-slate-500 font-normal text-base '
          const inputData = !isSingleInputCustom ? data[sm] : data
          return (
            <InputCustom
              key={key}
              data={inputData.inputCustom}
              title={title}
              usetitle={isInputCustom}
              label={inputData?.inputCustom?.ariaLabel || sm}
              prefix={inputData.inputCustom?.prefix}
              suffix={inputData.inputCustom?.suffix}
              outerprefix={inputData.inputCustom?.outerprefix}
              outersuffix={inputData.inputCustom?.outersuffix}
              propsInput={inputData.inputCustom?.propsInput}
              classItem={inputData.inputCustom?.classItem || classItem}
              childLength={childLength}
            />
          )
        }
        if (isSingle(sm)) {
          return (
            <Fragment key={`${key}-${sm}`}>
              <VmView className={classNames(classItem, 'text-xl')}>
                {Object.keys(data[sm]).map((sim, idxsim) => {
                  const singleTitle = textCase({Pascal: '-'}, sim)
                  return (
                    <Fragment key={`${key}-${idxsim}`}>
                      <VmText className="text-blue-500 font-bold">
                        {singleTitle}
                      </VmText>
                      {Items(data[sm][sim], `Child-${subindex}-${idxsim}`)}
                    </Fragment>
                  )
                })}
              </VmView>
              <VmView className={classNames(classSparator, 'border-t-2')} />
            </Fragment>
          )
        }
        // Default
        return (
          <VmView
            key={key}
            className="py-1 text-slate-600 font-semibold text-lg"
          >
            <VmText className="font-semibold">
              {textCase({Pascal: '-'}, title)}
            </VmText>
            {Object.keys(data[sm]).map((sim, idxsim) => {
              // console.log('sim', title, sim, data[sm][sim])
              if (!isObject(data[sm][sim])) {
                return null
              }
              return Items(
                {[sim]: data[sm][sim]},
                `Child-${key}-${title}-${idxsim}`
              )
            })}
          </VmView>
        )
      })}
    </Fragment>
  )
}
export const ItemCard = ({data, title, index}) => {
  const key = `C-${title}-${index}`
  return (
    <Fragment key={key}>
      <VmView className={classNames(classSparator, '')} />
      <VmView className={data?.containerClassName || containerClassName}>
        <VmText className={data?.titleClassName || titleClassName}>
          {textCase({Pascal: '-'}, title)}
        </VmText>
        {Items(data, key)}
      </VmView>
    </Fragment>
  )
}

export default function HandlerForm({data = {}}) {
  const keys = Object.keys(data)
  return keys.map((title, index) => {
    const key = `C-${title}-${index}`,
      isChildren = title === 'children',
      isCustomView = data[title]?.customView === true

    const isInvisible = data[title]?.visible === false

    if (isInvisible) return null
    if (isCustomView) {
      return <Fragment key={key}>{data[title].children(index)}</Fragment>
    }
    if (isChildren) {
      return <Fragment key={key}>{data.children(index)}</Fragment>
    }
    const childData = data[title]?.title || data[title]
    return <ItemCard data={childData} title={title} key={key} index={index} />
  })
}
