import * as React from 'react'

const VmPinLocationIcon = ({
  className = '',
  titleId = '',
  svgRef,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="currentColor"
    aria-hidden="true"
    {...{className, 'aria-labelledby': titleId, ref: svgRef, ...props}}
  >
    <path d="M7 6.708a1.458 1.458 0 1 1 0-2.916 1.458 1.458 0 0 1 0 2.916Zm0-5.541A4.083 4.083 0 0 0 2.917 5.25C2.917 8.313 7 12.833 7 12.833s4.083-4.52 4.083-7.583A4.083 4.083 0 0 0 7 1.167Z" />
  </svg>
)
export default VmPinLocationIcon
