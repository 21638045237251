import axios from 'axios'
import {APIDOC} from 'config/apiurl'
import QueryString from 'qs'
import {handlerResponse} from 'utils'

const definput = {
  reg: 'dbdok',
  a: 'dk13',
  'show-error-log': 'TRUE',
}

const prdRujukanIndex = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        APIDOC + '/prd-rujukan/index',
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: {},
            error: err,
            message: `[C5] Gagal mengambil data.`,
          })
        )
      })
    let message = '[S1] Gagal mengambil data.'
    if (data?.status === 1) {
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...data,
      })
    )
  })

const prdRujukanCreate = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        APIDOC + '/prd-rujukan/create',
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: {},
            error: err,
            message: `[C5] Gagal menyimpan data!`,
          })
        )
      })
    let message = '[S1] Gagal menyimpan data!'
    if (data?.status === 1) {
      message = 'Berhasil menyimpan data!'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...data,
      })
    )
  })

export {prdRujukanIndex, prdRujukanCreate}
