import {VmButton, VmView} from 'components'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {FullErrorView, ProgressFullScreen, VmHeader} from 'molecules'
import {TemplateListInputItems} from 'template'
import useAddEventListener from 'utils/useAddEventListener'
import {lstinput} from './entries'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import {mSubmitSetting, qIndex} from './api'

export default function MobileSettings() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const uiduser = searchParams.get('dctid')

  const [loading, setloading] = useState(false)
  const [error, seterror] = useState('')
  const [form, setform] = useState('')
  const defaultFormValue = {
    username: '',
    password: '',
  }
  const [defaultForm, setdefaultForm] = useState(defaultFormValue)
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)

  useEffect(() => {
    initDefault()
  }, [])

  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const openAlert = (caption, type, onConfirm = closeAlert) => {
    return setpropsalertform({
      ...propsalertform,
      type,
      visible: true,
      children: <>{caption}</>,
      onConfirm: onConfirm,
      onClose: closeAlert,
      onCancel: closeAlert,
    })
  }

  const initDefault = async () => {
    setloading(true)
    const resIndex = await qIndex({uid: uiduser})

    if (resIndex?.status === 1) {
      const resFormValue = {
        username: resIndex?.data?.sabunpcare || '',
        password: resIndex?.data?.sabpwpcare || '',
      }
      setdefaultForm(resFormValue)
      setform(resFormValue)
    }
    setloading(false)
  }

  const handleGoBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => window.ReactNativeWebView.postMessage('onBackPress:'))
    } else {
      navigate(-1)
    }
  }

  const onClick = async e => {
    const {ariaLabel} = e?.target
    if (ariaLabel === 'submitSetting') {
      if (!form?.username || !form?.password)
        return openAlert('Semua kolom harus diisi!', 2)

      setloading(true)
      const resSubmit = await mSubmitSetting({
        username: form?.username,
        password: form?.password,
        uid: uiduser,
      })
      setloading(false)
      if (resSubmit?.status == 1)
        return openAlert(resSubmit?.message, 4, initDefault)

      return openAlert(resSubmit?.message, 3, initDefault)
    }
  }

  const onChange = e => {
    const {ariaLabel, value} = e?.target
    const whiteListForm = ['username', 'password']
    if (whiteListForm.includes(ariaLabel))
      setform(s => ({...s, [ariaLabel]: value}))
  }

  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})

  if (!!error)
    return (
      <VmView className="inset-0 bg-white p-4">
        <VmHeader
          aria-label="header-error-search-obat-penolakan"
          HeaderMode="!text-gray-700"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType="Laporan Penolakan Obat"
          onBackPress={handleGoBack}
        />
        <FullErrorView message={error} itemClassName="m-4" />
      </VmView>
    )

  return (
    <>
      <ProgressFullScreen visible={loading} />
      <VmView className="fixed inset-0 bg-blue4-payment">
        <VmView className="flex flex-col p-4">
          <VmHeader
            className="mb-5"
            HeaderMode="!text-black2-payment"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType={'Pengaturan PCare'}
            onBackPress={handleGoBack}
          />
          <VmView className="w-full bg-white rounded-xl p-4">
            <VmView className="text-xs font-bold text-black2-payment">
              Pengaturan
            </VmView>
            <TemplateListInputItems
              data={defaultForm}
              lstinput={lstinput}
              bgcolors={'bg-[#F8FAFB]'}
              colors={'text-black2-payment'}
            />
          </VmView>
        </VmView>
        <VmButton
          className={'fixed bottom-4 right-4 px-6 py-3 !rounded-full'}
          label="submitSetting"
        >
          Simpan
        </VmButton>
      </VmView>
      <AlertForm {...{...propsalertform, noText: false, yesText: 'Ok'}} />
    </>
  )
}
