import classNames from 'classnames'
import {VmButton, VmIcons, VmView} from 'components'
import {FaTag} from 'react-icons/fa'

export default function CardItemRacikan({
  data: {name = '', code = '', kategori = '', flagcart = ''},
  label = '',
  index = 0,
}) {
  return (
    <VmView className="relative !text-black2-payment bg-gray7-payment mb-4 last:mb-20 rounded-xl rounded-t-2xl">
      <VmView className="flex bg-white px-4 py-3 rounded-xl">
        {/* Head */}
        <VmView className="block mr-auto max-w-[80%]">
          {/* Name */}
          <VmView className="pb-2 font-semibold !text-break-word">
            {name}
          </VmView>
          {/* Kode */}
          <VmView className="text-sm !text-break-word">{code}</VmView>
          {/* Kategori */}
          <VmView className="flex flex-row items-center text-sm !text-break-word">
            <FaTag className="mr-1 !text-gray2-payment" size={12} />
            {kategori}
          </VmView>
        </VmView>
        <VmView className="flex items-end">
          <VmButton
            className={classNames(
              'flex justify-center items-center !flex-none text-sm p-1 px-4 !min-w-[4rem]',
              '!bg-blue1-payment !text-white first:mr-3 last:!mr-0'
            )}
            hidering={true}
            label={`add-racikan${label}-${index}`}
          >
            Pilih
          </VmButton>
          {!!flagcart && (
            <VmView
              label={`delete-racikan${label}-##-${index}-##-${flagcart}`}
              className="flex p-[0.3rem] justify-center bg-red5-payment items-center rounded-md w-6 h-6"
            >
              <VmIcons
                label={`delete-racikan${label}-##-${index}-##-${flagcart}`}
                style={{strokeWidth: '4px'}}
                className="text-white w-full h-full"
                name="VmDeleteBinIcon"
                variant="outline"
              />
            </VmView>
          )}
        </VmView>
      </VmView>
    </VmView>
  )
}
