import {VmKeyIcon, VmUserIcon, VmUserGroupIcon} from 'components/icons/outline'

export const lstinput = [
  {
    type: 1,
    title: 'Username',
    Icon: VmUserIcon,
    label: 'username',
    field: 'username',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    required: true,
  },
  {
    type: 1,
    title: 'Password',
    Icon: VmKeyIcon,
    label: 'password',
    field: 'password',
    typeInput: 'text',
    maxLength: 255,
    usebtnremove: true,
    required: true,
  },
]
