import {VmButton, VmModal, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {BottomSheet} from 'react-spring-bottom-sheet'
import useAddEventListener from 'utils/useAddEventListener'
import {defaultpropsbottomsheet} from 'entries/bottomsheet'
import classNames from 'classnames'
import {eventTrigerInput, isFunction} from 'utils'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import {useParams} from 'react-router-dom'
import {conditionlist, etiketlist, inputEditObat} from './entries'
import {TemplateListInputItems} from 'template'
import ListConditions from './ListConditions'
import {useCarts} from './utils'
import {getElement} from 'utils/get-element'

export default function DetailObat({
  labelBackButton = 'modal-back-button',
  idx = '',
  datadoc = {},
  optcarts = {},
  fieldkey = ['obatid', 'sodid'],
  ...props
}) {
  const defaultetiket = {
    conditions: props?.data?.conditions || 0,
    etiket: props?.data?.etiket || 0,
  }
  const dataetiket = {
      conditions: conditionlist[defaultetiket.conditions].type,
      conditionsTypeTitle: conditionlist[defaultetiket.conditions].title,
      // etiket: etiketlist[defaultetiket.etiket].type,
      // etiketTypeTitle: etiketlist[defaultetiket.etiket].title,
      'condition-type': 'Makan',
    },
    showlog = true
  const modalRef = useRef(),
    bottomSheetRef = useRef()
  const dctid = parseInt(useParams().id)
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState(Object.assign(props?.data || {}, dataetiket))
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )
  const lstinput = useMemo(() => {
    return inputEditObat.lstinput.map(im => {
      if (im?.input1?.label === 'input-qty') {
        const equal = `input-qty-##-${data?.index}-##-${idx}`
        im.input1.label = equal
      }
      return im
    })
  }, [inputEditObat, data, idx])
  const {edititemcarts} = useCarts(optcarts)
  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const closeBs = () => setpropsbottomsheet(defaultpropsbottomsheet)
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (['input-etiket', 'picker-input-etiket'].includes(ariaLabel)) {
        // Open Bs etiket type
        // return setpropsbottomsheet({
        //   ...propsbottomsheet,
        //   open: true,
        //   onClick: closeBs,
        //   children: (
        //     <ListEtiket
        //       data={data}
        //       setdata={setdata}
        //       list={etiketlist}
        //       closeBs={closeBs}
        //     />
        //   ),
        // })
      }
      if (['input-conditions', 'picker-input-conditions'].includes(ariaLabel)) {
        // Open Bs conditions type
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          onClick: closeBs,
          children: (
            <ListConditions
              data={data}
              setdata={setdata}
              list={conditionlist}
              closeBs={closeBs}
            />
          ),
        })
      }
      if (['input-satuan', 'picker-input-satuan'].includes(ariaLabel)) {
        const equal = `showsatuan-k-##-${data?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'div',
          by: 'ariaLabel',
          equal,
        })
        if (isFunction(finsInputEl?.click)) finsInputEl.click()
        return
      }
    } catch (error) {}
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (`${ariaLabel}`?.match(/^input-qty-modal-obat-##/i)) {
        const equal = `input-qty-##-${data?.index}-##-${idx}`
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal,
        })
        return Promise.all([eventTrigerInput(finsInputEl, value)]).finally(
          () => {
            var newtimeout = null
            newtimeout = setTimeout(() => {
              e.target.value = finsInputEl.value
              clearTimeout(newtimeout)
            }, 100)
          }
        )
      }
    } catch (error) {}
  }
  const onBlur = e => {
    try {
      const {ariaLabel, value} = e?.target
      if (`${ariaLabel}`?.match(/^input-/i)) {
        var field = `${ariaLabel}`?.replace(/^input-/i, '')
        setdata(prevData => ({...prevData, [field]: value}))
      }
    } catch (error) {}
  }
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'focusout', handler: onBlur})
  const onBackPress = () => {
    if (isFunction(props?.close)) {
      props?.close()
    }
  }
  useEffect(() => {
    // On Change data
    edititemcarts(data, 'flagcart')
    return () => {}
  }, [data])

  return (
    <VmView className="flex flex-col absolute inset-0 bg-blue4-payment px-5 pt-5">
      {/* Headers */}
      <VmHeader
        className="mb-5"
        HeaderMode="!text-black2-payment"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType={'Pembuatan Resep'}
        onBackPress={onBackPress}
        labelBackButton={labelBackButton}
      />
      <VmView className="flex flex-col h-[-webkit-fill-available] overflow-y-scroll">
        <VmView className="relative bg-white rounded-2xl py-4 px-2 mb-36">
          <TemplateListInputItems
            colors="text-gray5-payment"
            lstinput={lstinput}
            data={data}
          />
        </VmView>
      </VmView>
      {/* Footer */}
      <VmView
        className={classNames(
          'flex flex-row-reverse absolute bottom-0 left-0 right-0 bg-blue4-payment px-5 pb-5 pt-4'
        )}
      >
        <VmButton
          className={classNames(
            'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[45%] !bg-red1-payment !text-white'
          )}
          hidering={true}
          onClick={onBackPress}
        >
          Tutup
        </VmButton>
      </VmView>
      {/* Bottom Sheet */}
      <BottomSheet ref={bottomSheetRef} {...propsbottomsheet} />
      {/* Modal */}
      <VmModal ref={modalRef} />
      {/* AlertForm */}
      <AlertForm {...propsalertform} />
      {/* Loading Full Screen */}
      <ProgressFullScreen visible={loading} />
    </VmView>
  )
}
