import axios from 'axios'
import {VmButton, VmIcons, VmInput, VmView} from 'components'
import {VmVmedisIcon} from 'components/icons/abstract'
import {VmImagePlusIcon, VmPencilIcon} from 'components/icons/solid'
import {APIDOC} from 'config/apiurl'
import {ProgressFullScreen, VmHeader} from 'molecules'
import QueryString from 'qs'
import React, {useEffect, useRef, useState} from 'react'
import vmedisicon from 'assets/vmedisicon.png'
import HStack from 'components/HStack'
import moment from 'moment'
import {useParams} from 'react-router-dom'
import {
  catatLog,
  catatLogDokter,
  empty,
  encodeImageFileAsURL,
  ifempty,
  isValidUrl,
} from 'utils/functions'
import classNames from 'classnames'
import AlertOk from 'components/AlertOk'
import trans_bg from 'assets/trans_bg.png'

export default function PengaturanResep() {
  let alertok = useRef()
  let params = useParams()
  let dctid = params.id

  const [oldstg, setoldStg] = useState({
    stgttddokter: 0,
    stgstempeldokter: 0,
  })
  const [stg, setStg] = useState({
    stgttddokter: 0,
    stgstempeldokter: 0,
    user_ttd: '',
    user_stempel: '',
  })
  const [img_user_ttd, setImg_user_ttd] = useState('')
  const [img_user_stempel, setImg_user_stempel] = useState('')

  const [user, setUser] = useState({
    user_id: 0,

    user_id: 0,
    kl_id: 0,
    app_id: 'dk13',
    username: '',
    nama_lengkap: 'dr. Akbar Umar',
    email: '',
    user_nosip: 'SKDF92928323/SADFASFD923 898',
    user_nosip_en: '',
    user_noktp: '',
    user_noktp_en: '',
    alamat: '',
    provinsi: '277',
    kota: '2439',
    kecamatan: '34055',
    keterangan: '',
    user_nostr_en: '',
    user_wa: '085606021473',
    kecnama: '',
    kotanama: '',
    ket_suspend: 'THT',
  })
  const [loading, setLoading] = useState(true)

  /**
   * index = atur pengaturan resep
   * preview = preview cetak faktur
   */
  const [mode, setMode] = useState('index')

  useEffect(() => {
    // get data
    getStg()
    getIdentitas()

    window.addEventListener('message', e => handleMessageRn(e))
    return () => {
      window.removeEventListener('message', e => handleMessageRn(e))
    }
  }, [])

  const handleMessageRn = e => {
    var actialfield = '',
      newvalue = '',
      onerror = false,
      newdata = {}

    console.log('gambar base64 gan', e.data)
    try {
      if (`${e?.data}`?.match(/^onChangeImage:/i)) {
        newdata = JSON.parse(e.data.replace(/^onChangeImage:/i, ''))
        actialfield = newdata.field
        newvalue = newdata.value

        switch (actialfield) {
          case 'user_ttd':
            setImg_user_ttd(newvalue)
            break

          case 'user_stempel':
            setImg_user_stempel(newvalue)
            break
        }
        console.log('newdata', newdata)
        // set
        // setdata({
        //   ...data,
        //   [actialfield === 'logo'
        //     ? 'logotoupload'
        //     : `${actialfield}-to-upload`]: newvalue,
        // })
        // return onChangeData({
        //   datetime: Date.now(),
        //   [actialfield]: `${
        //     data?.username ? data?.username.substring(0, 10) : ''
        //   }_${actialfield.substring(
        //     actialfield.length - 3,
        //     actialfield.length
        //   )}_${Date.now()}.jpg`,
        // })
      }
    } catch (error) {
      if (e?.data?.match(/^onChangeImage:/i)) {
        return
      }
    }
  }

  const getIdentitas = () => {
    setLoading(true)
    axios
      .post(
        `${APIDOC}/ap-user-dokter/get-profil-by-id`,
        QueryString.stringify({
          uid: dctid,
          a: 'dk13',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        if (res.status == 1) {
          setUser(res.data)
        }
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat get Data Identitas')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getStg = () => {
    axios
      .post(
        `${APIDOC}/prd-pengaturan-resep/get-stg`,
        QueryString.stringify({
          uid: dctid,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        setStg({
          ...res.data,
          stgstempeldokter: empty(res.data.stgstempeldokter) ? false : true,
          stgttddokter: empty(res.data.stgttddokter) ? false : true,
        })
        setoldStg({
          // Simpan data initial Untuk ngelog v2
          stgstempeldokter: empty(res.data.stgstempeldokter) ? false : true,
          stgttddokter: empty(res.data.stgttddokter) ? false : true,
        })

        setImg_user_stempel(res.data.user_stempel)
        setImg_user_ttd(res.data.user_ttd)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Data Setting')
      })
  }

  const openModalImage = newlabel => {
    if (!!window?.ReactNativeWebView?.postMessage) {
      // const newlabel = `${emId.ariaLabel}`.replace('input-', '')
      window.ReactNativeWebView.postMessage(`onModalImagePicker:${newlabel}`)
      return
    } else {
      document.getElementById(newlabel)?.click()
    }
  }

  const renderStg = () => {
    return (
      <>
        <VmView className="flex items-center">
          <VmView className="flex-1 flex flex-col mr-2">
            <h6 className="text-xs text-center font-bold">
              Tanda Tangan Dokter
            </h6>
            <VmView
              onClick={e => {
                console.log('onclick item gambar gan', e)
                openModalImage('user_ttd')
              }}
              style={{backgroundImage: `url(${img_user_ttd})`}}
              className={classNames(
                'flex h-36 bg-gray-200 rounded-lg mt-4 bg-no-repeat bg-cover overflow-hidden',
                img_user_ttd
                  ? 'justify-end items-start'
                  : 'justify-center items-center'
              )}
            >
              {!img_user_ttd ? (
                <VmImagePlusIcon className="w-8 text-blue-500 bg-white p-2 rounded-lg" />
              ) : (
                <VmPencilIcon
                  onClick={e => {
                    e.stopPropagation()
                    console.log('onedit', e)
                    openModalImage('user_ttd')
                  }}
                  className="w-8 primary p-2 rounded-bl-lg"
                />
              )}
              <input
                id="user_ttd"
                type="file"
                hidden
                onChange={async e => {
                  // console.log('user_ttd', e)
                  setImg_user_ttd(`${await encodeImageFileAsURL(e.target)}`)
                }}
              />
            </VmView>
          </VmView>

          <VmView className="flex-1 flex flex-col ml-2">
            <h6 className="text-xs text-center font-bold">Stempel Dokter</h6>
            <VmView
              onClick={e => {
                console.log('onclick item gambar gan', e)
                openModalImage('user_stempel')
              }}
              style={{backgroundImage: `url(${img_user_stempel})`}}
              className={classNames(
                'flex h-36 bg-gray-200 rounded-lg mt-4 bg-no-repeat bg-cover overflow-hidden',
                img_user_stempel
                  ? 'justify-end items-start'
                  : 'justify-center items-center'
              )}
            >
              {!img_user_stempel ? (
                <VmImagePlusIcon className="w-8 text-blue-500 bg-white p-2 rounded-lg" />
              ) : (
                <VmPencilIcon
                  onClick={e => {
                    e.stopPropagation()
                    console.log('onedit', e)
                    openModalImage('user_stempel')
                  }}
                  className="w-8 primary p-2 rounded-bl-lg"
                />
              )}
              <input
                id="user_stempel"
                type="file"
                hidden
                onChange={async e => {
                  // console.log('user_stempel', e)
                  setImg_user_stempel(`${await encodeImageFileAsURL(e.target)}`)
                }}
              />
            </VmView>
          </VmView>
        </VmView>

        <p className="text-sm my-4">
          Tampilkan Tanda Tangan Dokter pada Cetak Resep?
        </p>

        <VmView>
          <VmInput
            typeInput="toggle"
            onChange={e => {
              let val = e.target.checked
              setStg(prev => ({...prev, stgttddokter: val}))
              // console.log('Toggle TTD Cetak Resep', val)
            }}
            checked={stg.stgttddokter}
          />
        </VmView>

        <p className="text-sm my-4">
          Tampilkan Stempel Dokter pada Cetak Resep?
        </p>

        <VmView>
          <VmInput
            typeInput="toggle"
            onChange={e => {
              let val = e.target.checked
              setStg(prev => ({...prev, stgstempeldokter: val}))
              // console.log('Toggle Stempel Dokter', val)
            }}
            checked={stg.stgstempeldokter}
          />
        </VmView>

        <VmView className="flex justify-center mt-16 mb-20">
          <VmButton
            onClick={e => {
              setMode(prev => {
                if (prev == 'index') {
                  return 'preview'
                } else {
                  return 'index'
                }
              })
            }}
            className={'text-xs py-4 px-8 bg-[#269400] text-white'}
            rounded="full"
            color=""
          >
            {mode == 'index' ? 'Lihat Tampilan Resep' : 'Kembali'}
          </VmButton>
        </VmView>
      </>
    )
  }

  const renderPreview = () => {
    let nosip_split = user.user_nosip.match(/.{1,8}/g) ?? []
    let nosip_join = nosip_split.join(' ')
    return (
      <>
        <VmView className="flex justify-center">
          <img src={vmedisicon} className="w-24" alt="vmedisIcon" />
        </VmView>
        <p className="text-xs text-center font-bold mb-8">Resep Digital</p>

        <p className="text-sxs font-bold">No. Resep</p>
        <HStack justify="between" className={''}>
          <p className="text-sxs text-center">PJR0012311010001</p>
          <p className="text-sxs text-center">
            {moment().format('DD MMM YYYY')}
          </p>
        </HStack>

        <HStack
          justify="between"
          align="start"
          className={'border-y border-black py-4 my-4'}
        >
          <VmView className={'flex-1'}>
            <HStack align="start">
              <p className="flex-[0.4] text-sxs font-bold">Dokter</p>
              <p className="flex-[0.6] text-sxs ">{user.nama_lengkap}</p>
            </HStack>
            <HStack align="start">
              <p className="flex-[0.4] text-sxs font-bold">SIP</p>
              <p className="flex-[0.6] text-sxs ">{nosip_join}</p>
            </HStack>
            <HStack align="start">
              <p className="flex-[0.4] text-sxs font-bold">Spesialis</p>
              <p className="flex-[0.6] text-sxs ">{user.ket_suspend}</p>
            </HStack>
          </VmView>

          <VmView className={'flex-1'}>
            <HStack align="start">
              <p className="flex-[0.4] text-sxs font-bold">Pasien</p>
              <p className="flex-[0.6] text-sxs ">Muji Santoso</p>
            </HStack>
            <HStack align="start">
              <p className="flex-[0.4] text-sxs font-bold">Jenis Kelamin</p>
              <p className="flex-[0.6] text-sxs ">Pria</p>
            </HStack>
            <HStack align="start">
              <p className="flex-[0.4] text-sxs font-bold">Usia</p>
              <p className="flex-[0.6] text-sxs ">30 Tahun</p>
            </HStack>
          </VmView>
        </HStack>

        <HStack className={'mb-4'}>
          <p className="flex-[0.4] text-sxs font-bold">Obat</p>
          <p className="flex-[0.4] text-sxs font-bold mx-2">Aturan Pakai</p>
          <p className="flex-[0.2] text-sxs font-bold">Jumlah</p>
        </HStack>

        <HStack align="start" className={'mb-4'}>
          <p className="flex-[0.4] text-sxs font-bold">
            R/ Tremenza Sirup 60ml
          </p>
          <VmView className="flex-[0.4] mx-2">
            <p className=" text-sxs font-bold">3 x 2 cth Sesudah Makan </p>
            <p className=" text-sxs">Jika perlu durasi pengobatan 3 hari</p>
          </VmView>
          <p className="flex-[0.2] text-sxs ">1 BTL</p>
        </HStack>

        <HStack align="start">
          <p className="flex-[0.4] text-sxs font-bold">
            R/ Tremenza Sirup 60ml
          </p>
          <VmView className="flex-[0.4] mx-2">
            <p className=" text-sxs font-bold">3 x 2 cth Sesudah Makan </p>
            <p className=" text-sxs">Jika perlu durasi pengobatan 3 hari</p>
          </VmView>
          <p className="flex-[0.2] text-sxs ">1 BTL</p>
        </HStack>

        <VmView className="relative flex justify-end mt-4">
          {(() => {
            if (stg.stgttddokter && stg.stgstempeldokter) {
              return (
                <>
                  <img
                    className="absolute right-0 bottom-0 w-40 z-10 object-cover"
                    src={img_user_ttd}
                    alt="images"
                  />
                  <img
                    className="right-0 top-0 w-40 opacity-25 object-cover"
                    src={ifempty(img_user_stempel, trans_bg)}
                    alt="images"
                  />
                </>
              )
            } else if (stg.stgttddokter) {
              return (
                <img
                  className="right-0 top-0 w-40 object-cover"
                  src={ifempty(img_user_ttd, trans_bg)}
                  alt="images"
                />
              )
            } else if (stg.stgstempeldokter) {
              return (
                <img
                  className="right-0 top-0 w-40 opacity-25 object-cover"
                  src={ifempty(img_user_stempel, trans_bg)}
                  alt="images"
                />
              )
            }
          })()}
        </VmView>
        <p className="text-right text-xs">{user.nama_lengkap}</p>

        <VmView className="flex justify-center mt-8 mb-20">
          <VmButton
            onClick={e => {
              setMode('index')
            }}
            className={'text-xs py-4 px-8 bg-[#269400] text-white'}
            rounded="full"
            color=""
          >
            {'Kembali'}
          </VmButton>
        </VmView>
      </>
    )
  }

  const simpan = () => {
    setLoading(true)

    let datafoto = {}

    // Kalau dia URL gambar S3, maka tidak usah dipassing untuk Upload.
    if (!isValidUrl(img_user_ttd)) {
      datafoto.user_ttd = img_user_ttd.replace(/^data:image\/\w+;base64,/, '')
    }
    if (!isValidUrl(img_user_stempel)) {
      datafoto.user_stempel = img_user_stempel.replace(
        /^data:image\/\w+;base64,/,
        ''
      )
    }
    axios
      .post(
        `${APIDOC}/prd-pengaturan-resep/update-stg`,
        QueryString.stringify({
          uid: dctid,
          stgttddokter: stg.stgttddokter ? 1 : 0,
          stgstempeldokter: stg.stgstempeldokter ? 1 : 0,
          ...datafoto,
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        alertok.current.show({
          title: 'Berhasil',
          message: 'Berhasil Simpan Pengaturan Resep',
          type: 'success',
        })

        // Catat Log V2
        let dataLog = {
          keterangan: `Mengubah Pengaturan Cetak Resep`,
          menu: 'Resep Dokter Online - Pengaturan Cetak Resep',
          mn_id: 33,
          url: 'prd-pengaturan-cetak-resep',
          before: JSON.stringify({
            'Tanda Tangan Dokter': oldstg.stgttddokter
              ? 'Aktif'
              : 'Tidak Aktif',
            'Stempel Dokter': oldstg.stgstempeldokter ? 'Aktif' : 'Tidak Aktif',
          }),
          after: JSON.stringify({
            'Tanda Tangan Dokter': stg.stgttddokter ? 'Aktif' : 'Tidak Aktif',
            'Stempel Dokter': stg.stgstempeldokter ? 'Aktif' : 'Tidak Aktif',
          }),
          kl_id: 19,
          // ip: publicIp,
          a: 'dk13',
          uid: dctid,
          reg: 'db',
        }
        catatLog(dataLog)

        // Data initial di re-assign ke value yang baru
        setoldStg(stg)
      })
      .catch(e => {
        alertok.current.show({
          title: 'Gagal',
          message: (
            <>
              Terjadi Kesalahan saat Update Pengaturan Resep.
              <br />
              Silakan coba beberapa saat lagi.
            </>
          ),
          type: 'warning',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="">
      {loading ? <ProgressFullScreen visible /> : null}
      <AlertOk ref={alertok} />
      {/* Background Color */}
      <VmView className="fixed inset-0 bg-blue4-payment -z-10"></VmView>

      {/* Header Part */}
      <VmHeader
        HeaderMode="!text-black2-payment"
        HeaderName={'Menu'}
        HeaderButtonVersion={0}
        HeaderType={'Pengaturan Cetak Resep'}
        className="fixed px-4 py-4 left-0 right-0 top-0 bg-blue4-payment"
        classNameTitle="!pl-4"
      />

      <VmView className="rounded-lg bg-white mx-4 p-4">
        {mode == 'index' ? renderStg() : renderPreview()}
      </VmView>

      <VmView className="fixed flex justify-end bottom-0 left-0 right-0 bg-blue4-payment p-4">
        <VmButton
          onClick={simpan}
          className={'text-xs py-4 px-8'}
          rounded="full"
        >
          Simpan
        </VmButton>
      </VmView>
    </div>
  )
}
