import {VmIcons, VmSpinner, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {Fragment, useEffect, useRef, useState} from 'react'
import Searchbar from './Searchbar'
import CardItemRacikan from './CardItemRacikan'
import Footer from './Footer'
import {eventTrigerInput, isFunction} from 'utils'
import useAddEventListener from 'utils/useAddEventListener'
import {getElement} from 'utils/get-element'
import usePullToRefresh from 'utils/usePullToRefresh'
import {mDetailRacikan, mSearchRacikan, mStokObat} from './api'
import {useParams} from 'react-router-dom'
import {DataMitraApotek, DataObat} from './classes'
import {generateFlags, useCarts} from './utils'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import between from 'utils/between'

export default function SearchRacikan({
  labelBackButton = 'modal-back-button',
  datamitra = new DataMitraApotek({}),
  optcarts = {name: 'carts', flags: 'flagcart'},
  fieldkey = ['obatid', 'sodid'],
  ...props
}) {
  const showlog = !false,
    contentRef = useRef(),
    limit = 10
  const dctid = parseInt(useParams().id)
  const [offset, setoffset] = useState(0)
  const [keyword, setkeyword] = useState('')
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  const [loadmore, setloadmore] = useState(true)
  const [onloadmore, setonloadmore] = useState(true)
  const [data, setdata] = useState([])
  const [params, setparams] = useState({
    log: showlog,
    input: {
      id: dctid,
      limit,
      offset,
      a: datamitra?.defaultdata?.appid_apotek || '',
      reg: datamitra?.defaultdata?.regid_apotek,
      carimobile: keyword,
      'show-error-log': `${showlog}`.toUpperCase(),
    },
  })
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const {
    cart,
    carts,
    cartstoks,
    addtocarts,
    edititemcarts,
    removetocarts,
    setcarts,
  } = useCarts(optcarts)
  const onBackPress = () => {
    if (isFunction(props?.close)) {
      props?.close()
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel)
      }
      if (ariaLabel === 'reset-modal-search-racikan') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-modal-search-racikan',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-modal-search-racikan',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-modal-search-racikan') {
        return onSearchData()
      }
      if (`${ariaLabel}`?.match(/^add-racikan-modal-/i)) {
        const idx = `${ariaLabel}`?.replace(/^add-racikan-modal-/i, '')
        const item = new DataObat({
          ...data[idx]?.defaultdata,
          sodid: 'racikan',
          obatjenis: 'RACIKAN',
        })
        if (cartstoks[item?.defaultdata?.obatid]) {
          return setpropsalertform({
            ...propsalertform,
            type: 4,
            visible: true,
            children: <>Item racikan sudah ditambahkan.</>,
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        }
        const idxrck =
          (carts.filter(fl => fl?.jenis === 'RACIKAN')?.length || 0) + 1
        const dataaptkl = {
          a: datamitra?.defaultdata?.appid_apotek,
          reg: datamitra?.defaultdata?.regid_apotek,
        }
        setloading(true)
        return mDetailRacikan({
          input: {
            id: item?.defaultdata?.obatid,
            ...dataaptkl,
          },
        })
          .then(async ({data: resdata = {}}) => {
            if (resdata?.status === 1) {
              const itemscheckstok = resdata.data.map(im => {
                if (im?.jenis === 'JASA') {
                  return {
                    name: im?.name || '',
                    data: im,
                    status: 1,
                  }
                }
                return mStokObat({
                  input: {
                    obatid: im?.obatid,
                    gudid: datamitra?.defaultdata?.spdgudid,
                    ...dataaptkl,
                  },
                  data: im?.defaultdata || {},
                  name: im?.name || '',
                })
              })
              await Promise.all(itemscheckstok)
                .then(th => {
                  const fnOutOfStok = th.filter(fn => fn?.status === 0)
                  const dataobatnonjasa = th.filter(fn =>
                    ['NON KONSINYASI', 'KONSINYASI'].includes(fn?.data?.jenis)
                  )
                  const maxstokracikan = dataobatnonjasa.reduce((a, b) => {
                    const konversi = b?.data?.sodhasilkonversi || 1
                    const loweststok = parseFloat(b?.data?.stok / konversi)
                    const prevtstok = parseFloat(a)
                    if (prevtstok === 0) {
                      return loweststok
                    }
                    return loweststok < prevtstok && loweststok > 0
                      ? loweststok
                      : prevtstok
                  }, 0)
                  if (fnOutOfStok.length > 0) {
                    var listobat = null
                    if (fnOutOfStok.length > 1) {
                      listobat = fnOutOfStok.map((im, index) => (
                        <Fragment key={`${im}-${index}`}>
                          <br />
                          &nbsp;&nbsp;- {im.name}
                        </Fragment>
                      ))
                    } else {
                      listobat = (
                        <>
                          <br />
                          &nbsp;&nbsp;- {fnOutOfStok[0].name}
                        </>
                      )
                    }
                    return setpropsalertform({
                      ...propsalertform,
                      type: 4,
                      visible: true,
                      children: (
                        <>
                          Ada Stok Obat yang Tidak mencukupi!<b>{listobat}</b>
                        </>
                      ),
                      onConfirm: closeAlert,
                      onClose: closeAlert,
                      onCancel: closeAlert,
                    })
                  } else {
                    addtocarts({
                      ...item,
                      qty: 1,
                      stok: maxstokracikan,
                      flagcart: generateFlags(item?.defaultdata, fieldkey),
                      flax: `racikan${idxrck}`,
                      flaxR: `racikan${idxrck}`,
                    })
                    for (let i = 0; i < th.length; i++) {
                      const chldrck = th[i].data
                      addtocarts({
                        ...chldrck,
                        flagcart: generateFlags(chldrck?.defaultdata, fieldkey),
                        flax: `racikan${idxrck}`,
                        flaxR: `racikan${idxrck}`,
                      })
                    }
                    onBackPress()
                  }
                  if (showlog) console.log('itemscheckstok:th', fnOutOfStok, th)
                })
                .catch(ch => {
                  if (showlog) console.log('itemscheckstok:ch', ch)
                })
                .finally(fnl => {
                  if (showlog) console.log('itemscheckstok:fnl', fnl)
                })
            } else {
              return setpropsalertform({
                ...propsalertform,
                type: 4,
                visible: true,
                children: <>Gagal mengambil item racikan!</>,
                onConfirm: closeAlert,
                onClose: closeAlert,
                onCancel: closeAlert,
              })
            }
            if (showlog) console.log('fetchData:data', resdata)
          })
          .catch(ch => {
            if (showlog) console.log('fetchData:ch', ch)
          })
          .finally(() => {
            if (showlog) console.log('fetchData:final')
            setloading(false)
          })
      }
    } catch (error) {
      if (showlog) {
        console.log('onClickError', error)
      }
    }
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-modal-search-racikan') {
        return setkeyword(value)
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-modal-search-racikan' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const fetchData = (params = {}) => {
    seterror(null)
    setparams(params)
    mSearchRacikan(params)
      .then(({data: resdata = {}}) => {
        if (resdata?.status === 1) {
          if (params?.input?.offset === 0) {
            setdata(resdata?.data)
          } else {
            setdata([...data, ...resdata.data])
          }
          if (resdata?.data?.length < limit) {
            setloadmore(false)
          }
        } else {
          if (loadmore) {
            seterror(resdata?.message || 'Gagal mengambil data!')
          }
          setloadmore(false)
        }
        if (showlog) console.log('fetchData:data', data)
      })
      .catch(ch => {
        if (showlog) console.log('fetchData:ch', ch)
        setloadmore(false)
        seterror('[C0] Gagal mengambil data!')
      })
      .finally(() => {
        if (showlog) console.log('fetchData:final')
        setloading(false)
        setonloadmore(false)
      })
  }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        carimobile: keyword,
      },
    })
  }
  const onFetchMore = () => {
    if (data.length > 0 && loadmore && !onloadmore) {
      setonloadmore(true)
      return fetchData({
        ...params,
        input: {
          ...params.input,
          offset: params.input.offset + limit,
        },
      })
    }
  }
  const onEndReached = el => {
    const isBottom = el?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      onFetchMore()
    }
  }
  const calcTopZero = elm => {
    return between(elm?.getBoundingClientRect()?.top - elm?.offsetTop, -5, 5)
      ? 0
      : 1
  }
  const {pullChange} = usePullToRefresh({
    topTarget: calcTopZero(contentRef.current?.children[1]),
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-modal-search-racikan',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(params)
    return () => {}
  }, [])
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  return (
    <VmView className="flex flex-col absolute inset-0 bg-blue4-payment px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType={'Pembuatan Resep'}
          onBackPress={onBackPress}
          labelBackButton={labelBackButton}
        />
        {/* Searchbar */}
        <Searchbar
          label="modal-search-racikan"
          placeholder="Cari Racikan"
          keyword={keyword}
        />
        {/* Content */}
        <VmView
          ref={contentRef}
          onScroll={onScroll}
          className="block overflow-y-scroll"
        >
          <VmView
            className="refresh-container w-fit -mt-10 m-auto"
            style={{marginTop: pullChange / 3.118 || ''}}
          >
            <VmView className="refresh-icon p-2 rounded-full">
              <VmIcons
                name="VmArrowPathIcon"
                style={{transform: `rotate(${pullChange}deg)`}}
                size={25}
              />
            </VmView>
          </VmView>
          {data.map((im, index) => {
            return (
              <CardItemRacikan
                key={`CardItemRacikan-${index}`}
                index={index}
                data={im}
                label="-modal"
              />
            )
          })}
          {!loadmore ? (
            <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
              {error ? error : 'Sudah tidak ada data'}
            </VmView>
          ) : (
            <VmView
              key={'loadmore'}
              label="loadmore"
              className="relative self-center text-center text-gray2-payment last:mb-20 "
            >
              <VmSpinner className="text-blue1-payment" />
            </VmView>
          )}
        </VmView>
        {/* Footer */}
        <Footer
          title="Tambah Racikan"
          label="add-new-racikan"
          backButton
          backHandler={onBackPress}
        />
        {/* AlertForm */}
        <AlertForm {...propsalertform} />
        {/* Loading Full Screen */}
        <ProgressFullScreen visible={loading} />
      </VmView>
    </VmView>
  )
}
