import {VmText, VmView, VmButton} from 'components'
import {useNavigate} from 'react-router-dom'
import {VmHeader} from 'molecules'
import classNames from 'classnames'
import {TemplateListInputItems} from 'template'
import moment from 'moment'
import {lstmenu,lstmenuweb,lstmenuweb2} from './entries'

const hidering = '!ring-0 !ring-transparent !ring-offset-0'
const isRn = !!window?.ReactNativeWebView
const PrdRujukanCreate = ({
  data = {},
  onBackPress = () => {},
  HeaderName = 'Menu',
  HeaderType = 'Rujukan Eksternal',
  FormName = 'Buat Rujukan Eksternal',
}) => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate(-1)
  }
  return (
    <VmView className="bg-white">
      <VmView className="flex flex-col p-4 overflow-y-scroll">
        {/* Title */}
        <VmView className="py-3 px-2">
          <VmText className="text-slate-700 font-bold text-center">{FormName}</VmText>
        </VmView>
        {/* Content */}
        <VmView className="w-full p-4">
          {/* Patient Informations */}
          <PatientInfo data={data} />
          {/* Forms */}
          <Forms data={data} />
        </VmView>
        {/* Footer */}
        <VmView className="flex flex-row justify-between items-center px-4 py-4">
          {/* Left */}
          <VmView className="flex flex-row gap-4 px-2">
            <VmButton
              label="SimpanRujukan"
              rounded="2xl"
              className={classNames(
                hidering,
                'shadow-lg max-h-20 bg-gradient-to-r from-green-600 to-green-500 py-3 px-10 text-sm text-white font-semibold block'
              )}
            >
              Simpan
            </VmButton>
          </VmView>
          {/* Right */}
          <VmView className="flex flex-row gap-4 px-2">
            <VmButton
              label="batalSetting"
              className={
                'font-semibold text-sm px-6 py-3 min-w-[8rem] border-2'
              }
              rounded="2xl"
              color="danger"
              variant="outline"
              onClick={handleGoBack}
            >
              Batal
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
    </VmView>
  )
}
const PatientInfo = ({data = {}}) => {
  const lstinfo = [
    {
      title: 'No. RM',
      field: 'norm',
    },
    {
      title: 'Tanggal Lahir',
      field: 'born',
    },
    {
      title: 'Nama Lengkap',
      field: 'patname',
    },
    {
      title: 'Jenis Kelamin',
      field: 'sex',
    },
  ]
  return (
    <VmView className="grid grid-cols-2 gap-4 p-2">
      {lstinfo.map(({title, field}, index) => {
        const key = `pi-${index}`
        return (
          <VmView key={key} className="text-sm">
            <VmText className="text-slate-500 font-bold">{title}</VmText>
            <VmText className="text-slate-700">{data[field] || '-'}</VmText>
          </VmView>
        )
      })}
    </VmView>
  )
}
const Forms = ({data = {}}) => {
  <VmView className="grid grid-cols-2 gap-4 p-2">
    {lstmenu.map(({title, field}, index) => {
      const key = `pi-${index}`
      return (
        <VmView key={key} className="text-sm">
          <VmText className="text-slate-500 font-bold">{title}</VmText>
          <VmText className="text-slate-700">{data[field] || '-'}</VmText>
        </VmView>
      )
    })}
  </VmView>
  // font-bold !text-slate-400 cursor-default flex flex-nowrap flex-col
  return (
    <>
      <TemplateListInputItems
        colors="text-slate-500 !text-sm"
        bgcolors="!bg-gray0-payment"
        data={data}
        lstinput={lstmenuweb}
        className='grid grid-cols-2 mt-6'
        key={`lst-inpt-prd-rujukan`}
      />
      <TemplateListInputItems
        colors="text-slate-500 !text-sm"
        bgcolors="!bg-gray0-payment"
        data={data}
        lstinput={lstmenuweb2}
        // className='grid grid-cols-2 mt-6'
        key={`lst-inpt-prd-rujukan2`}
      />
    </>
  )
}

export default PrdRujukanCreate
