import axios from 'axios'
import classNames from 'classnames'
import {VmButton, VmInput, VmPicker, VmText, VmView} from 'components'
import {VmUserIcon} from 'components/icons/solid'
import ModalPembayaran from 'components/reusable_modal/ModalPembayaran'
import ModalPilihanPaket from 'components/reusable_modal/ModalPilihanPaket'
import VmInputCaption from 'components/VmInputCaption'
import {ProgressFullScreen, VmHeader} from 'molecules'
import QueryString from 'qs'
import React, {useRef} from 'react'
import {useEffect} from 'react'
import {useState} from 'react'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {empty, toCurrency} from 'utils/functions'
import {iconMap} from 'components/icons/IconMap'
import {hidering} from 'template/PrdStrukSetting'
import {renderField} from 'template/PrdUbahProfil/Index'
import {
  VmArchiveBoxIcon,
  VmClockIcon,
  VmReceiptPercentIcon,
  VmTagIcon,
} from 'components/icons/outline'
import Metode from 'components/reusable_modal/Metode'
import {IdentityField} from './Perpanjangan'
import AlertOk from 'components/AlertOk'

const {
  REACT_APP_BASE_URL_DOKTER: BASE_URL,
  REACT_APP_BASE_URL_UTAMA: BASE_URL_UTAMA,
} = process.env
const vaMap = {
  mandiri: {
    nama: 'MANDIRI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka aplikasi Mandiri Online dan login menggunakan USERNAME dan PASSWORD Anda',
      'Pilih "Bayar"',
      'Pilih "Multipayment"',
      'Pilih Xendit 88608 atau Xendit 88908 sebagai penyedia jasa, sesuai dengan 5 digit awal dari Nomor Virtual Account yang tampil',
      'Masukkan Nomor Virtual Account yang tampil',
      'Tekan Lanjut',
      'Tinjau dan konfirmasi detail transaksi anda, lalu tekan Konfirmasi',
      'Selesaikan transaksi dengan memasukkan MPIN Anda',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  bri: {
    nama: 'BRI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka aplikasi BRI Mobile Banking dan login menggunakan USER ID dan PIN Anda',
      'Pilih "Pembayaran" dan pilih "Briva"',
      'Masukkan Nomor Virtual Account yang tampil',
      'Masukkan PIN Mobile Banking BRI',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  bni: {
    nama: 'BNI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password Anda',
      'Pilih Menu "Transfer"',
      'Pilih Menu "Virtual Account Billing" kemudian pilih rekening debet',
      'Masukkan Nomor Virtual Account yang tampil',
      'Konfirmasi transaksi Anda',
      'Masukkan Password Transaksi',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  permata: {
    nama: 'PERMATA VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka Aplikasi PermataMobile Internet dan login menggunakan User ID dan Password Anda',
      'Pilih "Pembayaran Tagihan"',
      'Pilih "Virtual Account"',
      'Masukkan Nomor Virtual Account yang tampil',
      'Masukkan otentikasi transaksi/token',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  qris: {
    nama: 'Generate QRIS',
    langkah: [
      'Pastikan kamu memiliki aplikasi pembayaran bank atau e-wallet yang mendukung QRIS.',
      'Setelah klik “Bayar”, kamu akan menerima QR code.',
    ],
  },
  bca: {
    nama: 'BCA VIRTUAL ACCOUNT',
    langkah: [],
  },
}

export default function MetodePembayaran(props) {
  const [listPaket, setListPaket] = useState([])
  const [selectedMethod, setSelectedMethod] = useState('')
  const [selectedPaket, setSelectedPaket] = useState({})
  const [identitas, setIdentitas] = useState({})
  const [loading, setLoading] = useState(false)

  let [searchParams, setSearchParams] = useSearchParams()
  const [uid, setUid] = useState(searchParams.get('dctid'))
  const [pengaturanPembayaran, setPengaturanPembayaran] = useState([])
  const alert = useRef(null)

  const navigate = useNavigate()
  const {state: data} = useLocation()
  useEffect(() => {
    // Simpan data state dari location ke Local State
    setSelectedPaket(data)

    // Get List Paket Registrasi Dokter
    axios
      .post(
        `${BASE_URL}/backend-data-registrasi/paket-registrasi-dokter`,
        QueryString.stringify({})
      )
      .then(({data}) => {
        console.log('List Paket Dokter', data)
        setListPaket(data)
      })

    // Get Data User
    axios
      .post(
        `${BASE_URL}/ap-user-dokter/view?id=${uid}`,
        QueryString.stringify({
          a: 'dk13',
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        let identitas = res.data
        setIdentitas(identitas)
        console.log('data user', identitas)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get data user')
      })

    axios
      .post(
        `${BASE_URL_UTAMA}/ap-back-pengaturan-vmedis/get-setting-pembayaran-online`,
        QueryString.stringify({})
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        setPengaturanPembayaran(res.data)
      })
      .catch(e => {
        console.log('Terjadi Kesalahan saat Get Pengaturan Pembayaran Online')
      })
  }, [])

  // const {selectedPaket = {}} = state

  // let currentAktifPaket = listPaket.find(
  //   pkt => pkt.pktid == dataRegistrasi.reg_version
  // )

  const upgradePerpanjanganPaket = () => {
    let action =
      selectedPaket.mode == 'upgrade' ? 'upgrade-paket' : 'perpanjangan-paket'

    // console.log({action}); return
    setLoading(true)
    axios
      .post(
        `${BASE_URL}/backend-data-registrasi/${action}`,
        QueryString.stringify({
          uid,
          pktid: selectedPaket.pktid,
          bank_code: selectedMethod.toUpperCase(),
        })
      )
      .then(({data: res}) => {
        // Berhasil Get Data
        setLoading(false)
        if (res.status == 0) {
          alert.current.show({
            message: res.errorMessage,
          })
          return
        }

        navigate(`/site/bayarperpanjangan?dctid=${uid}`)
      })
      .catch(e => {
        setLoading(false)
        console.log(
          'Terjadi Kesalahan saat Upgrade Paket gan.',
          e.response?.data ?? e.message
        )
      })
  }

  const perpanjangan = () => {}

  console.log({selectedPaket})
  let hargaFinal = (
    selectedPaket?.pktHarga - selectedPaket?.pktDiskon
  ).toString()

  let mandiriAktif = pengaturanPembayaran[2]?.stgaktif == 1,
    bcaAktif = pengaturanPembayaran[1]?.stgaktif == 1,
    permataAktif = pengaturanPembayaran[5]?.stgaktif == 1,
    briAktif = pengaturanPembayaran[3]?.stgaktif == 1,
    bniAktif = pengaturanPembayaran[4]?.stgaktif == 1,
    qrisAktif = pengaturanPembayaran[11]?.stgaktif == 1

  return (
    <div
      className="px-4 pt-20 pb-24"
      style={
        {
          // backgroundColor: 'lightgray',
          // backgroundImage:
          //   "url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!-- Generator: Gravit.io --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='isolation:isolate' viewBox='0 0 360 538' width='360pt' height='538pt'%3E%3Cdefs%3E%3CclipPath id='_clipPath_HOQln3xPEuJQi1BVRsYHuJRL7LCJW0r6'%3E%3Crect width='360' height='538'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23_clipPath_HOQln3xPEuJQi1BVRsYHuJRL7LCJW0r6)'%3E%3ClinearGradient id='_lgradient_0' x1='0' y1='0.5' x2='1' y2='0.5' gradientTransform='matrix(360 0 0 180 0 0)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0%25' stop-opacity='1' style='stop-color:rgb(3 131 204)'/%3E%3Cstop offset='98.33333333333333%25' stop-opacity='1' style='stop-color:rgb(41 167 240)'/%3E%3C/linearGradient%3E%3Crect x='0' y='0' width='360' height='180' transform='matrix(1 0 0 1 0 0)' fill='url(%23_lgradient_0)'/%3E%3Cdefs%3E%3Cfilter id='sHJ0knPpD2PQfwQGcM1DmUlYeQQastcw' x='-200%25' y='-200%25' width='400%25' height='400%25' filterUnits='objectBoundingBox' color-interpolation-filters='sRGB'%3E%3CfeGaussianBlur xmlns='http://www.w3.org/2000/svg' stdDeviation='0'/%3E%3C/filter%3E%3C/defs%3E%3Cg filter='url(%23sHJ0knPpD2PQfwQGcM1DmUlYeQQastcw)'%3E%3Cpath d=' M 0 149 L 360 96 C 360 96 360 96 360 96 L 360 185 C 360 185 360 185 360 185 L 0 185 C 0 185 0 185 0 185 L 0 96 C 0 96 0 149 0 149 Z ' fill='rgb(7 131 202)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E\")",
          // backgroundAttachment: 'fixed',
          // backgroundRepeat: 'no-repeat',
        }
      }
    >
      <AlertOk ref={alert} />
      <ProgressFullScreen visible={loading} />
      <VmHeader
        HeaderMode="bg-blue-gradient"
        HeaderName={'Menu'}
        HeaderType={'Pilih Metode Pembayaran'}
        className="fixed bg-white px-4 py-4 z-10 top-0 left-0 right-0"
        classNameTitle="!pl-4"
      />

      {(() => {
        if (selectedMethod == '') {
          return (
            <>
              <p className="text-sm font-bold text-gray-500">Bank Transfer</p>
              <ul className="my-4 space-y-3">
                {/* {bcaAktif ? (
                  <Metode
                    imgUrl="https://register.vmedis.com/foto-bayar/bca.png"
                    name={'BCA Virtual Account'}
                    onClick={_ => {
                      setSelectedMethod('bca')
                    }}
                  />
                ) : null} */}
                {mandiriAktif ? (
                  <Metode
                    imgUrl="https://register.vmedis.com/foto-bayar/mandiri.png"
                    name={'Mandiri Virtual Account'}
                    onClick={_ => {
                      setSelectedMethod('mandiri')
                    }}
                  />
                ) : null}
                {briAktif ? (
                  <Metode
                    imgUrl="https://register.vmedis.com/foto-bayar/bri.png"
                    name={'BRI Virtual Account'}
                    onClick={_ => {
                      setSelectedMethod('bri')
                    }}
                  />
                ) : null}
                {bniAktif ? (
                  <Metode
                    imgUrl="https://register.vmedis.com/foto-bayar/bni.png"
                    name={'BNI Virtual Account'}
                    onClick={_ => {
                      setSelectedMethod('bni')
                    }}
                  />
                ) : null}
                {permataAktif ? (
                  <Metode
                    imgUrl="https://register.vmedis.com/foto-bayar/permata.png"
                    name={'Permata Virtual Account'}
                    onClick={_ => {
                      setSelectedMethod('permata')
                    }}
                  />
                ) : null}
                {/* <Metode imgUrl="" name={'Mandiri Virtual Account'} /> */}
              </ul>

              {qrisAktif ? (
                <>
                  <p className="text-sm font-bold text-gray-500">
                    Metode Lainnya
                  </p>
                  <ul className="my-4 space-y-3">
                    <Metode
                      imgUrl="https://register.vmedis.com/foto-bayar/qris.png"
                      name={'QRIS'}
                      onClick={_ => {
                        setSelectedMethod('qris')
                      }}
                    />
                  </ul>
                </>
              ) : null}
            </>
          )
        } else {
          let selectedMethodMap = vaMap[selectedMethod]
          return (
            <>
              <div id="pembayaran_all">
                {/* Card Informasi Dokter */}
                <div id="card-info" className="p-4 shadow-lg rounded-xl">
                  <h3 className="text-center text-primary  font-bold mb-4">
                    Data Registrasi
                  </h3>

                  <table
                    className=""
                    border="0"
                    width="100%"
                    cellSpacing="0"
                    cellPadding="0"
                  >
                    <tbody>
                      <tr className="">
                        <td
                          className="w-1/2 pb-2"
                          style={{
                            fontSize: '100%',
                          }}
                        >
                          {' '}
                          <IdentityField
                            icon="user-outline"
                            fieldName="Nama Dokter"
                            value={identitas?.nama_lengkap}
                          />
                        </td>
                        <td
                          className="w-1/2 pb-2"
                          style={{
                            fontSize: '100%',
                          }}
                        >
                          <IdentityField
                            icon="archive-outline"
                            fieldName="Paket Yang Dipilih"
                            value={selectedPaket?.pktNama}
                          />
                        </td>
                      </tr>

                      {/* 2nd Row */}
                      <tr className="">
                        <td className="w-1/2 pb-2" style={{fontSize: '100%'}}>
                          <IdentityField
                            icon="credit-card-outline"
                            fieldName="No. STR"
                            value={identitas?.nostr ?? '-'}
                          />
                        </td>
                        <td className="w-1/2 pb-2" style={{fontSize: '100%'}}>
                          <IdentityField
                            icon="calendar-outline"
                            fieldName="Harga Paket"
                            value={toCurrency(hargaFinal)}
                          />
                        </td>
                      </tr>

                      {/* 3rd Row */}
                      <tr className="">
                        <td className="w-1/2 pb-2" style={{fontSize: '100%'}}>
                          <IdentityField
                            icon="calendar-outline"
                            fieldName="Spesialisasi"
                            value={identitas?.ket_suspend}
                          />
                        </td>
                        <td className="w-1/2 pb-2" style={{fontSize: '100%'}}>
                          <IdentityField
                            icon="user-outline"
                            fieldName="Durasi (Bulan)"
                            value={selectedPaket?.prd_durasi}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  id="pem_manual"
                  data-pjax-container=""
                  data-pjax-timeout="1000"
                  className="mt-4 bg-white p-8 rounded-xl shadow-lg"
                >
                  <h3 className="text-center text-primary  font-bold">
                    Langkah-langkah Pembayaran
                  </h3>

                  {/* Step Langkah-langkah  */}
                  <ul
                    style={{
                      // marginLeft: '-25px',
                      listStyleType: 'lower-number',
                    }}
                  >
                    {selectedMethodMap.langkah.map((item, i) => {
                      return (
                        <li className="text-sm text-zinc-600" key={i}>
                          {item}
                        </li>
                      )
                    })}
                  </ul>
                </div>

                {/* Footer Section */}
                <div className="gg mt-6">
                  <VmButton
                    variant=""
                    color="danger"
                    className="p-2 px-4"
                    onClick={() => {
                      setSelectedMethod('')
                    }}
                  >
                    Kembali
                  </VmButton>
                </div>
              </div>

              {/* Fixed Footer */}
              <div
                id="footer"
                className="fixed flex bottom-0 right-0 left-0 bg-white shadow p-4"
              >
                <div className="flex-1">
                  <VmText className="text-sm text-primary font-bold">
                    Total
                  </VmText>
                  <VmText className="text-danger font-bold">
                    {toCurrency(hargaFinal)}
                  </VmText>
                </div>

                <VmButton
                  label="SimpanSetting"
                  className={classNames(
                    hidering,
                    // 'w-[-webkit-fill-available]',
                    'shadow-lg max-h-20 bg-gradient-to-r from-orange-400 to-yellow-300 py-3',
                    'rounded-lg text-sm text-white bottom-[30px] left-[30px] right-[30px]',
                    'flex-1'
                  )}
                  onClick={_ => {
                    upgradePerpanjanganPaket()
                    // setMPembayaranVisible(true)
                    // navigate('/perpanjangan/metode', {
                    //   state: selectedPaket,
                    // })
                  }}
                >
                  Bayar
                </VmButton>
              </div>
            </>
          )
        }
      })()}
    </div>
  )
}
