import React, {useState} from 'react'
import {VmButton, VmDataTable, VmView} from 'components'

export default function PatExamContents() {
  const usersData = [
    {
      id: 0,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 2,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 3,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 4,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 5,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 6,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 7,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 8,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 9,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 10,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 11,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 12,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 13,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
    {
      id: 14,
      date: '2018/01/01',
      kun_name: 'John Doe',
      pat_rm: 'RM2018',
      pat_name: 'RM2018',
      pol_name: 'RM2018',
      doc_name: 'RM2018',
      status: 1,
    },
  ]
  const [details, setDetails] = useState([])
  const toggleDetails = index => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }
  const fields = [
    {
      key: 'no',
      label: 'No',
      _style: {width: '1%'},
      sorter: false,
      filter: false,
    },
    {
      key: 'action',
      label: 'Action',
      _style: {width: '1%'},
      sorter: false,
      filter: false,
    },
    {
      key: 'date',
      label: 'Tanggal',
      _style: {width: '1%'},
      sorter: false,
      filter: false,
    },
    {key: 'kun_name', _style: {width: '40%'}},
    {key: 'pat_rm', _style: {width: '40%'}},
    {key: 'pat_name', _style: {width: '40%'}},
    {key: 'pol_name', _style: {width: '40%'}},
    {key: 'doc_name', _style: {width: '40%'}},
    {key: 'status', _style: {width: '20%'}},
  ]

  return (
    <VmView className="p-5">
      <VmDataTable
        items={usersData}
        fields={fields}
        columnFilter
        tableFilter
        // itemsPerPageSelect
        itemsPerPage={5}
        hover
        sorter
        pagination={{dots: true, limit: 5}}
        scopedSlots={{
          no: (item, index) => <td>{index + 1}</td>,
          action: (item, index) => {
            return (
              <td className="py-2">
                <VmButton
                  color="primary"
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    toggleDetails(index)
                  }}
                >
                  {details.includes(index) ? 'Hide' : 'Show'}
                </VmButton>
              </td>
            )
          },
          details: (item, index) => {
            if (details.includes(index)) {
              return (
                <VmButton onClick={() => alert(JSON.stringify({item, index}))}>
                  {JSON.stringify({item, index})}
                </VmButton>
              )
            } else {
              return <></>
            }
          },
        }}
      />
    </VmView>
  )
}
