import axios from 'axios'
import QueryString from 'qs'
import {handlerResponse} from 'utils'
import {
  DataMitraApotek,
  DataObat,
  DataObatRacikan,
  DataObatResep,
  DataPasien,
  DataResep,
  DataVisit,
} from './classes'
import moment from 'moment'
import {UserController} from 'controllers'
import {generateFlags} from './utils'
import {reportDateFormat} from 'config/dateFormat'
import {tglYMD, tglYMDHMS} from 'utils/functions'

const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  REACT_APP_BASE_URL_UTAMA: API5,
} = process.env

const definput = {
  reg: 'dbdok',
  a: 'dk13',
}
const mSearchVisit = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-pasien/search-visit`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      newdata['data'] = data.data.map(im => new DataVisit(im))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mDetailVisit = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-pasien/detail-visit`,
        QueryString.stringify({
          ...definput,
          ...input,
          tgl: tglYMDHMS(),
        })
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data kunjungan.`,
          })
        )
      })
    var newdata = data
    if (data?.status === 1) {
      newdata['data'] = new DataVisit(newdata['data'])
    }
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data kunjungan.`,
        ...newdata,
      })
    )
  })
// ======================= Dokter =================================
const mIdentitasDokter = async arg => {
  // return {
  //   status: 1,
  //   data: {
  //     status: 10,
  //     name: 'Sutopo purnomo',
  //     spesialis: 'Poli umum',
  //     address: 'Jl. Mawar',
  //     province: '277',
  //     city: '2461',
  //     subdistrict: '34371',
  //   },
  //   message: 'success',
  // }
  return UserController(
    {
      id: arg?.input?.id,
      key: 'index',
      dispatch: () => {},
      _ldata: 'mUserInfo',
      field: [
        'status',
        'name',
        'spesialis',
        'address',
        'province',
        'city',
        'subdistrict',
      ],
    },
    () => {}
  )
}
// ======================= Pasien =================================
const mSearchPasien = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-pasien/index`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil pasien.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      newdata['data'] = data.data.map(im => new DataPasien(im))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mGetPasien = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-pasien/view`,
        QueryString.stringify({
          ...definput,
          ...input,
          tgl: tglYMDHMS(),
        })
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data pasien.`,
          })
        )
      })
    var newdata = data
    if (data?.status === 1) {
      newdata['data'] = new DataPasien(newdata['data'])
    }
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data pasien.`,
        ...newdata,
      })
    )
  })
const mAddPasien = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-pasien/create`,
        QueryString.stringify({
          ...definput,
          ...input,
          tgl: tglYMDHMS(),
        })
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal menambahkan pasien.`,
          })
        )
      })
    var newdata = data
    resolve(
      handlerResponse(200, {
        message: `Berhasil menambahkan pasien.`,
        ...newdata,
      })
    )
  })
const mGetRmPasien = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-pasien/get-rm`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `[RM0] Gagal menambahkan pasien.`,
          })
        )
      })
    var newdata = data
    resolve(
      handlerResponse(200, {
        message: `[RM0] Berhasil menambahkan pasien.`,
        ...newdata,
      })
    )
  })
const mSearchPasienKota = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-pasien/get-kota`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      newdata['data'] = data.data.map(im => ({title: im?.paskota}))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
// ======================= Mitra =================================
const mSearchMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/index`,
        QueryString.stringify({...definput, ...input, reg_dokter: 'dbdok'})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil mitra.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      newdata['data'] = data.data.map(im => new DataMitraApotek(im))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mSearchMitraKota = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-mitra-dokter/get-kota`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.status === 1 && data?.data?.length > 0) {
      newdata['data'] = data.data.map(im => ({title: im?.kota || '-'}))
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mMitraGetGudangNama = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-penjualan-resep/get-gudang`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...newdata,
      })
    )
  })
// ======================= Obat =================================
const mSearchObat = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/index-transaksi-v3`,
        QueryString.stringify({...definput, ...input, sodurut: '1'})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.length > 0) {
      newdata['status'] = 1
      newdata['data'] = data.map(im => new DataObat(im))
      message = 'Berhasil mengambil data.'
    } else {
      newdata['status'] = 0
      newdata['data'] = data
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mSatuanObat = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/satuanview?id=${input?.id}`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        data,
      })
    )
  })
const mStokObat = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/pjstok-v3`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    if (data?.length > 0) {
      newdata = {
        data: new DataObat({...arg.data, ...data[0]}),
        status: parseInt(data[0]?.stok) > 0 ? 1 : 0,
      }
    } else {
      newdata = {data: {}, status: 0}
    }
    resolve({
      name: arg?.name || '',
      ...newdata,
    })
  })
// ======================= Racikan =================================
const mCreateRacikan = async args => {
  try {
    const {data} = await axios.post(
      APIDOC + '/apracikan/create',
      QueryString.stringify(args)
    )

    if (data.status != 1) return handlerResponse(500, {})
    return handlerResponse(200, {data})
  } catch (e) {
    return handlerResponse(500, {})
  }
}
const qObatKode = async args => {
  try {
    const {data} = await axios.post(
      APIDOC + '/apracikan/getobatkode',
      QueryString.stringify(args)
    )

    if (data['stack-trace']) return handlerResponse(500, {})
    return handlerResponse(200, {data})
  } catch (e) {
    return handlerResponse(500, {})
  }
}
const qCariKategori = async args => {
  try {
    const {data} = await axios.post(
      API5 + '/apkategori/index',
      QueryString.stringify(args)
    )

    if (data['stack-trace']) return handlerResponse(500, {})

    return handlerResponse(200, {data: data.data})
  } catch (e) {
    return handlerResponse(500, {})
  }
}
const mSearchRacikan = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/obatracikan`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    var message = 'Tidak ada yang ditampilkan.'
    if (data?.length > 0) {
      newdata['data'] = data.map(im => new DataObatRacikan(im))
      newdata['status'] = 1
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
const mDetailRacikan = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/detailracikan?id=${input?.id}`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    let message = 'Tidak ada yang ditampilkan.'
    if (data?.length > 0) {
      newdata['data'] = data.map(
        im => new DataObat({...im, qty: parseInt(im?.orjumlah)})
      )
      newdata['status'] = 1
      message = 'Berhasil mengambil data.'
    }
    resolve(
      handlerResponse(200, {
        message,
        ...newdata,
      })
    )
  })
// ======================= Resep =================================
const mSearchResepMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-penjualan-resep/resep-mitra`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    if (data?.status === 1) {
      newdata['data'] = data.data.map(im => new DataResep(im))
    }
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...newdata,
      })
    )
  })
const mDetailResepMitra = arg =>
  new Promise(async (resolve, reject) => {
    const {input, datamitra = {}, fieldkey = []} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/pilihpending`,
        QueryString.stringify({...definput, ...input})
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = {}
    if (data?.length > 0) {
      newdata['data'] = data.map(async im => {
        const dataaptkl = {
          a: datamitra?.appid_apotek,
          reg: datamitra?.regid_apotek,
        }
        const {data: datastok} = await mStokObat({
          input: {
            obatid: im?.obatid,
            gudid: datamitra?.spdgudid,
            ...dataaptkl,
          },
          data: im || {},
          name: im?.obatnama || '',
        })
        const newim = new DataObatResep({
          ...im,
          qty: im?.pjdjumlah ? parseInt(im?.pjdjumlah) : 0,
          stok: datastok?.defaultdata?.stok,
          flagcart: generateFlags(im, fieldkey),
        })
        return newim
      })
      await Promise.all(newdata['data'])
        .then(th => {
          var newth = []
          var newthracikan = {}
          for (let i = 0; i < th.length; i++) {
            const im = th[i]
            newth.push(im)
            if (im?.jenis === 'RACIKAN') {
              newthracikan[im?.flaxR] = {...im, index: i, stok: 0}
            }
            if (
              ['NON KONSINYASI', 'KONSINYASI'].includes(im?.jenis) &&
              `${im?.flaxR}`.toLowerCase().includes('racikan')
            ) {
              const konversi = im?.defaultdata?.sodhasilkonversi
                ? parseFloat(im?.defaultdata?.sodhasilkonversi)
                : 1
              const loweststok = parseFloat(im?.defaultdata?.stok / konversi)
              const prevtstok = parseFloat(
                newthracikan[im?.flaxR]['stok'] || '0'
              )
              if (prevtstok === 0) {
                newthracikan[im?.flaxR]['stok'] = loweststok
                newth[newthracikan[im?.flaxR]['index']]['stok'] = loweststok
                newth[newthracikan[im?.flaxR]['index']]['defaultdata']['stok'] =
                  loweststok
              } else {
                const newloweststok =
                  loweststok < prevtstok && loweststok > 0
                    ? loweststok
                    : prevtstok
                newthracikan[im?.flaxR]['stok'] = newloweststok
                newth[newthracikan[im?.flaxR]['index']]['stok'] = newloweststok
                newth[newthracikan[im?.flaxR]['index']]['defaultdata']['stok'] =
                  newloweststok
              }
            }
          }
          resolve(
            handlerResponse(200, {
              message: `Berhasil mengambil data.`,
              data: newth,
              status: 1,
            })
          )
        })
        .catch(ch => {
          if (arg?.log) {
            console.log('err', ch)
          }
          handlerResponse(200, {
            message: `[C00] Gagal mengambil data.`,
            status: 0,
          })
        })
    } else {
      resolve(
        handlerResponse(200, {
          message: `Gagal mengambil data.`,
          status: 0,
        })
      )
    }
  })
// ======================= Transactions =================================
const mGetFaktur = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${API7}/prd-penjualan-resep/get-faktur`,
        QueryString.stringify({
          ...definput,
          ...input,
          tgl: moment().format('YYYY-MM-DD'),
        })
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...newdata,
      })
    )
  })
const mCreateNewResep = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/pendingdata`,
        QueryString.stringify({
          ...definput,
          ...input,
          'save-log': 1,
        })
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal menyimpan data.`,
          })
        )
      })
    var newdata = data
    resolve(
      handlerResponse(200, {
        message: `Berhasil menyimpan data.`,
        ...newdata,
      })
    )
  })
const mCetakBarcode = arg =>
  new Promise(async (resolve, reject) => {
    const {input} = arg
    const {data} = await axios
      .post(
        `${APIDOC}/prd-penjualan-obat-resep/getdataqr`,
        QueryString.stringify({
          ...definput,
          ...input,
        })
      )
      .catch(err => {
        if (arg?.log) {
          console.log('err', err)
        }
        reject(
          handlerResponse(500, {
            data: [],
            error: err,
            message: `Gagal mengambil data.`,
          })
        )
      })
    var newdata = data
    resolve(
      handlerResponse(200, {
        message: `Berhasil mengambil data.`,
        ...newdata,
      })
    )
  })

export {
  mIdentitasDokter,
  mSearchVisit,
  mDetailVisit,
  mSearchPasien,
  mGetPasien,
  mAddPasien,
  mGetRmPasien,
  mSearchMitra,
  mSearchPasienKota,
  mSearchMitraKota,
  mGetFaktur,
  mSearchObat,
  mSearchRacikan,
  mSearchResepMitra,
  mMitraGetGudangNama,
  mSatuanObat,
  qCariKategori,
  mCreateNewResep,
  mCetakBarcode,
  mDetailResepMitra,
  mDetailRacikan,
  mStokObat,
  qObatKode,
  mCreateRacikan,
}
