import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmInput} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import {empty, toCurrency} from 'utils/functions'
import AlertOk from 'components/AlertOk'
import AlertYesNo from 'components/AlertYesNo'
import {isEmpty} from '@aws-amplify/core'
import axios from 'axios'
// import {BASE_URL} from './PrdDiagnosa'
import QueryString from 'qs'
import {Navigate, useSearchParams} from 'react-router-dom'
import {API, graphqlOperation} from 'aws-amplify'
import {mInsertDiagnosa} from 'graphql/mutations'
const {REACT_APP_BASE_URL_DOKTER: BASE_URL} = process.env

export default function PrdDiagnosaTambah(props) {
  // const [bianama, setBianama] = useState('')
  // const [biatarif, setBiatarif] = useState('')
  // const [biadiskon, setBiadiskon] = useState('')
  // const [biaasisten, setBiaasisten] = useState('')
  // const [biadokter, setBiadokter] = useState('')
  // const [biaper1, setBiaper1] = useState('')
  // const [biaper2, setBiaper2] = useState('')
  const [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [uid, setUid] = useState(searchParams.get('dctid'))
  const [device, setDevice] = useState('')

  const detectDevice = () => {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    setDevice(isMobile ? 'mobile' : 'web')
  }

  useEffect(() => {
    detectDevice()
    return () => {}
  }, [])

  const alert = useRef(null)
  const prompt = useRef(null)

  let simpan = data => {
    let {icdnama, icdkode, icdket} = data

    icdnama = icdnama.trim()
    icdkode = icdkode.trim()
    icdket = icdket.trim()

    API.graphql(
      graphqlOperation(mInsertDiagnosa, {
        input: {
          req: JSON.stringify({
            reg: 'dbdok',
            a: 'dk13',
            data: {
              icdnama,
              icdkode,
              icdket,
            },
            uid,
          }),
        },
      })
    )
      .then(res => {
        let {body} = res.data.mInsertDiagnosa
        body = JSON.parse(body)
        if (body?.status == 0) {
          alert.current.show({
            message: body?.message,
          })
          setSaving(false)
          return
        }
        console.log('logsuccess', res)
        setRedirect(true)
        // this.getInitialData()
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Biaya Pemeriksaan',
          })
          console.log('Error menambahkan Diagnosa', errMsg)
        }
      })
  }

  return (
    <>
      <AlertOk ref={alert} />
      <AlertYesNo ref={prompt} />

      {redirect ? (
        <Navigate to={`/prd-mas-data-diagnosa?dctid=${uid}`} replace={true} />
      ) : null}

      <VmForm
        headerTitle={'Tambah Data Diagnosa'}
        onFabPress={() => {}}
        contentContainerClassName="px-4"
        footerOptions={{
          visible: true,
          btnSimpanTitle: 'Simpan',
          btnSimpanDisabled: saving ? true : false,
          btnBatalDisabled: saving ? true : false,
          btnBatalTitle: 'Batal',
          onCancel: () => {
            setRedirect(true)
          },
        }}
        fields={[
          {
            key: 'icdkode',
            caption: 'Kode',
            type: 'input',
            variant: 'text',
            _style: {fontWeight: 'bold'},
            className: '',
          },
          {
            key: 'icdnama',
            caption: 'Nama',
            type: 'input',
            variant: 'text',
            min: 0,
            defaultValue: '',
          },
          {
            key: 'icdket',
            caption: 'Keterangan',
            type: 'input',
            variant: 'textarea',
            min: 0,
            defaultValue: '',
          },
        ]}
        onSimpan={data => {
          // do Something
          const {icdnama, icdkode, icdket} = data
          // console.log({
          //   bianama,
          //   biatarif,
          //   biadiskon,
          // })
          // return
          // Cek Data Kosong
          if (!icdnama.length) {
            alert.current.show({message: 'Nama Diagnosa harus diisi'})
            return
          } else if (!icdkode.length) {
            alert.current.show({message: 'Kode Diagnosa harus diisi'})
            return
          }

          setSaving(true)
          simpan(data)
          // Insert Data ke Database
        }}
        device={device}
      ></VmForm>

      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
