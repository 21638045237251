import React, {useEffect, useState} from 'react'

import {VmButton, VmIcons, VmInput, VmSpinner, VmText, VmView} from 'components'
import {useDebounce} from 'utils'
import {qKodeBiayaBpjs} from '../api'

const ModalItem = ({onClose, onSelect, uid}) => {
  const [keyword, setkeyword] = useState('')
  const [data, setdata] = useState([])
  const [loading, setloading] = useState(false)
  const {debouncedValue, loading: searchLoading} = useDebounce(keyword, 500)

  const initData = async () => {
    setloading(true)
    const data = await qKodeBiayaBpjs({uid})
    if (data.status == 0 || data?.log == 'gagal') {
      setdata({
        err: true,
        message:
          data?.log == 'gagal'
            ? '[EB-11] Gagal saat mengambil data, silakan coba kembali!'
            : data.message,
      })
    } else if (data?.metaData?.code == 402 || data?.metaData?.code == 401) {
      setdata({empty: true})
    } else {
      localStorage.setItem('tempkdbpjs', JSON.stringify(data?.response?.list))
      setdata(data?.response?.list || [])
    }
    setloading(false)
  }

  useEffect(() => {
    initData()
  }, [])

  useEffect(() => {
    if (data?.length > 0 || data?.empty) {
      const initialData = JSON.parse(localStorage.getItem('tempkdbpjs'))
      const filteredData =
        data?.length > 0
          ? data.filter(item => {
              const pattern = new RegExp(debouncedValue, 'i')
              return (
                pattern.test(item?.nmTindakan) || pattern.test(item?.kdTindakan)
              )
            })
          : []

      if (debouncedValue && filteredData.length > 0) {
        setdata(filteredData)
      } else if (!filteredData.length > 0 && debouncedValue) {
        setdata({empty: true})
      } else {
        setdata(initialData)
      }
    }
  }, [debouncedValue])

  return (
    <VmView>
      {/* Section Header */}
      <VmIcons
        size={26}
        name="VmXMarkIcon"
        className="text-black1-payment my-4 ml-auto"
        onClick={onClose}
      />

      {/* Section Search */}
      <VmView className="relative">
        <VmInput
          typeInput="text"
          className="border-[#e5e7eb] !rounded-y-full !text-xs w-full p-2 mb-4"
          hidering={true}
          placeholder="Cari Kode / Nama Biaya"
          onChangeCapture={e => setkeyword(e.target.value)}
          value={keyword}
        />
        {keyword?.length > 0 && (
          <VmView
            onClick={() => setkeyword('')}
            className="absolute flex items-center top-2 right-2"
          >
            <VmIcons
              size={16}
              name="VmXCircleIcon"
              variant="outline"
              className="text-red5-payment"
            />
          </VmView>
        )}
      </VmView>

      {/* Section List Item */}
      <VmView>
        {searchLoading || loading ? (
          <VmView className="flex justify-center items-center">
            <VmSpinner size="sm" color="primary" />
          </VmView>
        ) : null}

        {!searchLoading && !loading && !data?.err && !data?.empty
          ? data.map((item, i) => (
              <VmText
                onClick={() => onSelect(item)}
                className="text-black1-payment border-[#e5e7eb] border-b-[0.5px] mb-6 text-sm"
                key={i}
              >
                {`${item.kdTindakan || ' '} - ${item.nmTindakan || ' '}`}
              </VmText>
            ))
          : null}

        {data?.err && !loading ? (
          <VmView className="flex flex-col">
            <VmText className="text-black1-payment text-center mt-4 text-sm">
              {data?.message}
            </VmText>

            <VmButton
              size="sm"
              className="mx-auto mt-2 flex flex-row items-center"
              onClick={initData}
            >
              Reload
              <VmIcons
                size={14}
                name="VmArrowPathIcon"
                color="#FFFFFF"
                className="ml-2"
              />
            </VmButton>
          </VmView>
        ) : null}

        {data?.empty && !loading ? (
          <VmText className="text-black1-payment text-center mt-4 text-sm">
            Tidak ada data!
          </VmText>
        ) : null}
      </VmView>
    </VmView>
  )
}

export default ModalItem
