import classNames from 'classnames'
import {VmIcons, VmInput, VmText, VmView} from 'components'
import moment from 'moment'
import {Fragment} from 'react'
import {eventTrigerInput, isBoolean, isObject} from 'utils'

const defaultAdditionalButtonsConfig = {
  // name | variant | color | bgcolors
  plus: ['VmPlusSmallIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  minus: ['VmMinusSmallIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  view: ['VmEyeIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  search: ['VmMagnifyingGlassIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  trash: ['VmTrashIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
  edit: ['VmPencilSquareIcon', 'outline', '#FFFFFF', 'bg-blue1-payment'],
}
// any type input
export default function InputType1({
  im = {},
  data = {},
  index = -1,
  ImIcon = null,
  textColor = 'text-blue-500',
  bgcolors = 'bg-gray-200',
  imInputRef = {current: []},
  handleValue = (im, data, field) => data[field] || '',
  onChangeCapture = () => {},
  lstAdditionalButtonsConfig = defaultAdditionalButtonsConfig,
}) {
  const regexTime = time => {
    var regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
    return regex.test(time) ? time : ''
  }
  const regexDate = date => {
    var regex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
    return regex.test(date) ? date : moment().format('YYYY-MM-DD')
  }

  return (
    <VmView
      className={classNames(
        im?.className ? im.className : '',
        'flex flex-nowrap flex-col p-2 justify-between'
      )}
    >
      <VmView className="flex">
        <VmText className={classNames(`text-xs`, textColor)}>
          {im?.title || ''}
        </VmText>
        {im?.required && (
          <VmText className="px-1 text-xs text-red-500">*</VmText>
        )}
      </VmView>
      <VmView
        className={classNames(
          im?.classNameContent ? im.classNameContent : '',
          'relative flex flex-row mt-1'
        )}
      >
        <VmView
          className={classNames(
            im?.classNameWinput ? im.classNameWinput : '',
            `relative flex flex-row h-fit w-[-webkit-fill-available] ${bgcolors} rounded-md`
          )}
        >
          {!!ImIcon ? (
            <VmView
              className={classNames(
                im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                `p-3.5 w-12 rounded-l-md border-none`,
                `!${textColor}`
              )}
            >
              <ImIcon
                className={classNames(
                  im.typeInput === 'textarea' ? '' : 'h-full',
                  'w-full'
                )}
                strokeWidth="1.6"
              />
            </VmView>
          ) : (
            <VmView />
          )}
          <VmInput
            ref={ref => (imInputRef.current[`${im.label}`] = ref)}
            maxLength={im?.maxLength}
            max={im?.max}
            typeInput={im?.typeInput}
            disabled={im?.disabled}
            readonly={im?.readonly}
            required={im?.required}
            label={im.label}
            className={classNames(
              im?.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
              im?.usebtnremove ? '!pr-12' : '',
              im?.classNameInput ? im.classNameInput : '',
              'transparent-items center-items bg-transparent w-full rounded-l-none border-none'
            )}
            hidering={isBoolean(im?.hidering) ? im.hidering : true}
            placeholder={im?.placeholder || ''}
            key={data[im.field] || ''}
            onChangeCapture={e => onChangeCapture(e, im, index)}
            {...Object.assign(
              {},
              im?.typeInput === 'time'
                ? {defaultValue: regexTime(handleValue(im, data, im.field))}
                : {defaultValue: handleValue(im, data, im.field)},
              im?.typeInput === 'date'
                ? {value: regexDate(handleValue(im, data, im.field))}
                : {},
              isObject(im?.inputprops) ? im?.inputprops : {}
            )}
          />
          {im?.usebtnremove && (
            <VmView
              label={`remove-${im.label}`}
              className={classNames(
                data[im.field] ? '' : 'hidden',
                'w-12 p-3.5 absolute flex items-start top-0 right-0 bottom-0'
              )}
              onClick={() => {
                const input = imInputRef.current[`${im.label}`]
                eventTrigerInput(input, '')
                input.focus()
              }}
            >
              <VmIcons
                label={`remove-${im.label}`}
                className="w-full h-full text-red5-payment"
                name="VmXCircleIcon"
                variant="outline"
              />
            </VmView>
          )}
          {im?.usebtnpicker && (
            <VmView
              label={`picker-${im.label}`}
              className={classNames(
                'w-8 py-3.5 px-2 absolute flex items-center top-0 right-0 bottom-0'
              )}
            >
              <VmIcons
                label={`picker-${im.label}`}
                className="w-full h-full text-black2-payment"
                name="VmChevronDownIcon"
                variant="outline"
              />
            </VmView>
          )}
        </VmView>
        {im?.additionalButtons?.length > 0 && (
          <VmView
            className={`relative flex ${
              im?.additionalButtons?.length !== 3 ? 'flex-row' : 'flex-col'
            } flex-wrap content-start justify-end gap-4 ml-4 max-w-[7.1rem]`}
          >
            {im.additionalButtons.map((imb, indeximb) => {
              const defbtns = lstAdditionalButtonsConfig[imb]
              if (!!defbtns) {
                return (
                  <VmView
                    key={`${imb}-${indeximb}`}
                    label={`${imb}-${im.label}`}
                    className={classNames(
                      im.typeInput === 'textarea' ? 'h-12' : 'h-12',
                      defbtns[3],
                      `relative p-3.5 w-12 border-none !${textColor} rounded-md`
                    )}
                  >
                    <VmIcons
                      label={`${imb}-${im.label}`}
                      className="w-full h-full"
                      name={defbtns[0]}
                      variant={defbtns[1]}
                      color={defbtns[2]}
                      strokeWidth={defbtns[4] || '1.6'}
                    />
                    <VmView
                      className="absolute inset-0"
                      label={`${imb}-${im.label}`}
                    />
                  </VmView>
                )
              }
              return <Fragment key={`${imb}-${indeximb}`} />
            })}
          </VmView>
        )}
      </VmView>
    </VmView>
  )
}
