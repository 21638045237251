import React, {useCallback, useEffect, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'

import {isFunction} from 'utils'
import {VmInput, VmText, VmView} from 'components'
import {ReportMobile} from 'template'
import {
  ProgressFullScreen,
  VmCardReport,
  VmMobileFilter,
  VmPulltoRefresh,
} from 'molecules'
import {Page500} from 'pages'
import QueryString from 'qs'
import axios from 'axios'
import {empty, tglYMD} from 'utils/functions'
import satusehaticon from '../../assets/satusehat.png'
import moment from 'moment'

const {REACT_APP_BASE_URL_DOKTER: APIDOC} = process.env

export default function MobLapStatistikDiagnosa({
  classNameANCHORHeader = '',
  classNameANCHORContent = '',
  ...props
}) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dctid = searchParams.get('dctid')
  const initialApiParams = Object.assign(
    {
      reg: 'dbdok',
      uid: dctid,
      limit: 10,
      offset: 0,
      dateStart: new Date(moment().format('YYYY-MM-01')),
      dateEnd: new Date(),
      dateType: 2,
      bulan: moment().format('YYYY-MM-DD'),
      tahun: '',
      a: 'dk13',
      date: '',
      cari: '',
      sort: '',
    },
    props?.dctid ? {dctid: props.dctid} : {}
  )
  const initialFilter = {
    filterFieldsValue: [
      {
        'Semua Periode': [
          {
            value: '',
            label: 'Berdasarkan Tahun',
            flag: 'tahun',
          },
          {
            value: '',
            label: 'Berdasarkan Bulan',
            flag: 'bulan',
          },
          {
            value: '',
            label: 'Berdasarkan Tanggal',
            flag: 'tanggal',
          },
        ],
      },
    ],
    currentOpenedFilter: 'Semua Periode',
    activeFilterHead: ['Semua Periode'],
    activeFilterHeadLabel: {
      // tanggal: `${initialApiParams.dateStart} sd ${initialApiParams.dateEnd}`,
      'Semua Periode': `${moment(initialApiParams.dateStart).format(
        'MMM-YYYY'
      )}`,
    },
    dateFilterFlag: {bulan: true},
    sortIcon: '',
    activeFilter: {
      date: {
        bulan: new Date(initialApiParams.dateStart),
      },
      dateType: initialApiParams.dateType,
    },
    dateValue: {
      datePickerValue: new Date(initialApiParams.dateStart),
      // dateStart: new Date(initialApiParams.dateStart),
      // dateEnd: new Date(initialApiParams.dateEnd),
    },
  }

  // UI State
  const [error, setError] = useState('')
  const [data, setData] = useState('')
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState(initialFilter)
  const [cari, setCari] = useState('')
  const [count, setCount] = useState(0)

  // Api State
  const [hasMore, setHasMore] = useState(true)
  const [apiParams, setApiParams] = useState({...initialApiParams})

  useEffect(() => {
    fetchData()
  }, [
    apiParams.cari,
    apiParams.bulan,
    apiParams.tahun,
    apiParams.dateStart,
    apiParams.dateEnd,
    apiParams.sort,
  ])

  // Handler on Error Props
  useEffect(() => {
    if (error && !loading) {
      if (isFunction(props?.onError)) {
        // props.onError(error)
      }
    }
  }, [error, loading])

  const onCardItemClick = useCallback(data => {
    // if (isFunction(props.onCardItemClick)) {
    //   props.onCardItemClick(data)
    // }
    // persistPageState({filter, apiParams})
    // navigate(`/prd-lap-statistik-diagnosa/${data.pasid}`)
  })

  const fetchData = async () => {
    // const prevParams = initial ? {} : apiParams
    // const params = {...prevParams, ...additionalParams}
    // console.log('fetchData')
    setLoading(true)
    try {
      const res = await axios
        .post(
          `${APIDOC}/prd-lap-statistik-diagnosa/index`,
          QueryString.stringify({...apiParams})
        )
        .then(({data: res}) => {
          // Berhasil Get Data
          return res
        })
        .catch(e => {
          // console.log('Terjadi Kesalahan saat fetchData')
        })

      const resData = res
      if (resData && resData.status == 1) {
        let parsedData = resData.data
        const parsedCount = resData.count ?? 0
        // console.log({parsedData})

        parsedData = parsedData.map(item => {
          return {
            ...item,
            captall: `Total: ${item.jml}`,
            captlaki: `Jumlah Laki-laki: ${item.jml_laki}`,
            captpr: `Jumlah Perempuan: ${item.jml_pr}`,
          }
        })

        setData(parsedData)
        setCount(parsedCount)
        if (parsedData.length < 10) setHasMore(false)
        else setHasMore(true)
      } else {
        handleError()
      }
      setLoading(false)
    } catch (e) {
      // let runtimeError = handleLambdaRuntimeExit(e)
      // if (runtimeError) {
      //   return refetchLambdaError(runtimeError, params)
      // }
      setLoading(false)
      return handleError(e)
    }
  }
  const handleError = (e = '') => {
    setError({e: e ?? '', message: 'Terjadi kesalahan saat mengambil data'})
  }

  const fetchMore = async () => {
    // console.log('fetchMore')
    const params = {...apiParams, offset: data.length}
    try {
      const res = await axios
        .post(
          `${APIDOC}/prd-lap-statistik-diagnosa/index`,
          QueryString.stringify(params)
        )
        .then(({data: res}) => {
          // Berhasil Get Data
          return res
        })
        .catch(e => {
          // console.log('Terjadi Kesalahan saat fetchMoreData')
        })

      const resData = res
      if (resData && resData.status == 1) {
        let parsedData = resData.data

        parsedData = parsedData.map(item => {
          return {
            ...item,
            captall: `Total: ${item.jml}`,
            captlaki: `Jumlah Laki-laki: ${item.jml_laki}`,
            captpr: `Jumlah Perempuan: ${item.jml_pr}`,
          }
        })
        setData([...data, ...parsedData])
        if (parsedData.length < 10) return setHasMore(false)
      } else {
        handleError()
      }
    } catch (e) {
      let runtimeError = handleLambdaRuntimeExit(e)
      if (!runtimeError) return handleError(e)
      refetchLambdaError(runtimeError, params)
    }
  }

  const onChangeFilter = val => {
    // console.log('onChangeFilter', val)
    const cleanObject = {dateStart: '', dateEnd: '', date: ''}
    let initialDate = {
      dateStart:
        tglYMD(val?.date?.start) ?? tglYMD(moment().format('YYYY-MM-01')),
      dateEnd: tglYMD(val?.date?.end) ?? tglYMD(new Date()),
    }

    if (val?.clear) initialDate = {dateStart: '', dateEnd: ''}
    let params = {...val, ...initialDate, date: '', bulan: '', tahun: ''}

    if (val?.date?.bulan)
      params = {
        ...val,
        bulan: tglYMD(val?.date?.bulan),
        tahun: '',
        ...cleanObject,
      }
    if (val?.date?.tahun)
      params = {
        ...val,
        tahun: tglYMD(val?.date?.tahun),
        bulan: '',
        ...cleanObject,
      }

    // console.log('onchangefilter params', params)
    setApiParams(prev => ({...prev, ...params}))
  }

  const onClearFilter = () => {
    // console.log('onClearFilter')
    const params = {
      ...initialApiParams,
      dateStart: '',
      dateEnd: '',
      dateType: '',
    }
    setApiParams(params)
    setCari('')
  }

  const onSubmitSearch = e => {
    if (e.key === 'Enter') {
      setApiParams(prev => ({...prev, cari: e.target.value}))
    }
  }

  const onRefresh = () => {
    setFilter(initialFilter)
    setCari('')
    setApiParams(initialApiParams)
  }

  const handleLambdaRuntimeExit = error => {
    if (error?.errors?.length > 0)
      return (
        error?.errors[0]?.message.includes(
          'Error: Runtime exited with error: exit status 129'
        ) && error?.errors[0]?.errorType === 'Lambda:Unhandled'
      )
    return false
  }

  const refetchLambdaError = async (runtimeError, params) => {
    let limitCall = 0
    while (runtimeError) {
      if (limitCall === 10) {
        runtimeError = false
        return
      }
      limitCall++
      await fetchData(params)
    }
  }

  if (loading)
    return (
      <ProgressFullScreen useBgColor visible={loading} className="!z-[100]" />
    )

  if (error && !loading) return <Page500 />

  if (data) {
    const searchInputStyle =
      'sticky w-full border-gray-400 text-gray-500 placeholder:text-gray-400 placeholder:text-sm h-10 rounded-xl p-2 mb-1 z-50'

    // console.log({data})

    let headerFields = []
    let dataFields = [
      'icdkode',
      'icdnama',
      'captlaki',
      'captpr',
      {name: 'captall', className: 'font-bold'},
    ]
    let aliasFields = ''
    let imageComponent = ''
    // let statusDbColumn = 'pasaktif'
    let withStatus = false
    return (
      <VmPulltoRefresh onRefresh={onRefresh}>
        <ReportMobile
          withStatus={withStatus}
          data={data}
          // statusDbColumn={statusDbColumn}
          headerFields={headerFields}
          dataFields={dataFields}
          customRender={(item, i) => {
            let terhubung_satusehat = !empty(item.pasid_satusehat)
            return (
              <VmCardReport
                headerFields={headerFields}
                item={item}
                key={i}
                index={i}
                fields={dataFields}
                onCardItemClick={onCardItemClick}
                imageComponent={imageComponent}
                aliasFields={aliasFields}
                // withStatus={withStatus}
                // statusDbColumn={statusDbColumn}
                customStatusComponent={
                  terhubung_satusehat ? (
                    <VmView className="flex items-center">
                      <VmText className="text-xs">Terhubung</VmText>
                      <img
                        width={36}
                        src={satusehaticon}
                        alt="satusehat-icon"
                      />
                    </VmView>
                  ) : (
                    <></>
                  )
                }
                // button={showButtonEdit || detailAttr.button}
                // buttonLabel={showButtonEdit ? 'Ubah' : detailAttr.buttonLabel}
                // buttonAction={() => {}}
                // buttonAdditionalProps={{variant: 'outline', color: 'primary'}}
              />
            )
          }}
          aliasFields={aliasFields}
          imageComponent={imageComponent}
          filterComponent={
            <VmMobileFilter
              className="pt-1.5"
              filterValue={filter}
              filterHandler={setFilter}
              filterFields={[
                {
                  Sorting: [{label: 'Jumlah Diagnosa', dbColumn: 'jml'}],
                },
              ]}
              onChange={onChangeFilter}
              onClear={onClearFilter}
            />
          }
          searchComponent={
            <VmInput
              value={cari}
              onChange={e => setCari(e.target.value)}
              type="text"
              className={searchInputStyle}
              placeholder="Cari Kode / Nama Diagnosa"
              enterKeyHint="search"
              onKeyDown={onSubmitSearch}
            />
          }
          hasMore={hasMore}
          loadMore={fetchMore}
          onCardItemClick={onCardItemClick}
          empty={count <= 0 && !loading && !error}
          count={count ?? 0}
          headerAttr={{enable: false}}
          exportApiUrl={
            process.env.REACT_APP_BASE_URL_DOKTER +
            '/prd-lap-statistik-diagnosa/export'
          }
          exportApiParams={{
            ...initialApiParams,
            ...apiParams,
            ...cari,
            uid: dctid,
          }}
          exportPdfApiUrl={`/prd-lap-statistik-diagnosa/export?dctid=${dctid}&${QueryString.stringify(
            {...apiParams, limit: 99999}
          )}`}
          classNameANCHORHeader={classNameANCHORHeader}
          classNameANCHORContent={classNameANCHORContent}
          detailAttr={{
            button: true,
            buttonLabel: 'Detail',
            buttonAction: onCardItemClick,
            footerDividerVisible: false,
          }}
        />
      </VmPulltoRefresh>
    )
  }
}
