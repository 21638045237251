import axios from 'axios'
import {APIDOC} from 'config/apiurl'
import QueryString from 'qs'
import {toCurrency} from 'utils/functions'

export const fetchCetak = (dctid, id) => {
  const getIdentitas = async () => {
    try {
      const {data: res} = await axios.post(
        `${APIDOC}/ap-user-dokter/get-profil-by-id`,
        QueryString.stringify({
          uid: dctid,
          a: 'dk13',
        })
      )

      if (res.status == 1) return res.data
      return {e: true}
    } catch (error) {
      return {e: true}
    }
  }

  const getStg = async () => {
    try {
      const {data: res} = await axios.post(
        `${APIDOC}/prd-pengaturan-resep/get-stg`,
        QueryString.stringify({
          uid: dctid,
        })
      )

      if (res.status == 1) return res.data
      return {e: true}
    } catch (error) {
      return {e: true}
    }
  }

  const getDetail = async () => {
    const params = QueryString.stringify({id})
    try {
      const {data} = await axios.post(
        APIDOC + '/prd-lap-penjualan-resep/detail',
        params
      )

      if (data.status === 1)
        return {head: data.dataHead, child: data.dataDetail}
      return {e: true}
    } catch (e) {
      return {e: true}
    }
  }

  return Promise.all([getStg(), getIdentitas(), getDetail()])
    .then(([stg, identitas, detail]) => {
      if (stg?.e || identitas?.e || detail?.e) return {e: true}
      return {stg, identitas, detail}
    })

    .catch(err => ({e: true}))
}
