export const defaultpropsbottomsheet = {
  open: false,
  blocking: true,
  className: 'tilt-transparent relative z-[2001]',
  snapPoints: ({minHeight, maxHeight}) => [
    minHeight,
    minHeight > maxHeight / 3 ? maxHeight / 3 : minHeight,
  ],
}
export const defaultpropsbottomsheetBgTransparent = {
  open: false,
  blocking: true,
  className: 'bg-transparent tilt-transparent relative',
  snapPoints: ({minHeight, maxHeight}) => [
    minHeight,
    minHeight > maxHeight / 3 ? maxHeight / 3 : minHeight,
  ],
}
