import React, {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {VmIcons, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import {eventTrigerInput} from 'utils'
import between from 'utils/between'
import {getElement} from 'utils/get-element'
import usePullToRefresh from 'utils/usePullToRefresh'
import useAddEventListener from 'utils/useAddEventListener'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'

import {mBatalKunjungan, mDelete, qIndex} from './api'
import Searchbar from './Searchbar'
import CardItem from './CardItem'
import {PcareDataEntriKun} from './classes'

export default function MobPcareDtEntriKun() {
  const showlog = !false,
    contentRef = useRef(),
    navigate = useNavigate(),
    params = useParams()
  const uid = params?.id,
    defaultfilter = {
      noKartu: '',
      // 0002038332734
    },
    [newvariables, setnewvariables] = useState({
      uid,
      ...defaultfilter,
    }),
    [alertProps, setAlertProps] = useState(defaultpropsalertform),
    [keyword, setkeyword] = useState(''),
    [data, setdata] = useState([]),
    [loading, setloading] = useState(true),
    [error, seterror] = useState(false)
  const closeAlert = () => setAlertProps(defaultpropsalertform)
  const onBackPress = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage('onBackPress:')
    } else {
      navigate(-1)
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel)
      }
      if (ariaLabel === 'reset-modal-search') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-modal-search',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-modal-search',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-modal-search') {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onClickError', error)
      }
    }
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-modal-search') {
        return setkeyword(value)
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e, value)
      }
      if (ariaLabel === 'input-modal-search' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const fetchData = (params = {}) => {
      seterror(null)
      setnewvariables(params)
      qIndex(params)
        .then(
          ({
            status = 0,
            response: resdata = {},
            // message = null,
            metaData = {},
          }) => {
            if (metaData?.code === 200) {
              if (resdata?.count > 0) {
                setdata(resdata?.list || [])
              }
            } else {
              seterror(metaData?.message || 'Gagal mengambil data!')
            }
            if (showlog) console.log('fetchData:data', resdata)
          }
        )
        .catch(ch => {
          if (showlog) console.log('fetchData:ch', ch)
          seterror('[C0] Gagal mengambil data!')
        })
        .finally(() => {
          if (showlog) console.log('fetchData:final')
          setloading(false)
        })
    },
    deleteData = args => {
      mDelete(args)
        .then(res => {
          if (res?.metaData?.code === 200) {
            mBatalKunjungan(args)
              .then(() => {})
              .catch(() => {})
            setAlertProps({
              ...defaultpropsalertform,
              type: 1,
              visible: true,
              children: (
                <>
                  Berhasil Menghapus Data Kunjungan PCare{' '}
                  {args?.noKunjungan
                    ? `dengan No. Kunjungan ${args?.noKunjungan}`
                    : ''}
                </>
              ),
              onConfirm: () => {
                navigate(0)
              },
              onClose: () => {
                navigate(0)
              },
              onCancel: () => {
                navigate(0)
              },
            })
          } else {
            setAlertProps({
              ...defaultpropsalertform,
              type: 4,
              visible: true,
              children: (
                <>
                  {(res?.response?.length > 0
                    ? res?.response[0]?.message
                    : res?.metaData?.message) ||
                    'Gagal menghapus data, silakan coba beberapa saat lagi.'}
                </>
              ),
              onConfirm: closeAlert,
              onClose: closeAlert,
              onCancel: closeAlert,
            })
          }
        })
        .catch(error => {
          // console.log('error', error)
          setAlertProps({
            ...defaultpropsalertform,
            type: 4,
            visible: true,
            children: (
              <>
                {'[C0] Gagal menghapus data, silakan coba beberapa saat lagi.'}
              </>
            ),
            onConfirm: closeAlert,
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        })
        .finally(() => {})
    },
    toggleDelete = item => {
      return setAlertProps({
        ...alertProps,
        type: 2,
        title: 'Peringatan',
        position: 'fit',
        visible: true,
        children: (
          <>
            Apakah Anda yakin? Menghapus entri kunjungan ini akan menghapus
            pendaftaran peserta dan juga membatalkan kunjungan.
          </>
        ),
        onConfirm: () => deleteData({uid, noKunjungan: item.noKunjungan}),
        onClose: closeAlert,
        onCancel: closeAlert,
      })
    }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setdata([])
    fetchData({
      ...newvariables,
      noKartu: keyword,
      // offset: 0,
    })
  }
  const onEndReached = el => {
    const isBottom = el?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadingmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      // onFetchMore()
    }
  }
  const calcTopZero = elm => {
    return between(elm?.getBoundingClientRect()?.top - elm?.offsetTop, -5, 5)
      ? 0
      : 1
  }
  const {pullChange} = usePullToRefresh({
    topTarget: calcTopZero(contentRef.current?.children[1]),
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-modal-search',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(newvariables)
    return () => {}
  }, []) /* eslint-disable-line */

  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  return (
    <VmView className="fixed inset-0 bg-blue4-payment px-5 pt-5">
      <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
        {/* Headers */}
        <VmHeader
          className="mb-5"
          HeaderMode="!text-black2-payment"
          HeaderButtonVersion={0}
          HeaderName="Menu"
          HeaderType="Data Entri Kunjungan"
          onBackPress={onBackPress}
          // labelBackButton={labelBackButton}
        />
        {/* Searchbar */}
        <Searchbar keyword={keyword} label="modal-search" />
        {/* Content */}
        <VmView
          ref={contentRef}
          onScroll={onScroll}
          className="block overflow-y-scroll"
        >
          <VmView
            className="refresh-container w-fit -mt-10 m-auto"
            style={{marginTop: pullChange / 3.118 || ''}}
          >
            <VmView className="refresh-icon p-2 rounded-full">
              <VmIcons
                name="VmArrowPathIcon"
                style={{transform: `rotate(${pullChange}deg)`}}
                size={25}
              />
            </VmView>
          </VmView>
          {data.map((im, index) => {
            const newim = new PcareDataEntriKun(im)
            return (
              <CardItem
                label="-modal"
                key={`CardItem-${index}`}
                index={index}
                data={newim}
                onDelete={toggleDelete}
              />
            )
          })}
          <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
            {error ? error : 'Sudah tidak ada data'}
          </VmView>
        </VmView>
        {/* AlertForm */}
        <AlertForm {...alertProps} />
        {/* Loading Full Screen */}
        <ProgressFullScreen visible={loading} />
      </VmView>
    </VmView>
  )
}
