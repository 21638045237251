import classNames from 'classnames'
import {VmButton, VmView} from 'components'
import {bool, func, oneOf, shape, string} from 'prop-types'
import {useRef} from 'react'

const propType = {
  variant: oneOf(['', 'outline', 'ghost']),
}
const PatExamFooters = ({
  title,
  className,
  variant,
  disabled,
  active,
  rounded,
  color,
  onClick,
}) => {
  const data = Object.keys(title)
  const refView = useRef(null)

  return (
    <>
      <VmView style={{height: `50px`}} />
      <VmView
        ref={refView}
        className={classNames(
          'PatExamFooters',
          'fixed',
          className['container']
        )}
      >
        {data.map(im => {
          if (!title[im]) {
            return null
          }
          return (
            <VmButton
              key={`PatExamFooters${im}`}
              {...Object.assign(
                !!className[im]
                  ? {className: classNames(className[im], 'flex-auto')}
                  : {},
                !!disabled[im] ? {disabled: disabled[im]} : {},
                !!variant[im] ? {variant: variant[im]} : {},
                !!rounded[im] ? {rounded: rounded[im]} : {},
                !!onClick[im] ? {onClick: onClick[im]} : {},
                !!active[im] ? {active: active[im]} : {},
                !!color[im] ? {color: color[im]} : {},
                {}
                // im === 'yes' ? {type: 'submit'} : {}
              )}
            >
              {title[im]}
            </VmButton>
          )
        })}
      </VmView>
    </>
  )
}
PatExamFooters.propTypes = {
  title: shape({yes: string, no: string}),
  className: shape({yes: string, no: string, container: string}),
  color: shape({yes: string, no: string}),
  variant: shape({
    yes: propType.variant,
    no: propType.variant,
  }),
  disabled: shape({yes: bool, no: bool}),
  active: shape({yes: bool, no: bool}),
  rounded: shape({yes: string, no: string}),
  onClick: shape({yes: func, no: func}),
}
PatExamFooters.defaultProps = {
  // title: {yes: 'yes', no: 'no'},
  title: {},
  className: {
    yes: 'font-bold',
    no: 'border-2 font-bold',
    container: '',
  },
  variant: {yes: '', no: 'outline'},
  color: {yes: 'primary', no: 'danger'},
  disabled: {yes: false, no: false},
  active: {yes: false, no: false},
  rounded: {yes: 'xl', no: 'xl'},
  onClick: {yes: () => {}, no: () => {}},
}
export default PatExamFooters
