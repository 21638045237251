import {VmView, VmHeader, VmCardContainer} from 'components'
import {
  VmArchiveBoxIcon,
  VmHomeIcon,
  VmUserIcon,
  VmCalendarIcon,
} from 'components/icons/outline'

import DataKredit from './DataKredit'
import DataTunai from './DataTunai'
import DataKartu from './DataKartu'

const Index = ({data = {}, navigate = false}) => {
  return (
    <VmView>
      <VmHeader
        className="absolute px-4 pt-4 w-full !justify-start"
        spacing="true"
        light="true"
        title="Detail Pembayaran"
        onClick={() => navigate(-1)}
      />
      {/* informasi pasien */}
      <VmCardContainer classname="mt-14 m-3.5 !shadow-lg">
        <h1 className="w-full text-center font-medium text-blue-500">
          Informasi Pasien
        </h1>
        <VmView className="flex flex-1 mt-4 flex-col text-sm">
          <div className="flex flex-1 flex-row">
            <VmView className="flex basis-1/2 h-12">
              <VmView className="flex">
                <VmCalendarIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">Tanggal</h3>
                <p className="text-xs font-medium">{data.date}</p>
              </VmView>
            </VmView>
            <VmView className="flex basis-1/2">
              <VmView className="flex">
                <VmArchiveBoxIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">Nomor Faktur</h3>
                <p className="text-xs font-medium">{data.nofaktur}</p>
              </VmView>
            </VmView>
          </div>
          <div className="flex flex-1 flex-row mt-4">
            <VmView className="flex basis-1/2 h-12">
              <VmView className="flex">
                <VmArchiveBoxIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">No. RM</h3>
                <p className="text-xs font-medium">{data.patnorm}</p>
              </VmView>
            </VmView>
            <VmView className="flex basis-1/2">
              <VmView className="flex">
                <VmUserIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col">
                <h3 className="font-medium text-blue-500">Nama Pasien</h3>
                <p className="text-xs font-medium">{data.patname}</p>
              </VmView>
            </VmView>
          </div>
          <div className="flex flex-1 flex-row mt-4 mb-2">
            <VmView className="flex flex-grow h-12">
              <VmView className="flex">
                <VmHomeIcon className="text-blue-500 w-6 h-6 mt-2" />
              </VmView>
              <VmView className="ml-2 flex flex-col flex-grow">
                <h3 className="font-medium text-blue-500">Alamat</h3>
                <p className="text-xs font-medium">{data.pataddress}</p>
              </VmView>
            </VmView>
          </div>
        </VmView>
      </VmCardContainer>
      {/* detail biaya */}
      <VmCardContainer classname="mt-4 m-3.5 !shadow-lg">
        <h1 className="w-full text-center font-medium text-blue-500">
          Detail Biaya
        </h1>
        <VmView className="flex flex-1 mt-4 flex-col h-44 overflow-scroll">
          {/* item-1 */}
          {data?.cart?.length > 0 &&
            data.cart.map((im, index) => {
              return (
                <VmCardContainer
                  classname="!shadow-lg text-sm mb-4"
                  key={`${im.name} ${index}`}
                >
                  <h3 className="font-medium text-blue-500">{im.name}</h3>
                  <VmView className="flex flex-row">
                    <VmView className="flex basis-1/2 flex-col">
                      <h3 className="font-semibold text-gray-900">
                        {im.price}
                      </h3>
                      {Number(100 - (im.total / im.price) * 100).toFixed(2) >
                      0 ? (
                        <p className="text-xs font-medium text-red-400">
                          Diskon{' '}
                          {Number(100 - (im.total / im.price) * 100).toFixed(2)}{' '}
                          %
                        </p>
                      ) : (
                        ''
                      )}
                    </VmView>
                    <VmView className="flex basis-1/2 items-end text-right">
                      <h3 className="font-semibold text-gray-900 w-full">
                        {im.total}
                      </h3>
                    </VmView>
                  </VmView>
                </VmCardContainer>
              )
            })}
        </VmView>
      </VmCardContainer>
      {/* informasi pembayaran */}
      <VmCardContainer classname="mt-4 m-3.5 !shadow-lg">
        <h1 className="w-full text-center font-medium text-blue-500">
          Informasi Pembayaran
        </h1>
        <VmView className="flex flex-1 mt-4 flex-col text-sm">
          {/* banyak item */}
          <VmView className="flex flex-row">
            <VmView className="flex basis-1/2">
              <p className="text-blue-500">Item</p>
            </VmView>
            <VmView className="flex basis-1/2 text-right text-gray-500">
              <p className="w-full">{data.item}</p>
            </VmView>
          </VmView>
          {/* sub total */}
          <VmView className="flex flex-row">
            <VmView className="flex basis-1/2">
              <p className="text-blue-500">Sub Total</p>
            </VmView>
            <VmView className="flex basis-1/2 text-right text-gray-500">
              <p className="w-full">{data.subtotal}</p>
            </VmView>
          </VmView>
          {/* nominal diskon */}
          {data.nominaldisc > 0 ? (
            <VmView className="flex flex-row text-red-400">
              <VmView className="flex basis-1/2">
                <p>Diskon</p>
              </VmView>
              <VmView className="flex basis-1/2 text-right">
                <p className="w-full">{data.nominaldisc}</p>
              </VmView>
            </VmView>
          ) : (
            ''
          )}
          {/* total */}
          <VmView className="flex flex-row font-semibold">
            <VmView className="flex basis-1/2">
              <p className="text-blue-500">Total</p>
            </VmView>
            <VmView className="flex basis-1/2 text-right">
              <p className="w-full">{data.total}</p>
            </VmView>
          </VmView>
          {/* terbilang */}
          <VmView className="flex flex-row">
            <VmView className="flex basis-1/2">
              <p className="text-blue-500">Terbilang</p>
            </VmView>
            <VmView className="flex basis-1/2 text-right text-gray-500">
              <p className="w-full">{data.terbilang} Rupiah</p>
            </VmView>
          </VmView>
          {/* pembayaran */}
          <VmView className="flex flex-row">
            <VmView className="flex basis-1/2">
              <p className="text-blue-500">Pembayaran</p>
            </VmView>
            <VmView className="flex basis-1/2 text-right text-gray-500">
              <p className="w-full">{data.paymenttype}</p>
            </VmView>
          </VmView>
          {data.paymenttype === 'kartu' ? <DataKartu data={data} /> : ''}
          {/* 1148 = kartu */}
          {data.paymenttype === 'tunai' ? <DataTunai data={data} /> : ''}
          {/* 1145 = tunai */}
          {data.paymenttype === 'piutang' ? <DataKredit data={data} /> : ''}
          {/* 1146 = kredit */}
        </VmView>
      </VmCardContainer>
    </VmView>
  )
}

export default Index
