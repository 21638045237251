import classNames from 'classnames'
import {VmButton, VmIcons, VmView} from 'components'
import moment from 'moment'

export default function CardItemPasien({
  data: {title = '', address = null, telp = '', date = null, kunno = null},
  index = 0,
}) {
  return (
    <VmView className="relative !text-black2-payment bg-white mb-4 last:mb-20 rounded-xl rounded-t-2xl">
      <VmView className="flex px-4 py-3 rounded-xl">
        {/* Head */}
        <VmView className="block mr-auto max-w-[80%]">
          {/* Title */}
          <VmView className="pb-2 font-semibold !text-break-word">
            {title}
          </VmView>
          {/* Date */}
          {date && (
            <VmView className="text-sm !text-break-word">
              {moment(date).format('DD MMM YYYY')}
            </VmView>
          )}
          {kunno && (
            <VmView className="text-sm !text-break-word">{kunno}</VmView>
          )}
          {/* Address */}
          {address && (
            <VmView className="text-sm !text-break-word">{address}</VmView>
          )}
          {/* Telp */}
          <VmView className="flex flex-row items-center text-sm !text-break-word">
            <VmIcons name="VmPhoneIcon" className="mr-1 !text-gray2-payment" />
            {telp}
          </VmView>
        </VmView>
        <VmView className="flex items-end">
          <VmButton
            className={classNames(
              'flex justify-center items-center !flex-none text-sm p-1 px-4 !min-w-[4rem]',
              '!bg-blue1-payment !text-white'
            )}
            hidering={true}
            label={`add-pasien-${index}`}
          >
            Pilih
          </VmButton>
        </VmView>
      </VmView>
    </VmView>
  )
}
