import * as React from 'react'

function VmMaterialSymExportNotes({title, titleId, ...props}, svgRef) {
  return React.createElement(
    'svg',
    Object.assign(
      {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: '0 0 20 20',
        'aria-hidden': 'true',
        ref: svgRef,
        'aria-labelledby': titleId,
      },
      props,
      {
        fill: 'currentColor',
        stroke: 'none',
      }
    ),
    title
      ? React.createElement(
          'title',
          {
            id: titleId,
          },
          title
        )
      : null,
    React.createElement('path', {
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      d: 'M13.5 17.0833L15.8333 14.75V16.6667H16.6667V13.3333H13.3333V14.1667H15.25L12.9167 16.5L13.5 17.0833ZM15 19.1667C13.8472 19.1667 12.8647 18.7603 12.0525 17.9475C11.2403 17.1347 10.8339 16.1522 10.8333 15C10.8333 13.8472 11.2397 12.8647 12.0525 12.0525C12.8653 11.2403 13.8478 10.8339 15 10.8333C16.1528 10.8333 17.1356 11.2397 17.9483 12.0525C18.7611 12.8653 19.1672 13.8478 19.1667 15C19.1667 16.1528 18.7603 17.1356 17.9475 17.9483C17.1347 18.7611 16.1522 19.1672 15 19.1667ZM5.83333 7.5H14.1667V5.83333H5.83333V7.5ZM9.72917 17.5H4.16667C3.70833 17.5 3.31611 17.3369 2.99 17.0108C2.66389 16.6847 2.50056 16.2922 2.5 15.8333V4.16667C2.5 3.70833 2.66333 3.31611 2.99 2.99C3.31667 2.66389 3.70889 2.50056 4.16667 2.5H15.8333C16.2917 2.5 16.6842 2.66333 17.0108 2.99C17.3375 3.31667 17.5006 3.70889 17.5 4.16667V9.75C17.0972 9.55556 16.6908 9.40972 16.2808 9.3125C15.8708 9.21528 15.4439 9.16667 15 9.16667C14.8472 9.16667 14.7047 9.17 14.5725 9.17667C14.4403 9.18333 14.305 9.20083 14.1667 9.22917V9.16667H5.83333V10.8333H10.9375C10.6875 11.0694 10.4619 11.3264 10.2608 11.6042C10.0597 11.8819 9.8825 12.1806 9.72917 12.5H5.83333V14.1667H9.22917C9.20139 14.3056 9.18417 14.4411 9.1775 14.5733C9.17083 14.7056 9.16722 14.8478 9.16667 15C9.16667 15.4583 9.20833 15.8856 9.29167 16.2817C9.375 16.6778 9.52083 17.0839 9.72917 17.5Z',
    })
  )
}

const ForwardRef = React.forwardRef(VmMaterialSymExportNotes)
export default ForwardRef
