import {VmButton, VmIcons, VmSpinner, VmText, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {useEffect, useRef, useState} from 'react'
import Searchbar from './Searchbar'
import AddCardItem from './AddCardItem'
import {BottomSheet} from 'react-spring-bottom-sheet'
import useAddEventListener from 'utils/useAddEventListener'
import {defaultpropsbottomsheet} from 'entries/bottomsheet'
import classNames from 'classnames'
import {defaultpropsalertform} from 'entries/alertform'
import {mAddMitra, mSearchNewMitra, mTidakSetuju} from './api'
import AlertForm from 'template/Form/AlertForm'
import {getElement} from 'utils/get-element'
import {eventTrigerInput} from 'utils'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {AddMitraApotek} from './classes'
import {getValue} from 'utils/datausr'
import SimpleToast from 'components/SimpleToast'
import {defaultpropssimpletoast} from 'entries/simpletoast'
import usePullToRefresh from 'utils/usePullToRefresh'
import {UserController} from 'controllers'

export default function AddMitra() {
  const showlog = !false,
    limit = 10,
    navigate = useNavigate(),
    location = useLocation(),
    bottomSheetRef = useRef()
  const dctid = parseInt(useParams().id)
  const [propssimpletoast, setpropssimpletoast] = useState(
    defaultpropssimpletoast
  )
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [offset, setoffset] = useState(0)
  const [keyword, setkeyword] = useState('')
  const [error, seterror] = useState(null)
  const [loading, setloading] = useState(true)
  const [loadingdoc, setloadingdoc] = useState(true)
  const [loadmore, setloadmore] = useState(true)
  const [onloadmore, setonloadmore] = useState(true)
  const [data, setdata] = useState([])
  const [datadoc, setdatadoc] = useState(getValue() || {})
  const [datacount, setdatacount] = useState(0)
  const [params, setparams] = useState({
    log: showlog,
    input: {
      id: dctid,
      limit,
      offset,
      spdstatus: 0,
      carimobile: keyword,
    },
  })
  const onShowToast = ({message = '-', type}) => {
    var newtimeout1 = null
    newtimeout1 = setTimeout(() => {
      setpropssimpletoast(defaultpropssimpletoast)
      clearTimeout(newtimeout1)
    }, 3000)
    var toastericon = type === 'Fail' ? 'VmXCircleIcon' : 'VmCheckCircleIcon'
    var toasterbg = type === 'Fail' ? 'bg-red5-payment' : 'bg-blue1-payment'
    return setpropssimpletoast({
      toasterbg,
      toastericon,
      toastertitle: message,
      toastervisible: true,
    })
  }
  const onTolak = (data, eventBtn) => {
    setloading(true)
    mTidakSetuju({input: {uid: dctid, nama: data?.title || ''}}).finally(() => {
      setloading(!true)
      setpropsbottomsheet(defaultpropsbottomsheet)
    })
  }
  const onTerima = (data, eventBtn) => {
    var newtimeout1 = null
    setpropsbottomsheet(defaultpropsbottomsheet)
    const datainput = new AddMitraApotek({
      ...params.input,
      ...data.defaultdata,
      namadoc: datadoc?.nama_lengkap || '',
      email: datadoc?.email || '',
      doknama: datadoc?.nama_lengkap,
    })
    const kl_nama = data.title
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      closeable: false,
      children: (
        <>
          Apakah anda yakin akan mengajukan kerjasama dengan <b>{kl_nama}</b>?
        </>
      ),
      onConfirm: () => {
        setloading(true)
        mAddMitra({
          ...params,
          input: datainput,
        })
          .then(({data}) => {
            if (data?.status === 1) {
              newtimeout1 = setTimeout(() => {
                const finsInputEl = getElement({
                  tag: 'button',
                  by: 'ariaLabel',
                  equal: 'btn-search-mitra',
                })
                finsInputEl?.click()
                clearTimeout(newtimeout1)
              }, 100)
              onShowToast({
                message: (
                  <>
                    <b>{kl_nama}</b> ditambahkan.
                  </>
                ),
              })
            } else {
              onShowToast({
                message: (
                  <>
                    <b>{kl_nama}</b> gagal ditambahkan!
                  </>
                ),
                type: 'Fail',
              })
            }
          })
          .catch(ch => {
            if (showlog) {
              console.log('ch', ch)
            }
            onShowToast({
              message: (
                <>
                  {`[C0] `}
                  <b>{kl_nama}</b> gagal ditambahkan!
                </>
              ),
              type: 'Fail',
            })
          })
          .finally(() => {
            setloading(!true)
          })
      },
      onClose: () => {
        setpropsalertform(defaultpropsalertform)
      },
      onCancel: () => {
        eventBtn.target.click()
        setpropsalertform(defaultpropsalertform)
      },
    })
  }
  const fetchData = (params = {}) => {
    seterror(null)
    setparams(params)
    mSearchNewMitra(params)
      .then(({data: resdata = {}, statusCode = 0}) => {
        if (showlog) {
          console.log('resdata', resdata)
        }
        if (statusCode === 200 && resdata?.status === 1) {
          if (params?.input?.offset === 0) {
            setdata(resdata?.data)
            setdatacount(resdata?.count || 0)
          } else {
            setdata([...data, ...resdata.data])
          }
          if (resdata?.data?.length < limit) {
            setloadmore(false)
          }
        } else {
          if (loadmore) {
            seterror(resdata?.message || 'Gagal mengambil data!')
          }
          setloadmore(false)
        }
      })
      .catch(ch => {
        if (showlog) {
          console.log('ch', ch)
        }
        setloadmore(false)
        seterror(`[${ch?.data?.error?.code || 'C0'}] Gagal mengambil data!`)
      })
      .finally(() => {
        if (showlog) {
          console.log('finish')
        }
        setloading(false)
        setonloadmore(false)
      })
  }
  const onSearchData = () => {
    if (showlog) {
      console.log('keyword', keyword)
    }
    setloading(true)
    setloadmore(true)
    setdata([])
    setdatacount(0)
    fetchData({
      ...params,
      input: {
        ...params.input,
        offset: 0,
        carimobile: keyword,
      },
    })
  }
  const onFetchMore = () => {
    if (data.length > 0 && loadmore && !onloadmore) {
      setonloadmore(true)
      return fetchData({
        ...params,
        input: {
          ...params.input,
          offset: params.input.offset + limit,
        },
      })
    }
  }
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      // console.log('ariaLabel', ariaLabel)
      if (e?.target?.dataset?.rsbsBackdrop === 'true') {
        // onClick backdrop Bottom Sheet
        return setpropsbottomsheet(defaultpropsbottomsheet)
      }
      if (ariaLabel === 'reset-search-mitra') {
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal: 'input-search-mitra',
        })
        return Promise.all([eventTrigerInput(finsInputEl, '')]).finally(() => {
          var newtimeout = null
          newtimeout = setTimeout(() => {
            const finsInputEl = getElement({
              tag: 'button',
              by: 'ariaLabel',
              equal: 'btn-search-mitra',
            })
            finsInputEl?.click()
            clearTimeout(newtimeout)
          }, 100)
        })
      }
      if (ariaLabel === 'btn-search-mitra') {
        return onSearchData()
      }
      if (`${ariaLabel}`.match(/^add-mitra-/i)) {
        const idx = `${ariaLabel}`.replace(/^add-mitra-/i, '')
        // console.log('idx', data[idx])
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          snapPoints: ({minHeight, maxHeight}) => [
            maxHeight / 1.5,
            maxHeight / 1.5,
          ],
          children: (
            <VmView className="z-[2] rounded-t-2xl text-left">
              <VmView className="fixed z-[2] rounded-t-2xl top-0 left-0 right-0 bg-gray7-payment px-5 py-4">
                Dengan ini Saya TELAH SETUJU dengan Syarat dan Ketentuan berikut
                :
              </VmView>
              <VmView className="relative px-5 pb-4">
                Dengan ini Saya TELAH SETUJU dengan Syarat dan Ketentuan berikut
                :
              </VmView>
              <VmView className="relative flex-col overflow-scroll p-5 pb-[5rem] h-[70%] bg-white cursor-pointer">
                <VmView className="flex flex-col overflow-y-scroll">
                  {[
                    <>
                      1. Saya akan melakukan{' '}
                      <b>Kerjasama Resep Dokter Online</b> dengan{' '}
                      <b>{data[idx]?.title}</b>
                    </>,
                    <>
                      2. Setiap <b>Resep Dokter Online</b> yang ditebus dan
                      dibayar oleh Pasien di <b>{data[idx]?.title}</b>, Saya
                      Akan mendapatkan Insentif <b>{data[idx]?.insentif}%</b>{' '}
                      dari <b>Total Resep</b> tersebut.
                    </>,
                    <>
                      3. Total Insentif yang didapatkan akan tercatat di{' '}
                      <b>Laporan Resep Dokter</b> yang nanti akan dibayar oleh{' '}
                      <b>{data[idx]?.title}</b>.
                    </>,
                    <>
                      4. <b>Proses Kerjasama</b> dan{' '}
                      <b>Pemutusan Kerjasama Resep Dokter Online</b> ini hanya
                      akan berlangsung setelah mendapat persetujuan dari kedua
                      belah pihak Antara <b>{datadoc?.nama_lengkap || ''}</b>{' '}
                      dan <b>{data[idx]?.title}</b>.
                    </>,
                    <>
                      5. Saya <b>TIDAK AKAN MEMBUAT RESEP</b> yang secara UU dan
                      Aturan Hukum Lainnya tidak boleh diberikan kepada Pasien.
                    </>,
                    <>
                      6. Saya secara <b>Pribadi</b> siap menerima konsekuensi
                      hukum, apabila terbukti melakukan pelanggaran dalam
                      membuat <b>Resep Dokter Online</b>.
                    </>,
                  ].map((im, index) => {
                    return (
                      <VmText
                        key={`agreement-${index}`}
                        className="pb-2 text-sm"
                      >
                        {im}
                      </VmText>
                    )
                  })}
                </VmView>
              </VmView>
              <VmView className="absolute flex flex-row justify-between backdrop-blur-sm bg-white/10 p-5 bottom-0 left-0 right-0">
                <VmButton
                  label="bs-dontagree"
                  className={classNames(
                    'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[47.5%] !bg-red-600 !text-white'
                  )}
                  hidering={true}
                  onClick={() => onTolak(data[idx], {target: e?.target})}
                >
                  {`Tidak Setuju`}
                </VmButton>
                <VmButton
                  label="bs-agree"
                  className={classNames(
                    'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[47.5%] !bg-green-600 !text-white'
                  )}
                  hidering={true}
                  onClick={() => onTerima(data[idx], {target: e?.target})}
                >
                  {`Setuju`}
                </VmButton>
              </VmView>
            </VmView>
          ),
        })
      }
    } catch (error) {}
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, value)
      }
      if (ariaLabel === 'input-search-mitra') {
        return setkeyword(value)
      }
    } catch (error) {
      if (showlog) {
        console.log('onChangeError', error)
      }
    }
  }
  const onKeypress = e => {
    // onKeypress
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel', ariaLabel, e)
      }
      if (ariaLabel === 'input-search-mitra' && e?.keyCode === 13) {
        return onSearchData()
      }
    } catch (error) {
      if (showlog) {
        console.log('onKeypressError', error)
      }
    }
  }
  const onEndReached = etbodytablecost => {
    const isBottom =
      etbodytablecost?.getBoundingClientRect()?.bottom <= window.innerHeight
    if (isBottom) {
      return true
    }
    return false
  }
  const onScroll = e => {
    const {children} = e.target
    const elLoading = children[children.length - 1]
    const isElLoading = elLoading?.ariaLabel === 'loadmore'
    const onreached = onEndReached(elLoading)
    if (onreached && isElLoading) {
      onFetchMore()
    }
  }
  useEffect(() => {
    if (window?.ReactNativeWebView?.postMessage) {
      window.ReactNativeWebView.postMessage(`setDisableBackRN:`)
    }
    return () => {
      if (window?.ReactNativeWebView?.postMessage) {
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`)
      }
    }
  }, [])
  const goBack = () => {
    if (window?.ReactNativeWebView?.postMessage) {
      Promise.all([
        window.ReactNativeWebView.postMessage(`setEnableBackRN:`),
      ]).finally(() => {
        navigate(-1)
      })
    } else {
      navigate(-1)
    }
  }
  const onBackPress = e => {
    setpropsalertform({
      ...propsalertform,
      type: 3,
      visible: true,
      children: 'Apakah anda yakin akan keluar menu ini?',
      onConfirm: goBack,
      onClose: () => setpropsalertform(defaultpropsalertform),
      onCancel: () => setpropsalertform(defaultpropsalertform),
    })
  }
  const onHardwareBackPress = event => {
    const {data} = event
    // console.log(
    //   'window.location',
    //   location?.pathname,
    //   `${location?.pathname}`.split('-').length,
    //   `${location?.pathname}`?.match(
    //     /^\/prd\-pengajuan\-dokter\/add\-mitra\//i
    //   )
    // )
    if (
      `${data}`?.match(/^onHardwareBackPress:/i) &&
      `${location?.pathname}`.split('-').length === 4
    ) {
      const rbsPortal = document.getElementsByTagName('reach-portal')
      if (rbsPortal[0]?.children[0]?.dataset?.rsbsState === 'open') {
        rbsPortal[0]?.children[0]?.children[0]?.click()
        return
      }
      return onBackPress()
    }
  }
  useAddEventListener({
    eventName: 'message',
    eventRNName: 'hardwareBackPress',
    handler: onHardwareBackPress,
  })
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'keypress', handler: onKeypress})
  const {pullChange} = usePullToRefresh({
    maxPullChange: 150,
    onRefresh: () => {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        const finsInputEl = getElement({
          tag: 'button',
          by: 'ariaLabel',
          equal: 'btn-search-mitra',
        })
        finsInputEl?.click()
        clearTimeout(newtimeout)
      }, 100)
    },
  })
  useEffect(() => {
    fetchData(params)
    const showerrorloaddoc = (code = '') =>
      setpropsalertform({
        ...propsalertform,
        type: 4,
        visible: true,
        children: (
          <>
            {code} Gagal mengambil informasi user!
            <br />
            Silakan hubungi <b>Admin</b> jika user anda masih terkendala.
          </>
        ),
        onConfirm: goBack,
        onClose: goBack,
        onCancel: goBack,
      })
    UserController(
      {
        id: dctid,
        key: 'index',
        dispatch: () => {},
        _ldata: 'mUserInfo',
        field: [
          'status',
          'name',
          'spesialis',
          'address',
          'province',
          'city',
          'subdistrict',
          'email',
          'nosip',
          'nostr',
        ],
      },
      () => {}
    )
      .then(resdata => {
        if (resdata?.status === 1) {
          setdatadoc(
            Object.assign(datadoc, resdata?.data ? resdata?.data : {}, {
              nama_lengkap: resdata?.data?.name,
            })
          )
        } else {
          return showerrorloaddoc()
        }
      })
      .catch(ch => {
        return showerrorloaddoc('[C0]')
      })
      .finally(() => {
        setloadingdoc(false)
      })
    return () => {}
  }, [])

  return (
    <>
      <VmView className="fixed inset-0 bg-blue4-payment px-5 pt-5">
        <VmView className="flex flex-col max-h-[-webkit-fill-available] justify-between">
          {/* Headers */}
          <VmHeader
            className="mb-5"
            HeaderMode="!text-black2-payment"
            HeaderButtonVersion={0}
            HeaderName="Menu"
            HeaderType={'Tambah Mitra Apotek / Klinik'}
            onBackPress={onBackPress}
          />
          {/* Searchbar */}
          <Searchbar keyword={keyword} />
          {/* Content */}
          <VmView
            onScroll={onScroll}
            className="flex flex-col overflow-y-scroll"
          >
            <VmView
              className="refresh-container w-fit -mt-10 m-auto"
              style={{marginTop: pullChange / 3.118 || ''}}
            >
              <VmView className="refresh-icon p-2 rounded-full">
                <VmIcons
                  name="VmArrowPathIcon"
                  style={{transform: `rotate(${pullChange}deg)`}}
                  size={25}
                />
              </VmView>
            </VmView>
            {data.map((im, index) => {
              return (
                <AddCardItem
                  key={`AddCardItem-${index}`}
                  index={index}
                  data={im}
                />
              )
            })}
            {!loadmore ? (
              <VmView className="relative self-center text-center text-gray2-payment last:mb-20 ">
                {error ? error : 'Sudah tidak ada data'}
              </VmView>
            ) : (
              <VmView
                key={'loadmore'}
                label="loadmore"
                className="relative self-center text-center text-gray2-payment last:mb-20 "
              >
                <VmSpinner className="text-blue1-payment" />
              </VmView>
            )}
          </VmView>
        </VmView>
      </VmView>
      {/* Simple Toast */}
      <SimpleToast {...propssimpletoast} />
      {/* Bottom Sheet */}
      <BottomSheet ref={bottomSheetRef} {...propsbottomsheet} />
      {/* AlertForm */}
      <AlertForm {...propsalertform} />
      {/* Loading Full Screen */}
      <ProgressFullScreen visible={loading || loadingdoc} />
    </>
  )
}
