import {VmButton, VmIcons, VmModal, VmSpinner, VmText, VmView} from 'components'
import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useEffect,
  Fragment,
  createRef,
  useCallback,
  useMemo,
} from 'react'
import axios from 'axios'
import {TemplateListInputItems} from 'template'
import {VmListPencilIcon} from 'components/icons/solid'
import QueryString from 'qs'
import {showSimpleToastApp} from 'utils/simpletoast'
import {isFunction} from 'utils'

const textclassname = '!text-slate-500 text-sm font-semibold'
const titleclassname = 'font-bold'
function FormContent(
  {
    title = 'Title',
    defaultData = '',
    variables = {},
    config = {},
    method = 'POST',
    url = '',
    errorMessage = '[C0] Gagal menyimpan data!',
    status0Message = '[S0] Gagal menyimpan data!',
    field = '#',
    limit = 10,
    offset = 0,
    onCancel: onCancelProps = () => null,
    onConfirm: onConfirmProps = () => null,
    onSuccess: onSuccessProps = () => null,
    AdditionalComponents = () => <></>,
    children = null,
    confirmtitle = 'Simpan',
    label = 'Simpan',
    canceltitle = null,
    lstmenu = [
      {
        type: 1,
        title: 'Contoh',
        Icon: VmListPencilIcon,
        label: `input-field`,
        field: `field`,
        typeInput: 'text',
        className: titleclassname,
        classNameInput: textclassname,
        maxLength: 255,
      },
    ],
  },
  ref
) {
  const modalRef = createRef(),
    [newlimit, setnewlimit] = useState(limit),
    [newoffset, setnewoffset] = useState(offset),
    [date, setdate] = useState(Date.now()),
    [newvariables, setnewvariables] = useState(variables),
    [data, setdata] = useState(defaultData),
    [message, setmessage] = useState(''),
    [loading, setloading] = useState(false),
    [error, seterror] = useState(false),
    [errordata, seterrordata] = useState(),
    whensuccess = isFunction(onSuccessProps)

  const postData = async (config, url, variables, method) => {
    const req = {
      ...config,
      url,
      method,
    }
    if (!config?.data) {
      req['data'] = QueryString.stringify(variables)
    }
    axios
      .request(req)
      .then(({data}) => {
        setmessage(data?.message || '')
        if (data?.status === 1) {
          setdata(data)
          showSimpleToastApp(data?.message || 'Berhasil', 'Success')
        } else if (data?.status === 2) {
          seterrordata(data?.error)
          seterror(data?.message || status0Message)
          setdata(data || defaultData)
        } else {
          seterrordata(data?.error)
          seterror(data?.message || status0Message)
        }
      })
      .catch(error => {
        seterrordata(error)
        seterror(errorMessage)
      })
      .finally(() => {
        setloading(false)
      })
  }
  const onChangeData = useCallback(
    e => {
      setnewvariables(t => {
        return Object.assign(t || {}, e)
      })
    },
    // eslint-disable-next-line
    [newvariables]
  )
  const onChangeCapture = (e, im) => {
    onChangeData({[im?.field || field]: e?.target?.value || ''})
  }
  const onCancel = e => {
      if (e?.preventDefault) {
        e.preventDefault()
      }
      onCancelProps(response)
    },
    onConfirm = e => {
      if (e?.preventDefault) {
        e.preventDefault()
      }
      seterrordata(null)
      seterror(null)
      setloading(true)
      onConfirmProps(response)
      postData(config, url, newvariables, method)
    },
    onSuccess = e => {
      if (e?.preventDefault) {
        e.preventDefault()
      }
      onSuccessProps(response)
    }
  const response = {
    data,
    message,
    loading,
    error,
    errordata,
    onChangeCapture,
    limit: newlimit,
    setlimit: setnewlimit,
    offset: newoffset,
    setoffset: setnewoffset,
  }
  useImperativeHandle(ref, () => response)
  useEffect(() => {
    if (error) {
      showSimpleToastApp(error, 'Fail')
    }
  }, [error])
  useEffect(() => {
    console.log('whensuccess, data', whensuccess, data)
    if (whensuccess && data?.status === 1) {
      onSuccess()
    }
    return () => {
      setdata(defaultData)
    }
  }, [modalRef, data, defaultData, whensuccess])
  const formdata = useMemo(() => {
    if (data?.status !== 1) {
      return newvariables
    }
    return variables
  }, [variables, newvariables, data])

  return (
    <Fragment>
      <VmView>
        <VmView className="flex flex-col gap-4">
          {/* Header */}
          <VmView className="flex p-2 justify-between items-center">
            {/* Title */}
            <VmText className="text-[#3B4054] font-bold">{title}</VmText>
            {/* Close */}
            {/* <VmView
              onClick={()=>modalRef.current?.close()}
              className="relative flex items-center top-0 right-0 bottom-0 cursor-pointer"
            >
              <VmIcons
                size={24}
                name="VmXCircleIcon"
                variant="outline"
                className="text-red5-payment"
              />
            </VmView> */}
          </VmView>
          {/* Additional Components */}
          <AdditionalComponents />
          {/* List Forms */}
          <TemplateListInputItems
            colors="text-slate-500 !text-sm"
            bgcolors="!bg-gray0-payment"
            data={formdata}
            lstinput={lstmenu}
            key={`lst-inpt-prd-rujukan`}
            onChangeCapture={onChangeCapture}
          />
          {children}
        </VmView>
        <VmView className="flex justify-between items-center flex-row-reverse">
          {!!canceltitle && (
            <VmButton
              className="px-5"
              color="danger"
              onClick={onCancel}
              rounded="full"
            >
              {canceltitle}
            </VmButton>
          )}
          {!!confirmtitle && (
            <VmButton
              className="px-5"
              color="primary"
              onClick={loading ? () => {} : onConfirm}
              rounded="full"
            >
              {loading ? <VmSpinner size="sm" color="white" /> : confirmtitle}
            </VmButton>
          )}
        </VmView>
      </VmView>
    </Fragment>
  )
}

export default forwardRef(FormContent)
