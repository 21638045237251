import React, {useEffect, useRef, useState} from 'react'
import VmForm from 'components/template/VmForm'
import classNames from 'classnames'
import {VmIcons, VmInput, VmModal, VmText, VmView} from 'components'
import VmInputCaption from 'components/VmInputCaption'
import AlertOk from 'components/AlertOk'
import axios from 'axios'
import QueryString from 'qs'
import {
  Route,
  Link,
  Routes,
  useParams,
  useSearchParams,
  Navigate,
  useLocation,
} from 'react-router-dom'
import {formatValue} from 'react-currency-input-field'
import {catatLog, empty, toCurrency} from 'utils/functions'
import {mUpdateBiayaPemeriksaan} from '../../graphql/mutations'
import {API, graphqlOperation} from 'aws-amplify'
import ModalItem from './ModalKodeBiayaBpjs/ModalItem'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'

const {
  REACT_APP_BASE_URL_DOKTER: APIDOC,
  REACT_APP_BASE_URL_UTAMA_PHP7: API7,
  REACT_APP_BASE_URL_SATUSEHAT: APISATUSEHAT,
} = process.env

export default function PrdBiayaPemeriksaanUbah(props) {
  const alert = useRef(null)

  const [alertProps, setAlertProps] = useState(defaultpropsalertform),
    [redirect, setRedirect] = useState(false)
  const [saving, setSaving] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [uid, setUid] = useState(searchParams.get('dctid'))
  const [device, setDevice] = useState('')
  const closeAlert = () => setAlertProps(defaultpropsalertform)

  const [initialLoading, setInitialLoading] = useState(true)
  const [oldData, setOldData] = useState({})

  const params = useParams()
  const location = useLocation()
  const custom = location.state?.custom

  // custom bpjs pcare
  const [customTindakanPcare, setCustomTindakanPcare] = useState('')
  const dataModalRef = useRef()
  const resetbiatarif = useRef()
  const entriesCustomPcare = custom?.['121']
    ? {
        key: 'biakodepbpjs',
        caption: 'Kode Biaya BPJS',
        type: 'custom',
        Component: () => (
          <>
            <VmText>Kode Biaya BPJS</VmText>
            <VmView
              onClick={() => {
                resetbiatarif.current = false
                dataModalRef.current.open()
              }}
              className="flex justify-between w-full border-[#e5e7eb] border rounded-xl p-2 mb-4 mt-1"
            >
              <VmText className="text-black1-payment">
                {oldData?.biakodebpjs
                  ? oldData?.biakodebpjs
                  : `${customTindakanPcare?.kdTindakan || ' '} - ${
                      customTindakanPcare?.nmTindakan || ' '
                    }`}
              </VmText>
              <VmIcons name="VmChevronDownIcon" size={18} className="w-10" />
            </VmView>
            <VmModal ref={dataModalRef}>
              <ModalItem
                uid={uid}
                onClose={() => dataModalRef.current.close()}
                onSelect={val => {
                  dataModalRef.current.close()
                  setCustomTindakanPcare(val)
                }}
              />
            </VmModal>
          </>
        ),
      }
    : {}

  const detectDevice = () => {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )

    setDevice(isMobile ? 'mobile' : 'web')
  }
  // console.log('params', params)
  useEffect(() => {
    detectDevice()
    // Component DId Mount
    axios
      .post(
        `${APIDOC}/biaya-v2/view?id=${params.id}`,
        QueryString.stringify({
          a: 'dk13',
          uid,
        })
      )
      .then(({data: res}) => {
        console.log('Data Perawatan / Tindakan', res)
        setOldData(res)
        setInitialLoading(false)

        if (res.length < 10) {
          this.setState({hasMoreItems: false})
        }
      })
      .catch(err => {
        console.log('Error get Data Perawatan / Tindakan', err.message)
      })
  }, [])

  let simpan = data => {
    let {bianama, biatarif, biadiskon, biaicdkode} = data

    biadiskon = biadiskon?.replace(',', '.')
    bianama = bianama.trim()
    biaicdkode = biaicdkode.trim()
    // console.log({bianama, biatarif, biadiskon}); return
    axios
      .post(
        `${APIDOC}/biaya-v2/update?id=${params.id}`,
        QueryString.stringify({
          uid,
          a: 'dk13',
          data: {
            bianama,
            biatarif,
            biadiskon,
            biaicdkode,
            biakodebpjs: customTindakanPcare?.kdTindakan || '',
          },
        })
      )
      .then(({data: res}) => {
        let resData = res
        if (!resData) {
          alert.current.show({
            message: 'Terjadi kesalahan saat Tambah Perawatan / Tindakan',
          })
          setSaving(false)
          return
        } else if (resData?.status != 1) {
          alert.current.show({
            message:
              resData?.message ||
              'Terjadi kesalahan saat Tambah Perawatan / Tindakan',
          })
          setSaving(false)
          return
        } else {
          let dataLog = {
            keterangan: `Mengubah data Biaya Pemeriksaan ${oldData.bianama}`,
            menu: 'Billing - Perawatan / Tindakan',
            mn_id: 23,
            url: 'prd-mas-biaya-pemeriksaan',
            before: JSON.stringify({
              Nama: oldData.bianama,
              Tarif: toCurrency(oldData.biatarif),
              Diskon: `${oldData.biadiskon}%`,
              'Kode ICD-9-CM': oldData.biaicdkode,
            }),
            after: JSON.stringify({
              Nama: bianama,
              Tarif: toCurrency(biatarif),
              Diskon: `${biadiskon}%`,
              'Kode ICD-9-CM': biaicdkode,
            }),
            kl_id: 19,
            // ip: publicIp,
            a: 'dk13',
            uid: uid,
            reg: 'db',
          }
          catatLog(dataLog).finally(() => setRedirect(true))
        }
        // console.log('logsuccess', res)
        // setRedirect(true)
      })
      .catch(err => {
        let errMsg = err.errors[0].message
        if (errMsg.includes('exit status 129')) {
          simpan(data)
        } else {
          alert.current.show({
            message: 'Terjadi kesalahan saat Update Perawatan / Tindakan',
          })
          console.log('Error mengubah Perawatan / Tindakan', errMsg)
        }
      })
  }
  let icd9cmValidation = data => {
    axios
      .post(
        `${APIDOC}/biaya-v2/is-valid-icd9cm?kode=${data.biaicdkode}`,
        QueryString.stringify({
          uid,
          a: 'dk13',
        })
      )
      .then(res => {
        let resData = res?.data
        if (resData?.data === 2) {
          setAlertProps({
            ...defaultpropsalertform,
            type: 3,
            visible: true,
            children: (
              <>
                {resData?.message ||
                  'Kode ICD-9-CM tidak valid, Apakah ingin memproses data?'}
              </>
            ),
            onConfirm: () => {
              // update Data ke Database
              setSaving(true)
              simpan(data)
            },
            onClose: closeAlert,
            onCancel: closeAlert,
          })
        } else {
          // update Data ke Database
          setSaving(true)
          simpan(data)
        }
      })
      .catch(err => {
        setAlertProps({
          ...defaultpropsalertform,
          type: 4,
          visible: true,
          children: <>{'Gagal memvalidasi data ICD-9-CM!'}</>,
          onConfirm: closeAlert,
          onClose: closeAlert,
          onCancel: closeAlert,
        })
      })
  }

  return (
    <>
      <AlertOk ref={alert} />

      {redirect ? (
        <Navigate
          to={`/prd-mas-biaya-pemeriksaan?dctid=${uid}`}
          replace={true}
        />
      ) : null}

      {!initialLoading ? (
        <VmForm
          headerTitle={'Ubah Data Perawatan / Tindakan'}
          onFabPress={() => {}}
          contentContainerClassName="px-4"
          footerOptions={{
            visible: true,
            btnSimpanTitle: 'Simpan',
            btnSimpanDisabled: saving ? true : false,
            btnBatalDisabled: saving ? true : false,
            btnBatalTitle: 'Batal',
            onCancel: () => {
              setRedirect(true)
            },
          }}
          fields={[
            {
              key: 'biaicdkode',
              caption: 'Kode ICD-9-CM',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: oldData.biaicdkode,
            },
            {
              key: 'bianama',
              caption: 'Nama',
              type: 'input',
              variant: 'text',
              _style: {fontWeight: 'bold'},
              className: '',
              defaultValue: oldData.bianama,
            },
            {...entriesCustomPcare},
            {
              key: 'biatarif',
              caption: 'Tarif',
              type: 'input',
              variant: 'autonumeric',
              allowDecimals: false,
              defaultValue: `${parseInt(oldData.biatarif)}`,
              customValue: state => {
                if (!!customTindakanPcare?.maxTarif && !resetbiatarif.current) {
                  resetbiatarif.current = true
                  return (state.biatarif = customTindakanPcare?.maxTarif)
                }
                if (!resetbiatarif.current) resetbiatarif.current = true

                return state.biatarif
              },
            },
            {
              mode: 'horizontal',
              content: [
                {
                  key: 'biadiskon',
                  caption: 'Diskon',
                  type: 'input',
                  variant: 'autonumeric',
                  defaultValue: oldData.biadiskon,
                  containerClassName: 'flex-1 mr-2',
                  max: 100,
                },
                {
                  key: 'bianominaldiskon',
                  caption: 'Nominal Diskon',
                  type: 'input',
                  variant: 'text',
                  defaultValue: '0',
                  containerClassName: 'flex-1 ml-2',
                  readOnly: true,
                  disabled: true,
                  customValue: state => {
                    let {biatarif, biadiskon} = state
                    // console.log('original', {biatarif, biadiskon})
                    if (empty(biatarif)) {
                      // Jika kosong, kasih default 0
                      biatarif = '0'
                    }
                    if (empty(biadiskon)) {
                      // Jika kosong, kasih default 0
                      biadiskon = '0'
                    }

                    let diskon = biadiskon.replace(',', '.')
                    let nominaldiskon =
                      (parseFloat(diskon) / 100) * parseFloat(biatarif)

                    let formatted = formatValue({
                      value: `${nominaldiskon}`,
                      groupSeparator: '.',
                      decimalSeparator: ',',
                      prefix: '',
                      decimalScale: 0,
                    })
                    // console.log({nominaldiskon, formatted})
                    return formatted
                  },
                },
              ],
            },
          ]}
          onSimpan={data => {
            // do Something
            const {bianama, biatarif, biadiskon} = data
            // Cek Data Kosong
            if (!bianama.length) {
              alert.current.show({message: 'Nama Biaya harus diisi'})
              return
            } else if (!biatarif) {
              alert.current.show({message: 'Tarif Biaya harus diisi'})
              return
            } else if (!biadiskon) {
              alert.current.show({message: 'Diskon Biaya harus diisi'})
              return
            }

            // Cek value minus
            if (parseFloat(biatarif) < 0) {
              alert.current.show({message: 'Tarif Biaya tidak boleh minus.'})
              return
            } else if (parseFloat(biadiskon) < 0) {
              alert.current.show({message: 'Diskon Biaya tidak boleh minus.'})
              return
            }
            if (data?.biaicdkode?.length > 0) {
              // Validasi Icd9cm
              icd9cmValidation(data)
            } else {
              // update Data ke Database
              setSaving(true)
              simpan(data)
            }
          }}
          device={device}
        ></VmForm>
      ) : null}

      {/* AlertForm */}
      <AlertForm {...alertProps} />
      {/* <VmInput className={classNames('m-1')} typeInput={'text'} placeholder={'Nama Biaya '}/> */}
    </>
  )
}
