export const dataFields = ['pasnama', 'nama_apotek', 'status_ambil']

export const filterFields = [
  {
    'Semua Status Pengambilan': [
      {value: '1', label: 'Sudah diambil'},
      {value: '25', label: 'Belum diambil'},
      {value: '26', label: 'Dibatalkan'},
    ],
    dbColumn: 'pjstatus',
  },
  {
    'Semua Status Insentif': [
      {value: '1', label: 'Belum dibayar'},
      {value: '2', label: 'Sudah dibayar'},
    ],
    dbColumn: 'pjdelivery',
  },
  {
    Sorting: [
      {label: 'Tanggal Pembayaran', dbColumn: 'pjtanggal'},
      {label: 'Nama Pasien', dbColumn: 'pasnama'},
      {label: 'No. Resep', dbColumn: 'pjresep'},
      {label: 'No. Faktur', dbColumn: 'pjnofaktur'},
    ],
  },
]

export const aliasFields = [
  {
    dbColumn: 'pjdelivery',
    alias: [
      {
        value: '1',
        label: 'Belum dibayar',
        color: 'text-red-500 bg-red-200',
      },
      {
        value: '2',
        label: 'Sudah dibayar',
        color: 'text-green-500 bg-green-200',
      },
    ],
  },
]
