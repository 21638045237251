import classNames from 'classnames'
import React from 'react'

export default function HStack({
  children,
  justify = 'center',
  align = 'center',
  className,
}) {
  return (
    <div
      className={classNames(
        `flex justify-${justify} items-${align}`,
        className
      )}
    >
      {children}
    </div>
  )
}
