import {
  VmUserIcon,
  VmEyeIcon,
  VmPhotoIcon,
  VmIdentificationIcon,
  VmUserGroupIcon,
  VmTrashIcon,
  VmPhoneIcon,
  VmHomeIcon,
  VmMapPinIcon,
  VmLockClosedIcon,
} from 'components/icons/outline'
import {VmText, VmView, VmInput, VmButton, VmMaps} from 'components'
import {VmHeader} from 'molecules'
import {VmPPDefaultIcon} from 'components/icons/avatar'
import classNames from 'classnames'
import {brokenImage} from 'utils/functions'
import {
  VmChevronDownIcon,
  VmMagnifyingGlassIcon,
  VmPlusIcon,
} from 'components/icons/solid'
import {Fragment, useRef, useState} from 'react'
import {PageMaps} from 'pages'
import {Marker} from '@react-google-maps/api'
import {eventTrigerInput, isFunction, isJsonString, isObject} from 'utils'

const hidering = '!ring-0 !ring-transparent !ring-offset-0'
const isRn = !!window?.ReactNativeWebView
const Index = ({
  showUbahProfil = true,
  showUbahAkun = false,
  showUbahPassword = false,
  showKodeBpjs = false,
  data = {},
  HeaderName = 'Menu',
  HeaderType = 'Ubah Profile',
}) => {
  return (
    <VmView className="w-screen h-screen UbahProfil flex flex-col bg-gray">
      <VmHeader
        HeaderName={HeaderName}
        HeaderType={HeaderType}
        className="absolute px-4 pt-4 w-full"
        classNameTitle="!pl-4"
      />
      <VmView className="Hero flex flex-col min-h-[35%] bg-blue-500 static" />
      {/* section content utama*/}
      <VmView className="flex flex-col p-3 -mt-16 flex-grow">
        <VmView className="flex flex-col bg-white rounded-xl shadow-xl flex-grow">
          {/* section content 1 */}
          <VmView className="flex mx-auto items-center relative">
            <VmView
              label="img-logo"
              className="w-40 h-40 overflow-hidden rounded-full border-8 bg-gray-300 border-gray-300 -mt-28"
            >
              {!!data?.logotoupload ||
              (!!data?.logo &&
                ['jpeg', 'jpg', 'png'].filter(fl => data?.logo?.includes(fl))
                  .length > 0) ? (
                <VmView
                  label={`img-logo`}
                  id="view-img-logo"
                  className="flex bg-cover bg-center border-xl"
                  style={{
                    backgroundRepeat: 'no-repeat',
                    height: '-webkit-fill-available',
                    backgroundImage: `url(${
                      data?.logotoupload ||
                      (!!data?.logo ? data?.logo : brokenImage)
                    })`,
                  }}
                />
              ) : (
                <VmPPDefaultIcon aria-label={'img-logo'} />
              )}
              {showUbahProfil && (
                <input
                  id="img-logo"
                  aria-label={'input-logo'}
                  type={isRn ? 'text' : 'file'}
                  readOnly={isRn}
                  className="hidden"
                />
              )}
            </VmView>
            {!!data?.logotoupload ||
            (!!data?.logo &&
              ['jpeg', 'jpg', 'png'].filter(fl => data?.logo?.includes(fl))
                .length > 0)
              ? showUbahProfil && (
                  <VmButton
                    aria-label="RemoveFoto"
                    className={classNames(
                      hidering,
                      'w-11 h-11 p-3.5 !bg-red-400 !rounded-full shadow-lg absolute right-0'
                    )}
                  >
                    <VmTrashIcon
                      aria-label="RemoveFoto"
                      className="w-full text-white "
                    />
                  </VmButton>
                )
              : showUbahProfil && (
                  <VmButton
                    aria-label="img-logo"
                    className={classNames(
                      hidering,
                      'w-11 h-11 p-3.5 !bg-blue-400 !rounded-full shadow-lg absolute right-0'
                    )}
                  >
                    <VmPlusIcon
                      aria-label="img-logo"
                      className="w-full text-white "
                    />
                  </VmButton>
                )}
          </VmView>
          {/* section content 2 */}
          <VmView className="flex flex-row w-full justify-center mt-3">
            {[
              {
                label: 'UbahProfil',
                title: 'Ubah Profil',
                active: showUbahProfil,
              },
              {
                label: 'UbahAkun',
                title: 'Ubah Akun',
                active: showUbahAkun,
              },
            ].map((im, index) => {
              const imclass = `${
                index !== 0 ? 'ml-4' : ''
              } shadow-lg !h-10 !px-4 !radius-lg`
              return (
                <VmButton
                  key={JSON.stringify(im)}
                  aria-label={im.label}
                  variant="ghost"
                  active={im.active}
                  className={imclass}
                >
                  {im.title}
                </VmButton>
              )
            })}
          </VmView>

          {showUbahAkun ? (
            <UbahAkun showUbahPassword={showUbahPassword} data={data} />
          ) : null}
          {showUbahProfil ? (
            <UbahProfil showKodeBpjs={showKodeBpjs} data={data} />
          ) : null}

          <VmView className="p-4 flex flex-grow items-end">
            <VmButton
              label="SimpanProfile"
              className={classNames(
                hidering,
                'shadow-lg max-h-20 bg-gradient-to-r from-orange-400 to-yellow-300 py-3 rounded-lg text-sm text-white block w-full'
              )}
            >
              Simpan
            </VmButton>
          </VmView>
        </VmView>
      </VmView>
    </VmView>
  )
}

const UbahProfil = ({data = null, showKodeBpjs = false}) => {
  if (data === null) {
    return <></>
  }
  const lstmenu = [
    {
      type: 1,
      title: 'Nama Lengkap',
      Icon: VmUserIcon,
      label: 'input-name',
      field: 'name',
      active: true,
      typeInput: 'text',
      maxLength: 100,
    },
    {
      type: 1,
      title: 'Spesialisasi',
      Icon: VmUserGroupIcon,
      label: 'input-spesialis',
      field: 'spesialis',
      active: true,
      typeInput: 'text',
      maxLength: 255,
    },
    {
      type: 1,
      title: 'No. KTP',
      Icon: VmIdentificationIcon,
      label: 'input-noktp',
      field: 'noktp',
      active: true,
      typeInput: 'text',
      maxLength: 255,
    },
    {
      type: 4,
      title: 'Kode Dokter BPJS',
      Icon: VmIdentificationIcon,
      label: 'select-dokkode',
      field: 'dokkode',
      active: showKodeBpjs,
      typeInput: 'text',
      className: 'cursor-pointer',
      classNameInput: 'cursor-pointer',
      maxLength: 255,
      inputprops: {readonly: true, placeholder: 'Pilih Kode Dokter BPJS'},
    },
    {
      type: 11,
      title: 'Id Satu Sehat',
      Icon: VmIdentificationIcon,
      label: 'input-nosatusehat',
      field: 'nosatusehat',
      active: true,
      typeInput: 'text',
      maxLength: 255,
      inputprops: {readonly: true, placeholder: 'Belum Ada Id Satu Sehat'},
      btnsearchtitle: 'Cari',
    },
    {
      type: 3,
      title: 'Scan KTP',
      Icon: VmUserIcon,
      label: 'input-fotoktp',
      field: 'fotoktp',
      active: true,
    },
    {
      type: 1,
      title: 'No. STR',
      Icon: VmIdentificationIcon,
      label: 'input-nostr',
      field: 'nostr',
      active: true,
      typeInput: 'text',
      maxLength: 255,
    },
    {
      type: 3,
      title: 'Scan STR',
      Icon: VmUserIcon,
      label: 'input-fotostr',
      field: 'fotostr',
      active: true,
    },

    {
      type: 1,
      title: 'No. SIP',
      Icon: VmIdentificationIcon,
      label: 'input-nosip',
      field: 'nosip',
      active: true,
      typeInput: 'text',
      maxLength: 255,
    },
    {
      type: 3,
      title: 'Scan SIP',
      Icon: VmUserIcon,
      label: 'input-fotosip',
      field: 'fotosip',
      active: true,
    },
    {
      type: 1,
      title: 'No. Whatsapp',
      Icon: VmPhoneIcon,
      label: 'input-nowa',
      field: 'nowa',
      active: true,
      typeInput: 'text',
      maxLength: 50,
    },
    {
      type: 1,
      title: 'Alamat',
      Icon: VmHomeIcon,
      label: 'input-address',
      field: 'address',
      active: true,
      typeInput: 'textarea',
      maxLength: 255,
    },
    {
      type: 4,
      title: 'Provinsi',
      Icon: VmMapPinIcon,
      label: 'select-province',
      field: 'province',
      active: true,
      typeInput: 'text',
      maxLength: 100,
    },
    {
      type: 4,
      title: 'Kota',
      Icon: VmMapPinIcon,
      label: 'select-city',
      field: 'city',
      active: true,
      typeInput: 'text',
      maxLength: 100,
    },
    {
      type: 4,
      title: 'Kecamatan',
      Icon: VmMapPinIcon,
      label: 'select-subdistrict',
      field: 'subdistrict',
      active: true,
      typeInput: 'text',
      maxLength: 100,
    },
    {
      type: 4,
      title: 'Kelurahan',
      Icon: VmMapPinIcon,
      label: 'select-kelurahan',
      field: 'kelurahan',
      active: true,
      typeInput: 'text',
      maxLength: 100,
    },
    {
      type: 1,
      title: 'RT',
      Icon: VmIdentificationIcon,
      label: 'input-rt',
      field: 'rt',
      active: true,
      typeInput: 'text',
      maxLength: 30,
    },
    {
      type: 1,
      title: 'RW',
      Icon: VmIdentificationIcon,
      label: 'input-rw',
      field: 'rw',
      active: true,
      typeInput: 'text',
      maxLength: 30,
    },
    {
      type: 1,
      title: 'Kode Pos',
      Icon: VmIdentificationIcon,
      label: 'input-kodepos',
      field: 'kodepos',
      active: true,
      typeInput: 'text',
      maxLength: 100,
      bindValue: true,
    },
    {
      type: 12,
      title: 'Pilih Titik Alamat',
      Icon: VmMapPinIcon,
      label: 'input-position',
      field: 'position',
      active: true,
      typeInput: 'text',
      maxLength: 100,
      mapprops: {
        zoom: 12,
        showCurrentLocation: false,
        nearbysearch: false,
        useSelectMapOnClick: true,
        containerStyle: {
          width: 'auto',
          height: '10rem',
        },
        nearbysearchoptions: {
          type: undefined,
        },
        searchboxoptions: {
          types: [
            'accounting',
            'airport',
            'amusement_park',
            'aquarium',
            'art_gallery',
            'atm',
            'bakery',
            'bank',
            'bar',
            'beauty_salon',
            'bicycle_store',
            'book_store',
            'bowling_alley',
            'bus_station',
            'cafe',
            'campground',
            'car_dealer',
            'car_rental',
            'car_repair',
            'car_wash',
            'casino',
            'cemetery',
            'church',
            'city_hall',
            'clothing_store',
            'convenience_store',
            'courthouse',
            'dentist',
            'department_store',
            'doctor',
            'drugstore',
            'electrician',
            'electronics_store',
            'embassy',
            'fire_station',
            'florist',
            'funeral_home',
            'furniture_store',
            'gas_station',
            'gym',
            'hair_care',
            'hardware_store',
            'hindu_temple',
            'home_goods_store',
            'hospital',
            'insurance_agency',
            'jewelry_store',
            'laundry',
            'lawyer',
            'library',
            'light_rail_station',
            'liquor_store',
            'local_government_office',
            'locksmith',
            'lodging',
            'meal_delivery',
            'meal_takeaway',
            'mosque',
            'movie_rental',
            'movie_theater',
            'moving_company',
            'museum',
            'night_club',
            'painter',
            'park',
            'parking',
            'pet_store',
            'pharmacy',
            'physiotherapist',
            'plumber',
            'police',
            'post_office',
            'primary_school',
            'real_estate_agency',
            'restaurant',
            'roofing_contractor',
            'rv_park',
            'school',
            'secondary_school',
            'shoe_store',
            'shopping_mall',
            'spa',
            'stadium',
            'storage',
            'store',
            'subway_station',
            'supermarket',
            'synagogue',
            'taxi_stand',
            'tourist_attraction',
            'train_station',
            'transit_station',
            'travel_agency',
            'university',
            'veterinary_care',
            'zoo',
          ],
        },
      },
    },
  ]
  return (
    <VmView className="flex flex-col w-full mt-3 overflow-y-scroll px-2 max-h-[45vh]">
      {/* inputan - section content 3*/}
      {lstmenu.map((im, index) => {
        if (im.active) {
          return (
            <RenderField
              key={`RenderField-i-${index}`}
              item={im}
              data={data}
              index={index}
            />
          )
        }
      })}
      {/* akhir inputan - section content 3*/}
    </VmView>
  )
}

const RenderField = ({item = {}, data = {}, index}) => {
  const imInputRef = useRef([])
  const [mapstate, setmapstate] = useState({})
  const [mapsvisible, setmapsvisible] = useState(false),
    key = JSON.stringify({t: item?.title, index})
  if (item.type === 12) {
    const mapprops = Object.assign(
      {
        containerStyle: Object.assign(item?.mapprops?.containerStyle || {}, {
          marginBottom: '-24px',
        }),
      },
      item?.mapprops || {}
    )
    return (
      <>
        <VmView
          key={key}
          className="relative flex flex-nowrap flex-col p-2 justify-between"
          ref={() => {
            if (
              !!data[`${item.field}`] &&
              !mapstate[`${item.field}-${index}`]
            ) {
              var datamap = {}
              if (isJsonString(data[`${item.field}`])) {
                datamap = JSON.parse(data[`${item.field}`])
              }
              setmapstate({
                ...mapstate,
                [`${item.field}-${index}`]: datamap,
              })
            }
            if (!mapstate[`${item.field}-${index}-props`]) {
              setmapstate({
                ...mapstate,
                [`${item.field}-${index}-props`]: mapprops,
              })
            }
          }}
        >
          {item?.title && (
            <VmText className="text-xs text-blue-500 mb-1">{item.title}</VmText>
          )}
          <VmView className="overflow-hidden rounded-md">
            {!!mapstate[`${item.field}-${index}`] && (
              <VmMaps
                {...{
                  ...mapprops,
                  center: mapstate[`${item.field}-${index}`].center,
                }}
              >
                <Marker position={mapstate[`${item.field}-${index}`].center} />
              </VmMaps>
            )}
          </VmView>
          <VmButton
            hidering={true}
            className={classNames(
              !!mapstate[`${item.field}-${index}`]
                ? 'absolute inset-0 !bg-transparent !text-transparent'
                : 'w-max',
              '!rounded-md text-sm px-4 py-2 flex flex-row items-center'
            )}
            onClick={async () => {
              var datamap = {}
              if (isJsonString(data[`${item.field}`])) {
                datamap = JSON.parse(data[`${item.field}`])
              }
              if (!datamap?.result && datamap?.center && window?.google?.maps) {
                // Geocoding services
                const geocoder = new window.google.maps.Geocoder()
                await geocoder.geocode(
                  {location: datamap?.center},
                  (results, status) => {
                    if (status === 'OK') {
                      datamap['result'] = results[0]
                      setmapstate({
                        ...mapstate,
                        [`${item.field}-${index}`]: datamap,
                      })
                    }
                  }
                )
              }
              setmapsvisible(`${item.field}-${index}`)
            }}
          >
            <VmMapPinIcon
              className={classNames('h-4 w-4 mr-1')}
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
            {item?.placeholder || 'Pilih Titik'}
          </VmButton>
          <input
            type="text"
            className="hidden"
            aria-label={`${item.label}`}
            ref={ref => (imInputRef.current[`${item.field}-${index}`] = ref)}
          />
        </VmView>
        {!!mapsvisible && (
          <PageMaps
            selectedMarker={mapstate[mapsvisible]}
            useSelectMapOnClick={
              mapstate[`${mapsvisible}-props`]?.useSelectMapOnClick
            }
            nearbysearch={mapstate[`${mapsvisible}-props`]?.nearbysearch}
            nearbysearchoptions={
              isObject(mapstate[`${mapsvisible}-props`]?.nearbysearchoptions)
                ? mapstate[`${mapsvisible}-props`].nearbysearchoptions
                : {}
            }
            searchboxoptions={
              isObject(mapstate[`${mapsvisible}-props`]?.searchboxoptions)
                ? mapstate[`${mapsvisible}-props`].searchboxoptions
                : {}
            }
            center={mapstate[mapsvisible]?.center}
            onBackPress={(event, data) => {
              if (
                !!data &&
                event?.target?.innerText ===
                  (item?.mapprops?.selectMarkerText || 'Pilih')
              ) {
                const {position} = data
                const center = isFunction(position?.lat)
                  ? {
                      lat: position.lat(),
                      lng: position.lng(),
                    }
                  : position
                const newdata = {
                  ...mapstate,
                  [mapsvisible]: {...data, center, position: center},
                }
                setmapstate(newdata)
                const input = imInputRef.current[mapsvisible]
                eventTrigerInput(input, JSON.stringify(center))
              }
              setmapsvisible(false)
            }}
          />
        )}
      </>
    )
  }
  return renderField(item, index, data)
}

// Fungsi untuk render Field Elemen by object map.
export const renderField = (im, index, data) => {
  const ImIcon = im.Icon,
    key = JSON.stringify({t: im.title, index}),
    inputprops = im?.inputprops || {}
  if (im.type === 3) {
    return (
      <VmView
        key={key}
        className="flex flex-nowrap flex-col p-2 justify-between"
      >
        <VmText className="text-xs text-blue-500">{im.title}</VmText>
        {(!!data[`${im.field}-to-upload`]
          ? data[`${im.field}-to-upload`]
          : !!data[im.field] &&
            ['jpeg', 'jpg', 'png'].filter(fl => data[im.field]?.includes(fl))
              .length > 0
          ? data[im.field]
          : false) && (
          <VmView className="flex flex-row mt-1">
            <VmView
              id={`img-${im.field}`}
              label={`img-${im.field}`}
              className="flex w-4/5 bg-contain bg-center border-xl hover:bg-contain"
              style={{
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${
                  !!data[`${im.field}-to-upload`]
                    ? data[`${im.field}-to-upload`]
                    : !!data[im.field]
                    ? data[im.field]
                    : brokenImage
                })`,
              }}
            />
            {(!!data[`${im.field}-to-upload`] || !!data[im.field]) && (
              <VmView className="flex w-1/5 ml-2 flex-col justify-end mb-2">
                <VmButton
                  label={`view-${im.label}`}
                  className={classNames(
                    hidering,
                    'w-11 h-11 p-3.5 !bg-white rounded-lg shadow-lg mb-2'
                  )}
                >
                  <VmEyeIcon
                    aria-label={`view-${im.label}`}
                    titleId={`view-${im.label}`}
                    className="w-full text-blue-500 "
                  />
                </VmButton>
                <VmButton
                  label={`remove-${im.label}`}
                  className={classNames(
                    hidering,
                    'w-11 h-11 p-3.5 !bg-red-400 rounded-lg shadow-lg'
                  )}
                >
                  <VmTrashIcon
                    aria-label={`remove-${im.label}`}
                    titleId={`remove-${im.label}`}
                    className="w-full text-white "
                  />
                </VmButton>
              </VmView>
            )}
          </VmView>
        )}
        <VmView className="flex flex-row mt-1">
          <VmView className="p-3.5 h-12 w-12 bg-gray-200 rounded-l-md border-none text-blue-500">
            <VmPhotoIcon
              className="w-full h-full"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </VmView>
          <input
            maxLength={im.maxLength}
            aria-label={im.label}
            type={isRn ? 'text' : 'file'}
            readOnly={isRn}
            className="items-center transparent-items center-items w-full h-12 rounded-l-none border-none bg-gray-200 leading-10"
          />
        </VmView>
      </VmView>
    )
  }
  if (im.type === 4) {
    return (
      <VmView
        key={key}
        className="flex flex-nowrap flex-col p-2 justify-between"
      >
        <VmText className="text-xs text-blue-500">{im.title}</VmText>
        <VmView className="flex flex-row mt-1 bg-gray-200 rounded-md">
          <VmView
            className={classNames(
              im.typeInput === 'textarea' ? 'h-12' : 'h-12',
              'p-3.5 w-12 rounded-l-md border-none text-blue-500'
            )}
          >
            <ImIcon
              className={classNames(
                im.typeInput === 'textarea' ? '' : 'h-full',
                'w-full'
              )}
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </VmView>
          <VmInput
            readonly={true}
            maxLength={im.maxLength}
            typeInput={im.typeInput}
            label={im.label}
            className={classNames(
              im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
              hidering,
              'transparent-items center-items bg-transparent w-full rounded-none border-none'
            )}
            key={!!data[im.field] && data[im.field] != 0 ? data[im.field] : '-'}
            value={
              !!data[im.field] && data[im.field] != 0 ? data[im.field] : '-'
            }
          />
          <VmView
            label={im.label}
            className={classNames(
              im.typeInput === 'textarea' ? 'h-12' : 'h-12',
              'p-3.5 w-12 rounded-r-md border-none text-blue-500'
            )}
          >
            <VmChevronDownIcon
              className={classNames(
                im.typeInput === 'textarea' ? '' : 'h-full',
                'w-full'
              )}
              aria-label={im.label}
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </VmView>
        </VmView>
      </VmView>
    )
  }
  if (im.type === 1) {
    return (
      <VmView
        key={key}
        className="flex flex-nowrap flex-col p-2 justify-between"
      >
        <VmText className="text-xs text-blue-500">{im.title}</VmText>
        <VmView className="flex flex-row mt-1 bg-gray-200 rounded-md">
          <VmView
            className={classNames(
              im.typeInput === 'textarea' ? 'h-12' : 'h-12',
              'p-3.5 w-12 rounded-l-md border-none text-blue-500'
            )}
          >
            <ImIcon
              className={classNames(
                im.typeInput === 'textarea' ? '' : 'h-full',
                'w-full'
              )}
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </VmView>
          <VmInput
            maxLength={im.maxLength}
            typeInput={im.typeInput}
            label={im.label}
            className={classNames(
              im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
              'transparent-items center-items bg-transparent w-full rounded-l-none border-none'
            )}
            // defaultValue={!!data[im.field] ? data[im.field] : '-'}
            {...Object.assign(
              inputprops,
              im.bindValue
                ? {
                    value: !!data[im.field] ? data[im.field] : '-',
                  }
                : {
                    defaultValue: !!data[im.field] ? data[im.field] : '-',
                  }
            )}
          />
        </VmView>
      </VmView>
    )
  }
  if (im.type === 11) {
    return (
      <VmView
        key={key}
        className="flex flex-nowrap flex-col p-2 justify-between"
      >
        <VmText className="text-xs text-blue-500">{im.title}</VmText>
        <VmView className="flex flex-row mt-1 bg-gray-200 rounded-md">
          <VmView
            className={classNames(
              im.typeInput === 'textarea' ? 'h-12' : 'h-12',
              'p-3.5 w-12 rounded-l-md border-none text-blue-500'
            )}
          >
            <ImIcon
              className={classNames(
                im.typeInput === 'textarea' ? '' : 'h-full',
                'w-full'
              )}
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
            />
          </VmView>
          <VmInput
            maxLength={im.maxLength}
            typeInput={im.typeInput}
            label={im.label}
            className={classNames(
              im.typeInput === 'textarea' ? 'h-24 pt-3' : 'h-12',
              'transparent-items center-items bg-transparent w-full rounded-none border-none'
            )}
            defaultValue={!!data[im.field] ? data[im.field] : ''}
            {...Object.assign(
              inputprops,
              im.bindValue
                ? {
                    value: !!data[im.field] ? data[im.field] : '',
                  }
                : {
                    defaultValue: !!data[im.field] ? data[im.field] : '',
                  }
            )}
          />
          <VmButton
            aria-label={`search${im?.label ? `-${im.label}` : ''}`}
            className={classNames(
              hidering,
              'h-12 w-auto !rounded-md flex items-center'
            )}
          >
            {im?.btnsearchtitle && (
              <VmView
                aria-label={`search${im?.label ? `-${im.label}` : ''}`}
                className="mr-2"
              >
                {im?.btnsearchtitle}
              </VmView>
            )}
            <VmMagnifyingGlassIcon
              aria-label={`search${im?.label ? `-${im.label}` : ''}`}
              className="w-6 h-6 text-white "
              strokeWidth="3"
            />
          </VmButton>
        </VmView>
      </VmView>
    )
  }
  return <Fragment key={key} />
}

// eslint-disable-next-line
const UbahAkun = ({data = null, showUbahPassword = false}) => {
  if (data === null) {
    return <></>
  }
  const lstmenu = [
    {
      type: 1,
      title: 'Email',
      Icon: VmUserIcon,
      label: 'input-email',
      field: 'email',
      typeInput: 'email',
      maxLength: 255,
    },
    {
      type: 1,
      title: 'Username',
      Icon: VmUserIcon,
      label: 'input-username',
      field: 'username',
      typeInput: 'text',
      maxLength: 255,
    },
    {
      type: 2,
      title: 'Ubah Password',
      Icon: VmUserIcon,
      label: 'combo-password',
      checked: showUbahPassword,
      field: '',
      typeInput: '',
      maxLength: 255,
    },
    {
      type: 1,
      title: 'Password Baru',
      Icon: VmLockClosedIcon,
      label: 'input-new-password',
      field: 'password-new-password',
      typeInput: 'password',
      maxLength: 255,
    },
    {
      type: 1,
      title: 'Konfirmasi Password Baru',
      Icon: VmLockClosedIcon,
      label: 'input-confirm-new-password',
      field: 'password-confirm-new-password',
      typeInput: 'password',
      maxLength: 255,
    },
    {
      type: 1,
      title: 'Password',
      Icon: VmLockClosedIcon,
      label: 'input-password',
      field: 'password',
      typeInput: 'password',
      maxLength: 255,
    },
  ]
  return (
    <VmView className="flex flex-col w-full mt-3 overflow-y-scroll px-2 max-h-[45vh]">
      {/* inputan - section content 3*/}
      {lstmenu.map((im, index) => {
        const ImIcon = im.Icon,
          key = JSON.stringify({t: im.title, index})
        if (
          !showUbahPassword &&
          ['password-new-password', 'password-confirm-new-password'].includes(
            im.field
          )
        ) {
          return <VmView key={key} />
        }
        if (im.type === 2) {
          return (
            <VmView
              key={key}
              className="flex flex-nowrap flex-row p-2 pb-0 items-center"
            >
              <VmText className="text-xs text-blue-500">{im.title}</VmText>
              <VmInput
                maxLength={im.maxLength}
                typeInput={'checkbox'}
                label={im.label}
                className="ml-2"
                defaultChecked={im?.checked}
              />
            </VmView>
          )
        }
        return (
          <VmView
            key={key}
            className="flex flex-nowrap flex-col p-2 justify-between"
          >
            <VmText className="text-xs text-blue-500">{im.title}</VmText>
            <VmView className="flex flex-row mt-1">
              <VmView className="p-3.5 h-12 w-12 bg-gray-200 rounded-l-md border-none text-blue-500">
                <ImIcon
                  className="w-full h-full"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                />
              </VmView>
              <VmInput
                maxLength={im.maxLength}
                typeInput={im.typeInput}
                label={im.label}
                className="transparent-items center-items w-full h-12 rounded-l-none border-none bg-gray-200"
                defaultValue={!!data[im.field] ? data[im.field] : ''}
              />
            </VmView>
          </VmView>
        )
      })}
      {/* akhir inputan - section content 3*/}
    </VmView>
  )
}

export default Index
