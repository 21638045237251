import classNames from 'classnames'
import {VmButton} from 'components/button'
import React, {useState} from 'react'
import {toCurrency} from 'utils/functions'
import Metode from './Metode'

const vaMap = {
  mandiri: {
    nama: 'MANDIRI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka aplikasi Mandiri Online dan login menggunakan USERNAME dan PASSWORD Anda',
      'Pilih "Bayar"',
      'Pilih "Multipayment"',
      'Pilih Xendit 88608 atau Xendit 88908 sebagai penyedia jasa, sesuai dengan 5 digit awal dari Nomor Virtual Account yang tampil',
      'Masukkan Nomor Virtual Account yang tampil',
      'Tekan Lanjut',
      'Tinjau dan konfirmasi detail transaksi anda, lalu tekan Konfirmasi',
      'Selesaikan transaksi dengan memasukkan MPIN Anda',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  bri: {
    nama: 'BRI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka aplikasi BRI Mobile Banking dan login menggunakan USER ID dan PIN Anda',
      'Pilih "Pembayaran" dan pilih "Briva"',
      'Masukkan Nomor Virtual Account yang tampil',
      'Masukkan PIN Mobile Banking BRI',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  bni: {
    nama: 'BNI VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password Anda',
      'Pilih Menu "Transfer"',
      'Pilih Menu "Virtual Account Billing" kemudian pilih rekening debet',
      'Masukkan Nomor Virtual Account yang tampil',
      'Konfirmasi transaksi Anda',
      'Masukkan Password Transaksi',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  permata: {
    nama: 'PERMATA VIRTUAL ACCOUNT',
    langkah: [
      'Klik tombol "Bayar" untuk melanjutkan proses',
      'Selanjutnya akan tampil Nomor Virtual Account',
      'Buka Aplikasi PermataMobile Internet dan login menggunakan User ID dan Password Anda',
      'Pilih "Pembayaran Tagihan"',
      'Pilih "Virtual Account"',
      'Masukkan Nomor Virtual Account yang tampil',
      'Masukkan otentikasi transaksi/token',
      'Jika pembayaran berhasil di verifikasi maka perpanjangan / upgrade sudah selesai, Anda bisa login kembali ke domain Anda',
    ],
  },
  qris: {
    nama: 'Generate QRIS',
    langkah: [
      'Pastikan kamu memiliki aplikasi pembayaran bank atau e-wallet yang mendukung QRIS.',
      'Setelah klik “Bayar”, kamu akan menerima QR code.',
    ],
  },
}
export default function ModalPembayaran({
  visible,
  onClose,
  selectedPaket,
  ...props
}) {
  const [selectedMethod, setSelectedMethod] = useState('')

  let hargaFinal = (selectedPaket.pktHarga - selectedPaket.pktDiskon).toString()
  return (
    <div
      className={classNames('relative z-10', {
        hidden: !visible,
        block: visible,
      })}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      {/* <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 sm:items-center sm:p-0">
          {/* <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      --> */}

          <div
            id="modal-content"
            className="flex-1 mx-0 relative bg-white rounded-xl shadow"
          >
            {/* <!-- Modal header --> */}
            <div className="flex justify-between items-center px-6 py-4 border-b rounded-t">
              <h3 className="text-base font-semibold text-gray-900 lg:text-lg">
                Pilih Metode Pembayaran
              </h3>
              <button
                onClick={_ => onClose?.()}
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-xl text-sm p-1.5 ml-auto inline-flex items-center"
                data-modal-toggle="crypto-modal"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className="p-6">
              {(() => {
                if (selectedMethod == '') {
                  return (
                    <>
                      <p className="text-sm font-bold text-gray-500">
                        Bank Transfer
                      </p>
                      <ul className="my-4 space-y-3">
                        <Metode
                          imgUrl="https://register.vmedis.com/foto-bayar/mandiri.png"
                          name={'Mandiri Virtual Account'}
                          onClick={_ => {
                            setSelectedMethod('mandiri')
                          }}
                        />
                        <Metode
                          imgUrl="https://register.vmedis.com/foto-bayar/bri.png"
                          name={'BRI Virtual Account'}
                          onClick={_ => {
                            setSelectedMethod('bri')
                          }}
                        />
                        <Metode
                          imgUrl="https://register.vmedis.com/foto-bayar/bni.png"
                          name={'BNI Virtual Account'}
                          onClick={_ => {
                            setSelectedMethod('bni')
                          }}
                        />
                        <Metode
                          imgUrl="https://register.vmedis.com/foto-bayar/permata.png"
                          name={'Permata Virtual Account'}
                          onClick={_ => {
                            setSelectedMethod('permata')
                          }}
                        />
                        {/* <Metode imgUrl="" name={'Mandiri Virtual Account'} /> */}
                      </ul>

                      <p className="text-sm font-bold text-gray-500">QRIS</p>
                      <ul className="my-4 space-y-3">
                        <Metode
                          imgUrl="https://register.vmedis.com/foto-bayar/qris.png"
                          name={'QRIS'}
                          onClick={_ => {
                            setSelectedMethod('qris')
                          }}
                        />
                      </ul>
                    </>
                  )
                } else {
                  let selectedMethodMap = vaMap[selectedMethod]
                  return (
                    <div id="pembayaran_all">
                      <h5 className="">
                        <b id="nama_pembayaran">{selectedMethodMap.nama}</b>
                      </h5>

                      <table
                        className=""
                        border="0"
                        width="100%"
                        cellSpacing="0"
                        cellPadding="0"
                      >
                        {/* <tbody> */}
                        {/* <tr> */}
                        {/* <td style={{padding: '5px 0px 5px 0px'}}> */}
                        {/* <table
                                  border="0"
                                  width="100%"
                                  cellSpacing="0"
                                  cellPadding="0"
                                > */}
                        <tbody>
                          <tr>
                            <td
                              style={{
                                width: '30%',
                                fontSize: '100%',
                                padding: '12px 0px 0px 0px',
                              }}
                            >
                              {' '}
                              <p className="text-sm">Nama Dokter</p>
                            </td>
                            <td
                              style={{
                                width: '2%',
                                fontSize: '100%',
                                padding: '12px 0px 0px 0px',
                              }}
                            >
                              {' '}
                              <p className="text-sm">:</p>
                            </td>
                            <td
                              style={{
                                fontSize: '100%',
                                padding: '12px 0px 0px 0px',
                              }}
                            >
                              {' '}
                              <p className="text-sm">dr. Wahyu Setiyo Nurani</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{width: '30%', fontSize: '100%'}}>
                              {' '}
                              <p className="text-sm">Paket Yang Di Pilih</p>
                            </td>
                            <td style={{width: '2%', fontSize: '100%'}}>
                              {' '}
                              <p className="text-sm">:</p>
                            </td>
                            <td style={{fontSize: '100%'}}>
                              {' '}
                              <p className="text-sm">{selectedPaket.pktNama}</p>
                            </td>
                          </tr>
                          <tr>
                            <td style={{width: '30%', fontSize: '100%'}}>
                              {' '}
                              <p className="text-sm">Harga Paket</p>
                            </td>
                            <td style={{width: '2%', fontSize: '100%'}}>
                              {' '}
                              <p className="text-sm">:</p>
                            </td>
                            <td style={{fontSize: '100%'}}>
                              <p className="text-sm">
                                {' '}
                                <b>{toCurrency(hargaFinal)}</b>{' '}
                                <span
                                  style={{
                                    textDecoration: 'line-through red',
                                  }}
                                >
                                  {toCurrency(selectedPaket.pktHarga)}
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                        {/* </table> */}
                        {/* </td> */}
                        {/* </tr> */}
                        {/* </tbody> */}
                      </table>

                      <div
                        id="pem_manual"
                        data-pjax-container=""
                        data-pjax-timeout="1000"
                        className="mt-4 bg-slate-100 p-6 rounded-xl"
                      >
                        {/* Step Langkah-langkah  */}
                        <ul
                          style={{
                            // marginLeft: '-25px',
                            listStyleType: 'lower-number',
                          }}
                        >
                          {selectedMethodMap.langkah.map((item, i) => {
                            return (
                              <li className="text-sm" key={i}>
                                {item}
                              </li>
                            )
                          })}
                        </ul>
                      </div>

                      {/* Footer Section */}
                      <div className="gg mt-6">
                        <div className="flex justify-between">
                          <h5 className="" style={{marginBottom: ''}}>
                            Total Tagihan
                          </h5>
                          <h2
                            style={{
                              marginBottom: '',
                              textAlign: 'right',
                            }}
                            id="manual_totbayar"
                            className="text-xl"
                          >
                            {toCurrency(hargaFinal)}
                          </h2>
                        </div>

                        <div className="flex justify-between">
                          <VmButton
                            variant=""
                            color="success"
                            className="p-2 px-4"
                            onClick={() => {}}
                          >
                            Bayar
                          </VmButton>

                          <VmButton
                            variant=""
                            color="danger"
                            className="p-2 px-4"
                            onClick={() => {
                              setSelectedMethod('')
                            }}
                          >
                            Kembali
                          </VmButton>
                        </div>
                      </div>
                    </div>
                  )
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
