import moment from 'moment'
import {isArray} from 'utils'

export const formCashier = data => {
  const head = {
    pid: data?.pid || 0,
    kid: data?.kid || 0,
    dctid: data?.dctid || 0,
    polid: data?.polid || 0,
    shfid: data?.shfid || 0,
    labid: data?.labid || 0,
    katid: data?.katid || 0,
    tanggal: data?.tanggal || '',
    nofaktur: data?.nofaktur || '',
    diskon: data?.disc || 0,
    diskontext: data?.nominaldisc || 0,
    pajak: data?.pajak || 0,
    pajaktext: data?.pajaktext || 0,
    fakturpajak: data?.fakturpajak || '',
    jenis: data?.jenis || '',
    tunai: data?.tunai || 0,
    dp: data?.dp || 0,
    deadline: data?.deadline || '',
    hari: data?.deadline
      ? `${moment(data?.deadline).diff(moment(), 'days') + 1}`
      : '',
    kartucash: data?.kartucash || 0,
    kartu: data?.kartu || 0,
    ket: data?.ket || '',
    status: data?.status || '',
    nojaminan: data?.nojaminan || '',
    resep: data?.resep || '',
    pending: data?.pending || 0,
    promo: data?.promo || 0,
    nominalpromo: data?.nominalpromo || 0,
    diskonpromo: data?.diskonpromo || 0,
  }
  var chart = []
  if (isArray(data?.chart)) {
    var array = data.chart
    for (let i = 0; i < array.length; i++) {
      const elem = array[i]
      const newelem = {
        biaid: parseInt(elem?.biaid || 0),
        obatid: elem?.obatid || 0,
        soid: elem?.soid || 0,
        gudid: elem?.gudid || 0,
        labdid: elem?.labdid || 0,
        jumlah: elem?.value || 1,
        konversi: elem?.konversi || 1,
        hargabeli: elem?.hargabeli || 0,
        hpp: elem?.hpp || 0,
        harganormal: elem?.price || 0,
        harga: elem?.price || 0,
        subtotal: (elem?.price || 0) * (elem?.value || 1),
        diskon: parseInt(elem?.disc) || 0,
        diskontext: parseInt(elem?.nominaldisc) || 0,
        pajak: elem?.pajak || 0,
        pajaktext: elem?.pajaktext || 0,
        total:
          ((elem?.price || 0) - (parseInt(elem?.nominaldisc) || 0)) *
          (elem?.value || 1),
        feedokter: elem?.feedokter || 0,
        feedoktertext: elem?.feedoktertext || 0,
        retfeedoktertext: elem?.retfeedoktertext || 0,
        feedoktercek: elem?.feedoktercek || 0,
        feeasisten: elem?.feeasisten || 0,
        feeasistentext: elem?.feeasistentext || 0,
        retfeeasistentext: elem?.retfeeasistentext || 0,
        feeasistencek: elem?.feeasistencek || 0,
        feeper1: elem?.feeper1 || 0,
        feeper1text: elem?.feeper1text || 0,
        retfeeper1text: elem?.retfeeper1text || 0,
        feeper1cek: elem?.feeper1cek || 0,
        feeper2: elem?.feeper2 || 0,
        feeper2text: elem?.feeper2text || 0,
        retfeeper2text: elem?.retfeeper2text || 0,
        feeper2cek: elem?.feeper2cek || 0,
        feeklinik: elem?.feeklinik || 0,
        retfeekliniktext: elem?.retfeekliniktext || 0,
        feekliniktext: elem?.feekliniktext || 0,
        st: elem?.st || 0,
        resep: elem?.resep || 0,
        tuslah: elem?.tuslah || 0,
        demba: elem?.demba || 0,
        ket: elem?.ket || 0,
        flax: elem?.flax || 0,
        flaxR: elem?.flaxR || 0,
        hrgid: elem?.hrgid || 0,
        poin: elem?.poin || 0,
        nama: elem?.name || '-',
        expired: elem?.expired || null,
        nobatch: elem?.nobatch || '',
        racikan: elem?.racikan || 0,
        hrgcombo: elem?.hrgcombo || 0,
        aturan: elem?.aturan || 0,
        bentuk: elem?.bentuk || '',
        waktu: elem?.waktu || 0,
        catatan: elem?.catatan || '',
      }
      chart.push(newelem)
    }
  }

  return {
    head,
    chart,
  }
}

const modelData = ({key, data}) => {
  const reg = 'dbdok'
  switch (key) {
    case 'insert':
      return {
        req: JSON.stringify({
          ep: data._ldata,
          app: data.app,
          reg,
          data: {},
        }),
      }
    case 'patient-visit-info':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          id: data?.id,
          pid: data?.pid,
          dctid: data?.dctid,
          reg,
        }),
      }
    case 'user-info':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          id: data?.id,
          reg,
        }),
      }
    case 'insert-payment':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          id: data?.id,
          reg,
          data: formCashier(data),
        }),
      }
    case 'form':
      return formCashier(data)
    case 'cetak':
      return {
        req: JSON.stringify({
          ep: data?._ldata,
          id: data?.id,
          dctid: data?.dctid,
          reg,
        }),
      }
    default:
      return null
  }
}

export {modelData}
