import moment from 'moment'

export const pasienEmpty = {
  parentid: 0,
  parentrm: '',
  parentnama: '',
  statussambung: 0,
  pasnama: '',
  pasrm: '',
  pasid: 0,
  pasjaminan: '',
  pasalamat: '',
  pasjk: 'Laki-laki',
  pastgllahir: moment().format('YYYY-MM-DD'),
  pasnobpjs: '',
  pasnokk: '',
  pasnoktp: '',
  pasgoldarah: '',
  umur: '',
  alamat: '',
  provinsi: {
    id: '',
    nama: '',
  },
  kota: {
    id: '',
    nama: '',
  },
  kecamatan: {
    id: '',
    nama: '',
  },
  pastelp: '',
  paspekerjaan: '',
  pasemail: '',
  pasalergi: '',
  tahun: '0',
  bulan: '0',
  hari: '0',
}

export const pasienDummy = {
  parentid: 176,
  parentrm: 'RM2308310006',
  parentnama: 'Melinda Syahrani',
  statussambung: '1',
  pasnama: 'Muhammad Khoiron MM',
  pasrm: '',
  pasid: 0,
  pasjaminan: '',
  pasalamat: 'Semolowaru Utara Rt 03 Rw 04',
  pasjk: 'Laki-laki',
  pastgllahir: '2001-08-11',
  pasnobpjs: '88992271118271',
  pasnokk: '3515171882291',
  pasnoktp: '3515171889901',
  pasgoldarah: 'A+',
  pasumur: '',
  provinsi: {
    id: '',
    nama: '',
  },
  kota: {
    id: '',
    nama: '',
  },
  kecamatan: {
    id: '',
    nama: '',
  },
  pastelp: '085774483389',
  paspekerjaan: 'Tukang Kebun',
  pasemail: 'asian@gmail.com',
  pasalergi: 'Oskadon Extra, Pil KB',
  tahun: '0',
  bulan: '0',
  hari: '0',
}
