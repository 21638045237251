import {VmButton, VmIcons, VmView} from 'components'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {FullErrorView, ProgressFullScreen} from 'molecules'
import {TemplateListInputItems} from 'template'
import useAddEventListener from 'utils/useAddEventListener'
import {lstinput} from './entries'
import AlertForm from 'template/Form/AlertForm'
import {defaultpropsalertform} from 'entries/alertform'
import {mDeleteSetting, mSubmitSetting, qIndex} from './api'
import {getParentNode, isEmpty, isFunction} from 'utils'
import VmToast from 'molecules/toast/VmToast'
import VmToastHeader from 'molecules/toast/VmToastHeader'
import classNames from 'classnames'

export default function WebSettings() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const docid = searchParams.get('dctid'),
    iorigin = searchParams.get('origin'),
    title = 'Pengaturan Satu Sehat'

  const [loading, setloading] = useState(false)
  const [resstatus, setresstatus] = useState(0)
  const [error, seterror] = useState('')
  const [form, setform] = useState('')
  const defaultFormValue = {
    clientId: '',
    secretKey: '',
    organizationId: '',
  }
  const [defaultForm, setdefaultForm] = useState(defaultFormValue)
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [toastcontents, settoastcontents] = useState([])

  useEffect(() => {
    initDefault()
  }, [])
  useEffect(() => {
    if (toastcontents?.length > 0) {
      var newtimeout = null
      newtimeout = setTimeout(() => {
        toastcontents.map(im => {
          const onClose = im?.props?.children?.props?.onClose
          if (typeof onClose === 'function') {
            onClose()
          }
        })
        clearTimeout(newtimeout)
      }, 4000)
    }
  }, [toastcontents])

  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const openAlert = (caption, type, onConfirm = closeAlert) => {
    return setpropsalertform({
      ...propsalertform,
      type,
      visible: true,
      children: <>{caption}</>,
      onConfirm: onConfirm,
      onClose: closeAlert,
      onCancel: closeAlert,
    })
  }
  const openAlertWeb = (
    caption,
    type,
    onClose = () => settoastcontents([])
  ) => {
    const types = [
      {
        // Success
        textClassName: 'mr-auto text-white',
        iconClassName: 'mr-2 text-white',
        bgcolors: '!bg-green-600',
        iconName: 'VmCheckCircleIcon',
      },
      {
        // Danger
        textClassName: 'mr-auto text-white',
        iconClassName: 'mr-2 text-white',
        bgcolors: '!bg-red-600',
        iconName: 'VmExclamationCircleIcon',
      },
      {
        // Warning
        textClassName: 'mr-auto text-white',
        iconClassName: 'mr-2 text-white',
        bgcolors: '!bg-yellow2-payment',
        iconName: 'VmExclamationTriangleIcon',
      },
      {
        // Info
        textClassName: 'mr-auto text-white',
        iconClassName: 'mr-2 text-white',
        bgcolors: '!bg-blue1-payment',
        iconName: 'VmInformationCircleIcon',
      },
      {
        // Notifikasi
        textClassName: 'mr-auto text-slate-500',
        iconClassName: 'mr-2 text-slate-500',
        bgcolors: '!bg-slate-100/60',
        iconName: 'VmBellIcon',
      },
    ]
    settoastcontents([
      <VmToast
        show={true}
        autohide={false}
        className="w-fill-available my-2 rounded-md"
      >
        <VmToastHeader
          className={classNames(
            types[type].bgcolors || '!bg-red-500',
            '!rounded-md'
          )}
          onClose={() => {
            onClose()
            settoastcontents([])
          }}
        >
          <VmIcons
            size={22}
            variant="outline"
            className={types[type].iconClassName || 'text-white mr-2'}
            name={types[type].iconName || 'VmExclamationCircleIcon'}
          />
          <strong className={types[type].textClassName || 'mr-auto text-white'}>
            {caption}
          </strong>
        </VmToastHeader>
      </VmToast>,
    ])
  }
  const initDefault = async () => {
    setloading(true)
    const resIndex = await qIndex({klid: docid})
    setresstatus(
      resIndex?.status &&
        resIndex?.data?.clientkey?.length > 0 &&
        resIndex?.data?.secretkey?.length > 0 &&
        resIndex?.data?.organization_id?.length > 0
        ? 1
        : 0
    )
    if (resIndex?.status != 1 && resIndex?.status != 2) {
      seterror(resIndex?.message)
    } else if (resIndex?.status == 1){
      const resFormValue = {
        clientId: resIndex?.data?.clientkey || '',
        secretKey: resIndex?.data?.secretkey || '',
        organizationId: resIndex?.data?.organization_id || '',
      }
      setdefaultForm(resFormValue)
      setform(resFormValue)
    }
    setloading(false)
  }
  const onClick = async e => {
    const {ariaLabel} = e?.target
    if (ariaLabel === 'submitSetting') {
      if (!form?.clientId || !form?.secretKey || !form?.organizationId)
        return openAlertWeb('Semua kolom harus diisi!', 1)

      setloading(true)
      const resSubmit = await mSubmitSetting({
        data: {
          clientkey: form?.clientId,
          secretkey: form?.secretKey,
          organization_id: form?.organizationId,
          datetime: Date.now(),
        },
        dataLog: {
          before: JSON.stringify(defaultForm),
          after: JSON.stringify(form),
        },
        docid,
      })
      setloading(false)
      if (resSubmit?.status == 1)
        return openAlertWeb(resSubmit?.message, 0, initDefault)

      return openAlertWeb(resSubmit?.message, 2, initDefault)
    }
    if (ariaLabel === 'deleteSetting') {
      setloading(true)
      const resSubmit = await mDeleteSetting({
        data: {
          clientkey: form?.clientId,
          secretkey: form?.secretKey,
          organization_id: form?.organizationId,
          datetime: Date.now(),
        },
        dataLog: {
          before: JSON.stringify(defaultForm),
          after: JSON.stringify(defaultFormValue),
        },
        docid,
      })
      setloading(false)
      if (resSubmit?.status == 1){
        const resFormValue = {
          clientId: '',
          secretKey: '',
          organizationId: '',
        }
        setdefaultForm(resFormValue)
        setform(resFormValue)
        return openAlertWeb(resSubmit?.message, 0, initDefault)
      }

      return openAlertWeb(resSubmit?.message, 2, initDefault)
    }
  }
  const onChange = e => {
    const {ariaLabel, value} = e?.target
    const whiteListForm = ['clientId', 'secretKey', 'organizationId']
    if (whiteListForm.includes(ariaLabel))
      setform(s => ({...s, [ariaLabel]: value}))
  }
  const onRequired = e => {
    const newError = document.createElement('p', {})
    const errorId = `error-${e?.target?.ariaLabel}`
    if (isFunction(document.getElementById(errorId)?.remove)) {
      document?.getElementById(errorId)?.remove()
    }
    var parentNode = getParentNode(e?.target)
    parentNode = parentNode?.parentNode
    if (e?.target?.required && isEmpty(e?.target?.value)) {
      const title =
        parentNode?.parentNode?.firstChild?.firstChild?.innerText || 'Kolom ini'
      newError.className = 'ml-4 my-1 text-red-500 p-2 text-sm'
      newError.id = errorId
      newError.replaceChildren(`${title} tidak boleh kosong`)
      parentNode.after(newError)
      return true
    }
    return false
  }
  const onBlur = e => {
    const isRequired = onRequired(e)
    if (isRequired) {
      return
    }
  }
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  useAddEventListener({eventName: 'focusout', handler: onBlur})

  if (!!error)
    return (
      <VmView className="inset-0 bg-white p-4">
        <VmView className="flex flex-col p-4">
          <VmView className="px-5 pb-5 pt-8 text-lg font-semibold text-black2-payment text-center w-fill-available">
            {title}
          </VmView>
          <FullErrorView message={error} />
        </VmView>
      </VmView>
    )

  return (
    <>
      <ProgressFullScreen visible={loading} />
      <VmView className="fixed inset-0">
        {toastcontents?.length > 0 && toastcontents.map(Im => Im)}
        <VmView className="flex flex-col p-4 bg-white">
          <VmView className="px-5 pb-5 pt-8 text-lg font-semibold text-black2-payment text-center w-fill-available">
            {title}
          </VmView>
          <VmView className="w-full">
            <TemplateListInputItems
              data={defaultForm}
              lstinput={lstinput}
              bgcolors={
                'bg-slate-100 !rounded-2xl border-[3px] border-slate-200'
              }
              colors={'!text-sm text-slate-600 font-semibold'}
            />
          </VmView>
        </VmView>
        <VmView className="flex flex-row justify-between items-center px-4 py-4 bg-white">
          {/* Left */}
          <VmView className="flex flex-row gap-4 px-2">
            <VmButton
              label="submitSetting"
              className={'font-semibold text-sm px-6 py-3 min-w-[8rem]'}
              rounded="2xl"
              color="success"
            >
              Simpan
            </VmButton>
            {resstatus === 1 && (
              <VmButton
                label="deleteSetting"
                className={'font-semibold text-sm px-6 py-3 min-w-[8rem]'}
                rounded="2xl"
                color="danger"
              >
                Hapus
              </VmButton>
            )}
          </VmView>
          {/* Right */}
          <VmView className="flex flex-row gap-4 px-2"></VmView>
        </VmView>
      </VmView>
      <AlertForm {...{...propsalertform, noText: false, yesText: 'Ok'}} />
    </>
  )
}
