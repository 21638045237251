import {VmButton, VmModal, VmView} from 'components'
import {ProgressFullScreen, VmHeader} from 'molecules'
import React, {Fragment, useEffect, useRef, useState} from 'react'
import {BottomSheet} from 'react-spring-bottom-sheet'
import useAddEventListener from 'utils/useAddEventListener'
import {defaultpropsbottomsheet} from 'entries/bottomsheet'
import classNames from 'classnames'
import {isFunction} from 'utils'
import {defaultpropsalertform} from 'entries/alertform'
import AlertForm from 'template/Form/AlertForm'
import {useParams} from 'react-router-dom'
import CardItem from './CardItem'
import {DataObat} from './classes'
import {useCarts} from './utils'
import {conditionlist, etiketlist, inputEditRacikan} from './entries'
import {TemplateListInputItems} from 'template'
import ListConditions from './ListConditions'
import ListEtiket from './ListEtiket'
import {getElement} from 'utils/get-element'

export default function DetailRacikan({
  labelBackButton = 'modal-back-button',
  optcarts = {},
  ...props
}) {
  const defaultetiket = {
    conditions: props?.data?.conditions || 0,
    etiket: props?.data?.etiket || 0,
  }
  const dataetiket = {
      // conditions: conditionlist[defaultetiket.conditions].type,
      // conditionsTypeTitle: conditionlist[defaultetiket.conditions].title,
      // etiket: etiketlist[defaultetiket.etiket].type,
      // etiketTypeTitle: etiketlist[defaultetiket.etiket].title,
      // 'condition-type': 'Makan',
    },
    showlog = true,
    modalRef = useRef(),
    bottomSheetRef = useRef()
  const dctid = parseInt(useParams().id)
  const [loading, setloading] = useState(false)
  const [data, setdata] = useState(Object.assign(props?.data || {}, dataetiket))
  const [propsalertform, setpropsalertform] = useState(defaultpropsalertform)
  const [propsbottomsheet, setpropsbottomsheet] = useState(
    defaultpropsbottomsheet
  )
  const {carts, edititemcarts} = useCarts(optcarts)
  const closeAlert = () => setpropsalertform(defaultpropsalertform)
  const closeBs = () => setpropsbottomsheet(defaultpropsbottomsheet)
  const onClick = e => {
    // onClick
    try {
      const {ariaLabel} = e?.target
      if (['input-etiket', 'picker-input-etiket'].includes(ariaLabel)) {
        // Open Bs etiket type
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          onClick: closeBs,
          children: (
            <ListEtiket
              data={data}
              setdata={setdata}
              list={etiketlist}
              closeBs={closeBs}
            />
          ),
        })
      }
      if (['input-conditions', 'picker-input-conditions'].includes(ariaLabel)) {
        // Open Bs conditions type
        return setpropsbottomsheet({
          ...propsbottomsheet,
          open: true,
          onClick: closeBs,
          children: (
            <ListConditions
              data={data}
              setdata={setdata}
              list={conditionlist}
              closeBs={closeBs}
            />
          ),
        })
      }
    } catch (error) {}
  }
  const onChange = e => {
    // onChange
    try {
      const {ariaLabel, value} = e?.target
      if (showlog) {
        console.log('ariaLabel, value', e)
      }
      if (`${ariaLabel}`.toLowerCase()?.match(/^input-waktu/i)) {
        // const labellow = `${ariaLabel}`.toLowerCase()
        // const idx = labellow.replace('input-waktu ', '')
        // e.target.value = value
        return setdata({
          ...data,
          [ariaLabel.replace('input-W', 'w')]: value,
        })
      }
    } catch (error) {}
  }
  useAddEventListener({eventName: 'click', handler: onClick})
  useAddEventListener({eventName: 'input', handler: onChange})
  const onBackPress = () => {
    if (isFunction(props?.close)) {
      props?.close()
    }
  }
  useEffect(() => {
    // On Change data
    edititemcarts(data, 'flagcart')
    const etiket = data?.etiket || 0
    if (etiket > 0) {
      for (let i = 1; i <= etiket; i++) {
        const equal = `input-Waktu${i}`
        const finsInputEl = getElement({
          tag: 'input',
          by: 'ariaLabel',
          equal,
        })
        if (!!finsInputEl) {
          if (
            isFunction(finsInputEl.setAttribute) &&
            !finsInputEl.getAttribute('value')
          ) {
            finsInputEl.setAttribute('value', data[`waktu${i}`] || '00:00')
          }
        }
      }
    } else {
      data['waktu1'] = '00:00'
      data['waktu2'] = '00:00'
      data['waktu3'] = '00:00'
      data['waktu4'] = '00:00'
      data['waktu5'] = '00:00'
    }
    return () => {}
  }, [data])
  useEffect(() => {
    return () => {}
  }, [])

  return (
    <VmView className="flex flex-col absolute inset-0 bg-blue4-payment px-5 pt-5">
      {/* Headers */}
      <VmHeader
        className="sticky mb-5"
        HeaderMode="!text-black2-payment"
        HeaderButtonVersion={0}
        HeaderName="Menu"
        HeaderType={'Pembuatan Resep'}
        onBackPress={onBackPress}
        labelBackButton={labelBackButton}
      />
      <VmView className="flex flex-col h-[-webkit-fill-available] overflow-y-scroll">
        {/* Head Racikan */}
        <VmView className="relative bg-white rounded-2xl py-4 px-2 mb-4 text-prevent-select">
          {/* Name */}
          <VmView className="px-2 pb-1 font-semibold">
            {data?.name || ''}
          </VmView>
          {/* Code */}
          <VmView className="px-2 pb-1 flex flex-wrap text-sm">
            {data?.code || ''}
          </VmView>
          {/* Eticket Racikan
          <TemplateListInputItems
            colors="text-gray5-payment"
            lstinput={inputEditRacikan.lstinput}
            data={data}
          /> */}
        </VmView>
        <VmView className="relative mb-36">
          {/* Items Racikan */}
          {carts.map((im = new DataObat({}), index) => {
            const ischildracikan =
              im?.flaxR?.includes('racikan') &&
              im?.flaxR === data?.flaxR &&
              `${im?.jenis}`.toLowerCase() !== 'racikan'
            if (ischildracikan) {
              return (
                <CardItem key={`CardItem-${index}`} index={index} data={im} />
              )
            }
            return <Fragment key={`CardItem-${index}`} />
          })}
        </VmView>
      </VmView>
      {/* Footer */}
      <VmView
        className={classNames(
          'flex flex-row-reverse absolute bottom-0 left-0 right-0 bg-blue4-payment px-5 pb-5 pt-4'
        )}
      >
        <VmButton
          className={classNames(
            'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[45%] !bg-red1-payment !text-white'
          )}
          hidering={true}
          onClick={onBackPress}
        >
          Tutup
        </VmButton>
      </VmView>
      {/* Bottom Sheet */}
      <BottomSheet ref={bottomSheetRef} {...propsbottomsheet} />
      {/* Modal */}
      <VmModal ref={modalRef} />
      {/* AlertForm */}
      <AlertForm {...propsalertform} />
      {/* Loading Full Screen */}
      <ProgressFullScreen visible={loading} />
    </VmView>
  )
}
