/* eslint-disable */
import React, {useState, useRef, useMemo, useEffect} from 'react'
import {
  node,
  oneOfType,
  string,
  array,
  bool,
  object,
  func,
  number,
} from 'prop-types'
import classNames from 'classnames'
import {
  VmIcons,
  VmPagination,
  VmElementCover,
  VmPicker,
  VmSpinner,
  VmText,
} from 'components'
import style from './VmList.module.css'
import {VmPencilSquareIcon, VmTrashIcon} from 'components/icons/solid'
import InfiniteScroll from 'react-infinite-scroller'
import VmLoading from 'components/loader/VmLoading'
import {iconMap} from 'components/icons/IconMap'

const VmList = props => {
  const {
    innerRef,
    overTableSlot,
    underTableSlot,
    loadingSlot,
    loading,
    fields,
    itemsPerPage,
    items,
    sorter,
    columnFilter,
    tableFilterValue,
    searchbar,
    searchbarLeft,
    searchbarRight,
    cleaner,
    responsive,
    hasMoreItems,
    itemsPerPageSelect,
    sorterValue,
    columnFilterValue,
    onSorterValueChange,
    onColumnFilterChange,
    onPagesChange,
    onTableFilterChange,
    onFilteredItemsChange,
    editButton,
    deleteButton,
    customButton,
    itemContainerClassName,
    listSearchbarClassname,
    containerClassName,
  } = props

  const compData = useRef({
    firstRun: true,
    columnFiltered: 0,
    changeItems: 0,
  }).current

  const [perPageItems, setPerPageItems] = useState(itemsPerPage)
  const [sorterState, setSorterState] = useState(sorterValue || {})
  const [tableFilterState, setTableFilterState] = useState(tableFilterValue)

  const [columnFilterState, setColumnFilterState] = useState(
    columnFilterValue || {}
  )
  const [passedItems, setPassedItems] = useState(items || [])

  const inputClass =
    'flex-1 border py-2 px-3 mb-2 text-grey-darkest focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 rounded-xl mr-2 text-sm'

  useEffect(() => {
    onSorterValueChange && onSorterValueChange(sorterState)
  }, [JSON.stringify(sorterState)])

  useEffect(() => {
    onColumnFilterChange && onColumnFilterChange(columnFilterState)
  }, [JSON.stringify(columnFilterState)])

  const tableFilterChange = (value, type) => {
    const isLazy = searchbar && searchbar.lazy === true
    if ((isLazy && type === 'input') || (!isLazy && type === 'change')) {
      return
    }
    setTableFilterState(value)
  }

  useEffect(() => {
    onTableFilterChange && onTableFilterChange(tableFilterState)
  }, [tableFilterState])

  const genCols = Object.keys(passedItems[0] || {}).filter(
    el => el.charAt(0) !== '_'
  )

  const rawColumnNames = fields ? fields.map(el => el.key || el) : genCols

  const itemsDataColumns = rawColumnNames.filter(name => genCols.includes(name))

  useMemo(() => {
    compData.columnFiltered++
  }, [
    JSON.stringify(columnFilter),
    JSON.stringify(columnFilterState),
    itemsDataColumns.join(''),
    compData.changeItems,
  ])

  const columnFiltered = useMemo(() => {
    let items = passedItems
    if (columnFilter && columnFilter.external) {
      return items
    }
    Object.entries(columnFilterState).forEach(([key, value]) => {
      const columnFilter = String(value).toLowerCase()
      if (columnFilter && itemsDataColumns.includes(key)) {
        items = items.filter(item => {
          return String(item[key]).toLowerCase().includes(columnFilter)
        })
      }
    })
    return items
  }, [compData.columnFiltered])

  const tableFiltered = useMemo(() => {
    let items = columnFiltered
    if (!tableFilterState || (searchbar && searchbar.external)) {
      return items
    }
    const filter = tableFilterState.toLowerCase()
    const valueContainFilter = val => String(val).toLowerCase().includes(filter)
    items = items.filter(item => {
      return !!itemsDataColumns.find(key => valueContainFilter(item[key]))
    })
    return items
  }, [compData.columnFiltered, tableFilterState, JSON.stringify(searchbar)])

  const sortedItems = useMemo(() => {
    const col = sorterState.column
    if (
      !col ||
      !itemsDataColumns.includes(col) ||
      (sorter && sorter.external)
    ) {
      return tableFiltered
    }
    // if values in column are to be sorted by numeric value they all have to be type number
    const flip = sorterState.asc ? 1 : -1
    const sorted = tableFiltered.slice().sort((item, item2) => {
      const value = item[col]
      const value2 = item2[col]
      const a = typeof value === 'number' ? value : String(value).toLowerCase()
      const b =
        typeof value2 === 'number' ? value2 : String(value2).toLowerCase()
      return a > b ? 1 * flip : b > a ? -1 * flip : 0
    })
    return sorted
  }, [
    JSON.stringify(tableFiltered),
    JSON.stringify(sorterState),
    JSON.stringify(sorter),
  ])

  useEffect(() => {
    !compData.firstRun &&
      onFilteredItemsChange &&
      onFilteredItemsChange(sortedItems)
  }, [JSON.stringify(sortedItems)])

  const totalPages = Math.ceil(sortedItems.length / perPageItems) || 1
  useMemo(() => {
    !compData.firstRun && onPagesChange && onPagesChange(totalPages)
  }, [totalPages])

  const tableFilterData = {
    label: (searchbar && searchbar.label) || 'Filter:',
    placeholder: (searchbar && searchbar.placeholder) || 'Cari...',
  }

  // watc
  useMemo(() => setPerPageItems(itemsPerPage), [itemsPerPage])
  useMemo(() => setSorterState({...sorterValue}), [sorterValue])
  useMemo(() => setTableFilterState(tableFilterValue), [tableFilterValue])

  useMemo(
    () => setColumnFilterState({...columnFilterValue}),
    [columnFilterValue]
  )

  // items
  useMemo(() => {
    if (
      items &&
      !compData.firstRun &&
      (items.length !== passedItems.length ||
        JSON.stringify(items) !== JSON.stringify(passedItems))
    ) {
      setPassedItems(items)
      compData.changeItems++
    }
  })

  // render
  compData.firstRun = false

  return (
    <div className={classNames(containerClassName)}>
      <div ref={innerRef} className="flex flex-wrap mx-4">
        {searchbar && (
          <>
            {/* <label className="mfe-2">{tableFilterData.label}</label> */}
            {searchbarLeft}
            <input
              className={classNames(inputClass, listSearchbarClassname)}
              type="text"
              placeholder={tableFilterData.placeholder}
              onInput={e => {
                tableFilterChange(e.target.value, 'input')
              }}
              onChange={e => {
                tableFilterChange(e.target.value, 'change')
              }}
              onKeyUp={event => {
                if (event.key == 'Enter') {
                  console.log('onSubmit editing')
                  props.onSearchSubmit?.(tableFilterState)
                }
              }}
              onBlur={event => {
                console.log('onSubmit editing')
                props.onSearchSubmit?.(tableFilterState)
                // if (event.key == 'Enter') {
                // }
              }}
              value={tableFilterState || ''}
              aria-label="table filter input"
              // style={{flex:0.8}}
            />
            {searchbarRight}
          </>
        )}
      </div>

      {/* {console.log('VMList hasMoreItems', hasMoreItems)} */}
      <InfiniteScroll
        threshold={0}
        pageStart={0}
        loadMore={page => {
          // console.log('loadMore', page)
          props.loadMore(page)
        }}
        hasMore={hasMoreItems}
        loader={
          <div key={'Random-wkwkwk'} className="flex justify-center pt-0 pb-12">
            <VmSpinner color="sky-500" size="md" />
          </div>
        }
      >
        {/* Jika Tidak ada data, maka tampilkan Message Tidak ada Data. */}
        {!items.length ? (
          <VmText className={'text-center text-slate-600'}>
            Tidak ada data.
          </VmText>
        ) : null}

        {items.map((item, index) => {
          return (
            <div
              key={'Halo ' + index}
              className={classNames(
                'relative flex justify-between items-center item-container hover:bg-slate-50 rounded-xl',
                itemContainerClassName
              )}
              onClick={() => {
                // console.log('click item')
              }}
            >
              <div
                className="ribbon absolute top-0 left-0 px-4 py-1 rounded-tl-xl rounded-br-xl text-white font-bold bg-vm-blue"
                style={{backgroundColor: ''}}
              >
                {index + 1}
              </div>
              <div style={{flex: 1}}>
                {fields.map((field, j) => {
                  let itemStyle = field._style
                  // console.log(itemStyle)
                  let display
                  if (field.key == 'index') {
                    // To display index / number
                    display = `${index + 1}.`
                  } else {
                    // Displaying value of an attribute
                    if (field.format) {
                      display = field.format(item[field.key])
                    } else {
                      display = item[field.key] || '-'
                    }
                  }

                  if (field.type == 'icon') {
                    const {iconConfig} = field
                    let Icon = iconMap[iconConfig.name]
                    // console.log({Icon, iconName: iconConfig.name})
                    return (
                      <div
                        key={`Halo ${j}`}
                        className={classNames('flex', field.containerClassName)}
                      >
                        {Icon ? (
                          <Icon
                            width={iconConfig.width}
                            height={iconConfig.height}
                            className={classNames('mr-1', iconConfig.className)}
                          />
                        ) : null}
                        <p
                          key={j}
                          style={{...itemStyle}}
                          className={classNames('flex-1', field.className)}
                        >
                          {display}
                        </p>
                      </div>
                    )
                  } else {
                    return (
                      <p
                        key={j}
                        style={{...itemStyle}}
                        className={classNames('', field.className)}
                      >
                        {display}
                      </p>
                    )
                  }
                })}
                <div className="flex row justify-around mt-2">
                  {/* Edit button kasih callback data item, agar bisa lebih kondisional */}
                  {editButton?.(item) ? (
                    <div
                      onClick={() => {
                        props.onEditItemPress?.(item)
                      }}
                      className="flex flex-1 items-center justify-center rounded bg-transparent mr-2 py-1"
                    >
                      <VmPencilSquareIcon
                        // color="orange-500"
                        height={`${24}px`}
                        width={`${24}px`}
                        className="mr-0 text-orange-500"
                      />
                      <p className="text-orange-500">Ubah</p>
                    </div>
                  ) : null}

                  {/* Delete button kasih callback data item, agar bisa lebih kondisional */}
                  {deleteButton?.(item) ? (
                    <div
                      onClick={() => {
                        props.onDeleteItemPress?.(item)
                      }}
                      className="flex flex-1 items-center justify-center rounded bg-transparent ml-2 py-1"
                    >
                      <VmTrashIcon
                        // color="red-500"
                        height={`${24}px`}
                        width={`${24}px`}
                        className="mr-0 text-red-500"
                      />
                      <p className="text-red-500">Hapus</p>
                    </div>
                  ) : null}
                </div>

                {/* Custom button kasih callback data item, agar bisa lebih kondisional */}
                {customButton?.(item) ? customButton?.(item) : null}
              </div>
              <div className="flex flex-row items-center list-action"></div>
            </div>
          )
        })}
      </InfiniteScroll>

      {overTableSlot}

      <div className={`position-relative ${responsive && 'table-responsive'}`}>
        {loading &&
          (loadingSlot || (
            <VmElementCover
              boundaries={[
                {sides: ['top'], query: 'td'},
                {sides: ['bottom'], query: 'tbody'},
              ]}
            />
          ))}
      </div>

      {underTableSlot}
    </div>
  )
}

VmList.propTypes = {
  innerRef: oneOfType([object, func]),
  overTableSlot: node,
  columnHeaderSlot: object,
  sortingIconSlot: func,
  columnFilterSlot: object,
  noItemsViewSlot: node,
  noItemsView: object,
  captionSlot: node,
  footerSlot: node,
  underTableSlot: node,
  scopedSlots: object,
  theadTopSlot: node,
  loadingSlot: node,
  loading: bool,
  fields: array,
  pagination: oneOfType([bool, object]),
  activePage: number,
  itemsPerPage: number,
  items: array,
  sorter: oneOfType([bool, object]),
  clickableRows: bool,
  columnFilter: oneOfType([bool, object]),
  tableFilterValue: string,
  searchbar: oneOfType([bool, object]),
  cleaner: oneOfType([bool, func]),
  addTableClasses: oneOfType([string, array, object]),
  size: string,
  dark: bool,
  striped: bool,
  hover: bool,
  border: bool,
  outlined: bool,
  responsive: bool,
  footer: bool,
  itemsPerPageSelect: oneOfType([bool, object]),
  sorterValue: object,
  columnFilterValue: object,
  header: bool,
  onRowClick: func,
  onSorterValueChange: func,
  onPaginationChange: func,
  onColumnFilterChange: func,
  onPagesChange: func,
  onTableFilterChange: func,
  onPageChange: func,
  onFilteredItemsChange: func,
}

VmList.defaultProps = {
  itemsPerPage: 10,
  responsive: true,
  columnHeaderSlot: {},
  columnFilterSlot: {},
  scopedSlots: {},
  sorterValue: {},
  header: true,
}

export default VmList
