const modelData = ({key, data}) => {
  const reg = 'dbdok'
  switch (key) {
    case 'index':
      return {
        req: JSON.stringify({
          id: data?.id,
          limit: data?.limit,
          offset: data?.offset,
          dateStart: data?.dateStart,
          dateEnd: data?.dateEnd,
          bulan: data?.bulan,
          tahun: data?.tahun,
          sort: data?.sort,
          cari: data?.cari,
          ep: data?._ldata,
          log: true,
          reg,
        }),
      }
    default:
      return null
  }
}

export {modelData}
