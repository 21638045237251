import classNames from 'classnames'
import {VmButton, VmView} from 'components'

export default function Footer() {
  return (
    <VmView className="flex flex-row-reverse absolute bg-blue4-payment p-5 bottom-0 left-0 right-0">
      <VmButton
        label="add-mitra"
        className={classNames(
          'flex justify-center items-center !flex-none text-sm p-2 px-4 !min-w-[45%] !bg-blue1-payment !text-white'
        )}
        hidering={true}
      >
        {`Tambah`}
      </VmButton>
    </VmView>
  )
}
